<template>
    <div class="container">
        <inline-authorization-message v-if="!isEditable" />
        <emp-large-order-notification-form :facility="facility" :isEditable="isEditable">
        </emp-large-order-notification-form>

        <emp-button @click="onClick" label="Save" v-show="isEditable" class="save-button"></emp-button>
    </div>
</template>

<script>
import SmartApi from 'SmartApi';
import { systemNotifications, highlightErrorFields } from 'component/shared/elements/mixins';
import InlineAuthorizationMessage from 'component/shared/elements/InlineAuthorizationMessage';
import store from 'store';
import _ from 'lodash';
import Bugsnag from '@bugsnag/js';

async function fetchFacility() {
    return await SmartApi.get({
        routeName: 'facilities.show',
        routeParams: {
            id: store.getters['shared/currentUser/facilityId'],
        },
        config: {
            hideLoader: true,
        },
    });
}

export default {
    components: {
        InlineAuthorizationMessage,
    },
    mixins: [systemNotifications, highlightErrorFields],
    data() {
        return {
            facility: {
                largeOrderNotificationAmount: null,
                largeOrderNotificationEnabled: false,
                largeOrderNotificationEmails: [],
                largeOrderNotificationCellNumbers: [],
            },
        };
    },
    computed: {
        isEditable() {
            return this.currentUserCan('tools.large-order-notification.edit');
        },
    },
    methods: {
        setFacility(facility) {
            this.facility = facility;
        },
        async onClick() {
            try {
                let response = await this.updateFacility();
                this.showSuccessNotification('Large Order Notification Settings updated.');
            } catch (e) {
                if (!e.response) {
                    Bugsnag.notify(e);
                }
                const defaultMessage = 'An error occurred when attempting to save Large Order Notification Settings.';
                const message = _.get(e, 'response.data.message', defaultMessage);
                const data = _.get(e, 'response.data', {});
                this.highlightErrorFields(data);
                this.showDangerNotification(message);
            }
        },
        async updateFacility() {
            return await SmartApi.put({
                routeName: 'facilities.edit',
                routeParams: {
                    id: this.currentUserFacilityId,
                },
                data: this.facility,
            });
        },
    },
    async beforeRouteEnter(to, from, next) {
        const response = await fetchFacility();
        await next((vm) => vm.setFacility(response.data.data));
    },
};
</script>

<style lang="scss" scoped>
.save-button {
    margin-top: 1rem;
}
</style>
