import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = params.id ? route('users.show', { id: params.id }) : route('users');
        delete params.id;
        return await axios.get(url, { params });
    },
    async update(id, params = {}) {
        const url = route('users.update', [id]);
        const response = await axios.put(url, params);
        return response.data.data;
    },
    async getUserFacilityPermissions(id, facility) {
        let url = route('user_facility_permissions', { id: id, facility: facility });
        return await axios.get(url);
    },
    async updateUserFacilityPermissions(id, facility, payload) {
        let url = route('user_facility_permissions_save', { id: id, facility: facility });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    // TODO: Rename this to something sane.
    async dropdownData(params = {}) {
        let url = route('users.dropdowns');
        return await axios.get(url, { params });
    },
};
