<template>
    <emp-component-modal @modal:close="onClose" :isActive="isActive">
        <template v-if="isActive">
            <div slot="modal-header">Create Shipping Label</div>
            <div slot="modal-body">
                <form autocomplete="off" class="create-shipping-label">
                    <div class="step-1" v-if="activeStep == 1">
                        <div class="columns is-multiline">
                            <div class="column is-full">
                                <h3 class="subtitle">Who is paying for shipment?</h3>
                            </div>
                            <div class="column is-full">
                                <emp-button
                                    v-if="paysForReturn !== 'vendor'"
                                    label="Customer"
                                    @click="chooseWhoPaysForReturn('customer')"
                                />
                                <emp-button label="Vendor" @click="chooseWhoPaysForReturn('vendor')" />
                                <emp-button
                                    v-if="paysForReturn !== 'vendor'"
                                    label="Empire"
                                    @click="chooseWhoPaysForReturn('empire')"
                                />
                            </div>
                            <div class="column is-full">
                                <emp-checkbox
                                    label="Is this label for a return?"
                                    fieldClass=""
                                    v-model="form.isReturnLabel"
                                    name="isReturnLabel"
                                    :errors="errors"
                                />
                            </div>
                        </div>
                        <div class="level">
                            <div class="level-left"></div>
                            <div class="level-right">
                                <div class="level-item">
                                    <emp-button label="Cancel" @click="onCancel" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-2" v-if="activeStep == 2">
                        <div class="columns is-multiline" v-if="returnAddressIsConfigured">
                            <div class="column is-6">
                                <h3 class="subtitle">
                                    The following items may be returned using the same label. Check all items you would
                                    like to include in this shipment.
                                </h3>
                            </div>
                            <div class="column is-6">
                                <template v-for="(line, index) in linesForCombine">
                                    <div class="columns" :key="`create_shipping_label_${index}`">
                                        <emp-checkbox
                                            fieldClass="column is-1 checkbox"
                                            labelClass="has-text-right"
                                            @input="combineLine($event, line)"
                                        />
                                        <div class="column is-11">
                                            <span v-if="line.purchaseOrder"
                                                >PO# {{ line.purchaseOrder.idWithSuffix }} -</span
                                            >
                                            Item #{{ line.partNumber }}
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-else>
                            <h3 class="subtitle">
                                A return address is not configured for this Line's Vendor and Category. Additional Lines
                                will not be recommended.
                            </h3>
                        </div>
                        <div class="level">
                            <div class="level-left"></div>
                            <div class="level-right">
                                <div class="level-item">
                                    <emp-button label="Cancel" @click="onCancel" />
                                </div>
                                <div class="level-item">
                                    <emp-button label="Next" @click="activeStep = 3" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="step-3" v-if="activeStep == 3">
                        <div class="tile is-ancestor">
                            <div class="tile is-parent">
                                <div class="tile is-child box">
                                    <div class="columns is-multiline">
                                        <h3 class="column is-full subtitle">Ship To</h3>
                                        <emp-textbox
                                            label="Name"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipTo.name"
                                            name="shipTo.name"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Address"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipTo.address_line"
                                            name="shipTo.address_line"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="City"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipTo.city"
                                            name="shipTo.city"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="State"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipTo.state"
                                            name="shipTo.state"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Zipcode"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipTo.postal_code"
                                            name="shipTo.postal_code"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Country Code"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipTo.country_code"
                                            name="shipTo.country_code"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="tile is-parent">
                                <div class="tile is-child box">
                                    <div class="columns is-multiline">
                                        <h3 class="subtitle column is-full">Ship From</h3>
                                        <emp-textbox
                                            label="Name"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipFrom.name"
                                            name="shipFrom.name"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Address"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipFrom.address_line"
                                            name="shipFrom.address_line"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="City"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipFrom.city"
                                            name="shipFrom.city"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="State"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipFrom.state"
                                            name="shipFrom.state"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Zipcode"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipFrom.postal_code"
                                            name="shipFrom.postal_code"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Country Code"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.shipFrom.country_code"
                                            name="shipFrom.country_code"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="columns is-centered">
                            <div class="column has-text-centered">
                                <emp-button
                                    fieldClass="inline-block"
                                    label="Switch To/From Addresses"
                                    @click="switchAddresses"
                                />
                            </div>
                        </div>
                        <div class="tile is-ancestor">
                            <div class="tile is-parent">
                                <div class="tile is-child box">
                                    <div class="columns is-multiline">
                                        <div class="column is-full">
                                            <emp-select-service-type-shipping-method
                                                fieldClass=""
                                                label="Service Type"
                                                v-model="form.details.serviceType"
                                                name="details.serviceType"
                                                :errors="errors"
                                            />
                                        </div>
                                        <emp-textarea
                                            label="Package Description"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.details.packageDescription"
                                            name="details.packageDescription"
                                            :errors="errors"
                                        />
                                        <emp-textbox
                                            label="Package Weight (lbs)"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.details.packageWeight"
                                            name="details.packageWeight"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Reference #"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.details.referenceOne"
                                            name="details.referenceOne"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Reference #"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            name="details.referenceTwo"
                                            v-model="form.details.referenceTwo"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="tile is-parent">
                                <div class="tile is-child box">
                                    <div class="columns is-multiline">
                                        <div class="column is-full">
                                            <emp-checkbox
                                                fieldClass=""
                                                label="Include Dimensions?"
                                                v-model="form.details.includeDimensions"
                                            />
                                        </div>
                                        <emp-textbox
                                            label="Package Width (Inches)"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.details.dimensionsWidth"
                                            name="details.dimensionsWidth"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Package Height (Inches)"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.details.dimensionsHeight"
                                            name="details.dimensionsHeight"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Package Length (Inches)"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.details.dimensionsLength"
                                            name="details.dimensionsLength"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <div class="column is-full">
                                            <emp-checkbox
                                                label="Bill third party account?"
                                                fieldClass=""
                                                v-model="form.details.billThirdParty"
                                            />
                                        </div>
                                        <emp-textbox
                                            label="Third party account #"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.details.thirdPartyAccountNumber"
                                            name="details.thirdPartyAccountNumber"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                        <emp-textbox
                                            label="Billing Zip"
                                            fieldClass="column is-full"
                                            inputClass=""
                                            labelClass=""
                                            v-model="form.details.thirdPartyBillingZipcode"
                                            name="details.thirdPartyBillingZipcode"
                                            :errors="errors"
                                            :ignoreLastPass="true"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="level">
                            <div class="level-left"></div>
                            <div class="level-right">
                                <div class="level-item">
                                    <emp-button label="Save" @click="onSave" buttonClass="is-primary" />
                                </div>
                                <div class="level-item">
                                    <emp-confirmation-button label="Cancel" buttonClass="is-danger" @click="onCancel" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </template>
    </emp-component-modal>
</template>

<script>
import _ from 'lodash';
import { apis } from 'api/client';
import OrdersApi from 'api/endpoints/orders';
import EventBus from 'event-bus';
import { systemNotifications, handlesErrors } from 'component/shared/elements/mixins';
import { mapActions, mapState, mapGetters } from 'vuex';
import { Statuses } from 'enums';
import SmartApi from 'SmartApi';
import { defineComponent } from '@vue/composition-api';
import { getStateCodeById } from 'enumHelpers';

const UPS_REFERENCE_FIELD_LENGTH = 35;
const UPS_REFERENCE_FIELD_DELIMITER = ',';

const BLANK_FORM = {
    shipTo: {
        name: '',
        address_line: '',
        city: '',
        state: '',
        postal_code: '',
        country_code: '',
    },
    shipFrom: {
        name: '',
        address_line: '',
        city: '',
        state: '',
        postal_code: '',
        country_code: '',
    },
    details: {
        serviceType: '03',
        packageDescription: '',
        packageWeight: '',
        referenceOne: '',
        referenceTwo: '',
        includeDimensions: '',
        dimensionsWidth: '',
        dimensionsHeight: '',
        dimensionsLength: '',
        billThirdParty: '',
        thirdPartyAccountNumber: '',
        thirdPartyBillingZipcode: '',
    },
    combinedLines: [],
    isReturnLabel: true,
};

export default defineComponent({
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [systemNotifications, handlesErrors],
    data() {
        return {
            paysForReturn: '',
            form: _.clone(BLANK_FORM),
            linesForCombine: [],
            activeStep: 1,
            errors: {},
            returnAddress: {},
            returnRequest: null,
        };
    },
    computed: {
        ...mapState({
            line: (state) => state.shared.modal.data,
        }),
        returnAddressIsConfigured() {
            return Boolean(this.returnAddress.id);
        },
    },
    methods: {
        ...mapActions({
            updateLineShippingLabelsInformation: 'ordersManage/updateLineShippingLabelsInformation',
            closeModal: 'shared/modal/closeModal',
        }),
        switchAddresses() {
            var shipTo = _.clone(this.form.shipTo);
            var shipFrom = _.clone(this.form.shipFrom);
            this.form.shipTo = shipFrom;
            this.form.shipFrom = shipTo;
        },
        chooseWhoPaysForReturn(option) {
            this.paysForReturn = option;
            if (!this.returnAddressIsConfigured || this.linesForCombine.length) {
                this.activeStep = 2;
            } else {
                this.activeStep = 3;
            }

            switch (option) {
                case 'customer':
                    this.form.details.referenceTwo = this.line.costCenter.code;
                    this.form.details.billThirdParty = false;
                    break;
                case 'vendor':
                    this.form.details.referenceTwo = this.line.vendor.name;
                    this.form.details.billThirdParty = true;
                    break;
                case 'empire':
                    this.form.details.referenceTwo = 'INV';
                    this.form.details.billThirdParty = false;
                    break;
                default:
                    this.form.details.referenceTwo = '';
                    this.form.details.billThirdParty = false;
                    break;
            }

            let reference1;

            if (this.form.combinedLines.length > 1) {
                reference1 = 'RMA# ';
                let propertyPath = 'returnRmaNum';

                if (!this.line['returnRmaNum'] && this.line.purchaseOrder) {
                    reference1 = 'Return PO  ';
                    propertyPath = 'purchaseOrder.idWithSuffix';
                }

                let propertyValues = [];

                _.forEach(this.form.combinedLines, function (combinedLine) {
                    let property = _.get(combinedLine, propertyPath);

                    if (property) {
                        if (_.includes(propertyValues, property)) {
                            return false;
                        }
                        propertyValues.push(property);

                        let tmpReference = reference1 + property + UPS_REFERENCE_FIELD_DELIMITER;

                        if (tmpReference.length > UPS_REFERENCE_FIELD_LENGTH) {
                            return false;
                        }

                        reference1 = tmpReference;
                    }
                });
            } else {
                const rmaNumber = _.get(this, 'returnRequest.rma_return_number');
                if (rmaNumber) {
                    reference1 = 'RMA# ' + rmaNumber;
                } else if (this.line.purchaseOrder) {
                    reference1 = 'Return PO ' + this.line.purchaseOrder.idWithSuffix;
                }
            }

            this.form.details.referenceOne = reference1;
        },
        combineLine(event, line) {
            if (event) {
                this.form.combinedLines.push(line);
            } else {
                this.form.combinedLines = _.reject(this.form.combinedLines, line);
            }
        },
        clear() {
            this.paysForReturn = '';
            this.form = _.clone(BLANK_FORM);
            this.linesForCombine = [];
            this.activeStep = 1;
            this.returnAddress = {};
        },
        onCancel() {
            this.onClose();
        },
        onClose() {
            this.clear();
            this.closeModal();
        },
        updateLines(response) {
            if (response.status) {
                if (!this.form.isReturnLabel) {
                    this.form.combinedLines.forEach((line) => {
                        this.updateLineShippingLabelsInformation({
                            id: line.id,
                            shippingLabel: response.data.shippingLabel,
                            trackingNumber: response.data.trackingNumber,
                        });
                    });
                }
            }
        },
        async onSave() {
            this.errors = {};

            try {
                let response = await OrdersApi.createShippingLabel(this.form);
                this.updateLines(response);
                EventBus.$emit('create-shipping-label:onShippingLabelCreated');
                this.onClose();
            } catch (exception) {
                if (exception.response) {
                    this.errors = exception.response.data.errors.reduce((list, error) => {
                        list[error.field] = error.message;
                        return list;
                    }, {});
                    if (this.errors.isReturnLabel) {
                        this.activeStep = 1;
                    }
                }

                console.log(exception);
            }
        },
        async setData() {
            this.returnAddress = await this.selectReturnAddress();
            const shipTo = this.makeShipTo();
            const shipFrom = this.makeShipFrom();
            const details = this.makeDetails();
            this.form = {
                line: _.clone(this.line),
                shipTo,
                shipFrom,
                details,
                combinedLines: [this.line],
                isReturnLabel: true,
            };

            await this.getCombinedLines();

            await this.getReturnRequest();
        },
        async selectReturnAddress() {
            const returnAddresses = await this.fetchReturnAddresses();
            let returnAddress = this.findConfiguredReturnAddress(returnAddresses);
            if (!returnAddress) {
                returnAddress = {
                    id: null,
                    street_address: null,
                    city: null,
                    state_id: null,
                    zipcode: null,
                    country: null,
                    categories: [],
                };
            }
            return returnAddress;
        },
        async fetchReturnAddresses() {
            const response = await SmartApi.get({
                routeName: 'return-addresses.index',
                routeParams: {
                    vendor: this.line.vendorId,
                },
            });
            return response.data.data;
        },
        shouldCheckForReturnReason() {
            const postOrderStatuses = [
                Statuses.ORDERED,
                Statuses.PARTIALLY_RECEIVED,
                Statuses.RECEIVED,
                Statuses.BACKORDERED,
            ];

            return postOrderStatuses.includes(this.line.statusId);
        },
        async getReturnRequest() {
            if (!this.shouldCheckForReturnReason()) {
                return;
            }

            let response;

            try {
                response = await apis.ReturnRequests.listReturnRequests({
                    line_id: this.line.id,
                });
            } catch (e) {
                this.handleError(e, 'An error occurred while trying to retrieve the return reason.');
            }

            this.returnRequest = _.last(response.body.data);
        },
        findConfiguredReturnAddress(returnAddresses) {
            return returnAddresses.find((returnAddress) => {
                return returnAddress.categories.includes(this.line.categoryId);
            });
        },
        makeShipTo() {
            const shipTo = this.addressableToUPSAddress(this.returnAddress);
            shipTo.name = this.line.vendor.name;
            return shipTo;
        },
        addressableToUPSAddress(addressable) {
            const address_line = _.get(addressable, 'street_address', _.get(addressable, 'streetAddress'));
            const city = _.get(addressable, 'city');
            const country_code = _.get(addressable, 'country');
            const name = _.get(addressable, 'name');
            const postal_code = _.get(addressable, 'zipcode');
            const state = this.determineState(addressable);
            return {
                address_line,
                city,
                country_code,
                name,
                postal_code,
                state,
            };
        },
        determineState(addressable) {
            const missing = {};
            const stateCode = _.get(addressable, 'state.code', missing);
            const hasStateCode = stateCode !== missing;
            if (hasStateCode) {
                return stateCode;
            }
            const stateId = _.get(addressable, 'state_id', _.get(addressable, 'stateId'));
            if (!stateId) {
                return null;
            }
            return getStateCodeById(stateId);
        },
        makeShipFrom() {
            const shipFrom = this.addressableToUPSAddress(this.line.shippingAddress);
            shipFrom.country_code = 'US';
            return shipFrom;
        },
        makeDetails() {
            return {
                serviceType: '03',
                packageDescription: 'Returned Goods',
                thirdPartyAccountNumber: this.line.vendor.upsNumber,
                thirdPartyBillingZipcode: this.line.vendor.upsBillingZipcode,
            };
        },
        async getCombinedLines() {
            if (this.line.id) {
                let params = {
                    includes: ['purchaseOrder'],
                    per_page: 0,
                    shipping_address_id: this.line.shippingAddress.id,
                    vendor_id: this.line.vendor.id,
                    return_request_status_id: [Statuses.PENDING_RETURN, Statuses.RETURN_REQUESTED],
                    id_not_in: [this.line.id],
                };

                let result = await OrdersApi.get(params);
                const allowedCategories = new Set(this.returnAddress.categories);
                this.linesForCombine = result.data.data.filter((line) => {
                    return allowedCategories.has(line.categoryId);
                });
            }
        },
    },
    watch: {
        isActive: {
            handler(value, oldValue) {
                if (value && !oldValue) {
                    this.setData();
                }
            },
            immediate: true,
        },
    },
});
</script>

<style lang="scss" scoped>
.orders {
    .modal-content {
        .message-body {
            max-height: calc(100vh - 200px);
            overflow-y: auto;
        }
    }

    .card-header .button {
        text-decoration: none;
        margin-top: 6px;
        margin-right: 6px;
    }
    .margin-top {
        margin-bottom: 1.1rem;
    }
    .margin-bottom {
        margin-bottom: 1.5rem;
    }
    .checkbox {
        margin-top: 5px;
    }
}
</style>
