import axios from 'axios';
import store from 'store';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        const User = store.getters['shared/currentUser/user'];
        let url = params.id
            ? route('user.reminders.show', { reminder: params.id, user: User.id })
            : route('user.reminders.index', { user: User.id });
        return await axios.get(url, { params });
    },
    async delete(id) {
        const User = store.getters['shared/currentUser/user'];
        let url = route('user.reminders.destroy', { reminder: id, user: User.id });
        return await axios.delete(url);
    },
    async create(payload) {
        const User = store.getters['shared/currentUser/user'];
        let url = route('user.reminders.store', { user: User.id });
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async update(payload) {
        const User = store.getters['shared/currentUser/user'];
        let url = route('user.reminders.update', { reminder: payload.id, user: User.id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
};
