<template>
    <div class="history padded-content">
        <h5 class="title is-5">History</h5>
        <hr />
        <history-entry v-for="entry in historyLines" :key="entry.id" :entry="entry" />
        <a @click="onToggleMore" v-if="hasMore">{{ toggleMoreText }}</a>
    </div>
</template>

<script>
import HistoryEntry from './HistoryEntry';
import moment from 'moment-timezone';
import _ from 'lodash';

export default {
    components: {
        HistoryEntry,
    },
    props: {
        history: {
            type: Array,
            default: () => [],
        },
        lines: {
            type: Number,
            default: 3,
        },
    },
    data() {
        return {
            numberOfLinesToShow: null,
            toggleMoreText: 'View More',
            collapsed: true,
        };
    },
    methods: {
        onToggleMore() {
            if (this.collapsed) {
                this.collapsed = false;
                this.numberOfLinesToShow = this.history.length;
                this.toggleMoreText = 'View Less';
            } else {
                this.collapsed = true;
                this.numberOfLinesToShow = this.lines;
                this.toggleMoreText = 'View More';
            }
        },
    },
    computed: {
        shown: {
            get() {
                return this.numberOfLinesToShow ? this.numberOfLinesToShow : this.lines;
            },
        },
        historyLines() {
            return _.uniqBy(this.history, 'id')
                .sort((a, b) => {
                    if (a.id < b.id) {
                        return 1;
                    } else if (a.id > b.id) {
                        return -1;
                    }

                    return 0;
                })
                .slice(0, this.shown);
        },
        hasMore() {
            return this.lines < this.history.length;
        },
    },
};
</script>

<style lang="scss" scoped></style>
