<template>
    <emp-select
        :disabled="vendors.length == false || disabled"
        :fieldClass="fieldClass"
        :inputClass="[inputClass, { 'is-loading': vendors.length == false }]"
        :label="label"
        :labelClass="labelClass"
        :options="vendors"
        :placeholder="placeholder"
        valueFrom="id"
        :value="value"
        :errors="errors"
        :name="name"
        @input="$emit('input', $event)"
    />
</template>

<script>
import { input, hasOptions } from '../../shared/elements/mixins';
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        label: String,
        placeholder: String,
    },
    mixins: [input],
    computed: {
        ...mapGetters({
            vendors: 'getInventoryVendors',
        }),
    },
    methods: {
        ...mapActions({
            fetch: 'fetchInventoryVendors',
        }),
    },
    created: function () {
        this.fetch();
    },
};
</script>
