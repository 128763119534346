//Top level evaluation functions
export { default as any } from './any';
export { default as all } from './all';

//Logical filter composition functions
export { default as or } from './or';
export { default as and } from './and';
export { default as not } from './not';
export { default as xor } from './xor';

//Filter functions
export { default as inArray } from './inArray';
export { default as equal } from './equal';
export { default as strictEqual } from './strictEqual';
export { default as inFilter } from './in';
export { default as singleInstanceOfKey } from './singleInstanceOfKey';
export { default as uniqueByKey } from './uniqueByKey';
export { default as uniqueInstance } from './uniqueInstance';
export { default as truthy } from './truthy';

//Syntactic sugar functions
export function conditionalFilter(filter, condition) {
    return condition ? filter : null;
}

export function filterList(filterArray) {
    return filterArray.filter((f) => typeof f === 'function');
}
