import { notesRelation, auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
                address: {
                    foreignKey: 'shipping_address_id',
                    localField: 'shippingAddress',
                },
            },
            hasMany: {
                inventory: {
                    foreignKey: 'inventory_location_id',
                    localField: 'items',
                },
            },
            morphMany: {
                ...auditsRelation('inventoryLocation'),
            },
        },
    },
};
