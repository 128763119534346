<template>
    <div class="pagination" :class="['background-grey', classString]" v-if="paginationData && totalPages > 1">
        <div class="button-wrap">
            <emp-button label="Previous" classString="full-width" v-show="showPreviousButton" @click="onPrevious" />
        </div>
        <div class="page-selector">
            <div class="page-total">Page</div>
            <emp-textbox
                fieldClass="is-horizontal input-wrap"
                inputClass="has-text-centered"
                @keyup.enter="goToPage($event.target.value)"
                :value="currentPage"
                @input="onInput"
            />
            <div class="page-total">of {{ totalPages }}</div>
            <div class="button-wrap">
                <emp-button label="Go" @click="goToPage(goTo)" />
            </div>
        </div>
        <div class="button-wrap">
            <emp-button label="Next" classString="full-width" v-show="showNextButton" @click="onNext" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            goTo: 1,
        };
    },
    methods: {
        onPrevious() {
            this.goToPage(this.currentPage - 1);
        },
        onNext() {
            this.goToPage(this.currentPage + 1);
        },
        goToPage(pageNumber) {
            if (pageNumber < 1) {
                pageNumber = 1;
            }

            if (pageNumber > this.totalPages) {
                pageNumber = this.totalPages;
            }

            this.goTo = pageNumber;
            this.$emit('pagination:onSetPage', pageNumber);
        },
        onInput(pageNumber) {
            if (pageNumber != this.currentPage) {
                this.goTo = pageNumber;
            } else {
                this.goTo = this.currentPage;
            }
        },
    },
    computed: {
        showPreviousButton() {
            return this.currentPage > 1;
        },
        showNextButton() {
            return this.currentPage < this.totalPages;
        },
        currentPage() {
            return this.paginationData.current_page;
        },
        totalPages() {
            return this.paginationData.total_pages || this.paginationData.last_page;
        },
    },
    props: {
        paginationData: {
            required: true,
            type: [Object, Boolean],
        },
        classString: {
            type: String,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.pagination {
    margin: 0;
    padding: 0.5em 1vw;

    &.top {
        border-top: 1px solid #ccc;
    }

    &.bottom {
        border-bottom: 1px solid #ccc;
    }

    .button-wrap {
        width: 7em;
        .full-width {
            width: 100%;
        }
    }

    .page-selector {
        margin: 0 1em;
        display: flex;

        .field {
            margin-bottom: 0;
        }

        .input-wrap {
            margin: 0 0.5rem;
            max-width: 5em;
        }
    }

    .page-total {
        display: inline-block;
        padding-top: 0.36em;
    }
}
</style>
