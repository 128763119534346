import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get() {
        let url = route('queue.index');
        return await axios.get(url);
    },
    async delete(id) {
        let url = route('queue.destroy', { queue: id });
        return await axios.delete(url);
    },
};
