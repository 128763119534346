// @ts-check
import _ from 'lodash';
import useProductType from 'use/productTypes';
import useVendorDropdowns from 'use/vendors';
import store from 'store';
import { formatCurrency } from 'helpers';

export default function usePartNumberAutocomplete(props) {
    function featureFlagIsEnabled(flag) {
        return store.getters['revamp/systemSettings/featureFlagIsEnabled'](flag);
    }

    const { productTypeNamesByIdMap } = useProductType();
    const { vendors } = useVendorDropdowns();

    function getVendorNameById(id) {
        if (!vendors.value) {
            return null;
        }
        const vendor = vendors.value.find((v) => v.id === id);
        return vendor ? vendor.name : null;
    }

    function getAdminLabelParts(product) {
        const vendorName = getVendorNameById(product.vendor_id);
        const manufacturerName = getVendorNameById(product.manufacturer_id);
        const productTypeName = productTypeNamesByIdMap.value.get(product.type_id);

        return _.filter(_.uniq([vendorName, manufacturerName, productTypeName]));
    }

    function getFacilityLabelParts(product) {
        const manufacturerName = getVendorNameById(product.manufacturer_id);
        return _.filter([manufacturerName]);
    }

    function processPartNumberLabel(data, value) {
        return data.map((product) => {
            let autocompleteParts = [];

            if (this.currentUserIsAdmin) {
                autocompleteParts = getAdminLabelParts(product);
            } else {
                autocompleteParts = getFacilityLabelParts(product);
            }

            product.autocompleteLabel = '<br>' + autocompleteParts.join(' - ');

            if (product.description) {
                product.autocompleteLabel += `<br />${product.description}`;
            }

            if (props.showPrice && featureFlagIsEnabled('FACILITY_PRODUCT_PRICE')) {
                let price = product.facility_price || product.price;
                if (price) {
                    price = formatCurrency(price);
                    product.autocompleteLabel += `<br />Price: ${price}`;
                }
            }

            return product;
        });
    }

    return {
        getAdminLabelParts,
        getFacilityLabelParts,
        processPartNumberLabel,
    };
}
