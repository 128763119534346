import {apis} from "api/client";
import _ from "lodash";
import * as types from "store/shared/mutation_types";
import moment from "moment-timezone";
import { UpdateTypes, Statuses } from "enums";

export default {
    async fetchNotifications({ commit, state }) {
        const params = {omittedTypes: state.rejectedNotificationTypes};
        let response = await apis.Notifications.getNotifications(params);
        let notifications = _.reject(response.body.data, (n) => {
            return state.rejectedNotificationTypes.includes(n.type);
        });
        commit("setNotifications", notifications);
    },
    async fetchNewNotifications({ commit, dispatch, state, }) {
        let params = {omittedTypes: state.rejectedNotificationTypes};

        let latestNotification = _.reduce(state.notifications, (max, n) => {
            return (n.created_at > max.created_at) ? n : max;
        });

        if ( latestNotification ) {
            params.after =  latestNotification.created_at;
        }

        let response = await apis.Notifications.getNotifications(params);
        let notifications = _.reject(response.body.data, (n) => {
            return state.rejectedNotificationTypes.includes(n.type);
        });
        for(let x in notifications) {
            if(!state.notifications.hasOwnProperty(notifications[x].id)) {
                dispatch("addNotification", notifications[x]);
            }
        }
    },
    addNotification({ commit, dispatch }, notification) {
        //This looks weird but it basically assures the fields
        // are present and thus are and remain reactive. - Toby
        commit("addNotification", {created_at: null, updated_at: null, read_at: null, type: null, ...notification});
        dispatch("toastUserNotification", notification);
    },

    toastUserNotification({ commit, dispatch, state }, notification) {
        switch(notification.type.split('\\').pop()) {
            case 'ReminderDue':
                dispatch('toastInfo', { title: "Time For: " + notification.data.title, text: notification.data.description});

                //todo: Move this. It really shouldn't be in the toast function.
                const index = state.reminders.map((reminder) => reminder.id).indexOf(notification.data.id);
                commit(types.REMOVE_REMINDER, index);
                break;
            case 'DownloadNotification':
                // Do nothing, the Download widget will handle if/when to toast
                break;
            default:
                //todo: Some sort of generic toast?
        }
    },


    async deleteAllNotifications({commit, dispatch}) {
        try {
            let response = await apis.Notifications.deleteAllNotifications();
            commit("removeAllNotifications");
            return response.body.data.success;
        } catch(e) {
            console.error(e);
            const content = 'Error dismissing notifications: ' + ((e.response) ? e.response.body.message : e.message);
            const isDanger = true;
            dispatch('shared/toast/show', {content, isDanger}, {root:true});
            return false;
        }
    },
    async deleteNotification({commit, dispatch}, id) {
        try {
            let response = await apis.Notifications.deleteNotification({notification_id: id});
            commit("removeNotification", id);
            return response.body.data.success;
        } catch(e) {
            console.error(e);
            const content = 'Error dismissing notification: ' + ((e.response) ? e.response.body.message : e.message);
            const isDanger = true;
            dispatch('shared/toast/show', {content, isDanger}, {root:true});
            return false;
        }
    },
    async markAllNotificationsAsRead({commit, dispatch}) {
        try {
            let response = await apis.Notifications.markAllNotificationsRead();
            commit("markAllNotificationsAsRead", moment(response.body.data.id).format());
            return response.body.data.success;
        } catch(e) {
            console.error(e);
            const content = 'Error dismissing notifications: ' + ((e.response) ? e.response.body.message : e.message);
            const isDanger = true;
            dispatch('shared/toast/show', {content, isDanger}, {root:true});
            return false;
        }
    },
    async markNotificationAsRead({ commit }, notificationId) {
        let response = await apis.Notifications.markNotificationAsRead({notification_id: notificationId});
        if(response.body.data.success) {
            commit("updateNotification", {notificationId: notificationId, values: {read_at: moment().tz(moment.tz.guess()).format()}});
        }
    },

    updateNotificationStatusFromEvent({ commit }, event) {
        switch(event.updateType) {
            case UpdateTypes.ALL:
                switch(event.status) {
                    case Statuses.READ:
                        commit("markAllNotificationsAsRead", moment().tz(moment.tz.guess()).format());
                        break;
                    case Statuses.DELETED:
                        commit("removeAllNotifications");
                        break;
                    default:
                        console.warn("Unknown bulk status transition: ", event.status);
                }
                break;
            case UpdateTypes.SINGLE:
                switch(event.status) {
                    case Statuses.READ:
                        commit("updateNotification", {notificationId: event.id, values: {read_at: moment().tz(moment.tz.guess()).format()}});
                        break;
                    case Statuses.DELETED:
                        commit("removeNotification", event.id);
                        break;
                    default:
                        console.warn("Unknown bulk status transition: ", event.status);
                }
                break;
            default:
                console.warn("Unknown Notification Update Type: ", event.updateType);
        }
    },
    updateDownloadStatusFromEvent({ commit }, eventData) {
        commit("updateNotification", {notificationId: eventData.id, values: {data: eventData}});
    },
}
