import _ from 'lodash';
import { apis } from 'api/client';

export default {
    namespaced: true,
    state: {
        idToDropdown: {},
        nameToDropdown: {},
        modifiedDropdownIds: [],
        inRequest: false
    },
    mutations: {
        mapDropdowns(store, payload) {
            store.idToDropdown = {};
            payload.forEach(dropdown => {
                store.idToDropdown[dropdown.id] = dropdown;
            });
            store.nameToDropdown = {};
            payload.forEach(dropdown => {
                store.nameToDropdown[dropdown.name] = dropdown;
            });
        },
        updateDropdown(store, dropdown) {
            let clone = _.cloneDeep(store.idToDropdown);
            clone[dropdown.id] = dropdown;
            store.idToDropdown = clone;

            clone = _.cloneDeep(store.nameToDropdown);
            clone[dropdown.name] = dropdown;
            store.nameToDropdown = clone;

            if (!store.modifiedDropdownIds.includes(dropdown.id)) {
                store.modifiedDropdownIds.push(dropdown.id);
            }
        }
    },
    actions: {
        async getDropdowns({ state, commit }, params = {}) {
            if (state.inRequest === false) {
                state.inRequest = true;

                const response = await apis.Dropdowns.listDropdowns({per_page: -1, pagination_type: 'simple'});
                const dropdowns = response.body.data;
                commit('mapDropdowns', dropdowns);
                state.inRequest = false;
            }
        },
        async getDropdownsIfNotGotten({ getters, dispatch }, params = {}) {
            if (_.size(getters.dropdowns) === 0) {
                dispatch('getDropdowns', params);
            }
        },
        reorderDropdown({ getters, commit }, {childId, up, dropdownId}) {
            let dropdown = _.cloneDeep(getters.dropdownWhereId(dropdownId));
            const childrenLength = dropdown.children.length;
            if (childrenLength < 2) { return; }

            const methodIndex = dropdown.children.indexOf(childId);
            const notFound = methodIndex === -1;
            const firstAndUp = methodIndex === 0 && up;
            const lastAndDown = methodIndex === childrenLength - 1 && !up;
            if (notFound || firstAndUp || lastAndDown) { return; }

            if (up) {
                dropdown.children.splice(methodIndex - 1, 0, dropdown.children.splice(methodIndex, 1)[0]);
            } else {
                dropdown.children.splice(methodIndex + 1, 0, dropdown.children.splice(methodIndex, 1)[0]);
            }

            commit('updateDropdown', dropdown);
        },
        addChildToDropdown({getters, commit}, {childId, dropdownId}) {
            let dropdown = _.cloneDeep(getters.dropdownWhereId(dropdownId));
            if (dropdown.children.includes(childId)) { return; }
            dropdown.children.push(childId);

            commit('updateDropdown', dropdown);
        },
        removeChildFromDropdown({ getters, commit }, {childId, dropdownId}) {
            let dropdown = _.cloneDeep(getters.dropdownWhereId(dropdownId));
            dropdown.children = dropdown.children.filter(id => id != childId);

            commit('updateDropdown', dropdown);
        },
        async saveChangedDropdowns({ state, getters }) {
            return (await Promise.allSettled(
                state.modifiedDropdownIds.map(id => {
                    let dropdown = getters.dropdownWhereId(id);
                    return apis.Dropdowns.updateDropdownByName({
                        dropdown_name: dropdown.name
                    },{
                        requestBody: dropdown
                    });
                }))).every(result => result.value);
        }
    },
    getters: {
        dropdowns(state) {
            return state.nameToDropdown;
        },
        dropdownWhereId(state) {
            return (id) => {
                return state.idToDropdown[id];
            }
        },
        dropdownWhereName(state) {
            return (name) => {
                return state.nameToDropdown[name];
            }
        },
    }
};
