import SmartApi from 'SmartApi';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        statuses: [],
        billingStatuses: [],
        practitioners: [],
        orderers: [],
        shippingAddresses: [],
        costCenters: [],
        manufacturers: [],
        vendors: [],
        categories: [],
        messe:[],
    },
    mutations: {
        setStatuses(state, {statuses}) {
            state.statuses = statuses;
        },
        setBillingStatuses(state, {billingStatuses}) {
            state.billingStatuses = billingStatuses;
        },
        setPractitioners(state, {practitioners}) {
            state.practitioners = practitioners;
        },
        setOrderers(state, {orderers}) {
            state.orderers = orderers;
        },
        setShippingAddresses(state, {shippingAddresses}) {
            state.shippingAddresses = shippingAddresses;
        },
        setCostCenters(state, {costCenters}) {
            state.costCenters = costCenters;
        },
        setManufacturers(state, {manufacturers}) {
            state.manufacturers = manufacturers;
        },
        setVendors(state, {vendors}) {
            state.vendors = vendors;
        },
        setCategories(state, {categories}) {
            state.categories = categories;
        },
    },
    actions: {
        getStatuses({commit, rootGetters}) {
            const facilityId = rootGetters['shared/currentUser/facilityId'];
            SmartApi.get({
                            routeName: 'facilities.orders',
                            routeParams: {
                                boundFacility: facilityId,
                                distinct: 'status',
                            }
                        }).then((response) => {
                const responseStatuses = new Map(response.data.data.map((item) => [item.id, item]));

                const historyStatuses = rootGetters['shared/statuses/getStatusIdsByType']('isHistory');
                const statuses = [];
                historyStatuses.forEach((item, index, array) => {
                    const status = responseStatuses.get(item);
                    if (status !== undefined) {
                        statuses.push(status);
                    }
                });

                commit('setStatuses', { statuses });
            });
        },
        getStatusesIfNewOptionAvailable({state, dispatch}, statusIdsToCheck = []) {
            let statusNotFound = false;

            for (let statusId of statusIdsToCheck) {
                let found = _.find(state.statuses, (status) => status.id == statusId);
                if (found === undefined) {
                    statusNotFound = true;
                    break;
                }
            }

            if (statusNotFound) {
                dispatch('getStatuses');
            }
        },
        getBillingStatuses({state, commit, rootGetters}, params = {}) {
            const facilityId = rootGetters['shared/currentUser/facilityId'];
            SmartApi.get({
                            routeName: 'facilities.orders',
                            routeParams: {
                                boundFacility: facilityId,
                                distinct: 'billing_status',
                            }
                        }).then((response) => {
                const responseStatuses = new Map(response.data.data.map((item) => [item.id, item]));

                const lineBillingStatuses = rootGetters['shared/statuses/getStatusIdsByType']('isLineBilling');
                const billingStatuses = [];
                lineBillingStatuses.forEach((item, index, array) => {
                    const status = responseStatuses.get(item);
                    if (status !== undefined) {
                        billingStatuses.push(status);
                    }
                });
                commit('setBillingStatuses', { billingStatuses });
            });
        },
        getPractitioners({state, commit, rootGetters}, params = {}) {
            const facilityId = rootGetters['shared/currentUser/facilityId'];
            SmartApi.get({
                            routeName: 'facilities.orders',
                            routeParams: {
                                boundFacility: facilityId,
                                distinct: 'practitioner',
                            }
                        }).then((response) => {
                const practitioners = response.data.data;
                commit('setPractitioners', { practitioners });
            });
        },
        getOrderers({state, commit, rootGetters}, params = {}) {
            const facilityId = rootGetters['shared/currentUser/facilityId'];
            SmartApi.get({
                            routeName: 'facilities.orders',
                            routeParams: {
                                boundFacility: facilityId,
                                distinct: 'ordered_by',
                            }
                        }).then((response) => {
                const orderers = response.data.data;
                commit('setOrderers', { orderers });
            });
        },
        getShippingAddresses({state, commit, rootGetters}, params = {}) {
            const facilityId = rootGetters['shared/currentUser/facilityId'];
            SmartApi.get({
                            routeName: 'facilities.orders',
                            routeParams: {
                                boundFacility: facilityId,
                                distinct: 'shipping_address',
                            }
                        }).then((response) => {
                const shippingAddresses = response.data.data;
                commit('setShippingAddresses', { shippingAddresses });
            });
        },
        getCostCenters({state, commit, rootGetters}, params = {}) {
            const facilityId = rootGetters['shared/currentUser/facilityId'];
            SmartApi.get({
                            routeName: 'facilities.orders',
                            routeParams: {
                                boundFacility: facilityId,
                                distinct: 'cost_center',
                            }
                        }).then((response) => {
                const costCenters = response.data.data;
                commit('setCostCenters', { costCenters });
            });
        },
        getManufacturers({state, commit, rootGetters}, params = {}) {
            const facilityId = rootGetters['shared/currentUser/facilityId'];
            SmartApi.get({
                            routeName: 'facilities.orders',
                            routeParams: {
                                boundFacility: facilityId,
                                distinct: 'manufacturer',
                            }
                        }).then((response) => {
                const manufacturers = response.data.data;
                commit('setManufacturers', { manufacturers });
            });
        },
        getVendors({state, commit, rootGetters}, params = {}) {
            const facilityId = rootGetters['shared/currentUser/facilityId'];
            SmartApi.get({
                            routeName: 'facilities.orders',
                            routeParams: {
                                boundFacility: facilityId,
                                distinct: 'vendor',
                            }
                        }).then((response) => {
                const vendors = response.data.data;
                commit('setVendors', { vendors });
            });
        },
        getCategories({state, commit, rootGetters}, params = {}) {
            const facilityId = rootGetters['shared/currentUser/facilityId'];
            SmartApi.get({
                            routeName: 'facilities.orders',
                            routeParams: {
                                boundFacility: facilityId,
                                distinct: 'category',
                            }
                        }).then((response) => {
                let categories = response.data.data;
                categories = _.sortBy(categories, ['full_name']);
                commit('setCategories', { categories });
            });
        },
    }
}
