import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}, config = {}) {
        let url = params.id ? route('saved-orders.show', { id: params.id }) : route('saved-orders');
        return await axios.get(url, { params }, config);
    },
    async create(params = {}, config = {}) {
        let url = route('saved-orders.create');
        return await axios.post(url, params, config);
    },
    async update(params = {}, config = {}) {
        if (!params.id) {
            throw Error('An ID is required in order to update a Saved Order');
        }

        let url = route('saved-orders.edit', { id: params.id });
        delete params.id;
        return await axios.put(url, params, config);
    },
    async delete(params = {}, config = {}) {
        let url = route('saved-orders.destroy', { id: params.id }, config);
        return await axios.delete(url);
    },
};
