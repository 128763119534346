<template>
    <tooltip
        :placement="placement"
        :max-width="maxWidth"
        :min-width="minWidth"
        :smallButton="smallButton"
        :whiteSpace="whiteSpace"
        :mode="mode"
        class="emp-tooltip"
    >
        <div slot="outlet">
            <slot name="outlet">?</slot>
        </div>
        <div slot="tooltip">
            <slot name="tooltip"></slot>
        </div>
    </tooltip>
</template>

<script>
import Tooltip from 'component/shared/elements/Tooltip';

export default {
    components: {
        Tooltip,
    },
    props: {
        placement: {
            type: String,
            default: 'bottom',
        },
        maxWidth: {
            type: Number,
            default: 400,
        },
        minWidth: {
            type: Number,
            default: 400,
        },
        smallButton: {
            type: Boolean,
            default: false,
        },
        whiteSpace: {
            type: String,
            default: 'pre-wrap',
        },
    },
    computed: {
        mode() {
            return this.currentUserIsAdmin ? 'click' : 'hover';
        },
    },
};
</script>
