import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            morphTo: {
                pdfTextractorResponse: {
                    foreignKey: 'analyzable_id',
                    localField: 'analyzable',
                },
            },
            hasMany: {
                document: {
                    foreignKey: 'pdf_textractor_response_id',
                    localField: 'documents',
                },
            },
            morphMany: {
                ...auditsRelation('pdfTextractorResponse'),
            },
        },
    },
};
