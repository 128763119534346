import _ from 'lodash';
import { Times } from 'enums';
import Moment from 'moment';
import { mixVuexStoreOptions } from 'store/helpers.js';
import { getFriendlyName } from 'enumHelpers';

export default mixVuexStoreOptions(
    {},
    {
        namespaced: true,
        state: {
            previousRoute: {},
            momentTimeIds: [],
            timeInterval: null,
            currentTime: Moment(),
            timeIdLastChecked: Moment(),
            downloadConfirmationModalShown: false,
            downloadConfirmationModalIsActive: false,
            bugReportModalIsActive: false,
        },
        actions: {
            startTimeUpdateInterval({ commit, state, dispatch }) {
                commit('updateCurrentTime');

                state.timeInterval = setInterval(() => {
                    commit('updateCurrentTime');
                }, 1000 * 60);
            },
            stopTimeUpdateInterval({ commit, state }) {
                commit('stopTimeUpdateInterval');
            },
        },
        mutations: {
            setPageTitle(state, to) {
                document.title = to.meta.title ? `${to.meta.title} - Empire Medical` : 'Empire Medical';
            },
            setPreviousRoute(state, from) {
                state.previousRoute = {
                    name: from.name,
                    params: from.params,
                    query: from.query,
                };
            },
            updateCurrentTime(state) {
                state.currentTime = Moment();
            },
            stopTimeUpdateInterval(state) {
                clearInterval(state.timeInterval);
            },
            setMomentTimeIds(state) {
                state.momentTimeIds = Object.entries(Times).map(([key, id]) => {
                    const timeString = getFriendlyName(key);
                    return { moment: Moment(timeString, 'hh:mm A'), id };
                });
            },
            showDownloadConfirmationModal(state, payload = true) {
                state.downloadConfirmationModalIsActive = payload;
            },
            setDownloadConfirmationModalShown(state, payload = true) {
                state.downloadConfirmationModalShown = payload;
            },
            showBugReportModal(state, payload = true) {
                state.bugReportModalIsActive = payload;
            },
        },
    }
);
