export const ADD_REMINDER = 'ADD_REMINDER';
export const REMOVE_REMINDER = 'REMOVE_REMINDER';
export const SET_REMINDERS = 'SET_REMINDERS';
export const SET_INVENTORY_MANUFACTURERS = 'SET_INVENTORY_MANUFACTURERS';
export const SET_INVENTORY_ADDRESSES = 'SET_INVENTORY_ADDRESSES';
export const SET_MANUFACTURERS_WITH_PRODUCTS = 'SET_MANUFACTURERS_WITH_PRODUCTS';
export const SET_WHITELISTED_ADDRESSES = 'SET_WHITELISTED_ADDRESSES';
export const SET_WHITELISTED_USERS = 'SET_WHITELISTED_USERS';
export const SET_PRODUCT_TYPE_MANUFACTURERS = 'SET_PRODUCT_TYPE_MANUFACTURERS';
export const SET_SHOPPING_LIST_MANUFACTURERS = 'SET_SHOPPING_LIST_MANUFACTURERS';
