import Swagger from 'swagger-client';
import { getXSRFHeader } from 'helpers';
import _ from 'lodash';

Swagger.http.withCredentials = true;

const originalExecute = Swagger.prototype.execute;
Swagger.prototype.execute = function (options) {
    if (!options.server) {
        options.server = __API_SERVER_URL__;
    }
    return originalExecute.apply(this, [options]);
};

let client = null;
let apis = null;

const requestInterceptor = (request) => {
    const headers = Object.assign(
        {},
        request.headers,
        {
            'X-Requested-With': 'XMLHttpRequest',
        },
        getXSRFHeader()
    );
    if (request.body && !request.headers['Content-Type']) {
        headers['Content-Type'] = 'application/json';
    }
    request.headers = headers;
    return request;
};

export async function initializeClient() {
    const innerClient = await Swagger(__VERSION__ + '/spec.json', {
        requestInterceptor,
    });
    client = innerClient;
    apis = innerClient.apis;

    return innerClient;
}

export { client, apis, requestInterceptor };
