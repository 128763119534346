export default {
    mapper: {
        relations: {
            belongsToMany: {
                documentProcessingRequest: {
                    foreignKey: 'id',
                    localField: 'documentProcessingRequests',
                },
                emailProcessingRule: {
                    foreignKey: 'id',
                    localField: 'emailProcessingRules',
                },
            },
        },
    },
};
