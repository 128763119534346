// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import useCacheManager from 'use/cacheManager';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Shopping List Manufacturers',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Shipping Methods
 * @returns
 */
export default function useShoppingListManufacturers(config = {}) {
    const fetcher = async () => {
        const resp = await apis.ShoppingLists.listShoppingListManufacturers();
        return resp?.body || {};
    };

    const {
        data: shoppingListManufacturers,
        error,
        isValidating,
        mutate,
    } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    useCacheManager(mutate, metadata);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<Manufacturers[]>}
         */
        shoppingListManufacturers,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
