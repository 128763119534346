<template>
    <emp-component-modal :is-active="isActive" modal-class="inventory-form" @modal:close="onClose">
        <div slot="modal-header">A vendor or manufacturer is Blacklisted</div>
        <div slot="modal-body">
            <div class="columns is-multiline">
                <div class="column is-full">
                    <p
                        ><template v-if="isMultiple">A</template><template v-else>The</template> selected Vendor or
                        Manufacturer has been blacklisted. To continue, please enter the password below.</p
                    >
                </div>
                <div class="column is-full">
                    <emp-textbox
                        fieldClass="no-margin"
                        label="Blacklist Password:"
                        v-model="password"
                        name="vendor-blacklist-password"
                        @keyup.enter="onSubmit"
                        role="password"
                    />
                    <p class="help is-danger" v-if="error">{{ error }}</p>
                </div>
                <div class="level column is-full">
                    <div class="level-left">
                        <div class="level-item">
                            <emp-button role="submit" label="Submit" @click="onSubmit" />
                        </div>
                        <div class="level-item">
                            <emp-button role="cancel" label="Cancel" @click="onClose" buttonClass="is-danger" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </emp-component-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            error: '',
            password: '',
        };
    },
    methods: {
        ...mapActions('shared/modal', ['closeModal']),
        async onSubmit() {
            this.resetErrorMessage();

            if (!this.password.length) {
                this.error = 'Please enter a password.';
                return;
            }

            this.callback(this.password);

            this.onClose();
        },
        onClose() {
            this.password = '';
            this.closeModal();
            this.$emit('onClose');
        },
        resetErrorMessage() {
            this.error = '';
        },
    },
    computed: {
        ...mapState({
            modalData: (state) => state.shared.modal.data,
        }),
        isMultiple() {
            return _.get(this, 'modalData.isMultiple', false);
        },
        callback() {
            if (this.modalData) {
                return this.modalData.callback;
            }
        },
    },
};
</script>
