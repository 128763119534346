import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let { boundFacility } = params;
        let url = route('facilities.orders', { boundFacility });
        return await axios.get(url, { params });
    },
    async update(payload) {
        let url = route('facilities.orders.edit', { lineId: payload.id, facilityId: payload.facilityId });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async historyCSV(params = {}) {
        let url = route('orders.history.download-csv');
        return await axios.get(url, { params });
    },
};
