import _ from "lodash";
import {Statuses} from "enums";

export default {
    getMessages: (state) => state.messages,
    getMessage: (state) => (id) =>  state.messages[id],
    getInbox: (state) => (id) =>  state.inbox[id],
    getUnreadThreads: (state) => _.reduce(state.inbox, function(threads, message) {
        if (message.status_id === Statuses.UNREAD && !threads.includes(state.messages[message.id].data.thread)) {
            threads.push(state.messages[message.id].data.thread)
        }
        return threads;
    }, []),
    getUnreadThreadCount: (state, getters) => getters.getUnreadThreads.length,
    getUnreadMessageCount: (state) => _.reduce(state.inbox, function(sum, message) { return +(message.status_id === Statuses.UNREAD) + sum; }, 0),
}
