import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = params.id ? route('inventory-location.show', { id: params.id }) : route('inventory-location');
        delete params.id;
        return await axios.get(url, { params });
    },
    async delete(id) {
        let url = route('inventory-location.destroy', { id: id });
        return await axios.delete(url);
    },
    async update(id, payload) {
        let url = route('inventory-location.edit', { id: id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('inventory-location.create');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
};
