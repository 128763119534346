import { auditsRelation } from 'app/jsd/helpers';

export default {
    modelName: 'priceListException',
    mapper: {
        relations: {
            belongsTo: {
                product: {
                    foreignKey: 'product_id',
                    localField: 'product',
                },
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
            },
            morphMany: {
                ...auditsRelation('priceListException'),
            },
        },
    },
};
