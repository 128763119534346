// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Inventory Manufacturers Dropdowns',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Inventory Manufacturers
 * @returns
 */
export default function useInventoryManufacturersDropdowns(config = {}) {
    const fetcher = async () => {
        const resp = await apis.InventoryManufacturers.listInventoryManufacturers();
        return resp.body || [];
    };

    const {
        data: inventoryManufacturers,
        error,
        isValidating,
        mutate,
    } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<InventoryManufacturer[]>}
         */
        inventoryManufacturers,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
