export default {
    mapper: {
        relations: {
            belongsTo: {
                email: {
                    foreignKey: 'email_id',
                    localField: 'email',
                },
            },
        },
    },
};
