export default {
    mapper: {
        relations: {
            belongsTo: {
                line: {
                    foreignKey: 'line_id',
                    localField: 'line',
                },
                invoice: {
                    foreignKey: 'invoice_id',
                    localField: 'invoice',
                },
                user: {
                    foreignKey: 'practitioner_id',
                    localField: 'practitioner',
                },
            },
        },
    },
};
