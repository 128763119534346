<template>
    <empire-select
        v-bind="$attrs"
        :disabled="shippingAddresses.length == false || disabled"
        :fieldClass="fieldClass"
        :inputClass="[{ 'is-loading': shippingAddresses.length == false }, highlightClass]"
        :label="label"
        :labelClass="labelClass"
        :options="shippingAddresses"
        :placeholder="placeholder"
        valueFrom="id"
        :value="value"
        :name="name"
        :errors="errors"
        v-on="$listeners"
        emp-data="selectAddress"
        title="Select Shipping Address"
        data-testid="select-shipping-address"
        :groupBy="groupByStatus"
    />
</template>

<script>
import Partials from 'app/use/partials';
import { defineComponent, computed } from '@vue/composition-api';
import { hasInputProps, hasInput } from '@/has/input';
import useHighlightsAddress from 'use/highlightsAddress';
import EmpireSelect from 'component/shared/elements/Select';
import { all, conditionalFilter, equal, filterList, inArray } from '@/use/filters';
import { Statuses } from 'enums';
import { useGroupByStatus } from '@/use/groups';
import useCurrentUser from '@/use/currentUser';

export default defineComponent({
    name: 'SelectShippingAddress',
    components: {
        EmpireSelect,
    },
    props: {
        ...hasInputProps(),
        idWhitelist: {
            type: Array,
            default: () => [],
        },
        onlyActiveOptions: {
            type: Boolean,
            default: false,
        },
        facilityId: {
            type: [Number, String],
            required: false,
        },
    },
    setup(props) {
        const highlightsAddress = useHighlightsAddress(props);
        const groupByStatus = useGroupByStatus('status_id');
        const { currentUser } = useCurrentUser();
        const { shippingAddresses: shippingAddressesRaw } = Partials.useAddressDropdowns();

        const facilityId = computed(() => {
            return props.facilityId || currentUser.value.facilityId;
        });

        const filters = computed(() => {
            return filterList([
                conditionalFilter(inArray(props.idWhitelist, 'id'), props.idWhitelist.length),
                conditionalFilter(equal('status_id', Statuses.ACTIVE), props.onlyActiveOptions),
                conditionalFilter(equal('facility_id', facilityId.value), facilityId.value),
            ]);
        });

        const shippingAddresses = computed(() => {
            return shippingAddressesRaw.value.filter(all(filters.value));
        });

        return {
            ...hasInput(props),
            ...highlightsAddress,
            groupByStatus,
            shippingAddresses,
        };
    },
});
</script>
