<template>
    <transition name="slide">
        <div
            class="notification"
            v-if="isActive"
            :class="{
                'is-danger': isDanger,
                'is-success': isSuccess,
                'is-warning': isInfo,
            }"
        >
            <button class="delete" @click="close" v-if="persistant == false"></button>
            <span v-html="content"> </span>
        </div>
    </transition>
</template>

<script>
export default {
    methods: {
        close() {
            this.$store.dispatch('shared/toast/close');
        },
    },
    computed: {
        isActive() {
            return this.$store.state.shared.toast.isActive;
        },
        isSuccess() {
            return this.$store.state.shared.toast.isSuccess;
        },
        isDanger() {
            return this.$store.state.shared.toast.isDanger;
        },
        isInfo() {
            return this.$store.state.shared.toast.isInfo;
        },
        content() {
            return this.sanitizeHTML(this.$store.state.shared.toast.content);
        },
        persistant() {
            return this.$store.state.shared.toast.persistant;
        },
    },
};
</script>

<style lang="scss" scoped>
.notification {
    border-radius: 0px;
    bottom: 0px;
    font-size: 1.5rem;
    position: fixed;
    width: 100%;
    z-index: 50002;

    &.is-warning {
        color: #0d1321;
    }
}
.slide-active {
    animation: slide-up 0.4s;
}
.slide-leave-active {
    animation: slide-up 0.4s reverse;
}
@keyframes slide-up {
    0% {
        height: 0px;
        opacity: 0;
    }
    100% {
        height: auto;
        opacity: 1;
    }
}
</style>
