import FacilitiesApi from 'api/endpoints/admin/facilities';
import merge from 'deepmerge';
import {Statuses} from 'enums';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        dropdownData: [],
        fetchingDropdownData: false,
        moduleName: 'Facilities',
        lastRequestedDropdownParams: null,
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
        },
        updateFacility(store, payload) {
            store.dropdownData = store.dropdownData.map((facility) => {
                if (payload.id == facility.id) {
                    facility = payload;
                }

                return facility;
            });
        },
        pushRequestedDropdownParams(store, payload) {
            store.lastRequestedDropdownParams = {...payload};
        }
    },
    getters: {
        facilityById(state) {
            return (facilityId) => state.dropdownData.find((facility) => facility.id == facilityId);
        },
        nameFromId(state, getters) {
            return (facilityId) => {
                let name = null;
                let facility = getters.facilityById(facilityId);

                if (facility && facility.name) {
                    name = facility.name;
                }

                return name;
            };
        },
        exceptionsForId(state, getters) {
            return (facilityId) => {
                let exceptions = null;
                let facility = getters.facilityById(facilityId);

                if (facility && facility.priceExceptions) {
                    exceptions = facility.priceExceptions;
                }

                return exceptions;
            }
        },
        activeFacilities(state, getters) {
            return state.dropdownData.filter((facility) => facility.statusId === Statuses.ACTIVE);
        }
    },
    actions: {
        getFacilityById({state}, id) {
            return state.dropdownData.find((facility) => facility.id == id);
        },
        async getDropdownData({ state, commit}, params = {}) {
            if (state.fetchingDropdownData === false) {
                params = merge(
                    params,
                    {
                        sort: ['name'],
                    }
                );

                if (_.isEqual({...state.lastRequestedDropdownParams}, params)) {;
                    return;
                }
                commit('pushRequestedDropdownParams', {...params});

                state.fetchingDropdownData = true;
                try{
                    let response = await FacilitiesApi.dropdownData(params);
                    commit('setDropdownData', response.data.data);
                } catch(e) {
                    console.error(e);
                } finally {
                    state.fetchingDropdownData = false;
                }

            }

            return true;
        },
        async updateInventoryLocation({state, dispatch, commit}, payload) {
            let facility = await dispatch('getFacilityById', payload.facilityId);

            if (facility && facility.inventoryLocations) {
                let locationIndex = facility.inventoryLocations.findIndex((facilityInventoryLocation) => facilityInventoryLocation.id == payload.id);

                if (locationIndex) {
                    facility.inventoryLocations.splice(locationIndex, 1, payload);
                } else {
                    facility.inventoryLocations.push(payload);
                }

                commit('updateFacility', facility);
            }
        },
        async removeInventoryLocation({state, dispatch, commit}, payload) {
            let facility = await dispatch('getFacilityById', payload.facilityId)

            if (facility && facility.inventoryLocations) {
                facility.inventoryLocations = facility.inventoryLocations.filter((inventoryLocation) => inventoryLocation.id != payload.id);

                commit('updateFacility', facility);
            }
        },
    },
}
