<template>
    <emp-component-modal :is-active="isActive" content-class="prewritten-notes-modal" @modal:close="onClose">
        <div slot="modal-header">Add/Edit Notes</div>
        <div slot="modal-body">
            <div class="tile is-full is-parent">
                <div class="tile is-child box">
                    <div class="has-text-centered">
                        Add or edit Order Form notes below. Click and drag notes to rearrange the list.
                    </div>
                </div>
            </div>
            <prewritten-note-list
                ref="prewrittenNoteList"
                class="note-list"
                @prewrittenNoteList:unsavedChanges="markUnsavedChanges($event)"
                @prewrittenNoteList:saved="markUnsavedChanges(false)"
            />
            <div class="level">
                <div class="level-item prewritten-notes-modal-buttons">
                    <emp-button label="Save" @click="onSave" buttonClass="is-primary" />
                    <emp-confirmation-button label="Cancel" @click="onCancel" buttonClass="is-danger" />
                </div>
            </div>
        </div>
    </emp-component-modal>
</template>

<script>
import { mapActions } from 'vuex';
import PrewrittenNoteList from 'component/shared/PrewrittenNoteList.vue';

export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            unsavedChanges: false,
        };
    },
    components: {
        PrewrittenNoteList,
    },
    methods: {
        ...mapActions('shared/modal', ['closeModal']),
        markUnsavedChanges(value = true) {
            this.unsavedChanges = value;
        },
        onClose() {
            let answer = true;

            if (this.unsavedChanges) {
                answer = window.confirm('Your note changes have not been saved. Do you want to close this form?');
            }

            if (answer) {
                this.onCancel();
            }
        },
        async onSave() {
            let success = await this.$refs.prewrittenNoteList.onSave();
            if (success) {
                this.$store.dispatch('shared/prewrittenNotes/get', {
                    facility_id: this.currentUserFacilityId,
                });
                this.onClose();
            }
        },
        onCancel() {
            this.markUnsavedChanges(false);
            this.closeModal();
            this.$emit('onClose');
        },
    },
    watch: {
        isActive: {
            handler(newValue) {
                if (newValue) {
                    this.$refs.prewrittenNoteList.refreshData();
                }
            },
        },
    },
};
</script>

<style lang="scss">
.prewritten-notes-modal {
    width: 60vw;
}
.prewritten-notes-modal-buttons button {
    margin: 0 2rem;
}
</style>
