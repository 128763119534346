import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
                role: {
                    foreignKey: 'role_id',
                    localField: 'role',
                },
            },
            belongsToMany: {
                user: {
                    foreignKey: 'id',
                    localField: 'whitelistedUsers',
                },
                address: {
                    foreignKey: 'id',
                    localField: 'whitelistedAddresses',
                },
                scope: {
                    foreignKey: 'id',
                    localField: 'scopes',
                },
                product: {
                    foreignKey: 'id',
                    localField: 'favoritedProducts',
                },
            },
            hasMany: {
                /*savedOrder: {
                    foreignKey: "user_id",
                    localField: "savedOrders"
                },*/
                userPreference: {
                    foreignKey: 'user_id',
                    localField: 'preferences',
                },
                userNotificationPreference: {
                    foreignKey: 'user_id',
                    localField: 'notificationPreferences',
                },
                line: {
                    foreignKey: 'practitioner_id',
                    localField: 'isPractitionerOf',
                },
                reminder: {
                    foreignKey: 'user_id',
                    localField: 'reminders',
                },
            },
            hasOne: {
                invitation: {
                    foreignKey: 'user_id',
                    localField: 'invitation',
                },
            },
            morphMany: {
                notification: {
                    foreignKey: 'notifiable_id',
                    foreignType: 'notifiable_type',
                    relatedType: 'user',
                    localField: 'notifications',
                },
                ...auditsRelation('user'),
            },
        },
    },
};
