<template>
    <div class="checkbox-cluster">
        <div class="checkbox-cluster-buttons" v-if="!disabled">
            <empire-button label="All" buttonClass="is-primary" @click="onCheckAll" />
            <empire-button label="None" buttonClass="is-danger" @click="onUncheckAll" />
        </div>
        <div class="columns is-multiline is-vertical">
            <checkbox
                v-for="(item, index) in items"
                :fieldClass="fieldClass"
                :label="item[nameFrom]"
                :title="item[titleFrom]"
                :value="inValuesList(index)"
                @input="onChecked($event, index)"
                :key="getKey(item)"
                :disabled="disabled"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.checkbox-cluster-buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;

    & > button {
        margin-right: 1rem;
    }
}
</style>

<script>
import _ from 'lodash';
const Button = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/Button');
const Checkbox = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/Checkbox');

export default {
    components: {
        'empire-button': Button,
        Checkbox,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        fieldClass: {
            type: String,
            default: 'column is-one-third',
        },
        nameFrom: {
            type: String,
            default: 'name',
        },
        valueFrom: {
            type: String,
            default: 'id',
        },
        titleFrom: {
            type: String,
            default: 'title',
        },
        value: {
            type: Array,
            required: true,
        },
        predicate: {
            type: Function,
            default: (v, i) => v.id == i.id,
        },
        keyFrom: {
            type: [Array, String],
            default: 'id',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onCheckAll() {
            this.$emit('input', _.clone(this.items));
        },
        onUncheckAll() {
            this.$emit('input', []);
        },
        onChecked(event, index) {
            let checked = this.value;
            let item = this.getItemForIndex(index);

            if (event) {
                checked.push(item);
            } else {
                _.remove(checked, (v) => v.id == item.id);
            }

            this.$emit('input', checked);
        },
        inValuesList(index) {
            let item = this.getItemForIndex(index);
            let inList = false;

            if (item) {
                inList = this.value.find((v) => this.predicate(v, item)) !== undefined;
            }

            return inList;
        },
        getItemForIndex(index) {
            return this.items[index] ? _.clone(this.items[index]) : null;
        },
        getKey(item) {
            if (this.keyFrom instanceof Array) {
                return this.keyFrom.reduce((previous, current) => {
                    if (item.hasOwnProperty(current)) {
                        previous += item[current];
                    }
                    return previous;
                }, '');
            }

            return item[this.keyFrom];
        },
    },
};
</script>
