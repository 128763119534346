import RemindersApi from "api/endpoints/reminders";
import * as types from "store/shared/mutation_types";

export default {
    async fetchReminders({ commit }) {
        let reminders = [];

        RemindersApi.get().then(({ data }) => {
            if (data.data) {
                reminders = data.data;
            }
            commit(types.SET_REMINDERS, reminders);
        });
    },
    async deleteReminder({ commit, state }, index) {
        const reminder = state.reminders[index];
        RemindersApi.delete(reminder.id).then(() => commit(types.REMOVE_REMINDER, index));
    },
    async createReminder({ commit, dispatch }, reminder) {
        RemindersApi.create(
            reminder
        ).then((response) => {
            return RemindersApi.get({ id: response.recordId });
        }).then(({ data }) => {
            const reminder = data.data;
            dispatch('addReminder', reminder);
        });
    },
    async updateReminder({ commit, state }, { reminder, index }) {
        RemindersApi.update(
            reminder
        ).then((response) => {
            return RemindersApi.get({ id: response.recordId })
        }).then(({ data }) => {
            Object.assign(state.reminders[index], data.data);
        });
    },
    addReminder({ commit }, reminder) {
        commit(types.ADD_REMINDER, reminder);
    },
}
