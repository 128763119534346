// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import useCacheManager from 'use/cacheManager';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Shipping Services',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Shipping Services
 * @returns
 */
export default function useShippingServices(config = {}) {
    const fetcher = async () => {
        const resp = await apis.ShippingServices.listShippingServices({
            pagination_type: 'simple',
            per_page: -1,
            partials: ['dropdown'],
        });

        return resp.body.data || [];
    };

    const { data: shippingServices, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    useCacheManager(mutate, metadata);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<ShippingService[]>}
         */
        shippingServices,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
