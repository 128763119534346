<template>
    <select-vendor v-bind="{ ...$attrs, ...props }" :id-whitelist="whitelist" @input="$emit('input', $event)" />
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api';
import { hasIdWhitelistProp, hasInput, hasInputProps } from '@/has';
import SelectVendor from 'component/shared/elements/SelectVendor.vue';
import partials from '@/use/partials';
import { all, conditionalFilter, filterList, inArray, truthy, any } from '@/use/filters';
import { ProductTypes } from '@/enums';
import _ from 'lodash';

export default defineComponent({
    components: {
        SelectVendor,
    },
    props: {
        ...hasInputProps({
            name: 'manufacturer_id',
            label: 'Manufacturer',
        }),
        ...hasIdWhitelistProp(),
        onlyManufacturers: {
            type: Boolean,
        },
        productType: {
            type: [Number, Array],
            validator(value) {
                let acceptableTypes = [ProductTypes.MANUAL, ProductTypes.SEARCH, ProductTypes.PRICE_LIST];
                if (_.isArray(value)) {
                    return value.reduce((acc, val) => {
                        return acc && acceptableTypes.includes(val);
                    }, true);
                }
                return acceptableTypes.includes(value);
            },
        },
    },
    setup(props) {
        const { manufacturers } = partials.useManufacturers();

        const filters = computed(() => {
            return filterList([
                conditionalFilter(inArray(props.idWhitelist, 'manufacturer_id'), props.idWhitelist.length),
                conditionalFilter(
                    any(
                        filterList([
                            conditionalFilter(
                                truthy('has_manual_products'),
                                ProductTypes.MANUAL == props.productType ||
                                    _.includes(props.productType, ProductTypes.MANUAL)
                            ),
                            conditionalFilter(
                                truthy('has_search_products'),
                                ProductTypes.SEARCH == props.productType ||
                                    _.includes(props.productType, ProductTypes.SEARCH)
                            ),
                            conditionalFilter(
                                truthy('has_price_list_products'),
                                ProductTypes.PRICE_LIST == props.productType ||
                                    _.includes(props.productType, ProductTypes.PRICE_LIST)
                            ),
                        ])
                    ),
                    props.productType
                ),
            ]);
        });

        const whitelist = computed(() => {
            if (!props.onlyManufacturers && !props.productType) {
                return props.idWhitelist;
            }

            return Object.values(manufacturers.value)
                .filter(all(filters.value))
                .map((m) => m.manufacturer_id);
        });

        return {
            ...hasInput(props),
            whitelist,
            manufacturers,
            props,
        };
    },
});
</script>
