import state from "./state.js";
import actions from "./actions.js";
import mutations from "./mutations.js";
import * as actionTypes from "./action_types.js";
import * as mutationTypes from "./mutation_types.js";

export { actionTypes, mutationTypes };

export default {
    state,
    actions,
    mutations,
    namespaced: true
};
