import state from './state';
import actions from './actions';
import * as action_types from './action_types';
import _ from 'lodash';
import { TABLE_STORE } from 'store/shared/mixins';

export { action_types };
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    state,
    actions,
    namespaced: true,
});
