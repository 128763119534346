export function makeGuard(router) {
    return async (to, from, next) => {
        if (!router.app.$store.state.shared.auth.hasLoadedOnce) {
            await router.app.$store.dispatch('shared/auth/CHECK_AUTH');
        }
        if (to.matched.some((record) => record.meta.adminOnly)) {
            if (router.app.currentUserIsAdmin) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    };
}

function apply(router) {
    router.beforeEach(makeGuard(router));
}

export default {
    apply,
};
