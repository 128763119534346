<template>
    <emp-component-modal @modal:close="closeModal" :isActive="isActive">
        <template v-if="isActive">
            <div slot="modal-header"> Are you sure? </div>
            <div slot="modal-body">
                <p class="copy">
                    {{ categoryName }} are typically repair only items (not warranty replaceable).
                    <br />
                    <br />
                    If we order a replacement it will likely be non-returnable.
                    <br />
                    <br />
                    Double check with {{ vendorName }}. <br /><br />
                    Are you sure you want to open this line?
                </p>
                <div class="level">
                    <div class="level-left"></div>
                    <div class="level-right">
                        <div class="level-item" v-if="confirmedCallback">
                            <e-button label="Confirm" @click="onConfirmed" buttonClass="is-primary" />
                        </div>
                        <div class="level-item">
                            <e-button label="Cancel" buttonClass="is-danger" @click="closeModal" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </emp-component-modal>
</template>

<script>
const Button = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/Button.vue');
import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions } from 'vuex';

export default defineComponent({
    name: 'WarrantyReturnConfirmationModal',
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        'e-button': Button,
    },
    computed: {
        ...mapState({
            modalData: (state) => state.shared.modal.data,
        }),
        title() {
            return _.get(this.modalData, 'title', 'Please Confirm');
        },
        categoryName() {
            return _.get(this.modalData, 'categoryName');
        },
        vendorName() {
            return _.get(this.modalData, 'vendorName');
        },
        confirmedCallback() {
            return _.get(this.modalData, 'onConfirmed');
        },
    },
    methods: {
        ...mapActions({
            closeModal: 'shared/modal/closeModal',
        }),
        onConfirmed() {
            this.confirmedCallback();
            this.closeModal();
        },
    },
});
</script>
