import _ from 'lodash';
import SystemSettingsApi from 'api/endpoints/admin/systemSettings';
import moment from 'moment-timezone';
import SmartApi from 'SmartApi';

export default {
    namespaced: true,
    state: {
        data: [],
        promotions: []
    },
    mutations: {
        setData(state, payload) {
            state.data = payload;
        },
        setPromotions(state, payload) {
            state.promotions = payload;
        }
    },
    getters: {
        getSetting: (state) => (setting) => {
            return state.data.find(datum => datum.name === setting) || {};
        },
        billCuttoffDate: (state, getters) => {
            return moment(getters.getSetting('BILL_CLOSING_DATE').value, moment.ISO_8601);
        }
    },
    actions: {
        async get({ state, commit }) {
            let response = await SystemSettingsApi.get();
            commit('setData', response.data.data);
        },
        async getPromotions({ commit }) {
            let response = await SmartApi.get({
                routeName: 'promotions'
            });

            commit('setPromotions', response.data.data);
        },
    }
}

