export default function hasOptionProps() {
    return {
        options: {
            type: Array,
            required: true,
            default: () => [],
        },
        nameFrom: {
            type: String,
            default: 'name',
        },
        valueFrom: {
            type: String,
            default: 'value',
        },
        placeholder: {
            type: [String, Boolean],
            default: '',
        },
    };
}
