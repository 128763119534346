<template>
    <empire-select
        :disabled="statuses.length == false || disabled"
        :inputClass="[inputClass, { 'is-loading': statuses.length == false }]"
        :options="statuses"
        valueFrom="id"
        :value="value"
        @input="$emit('input', $event)"
        v-bind="$attrs"
    />
</template>

<script>
const Select = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/Select');
import { computed, defineComponent } from '@vue/composition-api';
import { useStore } from 'use/store';

export default defineComponent({
    components: {
        'empire-select': Select,
    },
    props: {
        statusType: {
            type: [Array, String],
            default: '',
        },
        value: {
            type: [String, Number, Array, Object, Boolean],
            default: null,
        },
        inputClass: {
            type: [String, Array, Object],
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const store = useStore();
        const statuses = computed(() => {
            return store.getters['shared/statuses/getStatusesWhereTypeSorted'](props.statusType);
        });
        return {
            statuses,
        };
    },
});
</script>
