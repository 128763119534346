// @ts-check
import { reactive, watch } from '@vue/composition-api';
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import VendorApi from 'api/endpoints/vendors';
// TODO: Fix paths so that a relative path is not required.
import store from 'store';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Vendors',
    uuid: getUID(),
};

/**
 * Setup data synchronization for Vendor "dropdowns."
 * @returns
 */
export default function useVendorDropdowns(config = {}) {
    const fetcher = async () => {
        const resp = await VendorApi.dropdownData({ sort: ['name'] }, { hideLoader: true });
        return resp.data.data;
    };

    const { data: vendors, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    if (!vendors.value) {
        vendors.value = [];
    }

    // TODO: Remove this once components rely on swrv for vendor dropdowns instead of the Veux store.
    watch(vendors, (newVendors) => {
        if (newVendors) {
            store.commit('shared/vendors/setDropdownData', newVendors);
        }
    });

    return {
        /**
         * @type {import('@vue/composition-api').Ref<Vendor[]>}
         */
        vendors,
        error,
        isValidating,
        mutate,
    };
}
