import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            morphToMany: {
                bill: {
                    foreignKey: 'bills_id',
                    localField: 'bills',
                },
                emailAttachment: {
                    foreignKey: 'email_attachment_id',
                    localField: 'emailAttachment',
                },
                purchaseOrder: {
                    foreignKey: 'purchase_orders_id',
                    localField: 'purchaseOrders',
                },
                documentProcessingRequest: {
                    foreignKey: 'document_processing_requests_id',
                    localField: 'documentProcessingRequests',
                },
            },
            hasMany: {
                extraction: {
                    foreignKey: 'document_id',
                    localField: 'extractions',
                },
                documentable: {
                    foreignKey: 'document_id',
                    localField: 'documentables',
                },
            },
            belongsToMany: {
                textractResponse: {
                    foreignKey: 'id',
                    localField: 'textractResponses',
                },
            },
            belongsTo: {
                pdfTextractorResponse: {
                    foreignKey: 'pdf_textractor_response_id',
                    localField: 'pdfTextractorResponse',
                },
            },
            morphTo: {
                document: {
                    foreignKey: 'source_id',
                    localField: 'source',
                },
            },
            morphMany: {
                ...auditsRelation('document'),
            },
        },
    },
};
