import FacilitiesApi from 'api/endpoints/facility/facilities';
import VendorApi from 'api/endpoints/vendors';
import ProductApi from 'api/endpoints/products';
import * as types from './mutation_types';
import BugsnagIgnoredError from 'errors/bugsnagIgnoredError';
import { apis } from 'api/client';

export default {
    getWindowDimensions({ state }) {
        let height = document.documentElement.clientHeight;
        let width = document.documentElement.clientWidth;
        state.windowDimensions = { height, width };
    },
    async fetchInventoryManufacturers({ commit, rootGetters }, facilityId) {
        let manufacturers = [];

        //TODO: Change to revamp, possibly.
        if (rootGetters['shared/currentUser/isFacility']) {
            facilityId = facilityId || rootGetters['shared/currentUser/facilityId'];
        }

        if (facilityId) {
            const response = await apis.Vendors.listVendors({
                sort: ['name'],
                facility_id: facilityId,
                is_manufacturer: true,
                distinct: 'inventory_manufacturer',
                per_page: -1,
            });
            response.body.data.forEach((manufacturer) => manufacturers.push(manufacturer));
            commit(types.SET_INVENTORY_MANUFACTURERS, manufacturers);
        }
    },
    async fetchInventoryAddresses({ commit, rootGetters }, facilityId) {
        let addresses = [];

        //TODO: Change to revamp, possibly.
        if (rootGetters['shared/currentUser/isFacility']) {
            facilityId = facilityId || rootGetters['shared/currentUser/facilityId'];
        }

        if (facilityId) {
            const response = await apis.Addresses.listAddresses({
                sort: ['name'],
                per_page: -1,
                in_facility_inventory_id: facilityId,
            });
            response.body.data.forEach((address) => addresses.push(address));
            commit(types.SET_INVENTORY_ADDRESSES, addresses);
        }
    },
    //todo: Remove entirely with refactoring to V2 and composables
    async fetchManufacturersWithProduct({ commit, getters }) {
        let manufacturers = [];
        const response = await VendorApi.get({
            sort: ['name'],
            manufacturers_with_manual_or_price_list_product: true,
        });
        response.data.data.forEach((manufacturer) => manufacturers.push(manufacturer));
        commit(types.SET_MANUFACTURERS_WITH_PRODUCTS, manufacturers);
    },
    async fetchWhitelistsForUser({ commit, rootGetters }) {
        const facilityId = rootGetters['shared/currentUser/facilityId'];
        const userId = rootGetters['shared/currentUser/user'].id;

        if (!facilityId) {
            throw new BugsnagIgnoredError('This function only works if the User is logged in as a Facility.');
        }

        const addressResponse = await apis.Users.listWhitelistedItemsForUser({
            user_id: userId,
            whitelist_type: 'address',
        });
        commit(types.SET_WHITELISTED_ADDRESSES, addressResponse.body.data);

        const userResponse = await apis.Users.listWhitelistedItemsForUser({ user_id: userId, whitelist_type: 'user' });
        commit(types.SET_WHITELISTED_USERS, userResponse.body.data);
    },
    //todo: Remove entirely with refactoring to V2 and composables
    async fetchManufacturersForProductType(context, { typeId }) {
        const manufacturers = await ProductApi.manufacturersForType(typeId);
        context.commit(types.SET_PRODUCT_TYPE_MANUFACTURERS, {
            typeId,
            manufacturers,
        });
    },
    //todo: Remove entirely after updating
    async fetchShoppingListManufacturers({ commit, rootGetters }, facilityId) {
        if (rootGetters['shared/currentUser/isFacility']) {
            facilityId = facilityId || rootGetters['shared/currentUser/facilityId'];
        }

        if (facilityId) {
            const response = await apis.Vendors.listVendors({
                is_manufacturer: 1,
                facility_id: facilityId,
                distinct: 'shopping_list_manufacturer',
                per_page: -1,
                pagination_type: 'simple',
            });

            commit(types.SET_SHOPPING_LIST_MANUFACTURERS, response.body.data);
        }
    },
};
