<template
    ><!--
--><div
        ><!--
    --><template v-if="vendor && shippingNotes.length"
            ><!--
        --><strong>Shipping:</strong><br /><!--
        --><emp-tooltip-notes :notes="shippingNotes" /><!--
        --><br /><!--
        --><br /><!--
    --></template
        ><!--
    --><template v-if="vendor && purchasingNotes.length"
            ><!--
        --><strong>Buyer:</strong><br /><!--
        --><emp-tooltip-notes :notes="purchasingNotes" /><!--
        --><br /><!--
        --><br /><!--
    --></template
        ><!--
    --><template v-if="showCutoffs"
            ><!--
        --><strong>Cutoffs:</strong><br /><!--
        -->Order By: {{ orderByCutoff }}<br /><!--
        -->Hard Cutoff: {{ hardCutoff }}<br /><!--
        --><template v-if="vendorWarehouseCutoffs.length"
                ><!--
            --><template v-for="(warehouseCutoff, index) in vendorWarehouseCutoffs"
                    ><!--
                -->{{ warehouseCutoff.label }} Warehouse Cutoff: {{ warehouseCutoff.value
                    }}<br :key="`vendor_warehouse_${index}`" /><!--
            --></template
                ><!--
        --></template
            ><!--
        -->SPS Cutoff: {{ spsCutoff }}<br /><!--
        --><br /><!--
        --><br /><!--
    --></template
        ><!--
    --><template v-if="vendor && generalNotes.length"
            ><!--
        --><strong>General:</strong><br /><!--
        --><emp-tooltip-notes :notes="generalNotes" /><!--
        --><br /><!--
        --><br /><!--
    --></template
        ><!--
    --><template v-if="vendor && billingNotes.length"
            ><!--
        --><strong>Billing:</strong><br /><!--
        --><emp-tooltip-notes :notes="billingNotes" /><!--
        --><br /><!--
        --><br /><!--
    --></template
        ><!--
    --><template v-if="vendor && accountsPayableNotes.length"
            ><!--
        --><strong>A/P:</strong><br /><!--
        --><emp-tooltip-notes :notes="accountsPayableNotes" /><!--
        --><br /><!--
        --><br /><!--
    --></template
        ><!--
--></div
    >
</template>

<script>
import _ from "lodash";
import { defineComponent, ref, toRefs, reactive, watch } from '@vue/composition-api';
import useNotes from 'use/notes';
import useVendorWarehouses from 'use/vendorWarehouses';
// TODO: Change path names to not conflict with builtins
import { NOTEABLE_TYPES } from '../../constants';

export default defineComponent({
    setup(props) {
        // TODO: Change prop from vendor to vendorId and change this to fetch the Vendor.
        const noteable_type = ref(NOTEABLE_TYPES.VENDOR);
        let { vendor } = toRefs(props);
        reactive(vendor);
        const noteable_id = ref(vendor.id);
        watch(
            vendor,
            (newVendor, oldVendor) => {
                noteable_id.value = newVendor.id;
            },
            {
                immediate: true
            }
        );
        const { notes } = useNotes({ noteable_type, noteable_id });
        const { warehouses: vendorWarehouses } = useVendorWarehouses({vendor_id: noteable_id});
        return {
            notes,
            vendorWarehouses
        }
    },
    props: {
        vendor: Object,
        showCutoffs: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        vendorWarehouseCutoffs() {
            return this.vendorWarehouses.map((warehouse) => {
                return {
                    label: warehouse.name,
                    value: warehouse.cutoffTimeValue,
                };
            });
        },
        generalNotes() {
            return this.notes.filter((note) => note.type === 'general');
        },
        shippingNotes() {
            return this.notes.filter((note) => note.type === 'shipping');
        },
        purchasingNotes() {
            return this.notes.filter((note) => note.type === 'purchasing');
        },
        accountsPayableNotes() {
            return this.notes.filter((note) => note.type === 'accounts_payable');
        },
        billingNotes() {
            return this.notes.filter((note) => note.type === 'billing');
        },
        spsCutoff() {
            return _.get(this.vendor, 'spsCutoffTimeValue', 'Unset');
        },
        orderByCutoff() {
            return _.get(this.vendor, 'cutoffTimeValue', 'Unset');
        },
        hardCutoff() {
            return _.get(this.vendor, 'hardCutoffTimeValue', 'Unset');
        },
    },
});
</script>

<style lang="scss" scoped>
strong {
    font-weight: bold;
    color: #fff;
}
</style>
