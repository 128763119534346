import Vue from 'vue';
import Vuex from 'vuex';

import shared from './shared';
import admin from './admin';
import facility from './facility';
import revamp from './revamp';

const actions = Object.assign({}, admin.actions, facility.actions);
const getters = Object.assign({}, admin.getters, facility.getters);
const mutations = Object.assign({}, admin.mutations, facility.mutations);
const state = Object.assign({}, admin.state, facility.state);
const modules = Object.assign({}, admin.modules, facility.modules);

// Vue.use(Vuex) must be called before creating a store instance.
Vue.use(Vuex);
export default new Vuex.Store({
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
    modules: {
        revamp,
        shared,
        ...modules,
    },
});
