export default class AxiosTransport {
    constructor(instance) {
        this.instance = instance;
    }
    async get(url, { config }) {
        return await this.instance.get(url, config);
    }
    async delete(url, { config }) {
        return await this.instance.delete(url, config);
    }
    async head(url, { config }) {
        return await this.instance.head(url, config);
    }
    async options(url, { config }) {
        return await this.instance.head(url, config);
    }
    async post(url, { data, config }) {
        return await this.instance.post(url, data, config);
    }
    async put(url, { data, config }) {
        return await this.instance.put(url, data, config);
    }
    async patch(url, { data, config }) {
        return await this.instance.patch(url, data, config);
    }
}
