<template>
    <empire-select
        v-bind="$attrs"
        :disabled="users.length == false || disabled"
        :inputClass="{ 'is-loading': users.length == false }"
        :label="label"
        :placeholder="placeholder"
        :options="users"
        valueFrom="id"
        @input="$emit('input', $event)"
        :value="value"
        :groupBy="groupByIsPractitionerAndStatus"
        :groupSort="groupOrder"
    />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
import { useGroupByIsPractitionerAndStatus, groupByIsPractitionerAndStatusOrder } from '@/use/groups';
import { useStore } from 'use/store';
import EmpireSelect from 'component/shared/elements/Select';

export default defineComponent({
    components: {
        EmpireSelect,
    },
    props: {
        label: {
            type: String,
            default: 'Practitioner',
        },
        facilityId: {
            type: [Number, String],
            required: true,
        },
        placeholder: {
            type: [String, Boolean],
            default: '',
        },
        value: {
            type: [Number, Object, String],
            default: null,
        },
        disabled: {
            type: [String, Boolean],
            default: false,
        },
    },
    setup(props) {
        const groupByIsPractitionerAndStatus = useGroupByIsPractitionerAndStatus('statusId', 'isPractitioner');
        const groupOrder = groupByIsPractitionerAndStatusOrder();
        const store = useStore();

        //todo: Replace with v2 use statement
        const users = computed(() => {
            return store.state.shared.users.users.filter((user) => user.facilityId == props.facilityId);
        });

        return {
            groupByIsPractitionerAndStatus,
            users,
            groupOrder,
        };
    },
});
</script>
