// @ts-check
import Vue from 'vue';
import axios from 'axios';
import { extend } from 'vee-validate/dist/vee-validate.full';
import { applyGuards } from '../guards';
import Config from './baseConfig';

/**
 * Configure Axios, and Vue from a configuration object.
 * @param {Config} config
 * @param {import("vue").VueConstructor} vue
 */
function fromConfig(config, vue = Vue) {
    if (config.axios) {
        for (const key in config.axios) {
            if (Object.hasOwnProperty.call(config.axios, key)) {
                axios.defaults[key] = config.axios[key];
            }
        }
    }
    if (config.filters) {
        for (const id in config.filters) {
            if (Object.hasOwnProperty.call(config.filters, id)) {
                const definition = config.filters[id];
                vue.filter(id, definition);
            }
        }
    }
    if (config.mixins) {
        vue.mixin(config.mixins);
    }
    if (config.components) {
        for (const id in config.components) {
            if (Object.hasOwnProperty.call(config.components, id)) {
                const definition = config.components[id];
                vue.component(id, definition);
            }
        }
    }
    if (config.validationRules) {
        for (const id in config.validationRules) {
            if (Object.hasOwnProperty.call(config.validationRules, id)) {
                const schema = config.validationRules[id];
                extend(id, schema);
            }
        }
    }
    if (Array.isArray(config.plugins)) {
        config.plugins.forEach(({ plugin, options }) => vue.use(plugin, ...options));
    }
    if (Array.isArray(config.guards) && config.router) {
        applyGuards(config.guards, config.router);
    }
}
export default {
    fromConfig,
};
