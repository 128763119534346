<template>
    <div>
        <label class="label">Shipping Labels</label>
        <ul>
            <li v-for="label in labels" :key="label.id">
                <span v-html="getTrackingNumberLinkHTML([label.trackingNumber], label.trackingNumber.type_id)"></span>
                | <a @click="onDownloadLabel(label.id)" target="_blank">Download Label</a>
            </li>
        </ul>
    </div>
</template>

<script>
import SmartApi from 'SmartApi';
import { downloadFile } from 'helpers';
import _ from 'lodash';

export default {
    props: {
        labels: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    methods: {
        async onDownloadLabel(labelId) {
            try {
                const response = await SmartApi.get({
                    routeName: 'shipping-labels.pdf',
                    routeParams: {
                        shippingLabel: labelId,
                    },
                    config: {
                        responseType: 'arraybuffer',
                    },
                });
                const contentDisposition = response.headers['content-disposition'] || '';
                const filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
                downloadFile(response.data, 'application/pdf', filename);
            } catch (e) {
                console.error(e);
                const defaultMessage = 'An error occurred while generating the shipping label.';
                const message = _.get(e, 'response.data.message', defaultMessage);
                this.showDangerNotification(message);
            }
        },
    },
};
</script>
