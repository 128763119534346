export default {
    mapper: {
        relations: {
            belongsToMany: {
                facility: {
                    foreignKey: 'id',
                    localField: 'facilities',
                },
            },
            hasMany: {
                oAuthClient: {
                    foreignKey: 'oauth_partner_id',
                    localField: 'clients',
                },
            },
        },
    },
};
