import * as actionTypes from "./action_types";
import * as mutationTypes from "./mutation_types";
import SmartApi from "SmartApi";

export default {
    [actionTypes.GET_FUNCTIONS]: async ({ commit }, params = {}) => {
        const config = { params };
        const routeName = "listTextTransformationLanguageFunctions";
        const request = {
            routeName,
            config
        };
        const response = await SmartApi.get(request);
        commit(mutationTypes.SET_FUNCTIONS, {
            functions: response.data.data
        });
    }
};
