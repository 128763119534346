import _ from 'lodash';
import OrdersApi from 'api/endpoints/orders';

export default {
    namespaced: true,
    state: {
        data: [],
    },
    actions: {
        async get({ commit }, orderId) {
            let response = await OrdersApi.getOriginalOrder(orderId);
            commit('setData', response.data.data);
        },
    },
    mutations: {
        setData(state, payload) {
            payload = payload.map((element) => {
                element._listId = _.uniqueId();
                return element;
            });
            state.data = payload;
        },
    }
}
