import _ from 'lodash';
import { actionTypes as linePresenceActionTypes } from '../admin/modules/linePresence';
import { apis } from 'api/client';
import { Statuses } from 'enums';
import Vue from 'vue';

function changeItem(storedData, payload) {
    return storedData.map((element) => {
        if (element._listId == payload._listId) {
            element = payload;
        }

        return element;
    });
}

function removeItem(storedData, payload) {
    return storedData.filter((element) => {
        if (element._listId == payload._listId) {
            return false;
        }

        return true;
    });
}

function returnableIdsFromLines(lines) {
    let lineIds = [];

    const returnableStatuses = [Statuses.ORDERED, Statuses.RECEIVED, Statuses.PARTIALLY_RECEIVED, Statuses.BACKORDERED];

    for (let line of lines) {
        if (returnableStatuses.includes(line.statusId)) {
            lineIds.push(line.id);
        }
    }

    return lineIds;
}

export const TABLE_STORE = {
    actions: {
        storeFilters({ commit, state }, payload) {
            let { page } = payload;
            commit('clearStoredFilters');
            commit('storeFilters', payload);

            if (page && state.pagination.page != 1) {
                commit('setPage', page);
            }
        },
        async clearData({ commit }) {
            commit('clearData');
        },
        async deleteLines({ getters }, { lineIds }) {
            if (lineIds instanceof Array === false) {
                lineIds = [lineIds];
            }

            let idString = lineIds.join(',');
            return await getters.api.delete(idString);
        },
        async updateLinesStatuses({ getters }, { lineIds, statusId }) {
            if (lineIds instanceof Array === false) {
                lineIds = [lineIds];
            }

            let idString = lineIds.join(',');
            return await getters.api.updateStatus({ idString, statusId });
        },
        async clearDataWithTimeout({ commit }) {
            setTimeout(function () {
                commit('clearData');
            }, 1500);
        },
        handleSort({ state, commit }, { column = '', directionOverride = null, sortIndex = 0 }) {
            if (!column) {
                throw "Sort 'column' property is required.";
            }

            let fullSort = _.clone(state.sort);
            if (directionOverride == null && fullSort[sortIndex] === `-${column}`) {
                if (fullSort[sortIndex] === state.default_sort[sortIndex]) {
                    fullSort[sortIndex] = column;
                } else {
                    fullSort[sortIndex] = state.default_sort[sortIndex];
                }
            } else if (directionOverride == 'DESC' || (directionOverride == null && fullSort[sortIndex] === column)) {
                fullSort[sortIndex] = `-${column}`;
            } else {
                fullSort[sortIndex] = column;
            }

            commit('setSort', fullSort);
        },
    },
    state: {
        data: [],
        paginationData: {},
        pagination: {
            page: 1,
            per_page: 200,
        },
        sort: [],
        default_sort: ['-id'],
        filters: {},
    },
    getters: {
        storedFilterValue(state, getters) {
            return (filterName) => {
                return state.filters.hasOwnProperty(filterName) ? state.filters[filterName] : undefined;
            };
        },
        storedFilters(state) {
            return state.filters;
        },
    },
    mutations: {
        setDefaultSort(state, payload) {
            state.default_sort = payload;
        },
        setData(state, payload) {
            payload = payload.map((element) => {
                element._listId = _.uniqueId();
                return element;
            });
            state.data = payload;
        },
        clearData(state) {
            state.data = [];
        },
        setPaginationData(state, payload) {
            state.paginationData = payload;
        },
        setPage(state, page) {
            state.pagination.page = page;
        },
        clearPage(state) {
            state.pagination.page = DEFAULT_PAGINATION.page;
        },
        setPerPage(state, perPage) {
            state.pagination.perPage = perPage;
        },
        setSort(state, value) {
            if (value && typeof value == 'string') {
                state.sort = value.split(',');
            } else if (value && value.length > 0) {
                state.sort = value;
            } else {
                state.sort = state.default_sort;
            }
        },
        clearPerPage(state) {
            state.pagination.perPage = DEFAULT_PAGINATION.perPage;
        },
        storeFilters(state, payload) {
            Object.keys(payload).forEach((key) => {
                state.filters[key] = payload[key];
            });
        },
        clearStoredFilters(state) {
            state.filters = {};
        },
        add(state, payload) {
            payload._listId = _.uniqueId();
            state.data.push(payload);
        },
        update(state, payload) {
            state.data = changeItem(state.data, payload);
        },
        updateByLineId(state, payload) {
            let lineIndex = _.findIndex(state.data, ['id', payload.id]);
            _.mapKeys(payload, (value, key) => {
                _.set(state.data[lineIndex], key, payload[key]);
            });
        },
        delete(state, payload) {
            state.data = removeItem(state.data, payload);
        },
        deleteLineById(state, lineId) {
            state.data = state.data.filter((line) => line.id != lineId);
            console.log(state.data);
        },
    },
};

export const SUBCONTENT_STORE = {
    state: {
        openedLines: new Set(),
    },
    getters: {
        isLineOpen(state) {
            return (line) => {
                const { id } = line;
                return state.openedLines.has(id);
            };
        },
        areAllLinesOpen(state) {
            return (lineIds) => _.every(lineIds, (id) => state.openedLines.has(id));
        },
    },
    mutations: {
        clearOpenedLines(state) {
            state.openedLines = new Set();
        },
        setLineOpenState(state, { itemId, open }) {
            const openedLines = new Set(state.openedLines);
            if (open) {
                openedLines.add(itemId);
            } else {
                openedLines.delete(itemId);
            }

            state.openedLines = openedLines;
        },
        batchSetLineOpenState(state, { itemIds, open }) {
            const openedLines = new Set(state.openedLines);
            itemIds.forEach((id) => {
                if (open) {
                    openedLines.add(id);
                } else {
                    openedLines.delete(id);
                }
            });

            state.openedLines = openedLines;
        },
        closeLine(state, lineId) {
            state.openedLines.delete(lineId);
        },
    },
    /**
     * @type {import('vuex').ActionTree}
     */
    actions: {
        closeLine({ commit, dispatch }, id) {
            dispatch(`linePresence/${linePresenceActionTypes.BROADCAST_SHUT}`, { id }, { root: true }).then(() =>
                commit('closeLine', id)
            );
        },
        setLineOpenState({ commit, dispatch, state }, { itemId, open }) {
            if (open) {
                if (state.openedLines.has(itemId)) {
                    // Bail early if no state change will occur to avoid broadcasting.
                    return;
                }
                commit('setLineOpenState', { itemId, open });
                dispatch(`linePresence/${linePresenceActionTypes.BROADCAST_OPEN}`, { id: itemId }, { root: true });
            } else {
                if (!state.openedLines.has(itemId)) {
                    // Bail early if no state change will occur to avoid broadcasting.
                    return;
                }
                // BROADCAST_SHUT must occur before setLineOpenState because BROADCAST_SHUT
                // verifies the Line is open before broadcasting a close event.
                dispatch(`linePresence/${linePresenceActionTypes.BROADCAST_SHUT}`, { id: itemId }, { root: true }).then(
                    () => commit('setLineOpenState', { itemId, open })
                );
            }
        },
        batchSetLineOpenState({ dispatch }, { itemIds, open }) {
            itemIds.forEach((id) => {
                dispatch('setLineOpenState', { itemId: id, open });
            });
        },
        async clearOpenedLines({ commit, dispatch, state }) {
            /**
             * @type {Promise<any>[]}
             */
            const promises = [];
            /**
             * @type {Set<number>}
             */
            const openedLines = state.openedLines;
            openedLines.forEach((id) => {
                promises.push(dispatch('closeLine', id));
            });
            await Promise.allSettled(promises);
            commit('clearOpenedLines');
        },
    },
};

export const SHOWS_ORDER_LINE_RETURN_STATUSES = {
    state: {
        returnRequestStatusIdByLineId: {},
    },
    actions: {
        async getReturnStatusesForLines({ state, commit }, lines) {
            let lineIds = returnableIdsFromLines(lines);

            if (!lineIds.length) {
                return;
            }

            const response = await apis.ReturnRequests.listReturnRequests({
                line_id: lineIds,
                sort: 'id',
                per_page: -1,
                pagination_type: 'simple',
            });

            commit('createOrderLineReturnStatusMap', response.body.data);
        },
    },
    getters: {
        returnStatusIdByLineId(state) {
            return (lineId) => {
                return state.returnRequestStatusIdByLineId[lineId] || null;
            };
        },
    },
    mutations: {
        createOrderLineReturnStatusMap(state, returnRequests) {
            for (let returnRequest of returnRequests) {
                Vue.set(state.returnRequestStatusIdByLineId, returnRequest.line_id, returnRequest.status_id);
            }
        },
        updateReturnStatusForLineId(state, { lineId, statusId }) {
            state.returnRequestStatusIdByLineId[lineId] = statusId;
        },
    },
};
