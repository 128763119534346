<template>
    <emp-select
        :disabled="disabled"
        :fieldClass="fieldClass"
        :label="label"
        :labelClass="labelClass"
        :options="roleOptions"
        :placeholder="placeholder"
        :value="value"
        v-on="$listeners"
    />
</template>

<script>
import { input, hasOptions } from './mixins';

export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: [String, Boolean],
            default: '',
        },
    },
    mixins: [input],
    computed: {
        roleOptions() {
            return [
                {
                    value: 1,
                    name: 'Admin',
                },
                {
                    value: 2,
                    name: 'Facility',
                },
            ];
        },
    },
};
</script>
