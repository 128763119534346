// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import useCacheManager from 'use/cacheManager';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Vendors Dropdowns',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Vendors dropdowns.
 * @returns
 */
export default function useVendorDropdowns(config = {}) {
    const fetcher = async () => {
        const response = await apis.Vendors.listVendors({
            pagination_type: 'simple',
            per_page: -1,
            partials: ['dropdown'],
            sort: 'name',
        });
        return response.body.data || [];
    };

    const { data: vendors, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    if (!vendors.value) {
        vendors.value = [];
    }

    useCacheManager(mutate, metadata);

    function vendorWhere(property, value) {
        return vendors.value.find((vendor) => vendor[property] === value);
    }

    return {
        /**
         * @type {import('@vue/composition-api').Ref<VendorV2[]>}
         */
        vendors,
        error,
        isValidating,
        mutate,
        metadata,
        vendorWhere,
    };
}
