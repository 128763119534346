<template>
    <emp-select
        :disabled="isLoading || disabled"
        :fieldClass="fieldClass"
        :inputClass="{ 'is-loading': isLoading }"
        label="Credit Card"
        :labelClass="labelClass"
        :options="creditCards"
        :placeholder="placeholder"
        valueFrom="id"
        :value="value"
        @input="$emit('input', $event)"
    />
</template>

<script>
// @ts-check
import { defineComponent } from '@vue/composition-api';
import { input } from 'component/shared/elements/mixins';
import useCreditCards from 'use/creditCards';

export default defineComponent({
    setup() {
        const { creditCards, isLoading } = useCreditCards();
        return {
            creditCards,
            isLoading,
        };
    },
    mixins: [input],
});
</script>
