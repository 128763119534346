import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
            },
            morphMany: {
                ...auditsRelation('prewrittenNote'),
            },
        },
    },
};
