import Vue from "vue";
import * as types from "./mutation_types.js";

export default {
    [types.REMOVE_EXTRACTION_INSTRUCTION]: (state, { id }) => {
        state.data = state.data.filter(item => item.id != id);
    },
    [types.SET_IS_LOADING]: (state, { isLoading }) => {
        state.isLoading = isLoading;
    },
    [types.SET_EXTRACTION_INSTRUCTION]: (state, extractionInstruction) => {
        const index = state.data.findIndex(
            ({ id }) => id == extractionInstruction.id
        );
        if (index > -1) {
            const existing = state.data[index];
            for (const key in extractionInstruction) {
                if (extractionInstruction.hasOwnProperty(key)) {
                    Vue.set(existing, key, extractionInstruction[key]);
                }
            }
        } else {
            state.data.push(extractionInstruction);
        }
    }
};
