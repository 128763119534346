import messageActions   from './messages/actions';
import messageGetters   from './messages/getters';
import messageMutations from './messages/mutations';
import messageState     from './messages/state';

import notificationActions   from './notifications/actions';
import notificationGetters   from './notifications/getters';
import notificationMutations from './notifications/mutations';
import notificationState     from './notifications/state';

import reminderActions   from './reminders/actions';
import reminderGetters   from './reminders/getters';
import reminderMutations from './reminders/mutations';
import reminderState     from './reminders/state';

import toastActions   from './toasts/actions';
import toastMutations from './toasts/mutations';
import toastState     from './toasts/state';

import mutations   from './mutations';
import eventHandlers   from './eventHandlers';

export default {
    namespaced: true,
    actions: {
        ...messageActions,
        ...notificationActions,
        ...reminderActions,
        ...eventHandlers,
        ...toastActions,
    },
    getters: {
        ...messageGetters,
        ...notificationGetters,
        ...reminderGetters,
    },
    mutations: {
        ...messageMutations,
        ...notificationMutations,
        ...reminderMutations,
        ...toastMutations,
        ...mutations,
    },
    state: {
        ...messageState,
        ...notificationState,
        ...reminderState,
        ...toastState,
        activeTab: 'messages',
    },
};
