<template>
    <emp-component-modal @modal:close="onClose" :isActive="isActive" contentClass="width-70vw">
        <template v-if="isActive">
            <div slot="modal-header">
                {{ title }}
            </div>
            <div slot="modal-body">
                <div class="message-body-content print-order-modal" v-if="line.id">
                    <div class="print-order-modal-content">
                        <h3 class="title is-3" v-if="title">{{ title }}</h3>
                        <table class="table is-bordered is-fullwidth is-striped">
                            <tr>
                                <td>
                                    <template v-if="isPrintOrder">
                                        <label class="label">Order #:</label>
                                        {{ line.orderId }}
                                    </template>
                                    <template v-else>
                                        <label class="label">PO #</label>
                                        {{ getPurchaseOrderIdWithSuffix(line) }}
                                    </template>
                                </td>
                                <td>
                                    <label class="label">Date Ordered:</label>
                                    {{ fromDateObject(line.userOrderedTimestamp, 'MM/DD/YY h:mma') }}
                                </td>
                            </tr>
                            <tr v-if="!isPrintOrder">
                                <td>
                                    <label class="label">Ship To:</label>
                                    <template v-if="line.shippingAddress">{{ line.shippingAddress.name }}</template>
                                </td>
                                <td>
                                    <label class="label">Bill To:</label>
                                    <template v-if="line.costCenter">{{ line.costCenter.name }}</template>
                                </td>
                            </tr>
                        </table>
                        <div class="mobile-table-wrapper">
                            <table class="table is-bordered is-fullwidth is-striped" v-if="orderLines">
                                <tr>
                                    <th class="has-text-centered">{{ vendorOrManufacturerHeading }}</th>
                                    <th class="has-text-centered">Item #</th>
                                    <th class="has-text-centered">Description</th>
                                    <th class="has-text-centered">PO #</th>
                                    <th class="has-text-centered" v-if="showPatientNames">Patient</th>
                                    <th class="has-text-centered">Qty</th>
                                    <th class="has-text-centered">Price Per</th>
                                    <th class="has-text-centered">Date Needed</th>
                                    <th class="has-text-centered">Date Expected</th>
                                    <th class="has-text-centered">Status</th>
                                </tr>
                                <tr v-for="(line, index) in orderLines" :key="`print_order_modal_${index}`">
                                    <td class="has-text-centered">{{ getVendorOrManufacturerName(line) }}</td>
                                    <td class="has-text-centered">{{ line.partNumber }}</td>
                                    <td>{{ line.description }}</td>
                                    <td class="has-text-centered">{{ getPurchaseOrderIdWithSuffix(line) }}</td>
                                    <td v-if="showPatientNames">
                                        <span v-if="line.patients">{{ formatPatientNames(line.patients) }}</span>
                                    </td>
                                    <td class="has-text-right">{{ line.quantity }}</td>
                                    <td class="has-text-right"
                                        ><span v-show="line.shouldShowPrice">{{
                                            formatCurrency(line.priceAfterRebate)
                                        }}</span></td
                                    >
                                    <td class="has-text-centered">
                                        {{ fromDateObject(line.dateNeeded, 'MM/DD/YY', null, false) }}
                                    </td>
                                    <td class="has-text-centered">
                                        {{ fromDateObject(line.dateExpected, 'MM/DD/YY', null, false) }}
                                    </td>
                                    <td class="has-text-centered" v-if="line.status">{{ line.status.name }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="message-body-buttons">
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="level-item">
                                <emp-button @click="onPrint" buttonClass="is-primary" label="Print" />
                            </div>
                            <div class="level-item">
                                <emp-button @click="onClose" label="Close" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </emp-component-modal>
</template>

<script>
import _ from 'lodash';
import EventBus from 'event-bus';
import { formatDateTime, todaysDate } from 'helpers';
import { formatters } from 'component/shared/elements/mixins';
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isPrintOrder: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [formatters],
    computed: {
        ...mapState({
            orderLines: (state) => state.shared.orderHistory.dataForPrint,
            line: (state) => state.shared.modal.data,
        }),
        vendorOrManufacturerHeading() {
            return this.isPrintOrder ? 'Manufacturer' : 'Vendor';
        },
        purchaseOrder() {
            return _.get(this.orderLines, '[0].purchaseOrder', {});
        },
        showPatientNames() {
            return _.get(this.purchaseOrder, 'shippingAddress.includePatientNameOnPackingSlip', false);
        },
        today() {
            let today = new Date();
            today = today.toUTCString();
            return today;
        },
        title() {
            let title = '';

            if (this.isPrintOrder && this.line.orderId) {
                title = `Empire Medical Order #${this.line.orderId}`;
            } else if (!this.isPrintOrder && this.line.purchaseOrder) {
                title = `Empire Medical PO # ${this.line.purchaseOrder.id}`;
            }

            return title;
        },
    },
    methods: {
        ...mapActions({
            onClose: 'shared/modal/closeModal',
            getOrderForPrint: 'shared/orderHistory/getOrderForPrint',
        }),
        async getData() {
            const filters = {
                includes: ['vendor', 'manufacturer', 'purchaseOrder.shippingAddress', 'patients'],
            };

            if (this.isPrintOrder) {
                filters.order_id = this.line.orderId;
            } else {
                filters.purchase_order_id = this.line.purchaseOrder.id;
            }

            await this.getOrderForPrint(filters);
        },
        onPrint() {
            let contentToPrint = document.querySelector('.print-order-modal.message-body-content').innerHTML;
            contentToPrint = `<div class="print-order">${contentToPrint}</div>`;
            EventBus.$emit('print-component:onPrint', contentToPrint);
        },
        getPurchaseOrderIdWithSuffix(line) {
            return _.get(line, 'purchaseOrder.idWithSuffix', 'N/A');
        },
        getVendorOrManufacturerName(line) {
            let bucket = this.isPrintOrder ? 'manufacturer.name' : 'vendor.name';
            return _.get(line, bucket);
        },
        formatDateTime,
        todaysDate,
    },
    watch: {
        isActive: {
            handler(value) {
                if (value) {
                    this.getData();
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.print-order-modal {
    .data-field {
        border: 2px solid #ccc;
    }
    .data-field + .data-field {
        border-top: none;
    }
    .data-field.right-column {
        border-left: none;
    }
}
</style>
