import { auditsRelation } from 'app/jsd/helpers';
export default {
    mapper: {
        relations: {
            hasMany: {
                bill: {
                    foreignKey: 'shipping_method_id',
                    localField: 'bills',
                },
                invoice: {
                    foreignKey: 'shipping_method_id',
                    localField: 'invoices',
                },
                purchaseOrder: {
                    foreignKey: 'shipping_method_id',
                    localField: 'purchaseOrders',
                },
            },
            belongsTo: {
                shippingService: {
                    foreignKey: 'shipping_service_id',
                    localField: 'shippingService',
                },
            },
            belongsToMany: {
                address: {
                    foreignKey: 'id',
                    localField: 'addresses',
                },
                vendor: {
                    foreignKey: 'id',
                    localField: 'vendors',
                },
            },
            morphMany: {
                ...auditsRelation('shippingMethod'),
            },
        },
    },
};
