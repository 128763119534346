import RolesApi from 'api/endpoints/roles';
import _ from 'lodash';
export default {
    namespaced: true,
    state: {
        dropdownData: [],
        inRequest: false
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
        }
    },
    actions: {
        async getDropdownData({ state, commit }, params) {
            if (state.inRequest === false) {
                state.inRequest = true;

                let paramsToPass = _.merge({}, {
                    sort: ['name'],
                }, params);

                let roles = await RolesApi.get(paramsToPass);
                commit('setDropdownData', roles.data.data);

                state.inRequest = false;
            }
        }
    },
}
