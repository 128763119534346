// @ts-check
import AuthGuard from './AuthGuard';
import ReloadUserData from './ReloadUserData';
import StorePreviousRoute from './StorePreviousRoute';
import AdminGuard from './AdminGuard';
import VueRouter from 'vue-router';

/**
 * Create and apply navigation guards to a VueRouter instance.
 * @param {{apply: (router: VueRouter) => void}[]} guards
 * @param {VueRouter} router
 */
export function applyGuards(guards, router) {
    guards.forEach((guard) => {
        guard.apply(router);
    });
}

export default [AuthGuard, ReloadUserData, StorePreviousRoute, AdminGuard];
