<template>
    <div class="address-tooltip-information"
        ><!--
        --><div class="group" v-show="general.length"
            ><!--
            --><strong class="group-title">General</strong
            ><!--
            --><emp-tooltip-notes :notes="general" /><!--
        --></div
        ><!--
        --><div class="group" v-show="sps.length"
            ><!--
            --><strong class="group-title">SPS</strong
            ><!--
            --><emp-tooltip-notes :notes="sps" /><!--
        --></div
        ><!--
        --><div class="group" v-show="pricing.length"
            ><!--
            --><strong class="group-title">Pricing</strong
            ><!--
            --><emp-tooltip-notes :notes="pricing" /><!--
        --></div
        ><!--
        --><div class="group" v-show="shipping.length"
            ><!--
            --><strong class="group-title">Shipping</strong
            ><!--
            --><emp-tooltip-notes :notes="shipping" /><!--
        --></div
        ><!--
        --><div class="group" v-show="preferences.length"
            ><!--
            --><strong class="group-title">Preferences</strong
            ><!--
            --><emp-tooltip-notes :notes="preferences" /><!--
        --></div
        ><!--
        --><div class="group" v-show="contacts.length"
            ><!--
            --><strong class="group-title">Contacts</strong
            ><!--
            --><emp-tooltip-notes :notes="contacts" /><!--
        --></div
        ><!--
        --><div class="group" v-show="payments.length"
            ><!--
            --><strong class="group-title">Payments</strong
            ><!--
            --><emp-tooltip-notes :notes="payments" /><!--
        --></div
        ><!--
    --></div>
</template>

<script>
export default {
    props: {
        facilityNotes: {
            type: Array,
            default: () => [],
        },
        addressNotes: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        general() {
            return this.generalAddressNotes.concat(this.generalFacilityNotes);
        },
        sps() {
            return this.spsAddressNotes.concat(this.spsFacilityNotes);
        },
        pricing() {
            return this.pricingAddressNotes.concat(this.pricingFacilityNotes);
        },
        shipping() {
            return this.shippingAddressNotes.concat(this.shippingFacilityNotes);
        },
        preferences() {
            return this.preferencesAddressNotes.concat(this.preferencesFacilityNotes);
        },
        contacts() {
            return this.contactsAddressNotes.concat(this.contactsFacilityNotes);
        },
        payments() {
            return this.paymentsAddressNotes.concat(this.paymentsFacilityNotes);
        },
        generalFacilityNotes() {
            return this.facilityNotes.filter((note) => note.type === 'general');
        },
        spsFacilityNotes() {
            return this.facilityNotes.filter((note) => note.type === 'address_sps');
        },
        pricingFacilityNotes() {
            return this.facilityNotes.filter((note) => note.type === 'address_pricing');
        },
        shippingFacilityNotes() {
            return this.facilityNotes.filter((note) => note.type === 'address_shipping');
        },
        preferencesFacilityNotes() {
            return this.facilityNotes.filter((note) => note.type === 'address_preferences');
        },
        contactsFacilityNotes() {
            return this.facilityNotes.filter((note) => note.type === 'address_contacts');
        },
        paymentsFacilityNotes() {
            return this.facilityNotes.filter((note) => note.type === 'address_payment_info');
        },
        generalAddressNotes() {
            return this.addressNotes.filter((note) => note.type === 'general');
        },
        spsAddressNotes() {
            return this.addressNotes.filter((note) => note.type === 'address_sps');
        },
        pricingAddressNotes() {
            return this.addressNotes.filter((note) => note.type === 'address_pricing');
        },
        shippingAddressNotes() {
            return this.addressNotes.filter((note) => note.type === 'address_shipping');
        },
        preferencesAddressNotes() {
            return this.addressNotes.filter((note) => note.type === 'address_preferences');
        },
        contactsAddressNotes() {
            return this.addressNotes.filter((note) => note.type === 'address_contacts');
        },
        paymentsAddressNotes() {
            return this.addressNotes.filter((note) => note.type === 'address_payment_info');
        },
    },
};
</script>

<style lang="scss" scoped>
.group {
    padding-bottom: 1rem;

    &-title {
        padding-bottom: 0.2rem;
        color: #fff;
    }
}
</style>
