<template>
    <empire-select
        :disabled="terms.length == false"
        :fieldClass="fieldClass"
        :inputClass="{ 'is-loading': terms.length === false }"
        :label="label"
        :labelClass="labelClass"
        :options="terms"
        :placeholder="placeholder"
        valueFrom="id"
        v-model="termId"
    />
</template>

<script>
import { Terms } from 'enums';
import { input } from './mixins';
import { getFriendlyName } from 'enumHelpers';
import Select from 'component/revamp/elements/Select';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    components: {
        'empire-select': Select,
    },
    mixins: [input],
    props: {
        placeholder: {
            type: [String, Boolean],
            default: false,
        },
    },
    data() {
        return {
            termId: 0,
        };
    },
    computed: {
        terms() {
            let terms = [{ id: Terms.EMPTY, name: 'Select Term' }];
            for (const property in Terms) {
                if (property == 'EMPTY') {
                    continue;
                }
                terms.push({ id: Terms[property], name: getFriendlyName(property) });
            }
            return terms;
        },
    },
    watch: {
        termId(value) {
            this.$emit('input', parseInt(value, 10));
        },
        value(value) {
            this.termId = value;
        },
    },
});
</script>
