import _ from 'lodash';
import merge from 'deepmerge';
import VendorApi from 'api/endpoints/vendors';
import { Statuses } from 'enums';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        dropdownData: [],
        inRequest: false,
        default_sort: ['name'],
        lastRequestedDropdownParams: null,
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
        },
        updateVendor(state, payload) {
            let mutable = _.clone(state.dropdownData);

            mutable = mutable.map((vendor) => {
                if (vendor.id == payload.vendor_id) {
                    vendor.fields = payload.fields;
                }

                return vendor;
            });

            state.dropdownData = mutable;
        },
        pushRequestedDropdownParams(store, payload) {
            store.lastRequestedDropdownParams = { ...payload };
        },
    },
    getters: {
        api() {
            return VendorApi;
        },
        selectedVendor(state) {
            return (vendorId) => {
                return state.dropdownData.find((vendor) => vendor.id == vendorId);
            };
        },
        getVendorCutoffTimeId(state, getters) {
            return (vendorId) => {
                return _.get(getters.selectedVendor(vendorId), 'cutoffTimeId', null);
            };
        },
        getVendorNameById(state, getters) {
            return (vendorId) => {
                let name = '';
                let vendor = getters.selectedVendor(vendorId);

                if (vendor) {
                    name = vendor.name;
                }

                return name;
            };
        },
        activeVendors(state, getters) {
            return state.dropdownData.filter((vendor) => vendor.statusId === Statuses.ACTIVE);
        },
        orderFormVendors(state, getters) {
            return getters.activeVendors.filter((vendor) => vendor.showOnOrderForm);
        },
        pinnedToSPS(state) {
            return state.dropdownData.filter(
                (vendor) => vendor.rebatesPinnedToSps || vendor.flatRateShippingExceptionsPinnedToSps
            );
        },
    },
    actions: {
        async getData({ state, dispatch }) {
            let requestParams = {
                page: state.pagination.page,
                per_page: state.pagination.per_page,
                includes: ['categories'],
            };

            await dispatch('get', requestParams);
            dispatch('shared/quickbar/deselectAllItems', null, { root: true });
        },
        async get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, params, { sort: state.sort });

            let response = await VendorApi.get(params);

            commit('setData', response.data.data);

            if (response.data.meta) {
                commit('setPaginationData', response.data.meta.pagination);
            }
        },
        async getVendorFields({ state, commit }, params = {}) {
            if (params.hasOwnProperty('id') == false) {
                throw Error('Vendor ID is required.');
            }

            let vendor_id = params.id;

            params = _.merge({}, state.filters, state.pagination, params, { sort: state.sort });
            let response = await VendorApi.getVendorFields(params);

            commit('updateVendor', {
                vendor_id: vendor_id,
                fields: response.data.data,
            });
        },
        async getDropdownData({ state, commit }, params = {}) {
            if (state.inRequest === false) {
                params = merge(params, {
                    sort: ['name'],
                });

                if (_.isEqual({ ...state.lastRequestedDropdownParams }, params)) {
                    return;
                }
                commit('pushRequestedDropdownParams', { ...params });

                state.inRequest = true;
                try {
                    await VendorApi.dropdownData(params).then((response) => {
                        commit('setDropdownData', response.data.data);
                    });
                } catch (e) {
                    console.error(e);
                } finally {
                    state.inRequest = false;
                }
            }

            return true;
        },
    },
});
