import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = params.id ? route('purchase-orders.show', { id: params.id }) : route('purchase-orders');
        delete params.id;
        return await axios.get(url, { params });
    },
    async delete(id) {
        let url = route('purchase-orders.destroy', { id: id });
        return await axios.delete(url);
    },
    async update(payload) {
        let url = route('purchase-orders.edit', { id: payload.id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('purchase-orders.create');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async print(payload) {
        let url = route('purchase-orders.print');
        return await axios.post(url, payload, { responseType: 'blob' });
    },
    async changeStatus(payload) {
        let url = route('purchase-orders.status.edit');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async changeBillingStatus(payload) {
        let url = route('purchase-orders.billing-status.edit');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async place(payload = {}) {
        let url = route('purchase-orders.place');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
};
