import addresses from './addresses';
import alert from './alert';
import auth from './auth';
import echo from './echo';
import facilities from './facilities';
import inventory from './inventory';
import invitations from './invitations';
import modal from './modal';
import orderFormOrder from './orderFormOrder';
import orderHistory from './orderHistory';
import orders from './orders';
import originalOrder from './originalOrder';
import patientNames from './patientNames';
import pendingUsers from './pendingUsers';
import inactiveUsers from './inactiveUsers';
import products from './products';
import productSearch from './productSearch';
import roles from './roles';
import savedOrders from './savedOrders';
import scopes from './scopes';
import shoppingListLinesView from './shoppingListLinesView';
import simpleFormModal from './simpleFormModal';
import statuses from './statuses';
import system from './system';
import toast from './toast';
import users from './users';
import vendors from './vendors';
import currentUser from './currentUser';
import quickbar from './quickbar';
import prewrittenNotes from './prewrittenNotes';
import messageCenter from './messageCenter';

export default {
    addresses,
    alert,
    auth,
    echo,
    facilities,
    inventory,
    invitations,
    modal,
    orderFormOrder,
    orderHistory,
    orders,
    originalOrder,
    patientNames,
    pendingUsers,
    inactiveUsers,
    products,
    productSearch,
    roles,
    savedOrders,
    scopes,
    shoppingListLinesView,
    simpleFormModal,
    statuses,
    system,
    toast,
    users,
    vendors,
    currentUser,
    quickbar,
    prewrittenNotes,
    messageCenter,
};
