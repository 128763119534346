import _ from 'lodash';
import { TABLE_STORE } from 'store/shared/mixins';
import { apis } from 'api/client';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        sort: ['email'],
    },
    actions: {
        getData({ dispatch }) {
            dispatch('getEmailAccounts');
        },
        async getEmailAccounts({ state, commit }, params = {}) {
            params = _.merge({}, { sort: state.sort }, params);
            const response = await apis.EmailAccounts.listEmailAccounts(params);
            commit('setData', response.body.data);
        },
    },
});
