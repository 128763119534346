/**-------------------------------[  WARNING  ]-------------------------------**/
/**  This file was generated by the generate:front-end-enums artisan command  **/
/**  Do not make changes to it directly, they will be overwritten.            **/
/**---------------------------------------------------------------------------**/

export const statuses = [
{"id":10,"name":"Deleted","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":true,"isFacility":true,"isProduct":true,"isVendor":true,"isEmailAccount":false,"isCancelable":false,"billingName":"Deleted","ordersManageName":"Deleted"},
{"id":20,"name":"Hidden","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Hidden","ordersManageName":"Hidden"},
{"id":60,"name":"Pending Review","isManage":true,"isManageAll":true,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":true,"billingName":"Pending Review","ordersManageName":"Pending Review"},
{"id":61,"name":"Need Shipping","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":true,"isLineBilling":false,"isInvoice":true,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Need Shipping","ordersManageName":"Need Shipping"},
{"id":62,"name":"Reviewed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Reviewed","ordersManageName":"Reviewed"},
{"id":63,"name":"Sent","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Sent","ordersManageName":"Sent"},
{"id":64,"name":"Posted","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":true,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Posted","ordersManageName":"Posted"},
{"id":65,"name":"Posted-Changed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":true,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Posted-Changed","ordersManageName":"Posted-Changed"},
{"id":66,"name":"Posted-Reviewed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":true,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Posted-Reviewed","ordersManageName":"Posted-Reviewed"},
{"id":67,"name":"On Hold","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":true,"billingName":"On Hold","ordersManageName":"On Hold"},
{"id":68,"name":"Open","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":true,"isLineBilling":true,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":true,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":true,"billingName":"Unbilled","ordersManageName":"Open"},
{"id":69,"name":"Ordered","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":true,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":true,"billingName":"Ordered","ordersManageName":"Ordered"},
{"id":70,"name":"Partially Received","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":true,"billingName":"Partially Received","ordersManageName":"Partially Received"},
{"id":71,"name":"Received","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Received","ordersManageName":"Received"},
{"id":72,"name":"Pending Return","isManage":false,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Pending Return","ordersManageName":"Pending Return"},
{"id":73,"name":"Returned","isManage":false,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Returned","ordersManageName":"Returned"},
{"id":74,"name":"Credited","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":true,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Credited","ordersManageName":"Credited"},
{"id":75,"name":"Backordered","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":true,"billingName":"Backordered","ordersManageName":"Backordered"},
{"id":76,"name":"In Email Queue","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"In Email Queue","ordersManageName":"In Email Queue"},
{"id":77,"name":"Need More Info","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":true,"billingName":"Need More Info","ordersManageName":"Need More Info"},
{"id":78,"name":"Waiting For Vendor","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":true,"billingName":"Waiting For Vendor","ordersManageName":"Waiting For Vendor"},
{"id":79,"name":"Delivered","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Delivered","ordersManageName":"Delivered"},
{"id":90,"name":"Active","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":true,"isFacility":true,"isProduct":true,"isVendor":true,"isEmailAccount":true,"isCancelable":false,"billingName":"Active","ordersManageName":"Active"},
{"id":91,"name":"Waiting","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":true,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Waiting","ordersManageName":"Waiting"},
{"id":92,"name":"Inactive","isManage":false,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":true,"isFacility":true,"isProduct":true,"isVendor":true,"isEmailAccount":true,"isCancelable":false,"billingName":"Inactive","ordersManageName":"Cancelled"},
{"id":93,"name":"Incomplete","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":true,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Incomplete","ordersManageName":"Incomplete"},
{"id":94,"name":"Complete","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Complete","ordersManageName":"Complete"},
{"id":102,"name":"Billed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":true,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":true,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Billed","ordersManageName":"Billed"},
{"id":103,"name":"Partially Billed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":true,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":true,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Partially Billed","ordersManageName":"Partially Billed"},
{"id":104,"name":"Invoiced","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":true,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Invoiced","ordersManageName":"Invoiced"},
{"id":105,"name":"Auto Generated","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Auto Generated","ordersManageName":"Auto Generated"},
{"id":106,"name":"Added To Bill","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Added To Bill","ordersManageName":"Added To Bill"},
{"id":107,"name":"Added To Credit","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Added To Credit","ordersManageName":"Added To Credit"},
{"id":108,"name":"Old Imported","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Old Imported","ordersManageName":"Old Imported"},
{"id":109,"name":"Sent-Changed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Sent-Changed","ordersManageName":"Sent-Changed"},
{"id":110,"name":"Sent-Reviewed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Sent-Reviewed","ordersManageName":"Sent-Reviewed"},
{"id":111,"name":"Reviewed-Changed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":true,"isCreditMemo":true,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Reviewed-Changed","ordersManageName":"Reviewed-Changed"},
{"id":113,"name":"Closed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":true,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Closed","ordersManageName":"Closed"},
{"id":114,"name":"Return Requested","isManage":false,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Return Requested","ordersManageName":"Return Requested"},
{"id":115,"name":"To Be Printed","isManage":false,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"To Be Printed","ordersManageName":"To Be Printed"},
{"id":116,"name":"Return Rejected","isManage":false,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Return Rejected","ordersManageName":"Return Rejected"},
{"id":117,"name":"EDI Queued","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":true,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"EDI Queued","ordersManageName":"EDI Queued"},
{"id":112,"name":"Changed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Changed","ordersManageName":"Changed"},
{"id":118,"name":"Draft","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Draft","ordersManageName":"Draft"},
{"id":119,"name":"Unread","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Unread","ordersManageName":"Unread"},
{"id":120,"name":"Read","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Read","ordersManageName":"Read"},
{"id":121,"name":"Download Queued","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Download Queued","ordersManageName":"Download Queued"},
{"id":122,"name":"Download Processing","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Download Processing","ordersManageName":"Download Processing"},
{"id":123,"name":"Download Ready","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Download Ready","ordersManageName":"Download Ready"},
{"id":124,"name":"Download Expired","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Download Expired","ordersManageName":"Download Expired"},
{"id":125,"name":"Download Failed","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Download Failed","ordersManageName":"Download Failed"},
{"id":126,"name":"Matched","isManage":false,"isManageAll":false,"isHistory":false,"isBilling":false,"isLineBilling":true,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"Matched","ordersManageName":"Matched"},
{"id":127,"name":"Ordered - Need More Info","isManage":true,"isManageAll":true,"isHistory":true,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":true,"billingName":"Ordered - Need More Info","ordersManageName":"Ordered - Need More Info"},
{"id":900,"name":"All (excluding deleted)","isManage":false,"isManageAll":true,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"All (excluding deleted)","ordersManageName":"All (excluding deleted)"},
{"id":901,"name":"All (including deleted)","isManage":false,"isManageAll":true,"isHistory":false,"isBilling":false,"isLineBilling":false,"isInvoice":false,"isCreditMemo":false,"isPurchaseOrders":false,"isPurchaseOrderBilling":false,"isAddress":false,"isFacility":false,"isProduct":false,"isVendor":false,"isEmailAccount":false,"isCancelable":false,"billingName":"All (including deleted)","ordersManageName":"All (including deleted)"},
];
export const validTransitions = 
{"line":{"0":[60,68,67,75,77,78,69,127,71,70],"60":[10,67,68,77,78,69,127,71],"68":[10,60,67,69,127,77,78],"67":[10,60,68,77,78],"75":[10,71,70,69,127],"77":[10,60,68,67,78],"78":[10,60,68,67,77],"69":[10,75,70,71,68,127],"127":[69,10,75,70,71,68],"71":[10,70,69,68,127],"70":[10,71,69,127],"10":[60,68,67,75,77,78,69,71,70,127]},"returnRequest":{"0":[10,114,115,73,116,72,92],"72":[10,114,115,73,116,92],"114":[10,73,115,116,92],"115":[10,73,116,92],"73":[10,72,116,92],"10":[72,114,115,73,116,92],"116":[72,114,73,92,10],"92":[72,114,115,73,116,92,10]}};
