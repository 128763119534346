<template>
    <emp-component-modal @modal:close="onClose" :isActive="isActive">
        <template v-if="isActive">
            <template slot="modal-header">
                <strong>User Action Required</strong>
            </template>
            <template slot="modal-body">
                <div class="message-body-content">
                    <p>
                        <strong>Before you may continue you must confirm that you have read the following:</strong>
                    </p>
                    <p style="white-space: pre-line" v-for="requiredNote in requiredNotes" :key="requiredNote.id">
                        {{ requiredNote.body.trim() }}
                    </p>
                    <p>
                        <strong>Click Confirm to continue and Cancel to make changes.</strong>
                    </p>
                </div>
                <div class="message-body-buttons">
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="level-item">
                                <emp-button @click="onConfirm" buttonClass="is-primary" label="Confirm" />
                            </div>
                            <div class="level-item">
                                <emp-button @click="onClose" label="Close" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </emp-component-modal>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        notes: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        onConfirm() {
            this.$emit('confirmed');
        },
        onClose() {
            this.$emit('close');
        },
    },
    computed: {
        requiredNotes() {
            return this.notes.filter((note) => note.type === 'required');
        },
    },
};
</script>
