const ALERT_DEFAULTS = {
    title: null,
    content: null,
    isSuccess: false,
    isDanger: false
};

export default {
    namespaced: true,
    state: {
        isActive: false,
        isSuccess: false,
        isDanger: false,
        content: null,
        title: null,
        moduleName: 'Alert'
    },
    mutations: {
        setAlertState(state, payload) {
            state.isActive  = true;
            state.title     = payload.title;
            state.content   = payload.content;
            state.isSuccess = payload.isSuccess;
            state.isDanger  = payload.isDanger;
        },
        clearAlertState(state) {
            state.isActive = false;

            Object.keys(ALERT_DEFAULTS).forEach((key) => {
                state[key] = ALERT_DEFAULTS[key];
            });
        }
    },
    actions: {
        show(context, payload) {
            let completePayload = Object.assign({}, ALERT_DEFAULTS, payload);
            context.commit('setAlertState', completePayload);
        },
        close(context) {
            context.commit('clearAlertState');
        }
    }
};
