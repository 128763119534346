import { notesRelation } from 'app/jsd/helpers';

export default {
    modelName: 'vendor',
    mapper: {
        noteable_type: 'vendor',
        relations: {
            hasMany: {
                representative: {
                    foreignKey: 'vendor_id',
                    localField: 'representatives',
                },
                returnAddress: {
                    foreignKey: 'vendor_id',
                    localField: 'returnAddresses',
                },
                vendorUpsData: {
                    foreignKey: 'vendor_id',
                    localField: 'vendorUpsData',
                },
                warehouse: {
                    foreignKey: 'vendor_id',
                    localField: 'warehouses',
                },
                vendorField: {
                    foreignKey: 'vendor_id',
                    localField: 'fields',
                },
                credit: {
                    foreignKey: 'vendor_id',
                    localField: 'credits',
                },
                invoice: {
                    foreignKey: 'vendor_id',
                    localField: 'invoices',
                },
            },
            belongsToMany: {
                address: {
                    foreignKey: 'id',
                    localField: 'addresses',
                },
                category: {
                    foreignKey: 'id',
                    localField: 'categories',
                },
                shippingService: {
                    foreignKey: 'id',
                    localField: 'shippingServices',
                },
                shippingMethod: {
                    foreignKey: 'id',
                    localField: 'shippingMethods',
                },
                facility: {
                    foreignKey: 'id',
                    localField: 'facilities',
                },
            },
            morphMany: {
                ...notesRelation('vendor'),
            },
        },
    },
};
