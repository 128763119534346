export default {
    mapper: {
        relations: {
            belongsTo: {
                trackingNumberImportResultCollection: {
                    foreignKey: 'result_collection_id',
                    localField: 'collection',
                },
                purchaseOrder: {
                    foreignKey: 'purchase_order_id',
                    localField: 'purchaseOrder',
                },
            },
            hasOneThrough: {
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
            },
        },
    },
};
