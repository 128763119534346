import addresses from './addresses';
import billImports from './billlImports';
import feeScheduleImports from './feeScheduleImports';
import dropdowns from './dropdowns';
import facilities from './facilities';
import priceLists from './priceLists';
import emailAccounts from './emailAccounts';
import permissions from './permissions';
import shippingMethods from './shippingMethods';
import shippingServices from './shippingServices';
import table from './table';
import users from './users';
import vendors from './vendors';
import shoppingLists from './shoppingLists';
import shoppingListView from './shoppingListView';
import shoppingListLinesView from './shoppingListLinesView';
import systemSettings from './systemSettings';

export default {
    addresses,
    billImports,
    feeScheduleImports,
    dropdowns,
    emailAccounts,
    facilities,
    priceLists,
    permissions,
    shippingMethods,
    shippingServices,
    table,
    users,
    vendors,
    shoppingLists,
    shoppingListView,
    shoppingListLinesView,
    systemSettings,
};
