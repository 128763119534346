import { utils, hasManyType, hasOneType, belongsToType } from 'js-data';
import { MorphManyType, LoadWith as LoadMorphMany } from 'app/jsd/classes/MorphMany';
import { Adapter } from 'js-data-adapter';

const DEFAULTS = {
    defaultResponsePath: 'body.data',
    defaultMetadataPath: 'body.metadata',
    defaultNamingPattern: '%verb%%name%',
    namingPatterns: {
        singular: {
            find: '%verb%%name%',
            create: '%verb%%name%',
            update: '%verb%%name%',
            destroy: '%verb%%name%',
        },
        plural: {
            find: '%verb%%plural-name%',
            create: '%verb%%plural-name%',
            update: '%verb%%plural-name%',
            destroy: '%verb%%plural-name%',
        },
    },
    restVerbs: {
        singular: {
            find: 'get',
            create: 'create',
            update: 'update',
            destroy: 'delete',
        },
        plural: {
            find: 'list',
            create: 'create',
            update: 'update',
            destroy: 'delete',
        },
    },
    httpVerbs: ['get', 'post', 'put', 'patch', 'delete'],
    pluralModelNames: {},
    debug: true,
};

/**
 * SwaggerAdapter class.
 *
 * @example
 * import { DataStore } from 'js-data';
 * import { SwaggerAdapter } from 'js-data-swagger';
 *
 * const swaggerAdapter = new SwaggerAdapter();
 * const store = new DataStore();
 *
 * store.registerAdapter('swagger', swaggerAdapter, { 'default': true });
 *
 * store.defineMapper('school');
 * store.defineMapper('student');
 *
 * // GET /school/1
 * store.find('school', 1).then((school) => {
 *   console.log('school');
 * });
 *
 * @class SwaggerAdapter
 * @extends Adapter
 * @param swaggerClient
 * @param {object} [opts] Configuration options.
 */
export function SwaggerAdapter(opts) {
    utils.classCallCheck(this, SwaggerAdapter);

    opts || (opts = {});

    Adapter.call(this, opts);

    // Fill in any missing options with the defaults
    utils.fillIn(opts, DEFAULTS);
    if (!opts.swaggerClient) {
        throw new Error("'swaggerClient' is required");
    }

    opts.allApiOperations = {};
    for (let p in opts.swaggerClient.spec.paths) {
        for (let verb in opts.httpVerbs) {
            if (opts.swaggerClient.spec.paths[p].hasOwnProperty(opts.httpVerbs[verb])) {
                opts.allApiOperations[opts.swaggerClient.spec.paths[p][opts.httpVerbs[verb]].operationId] =
                    opts.swaggerClient.spec.paths[p][opts.httpVerbs[verb]];
                opts.allApiOperations[opts.swaggerClient.spec.paths[p][opts.httpVerbs[verb]].operationId].verb =
                    opts.httpVerbs[verb];
            }
        }
    }

    this.config = opts;
}

Adapter.extend({
    constructor: SwaggerAdapter,

    async _create(mapper, props, opts) {
        this.dbg('_create', mapper, props, opts);
        return await this.execute(mapper, 'create', false, opts, null, props);
    },

    async _createMany(mapper, records, opts) {
        this.dbg('_createMany', mapper, records, opts);

        const method = 'create';
        const operationId = this.composeOperationId(mapper, method, true, opts);

        if (this.config.allApiOperations.hasOwnProperty(operationId)) {
            return await this.execute(mapper, method, true, opts, null, records);
        }

        return records.map((record) => {
            return this.create(mapper, record, opts);
        }, this);
    },

    async _destroy(mapper, id, opts) {
        this.dbg('_destroy', mapper, id, opts);
        return await this.execute(mapper, 'destroy', false, opts, id, {});
    },

    async _destroyAll(mapper, query, opts) {
        console.log('todo: Flesh out: _destroyAll');
        this.dbg('_destroyAll', mapper, query, opts);
        return await this.execute(mapper, 'destroy', true, opts, query, {});
    },

    async _find(mapper, id, opts) {
        this.dbg('_find', mapper, id, opts);
        return await this.execute(mapper, 'find', false, opts, id, {});
    },

    async _findAll(mapper, query, opts) {
        this.dbg('_findAll', mapper, query, opts);
        return await this.execute(mapper, 'find', true, opts, query, null);
    },

    async _update(mapper, id, props, opts) {
        this.dbg('_update', mapper, id, props, opts);
        return await this.execute(mapper, 'update', false, opts, id, props);
    },

    async _updateAll(mapper, props, query, opts) {
        console.log('todo: Flesh out: _updateAll');
        this.dbg('_updateAll', mapper, props, query, opts);
        return await this.execute(mapper, 'update', true, opts, query, props);
    },

    async _updateMany(mapper, records, opts) {
        this.dbg('_updateMany', mapper, records, opts);

        const method = 'update';
        const operationId = this.composeOperationId(mapper, method, true, opts);

        if (this.config.allApiOperations.hasOwnProperty(operationId)) {
            return await this.execute(mapper, method, true, opts, null, records);
        }

        return records.map((record) => {
            return this.update(mapper, record[mapper.idAttribute], record, opts);
        }, this);
    },

    async _count(mapper, query, opts) {
        this.dbg('_count', mapper, query, opts);
        this.error('Entity Count has not been implemented in js-data-swagger adapter.');
        //return await this.execute(mapper, 'count', false, opts, query, null);
    },

    async _sum(mapper, field, query, opts) {
        this.dbg('_sum', mapper, field, query, opts);
        this.error('Entity Sum has not been implemented in js-data-swagger adapter.');
        //return await this.execute(mapper, 'sum', true, opts, query, null);
    },

    /**
     * Get the plural name for a given resource type
     * @name SwaggerAdapter#buildPathParameters
     * @method
     * @param {object} mapper The Mapper.
     * @param {string} operationId The api operationId the params are being constructed for.
     * @param {*} params
     * @param {*} bodyProps
     * @param {*} opts Configuration options.
     * @returns {object} the path parameter object
     */
    buildPathParameters(mapper, operationId, params, bodyProps, opts) {
        let pathParams = {};

        this.dbg('buildPathParameters', mapper, operationId, params, bodyProps, opts);

        for (let i in this.config.allApiOperations[operationId]?.parameters) {
            if (this.config.allApiOperations[operationId].parameters[i].in === 'path') {
                let paramName = this.config.allApiOperations[operationId].parameters[i].name;

                if (params.hasOwnProperty(paramName)) {
                    pathParams[paramName] = params[paramName];
                } else if (bodyProps.hasOwnProperty(paramName)) {
                    pathParams[paramName] = bodyProps[paramName];
                } else if (
                    paramName === mapper.name + '_id' ||
                    paramName === mapper.name + 'Id' ||
                    paramName === this.toSnakeCase(mapper.name) + '_id'
                ) {
                    //This is for the case where params is JUST an id like in
                    // jsd.find('address', 1);
                    if (['string', 'number'].includes(typeof params)) {
                        pathParams[paramName] = params;
                    } else if (params.hasOwnProperty('id')) {
                        pathParams[paramName] = params.id;
                    } else if (bodyProps.hasOwnProperty('id')) {
                        pathParams[paramName] = bodyProps.id;
                    } else {
                        let pkey = mapper.idAttribute;
                        if (params.hasOwnProperty(pkey)) {
                            pathParams[paramName] = params[pkey];
                        } else if (bodyProps.hasOwnProperty(pkey)) {
                            pathParams[paramName] = bodyProps[pkey];
                        } else {
                            console.warn(
                                'Unresolvable primary key mapping (' + paramName + ') for ' + mapper.name,
                                params,
                                bodyProps
                            );
                        }
                    }
                } else {
                    if (mapper.parameterMappings?.hasOwnProperty(paramName)) {
                        if (params.hasOwnProperty(mapper.parameterMappings[paramName])) {
                            pathParams[paramName] = params[mapper.parameterMappings[paramName]];
                        } else if (bodyProps.hasOwnProperty(mapper.parameterMappings[paramName])) {
                            pathParams[paramName] = bodyProps[mapper.parameterMappings[paramName]];
                        } else {
                            console.warn(
                                "Unresolvable path parameter '" +
                                    paramName +
                                    ' -> ' +
                                    mapper.parameterMappings[paramName] +
                                    "' for " +
                                    mapper.name,
                                params,
                                bodyProps
                            );
                        }
                    } else {
                        console.warn(
                            "Unresolvable path parameter mapping '" + paramName + "' for " + mapper.name,
                            params,
                            bodyProps
                        );
                    }
                }
            }
        }

        return pathParams;
    },

    /**
     * Capitalize the first letter in a string.
     *
     * @name SwaggerAdapter#capitalizeFirstLetter
     * @method
     * @param string
     * @returns {string} with first letter capitalized.
     */
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    /**
     * Compose the swagger operationId that corresponds to a given
     * action on a given resource type.
     *
     * @name SwaggerAdapter#composeOperationId
     * @method
     * @param {object} mapper The Mapper for the requested resource.
     * @param {string} method The operation naming pattern to be followed.
     * @param {boolean} plural if the requested operation is the plural or singular
     * @param {*} opts The options object
     * @returns {string} The Swagger API operationId that corresponds to the given verb and resource.
     */
    composeOperationId(mapper, method, plural, opts) {
        this.dbg('composeOperationId', mapper, method, plural, opts);

        if (opts.operationId) {
            return opts.operationId;
        }

        const relativePath = this.getPluralKey(plural) + '.' + method;

        const fromOpts = this.getNestedValue('operationIds.' + relativePath, opts, false);
        if (fromOpts) {
            return fromOpts;
        }

        const fromMapper = this.getNestedValue('operationIds.' + relativePath, mapper, false);
        if (fromMapper) {
            return fromMapper;
        }

        const verb = this.getNestedValue(relativePath, this.config.restVerbs, '');
        const pluralName = this.getPluralName(mapper, opts);
        const pattern = this.getNestedValue(relativePath, this.config.namingPatterns, '');

        let result = pattern.replaceAll('%verb%', verb);
        result = result.replaceAll('%plural-name%', this.capitalizeFirstLetter(pluralName));

        for (let key in mapper) {
            if (mapper.hasOwnProperty(key) && (typeof mapper[key] === 'string' || mapper[key] instanceof String)) {
                result = result.replaceAll('%' + this.toKebabCase(key) + '%', this.capitalizeFirstLetter(mapper[key]));
            }
        }

        if (result.includes('%') && console) {
            console.warn('Unresolved replacement patterns in operationId composition: ', {
                mapper: mapper,
                method: method,
                pattern: pattern,
                plural: plural,
            });
        }

        return result;
    },

    /**
     * Log an error.
     *
     * @name SwaggerAdapter#error
     * @method
     * @param {...*} [args] Arguments to log.
     */
    error(...args) {
        if (typeof this.config.errorCallback === 'function') {
            this.config.errorCallback(...args);
        } else {
            console[typeof console.error === 'function' ? 'error' : 'log'](...args);
        }
    },

    /**
     * Execute an API action
     *
     * @name SwaggerAdapter#execute
     * @method
     * @param {object} mapper The Mapper.
     * @param {string} method
     * @param {boolean} plural
     * @param {*} opts Configuration options.
     * @param {*} params
     * @param {*} props
     * @returns {*}
     */
    async execute(mapper, method, plural, opts, params, props) {
        this.dbg('execute', mapper, method, plural, opts, params, props);

        params ??= {};
        props ??= {};
        const operationId = this.composeOperationId(mapper, method, plural, opts);
        const apiSection = this.getApiSection(mapper, operationId, opts);
        const pathParams = this.buildPathParameters(mapper, operationId, params, props, opts);
        const linkParams = opts['__swaggerLinkParams__']?.[operationId];
        delete opts['__swaggerLinkParams__'];
        const relationParams = opts['__swaggerParams__'];
        delete opts['__swaggerParams__'];

        params = { ...linkParams, ...relationParams, ...pathParams, ...params };

        if (this.config.allApiOperations[operationId]) {
            this.dbg('Executing API call swaggerClient.apis.' + apiSection + '.' + operationId, params, props);
            const swaggerOpts = opts.swaggerOptions || {};
            let response;
            if (['get', 'head'].includes(this.config.allApiOperations[operationId].verb)) {
                props = { ...props, ...swaggerOpts };
            } else {
                props = { requestBody: props, ...swaggerOpts };
            }
            response = await this.config.swaggerClient.apis[apiSection][operationId](params, props);
            return [
                this.getNestedValue(this.getResponseDataPath(mapper, method, plural, opts), response, []),
                {
                    response: response,
                    props: props,
                    params: params,
                    operationId: operationId,
                    apiSection: apiSection,
                    metadata: this.normalizeMetadata(mapper, response, method, plural, opts),
                },
            ];
        } else {
            this.error('Swagger Error: API Operation ' + operationId + ' not found');
            //todo: A missing operation should optionally trigger a throw()
            return [
                null,
                {
                    response: null,
                    metadata: null,
                    errors: ['API Operation ' + operationId + ' not found'],
                },
            ];
        }
    },

    /**
     * Get the plural name for a given resource type
     * @name SwaggerAdapter#getApiSection
     * @method
     * @param {object} mapper The Mapper.
     * @param {string} operationId The API operation ID being taken.
     * @param {*} opts Configuration options.
     * @returns {string} The name of the API section to use for executing
     */
    getApiSection(mapper, operationId, opts) {
        this.dbg('getApiSection', mapper, operationId, opts);

        if (
            this.config.allApiOperations.hasOwnProperty(operationId) &&
            this.config.allApiOperations[operationId].hasOwnProperty('tags')
        ) {
            for (let i in this.config.allApiOperations[operationId].tags) {
                if (
                    this.config.swaggerClient.apis.hasOwnProperty(this.config.allApiOperations[operationId].tags[i]) &&
                    utils.isFunction(
                        this.config.swaggerClient.apis[this.config.allApiOperations[operationId].tags[i]][operationId]
                    )
                ) {
                    return this.config.allApiOperations[operationId].tags[i];
                }
            }
        }

        if (mapper.hasOwnProperty('apiSection')) {
            return mapper.apiSection;
        }

        if (opts.hasOwnProperty('apiSection')) {
            return opts.apiSection;
        }

        // This is what the swagger client falls back on when an operation hasn't been tagged.
        return 'default';
    },

    /**
     * Get the plural name for a given resource type
     * @name SwaggerAdapter#getNestedValue
     * @method
     * @param {string|array} path - The path to traverse to find the requested value.
     * @param {object} obj - The object to retrieve the value from.
     * @param {*} defaultValue - The value to return if the path is undefined.
     * @returns {*} The nested value requested.
     */
    getNestedValue(path, obj, defaultValue) {
        if (typeof path === 'string' || path instanceof String) {
            if (path === '*') {
                return obj;
            }
            path = path.split('.');
        }
        let key = path.shift();
        if (!obj.hasOwnProperty(key)) {
            return defaultValue;
        }
        if (path.length === 0) {
            return obj[key];
        }

        return this.getNestedValue(path, obj[key], defaultValue);
    },

    /**
     * Get the plural name for a given resource type
     * @name SwaggerAdapter#getPluralKey
     * @method
     * @param {boolean} plural If its plural or not
     * @returns {string} 'plural' | 'singular'
     */
    getPluralKey(plural) {
        return plural ? 'plural' : 'singular';
    },

    /**
     * Get the plural name for a given resource type
     * @name SwaggerAdapter#getPluralName
     * @method
     * @param {object} mapper The Mapper.
     * @param {*} opts Configuration options.
     * @returns {string} Pluralized name.
     */
    getPluralName(mapper, opts) {
        if (opts.pluralName) {
            return opts.pluralName;
        }

        if (mapper.pluralName) {
            return mapper.pluralName;
        }

        if (this.config.pluralModelNames.hasOwnProperty(mapper.name)) {
            return this.config.pluralModelNames[mapper.name];
        }

        if (mapper.name.endsWith('y')) {
            return mapper.name.substring(0, mapper.name.length - 1) + 'ies';
        }
        return mapper.name + (mapper.name.endsWith('s') ? 'es' : 's');
    },

    /**
     * Get the plural name for a given resource type
     * @name SwaggerAdapter#getResponseDataPath
     * @method
     * @param {object} mapper The Mapper.
     * @param {string} method The method we're working with.
     * @param {boolean} plural If this is the plural action
     * @param {*} opts Configuration options.
     * @returns {string} The path to retrieve data for a given resource/action pair
     */
    getResponseDataPath(mapper, method, plural, opts) {
        return this.getNestedValue(
            'paths.response.' + this.getPluralKey(plural) + '.' + method,
            mapper,
            mapper.defaultResponsePath || this.config.defaultResponsePath
        );
    },

    /**
     * Given a result set from a swagger request determine if there are any links
     * and if so collect the relevant parameters from the response so they can be
     * used in subsequent calls.
     *
     * This is an expansion of Adapter#loadRelationsFor
     *
     * @name SwaggerAdapter#loadRelationsFor
     * @param mapper
     * @param results
     * @param opts
     * @returns {Promise<Awaited<unknown>[]>}
     */
    loadRelationsFor(mapper, results, opts) {
        this.dbg('loadRelationsFor', mapper, results, opts);

        const [records] = results;
        const tasks = [];

        if (records && records.length > 0) {
            const links =
                this.config.allApiOperations[results[1].operationId].responses[results[1].response.status]?.links || {};

            //Load up the link parameters
            let linkParams = {};
            utils.forOwn(links, (link, name) => {
                linkParams[name] = { [link.operationId]: this.parseSwaggerLinkParams(link.parameters, results[1]) };
            });

            this.dbg('links', links);
            this.dbg('linkParams', linkParams);

            utils.forEachRelation(mapper, opts, (def, __opts) => {
                let task;

                if (linkParams.hasOwnProperty(def.localField)) {
                    __opts['__swaggerLinkParams__'] = linkParams[def.localField];
                }

                if (def.hasOwnProperty('params')) {
                    __opts['__swaggerParams__'] = def.params;
                }

                if (def.foreignKey && (def.type === hasOneType || def.type === hasManyType)) {
                    if (def.type === hasOneType) {
                        task = this.loadHasOne(mapper, def, records, __opts);
                    } else {
                        task = this.loadHasMany(mapper, def, records, __opts);
                    }
                } else if (def.type === hasManyType && def.localKeys) {
                    task = this.loadHasManyLocalKeys(mapper, def, records, __opts);
                } else if (def.type === hasManyType && def.foreignKeys) {
                    task = this.loadHasManyForeignKeys(mapper, def, records, __opts);
                } else if (def.type === belongsToType) {
                    task = this.loadBelongsTo(mapper, def, records, __opts);
                } else if (def.type === MorphManyType) {
                    task = LoadMorphMany(this, mapper, def, records, __opts);
                } else {
                    this.dbg('Unknown relationship type: ', def);
                }
                if (task) {
                    tasks.push(task);
                }
            });
        }

        return utils.Promise.all(tasks).then(() => results);
    },

    /**
     * @name SwaggerAdapter#normalizeMetadata
     * @param {object} mapper The Mapper.
     * @param response
     * @param {string} method The method we're working with.
     * @param {boolean} plural If this is the plural action
     * @param {*} opts Configuration options.
     * @returns {*} The metadata contained in the response.
     */
    normalizeMetadata(mapper, response, method, plural, opts) {
        opts || (opts = {});
        let metadataPath =
            opts.metadataPath ||
            this.getNestedValue(
                'paths.metadata.' + this.getPluralKey(plural) + '.' + method,
                mapper,
                mapper.defaultMetadataPath
            ) ||
            this.config.defaultMetadataPath;
        return this.getNestedValue(metadataPath, response, {});
    },

    /**
     * Given a collection of name:runtime_expression pairs it will return a
     * collection of key:value pairs that can be merged into an API call opts obj
     *
     * @name SwaggerAdapter#parseSwaggerLinkParams
     * @param {object} params
     * @param {object} previous - All of the metadata from the previous request/response (returned
     * as metadata from SwaggerAdapter#execute)
     * @returns {object} - collection of key value pairs for use as api params
     */
    parseSwaggerLinkParams(params, previous) {
        this.dbg('parseSwaggerLinkParams', params, previous);
        let results = {};
        if (params) {
            utils.forOwn(params, (rteString, name) => {
                if (rteString.startsWith('$')) {
                    results[name] = this.parseSwaggerLinkRuntimeSyntax(rteString, previous);
                } else {
                    let resString = rteString;
                    let findParts = rteString.match(/{\$[^}]*}/g);

                    if (findParts) {
                        let replaceParts = findParts.map((rte) => {
                            return this.parseSwaggerLinkRuntimeSyntax(rte.slice(1, -1), previous);
                        });
                        if (findParts.length !== replaceParts.length) {
                            throw new Error("Somehow the find and replace lengths don't match.");
                        }
                        for (let i = 0; i < findParts.length; i++) {
                            resString = resString.replace(findParts[i], replaceParts[i]);
                        }
                    }
                    results[name] = resString;
                }
            });
        }
        return results;
    },

    /**
     * Parses a RuntimeSyntax string and returns the appropriate value
     *
     * @name SwaggerAdapter#parseSwaggerLinkRuntimeSyntax
     * @param {String} rteString - A properly formed Runtime Expression from the OAS spec.
     * @param {object} previous - All of the metadata from the previous request/response (returned
     * as metadata from SwaggerAdapter#execute)
     * @returns {*}
     */
    parseSwaggerLinkRuntimeSyntax(rteString, previous) {
        this.dbg('parseSwaggerLinkRuntimeSyntax', rteString, previous);

        //['$url', '$method', '$request.query.param_name', '$request.path.param_name', '$request.header.header_name', '$request.body', '$request.body#/foo/bar', '$statusCode', '$response.header.header_name', '$response.body', '$response.body#/foo/bar']
        let parts;
        switch (rteString.replace(/\$([a-z]*).*/i, '$1')) {
            case 'url':
                return previous.response.url;
            case 'method':
                return this.config.allApiOperations[previous.operationId].verb;
            case 'statusCode':
                return previous.response.status;
            case 'request':
                if (rteString === '$request.body') {
                    return previous.params.requestBody;
                }
                parts = rteString.split('.');
                switch (parts[1].replace(/([a-z]*).*/i, '$1')) {
                    case 'query':
                    case 'path':
                        return previous.params[parts[2]];
                    case 'header':
                        //todo: find a simple way to implement this. I don't have time to sort it out now.
                        throw new Error('This has yet to be implemented. If you need it, submit a PR.');
                    //return previous.request.headers[parts[2]];
                    case 'body':
                        return this.getNestedValue(
                            parts[1].replace('body#/', '').split('/'),
                            previous.params.requestBody,
                            null
                        );
                }
                break;
            case 'response':
                if (rteString === '$response.body') {
                    return previous.response.body;
                }
                parts = rteString.split('.');
                switch (parts[1].replace(/([a-z]*).*/i, '$1')) {
                    case 'header':
                        return previous.response.headers[parts[2]];
                    case 'body':
                        return this.getNestedValue(
                            parts[1].replace('body#/', '').split('/'),
                            previous.response.body,
                            null
                        );
                }
        }
        throw new Error('Unknown or unsupported Runtime Expression: ' + rteString);
    },

    /**
     * Turns a camelCase string in to kebab case
     *
     * @name SwaggerAdapter#toKebabCase
     * @method
     * @param {string} str
     * @returns {string}
     */
    toKebabCase(str) {
        return str
            .replace(/^[A-Z]/, (letter) => `${letter.toLowerCase()}`)
            .replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
    },

    /**
     * Turns a camelCase string in to kebab case
     *
     * @name SwaggerAdapter#toSnakeCase
     * @method
     * @param {string} str
     * @returns {string}
     */
    toSnakeCase(str) {
        return str
            .replace(/^[A-Z]/, (letter) => `${letter.toLowerCase()}`)
            .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
    },
});
