// @ts-check
import _ from 'lodash';
require('regenerator-runtime');
const qs = require('qs');
import { mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full';
import VueAnalytics from 'vue-analytics';
import VueClip from 'vue-clip';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VueRouter from 'vue-router';
import VueCompositionAPI from '@vue/composition-api';

import AutocompleteTag from 'component/shared/elements/AutocompleteTag';
import EmpAlert from 'component/shared/elements/Alert';
import EmpButton from 'component/shared/elements/Button';
import EmpCheckbox from 'component/shared/elements/Checkbox';
import EmpCheckboxCluster from 'component/shared/elements/CheckboxCluster';
import EmpCheckboxClusterWithAddText from 'component/shared/elements/CheckboxClusterWithAddText';
import EmpComponentModal from 'component/shared/elements/ComponentModal';
import EmpConfirmationButton from 'component/shared/elements/ConfirmationButton';
import EmpDatepicker from 'component/shared/elements/Datepicker';
import EmpEditableContent from 'component/shared/elements/EditableContent';
import EmpEmailList from 'component/shared/elements/EmailList';
import EmpLargeOrderNotification from 'component/shared/LargeOrderNotification';
import EmpLargeOrderNotificationForm from 'component/shared/LargeOrderNotificationForm';
import EmpLineSubcontent from 'component/admin/LineSubcontent';
import EmpNotes from 'component/shared/Notes';
import EmpPagination from 'component/shared/elements/Pagination';
import EmpQuickbar from 'component/shared/elements/QuickbarTab';
import EmpRadio from 'component/shared/elements/Radio';
import EmpSelect from 'component/shared/elements/Select';
import EmpSelectCreditCard from 'component/admin/SelectCreditCard';
import EmpSelectFacilityUser from 'component/admin/SelectFacilityUser';
import EmpSelectInventoryVendor from 'component/admin/elements/SelectInventoryVendor';
import EmpSelectRole from 'component/shared/elements/SelectRole';
import EmpSelectServiceTypeShippingMethod from 'component/shared/elements/SelectServiceTypeShippingMethod';
import EmpSelectState from 'component/shared/elements/SelectState';
import EmpSelectStatus from 'component/shared/elements/SelectStatus';
import EmpSelectTerm from 'component/shared/elements/SelectTerm';
import EmpSelectWarehouse from 'component/shared/elements/SelectWarehouse';
import EmpSimpleFormModal from 'component/shared/elements/SimpleFormModal';
import EmpTag from 'component/shared/elements/TagField';
import EmpTextarea from 'component/shared/elements/Textarea';
import EmpTextbox from 'component/shared/elements/Textbox';
import EmpTitlebar from 'component/shared/elements/Titlebar';
import EmpToast from 'component/shared/elements/Toast';
import EmpTooltip from 'component/shared/elements/EmpTooltip';
import EmpTooltipNotes from 'component/shared/TooltipNotes';
import LoadingIndicator from 'component/shared/elements/LoadingIndicator';
import PassportAuthorizedClients from 'component/passport/AuthorizedClients';
import UserInvitationsForm from 'component/admin/UserInvitationsForm';
import UserRoleForm from 'component/admin/UserRoleForm';
import VendorRepresentativeForm from 'component/admin/VendorRepresentativeForm';
import {
    sanitizeHtml,
    headers,
    formatters,
    systemNotifications,
    highlightErrorFields,
    dialogueBoxControls,
    authorizeAndPerformAction,
} from 'component/shared/elements/mixins';
import route from '../ziggyRoute';
import router from '../router';
import guards from '../guards';
import Config from './baseConfig';

const axios = {
    headers: {
        common: headers,
    },
    hideLoader: false,
    withCredentials: true,
    paramsSerializer: function (/** @type {any} */ params) {
        return qs.stringify(params, { arrayFormat: 'indices' });
    },
};

const filters = {
    phoneNumber: function (/** @type {string} */ value) {
        let formatted = '';

        if (value) {
            value = value.toString();
            let matches = value.replace(/[^\d]+/, '').match(/([\d]{0,2})([\d]{3})([\d]{3})([\d]{4})/);

            if (matches) {
                formatted = `(${matches[2]}) ${matches[3]}-${matches[4]}`;
            }
        }

        return formatted;
    },
};

const mixins = _.merge(
    sanitizeHtml,
    formatters,
    systemNotifications,
    highlightErrorFields,
    dialogueBoxControls,
    authorizeAndPerformAction,
    {
        computed: {
            ...mapGetters('shared/currentUser', {
                currentUser: 'user',
                currentUserIsFacility: 'isFacility',
                currentUserIsAdmin: 'isAdmin',
                currentUserCan: 'can',
                currentUserType: 'userType',
                currentUserFacilityId: 'facilityId',
                currentUserFacilityName: 'facilityName',
                currentUserIsAuthenticated: 'isAuthenticated',
                currentUserIsUnauthenticated: 'isUnauthenticated',
            }),
        },
    },
    {
        methods: {
            route: route,
        },
    }
);
const components = {
    'autocomplete-tag': AutocompleteTag,
    'emp-alert': EmpAlert,
    'emp-button': EmpButton,
    'emp-checkbox': EmpCheckbox,
    'emp-checkbox-cluster': EmpCheckboxCluster,
    'emp-checkbox-cluster-with-add-text': EmpCheckboxClusterWithAddText,
    'emp-confirmation-button': EmpConfirmationButton,
    'emp-email-list': EmpEmailList,
    'emp-radio': EmpRadio,
    'emp-select': EmpSelect,
    'emp-select-state': EmpSelectState,
    'emp-select-term': EmpSelectTerm,
    'emp-select-inventory-vendor': EmpSelectInventoryVendor,
    'emp-select-service-type-shipping-method': EmpSelectServiceTypeShippingMethod,
    'emp-select-role': EmpSelectRole,
    'emp-select-status': EmpSelectStatus,
    'emp-tag': EmpTag,
    'emp-textarea': EmpTextarea,
    'emp-textbox': EmpTextbox,
    'emp-titlebar': EmpTitlebar,
    'emp-simple-form-modal': EmpSimpleFormModal,
    'emp-tooltip': EmpTooltip,
    'emp-datepicker': EmpDatepicker,
    'emp-pagination': EmpPagination,
    'emp-line-subcontent': EmpLineSubcontent,
    'emp-loading-indicator': LoadingIndicator,
    'emp-component-modal': EmpComponentModal,
    'vendor-representative-form': VendorRepresentativeForm,
    'emp-toast': EmpToast,
    'emp-quickbar': EmpQuickbar,
    'user-invitations-form': UserInvitationsForm,
    'user-role-form': UserRoleForm,
    'emp-select-credit-card': EmpSelectCreditCard,
    'emp-notes': EmpNotes,
    'emp-editable-content': EmpEditableContent,
    'emp-tooltip-notes': EmpTooltipNotes,
    'emp-large-order-notification-form': EmpLargeOrderNotificationForm,
    'emp-large-order-notification': EmpLargeOrderNotification,
    'emp-select-facility-user': EmpSelectFacilityUser,
    'passport-authorized-clients': PassportAuthorizedClients,
    'emp-select-warehouse': EmpSelectWarehouse,
    ValidationProvider: ValidationProvider,
    ValidationObserver: ValidationObserver,
};

const validationRules = {
    maxLength: {
        params: ['max'],
        /**
         * @param {string | any[]} value
         * @param {object} args
         * @param {Number} args.max
         */
        validate(value, { max }) {
            const length = value && value.length;
            return length <= max;
        },
        message: 'The {_field_} length must be less than {max}.',
    },
    notIn: {
        params: ['list'],
        /**
         * @param {string} value
         * @param {object} args
         * @param {string[]|string} args.list
         */
        validate(value, { list }) {
            // If there's only one item, then list will be a string.
            // Thanks Vee-Validate...
            if (_.isString(list)) {
                list = list.split(',');
            }
            list = list.map((/** @type {string} */ item) => item.toUpperCase());
            value = value.toUpperCase();
            return !list.includes(value);
        },
        message: 'The {_field_} already exists.',
    },
};

const plugins = [
    { plugin: VueCompositionAPI, options: [] },
    {
        plugin: VueAnalytics,
        options: [
            {
                // Consider using different tracking IDs for admin vs facility.
                // @ts-ignore
                id: GA_PROPERTY_ID,
                router,
                // Script "should" be loaded from app.blade.php
                checkDuplicatedScript: true,
                autoTracking: {
                    // Prevent pageview submission when the tracker loads. If this is `true`,
                    // two page views will be submitted for `/app` when a user logs in: one from
                    // VueAnalytics & one from the router broadcasting a route change.
                    pageviewOnLoad: false,
                },
            },
        ],
    },
    {
        // The component provided by VueClip should not be used
        // use EmpireVueClip instead.
        plugin: VueClip,
        options: [],
    },
    { plugin: VueFlatPickr, options: [] },
    { plugin: VueRouter, options: [] },
];

export default new Config(axios, filters, mixins, components, validationRules, plugins, router, guards);
