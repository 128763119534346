import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            morphMany: {
                ...auditsRelation('order'),
            },
        },
    },
};
