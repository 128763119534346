export const CLEAR_STORED_SEARCH_PARAMS = 'clearStoredSearchParams';
export const LOAD_FILTER_PREFERENCES = 'loadFilterPreferences';
export const REMOVE_LINE_AT_INDEX = 'removeLineAtIndex';
export const RESET_FILTERS_HAVE_CHANGED = 'resetFiltersHaveChanged';
export const SET_APPLIED_FILTERS = 'setAppliedFilters';
export const SET_DATA = 'setData';
export const SET_DEFAULT_FILTERS = 'setDefaultFilters';
export const SET_DEFAULT_PAGINATION_DATA = 'setDefaultPaginationData';
export const SET_DEFAULT_SORT = 'setDefaultSort';
export const SET_FILTER_VALUE = 'setFilterValue';
export const SET_FILTERS = 'setFilters';
export const SET_FILTER_OVERRIDE = 'setFilterOverride';
export const SET_PAGE = 'setPage';
export const SET_PAGINATION_DATA = 'setPaginationData';
export const SET_PER_PAGE = 'setPerPage';
export const SET_PREFERENCES_KEY = 'setPreferencesKey';
export const SET_FILTER_PREFERENCES = 'setFilterPreferences';
export const SET_SEARCH_SUBMITTED = 'setSearchSubmitted';
export const SET_SORT = 'setSort';
export const SET_TABLE_DATA_LOADED = 'setTableDataLoaded';
export const STORE_SEARCH_PARAMS = 'storeSearchParams';
export const UPDATE_BY_LINE_ID = 'updateByLineId';

export const TABLE_MUTATIONS = {
    SET_DATA,
    SET_PAGINATION_DATA,
    SET_TABLE_DATA_LOADED,
    STORE_SEARCH_PARAMS,
    SET_SEARCH_SUBMITTED,
};

export const TABLE_FILTERS_MUTATIONS = {
    CLEAR_STORED_SEARCH_PARAMS,
    SET_FILTERS,
    SET_FILTER_VALUE,
    SET_PAGE,
    SET_SORT,
    SET_FILTER_OVERRIDE,
};

export const PAGINATION_MUTATIONS = {
    SET_PAGE,
    SET_FILTER_OVERRIDE,
};

export default {};
