<template>
    <!--
    -->
    <p>
        <!--
        --><template v-if="noteType === 'general'"
            ><!--
            --><vendor-general-notes :vendor="vendor" /><!--
            --><template v-if="showOrderingEmail"
                ><!--
                --><strong>Ordering Email:</strong><br /><!--
                --><span
                    ><!--
                    --><span v-for="(address, index) in vendor.orderingEmailAddresses" :key="index"
                        ><!--
                            -->{{ address
                        }}<!--
                            --><br v-if="index + 1 < vendor.orderingEmailAddresses.length" /><!--
                    --></span
                    ><!--
                --></span
                ><!--
                --><br /><!--
                --><br /><!--
            --></template
            ><!--
            --><strong>Avg Discount:</strong><br /><!--
            -->{{ vendor.averageDiscount
            }}<!--
            --><br /><!--
            --><br /><!--
            --><strong>Default Markup:</strong><br /><!--
            -->{{ vendor.defaultMarkup
            }}<!--
            --><br /><!--
        --></template
        ><!--
        --><template v-else-if="noteType === 'return'"
            ><!--
            --><template v-if="vendor.returnPolicyEmpire"
                ><!--
                --><strong>Return Policies/Procedures - Empire:</strong
                ><!--
                --><br /><!--
                -->{{ vendor.returnPolicyEmpire
                }}<!--
                --><br /><!--
                --><br /><!--
            --></template
            ><!--
            --><template v-if="vendor.returnInstructions"
                ><!--
                --><strong>Return Instructions for Customers:</strong
                ><!--
                --><br /><!--
                -->{{ vendor.returnInstructions
                }}<!--
                --><br /><!--
                --><br /><!--
            --></template
            ><!--
            --><template v-if="vendor.returnsEmailAddresses && vendor.returnsEmailAddresses.length"
                ><!--
                --><strong>Returns Email Address:</strong
                ><!--
                --><br /><!--
                --><span
                    ><!--
                    --><span v-for="(address, index) in vendor.returnsEmailAddresses" :key="index"
                        ><!--
                            -->{{ address
                        }}<!--
                            --><br v-if="index + 1 < vendor.returnsEmailAddresses.length" /><!--
                    --></span
                    ><!--
                --></span
                ><!--
            --></template
            ><!--
        --></template
        ><!--
        --><template v-else-if="noteType === 'account'"
            ><!--
            --><strong>Empire Acct #:</strong
            ><!--
            --><br /><!--
            -->{{ vendor.accountNumber
            }}<!--
            --><br /><!--
            --><br /><!--
            --><strong>Phone #:</strong
            ><!--
            -->{{ vendor.phoneNumber
            }}<!--
            --><span v-if="representatives"
                ><!--
                --><br /><!--
                --><br /><!--
                --><strong>Reps:</strong
                ><!--
                -->
                <div>
                    <!--
                    --><span v-for="(rep, index) in vendor.representatives" :key="index"
                        ><!--
                        -->{{ formatRepresentative(rep)
                        }}<!--
                        --><br v-if="index + 1 < vendor.representatives.length" /><!--
                        --><br v-if="index + 1 < vendor.representatives.length" /><!--
                    --></span
                    ><!--
                --></div>
                <!--
                --><br /><!--
            --></span
            ><!--
        --></template
        ><!--
    --></p>
    <!--
--></template>

<script>
import VendorGeneralNotes from 'component/admin/VendorGeneralNotes';

export default {
    props: {
        vendor: Object,
        noteType: {
            type: String,
            default: 'general',
        },
    },
    components: {
        VendorGeneralNotes,
    },
    computed: {
        orderByCutoff() {
            return _.get(this.vendor, 'cutoffTimeValue', 'UNK');
        },
        hardCutoff() {
            return _.get(this.vendor, 'hardCutoffTimeValue', 'UNK');
        },
        representatives() {
            return _.get(this.vendor, 'representatives');
        },
        showOrderingEmail() {
            return this.vendor && this.vendor.orderingEmailAddresses && this.vendor.orderingEmailAddresses.length;
        },
    },
    methods: {
        formatRepresentative(rep) {
            let formatted = '';

            formatted += rep.name || '';
            formatted += formatted.length && rep.title ? ', Title: ' : '';
            formatted += rep.title || '';
            formatted += formatted.length && rep.phoneNumber ? ', Phone: ' : '';
            formatted += rep.phoneNumber ? rep.phoneNumber : '';
            formatted += formatted.length && rep.emailAddress ? ', Email: ' : '';
            formatted += rep.emailAddress ? rep.emailAddress : '';
            formatted += formatted.length && rep.notes ? ', Notes: ' : '';
            formatted += rep.notes ? rep.notes : '';

            return formatted;
        },
    },
};
</script>
