import _ from 'lodash';
import ShippingMethodsApi from 'api/endpoints/shippingMethods';

export default {
    namespaced: true,
    state: {
        dropdownData: [],
        inRequest: false,
        map: {}
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
        },
        mapMethods(store, payload) {
            store.map = {};
            payload.forEach(shippingMethod => {
                store.map[shippingMethod.id] = shippingMethod;
            });
        }
    },
    actions: {
        async getDropdownData({ state, commit }, params = {}) {
            if (state.inRequest === false) {
                state.inRequest = true;

                let response = await ShippingMethodsApi.get(params);
                let shippingMethods = response.data.data;
                commit('setDropdownData', shippingMethods);
                commit('mapMethods', shippingMethods);
                state.inRequest = false;
            }
        },
        async saveReorder({ state}) {
            let shippingMethods = _.cloneDeep(state.dropdownData);
            return await ShippingMethodsApi.saveReorder(shippingMethods);
        },
        reorderDropdown({ getters, state, commit }, {shippingMethodId, up, dropdownModifier}) {      
            let orderVariable = _.camelCase(dropdownModifier + 'DropdownOrder');
            let shippingMethod = _.clone(state.map[shippingMethodId]);
            if (shippingMethod[orderVariable] === 0 && up) { return }

            let relatedShippingMethods = getters.relatedShippingMethods(shippingMethodId);
            if (_.maxBy(relatedShippingMethods, method => method[orderVariable])[orderVariable] === shippingMethod[orderVariable] && !up) { return }
            
            let stateShippingMethods = _.cloneDeep(state.dropdownData);
            let methodIndex = stateShippingMethods.findIndex(method => method.id === shippingMethod.id);

            let newDropdownOrder = up ? shippingMethod[orderVariable] - 1 : shippingMethod[orderVariable] + 1;
            
            let displacedId = relatedShippingMethods.find(method => method[orderVariable] === newDropdownOrder).id;
            let displacedIndex = stateShippingMethods.findIndex(method => method.id === displacedId);

            if (methodIndex !== -1) {
                stateShippingMethods[methodIndex][orderVariable] = newDropdownOrder;
            }
            if (displacedIndex !== -1) {
                stateShippingMethods[displacedIndex][orderVariable] = shippingMethod[orderVariable];
            }

            commit('setDropdownData', stateShippingMethods);
            commit('mapMethods', stateShippingMethods);
        },
        addMethodToDropdown( {state, getters, commit}, {shippingMethodId, dropdownModifier}) {
            let orderVariable = _.camelCase(dropdownModifier + 'DropdownOrder');
            let stateShippingMethods = _.cloneDeep(state.dropdownData);
            let lastMethod = _.maxBy(getters.relatedShippingMethods(shippingMethodId), method => method[orderVariable]);
            stateShippingMethods.find(method => method.id === shippingMethodId)[orderVariable] = lastMethod ? lastMethod[orderVariable] + 1 : 0;
            
            commit('setDropdownData', stateShippingMethods);
            commit('mapMethods', stateShippingMethods);
        },
        removeDropdown({state, getters, commit}, {shippingMethodId, dropdownModifier}) {
            let orderVariable = _.camelCase(dropdownModifier + 'DropdownOrder');
            let originalDropdownOrder = state.map[shippingMethodId][orderVariable];
            let stateShippingMethods = _.cloneDeep(state.dropdownData);
            let relatedShippingMethodIds = new Set(getters.relatedShippingMethods(shippingMethodId).map(m => m.id));

            stateShippingMethods.forEach(method => {
                if (!relatedShippingMethodIds.has(method.id)) { return }
                if (method.id === shippingMethodId) { method[orderVariable] = null }
                return method[orderVariable] > originalDropdownOrder ? --method[orderVariable] : method[orderVariable];
            });

            commit('setDropdownData', stateShippingMethods);
            commit('mapMethods', stateShippingMethods);
        },
    },
    getters: {
        shippingMethods(state) {
            return state.dropdownData;
        },
        empireMethods(state) {
            return state.dropdownData.filter(method => method.isEmpireAccount);
        },
        vendorMethods(state) {
            return state.dropdownData.filter(method => !method.isEmpireAccount);
        },
        nonFlatRateMethods(state) {
            return state.dropdownData.filter(method => !method.isFlatRate);
        },
        flatRateMethods(state) {
            return state.dropdownData.filter(method => method.isFlatRate);
        },
        relatedShippingMethods(state, getters) {
            return (shippingMethodId) => {
                const shippingMethod = state.map[shippingMethodId];

                if (shippingMethod.isEmpireAccount) {
                    return getters.empireMethods;
                } else {
                    return getters.vendorMethods.filter(method => method.shippingServiceId === shippingMethod.shippingServiceId);
                }
            }  
        },
        methodWhereId(state) {
            return (methodId) => {
                return state.dropdownData.find(method => method.id == methodId);
            }
        },
        methodWhereImmutableName(state) {
            return (immutableName) => {
                return state.dropdownData.find(method => method.immutableName == immutableName);
            }
        },
        empireShippingMethodOptions(state, getters) {
            const filteredDropdowns = _.filter(getters.empireMethods, method => Number.isInteger(method.dropdownOrder));
            const sortedDropdowns = _.sortBy(filteredDropdowns, method => method.dropdownOrder);
            let options = [];
            const fallback = {
                id: null,
                name: null,
            };
            
            sortedDropdowns.forEach((dd) => {
                while (options.length < dd.dropdownOrder) {
                    fallback.dropdownOrder = options.length;
                    options.push(_.cloneDeep(fallback));
                }
                options.push(dd);
            });

            return options;
        },
        billEmpireShippingMethodOptions(state, getters) {
            const filteredDropdowns = _.filter(getters.empireMethods, method => Number.isInteger(method.billDropdownOrder));
            const sortedDropdowns = _.sortBy(filteredDropdowns, method => method.billDropdownOrder);
            let options = [];
            const fallback = {
                id: null,
                name: null,
            };
            
            sortedDropdowns.forEach((dd) => {
                while (options.length < dd.billDropdownOrder) {
                    fallback.billDropdownOrder = options.length;
                    options.push(_.cloneDeep(fallback));
                }
                options.push(dd);
            });

            return options;
        },
        invoiceEmpireShippingMethodOptions(state, getters) {
            const filteredDropdowns = _.filter(getters.empireMethods, method => Number.isInteger(method.invoiceDropdownOrder));
            const sortedDropdowns = _.sortBy(filteredDropdowns, method => method.invoiceDropdownOrder);
            let options = [];
            const fallback = {
                id: null,
                name: null,
            };
            
            sortedDropdowns.forEach((dd) => {
                while (options.length < dd.invoiceDropdownOrder) {
                    fallback.invoiceDropdownOrder = options.length;
                    options.push(_.cloneDeep(fallback));
                }
                options.push(dd);
            });

            return options;
        },
        vendorShippingMethodOptions(state, getters) {
            const filteredDropdowns = _.filter(getters.vendorMethods, method => Number.isInteger(method.dropdownOrder));
            const sortedDropdowns = _.sortBy(filteredDropdowns, method => method.dropdownOrder);
            let options = [];
            const fallback = {
                id: null,
                name: null,
            };
            
            sortedDropdowns.forEach((dd) => {
                while (options.length < dd.dropdownOrder) {
                    fallback.dropdownOrder = options.length;
                    options.push(_.cloneDeep(fallback));
                }
                options.push(dd);
            });

            return options;
        },
        billVendorShippingMethodOptions(state, getters) {
            const filteredDropdowns = _.filter(getters.vendorMethods, method => Number.isInteger(method.billDropdownOrder));
            const sortedDropdowns = _.sortBy(filteredDropdowns, method => method.billDropdownOrder);
            let options = [];
            const fallback = {
                id: null,
                name: null,
            };
            
            sortedDropdowns.forEach((dd) => {
                while (options.length < dd.billDropdownOrder) {
                    fallback.billDropdownOrder = options.length;
                    options.push(_.cloneDeep(fallback));
                }
                options.push(dd);
            });

            return options;
        },
        invoiceVendorShippingMethodOptions(state, getters) {
            const filteredDropdowns = _.filter(getters.vendorMethods, method => Number.isInteger(method.invoiceDropdownOrder));
            const sortedDropdowns = _.sortBy(filteredDropdowns, method => method.invoiceDropdownOrder);
            let options = [];
            const fallback = {
                id: null,
                name: null,
            };
            
            sortedDropdowns.forEach((dd) => {
                while (options.length < dd.invoiceDropdownOrder) {
                    fallback.invoiceDropdownOrder = options.length;
                    options.push(_.cloneDeep(fallback));
                }
                options.push(dd);
            });

            return options;
        },
        methodsNotInDropdown (state) {
            return _.filter(state.dropdownData, method => method.dropdownOrder === null && method.id !== 0);
        },
        billMethodsNotInDropdown (state) {
            return _.filter(state.dropdownData, method => method.billDropdownOrder === null && method.id !== 0);
        },
        invoiceMethodsNotInDropdown (state) {
            return _.filter(state.dropdownData, method => method.invoiceDropdownOrder === null && method.id !== 0);
        },
    }
}