// @ts-check
import _ from 'lodash';
import state from './state.js';
import getters from './getters.js';
import mutations from './mutations.js';
import actions from './actions.js';
import * as actionTypes from './action_types.js';
import * as mutationTypes from './mutation_types.js';
import Viewer from './viewer.js';

export { mutationTypes, actionTypes, Viewer };

export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true,
};
