import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
                address: {
                    foreignKey: 'address_id',
                    localField: 'address',
                },
                user: {
                    foreignKey: 'user_id',
                    localField: 'user',
                },
                inventory: {
                    foreignKey: 'inventory_id',
                    localField: 'inventory',
                },
                patientName: {
                    foreignKey: 'patient_name_id',
                    localField: 'patientName',
                },
                inventoryLocation: {
                    foreignKey: 'inventory_location_id',
                    localField: 'inventoryLocation',
                },
            },
            morphMany: {
                ...auditsRelation('inventoryRedemption'),
            },
        },
    },
};
