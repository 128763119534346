<template>
    <div class="entry">
        {{ message }} <a @click="onToggleDetail">{{ detailText }}</a>
        <history-entry-detail :detail="entry.detail" v-if="detailExpanded" />
    </div>
</template>

<script>
import HistoryEntryDetail from './HistoryEntryDetail';
import moment from 'moment-timezone';

export default {
    components: {
        HistoryEntryDetail,
    },
    props: {
        entry: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            detailExpanded: false,
            detailText: 'View Details',
        };
    },
    methods: {
        prettyEvent(eventName) {
            let colonPosition = eventName.indexOf(':');

            if (colonPosition !== -1) {
                let pascalCaseRegex = /[A-Z]{1}[a-z]+/g;
                let matches = eventName.match(pascalCaseRegex);

                if (matches.length) {
                    let withSpaces = matches.join(' ');
                    eventName = eventName.replace(eventName.substring(colonPosition + 2), withSpaces);
                }
            }

            return eventName;
        },
        prettyDate(date) {
            return moment.tz(date, moment.tz.guess()).format('M/D/YY h:mma');
        },
        onToggleDetail() {
            if (this.detailExpanded) {
                this.detailExpanded = false;
                this.detailText = 'View Details';
            } else {
                this.detailExpanded = true;
                this.detailText = 'Hide Details';
            }
        },
    },
    computed: {
        name() {
            return this.entry.user.name || '';
        },
        email() {
            if (this.entry.user.email) {
                return `(${this.entry.user.email})`;
            }
            return '';
        },
        createdAt() {
            return this.prettyDate(this.entry.createdAt);
        },
        event() {
            return this.prettyEvent(this.entry.event);
        },
        message() {
            let message = `${this.name} ${this.email} ${this.event} (${this.createdAt})`;
            message = message.trim();
            let first = message[0];
            first = first.toUpperCase();
            message = first + message.slice(1);
            return message;
        },
    },
};
</script>

<style lang="scss" scoped>
.entry {
    max-width: 30vw;
}
</style>
