import _ from 'lodash';
import AdminUserApi from 'api/endpoints/admin/users';
import FacilityUserApi from 'api/endpoints/facility/users';
import UsersApi from 'api/endpoints/users'; //TODO: Consolidate 'Users' endpoints files. EMK-1170
import { TABLE_STORE } from 'store/shared/mixins';
import { Roles, Statuses } from 'enums';
import merge from 'deepmerge';
import SmartApi from 'SmartApi';

const DEFAULT_FILTERS = {};
const DEFAULT_PAGINATION = {
    page: 1,
    per_page: 5,
};
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        default_sort: ['name'],
        viewedUser: {},
        users: [],
        fetchingUsers: false,
    },
    mutations: {
        setViewedUser(state, payload) {
            state.viewedUser = payload;
        },
        setUserData(store, payload) {
            store.users = payload;
        },
    },
    actions: {
        get({ state, commit, getters }, params = {}) {
            params = _.merge({ status_id: Statuses.ACTIVE }, params, state.filters, state.pagination, {
                sort: state.sort,
            });

            getters.api.get(params).then((response) => {
                commit('setData', response.data.data);
                if (response.data.meta) {
                    commit('setPaginationData', response.data.meta.pagination);
                }
            });
        },
        async getById({ state, commit, getters, rootGetters }, params = {}) {
            if (rootGetters['shared/currentUser/isFacility']) {
                params['facilityId'] = rootGetters['shared/currentUser/facilityId'];
            }

            const user = await getters.api.show(params);
            commit('setViewedUser', user);
        },
        async update({ state, commit, getters, rootGetters }, params = {}) {
            await getters.api.update(params, rootGetters['shared/currentUser/facilityId']);
        },
        async updateSelf({ state, commit, getters }, params = {}) {
            await getters.api.updateSelf(params);
        },
        async createUser({ state, commit, getters, rootGetters }, params = {}) {
            if (rootGetters['shared/currentUser/isFacility']) {
                throw Error('You do not have permissions to create new Users.');
            } else {
                await getters.api.create(params);
            }
        },
        async getDropdownData({ state, commit, getters }, params = {}) {
            if (state.fetchingUsers === false) {
                params = merge(
                    {
                        sort: ['name'],
                        include_inactive: true,
                    },
                    params
                );

                state.fetchingUsers = true;
                // TODO: Rename this to something sane.
                await UsersApi.dropdownData(params).then((response) => commit('setUserData', response.data.data));
                state.fetchingUsers = false;
            }
        },
    },
    getters: {
        api(state, getters, rootState, rootGetters) {
            return rootGetters['shared/currentUser/isFacility'] ? FacilityUserApi : AdminUserApi;
        },
        facilityUsers: (state) => (facilityId) => {
            return state.users.filter((user) => {
                return facilityId == user.facilityId;
            });
        },
        activeFacilityUsers: (state, getters) => (facilityId) => {
            return getters.facilityUsers(facilityId).filter((user) => {
                return user.statusId === Statuses.ACTIVE;
            });
        },
        inactiveFacilityUsers: (state, getters) => (facilityId) => {
            return getters.facilityUsers(facilityId).filter((user) => {
                return user.statusId === Statuses.INACTIVE;
            });
        },
        facilityPractitioners: (state, getters) => (facilityId) => {
            return getters.facilityUsers(facilityId).filter((user) => {
                return user.isPractitioner;
            });
        },
        activeFacilityPractitioners: (state, getters) => (facilityId) => {
            return getters.activeFacilityUsers(facilityId).filter((user) => {
                return user.isPractitioner;
            });
        },
        inactiveFacilityPractitioners: (state, getters) => (facilityId) => {
            return getters.inactiveFacilityUsers(facilityId).filter((user) => {
                return user.isPractitioner;
            });
        },
        activeFacilityNonPractitioners: (state, getters) => (facilityId) => {
            return getters.activeFacilityUsers(facilityId).filter((user) => {
                return !user.isPractitioner;
            });
        },
        inactiveFacilityNonPractitioners: (state, getters) => (facilityId) => {
            return getters.inactiveFacilityUsers(facilityId).filter((user) => {
                return !user.isPractitioner;
            });
        },
    },
});
