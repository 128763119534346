import qs from 'qs';
import VueRouter from 'vue-router';
import facilityRoutes from './facility';
import adminRoutes from './admin';
import sharedRoutes from './shared';
import { scrollBehavior } from './shared';
import Bugsnag from '@bugsnag/js';
import { getCookie, setCookie } from 'helpers';

const StandardApplication = () =>
    import(/* webpackChunkName: 'components-shared' */ 'component/shared/StandardApplication');
const PopoutApplication = () => import(/* webpackChunkName: 'popout' */ 'component/shared/PopoutApplication');

const routes = [
    {
        path: '/',
        component: StandardApplication,
        name: 'standard-application',
        meta: { guestOnly: true },
        children: [...adminRoutes, ...facilityRoutes, ...sharedRoutes],
        // This route needs to be considered a guest route so that
        // the AuthGuard does not prevent users from getting to the login screen. [DJF]
    },
    {
        path: '/popout/orders/form',
        component: PopoutApplication,
        name: 'popout-application',
    },
    {
        path: '*',
        redirect: (to) => {
            let fromAuthBridge = getCookie('debug_from_auth_bridge');
            if (fromAuthBridge) {
                Bugsnag.leaveBreadcrumb('User redirected to / after coming from Authentication Bridge', {
                    intended_redirect: getCookie('debug_intended_redirect'),
                });
                Bugsnag.notify(new Error('User redirected to / after coming from Authentication Bridge'));
                setCookie('debug_from_auth_bridge', null, 0);
                setCookie('debug_intended_redirect', null, 0);
            }
            return '/';
        },
    },
];

export default new VueRouter({
    mode: 'history',
    base: '/app/',
    parseQuery(query) {
        return qs.parse(query);
    },
    stringifyQuery(query) {
        return qs.stringify(query, { addQueryPrefix: true, arrayFormat: 'indices' });
    },
    scrollBehavior,
    routes,
    // is-active is the class Bulma uses to style the active element of the menu
    // linkActiveClass: 'is-active'
    linkExactActiveClass: 'is-active',
    linkActiveClass: 'is-active',
});

// Routes are exported so that tests can generate their own instances of VueRouter with the same Routes.
export { routes };
