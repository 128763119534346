/**
 * Represent a user who is viewing a Line.
 */
export default class Viewer {
    /** @type {number} */
    id;
    /** @type {string}*/
    name;
    /** @type {string}*/
    socketId;

    /**
     * Create a viewer.
     * @param {Object} viewer
     * @param {number} viewer.id The id of the User that is viewing the Line.
     * @param {string} viewer.name The name of the User that is viewing the Line.
     * @param {string} viewer.socketId The unique id of the socket used to communicate with the server.
     */
    constructor({ id, name, socketId }) {
        this.id = id;
        this.name = name;
        this.socketId = socketId;
    }
}
