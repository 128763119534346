import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = params.id ? route('credit-memos.show', { id: params.id }) : route('credit-memos');
        return await axios.get(url, { params });
    },
    async delete(id) {
        let url = route('credit-memos.destroy', { id: id });
        return await axios.delete(url);
    },
    async update(id, payload) {
        let url = route('credit-memos.edit', { id: id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async print(payload) {
        let url = route('credit-memos.print');
        return await axios.post(url, payload, { responseType: 'blob' });
    },
    async changeStatus(payload) {
        let url = route('credit-memos.status.edit');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async downloadCSV(params = {}) {
        let url = route('credit-memos.download-csv');
        return await axios.get(url, { params });
    },
    async batch(params = {}) {
        let url = route('tools.batch-credit-memos');
        return await axios.post(url, params);
    },
};
