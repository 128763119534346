import _ from 'lodash';
import AdminUserApi from 'api/endpoints/admin/users'; //TODO: Kill
import FacilityUserApi from 'api/endpoints/facility/users'; //TODO: Kill
import { Statuses, Roles } from 'enums';
import merge from 'deepmerge';
import { apis } from 'api/client';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions(
    {},
    {
        namespaced: true,
        state: {
            default_sort: ['name'],
            viewedUser: {},
            users: [],
            fetchingUsers: false,
            adminUsers: [],
            idToUser: {},
        },
        mutations: {
            setViewedUser(state, payload) {
                state.viewedUser = payload;
            },
            setUserData(store, payload) {
                store.users = payload;
                store.idToUser = {};
                payload.forEach((user) => (store.idToUser[user.id] = user));
            },
            setAdminUsers(store, payload) {
                store.adminUsers = payload;
            },
            addUsers(store, payload) {
                store.users = [...store.users, ...payload];
                store.idToUser = {};
                store.users.forEach((user) => (store.idToUser[user.id] = user));
            },
        },
        actions: {
            get({ state, commit, getters }, params = {}) {
                params = _.merge({ status_id: Statuses.ACTIVE }, params, state.filters, state.pagination, {
                    sort: state.sort,
                });

                getters.api.get(params).then((response) => {
                    commit('setData', response.data.data);
                    if (response.data.meta) {
                        commit('setPaginationData', response.data.meta.pagination);
                    }
                });
            },
            async getById({ state, commit, getters, rootGetters }, params = {}) {
                if (rootGetters['shared/currentUser/isFacility']) {
                    params['facilityId'] = rootGetters['shared/currentUser/facilityId'];
                }

                const user = await getters.api.show(params);
                commit('setViewedUser', user);
            },
            async update({ state, commit, getters, rootGetters }, params = {}) {
                await getters.api.update(params, rootGetters['shared/currentUser/facilityId']);
            },
            async updateSelf({ state, commit, getters }, params = {}) {
                await getters.api.updateSelf(params);
            },
            async createUser({ state, commit, getters, rootGetters }, params = {}) {
                if (rootGetters['shared/currentUser/isFacility']) {
                    throw Error('You do not have permissions to create new Users.');
                } else {
                    await getters.api.create(params);
                }
            },
            async getDropdownData({ state, commit, getters }, params = {}) {
                if (state.fetchingUsers === false) {
                    params = merge(
                        {
                            sort: ['name'],
                            include_inactive: true,
                            per_page: -1,
                            pagination_type: 'simple'
                        },
                        params
                    );

                    state.fetchingUsers = true;

                    const response = await apis.Users.listUsers(params);

                    commit('setUserData', response.body.data);

                    state.fetchingUsers = false;
                }
            },
            async getAdminUsers({ state, commit }, params = {}) {
                params['role_id'] = Roles.ADMIN_ID;
                params['include_inactive'] = params.include_inactive || true;

                const response = await apis.Users.listUsers(params);

                commit('setAdminUsers', response.body.data);
            },
            async findUsers({ state, commit, getters }, { ids }) {
                // Eventually, shouldFetch needs to be moved
                // and know when to re-fetch an entity even if
                // it's already in the map. Maybe a TTL for
                // each entity?
                const shouldFetch = (id) => !state.idToUser[id] && id != null;
                const toFetch = ids.filter(shouldFetch);
                if (toFetch.length) {
                    const response = await apis.Users.listUsers({
                        id: toFetch,
                    });
                    commit('addUsers', response.body.data);
                }
                return ids.map((id) => state.idToUser[id]);
            },
        },
        getters: {
            api(state, getters, rootState, rootGetters) {
                return rootGetters['shared/currentUser/isFacility'] ? FacilityUserApi : AdminUserApi;
            },
            facilityUsers: (state) => (facilityId) => {
                return state.users.filter((user) => {
                    return facilityId == user.facility_id;
                });
            },
            activeFacilityUsers: (state, getters) => (facilityId) => {
                return getters.facilityUsers(facilityId).filter((user) => {
                    return user.status_id === Statuses.ACTIVE;
                });
            },
            inactiveFacilityUsers: (state, getters) => (facilityId) => {
                return getters.facilityUsers(facilityId).filter((user) => {
                    return user.status_id === Statuses.INACTIVE;
                });
            },
            facilityPractitioners: (state, getters) => (facilityId) => {
                return getters.facilityUsers(facilityId).filter((user) => {
                    return user.is_practitioner;
                });
            },
            activeFacilityPractitioners: (state, getters) => (facilityId) => {
                return getters.activeFacilityUsers(facilityId).filter((user) => {
                    return user.is_practitioner;
                });
            },
            inactiveFacilityPractitioners: (state, getters) => (facilityId) => {
                return getters.inactiveFacilityUsers(facilityId).filter((user) => {
                    return user.is_practitioner;
                });
            },
            activeFacilityNonPractitioners: (state, getters) => (facilityId) => {
                return getters.activeFacilityUsers(facilityId).filter((user) => {
                    return !user.is_practitioner;
                });
            },
            inactiveFacilityNonPractitioners: (state, getters) => (facilityId) => {
                return getters.inactiveFacilityUsers(facilityId).filter((user) => {
                    return !user.is_practitioner;
                });
            },
            adminById(state) {
                return (userId) => {
                    return _.find(state.adminUsers, (user) => user.id == userId);
                };
            },
            adminNameById(state, getters) {
                return (userId) => {
                    let user = getters.adminById(userId);

                    return user ? user.name : '';
                };
            },
            userById(state) {
                return (userId) => {
                    return state.idToUser[userId];
                };
            },
            nameById(state, getters) {
                return (userId) => {
                    let user = getters.userById(userId);
                    return user?.name || '';
                };
            },
        },
    }
);
