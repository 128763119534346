<template>
    <emp-select
        :disabled="shippingMethods.length == false"
        :fieldClass="fieldClass"
        :inputClass="[inputClass, { 'is-loading': shippingMethods.length === false }]"
        :label="label"
        :labelClass="labelClass"
        :options="shippingMethods"
        :placeholder="placeholder"
        valueFrom="id"
        :value="value"
        :errors="errors"
        :name="name"
        @input="$emit('input', $event)"
    />
</template>

<script>
import { input, hasOptions } from './mixins';

export default {
    mixins: [input],
    props: {
        placeholder: {
            type: [String, Boolean],
            default: '',
        },
    },
    computed: {
        //TODO: I'm pretty sure this is fine.
        shippingMethods() {
            return [
                { id: '01', name: 'UPS Next Day Air' },
                { id: '02', name: 'UPS 2nd Day Air' },
                { id: '03', name: 'UPS Ground' },
                { id: '11', name: 'UPS Standard' },
                { id: '12', name: 'UPS 3 Day Select' },
                { id: '14', name: 'UPS Next Day Air Early A.M.' },
                { id: '59', name: 'UPS 2nd Day Air A.M.' },
            ];
        },
    },
};
</script>
