import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                address: {
                    foreignKey: 'shipping_address_id',
                    localField: 'shippingAddress',
                },
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
                inventoryLocation: {
                    foreignKey: 'inventory_location_id',
                    localField: 'inventoryLocation',
                },
                product: {
                    foreignKey: 'product_id',
                    localField: 'product',
                },
            },
            morphMany: {
                ...auditsRelation('inventory'),
            },
        },
    },
};
