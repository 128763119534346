<template>
    <button @click="proxyClickMethod" :type="type" :disabled="disabled" :class="['button', buttonClass]">
        <span v-if="icon" class="icon"><i :class="['fa', icon]"></i></span>
        <span>{{ currentLabel }}</span>
        <emp-loading-indicator :style-override="loaderStyles" v-if="showLoader" />
    </button>
</template>

<script>
import { buttons } from '../../../components/revamp/elements/mixins';

export default {
    mixins: [buttons],
    props: {
        confirmationMessage: { default: 'Really?', type: String },
    },
    data() {
        return {
            originalLabel: '',
            currentLabel: '',
            clickCounter: 0,
            messageTimeout: 2000,
            messageTimerId: null,
        };
    },
    created() {
        this.originalLabel = this.label;
        this.currentLabel = this.originalLabel;
    },
    methods: {
        proxyClickMethod() {
            this.clickCounter++;

            if (this.clickCounter === 1) {
                this.currentLabel = this.confirmationMessage;

                this.messageTimerId = setTimeout(() => {
                    this.currentLabel = this.originalLabel;
                    this.clickCounter = 0;
                }, this.messageTimeout);
            } else if (this.clickCounter >= 2) {
                clearTimeout(this.messageTimerId);

                this.currentLabel = this.originalLabel;
                this.$emit('click');

                this.clickCounter = 0;
            }
        },
    },
};
</script>
