/**
 * Admin Components
 */

const OrderRequestReviewContainer = () =>
    import(/* webpackChunkName: "bumblebee" */ 'component/admin/OrderRequestReview/Container');
const OrderRequestReviewResults = () =>
    import(/* webpackChunkName: "bumblebee" */ 'component/admin/OrderRequestReview/Results');
const OrderRequestManageContainer = () =>
    import(/* webpackChunkName: "bumblebee" */ 'component/admin/OrderRequestManage/Container');
const OrderRequestManageResults = () =>
    import(/* webpackChunkName: "bumblebee" */ 'component/admin/OrderRequestManage/Results');
const OrdersSearch = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/OrdersSearch');
const PurchaseOrders = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/PurchaseOrders');
const PurchaseOrderForm = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/PurchaseOrderForm');
const Bills = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/Bills');
const BillsForm = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/BillsForm');
const Invoices = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/Invoices');
const InvoiceForm = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/InvoiceForm');
const Credits = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/Credits');
const CreditsForm = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/CreditsForm');
const CreditMemos = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/CreditMemos');
const CreditMemoForm = () => import(/* webpackChunkName: "hufflepuff" */ 'component/admin/CreditMemoForm');
const ProductPriceListExceptions = () =>
    import(/* webpackChunkName: "hufflepuff" */ 'component/admin/ProductPriceListExceptions');
const Facilities = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/Facilities');
const FacilityForm = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/FacilityForm');
const Batch = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/Batch');
const ERPExport = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/ERPExport');
const Reconciliation = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/Reconciliation');
const POsByTrackingNumber = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/POsByTrackingNumber');
const FileUpload = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/FileUpload');
const System = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/System');
const ProductForm = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/ProductForm');
const VendorForm = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/VendorForm');
const AddressForm = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/AddressForm');
const Users = () => import(/* webpackChunkName: "gryffindor" */ 'component/shared/Users');
const UsersForm = () => import(/* webpackChunkName: "gryffindor" */ 'component/shared/UsersForm');
const SendPractitionerSMS = () => import(/* webpackChunkName: "gryffindor" */ 'component/admin/SendPractitionerSMS');
const AddItemToOrderForm = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/AddItemToOrderForm');
const ShippingMethods = () => import(/* webpackChunkName: "ravenclaw" */ 'component/admin/ShippingMethods');
const ShippingMethodsReorder = () =>
    import(/* webpackChunkName: "ravenclaw" */ 'component/admin/ShippingMethods/Reorder');
const ShippingMethodForm = () => import(/* webpackChunkName: "ravenclaw" */ 'component/admin/ShippingMethodForm');
const ShippingServiceForm = () => import(/* webpackChunckName: "ravenclaw" */ 'component/admin/ShippingServiceForm');

const Addresses = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/Addresses');
const Vendors = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/Vendors');
const ProductSearch = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/ProductSearch');
const ProductSearchModal = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/ProductSearchModal');
const ProductDatabase = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/ProductDatabase');
const SearchProductMatches = () => import(/* webpackChunkName: "slytherin" */ 'component/admin/SearchProductMatches');
const InventoryForm = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/InventoryForm');
const PatientNames = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/PatientNames');
const Reports = () => import(/* webpackChunkName: "slytherin" */ 'component/admin/Reports');
const Dashboard = () => import('component/admin/Dashboard/Dashboard');
const DashboardSettings = () => import('component/admin/Dashboard/DashboardSettings');
const Categories = () => import(/* webpackChunkName: "megatron" */ 'component/admin/Categories');
const CategoryForm = () => import(/* webpackChunkName: "megatron" */ 'component/admin/CategoryForm');
const EmailAccounts = () => import(/* webpackChunkName: "megatron" */ 'component/admin/EmailAccounts');
const GmailRedirect = () => import(/* webpackChunkName: "megatron" */ 'component/admin/GmailRedirect');
const EmailAccountsForm = () => import(/* webpackChunkName: "megatron" */ 'component/admin/EmailAccountsForm');
const EdiDocument = () => import('component/admin/EdiDocuments');
const DocumentViewer = () => import('component/shared/DocumentViewer');
const TrackingNumberImportResults = () => import('component/admin/TrackingNumbers/ImportResults/Results');
const TradingPartners = () => import(/* webpackChunkName: "trading-partners" */ 'component/admin/TradingPartners');
const TradingPartnerForm = () =>
    import(/* webpackChunkName: "trading-partners" */ 'component/admin/TradingPartners/Form');
const PrewrittenNotes = () => import('component/shared/PrewrittenNotes');
const UploadedFiles = () =>
    import(/* webpackChunkName: "uploadedFiles" */ 'component/admin/UploadedFiles/UploadedFiles.vue');
const UploadedFile = () =>
    import(/* webpackChunkName: "uploadedFiles" */ 'component/admin/UploadedFiles/UploadedFile.vue');
const DocumentUploadForm = () => import('component/admin/DocumentUploadForm');
const BillImport = () => import(/* webpackChunkName: "price-list-import" */ 'component/admin/BillImport');
const PriceListImport = () => import(/* webpackChunkName: "price-list-import" */ 'component/admin/PriceListImport');
const ShippingImport = () => import(/* webpackChunkName: "price-list-import" */ 'component/admin/ShippingImport');
const FeeScheduleImport = () => import(/* webpackChunkName: "price-list-import" */ 'component/admin/FeeScheduleImport');
const EmailProcessing = () => import('component/admin/EmailProcessing');
const ExtractionInstructions = () =>
    import(
        /* webpackChunkName: "extractionInstructions" */ 'component/admin/DocumentProcessing/ExtractionInstructions/ExtractionInstructions'
    );
const ExtractionInstructionEditForm = () =>
    import(
        /* webpackChunkName: "extractionInstructions" */ 'component/admin/DocumentProcessing/ExtractionInstructions/Edit'
    );
const ExtractionInstructionsCreateForm = () =>
    import(
        /* webpackChunkName: "extractionInstructions" */ 'component/admin/DocumentProcessing/ExtractionInstructions/Create'
    );
const EmailProcessingRules = () =>
    import(
        /* webpackChunkName: "emailProcessingRules" */ 'component/admin/EmailProcessingRules/EmailProcessingRules.vue'
    );
const EmailProcessingRulesCreate = () =>
    import(/* webpackChunkName: "emailProcessingRules" */ 'component/admin/EmailProcessingRules/CreateForm.vue');
const EmailProcessingRulesEdit = () =>
    import(/* webpackChunkName: "emailProcessingRules" */ 'component/admin/EmailProcessingRules/EditForm.vue');
const Email = () => import(/* webpackChunkName: "email" */ 'component/admin/Emails/Email.vue');
const DeletedItems = () => import(/* webpackChunkName: "deletedItems" */ 'component/admin/DeletedItems');
const ClientSettings = () => import('component/admin/ClientSettings.vue');
const UserPreferences = () => import(/* webpackChunkName: "userPreferences" */ 'component/shared/UserPreferences');
const RewardsTiers = () => import('component/admin/RewardsTiers');
const ProductPricingSimulator = () => import('component/admin/ProductPricingSimulator');

import {
    TYPE_BILLS,
    TYPE_PRODUCT,
    TYPE_TRACKING_NUMBERS,
    TYPE_SHIPPING_AMOUNTS,
    TYPE_CONFIRMATION_NUMBERS,
    TYPE_BILL_WO_POS,
    MODE_CREATE,
    MODE_EDIT,
    TYPE_INVENTORY,
} from 'constants';

const routes = [
    {
        path: '/orders',
        name: 'orders',
        redirect: {
            name: 'orders.review',
        },
    },
    {
        path: '/orders/review',
        component: OrderRequestReviewContainer,
        name: 'orders.review',
        children: [
            {
                path: 'shipping-address/:shippingAddressId(\\d+)',
                component: OrderRequestReviewResults,
                name: 'orders.review.results',
                children: [
                    {
                        path: 'message/:practitionerId(\\d+)',
                        component: SendPractitionerSMS,
                        name: 'orders.review.message',
                    },
                    {
                        name: 'orders.review.reorder',
                        path: ':id/order',
                        component: AddItemToOrderForm,
                    },
                ],
            },
        ],
    },
    {
        path: '/orders/manage',
        component: OrderRequestManageContainer,
        name: 'orders.manage',
        children: [
            {
                path: 'vendor/:vendorId(\\d+)',
                component: OrderRequestManageResults,
                name: 'orders.manage.results',
                children: [
                    {
                        path: 'message/:practitionerId(\\d+)',
                        component: SendPractitionerSMS,
                        name: 'orders.manage.message',
                    },
                    {
                        name: 'orders.manage.reorder',
                        path: ':id/order',
                        component: AddItemToOrderForm,
                    },
                ],
            },
        ],
    },
    {
        path: '/orders/search',
        component: OrdersSearch,
        name: 'orders.search',
        children: [
            {
                path: 'message/:practitionerId(\\d+)',
                component: SendPractitionerSMS,
                name: 'orders.search.message',
            },
            {
                name: 'orders.search.reorder',
                path: ':id/order',
                component: AddItemToOrderForm,
            },
        ],
        meta: { title: 'Orders' },
    },
    {
        path: '/purchase-orders',
        component: PurchaseOrders,
        name: 'purchase-orders',
        meta: { title: 'Purchase Orders' },
        children: [
            {
                path: ':id(\\d+)',
                component: PurchaseOrderForm,
                name: 'purchase-orders.edit',
                meta: { title: 'Edit Purchase Order' },
            },
        ],
    },
    {
        path: '/bills',
        component: Bills,
        name: 'bills',
        meta: { title: 'Bills' },
    },
    {
        path: '/bills/create',
        component: BillsForm,
        name: 'bills.create',
        meta: { title: 'Create Bill' },
    },
    {
        path: '/bills/edit/:id(\\d+)',
        component: BillsForm,
        name: 'bills.edit',
        meta: { title: 'Edit Bill' },
    },
    {
        path: '/imports/bills',
        component: BillImport,
        name: 'imports.bills',
        meta: { title: 'Import Bills' },
    },
    {
        path: '/invoices',
        component: Invoices,
        name: 'invoices',
        children: [
            {
                path: 'edit/:id(\\d+)',
                component: InvoiceForm,
                name: 'invoices.edit',
            },
        ],
        meta: { title: 'Invoices' },
    },
    {
        path: '/credits',
        component: Credits,
        name: 'credits',
        meta: { title: 'Credits' },
    },
    {
        path: '/credits/edit/:id(\\d+)',
        component: CreditsForm,
        name: 'credits.edit',
        meta: { title: 'Edit Credit' },
    },
    {
        path: '/credits/create',
        component: CreditsForm,
        name: 'credits.create',
        meta: { title: 'Create Credit' },
    },
    {
        path: '/credit-memos',
        component: CreditMemos,
        name: 'credit-memos',
        children: [
            {
                path: 'edit/:id(\\d+)',
                component: CreditMemoForm,
                name: 'credit-memos.edit',
            },
        ],
        meta: { title: 'Credit Memos' },
    },
    {
        path: '/products/price-list/exceptions',
        component: ProductPriceListExceptions,
        name: 'products.price-list-exceptions',
        meta: { title: 'Price List Exceptions' },
    },
    {
        path: '/facilities',
        component: Facilities,
        name: 'facilities',
        meta: { title: 'Facilities' },
    },
    {
        path: '/facilities/edit/:id(\\d+)',
        component: FacilityForm,
        props: {
            mode: MODE_EDIT,
        },
        name: 'facilities.edit',
        meta: { title: 'Edit Facility' },
    },
    {
        path: '/facilities/create',
        component: FacilityForm,
        props: {
            mode: MODE_CREATE,
        },
        name: 'facilities.create',
        meta: { title: 'Create Facility' },
    },
    {
        path: '/tools/reconciliation',
        component: Reconciliation,
        name: 'tools.reconciliation',
        meta: { title: 'Reconciliation' },
    },
    {
        path: '/tools/pos-by-tracking-number',
        component: POsByTrackingNumber,
        name: 'tools.pos-by-tracking-number',
        meta: { title: 'POs by Tracking Number' },
    },
    {
        path: '/tools/internal-notes',
        component: PrewrittenNotes,
        name: 'tools.internal-notes',
        meta: { title: 'Internal Notes' },
    },
    {
        path: '/tools/batch',
        component: Batch,
        name: 'tools.batch-invoices',
        meta: { title: 'Batch Invoices' },
    },
    {
        path: '/tools/erp-export',
        component: ERPExport,
        name: 'tools.erp-export',
        meta: { title: 'ERP Export' },
    },
    {
        path: '/tools/site-metrics',
        component: Dashboard,
        name: 'tools.site-metrics',
        meta: { title: 'Site Metrics' },
    },
    {
        path: '/tools/site-metrics/settings',
        component: DashboardSettings,
        name: 'tools.site-metrics.settings',
        meta: { title: 'Site Metrics Settings' },
    },
    {
        path: '/tools/categories',
        component: Categories,
        name: 'tools.categories',
        meta: { title: 'Categories' },
    },
    {
        path: '/tools/categories/create',
        component: CategoryForm,
        name: 'tools.categories.create',
        props: {
            mode: MODE_CREATE,
        },
        meta: { title: 'Create Category' },
    },
    {
        path: '/tools/categories/edit/:id(\\d+)',
        component: CategoryForm,
        name: 'tools.categories.edit',
        props: {
            mode: MODE_EDIT,
        },
        meta: { title: 'Edit Category' },
    },
    {
        path: '/tools/rewards-tiers',
        component: RewardsTiers,
        name: 'tools.rewards-tiers',
        meta: { title: 'Rewards Tiers' },
    },
    {
        path: '/email-accounts',
        component: EmailAccounts,
        name: 'email.accounts',
        meta: { title: 'Email Accounts' },
    },
    {
        path: '/email-accounts/gmail-redirect',
        component: GmailRedirect,
        name: 'email.accounts.gmail-redirect',
        meta: { title: 'Gmail Authorization Successful' },
    },
    {
        path: '/email-accounts/create',
        component: EmailAccountsForm,
        name: 'email.accounts.create',
        props: {
            mode: MODE_CREATE,
        },
        meta: { title: 'Create Email Account' },
    },
    {
        path: '/email-accounts/edit/:id(\\d+)',
        component: EmailAccountsForm,
        name: 'email.accounts.edit',
        props: {
            mode: MODE_EDIT,
        },
        meta: { title: 'Edit Email Account' },
    },
    {
        path: '/tools/edi/trading-partners',
        component: TradingPartners,
        name: 'tools.edi.trading-partners',
        meta: { title: 'Trading Partners' },
    },
    {
        path: '/tools/edi/trading-partner/:id(\\d+)?',
        component: TradingPartnerForm,
        name: 'tools.edi.trading-partners.form',
        meta: { title: 'Edit - Trading Partner' },
    },
    {
        path: '/tools/edi/documents',
        component: EdiDocument,
        name: 'tools.edi-documents',
        meta: { title: 'Edi Documents' },
    },
    {
        path: '/tools/document-upload-form',
        component: DocumentUploadForm,
        name: 'tools.document-upload-form',
        meta: { title: 'Upload Documents' },
    },
    {
        path: '/tools/email-processing',
        component: EmailProcessing,
        name: 'tools.email-processing',
        meta: { title: 'Email Processing' },
    },
    {
        path: '/imports',
        name: 'imports',
        redirect: {
            name: 'imports.products',
        },
    },
    {
        path: '/imports/product-upload',
        component: FileUpload,
        props: {
            type: TYPE_PRODUCT,
        },
        name: 'imports.products',
        meta: { title: 'Search Product Import' },
    },
    {
        path: '/imports/products/price-list/',
        component: PriceListImport,
        name: 'price-list-import',
        meta: { title: 'Price List Import' },
    },
    {
        path: '/imports/shipping',
        component: ShippingImport,
        name: 'imports.shipping',
        meta: { title: 'Shipping Import' },
    },
    {
        path: '/imports/fee-schedule',
        component: FeeScheduleImport,
        name: 'imports.fee-schedule',
        meta: { title: 'Import Fee Schedule' },
    },
    {
        path: '/deleted-items',
        component: DeletedItems,
        name: 'deleted-items',
        meta: { title: 'Deleted Items' },
    },
    {
        path: '/tools/client-settings',
        component: ClientSettings,
        name: 'tools.client-settings',
        meta: { title: 'Client Settings' },
    },
    {
        path: '/tools/system-settings',
        component: System,
        name: 'tools.system-settings',
        meta: { title: 'System Settings' },
    },
    {
        path: '/imports/tracking-numbers',
        component: FileUpload,
        props: {
            type: TYPE_TRACKING_NUMBERS,
        },
        name: 'imports.tracking-numbers',
        meta: { title: 'Import Tracking Numbers' },
    },
    {
        path: '/imports/confirmation-numbers',
        component: FileUpload,
        props: {
            type: TYPE_CONFIRMATION_NUMBERS,
        },
        name: 'imports.confirmation-numbers',
        meta: { title: 'Import Confirmation Numbers' },
    },
    {
        path: '/imports/inventory',
        component: FileUpload,
        props: {
            type: TYPE_INVENTORY,
        },
        name: 'imports.inventory',
        meta: { title: 'Import Inventory List' },
    },
    {
        path: '/products/edit/:id(\\d+)',
        component: ProductForm,
        name: 'products.edit',
        props: {
            mode: MODE_EDIT,
        },
        meta: { title: 'Edit Product' },
    },
    {
        path: '/products/create',
        component: ProductForm,
        name: 'products.create',
        props: {
            mode: MODE_CREATE,
        },
        meta: { title: 'Create Product' },
    },
    {
        path: '/products/search',
        component: ProductSearch,
        name: 'products.search',
        children: [
            {
                path: 'view/:id(\\d+)',
                component: ProductSearchModal,
                name: 'products.single',
                meta: { title: 'View Product' },
            },
            {
                path: 'order/:id(\\d+)',
                component: AddItemToOrderForm,
                name: 'products.order',
                meta: { title: 'View Product' },
            },
            {
                path: 'order',
                component: AddItemToOrderForm,
                name: 'products.order-by-query',
            },
        ],
        meta: { title: 'L-Code Search' },
    },
    {
        path: '/products',
        component: ProductDatabase,
        name: 'products',
        children: [
            {
                path: '/products/:productId(\\d+)/create-inventory/:facilityId(\\d+)',
                component: InventoryForm,
                name: 'products.inventory.create',
                props: (route) => {
                    return {
                        facilityId: Number(route.params.facilityId),
                        productId: Number(route.params.productId),
                        inventoryRecordId: false,
                    };
                },
                meta: { title: 'Create Inventory Part' },
            },
        ],
        meta: { title: 'Products' },
    },
    {
        path: '/products/search-matches',
        component: SearchProductMatches,
        name: 'products.search-matches',
        meta: { title: 'Search Product Matches' },
    },
    {
        path: '/products/pricing-simulator',
        component: ProductPricingSimulator,
        name: 'products.pricing-simulator',
        meta: { title: 'Product Pricing Simulator' },
    },
    {
        path: '/vendors',
        component: Vendors,
        name: 'vendors',
        meta: { title: 'Vendors' },
    },
    {
        path: '/vendors/create',
        component: VendorForm,
        name: 'vendors.create',
        props: {
            mode: MODE_CREATE,
        },
        meta: { title: 'Create Vendor' },
    },
    {
        path: '/vendors/edit/:id(\\d+)',
        component: VendorForm,
        name: 'vendors.edit',
        props: {
            mode: MODE_EDIT,
        },
        meta: { title: 'Edit Vendor' },
    },
    {
        path: '/addresses',
        component: Addresses,
        name: 'addresses',
        meta: { title: 'Addresses' },
    },
    {
        path: '/addresses/create',
        component: AddressForm,
        name: 'addresses.create',
        props: {
            mode: MODE_CREATE,
        },
        meta: { title: 'Create Address' },
    },
    {
        path: '/addresses/edit/:id(\\d+)',
        component: AddressForm,
        name: 'addresses.edit',
        props: {
            mode: MODE_EDIT,
        },
        meta: { title: 'Edit Address' },
    },
    {
        path: '/shipping-methods',
        component: ShippingMethods,
        name: 'shipping-methods',
        meta: { title: 'Shipping Methods' },
    },
    {
        path: '/shipping-methods/create',
        component: ShippingMethodForm,
        name: 'shipping-methods.create',
        props: {
            mode: MODE_CREATE,
        },
        meta: { title: 'Create Shipping Method' },
    },
    {
        path: '/shipping-methods/edit/:id(\\d+)',
        component: ShippingMethodForm,
        name: 'shipping-methods.edit',
        props: {
            mode: MODE_EDIT,
        },
        meta: { title: 'Edit Shipping Method' },
    },
    {
        path: '/shipping-services/create',
        component: ShippingServiceForm,
        name: 'shipping-services.create',
        props: {
            mode: MODE_CREATE,
        },
        meta: { title: 'Create Shipping Service' },
    },
    {
        path: '/shipping-services/edit/:id(\\d+)',
        component: ShippingServiceForm,
        name: 'shipping-services.edit',
        props: {
            mode: MODE_EDIT,
        },
        meta: { title: 'Edit Shipping Service' },
    },
    {
        path: '/shipping-methods/reorder',
        component: ShippingMethodsReorder,
        name: 'shipping-methods.dropdownReorder',
        meta: { title: 'Reorder Shipping Methods' },
    },
    {
        path: '/users/',
        component: Users,
        name: 'admin.users',
    },
    {
        path: '/users/:id(\\d+)',
        component: UsersForm,
        name: 'admin.users.edit',
        props: {
            mode: MODE_EDIT,
        },
        meta: { title: 'User Settings' },
    },
    {
        path: '/patient-names',
        component: PatientNames,
        name: 'patient-names',
        meta: { title: 'Patient Names/IDs' },
    },
    {
        path: '/imports/upload-bill-wo-pos',
        component: FileUpload,
        props: {
            type: TYPE_BILL_WO_POS,
        },
        name: 'tools.upload-bill-wo-pos',
        meta: { title: 'Upload Bill with POs' },
    },
    {
        path: '/reports',
        component: Reports,
        name: 'reports',
        meta: { title: 'Reports' },
    },
    {
        path: '/document-viewer',
        component: DocumentViewer,
    },
    {
        path: '/tracking-number-import-results',
        name: 'tracking-number-import-results',
        component: TrackingNumberImportResults,
    },
    {
        path: '/uploaded-files',
        name: 'uploaded-files',
        component: UploadedFiles,
    },
    {
        path: '/uploaded-files/:id',
        name: 'uploaded-file.edit',
        component: UploadedFile,
        props: true,
    },
    {
        path: '/extraction-instructions',
        name: 'extractionInstructions',
        component: ExtractionInstructions,
    },
    {
        path: '/extraction-instructions/create',
        name: 'extractionInstructions.create',
        component: ExtractionInstructionsCreateForm,
    },
    {
        path: '/extraction-instructions/edit/:id(\\d+)',
        name: 'extractionInstructions.edit',
        component: ExtractionInstructionEditForm,
    },
    {
        path: '/email-processing-rules',
        name: 'email-processing-rules',
        component: EmailProcessingRules,
    },
    {
        path: '/email-processing-rules/create',
        name: 'email-processing-rules-create',
        component: EmailProcessingRulesCreate,
    },
    {
        path: '/email-processing-rules/edit/:id(\\d+)',
        name: 'email-processing-rules-edit',
        component: EmailProcessingRulesEdit,
    },
    {
        path: '/emails/:id(\\d+)',
        name: 'emails',
        component: Email,
        props: true,
    },
    {
        path: '/user/preferences',
        component: UserPreferences,
        name: 'user.preferences',
        meta: { title: 'User Preferences' },
    },
];

function tagAsAdminOnly(route) {
    const meta = route.meta || {};
    meta.adminOnly = true;
    route.meta = meta;
    const children = route.children || [];
    children.forEach(tagAsAdminOnly);
}

routes.forEach(tagAsAdminOnly);

export default routes;
