<template>
    <div class="field" :class="fieldClass">
        <label class="label" :class="labelClass" v-if="label">{{ label }}</label>
        <div class="field is-grouped is-grouped-multiline">
            <div class="control" v-for="(email, index) in emails" :key="email">
                <div class="tags has-addons">
                    <span
                        class="tag is-info is-medium"
                        @click="select(index)"
                        @dblclick="copy(index)"
                        :ref="`email_${index}`"
                        >{{ email }}</span
                    >
                    <a class="tag is-delete is-medium" @click="remove(index)" v-if="isEditable"></a>
                </div>
            </div>
        </div>
        <ValidationProvider :rules="rules" name="email" mode="lazy" ref="email" :skipIfEmpty="false">
            <template slot="default" slot-scope="{ errors }">
                <p class="control has-icons-left">
                    <input
                        class="input"
                        type="email"
                        placeholder="Add email"
                        @keyup.enter="add($event)"
                        v-model="newEmail"
                        :disabled="!isEditable"
                    />
                    <span class="icon is-small is-left">
                        <i class="fas fa-envelope"></i>
                    </span>
                </p>
                <p class="help is-danger">{{ errors[0] }}</p>
            </template>
        </ValidationProvider>
        <template v-if="hasErrorArray">
            <p class="help is-danger" v-for="(error, index) in errors[name]" :key="index">{{ error }}</p>
        </template>
        <template v-else-if="hasError">
            <p class="help is-danger">{{ errors[name] }}</p>
        </template>
    </div>
</template>

<script>
import { hasErrors } from './mixins';
export default {
    mixins: [hasErrors],
    props: {
        value: {
            type: Array,
            default: [],
        },
        isEditable: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            default: '',
        },
        labelClass: {
            type: String,
            default: '',
        },
        fieldClass: {
            type: [String, Array, Object],
            default: 'column',
        },
        name: String,
    },
    data() {
        return {
            newEmail: '',
        };
    },
    methods: {
        add(event) {
            this.$refs.email.validate().then((result) => {
                if (!result.valid) {
                    return;
                }
                const emails = [...this.emails, this.newEmail];
                this.$emit('input', emails);
                this.newEmail = '';
                this.$refs.email.reset();
            });
        },
        remove(index) {
            const emails = [...this.emails.slice(0, index), ...this.emails.slice(index + 1)];
            this.$emit('input', emails);
        },
        select(index) {
            let range = document.createRange();
            range.selectNode(this.$refs[`email_${index}`][0]);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        },
        copy() {
            document.execCommand('copy');
        },
    },
    computed: {
        rules() {
            const emails = this.emails.join(',');
            return `email|notIn:${emails}`;
        },
        emails() {
            return this.value;
        },
    },
};
</script>
