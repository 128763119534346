import { notesRelation, auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                purchaseOrder: {
                    foreignKey: 'purchase_order_id',
                    localField: 'purchaseOrder',
                },
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
            },
            hasMany: {
                invoice: {
                    foreignKey: 'bill_id',
                    localField: 'invoices',
                },
            },
            belongsToMany: {
                line: {
                    foreignKey: 'id',
                    localField: 'lines',
                },
                purchaseOrder: {
                    foreignKey: 'id',
                    localField: 'purchaseOrders',
                },
            },
            morphToMany: {
                document: {
                    foreignKey: 'documents_id',
                    localField: 'documents',
                },
            },
            morphMany: {
                ...auditsRelation('bill'),
                ...notesRelation('bill'),
            },
        },
    },
};
