import _ from 'lodash';
import { HAS_SIDEBAR, ORDERS_TABLE, MANAGE_FORM } from 'store/admin/mixins/orders';
import { mixVuexStoreOptions } from 'store/helpers.js';
import { SHOWS_ORDER_LINE_RETURN_STATUSES } from 'store/shared/mixins';

export default mixVuexStoreOptions(
    {},
    HAS_SIDEBAR,
    ORDERS_TABLE,
    MANAGE_FORM,
    SHOWS_ORDER_LINE_RETURN_STATUSES,
    {
        namespaced: true,
        state: {
            moduleName: 'OrdersManage',
            organizeBy: 'vendor_name',
            selectedOrderMethodId: null,
        },
        mutations: {
            setSelectedOrderMethodId(state, value) {
                state.selectedOrderMethodId = value;
            },
        },
    });
