<template>
    <emp-component-modal @modal:close="onClose" :isActive="isActive" contentClass="width-30vw">
        <template v-if="isActive">
            <div slot="modal-header">
                {{ header }}
            </div>
            <div slot="modal-body">
                <div v-html="body"></div>
                <div class="dialogue-buttons">
                    <emp-button label="Ok" @click="onClose" />
                </div>
            </div>
        </template>
    </emp-component-modal>
</template>

<style lang="scss" scoped>
.dialogue-buttons {
    text-align: center;
    padding-top: 1rem;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapActions({
            closeModal: 'shared/modal/closeModal',
        }),
        onClose() {
            this.closeModal();
        },
    },
    computed: {
        ...mapState({
            data: (state) => state.shared.modal.data,
        }),
        header() {
            return this.data.header;
        },
        body() {
            return this.sanitizeHTML(this.data.body);
        },
    },
};
</script>
