import _ from 'lodash';
import * as actionTypes from "./action_types";
import * as mutationTypes from "./mutation_types";
import SmartApi from "SmartApi";
import Bugsnag from '@bugsnag/js';
import { apis } from "api/client.js";

export default {
    async getData({ dispatch }, params = {}) {
        await dispatch(actionTypes.GET_EXTRACTION_INSTRUCTIONS, params);
    },
    [actionTypes.GET_EXTRACTION_INSTRUCTIONS]: async (
        { state, commit },
        params = {}
    ) => {
        const { sort } = state;
        const routeParams = _.merge(
            {},
            state.filters,
            state.pagination,
            { sort },
            params
        );
        const request = {
            routeName: "listExtractionInstructions",
            routeParams,
            config: {
                hideLoader: true
            }
        };
        commit(mutationTypes.SET_IS_LOADING, { isLoading: true });
        state.data.forEach(extractionInstruction => {
            commit(mutationTypes.SET_EXTRACTION_INSTRUCTION, {
                ...extractionInstruction,
                isLoaded: false
            });
        });
        let response;
        try {
            response = await SmartApi.get(request);
        } catch (error) {
            Bugsnag.notify(error);
            return;
        } finally {
            commit(mutationTypes.SET_IS_LOADING, { isLoading: false });
        }
        const extractionInstructions = response.data.data;
        extractionInstructions.forEach(extractionInstruction => {
            extractionInstruction.isLoaded = true;
        });
        commit("setData", extractionInstructions);
        commit("setPaginationData", response.data.meta);
    },
    [actionTypes.DELETE_EXTRACTION_INSTRUCTION]: async (
        { commit },
        extractionInstruction
    ) => {
        const routeParams = {
            extraction_instruction_id: extractionInstruction.id
        };
        const request = {
            routeName: "deleteExtractionInstruction",
            routeParams
        };
        await SmartApi.delete(request);
        commit(
            mutationTypes.REMOVE_EXTRACTION_INSTRUCTION,
            extractionInstruction
        );
    },
    [actionTypes.GET_EXTRACTION_INSTRUCTION]: async (
        { commit, getters },
        { extractionInstructionId }
    ) => {
        const existing = getters.map[extractionInstructionId];
        let placeholder;
        if (existing) {
            placeholder = {
                ...existing,
                isLoaded: false
            };
        } else {
            placeholder = {
                id: extractionInstructionId,
                name: "",
                instructions: [],
                isLoaded: false
            };
        }
        commit(mutationTypes.SET_EXTRACTION_INSTRUCTION, placeholder);

        let response;
        try {
            response = await apis[
                "Extraction Instructions"
            ].getExtractionInstruction({
                extraction_instruction_id: extractionInstructionId
            });
        } catch (error) {
            Bugsnag.notify(error);
            return;
        }

        const extractionInstruction = response.body.data;
        extractionInstruction.isLoaded = true;

        commit(mutationTypes.SET_EXTRACTION_INSTRUCTION, extractionInstruction);
        return getters.map[extractionInstructionId];
    },
    [actionTypes.ENSURE_EXTRACTION_INSTRUCTION]: async (
        { getters, dispatch },
        { extractionInstructionId }
    ) => {
        if (getters.map[extractionInstructionId] === undefined) {
            return await dispatch(actionTypes.GET_EXTRACTION_INSTRUCTION, {
                extractionInstructionId
            });
        }
        return getters.map[extractionInstructionId];
    }
};
