import _ from 'lodash';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';
import jsd from 'app/jsd';
import { logExceptionAndReportValidationErrors } from 'app/jsd/helpers';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    mutations: {
        updateItemStatus(state, { id, statusId }) {
            state.data = state.data.map((item) => {
                if (item.id == id) {
                    item.status.id = statusId;
                }

                return item;
            });
        },
    },
    actions: {
        async deleteLines({getters}, {lineIds}) {
            if (lineIds instanceof Array === false) {
                lineIds = [lineIds];
            }

            let promises = [];
            for (let credit_id of lineIds) {
                promises.push(jsd.destroy('credit', {credit_id}));
            }
            return await Promise.allSettled(promises);
        },
        async get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort, per_page: 100 }, params);

            let credits = [];
            let metadata = [];

            try {
                let response = await jsd.findAll(
                    'credit',
                    params,
                    {
                        force: true,
                        raw: true,
                    }
                );

                credits = _.get(response, 'data', []);
                metadata = _.get(response, 'metadata', {});
            } catch (e) {
                console.log(e);

                if (e.errors) {
                    console.log(e.errors);
                }
            }


            if (credits.length) {
                let purchaseOrderIds = [];
                try {
                    const [creditLinesResult] = await Promise.allSettled([
                        jsd.findAll(
                            'creditLine',
                            {
                                credit_id: credits.map((credit) => credit.id),
                                per_page: -1,
                                pagination_type: 'simple'
                            },
                            {
                                force: true
                            }
                        ).catch(logExceptionAndReportValidationErrors),
                        jsd.findAll(
                            'vendor',
                            {
                                id: credits.map((credit) => credit.vendor_id),
                                per_page: -1,
                                pagination_type: 'simple'
                            }
                        ).catch(logExceptionAndReportValidationErrors)
                    ]);

                    if (creditLinesResult.status === 'fulfilled') {
                        purchaseOrderIds = creditLinesResult.value.map((creditLine) => creditLine.purchase_order_id).filter(Boolean);
                    } else {
                        console.error("Could not fetch credit lines reason:", creditLinesResult.reason);
                    }
                } catch (e) {
                    logExceptionAndReportValidationErrors(e);
                }

                if (purchaseOrderIds.length) {
                    await jsd.findAll(
                        'purchaseOrder',
                        {
                            purchase_order_id: purchaseOrderIds,
                            per_page: -1,
                            pagination_type: 'simple'
                        }
                    );

                    for (const credit of credits) {
                        credit.purchaseOrders = credit.lines
                            .map(({purchaseOrder}) => purchaseOrder)
                            .flat()
                            .filter((value) => Boolean(value));
                    }
                }
            }

            commit('setData', credits);
            commit('setPaginationData', metadata);
        },
    },
});
