<template>
    <div class="field" :class="fieldClass">
        <label class="label" :class="labelClass">
            {{ label }}
        </label>
        <p class="control">
            <label
                class="radio"
                :class="inputClass"
                v-for="(option, index) in options"
                :key="index"
                :for="`radio_${_uid}_${index}`"
            >
                <input
                    :name="name"
                    :value="option[valueFrom]"
                    :tabindex="tabindex"
                    type="radio"
                    v-model="proxy"
                    :disabled="disabled"
                    :id="`radio_${_uid}_${index}`"
                />
                {{ option[nameFrom] }}
            </label>
        </p>
    </div>
</template>

<script>
import { input, radio, hasOptions } from './mixins';
export default {
    props: {
        value: {
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [input, hasOptions],
    computed: {
        proxy: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style lang="scss" scoped></style>
