import _ from 'lodash';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';
import { apis } from 'api/client';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        default_sort: ['name'],
        pagination: {
            page: 1,
            per_page: 20,
        },
    },
    actions: {
        async get({ state, commit }, params = {}) {
            let paramsMerged = _.merge({}, params, state.filters, state.pagination, { sort: state.sort });

            let response;

            let shoppingLists;
            response = await apis.ShoppingLists.listShoppingLists(paramsMerged);
            shoppingLists = response.body.data;
            commit('setData', shoppingLists);
        },
    },
    mutations: {
        setData(state, value) {
            state.data = value;
        },
    },
});
