// @ts-check
import { computed } from '@vue/composition-api';
import { ProductTypes } from 'enums';
import { getFriendlyName } from 'app/enumHelpers';

let productTypeNamesById = new Map();

function mapProductTypeNamesById() {
    for (let type in ProductTypes) {
        productTypeNamesById.set(ProductTypes[type], getFriendlyName(type));
    }
}

export default function useProductType() {
    if (productTypeNamesById.size === 0) {
        mapProductTypeNamesById();
    }

    const productTypeNamesByIdMap = computed(() => {
        return productTypeNamesById;
    });

    return {
        productTypeNamesByIdMap,
    };
}
