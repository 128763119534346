import { DataStore, utils } from 'js-data';
import { client } from 'api/client';
import { SwaggerAdapter } from 'app/jsd/classes/SwaggerAdapter';
import CustomMapper from 'app/jsd/classes/CustomMapper';
import {
    MorphManyRelation,
    MorphManyType,
    createDescriptor as createMorphManyDescriptor,
} from 'app/jsd/classes/MorphMany';
import jsdConfig from 'app/jsd/config';
import _ from 'lodash';

let debugging = false;

let adapter = null;
let store = null;

async function bootstrap() {
    const dataStore = new DataStore({
        mapperClass: CustomMapper,
        debug: debugging,
    });
    dataStore.registerRelationshipType(MorphManyType, MorphManyRelation, createMorphManyDescriptor);

    const swaggerAdapter = new SwaggerAdapter({
        swaggerClient: client,
        defaultMetadataPath: 'body.meta',
        debug: debugging,
    });

    dataStore.registerAdapter('swagger', swaggerAdapter, { default: true });

    for (let schemaName in client.spec.components.schemas) {
        if (client.spec.components.schemas.hasOwnProperty(schemaName) && !schemaName.endsWith('Trait')) {
            if (jsdConfig[schemaName]?.skipSchema === true) {
                continue;
            }

            //Let the config override which model name the schema is associated with
            let modelName = jsdConfig[schemaName]?.modelName ?? schemaName;

            let modelSchema = { debug: debugging, ...client.spec.components.schemas[schemaName] };

            //Allow JS-Data to both use the OAS schema but also be a bit more flexible about schema null validation.
            if (jsdConfig[schemaName]?.nullableProperties?.length) {
                for (let i in jsdConfig[schemaName].nullableProperties) {
                    if (_.isArray(modelSchema.properties[jsdConfig[schemaName].nullableProperties[i]].type)) {
                        modelSchema.properties[jsdConfig[schemaName].nullableProperties[i]].type.push('null');
                    } else {
                        modelSchema.properties[jsdConfig[schemaName].nullableProperties[i]].type = [
                            modelSchema.properties[jsdConfig[schemaName].nullableProperties[i]].type,
                            'null',
                        ];
                    }
                }
            }

            //Create the mapper
            dataStore.defineMapper(modelName, {
                schema: modelSchema,
                ...jsdConfig[schemaName]?.mapper,
                debug: debugging,
            });

            //Create custom indexes for those models that need it, eg, for those with polymorphic relations
            _.forIn(jsdConfig[schemaName]?.customIndexes, (fieldList, name) => {
                dataStore.getCollection(modelName).createIndex(name, fieldList);
            });
        }
    }

    adapter = swaggerAdapter;
    store = dataStore;

    //I'm leaving this here for debugging but commenting it out.
    //Uncomment to use js-data in the console. - Toby
    // eslint-disable-next-line
    window.jsd = { store: store, adapter: adapter, utils: utils, mapper: CustomMapper, config: jsdConfig };
}

export { store as default, adapter, bootstrap };
