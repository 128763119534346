import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                credit: {
                    foreignKey: 'credit_id',
                    localField: 'credit',
                },
                creditMemo: {
                    foreignKey: 'credit_memo_id',
                    localField: 'creditMemo',
                },
                line: {
                    foreignKey: 'line_id',
                    localField: 'orderLine',
                },
                address: {
                    foreignKey: 'cost_center_id',
                    localField: 'costCenter',
                },
                product: {
                    foreignKey: 'product_id',
                    localField: 'product',
                },
                vendor: {
                    foreignKey: 'manufacturer_id',
                    localField: 'manufacturer',
                },
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
                category: {
                    foreignKey: 'category_id',
                    localField: 'category',
                },
                purchaseOrder: {
                    foreignKey: 'purchase_order_id',
                    localField: 'purchaseOrder',
                },
            },
            morphMany: {
                ...auditsRelation('creditLine'),
            },
        },
    },
};
