import _ from 'lodash';
import PatientNameApi from 'api/endpoints/patientNames';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';

//TODO: Remove this method once the LineSubcontentData, ItemTableRow (bills), and PatientNamesOrderHistory are getting their data from the v2 api
const camelAndSnakeCaseObjectKeys = (obj = {}) => {
    Object.keys(obj).forEach((key) => {
        if (key.includes('_')) {
            let newKey = _.camelCase(key);
            obj[newKey] = obj[key];
        } else {
            let newKey = _.snakeCase(key);
            obj[newKey] = obj[key];
        }
    });
    return obj;
};  

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        default_sort: ['lastname'],
        selected: [],
        searchSubmitted: false,
    },
    actions: {
        get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort, per_page: 20 }, params);
            PatientNameApi.get(params).then((response) => {
                commit('setData', response.data.data);
                commit('setPaginationData', response.data.meta.pagination);
            });
            state.searchSubmitted = true;
        },
        async delete({ state, dispatch }, payload) {
            let promises = [];

            if (payload instanceof Array === false) {
                payload = [payload];
            }

            payload.forEach((item) => promises.push(PatientNamesApi.delete(item)));

            return await Promise.all(promises);
        },
    },
    getters: {
        api() {
            return PatientNameApi;
        },
    },
    mutations: {
        clearSelected(state) {
            state.selected = [];
        },
        selectPatient(state, payload) {
            state.selected.push(camelAndSnakeCaseObjectKeys(payload));
        },
        updateSelectedPatient(state, payload) {
            let newData = state.selected.map((patientName) => {
                if (patientName.id == payload.id) {
                    patientName = camelAndSnakeCaseObjectKeys(payload);
                }

                return patientName;
            });

            state.selected = newData;
        },
        deselectPatient(state, payload) {
            state.selected = state.selected.filter((element) => element.id !== payload.id);
        },
        setSelected(state, payload) {
            payload = payload.map((element) => {
                camelAndSnakeCaseObjectKeys(element)
                element._listId = _.uniqueId();
                return element;
            });
            state.selected = payload;
        },
        setSearchSubmitted(state, value) {
            state.searchSubmitted = value;
        },
    },
});
