// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import useCacheManager from 'use/cacheManager';
import { computed } from '@vue/composition-api';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Shipping Methods',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Shipping Methods
 * @returns
 */
export default function useShippingMethods(config = {}) {
    const fetcher = async () => {
        const response = await apis.ShippingMethods.listShippingMethods({
            pagination_type: 'simple',
            per_page: -1,
            partials: ['dropdown'],
        });
        return response.body.data || [];
    };

    const { data: shippingMethods, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    if (!shippingMethods.value) {
        shippingMethods.value = [];
    }

    useCacheManager(mutate, metadata);

    const shippingMethodWhereId = (shippingMethodId) => {
        return shippingMethods.value.find((shippingMethod) => shippingMethod.id == shippingMethodId);
    };

    const shippingMethodWhereImmutableName = (shippingMethodImmutableName) => {
        return shippingMethods.value.find(
            (shippingMethod) => shippingMethod.immutable_name == shippingMethodImmutableName
        );
    };

    const amountAllowedForMethod = (shippingMethodId, propertyToCheck) => {
        let shippingMethod = shippingMethodWhereId(shippingMethodId);

        if (shippingMethod && shippingMethod.hasOwnProperty(propertyToCheck)) {
            return shippingMethod[propertyToCheck];
        }

        return false;
    };

    const invoiceShippingAmountAllowedForMethod = (shippingMethodId) => {
        return amountAllowedForMethod(shippingMethodId, 'is_invoice_dollar_amount_allowed');
    };

    const billShippingAmountAllowedForMethod = (shippingMethodId) => {
        return amountAllowedForMethod(shippingMethodId, 'is_bill_dollar_amount_allowed');
    };

    const freightRequiredShippingMethods = computed(() => {
        return shippingMethods.value.filter((shippingMethod) => shippingMethod.requires_freight);
    });

    const reviewedShippingMethods = computed(() => {
        return shippingMethods.value.filter((shippingMethod) => shippingMethod.is_reviewed);
    });

    return {
        /**
         * @type {import('@vue/composition-api').Ref<ShippingMethod[]>}
         */
        shippingMethods,
        error,
        isValidating,
        mutate,
        metadata,
        shippingMethodWhereId,
        shippingMethodWhereImmutableName,
        billShippingAmountAllowedForMethod,
        invoiceShippingAmountAllowedForMethod,
        freightRequiredShippingMethods,
        reviewedShippingMethods,
    };
}
