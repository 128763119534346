import { auditsRelation, notesRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            hasMany: {
                address: {
                    foreignKey: 'facility_id',
                    localField: 'addresses',
                },
                savedOrder: {
                    foreignKey: 'facility_id',
                    localField: 'savedOrders',
                },
                priceListException: {
                    foreignKey: 'facility_id',
                    localField: 'priceListExceptions',
                },
                patientName: {
                    foreignKey: 'facility_id',
                    localField: 'patientNames',
                },
                line: {
                    foreignKey: 'facility_id',
                    localField: 'lines',
                },
                user: {
                    foreignKey: 'facility_id',
                    localField: 'users',
                },
                inventory: {
                    foreignKey: 'facility_id',
                    localField: 'inventory',
                },
                inventoryLocation: {
                    foreignKey: 'facility_id',
                    localField: 'inventoryLocations',
                },
                invitation: {
                    foreignKey: 'facility_id',
                    localField: 'invitations',
                },
            },
            belongsToMany: {
                product: {
                    foreignKey: 'id',
                    localField: 'productPriceExceptions',
                },
                vendor: {
                    foreignKey: 'id',
                    localField: 'manufacturers',
                },
                oAuthPartner: {
                    foreignKey: 'id',
                    localField: 'oauthPartners',
                },
            },
            morphMany: {
                ...notesRelation('facility'),
                ...auditsRelation('facility'),
            },
        },
    },
};
