import _ from 'lodash';
import { apis } from 'api/client';

export default {
    namespaced: true,
    state: {
        dropdownData: [],
        inRequest: false,
        idToMethod: {},
        addressShippingMethods: {},
        shippingMethodVendors: {},
        cacheLifespan: 1000 * 60 * 5,
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
        },
        mapMethods(store, payload) {
            store.idToMethod = {};
            payload.forEach((shippingMethod) => {
                store.idToMethod[shippingMethod.id] = shippingMethod;
            });
        },
        setAddressShippingMethods(store, payload) {
            store.addressShippingMethods[payload.name] = payload.request;
        },
        unsetAddressShippingMethod(store, name) {
            delete store.addressShippingMethods[name];
        },
        setShippingMethodVendors(store, payload) {
            store.shippingMethodVendors[payload.name] = payload.request;
        },
        unsetShippingMethodVendor(store, name) {
            delete store.shippingMethodVendors[name];
        },
    },
    actions: {
        async getDropdownData({ state, commit }, params = {}) {
            if (state.inRequest) {
                return;
            }
            
            state.inRequest = true;

            params['per_page'] = params['per_page'] ?? -1;
            params['pagination_type'] = params['pagination_type'] ?? 'simple';

            const response = await apis.ShippingMethods.listShippingMethods(params);
            const shippingMethods = response.body.data;

            commit('setDropdownData', shippingMethods);
            commit('mapMethods', shippingMethods);
            state.inRequest = false;
        },
        async getDropdownDataIfNotGotten({ getters, dispatch }, params = {}) {
            if (getters.shippingMethods.length == 0) {
                dispatch('getDropdownData', params);
            }
        },
        async getAddressShippingMethods({ state, commit }, params = {}) {
            const name = `${params.shipping_method_id}${params.address_id}`;

            if (state.addressShippingMethods[name]) {
                return state.addressShippingMethods[name];
            }
            let request = apis.Addresses.listAddressShippingMethods(params);
            let payload = { name, request };

            commit('setAddressShippingMethods', payload);
            setTimeout(() => {
                commit('unsetAddressShippingMethod', name);
            }, state.cacheLifespan);

            return request;
        },
        async getShippingMethodVendors({ state, commit }, params = {}) {
            const name = `${params.shipping_method_id}${params.vendor_id}`;

            if (state.shippingMethodVendors[name]) {
                return state.shippingMethodVendors[name];
            }
            let request = apis.Vendors.listShippingMethodVendors(params);
            let payload = { name, request };

            commit('setShippingMethodVendors', payload);
            setTimeout(() => {
                commit('unsetShippingMethodVendor', name);
            }, state.cacheLifespan);

            return request;
        },
    },
    getters: {
        shippingMethods(state) {
            return state.dropdownData;
        },
        empireMethods(state) {
            return state.dropdownData.filter((method) => method.is_empire_account);
        },
        vendorMethods(state) {
            return state.dropdownData.filter((method) => !method.is_empire_account);
        },
        nonFlatRateMethods(state) {
            return state.dropdownData.filter((method) => !method.is_flat_rate);
        },
        flatRateMethods(state) {
            return state.dropdownData.filter((method) => method.is_flat_rate);
        },
        methodWhereId(state) {
            return (methodId) => {
                return state.idToMethod[methodId];
            };
        },
        methodWhereImmutableName(state) {
            return (immutableName) => {
                return state.dropdownData.find((method) => method.immutable_name == immutableName);
            };
        },
        shippingMethodOptions(state, getters, rootState, rootGetters) {
            return (dropdownName) => {
                const dropdown = rootGetters['revamp/dropdowns/dropdownWhereName'](dropdownName);
                let options = [];
                if (dropdown) {
                    options = dropdown.children
                        .map((id) => {
                            return getters.methodWhereId(id);
                        })
                        // shippingMethod can be undefined if this action evaluates
                        // before shipping methods are fetched. Populating options with
                        // undefined causes TypeErrors downstream
                        .filter((shippingMethod) => shippingMethod !== undefined);
                }

                return options;
            };
        },
        empireShippingMethodOptions(state, getters) {
            return getters.shippingMethodOptions('EMPIRE_SHIPPING_METHODS');
        },
        billEmpireShippingMethodOptions(state, getters) {
            return getters.shippingMethodOptions('BILL_EMPIRE_SHIPPING_METHODS');
        },
        invoiceEmpireShippingMethodOptions(state, getters) {
            return getters.shippingMethodOptions('INVOICE_EMPIRE_SHIPPING_METHODS');
        },
        vendorShippingMethodOptions(state, getters) {
            return (serviceId) => {
                return getters.shippingMethodOptions('SHIPPING_SERVICE_' + serviceId);
            };
        },
        billVendorShippingMethodOptions(state, getters) {
            return (serviceId) => {
                return getters.shippingMethodOptions('BILL_SHIPPING_SERVICE_' + serviceId);
            };
        },
        invoiceVendorShippingMethodOptions(state, getters) {
            return (serviceId) => {
                return getters.shippingMethodOptions('INVOICE_SHIPPING_SERVICE_' + serviceId);
            };
        },
    },
};
