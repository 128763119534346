import _ from 'lodash';
import AdminUserApi from 'api/endpoints/admin/users';
import FacilityUserApi from 'api/endpoints/facility/users';
import { TABLE_STORE } from 'store/shared/mixins';
import { Statuses } from 'enums';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        default_sort: ['-invitationDate'],
    },
    actions: {
        async get({ state, commit, getters }, params = {}) {
            params = _.merge({ status_id: Statuses.WAITING }, params, state.filters, state.pagination, {
                sort: state.sort,
            });

            let response = await getters.api.get(params);
            commit('setData', response.data.data);

            if (response.data.meta) {
                commit('setPaginationData', response.data.meta.pagination);
            }
        },
    },
    getters: {
        api(state, getters, rootState, rootGetters) {
            return rootGetters['shared/currentUser/isFacility'] ? FacilityUserApi : AdminUserApi;
        },
        tableColumns(state, getters, rootState, rootGetters) {
            return [
                {
                    name: '__checkbox',
                    dataClass: 'has-text-centered',
                },
                {
                    name: 'invitation.dateOfInvite',
                    title: 'Invitation Date',
                    sortField: 'invitationDate',
                    titleClass: 'has-text-centered',
                    dataClass: 'has-text-centered',
                    callback: 'fromDateObject|MM/DD/YYYY',
                },
                {
                    name: 'email',
                    title: 'Email',
                    sortField: 'name',
                    titleClass: 'has-text-centered',
                    dataClass: 'has-text-centered',
                },
                {
                    name: 'invitation.invitedTo',
                    title: 'Invited To',
                    sortField: 'invitedBy',
                    titleClass: 'has-text-centered',
                    dataClass: 'has-text-centered',
                    displayIf: rootGetters['shared/currentUser/isAdmin'],
                },
            ];
        },
    },
});
