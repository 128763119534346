import {apis} from "api/client";
import _ from "lodash";
import Bugsnag from "@bugsnag/js";
import {Statuses, UpdateTypes} from "enums";

export default {
    async fetchMessages({ state, commit }, params) {
        const saveMeta = params.saveMeta;
        delete params.saveMeta;
        if (state.loadingMessages === false) {
            state.loadingMessages = true;
            try {
                let response = await apis.Messages.listMessages(params);
                let meta = response.body.meta;
                meta.links = response.body.links;
                commit('addMessages', response.body.data);
                if (saveMeta) {
                    commit('setMeta', meta);
                }
            } catch (e) {
                console.error(e);
            }
            state.loadingMessages = false;
        }
    },

    async fetchMessagesPage({ dispatch }, pageNumber) {
        pageNumber = pageNumber || 1;
        let params = {page: pageNumber, saveMeta: true};
        return await dispatch("fetchMessages", params);
    },

    async fetchNewMessages({ state, commit, dispatch }, messageId) {
        messageId = messageId || Math.max(...Object.keys(state.messages));
        let params = {after: messageId};
        return await dispatch("fetchMessages", params);
    },

    async fetchMessageThread({ commit,state,dispatch }, messageId) {
        let params = {message_id: messageId};
        try {
            let response = await apis.Messages.messageThread(params);
            commit('addMessages', response.body.data);
            return _.reduce(response.body.data, function(list, obj) {
                list.push(obj.id);
                return list;
            }, []);
        } catch (e) {
            console.error(e);
            if (e.response) {
                dispatch('shared/toast/show', {
                    content:e.response.body.message,
                    isDanger: true,
                }, {root:true});
            } else {
                Bugsnag.notify(e);
            }
            return false;
        }
    },

    async fetchMessageThreadById({ commit, dispatch }, params) {
        try {
            let response = await apis.Messages.viewThread(params);
            commit('addMessages', response.body.data);
            return _.reduce(response.body.data, function(list, obj) {
                list.push(obj.id);
                return list;
            }, []);
        } catch (e) {
            console.error(e);
            if (e.response) {
                dispatch('shared/toast/show', {
                    content:e.response.body.message,
                    isDanger: true,
                }, {root:true});
            } else {
                Bugsnag.notify(e);
            }
            return false;
        }
    },


    async createMessage({ commit, dispatch }, params) {
        try {
            let response = await apis.Messages.createMessage({}, {requestBody: params});
            commit('addMessages', [response.body]);
            return response.body;
        } catch (e) {
            console.error(e);
            if (e.response) {
                dispatch('shared/toast/show', {
                    content:e.response.body.message,
                    isDanger: true,
                }, {root:true});
            } else {
                Bugsnag.notify(e);
            }
            return false;
        }
    },


    async markMessageAsRead({ state, dispatch }, messageId) {
        state.inbox[messageId].status_id = Statuses.READ;
        let input = {params: {message_id: messageId}, body: {status_id: Statuses.READ}};
        return await dispatch("markMessageAs", input);
    },
    async markMessageAsUnread({ state, dispatch }, messageId) {
        state.inbox[messageId].status_id = Statuses.UNREAD;
        let input = {params: {message_id: messageId}, body: {status_id: Statuses.UNREAD}};
        return await dispatch("markMessageAs", input);
    },
    async markMessageAs({ commit, dispatch }, input) {
        try {
            let response = await apis.Messages.updateMessage(input.params, {requestBody: input.body});
            commit('addMessages', [response.body]);
            return response.body;
        } catch (e) {
            console.error(e);
            if (e.response) {
                dispatch('shared/toast/show', {
                    content:e.response.body.message,
                    isDanger: true,
                }, {root:true});
            } else {
                Bugsnag.notify(e);
            }
            return [];
        }
    },


    async markThreadAsRead({ dispatch }, threadId) {
        let input = {params: {thread_id: threadId}, body: {status_id: Statuses.READ}};
        return await dispatch("updateThread", input);
    },
    async markThreadAsUnread({ dispatch }, threadId) {
        let input = {params: {thread_id: threadId}, body: {status_id: Statuses.UNREAD}};
        return await dispatch("updateThread", input);
    },
    async updateThread({ commit, dispatch }, input) {
        try {
            let response = await apis.Messages.updateThread(input.params, {requestBody: input.body});
            commit('addMessages', response.body.data);
            return _.reduce(response.body.data, function(list, obj) {
                list.push(obj.id);
                return list;
            }, []);
        } catch (e) {
            console.error(e);
            if (e.response) {
                dispatch('shared/toast/show', {
                    content:e.response.body.message,
                    isDanger: true,
                }, {root:true});
            } else {
                Bugsnag.notify(e);
            }
            return [];
        }
    },

    async deleteThread({ commit, dispatch }, threadId) {
        let params = {thread_id: threadId};
        try {
            await apis.Messages.deleteThread(params);
            commit('markThreadAs', {threadId: threadId, statusId: Statuses.DELETED});
            return true;
        } catch (e) {
            console.error(e);
            if (e.response) {
                dispatch('shared/toast/show', {
                    content:e.response.body.message,
                    isDanger: true,
                }, {root:true});
            } else {
                Bugsnag.notify(e);
            }
            return false;
        }
    },

    async markAllMessagesAsRead({ commit, dispatch }) {
        try {
            let response = await apis.Messages.markAllMessagesAsRead();
            commit('addMessages', response.body.data);
            return _.reduce(response.body.data, function(list, obj) {
                list.push(obj.id);
                return list;
            }, []);
        } catch (e) {
            console.error(e);
            if (e.response) {
                dispatch('shared/toast/show', {
                    content:e.response.body.message,
                    isDanger: true,
                }, {root:true});
            } else {
                Bugsnag.notify(e);
            }
            return [];
        }
    },
    updateMessageStatusFromEvent({ commit }, event) {
        switch(event.updateType) {
            case UpdateTypes.ALL:
                commit("markAllMessageAs", event.status);
                break;
            case UpdateTypes.SINGLE:
                commit("markMessageAs", {messageId: event.id, statusId: event.status});
                break;
            case UpdateTypes.MULTIPLE:
                _.each(event.id, (id) => {
                    commit("markMessageAs", {messageId: id, statusId: event.status});
                });
                break;
            case UpdateTypes.THREAD:
                commit("markThreadAs", {threadId: event.id, statusId: event.status});
                break;
            default:
                console.warn("Unknown update type: ", event.updateType);
        }
    },
}
