export default {
    mapper: {
        relations: {
            belongsTo: {
                line: {
                    foreignKey: 'line_id',
                    localField: 'line',
                },
                shippingLabel: {
                    foreignKey: 'shipping_label_id',
                    localField: 'shippingLabel',
                },
            },
        },
    },
};
