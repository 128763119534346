export const EMPIRE_CONTACTS = {
    PHONE: '541.245.6657',
    EMAIL: 'po@empire-medical.com',
    FAX: '541.245.6687',
    SMS: '858.914.4560',
};

export const EDIT_INVENTORY_REDEMPTION_MODAL_SELECTED_PATIENTS_KEY = 'EditInventoryRedemptionModalSelectedPatients';

export const TYPE_INVOICES = 'invoices';
export const TYPE_BILLS = 'bills';
export const TYPE_PRODUCT = 'product';
export const TYPE_PRICE_LIST = 'price-list';
export const TYPE_TRACKING_NUMBERS = 'tracking-numbers';
export const TYPE_SHIPPING_AMOUNTS = 'shipping-amounts';
export const TYPE_BILL_WO_POS = 'upload-bill-wo-pos';
export const TYPE_CONFIRMATION_NUMBERS = 'confirmation-numbers';
export const TYPE_DIALOGUE = 'dialogue';
export const TYPE_INVENTORY = 'inventory';

export const MODE_CREATE = 'create';
export const MODE_EDIT = 'edit';

export const PRODUCT_TYPES = {
    TYPE_MANUAL: 1,
    TYPE_SEARCH: 2,
    TYPE_EXTRA: 4,
    TYPE_PRICE_LIST: 5,
};

export const LIST_STATES = [
    { value: 'AL', name: 'Alabama' },
    { value: 'AK', name: 'Alaska' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'AR', name: 'Arkansas' },
    { value: 'CA', name: 'California' },
    { value: 'CO', name: 'Colorado' },
    { value: 'CT', name: 'Connecticut' },
    { value: 'DE', name: 'Delaware' },
    { value: 'FL', name: 'Florida' },
    { value: 'GA', name: 'Georgia' },
    { value: 'HI', name: 'Hawaii' },
    { value: 'ID', name: 'Idaho' },
    { value: 'IL', name: 'Illinois' },
    { value: 'IN', name: 'Indiana' },
    { value: 'IA', name: 'Iowa' },
    { value: 'KS', name: 'Kansas' },
    { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' },
    { value: 'ME', name: 'Maine' },
    { value: 'MD', name: 'Maryland' },
    { value: 'MA', name: 'Massachusetts' },
    { value: 'MI', name: 'Michigan' },
    { value: 'MN', name: 'Minnesota' },
    { value: 'MS', name: 'Mississippi' },
    { value: 'MO', name: 'Missouri' },
    { value: 'MT', name: 'Montana' },
    { value: 'NE', name: 'Nebraska' },
    { value: 'NV', name: 'Nevada' },
    { value: 'NH', name: 'New Hampshire' },
    { value: 'NJ', name: 'New Jersey' },
    { value: 'NM', name: 'New Mexico' },
    { value: 'NY', name: 'New York' },
    { value: 'NC', name: 'North Carolina' },
    { value: 'ND', name: 'North Dakota' },
    { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' },
    { value: 'OR', name: 'Oregon' },
    { value: 'PA', name: 'Pennsylvania' },
    { value: 'RI', name: 'Rhode Island' },
    { value: 'SC', name: 'South Carolina' },
    { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' },
    { value: 'TX', name: 'Texas' },
    { value: 'UT', name: 'Utah' },
    { value: 'VT', name: 'Vermont' },
    { value: 'VA', name: 'Virginia' },
    { value: 'WA', name: 'Washington' },
    { value: 'WV', name: 'West Virginia' },
    { value: 'WI', name: 'Wisconsin' },
    { value: 'WY', name: 'Wyoming' },
];

export const LCODE_DISCLAIMER =
    ' All coding suggestions are based upon information provided by the manufacturer. Regardless of the source of the information, the final responsibility for correct coding within all established laws, rules, standards and practices is the sole responsibility of the facility submitting the claim.';

export const PART_NUMBER_MAX_LENGTH = 64;

export const NOTEABLE_TYPES = {
    ADDRESS: 'address',
    BILL: 'bill',
    CREDIT: 'credit',
    CREDIT_MEMO: 'credit-memo',
    FACILITY: 'facility',
    INVOICE: 'invoice',
    LINE: 'line',
    PRODUCT: 'product',
    PURCHASE_ORDER: 'purchase-order',
    RETURN_REQUEST: 'return-request',
    VENDOR: 'vendor',
};

export const PREFERENCES_KEYS = {
    ADDRESSES: 'addresses',
    BILL_IMPORT: 'bill-import',
    CATEGORIES: 'categories',
    DELETED_ITEMS: 'restore-deleted-items',
    EMAIL_PROCESSING: 'email-processing',
    EMAIL_PROCESSING_RULES: 'email_processing_rules',
    FACILITIES: 'facilities',
    INVENTORY: 'inventory',
    INVENTORY_REDEMPTIONS: 'inventory-redemptions',
    INVOICES: 'invoices',
    PATIENT_NAMES: 'patient_names',
    PRICE_LIST_IMPORT: 'price-list-import',
    SHOPPING_LISTS: 'shopping_lists',
    USERS: 'users',
    VENDORS: 'vendors',
    SHIPPING_IMPORT: 'shipping-import',
};

export const PAYMENT_TERMS = [
    { id: 3, name: 'Net 15' },
    { id: 1, name: 'Net 30' },
    { id: 15, name: 'Net 45' },
    { id: 7, name: 'Net 60' },
    { id: 21, name: 'Net 90' },
    { id: 2, name: 'Due on Receipt' },
];
