import _ from 'lodash';
import InventoryApi from 'api/endpoints/inventory';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    state: {
        searchSubmitted: false,
        moduleName: 'Inventory',
    },
    namespaced: true,
    mutations: {
        setDropdownData(state, payload) {
            state.dropdownData = payload;
        },
        setSearchSubmitted(state, value) {
            state.searchSubmitted = value;
        },
    },
    getters: {
        api() {
            return InventoryApi;
        },
    },
    actions: {
        get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);

            InventoryApi.get(params).then((response) => {
                commit('setData', response.data.data);
                commit('setPaginationData', response.data.meta.pagination);
            });

            commit('setSearchSubmitted', true);
        },
    },
});
