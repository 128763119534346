import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = params.id ? route('inventory.show', { id: params.id }) : route('inventory');
        delete params.id;
        return await axios.get(url, { params });
    },
    async delete(ids) {
        let url = route('inventory.destroy', { inventories: ids });
        return await axios.delete(url);
    },
    async update(id, payload) {
        let url = route('inventory.edit', { id: id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('inventory.create');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async redeem(payload) {
        let url = route('inventory.redeem', { hash: payload.hash });
        delete payload.hash;
        let result = await axios.post(url, payload);
        return result.data;
    },
    async printLabels(params = {}) {
        let url = route('inventory.label');
        return await axios.get(url, { responseType: 'blob', params });
    },
    async changeToProduct(payload = {}) {
        let url = route('inventory.change-to-product');
        let result = await axios.post(url, payload);
        return result.data;
    },
    async downloadCSV(params = {}) {
        let url = route('inventory.download-csv');
        return await axios.get(url, { params });
    },
};
