<template>
    <div class="authorized-clients">
        <div class="padded-content background-grey tile is-ancestor">
            <div class="tile is-parent is-vertical is-half">
                <div class="tile is-child box">
                    <div class="title">Partner Integration Permissions</div>
                    <div class="description">
                        <p
                            >Grant Empire's partner integrations access to {{ facilityName }} order data on your
                            behalf.</p
                        >
                        <p
                            ><strong
                                >Please Note: Changes to these values will apply to all {{ facilityName }} users. Only
                                disallow if you are sure no {{ facilityName }} users use this functionality.</strong
                            ></p
                        >
                    </div>
                    <div class="table-base has-side-borders">
                        <div class="table-row table-head">
                            <div class="col-name">Name</div>
                            <div class="col-allow-read-access">Allow Read Access</div>
                        </div>
                        <div
                            class="table-row"
                            v-for="partner in facilityPartners"
                            :key="`partner_row_${partner.oauth_partner_id}`"
                        >
                            <div class="col-name" v-if="partner.name">{{ partner.name }}</div>
                            <div class="col-name" v-else><emp-loading-indicator /></div>
                            <div class="col-allow-read-access">
                                <checkbox inputClass="" labelClass="" v-model="partner.allow_read_access" />
                            </div>
                        </div>
                        <div class="table-data-loading" v-if="!tokens.length && partnersLoading">
                            <emp-loading-indicator />
                        </div>
                        <informational-message
                            text="You have not authorized any third party applications to use Empire on your behalf."
                            v-if="!tokens.length && !partnersLoading"
                        />
                    </div>
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="level-item">
                                <empire-button
                                    label="Save"
                                    @click="savePartners()"
                                    buttonClass="is-primary"
                                    :disabled="partnersLoading"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tile is-parent is-vertical is-half">
                <div class="tile is-child box">
                    <div class="title">Authorized Applications</div>
                    <div class="description">
                        <p>Below is a list of applications you have authorized to access Empire on your behalf.</p>
                        <p>
                            If you decide you no longer want an application to be authorized, click the "Revoke" button
                            associated with that application.
                        </p>
                    </div>
                    <div class="table-base has-side-borders">
                        <div class="table-row table-head">
                            <div class="col-name">Name</div>
                            <div class="col-scopes">Scopes</div>
                            <div class="col-revoke">Revoke</div>
                        </div>
                        <div class="table-row" v-for="token in tokens" :key="`token_row_${token.id}`">
                            <div class="col-name">{{ token.client.name }}</div>
                            <div class="col-scopes">{{ formatScopes(token.scopes) }}</div>
                            <div class="col-revoke">
                                <confirmation-button label="Revoke" @click="revoke(token)" buttonClass="is-danger" />
                            </div>
                        </div>
                        <informational-message
                            text="You have not authorized any third party applications to use Empire on your behalf."
                            v-if="!tokens.length && !tokensLoading"
                        />
                        <div class="table-data-loading" v-if="tokensLoading">
                            <emp-loading-indicator />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { apis } from 'api/client';
import { handlesErrors } from 'component/revamp/elements/mixins';
import Bugsnag from '@bugsnag/js';
const Checkbox = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/Checkbox');
const Button = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/Button');
const ConfirmationButton = () =>
    import(/* webpackChunkName: "common" */ 'component/shared/elements/ConfirmationButton');
const InformationalMessage = () =>
    import(/* webpackChunkName: "common" */ 'component/shared/table/InformationalMessage');

export default {
    mixins: [handlesErrors],
    components: {
        InformationalMessage,
        'empire-button': Button,
        Checkbox,
        ConfirmationButton,
    },
    data() {
        return {
            tokens: [],
            facilityPartners: [],
            partners: [],
            tokensLoading: true,
            partnersLoading: true,
        };
    },
    methods: {
        initializeComponent() {
            this.getTokens();
            this.getPartners();
        },
        async getPartners() {
            this.partnersLoading = true;

            await this.getFacilityPartners();

            if (!this.facilityPartners.length) {
                this.partnersLoading = false;
                return;
            }

            try {
                const response = await apis.OAuthPartners.listOAuthPartners({});
                this.partners = response.body.data;
            } catch (e) {
                this.handleError(e, `There was an issue retrieving partner data.`);
            }
            this.addNamesToFacilityPartners();
            this.partnersLoading = false;
        },
        async getFacilityPartners() {
            try {
                const response = await apis.FacilityOAuthPartners.listFacilityOAuthPartners(
                    {
                        facility_id: this.currentUser.facilityId,
                    },
                    {}
                );
                this.facilityPartners = response.body.data;
            } catch (e) {
                this.handleError(e, `There was an issue retrieving partner data associated with ${this.facilityName}.`);
            }
        },
        addNamesToFacilityPartners() {
            const partnerNamesById = {};
            for (let partner of this.partners) {
                partnerNamesById[partner.id] = partner.name;
            }
            for (let facilityPartner of this.facilityPartners) {
                facilityPartner['name'] = partnerNamesById[facilityPartner.oauth_partner_id];
            }
        },
        getTokens() {
            this.tokensLoading = true;
            const route = this.route('passport.tokens.index');
            axios.get(route).then((response) => {
                this.tokens = response.data;
            });
            this.tokensLoading = false;
        },
        revoke(token) {
            const route = this.route('passport.tokens.destroy', {
                token_id: token.id,
            });
            axios.delete(route).then((response) => {
                this.getTokens();
            });
        },
        formatScopes(scopes) {
            return scopes.join(', ');
        },
        async savePartners() {
            let errorOccurred = false;

            for (let facilityPartner of this.facilityPartners) {
                try {
                    await apis.FacilityOAuthPartners.updateFacilityOAuthPartner(
                        {
                            facility_id: facilityPartner.facility_id,
                            oauth_partner_id: facilityPartner.oauth_partner_id,
                        },
                        {
                            requestBody: { allow_read_access: facilityPartner.allow_read_access },
                        }
                    );
                } catch (e) {
                    Bugsnag.notify(e);
                    errorOccurred = true;
                }
            }

            if (errorOccurred) {
                this.showDangerNotification('An error occurred while saving partner integration permissions.');
                return;
            }

            this.showSuccessNotification('Partner integration permissions successfully saved.');
        },
    },
    computed: {
        facilityName() {
            return _.get(this, 'currentUser.facility.name');
        },
    },
    mounted() {
        this.initializeComponent();
    },
};
</script>

<style lang="scss" scoped>
.authorized-clients {
    .description p {
        padding: 0 0 1rem;
    }
    .table-base {
        .table-row {
            .col-name {
                flex: 1 0 0;
                min-width: 100px;
            }
            .col-allow-read-access {
                flex: 1 0 0;
                min-width: 100px;
            }
            .col-scopes {
                flex: 1 0 0;
                min-width: 100px;
            }
            .col-revoke {
                flex: 1 0 0;
                min-width: 100px;
            }
        }
    }
    .level {
        margin-top: 1rem;
    }
}
</style>
