<template>
    <div
        :class="['field', fieldClass, addonsClass]"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
        @touchstart="onTouchStart"
    >
        <label :class="['label', labelClass]" v-if="label" :for="`${name}_${_uid}`">
            {{ label }}
        </label>
        <span class="control" :class="controlClass">
            <span :class="['select is-fullwidth', inputClass, { 'is-danger': hasError, 'is-loading': isLoading }]">
                <select
                    :id="`${name}_${_uid}`"
                    :class="inputClass"
                    :disabled="disabled"
                    v-model="valueProxy"
                    @blur="onBlur"
                    @focus="onFocus"
                    v-bind="$attrs"
                >
                    <slot name="options">
                        <option v-if="placeholder !== false" :value="null">{{ placeholder }}</option>
                        <template v-if="groupOrder">
                            <optgroup v-for="groupName in groupOrder" :label="groupName">
                                <template v-for="option in groups[groupName]">
                                    <option
                                        :key="option[valueFrom]"
                                        :value="option[valueFrom]"
                                        :disabled="optionIsDisabled(option)"
                                        >{{ getName(option) }}</option
                                    >
                                </template>
                            </optgroup>
                        </template>
                        <template v-else>
                            <template v-for="option in selectables">
                                <option
                                    :key="option[valueFrom]"
                                    :value="option[valueFrom]"
                                    :disabled="optionIsDisabled(option)"
                                    >{{ getName(option) }}</option
                                >
                            </template>
                        </template>
                    </slot>
                </select>
            </span>
        </span>
        <template v-if="hasErrorArray">
            <p class="help is-danger" v-for="(error, index) in errors[name]" :key="index">{{ error }}</p>
        </template>
        <template v-else-if="hasError">
            <p class="help is-danger">{{ errors[name] }}</p>
        </template>
        <slot name="addons">
            <!-- Used for Bulma style "addon" buttons -->
        </slot>
    </div>
</template>

<script>
import _ from 'lodash';
import { hasOptionProps, hasInputProps, hasInput } from '@/has';
import { defineComponent, ref, computed } from '@vue/composition-api';

export default defineComponent({
    // Prevent parent scope attribute bindings from being
    // applied to the root element of this component. In most
    // cases, the intent is to apply the attribute to the wrapped
    // `select` and not to the `div`.
    inheritAttrs: false,
    props: {
        ...hasInputProps(),
        ...hasOptionProps(),
        nameCallback: {
            type: [String, Function],
            default: null,
        },
        selectSolitaryOption: {
            type: Boolean,
            default: true,
        },
        controlClass: {
            type: String,
            default: '',
        },
        hideSingleGroup: {
            type: Boolean,
            default: false,
        },
        groupBy: {
            type: Function,
            default: null,
        },
        groupSort: {
            type: [Array, Function],
            default: null,
        },
    },
    setup(props, { slots }) {
        const isSelecting = ref(false);
        const isFocused = ref(false);
        const hasAddons = ref(false);
        const uid = ref(Math.random());
        const componentId = ref(`select_${uid.value}`);

        const addonsClass = computed(() => {
            return !!slots?.addons ? 'has-addons' : '';
        });

        return {
            ...hasInput(props),
            uid,
            componentId,
            isSelecting,
            isFocused,
            hasAddons,
            addonsClass,
        };
    },
    methods: {
        getName(option) {
            if (typeof this.nameCallback === 'function') {
                return this.nameCallback(option);
            }

            if (typeof this.$parent[this.nameCallback] == 'function') {
                return this.$parent[this.nameCallback].call(this.$parent, option);
            }

            return option[this.nameFrom];
        },
        onMouseover() {
            this.isSelecting = true;
        },
        onMouseleave() {
            if (!this.isFocused) {
                this.isSelecting = false;
            }
        },
        onFocus($event) {
            this.$emit('focus', $event);
            this.isSelecting = true;
            this.isFocused = true;
        },
        onBlur($event) {
            this.$emit('blur', $event);
            this.isFocused = false;
        },
        onTouchStart($event) {
            this.isSelecting = true;
        },
        optionIsDisabled(option) {
            if (option[this.valueFrom] == null) {
                return true;
            }

            return Boolean(option.isDisabled);
        },
    },
    computed: {
        valueProxy: {
            // This proxy exists to prevent a perceived change in the selected value when a user mouses over the
            // select for a Select that has a value that does not correspond to an option. If the `v-model` directive
            // is used, then the change options that occurs when `isSelecting` is truthy (see `selectables`) does
            // not cause the selected value to appear to change. However, if the `v-model` directive is replaced
            // with the equivalent binding, `:value="value"`, and listener, `@change="$emit('input', $event.target.value)"`
            // then an option will appear to be selected when the user mouses over the select and a different option
            // selected when the user's mouse exits the select. Those events do not cause an actual change to
            // `value` or cause the emission of any Vue events, so it's unclear why the value appears to change.
            // When applied to a `select`, `v-model` does change its behavior slightly, so this is likely an edge case
            // that `v-model` handles. Exactly how its handled or why occurs remains a mystery. If you solve it, please
            // replace this comment with a better explanation.
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        groups() {
            return this.groupBy ? _.groupBy(this.selectables, this.groupBy) : {};
        },
        groupOrder() {
            //If we don't more than one group to display just return false
            let groupCount = Object.keys(this.groups).length;
            if (groupCount === 0 || (groupCount === 1 && this.hideSingleGroup)) {
                return false;
            }

            //If we have a custom sorting mechanism use it
            if (this.groupSort) {
                //Custom sort() comparator
                if (typeof this.groupSort === 'function') {
                    return Object.keys(this.groups).sort(this.groupSort);
                }
                //An array with the keys pre-sorted.
                //Note: This will default to any keys not in the pre-sorted list being
                // tacked on in alphabetical order at the end, not left out.
                if (Array.isArray(this.groupSort)) {
                    return [
                        ...new Set([
                            ...this.groupSort.filter((key) => this.groups.hasOwnProperty(key)),
                            ...Object.keys(this.groups).sort(),
                        ]),
                    ];
                }
            }

            //Default to alphabetical order.
            return Object.keys(this.groups).sort();
        },
        selectables() {
            //todo: Find a better way to do this than a flag tied to window.
            if (
                this.isSelecting ||
                this.definedOptions.length < 10 ||
                (typeof jest === 'undefined' && !window.enableSelectOptimization)
            ) {
                return this.definedOptions;
            }
            // JEF: Always include the longest if it exists
            //      so that the width of the select does not
            //      change on mouseover.
            if (this.selected) {
                if (this.longest && this.selected !== this.longest) {
                    return [this.selected, this.longest];
                }
                return [this.selected];
            }
            if (this.longest) {
                return [this.longest];
            }
            return [];
        },
        definedOptions() {
            return this.options.filter((option) => !_.isUndefined(option));
        },
        selected() {
            return this.definedOptions.find((option) => this.value == option[this.valueFrom]);
        },
        longest() {
            let max = 0;
            let idxOfMax = 0;
            for (var i = this.definedOptions.length - 1; i >= 0; i--) {
                const element = this.definedOptions[i];
                const name = element[this.nameFrom];
                if (name !== null && name !== undefined) {
                    const currentLength = name.trim().length;
                    if (currentLength > max) {
                        idxOfMax = i;
                        max = currentLength;
                    }
                }
            }
            return this.definedOptions[idxOfMax];
        },
    },
    watch: {
        options: {
            handler: function (val, old) {
                if (this.selectSolitaryOption) {
                    if (val.length == 1 && val[0][this.valueFrom] != this.value) {
                        this.$emit('input', val[0][this.valueFrom]);
                    }
                }
            },
            immediate: true,
        },
    },
});
</script>

<style lang="scss">
select.highlight-light-blue {
    background-color: #537d8d;
    color: #fff;
}
</style>
