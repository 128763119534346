// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Address Facilty Type Map',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Address facility type map.
 * @returns
 */
export default function useAddressFacilityTypeMap(config = {}) {
    const fetcher = async () => {
        const response = await apis.Addresses.listAddressFacilityTypeMap({});
        return response.body.data || [];
    };

    const { data: addressFacilityMap, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<AddressV2[]>}
         */
        addressFacilityMap,
        error,
        isValidating,
        mutate,
    };
}
