<template>
    <empire-select
        v-bind="$attrs"
        :options="options"
        :disabled="!options.length || disabled"
        :value="value"
        valueFrom="id"
        v-on="$listeners"
        @input="onInput($event)"
        :label="label"
        emp-data="selectFacility"
        :inputClass="highlightClass"
        :placeholder="placeholder"
        :groupBy="groupByStatus"
    />
</template>

<script>
import _ from 'lodash';
import Partials from 'use/partials';
import EmpireSelect from 'component/shared/elements/Select';
import { computed, defineComponent } from '@vue/composition-api';
import { useStore } from 'use/store';
import { useGroupByStatus } from '@/use/groups';
import { all, conditionalFilter, equal, filterList, inArray, not } from '@/use/filters';
import { Statuses } from 'enums';

export default defineComponent({
    inheritAttrs: false,
    components: {
        EmpireSelect,
    },
    props: {
        fetchNested: {
            type: Boolean,
            // TODO: Reverse this default and check all usages
            default: true,
        },
        placeholder: {
            type: [String, Boolean],
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number, Array, Object, Boolean],
            default: null,
        },
        label: {
            type: String,
            default: 'Facility',
        },
        blacklist: {
            type: Array,
            default: (input) => {
                if (input) {
                    [...input];
                } else {
                    return [];
                }
            },
        },
        onlyActiveOptions: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const store = useStore();
        const groupByStatus = useGroupByStatus('status_id');

        const filters = computed(() => {
            return filterList([
                conditionalFilter(not(inArray(props.blacklist, 'id')), props.blacklist.length),
                conditionalFilter(equal('status_id', Statuses.ACTIVE), props.onlyActiveOptions),
            ]);
        });
        const { facilities } = Partials.useFacilityDropdowns();

        const options = computed(() => {
            return facilities.value ? facilities.value.filter(all(filters.value)) : [];
        });

        const highlightClass = computed(() => {
            let selectedFacility = options.value.find((facility) => facility.id == props.value);

            let isFlagship = _.get(selectedFacility, 'is_flagship', false);
            if (isFlagship) {
                return 'flagship-selected';
            }

            let babysit = _.get(selectedFacility, 'babysit', false);
            if (babysit) {
                return 'babysit-selected';
            }

            return;
        });

        const getNested = (value) => {
            if (props.fetchNested) {
                store.dispatch('shared/fetchInventoryAddresses', value);
                store.dispatch('shared/fetchInventoryManufacturers', value);
                store.dispatch('fetchInventoryVendors', value);
            }
        };
        getNested(props.value);

        const onInput = (value) => {
            getNested(value);
            emit('input', value);
        };

        return {
            options,
            facilities,
            highlightClass,
            getNested,
            onInput,
            groupByStatus,
        };
    },
});
</script>
