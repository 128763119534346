<template>
    <div class="tooltip-notes"
        ><!--
        --><p class="tooltip-note" v-for="(note, index) in notes" :key="index"
            ><!--
            -->{{ note.body.trim() }} {{ metaData(note)
            }}<!--
        --></p
        ><!--
    --></div>
</template>

<style type="text/css" scoped>
.tooltip-note {
    white-space: pre-line;
}
</style>

<script>
import moment from 'moment';

export default {
    props: {
        notes: Array,
        showAge: {
            type: Boolean,
            default: false,
        },
        showNoteTaker: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getDateOutput(timestamp) {
            return moment(timestamp).format('M/D/YY h:mma');
        },
        metaData(note) {
            let output = '';
            const name = note.notetakerName || note.notetaker_name;

            if (this.showAge && this.showNoteTaker) {
                output = '(' + name + ' - ' + this.getDateOutput(note.updated_at) + ')';
            } else if (this.showAge) {
                output = '(' + this.getDateOutput(note.updated_at) + ')';
            } else if (this.showNoteTaker) {
                output = '(' + name + ')';
            }
            return output;
        },
    },
};
</script>
