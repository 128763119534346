import _ from 'lodash';
import { TABLE_STORE } from 'store/shared/mixins';
import * as actions from 'store/actions';
import SmartApi from 'SmartApi';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        data: [],
        statuses: [{ id: 'processing' }, { id: 'success' }, { id: 'error' }, { id: 'manually resolved' }],
        pagination: {
            page: 1,
            per_page: 25,
        },
        sort: ['-created_at'],
        default_sort: ['-created_at'],
    },
    getters: {},
    mutations: {
        updateResult(store, result) {
            const index = store.data.findIndex(({ id }) => id == result.id);
            store.data.splice(index, 1, result);
        },
    },
    actions: {
        getData({ dispatch }) {
            dispatch(actions.GET_TRACKING_NUMBER_IMPORT_RESULTS);
        },
        async [actions.GET_TRACKING_NUMBER_IMPORT_RESULTS]({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);

            const request = {
                routeName: 'tracking-number-import-results.index',
                config: { params },
            };

            const response = await SmartApi.get(request);

            commit('setData', response.data.data);
            commit('setPaginationData', response.data.meta);
        },
        async [actions.UPDATE_TRACKING_NUMBER_IMPORT_RESULTS]({ state, commit }, payload) {
            const original = state.data.find((item) => item.id === payload.id);
            if (!original) {
                return;
            }
            const newResult = Object.assign({}, original, payload);
            commit('updateResult', newResult);
            let response;
            try {
                response = await SmartApi.put({
                    routeName: 'tracking-number-import-results.update',
                    routeParams: { id: newResult.id },
                    data: newResult,
                });
            } catch (error) {
                commit('updateResult', original);
                throw error;
            }
            commit('updateResult', response.data.data);
        },
    },
});
