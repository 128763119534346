import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';
import modules from './modules';

export default {
    actions,
    getters,
    mutations,
    state,
    modules,
};
