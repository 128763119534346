const MODAL_EDIT_DEFAULTS = {
    heading: null,
    fields: [],
    editObject: {},
    handler: ()=>{}
};

export default {
    namespaced: true,
    state: {
        isActive: false,
        heading: null,
        fields: [],
        editObject: {},
        handler: ()=>{}
    },
    mutations: {
        setModalEditState(state, payload) {
            state.isActive  = true;
            state.fields     = payload.fields;
            state.editObject   = payload.editObject;
            state.heading     = payload.heading;
            state.handler = payload.handler;
        },
        clearModalEditState(state) {
            state.isActive = false;

            Object.keys(MODAL_EDIT_DEFAULTS).forEach((key) => {
                state[key] = MODAL_EDIT_DEFAULTS[key];
            });
        }
    },
    actions: {
        show(context, payload) {
            let completePayload = Object.assign({}, MODAL_EDIT_DEFAULTS, payload);
            context.commit('setModalEditState', completePayload);
        },
        close(context) {
            context.commit('clearModalEditState');
        }
    }
};