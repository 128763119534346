import { apis } from 'api/client';
import _ from 'lodash';
import Bugsnag from '@bugsnag/js';

export default {
    namespaced: true,
    state: {
        valuesByName: null,
        inRequest: false,
    },
    mutations: {
        mapValuesByName(state, settings) {
            let valuesByName = {};
            settings.forEach((setting) => (valuesByName[setting.name] = setting.value));
            // Wait to set state.valuesByName so getters.featureFlagIsEnabled doesn't throw errors in the meantime. -JJB
            state.valuesByName = valuesByName;
        },
    },
    actions: {
        async getData({ state, commit }) {
            if (state.inRequest) {
                return;
            }

            state.inRequest = true;

            let response;
            try {
                response = await apis.SystemSettings.listSystemSettings({
                    per_page: -1,
                    pagination_type: 'simple',
                });
            } catch (e) {
                console.error(e);
                Bugsnag.notify(e);
            }

            commit('mapValuesByName', response.body.data);

            state.inRequest = false;
        },
    },
    getters: {
        settingByName(state, getters) {
            return (name) => {
                if (! state.valuesByName) {
                    return null;
                }
                return state.valuesByName[name] || null;
            };
        },
        featureFlagIsEnabled(state, getters) {
            return (flagName) => {
                if (! state.valuesByName) {
                    return false;
                }
                if (! flagName.startsWith('FF_')) {
                    flagName = `FF_${flagName}`;
                }

                if (state.valuesByName && ! state.valuesByName.hasOwnProperty(flagName)) {
                    const error = new Error(`${flagName} feature flag setting value could not be retrieved. Please verify the name is correct.`);
                    Bugsnag.notify(error);
                    console.error(error);
                }

                const settingValue = state.valuesByName[flagName];

                if (typeof settingValue == 'boolean' || settingValue === null) {
                    return settingValue;
                }

                const settingDate = new Date(settingValue);

                if (isNaN(settingDate.getDate())) {
                    return settingValue;
                }

                return new Date() > settingDate;
            };
        },
    },
};
