import _ from "lodash";

export default {
    createToast(state, toast) {
        state.toasts = {[toast.id]: toast, ...state.toasts};
    },
    deleteToast(state, id) {
        state.toasts = _.omit(state.toasts, id);
    },
    clearAllToasts(state) {
        state.toasts = {};
    },
}
