export const idToNameMap = {
    1: 'Alabama',
    2: 'Alaska',
    3: 'Arizona',
    4: 'Arkansas',
    5: 'California',
    6: 'Colorado',
    7: 'Connecticut',
    8: 'Delaware',
    9: 'Florida',
    10: 'Georgia',
    11: 'Hawaii',
    12: 'Idaho',
    13: 'Illinois',
    14: 'Indiana',
    15: 'Iowa',
    16: 'Kansas',
    17: 'Kentucky',
    18: 'Louisiana',
    19: 'Maine',
    20: 'Maryland',
    21: 'Massachusetts',
    22: 'Michigan',
    23: 'Minnesota',
    24: 'Mississippi',
    25: 'Missouri',
    26: 'Montana',
    27: 'Nebraska',
    28: 'Nevada',
    29: 'New Hampshire',
    30: 'New Jersey',
    31: 'New Mexico',
    32: 'New York',
    33: 'North Carolina',
    34: 'North Dakota',
    35: 'Ohio',
    36: 'Oklahoma',
    37: 'Oregon',
    38: 'Pennsylvania',
    39: 'Rhode Island',
    40: 'South Carolina',
    41: 'South Dakota',
    42: 'Tennessee',
    43: 'Texas',
    44: 'Utah',
    45: 'Vermont',
    46: 'Virginia',
    47: 'Washington',
    48: 'West Virginia',
    49: 'Wisconsin',
    50: 'Wyoming',
    51: 'Puerto Rico',
    52: 'District Of Columbia',
    53: 'Virgin Islands of the United States',
};
export const idToCodeMap = {
    1: 'AL',
    2: 'AK',
    3: 'AZ',
    4: 'AR',
    5: 'CA',
    6: 'CO',
    7: 'CT',
    8: 'DE',
    52: 'DC',
    9: 'FL',
    10: 'GA',
    11: 'HI',
    12: 'ID',
    13: 'IL',
    14: 'IN',
    15: 'IA',
    16: 'KS',
    17: 'KY',
    18: 'LA',
    19: 'ME',
    20: 'MD',
    21: 'MA',
    22: 'MI',
    23: 'MN',
    24: 'MS',
    25: 'MO',
    26: 'MT',
    27: 'NE',
    28: 'NV',
    29: 'NH',
    30: 'NJ',
    31: 'NM',
    32: 'NY',
    33: 'NC',
    34: 'ND',
    35: 'OH',
    36: 'OK',
    37: 'OR',
    38: 'PA',
    39: 'RI',
    40: 'SC',
    41: 'SD',
    42: 'TN',
    43: 'TX',
    44: 'UT',
    45: 'VT',
    46: 'VA',
    47: 'WA',
    48: 'WV',
    49: 'WI',
    50: 'WY',
    51: 'PR',
    53: 'VI',
};
