import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';
import * as mutationTypes from './mutation_types';
import * as actionTypes from './action_types';

export { mutationTypes };
export { actionTypes };

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};