import _ from 'lodash';
import { ORDERS_TABLE, MANAGE_FORM } from 'store/admin/mixins/orders';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, ORDERS_TABLE, MANAGE_FORM, {
    namespaced: true,
    state: {
        default_sort: ['vendor_name', '-id'],
        organizeBy: 'vendor_name',
        moduleName: 'Orders Search',
    },
});
