import _ from 'lodash';

export default {
    getInventoryManufacturers: (state) => state.inventoryManufacturers,
    getInventoryAddresses: (state) => state.inventoryAddresses,
    getManufacturersWithProduct: (state) => state.manufacturersWithProduct,
    whitelistedUsers: (state) => state.whitelistedUsers,
    whitelistedAddresses: (state) => state.whitelistedAddresses,
    whitelistedShippingAddresses: (state, getters) =>
        _.sortBy(
            state.whitelistedAddresses.filter((address) => address.enabled_as_shipping_address && address.is_ship_to),
            (address) => address.name.toUpperCase()
        ),
    whitelistedCostCenters: (state, getters) =>
        _.sortBy(
            state.whitelistedAddresses.filter((address) => address.enabled_as_cost_center && address.is_cost_center),
            (address) => address.name.toUpperCase()
        ),
    getManufacturersByProductType(state) {
        return (productTypeId) => {
            if (state.manufacturersByProductType.hasOwnProperty(productTypeId)) {
                return state.manufacturersByProductType[productTypeId];
            }

            return [];
        };
    },
    getManufacturerByProductTypeAndId(state, getters) {
        return ({ productTypeId, manufacturerId }) => {
            return getters
                .getManufacturersByProductType(productTypeId)
                .find((manufacturer) => manufacturer.id === manufacturerId);
        };
    },
    expandedTableLinesForIdentifier(state, getters) {
        return function (tableId) {
            return _.get(state.expandedTableLines, tableId, []);
        };
    },
};
