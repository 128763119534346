<template>
    <emp-component-modal @modal:close="closeModal" :isActive="isActive">
        <template v-if="isActive">
            <template slot="modal-header">
                <strong>Message Required</strong>
            </template>
            <template slot="modal-body">
                <div class="message-body-content need-more-info-message-form">
                    <p>
                        <strong
                            >In order to mark Line(s) as {{ toStatus }} you must supply a message indicating what info
                            is needed:</strong
                        >
                    </p>
                    <VueTrix v-model="message" placeholder="Type your message here." />
                </div>
                <div class="message-body-buttons">
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="level-item">
                                <emp-button @click="onConfirm" buttonClass="is-primary" :label="toStatus" />
                            </div>
                            <div class="level-item">
                                <confirmation-button label="Cancel" buttonClass="is-danger" @click="closeModal" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </emp-component-modal>
</template>

<script>
import { mapActions } from 'vuex';

const ConfirmationButton = () =>
    import(/* webpackChunkName: "common" */ 'component/shared/elements/ConfirmationButton');
import VueTrix from 'vue-trix';
import { Statuses } from 'enums';

export default {
    components: {
        ConfirmationButton,
        VueTrix,
    },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        reason: {
            type: String,
            default: () => '',
        },
        statusId: {
            type: Number,
        },
    },
    data() {
        return {
            message: this.reason,
        };
    },
    methods: {
        ...mapActions({
            closeModal: 'shared/modal/closeModal',
        }),
        onConfirm() {
            if (!!this.message && this.message.length > 30) {
                this.$emit('confirmWithMessage', this.message);
            } else {
                this.showDangerNotification('An explanation message of at least 20 characters is required');
            }
        },
    },
    computed: {
        toStatus() {
            return this.statusId == Statuses.ORDERED_NEED_MORE_INFO ? 'Ordered - Need More Info' : 'Need More Info';
        },
    },
};
</script>

<style lang="scss">
/* This is a soft solution to hide the trix attachment button because internal
    messages don't currently support attachments. The documentation for trix makes no
    mention of the "proper" way to hide the button, but a github issue suggests this: */
.need-more-info-message-form trix-toolbar .trix-button-group--file-tools {
    display: none;
}
</style>
