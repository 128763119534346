<template>
    <component-modal :is-active="isActive" @modal:close="onCancel">
        <template v-slot:modal-header>Conflicting Updates!</template>
        <template v-slot:modal-body>
            <div>
                <article class="message is-warning">
                    <div class="message-body">
                        Your update conflicts with another recent update! Please review the following conflicting
                        attributes and select the values you want to save.
                    </div>
                </article>
                <conflicting-update-resolution-modal-chunk
                    v-for="conflict in conflicts"
                    :key="conflict.model.id"
                    :model="conflict.model"
                    :model-name="modelName"
                    :previous="conflict.previous"
                    :current="conflict.current"
                    class="block"
                />
                <div class="field is-grouped is-grouped-right">
                    <div class="control">
                        <button class="button is-primary" @click="onConfirm">Confirm</button>
                    </div>
                    <div class="control">
                        <button class="button is-link is-danger" @click="onCancel">Cancel</button>
                    </div>
                </div>
            </div>
        </template>
    </component-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
const ComponentModal = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/ComponentModal.vue');
const ConflictingUpdateResolutionModalChunk = () =>
    import('component/shared/ConflictingUpdateResolutionModalChunk.vue');
export default {
    components: {
        ComponentModal,
        ConflictingUpdateResolutionModalChunk,
    },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        modelName: {
            type: String,
            required: false,
            default: 'Model',
        },
    },
    computed: {
        ...mapState({
            data: (state) => state.shared.modal.data,
        }),
        conflicts() {
            return _.get(this.data, 'conflicts', []);
        },
    },
    methods: {
        ...mapActions('shared/modal', ['closeModal']),
        async onConfirm() {
            const resolver = _.get(this.data, 'onConfirm', _.noop);
            return await this.closeModal({
                method: resolver,
                params: this.conflicts,
            });
        },
        onCancel() {
            const resolver = _.get(this.data, 'onCancel', _.noop);
            this.closeModal({
                method: resolver,
                params: this.conflicts,
            });
        },
    },
};
</script>

<style></style>
