<template
    ><!--
    --><div
        ><!--
        --><div class="required-notes" v-show="required.length"
            ><!--
            --><strong>Required:</strong
            ><!--
            --><emp-tooltip-notes :showAge="true" :showNoteTaker="true" :notes="required" /><br /><!--
        --></div
        ><!--
        --><div class="general-notes" v-show="general.length"
            ><!--
            --><strong>General:</strong
            ><!--
            --><emp-tooltip-notes :showAge="true" :showNoteTaker="true" :notes="general" /><!--
        --></div
        ><!--
    --></div
    ><!--
--></template>

<script>
export default {
    props: {
        notes: Array,
    },
    computed: {
        required() {
            return this.notes.filter((note) => note.type === 'required');
        },
        general() {
            return this.notes.filter((note) => note.type === 'general');
        },
    },
};
</script>
