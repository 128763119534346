<template>
    <empire-textarea
        v-if="isEditing"
        class="editing"
        :value="content"
        v-on:input="$emit('update:content', $event)"
        @keyup.enter="$emit('update:content', $event)"
    ></empire-textarea>
    <div v-else class="viewing">{{ content.trim() }}</div>
</template>

<script>
const Textarea = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/Textarea');

export default {
    components: {
        'empire-textarea': Textarea,
    },
    props: ['content', 'isEditing'],
};
</script>

<style type="text/css" scoped>
.editing {
    width: 100%;
}
.viewing {
    white-space: pre-line;
}
</style>
