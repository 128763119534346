<template>
    <emp-component-modal @modal:close="onClose" :isActive="isActive" contentClass="width-30vw">
        <template v-if="isActive">
            <div slot="modal-header">Choose a Shipping Service/Expected Date</div>
            <div slot="modal-body">
                <div class="choose-date-expected">
                    <div class="instructions">
                        Please select a shipping service/expected date.
                        <strong v-show="shipDateLeadTime">(Lead Time: {{ shipDateLeadTime }})</strong>
                    </div>
                    <div class="mobile-table-wrapper">
                        <emp-radio
                            name="expected-dates"
                            :options="shippingServiceOptionsArray"
                            fieldClass=""
                            label=""
                            :value="selectedDateExpected"
                            v-model="selectedDateExpected"
                        />
                    </div>
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="level-item">
                                <emp-button
                                    label="Choose Selected"
                                    @click="onSubmit"
                                    buttonClass="is-primary"
                                    :disabled="noOptionSelected"
                                />
                            </div>
                            <div class="level-item">
                                <emp-button buttonClass="is-danger" label="Cancel" @click="onClose" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </emp-component-modal>
</template>

<script>
import _ from 'lodash';
import { systemNotifications, formatters } from 'component/shared/elements/mixins';
import { mapState, mapActions, mapGetters } from 'vuex';
import Bugsnag from '@bugsnag/js';

export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedDateExpected: null,
            shippingServiceOptions: new Map(),
        };
    },
    mixins: [systemNotifications, formatters],
    methods: {
        ...mapActions({
            closeModal: 'shared/modal/closeModal',
            getDateExpected: 'shippingEstimates/getDateExpected',
        }),
        ...mapGetters({
            getVendor: 'shared/vendors/selectedVendor',
        }),
        getDatesExpectedForShippingServices() {
            if (this.shippingServiceOptions.size) {
                return;
            }

            let payload = {
                shippingAddressId: this.shippingAddressId,
            };

            if (this.warehouse) {
                payload['warehouseId'] = this.warehouse.id;
            } else {
                payload['vendorId'] = this.vendor.id;
            }

            for (let service of this.shippingServices) {
                let serviceName = this.getNameForServiceWithKey(service.routeKey);
                payload['shippingServiceKey'] = service.routeKey;

                this.shippingServiceOptions.set(service.routeKey, {
                    name: `${serviceName} - Loading...`,
                    value: null,
                });

                this.getDateExpected(payload).then(
                    (response) => {
                        let shippingServiceOption = this.shippingServiceOptions.get(service.routeKey);

                        if (!response.dateExpected) {
                            shippingServiceOption.name = `${serviceName} - Unable to retrieve expected date estimate`;
                            return;
                        }

                        let formattedDate = this.fromDateObject(response.dateExpected, null, 'M/D/YY');
                        shippingServiceOption.name = `${serviceName} - ${formattedDate}`;
                        shippingServiceOption.value = response.dateExpected;

                        this.shippingServiceOptions.set(service.routeKey, shippingServiceOption);
                        this.shippingServiceOptions = new Map(this.shippingServiceOptions);
                    },
                    (error) => {
                        const defaultMessage = 'An unexpected error occurred. The development team has been notified.';
                        const message = _.get(error, 'response.data.message', defaultMessage);
                        if (message === defaultMessage) {
                            // If the message is the default message, then the error the error does not have the
                            // expected schema, which means it's either a client side error or the server
                            // sent an unexpected response.
                            Bugsnag.notify(error);
                        }
                        let shippingServiceOption = this.shippingServiceOptions.get(service.routeKey);
                        if (shippingServiceOption) {
                            shippingServiceOption.name = `${serviceName} - ${message}`;
                            this.shippingServiceOptions = new Map(this.shippingServiceOptions);
                        } else {
                            // The shippingServiceOption is always expected to exist. However, Bugsnag
                            // has caught a few `TypeError: Cannot set property 'name' of undefined`s, which would only
                            // occur if `this.shippingServiceOptions.get(service.routeKey);` returns `undefined`.
                            // A plausible case is that the error handler executes after the user has closed the modal.
                            if (!this.isActive) {
                                return;
                            }
                            // Notify Bugsnag explicitly if the user has not closed the modal and `shippingServiceOption` is falsy.
                            Bugsnag.leaveBreadcrumb(`${service.routeKey} is not a member of shippingServiceOptions`);
                            Bugsnag.notify(error);
                        }
                    }
                );
            }
        },
        getNameForServiceWithKey(serviceKey) {
            const service = _.find(this.shippingServices, (service) => service.routeKey === serviceKey);
            return service.name;
        },
        async onSubmit() {
            await this.onSubmitCallback(this.selectedDateExpected);
            this.onClose();
        },
        onClose() {
            this.shippingServiceOptions = new Map();
            this.selectedDateExpected = null;
            this.closeModal();
        },
    },
    computed: {
        ...mapState({
            data: (state) => state.shared.modal.data,
        }),
        shippingServiceOptionsArray() {
            return Array.from(this.shippingServiceOptions.values());
        },
        shippingServices() {
            return _.get(this.data, 'shippingServices', []).filter((service) => service.routeKey !== null);
        },
        vendor() {
            return _.get(this.data, 'vendor', {});
        },
        shipDateLeadTime() {
            let leadtime = 0;
            if (this.data.vendor) {
                leadtime = _.get(this.data, 'vendor.shipDateLeadTime', leadtime);
            } else if (this.data.warehouse) {
                let vendorId = _.get(this.data, 'warehouse.vendorId', 0);
                let storedVendor = this.getVendor()(vendorId);
                leadtime = _.get(storedVendor, 'shipDateLeadTime', leadtime);
            }
            return leadtime;
        },
        warehouse() {
            return _.get(this.data, 'warehouse', null);
        },
        shippingAddressId() {
            return _.get(this.data, 'shippingAddressId', null);
        },
        noOptionSelected() {
            return this.selectedDateExpected === null;
        },
        onSubmitCallback() {
            return _.get(this.data, 'onSubmit', () => {});
        },
    },
    watch: {
        isActive: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.getDatesExpectedForShippingServices();
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
.choose-date-expected {
    .radio {
        display: block;
        margin: 1rem;
    }
    .radio + .radio {
        margin: 1rem;
    }
}
</style>
