// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import { ref } from '@vue/composition-api';
import { Roles } from 'enums';
import useCacheManager from 'use/cacheManager';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Admin User Dropdowns',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for AdminUser "dropdowns."
 * @returns
 */
export default function useAdminUserDropdowns(config = {}) {
    const fetcher = async () => {
        const resp = await apis.Users.listUsers({
            role_id: Roles.ADMIN_ID,
            include_inactive: true,
            pagination_type: 'simple',
            per_page: -1,
            partials: ['dropdown'],
            sort: 'name',
        });
        return resp.body.data || [];
    };

    const { data: adminUsers, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    if (!adminUsers.value) {
        adminUsers.value = ref([]);
    }

    useCacheManager(mutate, metadata);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<UserV2[]>}
         */
        adminUsers,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
