export default {
    mapper: {
        relations: {
            morphTo: {
                documentProcessingRequest: {
                    foreignKey: 'processable_id',
                    localField: 'processable',
                },
            },
            belongsTo: {
                extractionInstruction: {
                    foreignKey: 'extraction_instruction_id',
                    localField: 'extractionInstruction',
                },
                textractRequest: {
                    foreignKey: 'textract_request_id',
                    localField: 'textractRequest',
                },
            },
            hasManyThrough: {
                textractResponse: {
                    foreignKey: 'textract_responses_id',
                    localField: 'textractResponses',
                },
            },
            morphToMany: {
                document: {
                    foreignKey: 'documents_id',
                    localField: 'documents',
                },
            },
            belongsToMany: {
                documentProcessor: {
                    foreignKey: 'id',
                    localField: 'documentProcessors',
                },
            },
        },
    },
};
