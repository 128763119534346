export default {
    mapper: {
        relations: {
            belongsTo: {
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
            },
            belongsToMany: {
                category: {
                    foreignKey: 'id',
                    localField: 'categories',
                },
            },
        },
    },
};
