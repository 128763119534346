import SmartApi from 'SmartApi';

export default {
    namespaced: true,
    state: {
        estimatedShippingAmount: null,
        estimatedShippingResponse: null,
    },
    actions: {
        async getDateExpected({commit}, params = {}) {
            let routeName = '';
            let routeParams = {
                shippingServiceKey: params.shippingServiceKey,
                address: params.shippingAddressId
            };

            if (params.warehouseId) {
                routeName = 'shipping-services.estimate.date-expected.warehouse';
                routeParams['warehouse'] = params.warehouseId;
            } else {
                routeName = 'shipping-services.estimate.date-expected.vendor';
                routeParams['vendor'] = params.vendorId;
            }

            let response = await SmartApi.get({
                routeName,
                routeParams,
                config: {
                    hideLoader: true
                }
            });

            return {
                shippingServiceKey: response.data.data.shippingServiceKey,
                dateExpected: response.data.data.dateExpected,
            };
        },
        async getUpsShippingEstimate({commit}, payload = {}) {
            const response = await SmartApi.post({
                routeName: 'orders.estimate-shipping',
                data: payload,
            });

            commit('setEstimatedShippingResponse', response.data.data);
            commit('setEstimatedShippingAmount', response.data.data.estimatedShipping);
        }
    },
    mutations: {
        setEstimatedShippingAmount(state, estimatedShipping) {
            state.estimatedShippingAmount = estimatedShipping;
        },
        setEstimatedShippingResponse(state, response) {
            state.estimatedShippingResponse = response;
        },
    }
};
