import SavedOrderApi from 'api/endpoints/savedOrders';
import _ from 'lodash';
import Bugsnag from '@bugsnag/js';
import {fromDateObject} from 'helpers';

export default {
    namespaced: true,
    state: {
        data: [],
        inRequest: false
    },
    mutations: {
        setData(state, payload) {
            state.data = payload;
        },
        removeItemById(state, payload) {
            let newData = state.data.filter((savedOrder) => savedOrder.id != payload);
            state.data = newData;
        },
        addItem(state, payload) {
            state.data.unshift(payload);
        },
        updateItem(state, payload) {
            let newData = state.data.map((savedOrder) => {

                if (savedOrder.id == payload.id) {
                    savedOrder = payload;
                }

                return savedOrder;
            });

            state.data = newData;
        }
    },
    actions: {
        async get({state, commit}, payload) {
            Bugsnag.leaveBreadcrumb("Fetching saved orders from backend", {
                payload,
                inRequest: state.inRequest
            });

            if (state.inRequest === false) {
                state.inRequest = true;
                let savedOrders = await SavedOrderApi.get(payload);

                if (savedOrders) {
                    commit('setData', savedOrders.data.data);
                }

                state.inRequest = false;
            }
        },
        async create({state, commit}, payload) {
            let created = false;
            if (state.inRequest === false) {
                state.inRequest = true;

                const {config} = payload || {};
                delete payload.config;

                try {
                    let response = await SavedOrderApi.create(payload, config);
                    created = response.data.data;
                    commit('addItem', created);
                } catch (e) {
                    throw Error(e);
                } finally {
                    state.inRequest = false;
                }

            }
            return created;
        },
        async delete({state, commit}, payload) {
            let deleted = false;
            if (state.inRequest === false) {
                state.inRequest = true;

                try {
                    let response = await SavedOrderApi.delete(payload);
                    deleted = response.data.data.success;
                    commit('removeItemById', payload.id);
                } catch (e) {
                    throw Error(e);
                } finally {
                    state.inRequest = false;
                }
            }

            return deleted;
        },
        async deleteAll({state, commit}) {
            let deleted = false;
            if (state.inRequest === false) {
                state.inRequest = true;
                try {
                    let savedOrderIds = _.map(state.data, 'id').join(',');
                    let payload = { id: savedOrderIds};
                    let response = await SavedOrderApi.delete(payload);
                    deleted = response.data.data.success;
                    commit('setData', []);
                } catch (e) {
                    throw Error(e);
                } finally {
                    state.inRequest = false;
                }
            }

            return deleted;
        },
        async update({state, commit}, payload) {
            let updated = false;
            if (state.inRequest == false) {
                state.inRequest = true;
                const {config} = payload || {};
                delete payload.config;

                try {
                    let response = await SavedOrderApi.update(payload, config);
                    updated = response.data.data;
                    commit('updateItem', updated);
                } catch (e) {
                    throw Error(e);
                } finally {
                    state.inRequest = false;
                }
            }

            return updated;
        }
    },
    getters: {
        selectedSavedOrder: (state) => (savedOrderId) => {
            return _.clone(state.data.find((savedOrder) => savedOrder.id == savedOrderId));
        }
    }
};
