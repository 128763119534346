// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import useCacheManager from 'use/cacheManager';
import { computed } from '@vue/composition-api';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Categories',
    uuid: getUID(),
};

/**
 * Setup data synchronization for Categories.
 */
export default function useCategories(config = {}) {
    const fetcher = async () => {
        const resp = await apis.Categories.listCategories({
            pagination_type: 'simple',
            per_page: -1,
        });
        return resp.body.data || [];
    };

    /** @type {import('swrv/dist/types').IResponse<CategoryV2[]>} */
    const { data: categories, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);
    /** @type {import('@vue/composition-api').ComputedRef<CategoryV2[] | undefined>} */
    const categoriesForVendor = computed(() => categories.value?.filter((c) => c.for_vendors == true));

    useCacheManager(mutate, metadata);

    return {
        categories,
        categoriesForVendor,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
