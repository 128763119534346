import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}, config = {}) {
        let url = route('tools.system-settings');
        return await axios.get(url, { params, ...config });
    },
    async update(payload) {
        let url = route('tools.system-settings.edit');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
};
