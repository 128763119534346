import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                extractionInstruction: {
                    foreignKey: 'extraction_instruction_id',
                    localField: 'extractionInstruction',
                },
            },
            belongsToMany: {
                documentProcessor: {
                    foreignKey: 'id',
                    localField: 'documentProcessors',
                },
                email: {
                    foreignKey: 'id',
                    localField: 'emails',
                },
            },
            morphMany: {
                ...auditsRelation('emailProcessingRule'),
            },
        },
    },
};
