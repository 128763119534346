import Accounting from 'accounting-js';
import Moment from 'moment';
import _ from 'lodash';
import { getFriendlyName } from 'enumHelpers';
import { Times } from 'enums';
import Bugsnag from '@bugsnag/js';

const SECOND_IN_MILLISECONDS = 1000;
const MINUTE_IN_SECONDS = 60;

/**
 * Helper function to convert minutes to milliseconds
 * @param {Number} minutes
 * @returns {Number}
 */
export function minutesToMilliseconds(minutes) {
    return minutes * MINUTE_IN_SECONDS * SECOND_IN_MILLISECONDS;
}

/**
 * Helper function to convert seconds to milliseconds
 * @param {Number} seconds
 * @returns {Number}
 */
export function secondsToMilliseconds(seconds) {
    return seconds * SECOND_IN_MILLISECONDS;
}

export function isNumeric(value) {
    return isNaN(value) === false && isFinite(value);
}

export function toSnakeCase(str) {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function toCamelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export function ucfirst(string) {
    let first = string.charAt(0);
    let end = string.slice(1);
    first = first.toUpperCase();
    return first.concat(end);
}

export function titleCase(string) {
    let segments = string.split(' ');
    let modifiedSegments = [];

    segments.forEach((segment) => {
        modifiedSegments.push(ucfirst(segment));
    });

    return modifiedSegments.join(' ');
}

export function calculateDiscount(cost, price) {
    if (price <= 0 || isNaN(price)) {
        return 0;
    }

    let discount = 1 - cost / price;
    discount = discount * 100;

    return numberFormat(discount, 3);
}

export function calculateCustomerDiscount(price, listPrice) {
    return calculateDiscount(price, listPrice);
}

export function calculateMarkup(price, cost, format = true) {
    if (cost <= 0 || isNaN(cost)) {
        return 0;
    }

    let markup = (price / cost - 1) * 100;
    markup = Accounting.toFixed(markup, 3);

    if (format) {
        markup = numberFormat(markup, 3);
    }

    return markup;
}

export function calculatePrice(cost, markup, format = true) {
    let price = cost * (1 + markup / 100);

    price = Accounting.toFixed(price, 2);

    if (format) {
        price = numberFormat(price);
    }

    return price;
}

export function calculateCost(discount, listPrice) {
    let cost = listPrice * (1 - discount);
    return numberFormat(cost);
}

export function calculatePriceAfterRebate(price, rebatePercent) {
    let calculatedPrice = price * (1 - rebatePercent / 100);
    return numberFormat(calculatedPrice);
}

export function calculatePriceMinusRebate(price, rebatePercent) {
    let calculatedPrice = price / (1 - rebatePercent / 100);
    return numberFormat(calculatedPrice);
}

export function numberFormat(value, decimals = 2) {
    return Accounting.toFixed(Number(value), decimals);
}

export function formatCurrency(value, decimals = 2) {
    return Accounting.formatMoney(Accounting.unformat(value), {
        precision: decimals,
        symbol: '$',
    });
}

export function formatPercent(value) {
    return `${numberFormat(value, 3)}%`;
}

export function unformatNumber(value) {
    return Accounting.unformat(value);
}

export function setObjectValue(obj, path, value) {
    let explodedPath = path.split('.');
    let keyToFind = explodedPath.shift();

    Object.keys(obj).forEach((key) => {
        if (key === keyToFind) {
            if (explodedPath.length === 0) {
                obj[key] = value;
            } else if (obj[key] instanceof Object) {
                let nextPath = explodedPath.join('.');
                setObjectValue(obj[key], nextPath, value);
            }
        }
    });
}

export function getObjectValue(obj, path, defaultValue = null) {
    let explodedPath = path.split('.');
    let keyToFind = explodedPath.shift();
    let value = defaultValue;

    Object.keys(obj).forEach((key) => {
        if (key === keyToFind) {
            if (explodedPath.length === 0) {
                value = obj[key];
            } else if (obj[key] instanceof Object) {
                let nextPath = explodedPath.join('.');
                value = getObjectValue(obj[key], nextPath, defaultValue);
            }
        }
    });

    return value;
}

export function formatTimestamp(timestamp, format = 'M/D/YY') {
    if (timestamp) {
        return Moment.unix(timestamp).format(format);
    }

    return 'N/A';
}

export function formatLCodes(value) {
    let lcodeString = '';
    for (let i = 0, len = value.length; i < len; i++) {
        lcodeString += value[i].code;
        lcodeString += i < len - 1 ? ', ' : '';
    }

    return lcodeString;
}

export function formatKLevel(value) {
    return value.map((elem, index, arr) => (arr[index] = elem.code)).join(',');
}

export function formatTime(value) {
    let timeEnumKey = Object.keys(Times).filter((key) => Times[key] === parseInt(value))[0];
    if (typeof timeEnumKey !== 'string') {
        return '';
    }
    return getFriendlyName(timeEnumKey);
}

export function formatDateTime(dateTime, format = 'M/D/YY') {
    if (dateTime) {
        return Moment(dateTime, 'YYYY-MM-DD').format(format);
    }

    return 'N/A';
}

export function removeOctals(expression) {
    if (expression) {
        let matches = expression.match(/([\d\.]+)/g); //https://regex101.com/r/l6NL4v/1
        if (matches) {
            matches
                .filter((match) => {
                    if (match[0] === '.') {
                        return false;
                    }

                    return true;
                })
                .forEach((match) => {
                    expression = expression.replace(match, parseFloat(match));
                });
        }
    }

    return expression;
}

export function evalMathExpression(expression) {
    let sanitizedExpression = String(expression).replace(/[^\d\\\-\+\*\/\.\(\)\%]+/i, '');
    sanitizedExpression = removeOctals(sanitizedExpression);
    let result = expression;

    if (sanitizedExpression) {
        try {
            result = eval(sanitizedExpression);
        } catch (e) {
            // Silence errors thrown by this so Bugnsag stops complaining when someone
            // puts in bad input.
        }
    }

    if (isNumeric(result) === false) {
        result = 0;
    }

    return parseFloat(result);
}

export function todaysDate(formatString = 'M/D/YY') {
    let today = Moment().format(formatString);
    return today;
}

export function downloadPreparedFile(file) {
    let a = window.document.createElement('a');
    a.href = file.url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function downloadFile(data, type, filename) {
    let blob = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob)
        // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(blob, filename);
    else {
        let a = window.document.createElement('a');
        let url = window.URL.createObjectURL(blob, { type: type });
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);

        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 10000);
    }
}

export function openFile(data, type, filename) {
    let blob = new Blob([data], { type });
    let url = window.URL.createObjectURL(blob);
    let a = window.document.createElement('a');

    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 10000);
}

export function getXSRFHeader() {
    const xsrfCookieWrapper = document.cookie.split(';').filter((item) => item.trim().startsWith('XSRF-TOKEN='));
    if (xsrfCookieWrapper.length != 1) {
        return {};
    }
    const xsrfCookie = decodeURIComponent(xsrfCookieWrapper[0].split('=')[1]);
    return {
        'X-XSRF-TOKEN': xsrfCookie,
    };
}

/**
 * Return the value at the camelCase or snake_case path of object.
 *
 * The defaultValue is returned if both the camelCase and snake_case paths resolve to an undefined value.
 * @param {*} object
 * @param {String} path
 * @param {*} defaultValue
 * @param {Boolean} ignoreCamelCasedPath
 */
export function getCamelOrSnakeCase(object, path, defaultValue = null, ignoreCamelCasedPath = false) {
    if (!ignoreCamelCasedPath && /[A-Z]/g.test(path.split('.').at(-1))) {
        console.warn('Deprecation warning: getCamelOrSnakeCase() invoked with a camelCased path', path);
    }

    let target = object;
    if (path.includes('.')) {
        target = getCamelOrSnakeCase(object, path.split('.').slice(0, -1).join('.'), null, true);
        path = path.split('.').at(-1);
    }

    if (!target) {
        return defaultValue;
    }

    const camelCasePath = _.camelCase(path);
    const snakeCasePath = _.snakeCase(path);

    if (
        camelCasePath !== snakeCasePath &&
        target.hasOwnProperty(camelCasePath) &&
        target.hasOwnProperty(snakeCasePath)
    ) {
        Bugsnag.notify(
            new Error(
                'getCamelOrSnakeCase() invoked with a target object that has both camelCased and snake_cased variants.'
            )
        );
    }

    const warnIfCamelCaseHasValue = () => {
        const value = _.get(target, camelCasePath);
        //false is a real response value but null/undefined etc are not.
        if (value || value === false) {
            console.warn(
                'Deprecation warning: getCamelOrSnakeCase() returned a non-null value via a camelCased path.',
                path,
                value
            );
        }
        return value;
    };
    return _.get(target, snakeCasePath) ?? warnIfCamelCaseHasValue() ?? defaultValue;
}

export function getCookie(cookieName) {
    const name = cookieName + '=';
    let wrapper = document.cookie.split(';').filter((item) => item.trim().startsWith(name));
    if (wrapper.length) {
        return wrapper[0].split('=')[1];
    }
    return '';
}

export function setCookie(cookieName, value, daysTillExpiration = 1) {
    const date = new Date();
    date.setTime(date.getTime() + daysTillExpiration * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + date.toUTCString();
    document.cookie = cookieName + '=' + value + ';' + expires + ';path=/';
}

export function escapeHTML(text) {
    // _.escape doesn't convert exactly like PHP's htmlspecialchars, so I snagged
    // this from Stack Overflow: https://bit.ly/36OoFEa -JJB
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;',
    };

    return text.replace(/[&<>"']/g, (char) => {
        return map[char];
    });
}

export function getUID() {
    //jest doesn't have crypto
    if (typeof crypto == 'undefined') {
        return _.uniqueId();
    }
    return crypto.getRandomValues(new BigUint64Array(1))[0];
}
