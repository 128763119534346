import _ from 'lodash';
import ProductApi from 'api/endpoints/products';
import { TABLE_STORE } from 'store/shared/mixins';
import { PRODUCT_TYPES } from 'constants';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        filters: {
            lcode_in: [],
        },
        searchSubmitted: false,
    },
    actions: {
        getData({ dispatch, getters, rootGetters }, params = null) {
            let defaultParams = {
                includes: [
                    'vendor',
                    'manufacturer',
                    'lcodes',
                    'priceExceptions',
                    'inventoryRecords',
                    'favoritedBy',
                    'isCurrentUsersFavorite',
                    'category',
                ],
            };

            params = params || defaultParams;

            if (getters.hasFilters) {
                if (rootGetters['shared/currentUser/isFacility']) {
                    params['type_id_in'] = [PRODUCT_TYPES.TYPE_MANUAL, PRODUCT_TYPES.TYPE_PRICE_LIST];
                }

                dispatch('get', params);
                dispatch('shared/quickbar/deselectAllItems', null, { root: true });
            }
        },
        async get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);

            let response = await ProductApi.get(params);
            commit('setData', response.data.data);
            commit('setPaginationData', response.data.meta.pagination);
            commit('setSearchSubmitted', true);
        },
        async delete({ getters, commit }, { product }) {
            let response;
            try {
                response = await getters.api.delete(product.id);
            } catch (error) {
                error.product = product;
                return error;
            }
            commit('delete', product);
            return response;
        },
    },
    getters: {
        api() {
            return ProductApi;
        },
        hasFilters(state) {
            const filters = _.omit(state.filters, ['page', 'lcode_in', 'status_id']);
            const hasFilters = Object.keys(filters).length > 0;
            const hasLCode = state.filters.hasOwnProperty('lcode_in') && state.filters['lcode_in'].length;

            return hasFilters || hasLCode;
        },
    },
    mutations: {
        clearStoredFilters(state) {
            state.filters = {
                lcode_in: [],
            };
        },
        setSearchSubmitted(state, value) {
            state.searchSubmitted = value;
        },
    },
});
