import { getStatusNameById } from 'enumHelpers';

export default function useGroupByIsPractitionerAndStatus(
    statusField = 'status_id',
    isPractitionerField = 'is_practitioner'
) {
    return (option) => {
        let statusName = getStatusNameById(option[statusField]);
        let prefix = !!option[isPractitionerField] ? '' : 'Non-';
        return `${statusName} ${prefix}Practitioners`;
    };
}

export function groupByIsPractitionerAndStatusOrder() {
    return ['Active Practitioners', 'Inactive Practitioners', 'Active Non-Practitioners', 'Inactive Non-Practitioners'];
}
