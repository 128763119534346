import _ from 'lodash';
import BillApi from 'api/endpoints/admin/bills';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    actions: {
        get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);
            BillApi.get(params).then((response) => {
                commit('setData', response.data.data);
                commit('setPaginationData', response.data.meta.pagination);
            });
        },
        async createInvoices({ state, dispatch }, payload) {
            return await BillApi.createInvoices({ id: payload });
        },
    },
    getters: {
        api() {
            return BillApi;
        },
    },
});
