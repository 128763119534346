<template>
    <div :class="['field', fieldClass]">
        <label :class="['label', labelClass]" v-if="label">
            {{ label }}
        </label>
        <div class="control">
            <textarea
                :class="['input', 'resize-vertical', inputClass, { 'is-danger': hasError }]"
                :name="name"
                :disabled="disabled"
                @input="inputHandler($event)"
                :placeholder="placeholder"
                :resize="resize"
                :tabindex="tabindex"
                :maxlength="maxlength"
                v-model="proxyValue"
                @focus="$root.$emit('textarea-focus')"
                @blur="$root.$emit('textarea-blur')"
                ref="textarea"
                data-lpignore="true"
                :readonly="readonly"
                v-bind="$attrs"
                :data-testid="name + 'TextArea'"
            ></textarea>
            <div v-if="showCharacterCount" :class="['character-count', { 'text-red': overCharacterLimit }]">
                {{ characterCountText }}
            </div>
            <div v-if="showEntityCountWarning" class="entity-count-warning">
                Special characters &, ', ", &lt;, and &gt; count as more than one character.
            </div>
            <template v-if="hasErrorArray">
                <p class="help is-danger" v-for="(error, index) in errors[name]" :key="index">
                    {{ error }}
                </p>
            </template>
            <template v-else-if="hasError">
                <p class="help is-danger">{{ errors[name] }}</p>
            </template>
        </div>
    </div>
</template>

<script>
import { input } from './mixins';
import { escapeHTML } from 'helpers';

export default {
    inheritAttrs: false,
    computed: {
        proxyValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        characterCountText() {
            let currentCount = String(this.characterCount);
            if (this.maxCharacterCount) {
                currentCount += `/${this.maxCharacterCount}`;
            }
            return currentCount;
        },
        characterCount() {
            if (this.characterCountToUse) {
                return this[this.characterCountToUse];
            }

            return this.value.length;
        },
        countWithHtmlEntities() {
            return escapeHTML(this.value).length;
        },
        showEntityCountWarning() {
            return (
                this.characterCountToUse == 'countWithHtmlEntities' &&
                this.valueContainsSpecialCharacters &&
                this.overCharacterLimit
            );
        },
        valueContainsSpecialCharacters() {
            return this.value.match(/[&<>"']/g);
        },
        overCharacterLimit() {
            return this.maxCharacterCount > 0 && this.characterCount > this.maxCharacterCount;
        },
    },
    props: {
        resize: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: [String, Number],
        },
        maxlength: {
            type: Number,
        },
        contentBasedHeight: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: [String, Boolean],
            default: false,
        },
        showCharacterCount: {
            type: Boolean,
            default: false,
        },
        maxCharacterCount: {
            type: Number,
            default: 0,
        },
        characterCountToUse: {
            type: String,
            default: null,
        },
    },
    mixins: [input],
    methods: {
        inputHandler(event) {
            if (this.contentBasedHeight) {
                this.adjustHeightBasedOnContent();
            }
            this.$emit('input', event.target.value);
        },
        adjustHeightBasedOnContent() {
            let textarea = this.$refs.textarea;
            let maxHeight = this.maxHeight;
            textarea.style.height = 0;

            textarea.style.overflowY = maxHeight ? 'scroll' : 'hidden';

            if (maxHeight) {
                textarea.style.maxHeight = maxHeight;
            }

            let adjustedHeight = this.getScrollbarWidth() + textarea.scrollHeight;

            textarea.style.height = `${adjustedHeight}px`;
        },
        getScrollbarWidth() {
            //Stolen from https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
            var outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.width = '100px';
            outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

            document.body.appendChild(outer);

            var widthNoScroll = outer.offsetWidth;
            // force scrollbars
            outer.style.overflowY = 'scroll';

            // add innerdiv
            var inner = document.createElement('div');
            inner.style.width = '100%';
            outer.appendChild(inner);

            var widthWithScroll = inner.offsetWidth;

            // remove divs
            outer.parentNode.removeChild(outer);

            return widthNoScroll - widthWithScroll;
        },
    },
    mounted() {
        if (this.contentBasedHeight) {
            this.adjustHeightBasedOnContent();
        }
    },
};
</script>

<style lang="scss" scoped>
textarea.height-4em {
    height: 4em;
}

textarea.height-6em {
    height: 6em;
}

textarea.height-7em {
    height: 7em;
}

textarea.input {
    min-height: 2em;
}

textarea.resize-vertical {
    resize: vertical;
}

textarea.non-resizeable {
    resize: none;
}

textarea.no-wrap {
    white-space: nowrap;
    overflow-x: hidden;
}

.character-count {
    text-align: right;
    max-width: 5rem;
    margin: 0 0 0 auto;
    float: right;
}

.entity-count-warning {
    background-color: #f6bd60;
    border-radius: 0.25rem;
    border: 0;
    padding: 0.5rem;
}
</style>
