import _ from 'lodash';
import { TABLE_STORE } from 'store/shared/mixins';
import { apis } from 'api/edi';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    actions: {
        async get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, params);

            const storedDocumentsApi = apis['StoredDocuments'];
            const response = await storedDocumentsApi.listStoredDocuments(params);

            commit('setData', response.body.data);
            commit('setPaginationData', response.body.meta);
        },
    },
    mutations: {
        setData(state, payload) {
            state.data = payload;
        },
    },
});
