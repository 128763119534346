import _ from "lodash";
import {Statuses} from "enums";

export default {
    addMessages(state, messages) {
        let inboxAdds = {};
        _.each(messages, (message) => {
            if(message.inbox) {
                inboxAdds[message.id] = message.inbox;
                inboxAdds[message.id].id = message.id;
                delete message.inbox;
            }
        });
        state.messages = {
            ...state.messages,
            ..._.keyBy(messages, (message) => message.id)
        };
        state.inbox = {
            ...state.inbox,
            ...inboxAdds
        };
    },
    clearMessages(state) {
        state.messages = {};
        state.inbox = {};
    },
    setMeta(state, meta) {
        state.messageMeta = Object.assign({}, meta);
    },
    markThreadAs(state, {threadId, statusId}) {
        _.each(state.messages, (msg,id) => {
            if (msg.data.thread === threadId && state.inbox[msg.id]) {
                state.inbox[msg.id].status_id = statusId;
            }
        });
    },
    markMessageAs(state, {messageId, statusId}) {
        state.inbox[messageId].status_id = statusId;
    },
    markAllMessageAs(state, statusId) {
        _.each(state.inbox, (msg,id) => {
            if (msg.status_id !== Statuses.DELETED) {
                state.inbox[id].status_id = statusId;
            }
        });
    },
}
