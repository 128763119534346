export default {
    mapper: {
        relations: {
            belongsToMany: {
                line: {
                    foreignKey: 'id',
                    localField: 'lines',
                },
                returnRequest: {
                    foreignKey: 'id',
                    localField: 'returnRequests',
                },
            },
            hasOne: {
                shippingLabel: {
                    foreignKey: 'tracking_number_id',
                    localField: 'shippingLabel',
                },
            },
        },
    },
};
