<template>
    <div class="title-bar">
        <div class="level">
            <template v-show="title">
                <div class="level-left">
                    <h1 class="level-item title is-2">{{ title }}</h1>
                </div>
            </template>
            <div class="level-right">
                <slot></slot>
            </div>
        </div>
        <hr />
    </div>
</template>

<script>
export default {
    props: ['title'],
};
</script>

<style lang="scss" scoped>
.title-bar {
    padding-top: 1.5rem;
    > .level {
        padding: 0 1vw;
    }
}
</style>
