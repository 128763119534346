export function makeGuard(router) {
    return async (to, from, next) => {
        if (!router.app.$store.state.shared.auth.hasLoadedOnce) {
            await router.app.$store.dispatch('shared/auth/CHECK_AUTH');
        }

        if (to.matched.length && to.matched.every((record) => record.meta.guestOnly)) {
            if (router.app.$store.getters['shared/auth/isAuthenticated']) {
                next({ name: 'dashboard' });
            } else {
                next();
            }
        } else {
            if (!router.app.$store.getters['shared/auth/isAuthenticated']) {
                next({
                    name: 'login',
                    query: { redirect: to.fullPath },
                });
            } else {
                next();
            }
        }
    };
}

function apply(router) {
    router.beforeEach(makeGuard(router));
}

export default {
    apply,
};
