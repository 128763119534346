// @ts-check
import Vue from 'vue';
import * as mutationTypes from './mutation_types.js';
import state from './state.js';
import View from './view.js';

/**
 * @type {import('vuex').MutationTree<typeof state>}
 */
const mutations = {
    /**
     * Record that another user is viewing a Line.
     * @param {state} state
     * @param {View} view
     */
    [mutationTypes.RECORD_OPEN](state, { id, viewer }) {
        // It is possible, but unlikely, that RECORD_OPEN will
        // be executed before ENTER. Provide a fallback
        // in case ENTER has not yet been executed for the given
        // line.
        const viewers = state.views[id] || [];
        viewers.push(viewer);
        state.views[id] = viewers;
    },
    /**
     * Record that another user is no longer viewing a Line.
     * @param {state} state
     * @param {View} view
     */
    [mutationTypes.RECORD_SHUT](state, { id, viewer }) {
        const viewers = state.views[id];
        if (!viewers) {
            return;
        }
        const index = viewers.findIndex(({ id }) => id === viewer.id);
        if (index !== -1) {
            viewers.splice(index, 1);
        }
    },
    /**
     * Add a Line id to the map of viewed lines.
     *
     * This represents starting observation of the state of a particular Line. Alternative names
     * could be `watch` or `observe`.
     *
     * This should be committed before this client has joined the presence channel for the Line.
     * @param {state} state
     * @param {Object} payload
     * @param {number} payload.id The id of a Line this client is viewing.
     * @returns
     */
    [mutationTypes.ENTER](state, { id }) {
        if (state.views[id]) {
            return;
        }
        Vue.set(state.views, id, []);
    },
    /**
     * Remove a Line id from the map of viewed lines.
     *
     * This represents removing observation of the state of a particular Line. Alternate names could
     * be `stopObserving`.
     *
     * This should not be committed until after this client has left the presence channel for the Line
     * and the representation of the Line is no longer visible to the user.
     * @param {state} state
     * @param {Object} payload
     * @param {number} payload.id The id of a Line this client is viewing.
     */
    [mutationTypes.LEAVE](state, { id }) {
        Vue.delete(state.views, id);
    },
    /**
     * Remove all of a Line's Viewers.
     * @param {state} state
     * @param {Object} payload
     * @param {number} payload.id The id of a Line this client is viewing.
     */
    [mutationTypes.CLEAR](state, { id }) {
        Vue.set(state.views, id, []);
    },
    /**
     * Enable line viewing monitoring.
     * @param {state} state
     */
    [mutationTypes.ENABLE](state) {
        state.isEnabled = true;
    },
    /**
     * Disable line viewing monitoring.
     * @param {state} state
     */
    [mutationTypes.DISABLE](state) {
        state.isEnabled = false;
    },
};

export default mutations;
