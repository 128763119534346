import Echo from 'laravel-echo';
import { downloadPreparedFile, getXSRFHeader } from 'helpers';

export default {
    namespaced: true,
    state: {
        instance: null,
        disconnecting: false,
        moduleName: 'Echo',
    },
    getters: {
        instance: (state) => state.instance,
        connected: (state) => state.instance !== null,
        channel: (state) => (channelName) => {
            let channels = state.instance.connector.channels;

            if (state.instance) {
                const publicChannel = channels[channelName];
                const presenceChannel = channels[`presence-${channelName}`];
                const privateChannel = channels[`private-${channelName}`];
                return publicChannel || presenceChannel || privateChannel;
            }
        },
    },
    mutations: {
        SET_INSTANCE(state, instance) {
            state.instance = instance;
        },
        SET_DISCONNECTING(state, bool = false) {
            state.disconnecting = bool;
        },
    },
    actions: {
        ESTABLISH_CONNECTION({ getters, commit, dispatch }) {
            try {
                if (getters.instance instanceof Echo) {
                    commit('SET_DISCONNECTING', true);
                    getters.instance.disconnect();
                    commit('SET_DISCONNECTING', false);
                }
                const headers = getXSRFHeader();
                const instance = new Echo({
                    broadcaster: LARAVEL_ECHO_BROADCASTER,
                    host: LARAVEL_ECHO_HOST,
                    path: LARAVEL_ECHO_PATH,
                    auth: {
                        headers,
                    },
                });
                commit('SET_INSTANCE', instance);
            } catch (e) {
                console.error(e);
                commit('SET_DISCONNECTING', false);
                dispatch('shared/currentUser/pollForUserChanges', null, { root: true });
                dispatch(
                    'shared/toast/show',
                    {
                        isInfo: true,
                        content: 'Could not establish a websocket connection, not all features will be available.',
                    },
                    { root: true }
                );
            }
        },
        LISTEN({ dispatch }) {
            dispatch('listenForCodebaseChangeBroadcasts');
            dispatch('listenForNotificationBroadcasts');
            dispatch('listenForMenuChanges');
            dispatch('listenForInventoryLocationChangeBroadcasts');
            dispatch('listenForOrderUpdateBroadcasts');
            dispatch('listenForConnectionChanges');
            dispatch('listenForMessageCenterEvents');
        },
        listenForCodebaseChangeBroadcasts({ getters, dispatch }) {
            if (getters.connected) {
                let ctrlButton = 'Ctrl';

                if (window.navigator.platform == 'MacIntel') {
                    ctrlButton = 'Cmd';
                }

                let payload = {
                    isInfo: true,
                    persistant: true,
                    content: `We just updated the application. Please press ${ctrlButton} + Shift + R to load the most recent version.`,
                };

                getters.instance.channel('codebase-updates').listen('.code-updated', (event) => {
                    dispatch('shared/toast/show', payload, { root: true });
                });
            }
        },
        listenForDownloadBroadcasts({ getters, dispatch, rootGetters }) {
            if (getters.connected) {
                const User = rootGetters['shared/currentUser/user'];
                getters.instance
                    .private(`file-download.${User.id}`)
                    .listen('.download.failed', (event) => {
                        dispatch(
                            'shared/toast/show',
                            {
                                content: `Failed to prepare ${event.filename} for download.`,
                                isDanger: true,
                            },
                            { root: true }
                        );
                    })
                    .listen('.download.ready', (event) => {
                        dispatch(
                            'shared/toast/show',
                            {
                                content: `${event.filename} is ready for download, and will begin shortly.`,
                                isInfo: true,
                            },
                            { root: true }
                        );
                        downloadPreparedFile(event);
                    });
            }
        },
        listenForNotificationBroadcasts({ getters, rootGetters, dispatch }) {
            if (getters.connected) {
                const User = rootGetters['shared/currentUser/user'];
                getters.instance
                    .private('App.Models.User.' + User.id)
                    .notification((notification) => dispatch('shared/messageCenter/handleUserNotifications', notification, { root: true }))
                    .listen('.UserChanged', (response) =>
                        dispatch('shared/currentUser/updateUser', null, { root: true })
                    )
                    .listen('.UserPermissionsChanged', (response) =>
                        dispatch('shared/currentUser/updateUserPermissions', null, { root: true })
                    )
                    .listen('.UserNotificationPreferencesChanged', (response) =>
                        dispatch('shared/currentUser/updateUserNotificationPreferences', null, { root: true })
                    )
                    .listen('.UserPreferencesChanged', (response) =>
                        dispatch('shared/currentUser/updateUserPreferences', null, { root: true })
                    );
            }
        },
        listenForMessageCenterEvents({ commit, getters, rootGetters, dispatch }) {
            if (getters.connected) {
                const User = rootGetters['shared/currentUser/user'];
                const channel = getters.instance.private('message-center.' + User.id);
                const events = ['message.change', 'message.new', 'notification.change', "download.change"];
                events.forEach(eventName => {
                        channel.listen('.' + eventName, event => {
                            dispatch('shared/messageCenter/handleUserEvents', {'type': eventName, 'data': event}, { root: true });
                        });
                });
            }
        },
        listenForMenuChanges({ getters, rootGetters, commit }) {
            if (getters.connected) {
                const User = rootGetters['shared/currentUser/user'];
                getters.instance.private('menu.' + User.id).listen('.changed', (event) => {
                    commit('shared/setMenuItems', { list: event.menu }, { root: true });
                });
            }
        },
        listenForInventoryLocationChangeBroadcasts({ getters, dispatch }) {
            if (getters.connected) {
                getters.instance
                    .channel('inventory-locations')
                    .listen('.saved', (event) => {
                        dispatch('shared/facilities/updateInventoryLocation', event.data, { root: true });
                    })
                    .listen('.deleted', (event) => {
                        dispatch('shared/facilities/removeInventoryLocation', event.data, { root: true });
                    });
            }
        },
        listenForOrderUpdateBroadcasts({ getters, dispatch }) {
            if (getters.connected) {
                getters.instance.private('orders').listen('.newNote', (event) => {
                    dispatch('ordersManage/addNoteToOrder', event.note, { root: true });
                });
            }
        },
        listenForConnectionChanges({ getters, state, dispatch }) {
            if (getters.connected) {
                // The socket property only exists on the Socket IO Connector which
                // we cannot use during jest unit tests as we have to use the NullConnector
                // which does not have the same property
                getters.instance.connector.socket?.on('subscription_error', (channelName) => {
                    if (channelName.includes('App.Models.User.')) {
                        dispatch('shared/currentUser/pollForUserChanges', null, { root: true });
                    }
                });
                getters.instance.connector.socket?.on('disconnect', () => {
                    if (!state.disconnecting) {
                        dispatch('shared/currentUser/pollForUserChanges', null, { root: true });
                    }
                });
                getters.instance.connector.socket?.on('reconnect', () => {
                    dispatch('shared/currentUser/stopPollingForUserChanges', null, { root: true });
                });
            }
        },
        listenForPriceListUpdates({ getters, dispatch }) {
            if (getters.connected) {
                getters.instance.channel('imports').listen('.price-list-updated', (event) => {
                    dispatch('revamp/priceLists/priceListUpdated', event, { root: true });
                });
            }
        },
        listenForBillImportUpdates({ getters, dispatch }) {
            if (getters.connected) {
                getters.instance.channel('imports').listen('.bill-updated', (event) => {
                    dispatch('revamp/billImports/billUpdated', event, { root: true });
                });
            }
        },
        listenForShippingImportUpdates({ getters, dispatch }) {
            if (getters.connected) {
                getters.instance.channel('imports').listen('.shipping-updated', (event) => {
                    dispatch('revamp/shippingImports/shippingUpdated', event, { root: true });
                });
            }
        },
        listenForFeeScheduleImportUpdates({ getters, dispatch }) {
            if (getters.connected) {
                getters.instance.channel('imports').listen('.fee-schedule-updated', (event) => {
                    dispatch('revamp/feeScheduleImports/feeScheduleUpdated', event, { root: true });
                });
            }
        },
        leaveChannel({ getters }, channel) {
            if (getters.connected) {
                getters.instance.leaveChannel(channel);
            }
        },
        disconnect({ getters }) {
            if (getters.connected) {
                getters.instance.disconnect();
            }
        },
    },
};
