export default {
    mapper: {
        relations: {
            belongsTo: {
                oAuthPartner: {
                    foreignKey: 'oauth_partner_id',
                    localField: 'oauthPartner',
                },
            },
        },
    },
};
