<template>
    <emp-select
        :disabled="warehouses.length == false || disabled"
        :inputClass="[inputClass, { 'is-loading': warehouses.length == false }]"
        :options="options"
        valueFrom="id"
        :value="value"
        :label="label"
        @input="$emit('input', $event)"
        v-bind="$attrs"
        emp-data="selectWarehouse"
        name="warehouseId"
        :errors="errors"
        :nameCallback="getName"
        data-testid="select-warehouse"
    />
</template>

<script>
import { Statuses } from 'enums';
import { formatTime } from 'helpers';
import { getStateCodeById } from 'app/enumHelpers';
import { defineComponent, ref, computed, toRefs } from '@vue/composition-api';
import { hasErrorProps, hasErrors } from '@/has';
import useVendorWarehouses from 'use/vendorWarehouses';

export default defineComponent({
    setup(props) {
        hasErrors(props);
        const { vendorId } = toRefs(props);
        const { warehouses } = useVendorWarehouses({ vendor_id: vendorId });

        function getName(warehouse) {
            const cutoff = formatTime(warehouse.cutoffTimeId);
            const state = getState(warehouse);
            return [warehouse.name, state, warehouse.zipcode, cutoff].join(' - ');
        }

        function getState(warehouse) {
            return getStateCodeById(warehouse.stateId);
        }

        const activeWarehouses = computed(() => {
            return warehouses.value
                .filter(({ statusId }) => statusId == Statuses.ACTIVE)
                .map((warehouse) => {
                    warehouse.isDisabled = false;
                    return warehouse;
                });
        });

        const inactiveWarehouses = computed(() => {
            return warehouses.value
                .filter(({ statusId }) => statusId != Statuses.ACTIVE)
                .map((warehouse) => {
                    warehouse.isDisabled = true;
                    return warehouse;
                });
        });

        const options = computed(() => {
            const hasActive = Boolean(activeWarehouses.length);
            const hasInactive = Boolean(inactiveWarehouses.length);
            const hasBoth = hasActive && hasInactive;
            if (hasBoth) {
                return [
                    {
                        name: '-- ACTIVE WAREHOUSES --',
                        value: 99999999999999,
                        id: _.uniqueId(`select_warehouse_${this._uid}`),
                        isDisabled: true,
                    },
                    ...activeWarehouses.value,
                    {
                        name: '-- INACTIVE WAREHOUSES --',
                        value: 99999999999999,
                        id: _.uniqueId(`select_warehouse_${this._uid}`),
                        isDisabled: true,
                    },
                    ...inactiveWarehouses.value,
                ];
            } else if (hasInactive) {
                return [
                    {
                        name: '-- INACTIVE WAREHOUSES --',
                        value: 99999999999999,
                        id: _.uniqueId(`select_warehouse_${this._uid}`),
                        isDisabled: true,
                    },
                    ...inactiveWarehouses.value,
                ];
            }
            return activeWarehouses.value;
        });

        return {
            warehouses,
            options,
            getName,
            getState,
            activeWarehouses,
            inactiveWarehouses,
        };
    },
    props: {
        ...hasErrorProps(),
        disabled: {
            type: [String, Boolean],
            default: false,
        },
        value: {
            type: [String, Number, Array, Object, Boolean],
            default: null,
        },
        inputClass: {
            type: [String, Array, Object],
            default: '',
        },
        label: {
            type: String,
            default: 'Warehouse',
        },
        vendorId: {
            type: Number,
            default: null,
        },
    },
});
</script>
