// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import useCacheManager from 'use/cacheManager';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Facilities Dropdowns',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Facilities dropdowns.
 * @returns
 */
export default function useFacilityDropdowns(config = {}) {
    const fetcher = async () => {
        const response = await apis.Facilities.listFacilities({
            pagination_type: 'simple',
            per_page: -1,
            partials: ['dropdown'],
            sort: 'name',
        });
        return response.body.data || [];
    };

    const { data: facilities, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    useCacheManager(mutate, metadata);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<Facility[]>}
         */
        facilities,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
