export const GET_DROPDOWN_DATA = 'getDropdownData';
export const HANDLE_SORT = 'handleSort';
export const INITIALIZE_FILTERS = 'initializeFilters';
export const REMOVE_LINES_BY_ID = 'removeLinesById';
export const RESET_TABLE_STATE = 'resetTableState';
export const SORT_OUT_QUERY_PARAM_VALUES = 'sortOutQueryParamValues';
export const FETCH = 'fetch';

export const TABLE_ACTIONS = {
    REMOVE_LINES_BY_ID,
    RESET_TABLE_STATE,
    SORT_OUT_QUERY_PARAM_VALUES,
    FETCH,
};

export const DATA_LOADER_ACTIONS = {
    INITIALIZE_FILTERS,
};

export default {};
