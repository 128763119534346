import _ from 'lodash';
import SmartApi from 'SmartApi';

export default {
    namespaced: true,
    state: {
        data: [],
    },
    mutations: {
        setData(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async get({ commit, rootGetters }, params) {
            const userIsAdmin = rootGetters['shared/currentUser/isAdmin'];
            const routeName = userIsAdmin ? 'listAdminPrewrittenNotes' : 'listFacilityPrewrittenNotes';
            const routeParams = userIsAdmin ? {} : { 'facility_id': params.facility_id };
            const config = { 'params': {'per_page': -1}};

            let response = await SmartApi.get({ routeName, routeParams, config });
            let sortedNotes = _.sortBy(response.data.data, ['order']);
            commit('setData', sortedNotes);
        },
    },
    getters: {
        notesByTypeId: (state) => (noteTypeId) => {
            return _.filter(state.data, note => note.type_id === noteTypeId);
        },
    },
};
