<template>
    <autocomplete
        :label="label"
        :config="finalConfig"
        :initValue="proxy"
        v-model="proxy"
        :classes="classes"
        ref="autocomplete"
        :onSelect="onSelect"
        :onInput="onInput"
        :textareaField="textareaField"
        v-on="$listeners"
        v-bind="$attrs"
        :showCharacterCount="true"
        :characterLimit="PART_NUMBER_MAX_LENGTH"
        :onBlur="onBlur"
        :use-jsd="true"
    />
</template>

<script>
import { PART_NUMBER_MAX_LENGTH } from 'constants';
import Autocomplete from 'component/revamp/elements/Autocomplete';
import _ from 'lodash';
import jsd from 'app/jsd';
import { defineComponent } from '@vue/composition-api';
import usePartNumberAutocomplete from 'use/partNumberAutocomplete';
import useOrderFormSavedOrder from 'use/orderFormSavedOrder';
import { ProductTypes } from '@/enums';

export default defineComponent({
    setup(props) {
        const { processPartNumberLabel } = usePartNumberAutocomplete(props);

        return {
            ...useOrderFormSavedOrder(),
            processPartNumberLabel,
        };
    },
    inheritAttrs: false,
    components: {
        autocomplete: Autocomplete,
    },
    data() {
        return {
            PART_NUMBER_MAX_LENGTH,
        };
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        config: {
            type: Object,
            default: () => {
                return {};
            },
        },
        facilityId: {
            type: Number,
            default: () => null,
        },
        showPrice: {
            type: Boolean,
            default: false,
        },
        classes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        label: {
            type: String,
            default: 'Part #',
        },
        onSelect: {
            type: Function,
            default: () => {},
        },
        onInput: {
            type: Function,
            default: () => {},
        },
        textareaField: {
            type: Boolean,
            default: false,
        },
        onBlur: Function,
    },
    computed: {
        proxy: {
            set(value) {
                this.$emit('input', value);
            },
            get() {
                return this.value;
            },
        },
        finalConfig() {
            const defaults = {
                url: async (params = {}, config = {}, swaggerOptions = {}) => {
                    if (!params.type_id_in) {
                        params.type_id_in = [ProductTypes.MANUAL, ProductTypes.PRICE_LIST];
                    }

                    const response = await jsd.findAll(
                        'product',
                        {
                            autocomplete_field: 'part_number_search',
                            autocomplete_query: params.part_number,
                            autocomplete_request_id: params.autocomplete_request_id,
                            vendor_id: params.vendor_id ? params.vendor_id.join() : undefined,
                            type_id_in: params.type_id_in ? params.type_id_in.join() : undefined,
                            pagination_type: 'simple',
                            facility_id: this.facilityId ?? undefined,
                        },
                        {
                            force: config.force ?? true,
                            raw: config.raw ?? true,
                            swaggerOptions: swaggerOptions,
                        }
                    );

                    return response;
                },
                anchorFrom: 'part_number',
                labelFrom: 'autocompleteLabel',
                param: 'part_number',
                process: this.processPartNumberLabel,
            };

            return _.merge({}, defaults, this.config);
        },
    },
    methods: {
        setValue(value) {
            this.$refs.autocomplete.setValue(value);
        },
    },
});
</script>
