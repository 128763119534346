import * as mutationTypes from "./mutation_types";
import * as actionTypes from "./action_types";
import SmartApi from "SmartApi";

export default {
    [actionTypes.GET_EXTRACTABLES]: async ({ commit }, params = {}) => {
        const config = { params };
        const routeName = "listExtractables";
        const request = {
            routeName,
            config
        };
        const response = await SmartApi.get(request);
        commit(mutationTypes.SET_EXTRACTABLES, {
            extractables: response.data.data
        });
    }
};
