import _ from 'lodash';
import merge from 'deepmerge';
import { Statuses } from 'enums';
import { TABLE_STORE } from 'store/shared/mixins';
import { apis } from 'api/client';
import * as actions from 'revamp-actions';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        dropdownData: [],
        inRequest: false,
        idToVendor: {},
        default_sort: ['name'],
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
        },
        mapVendors(store, payload) {
            store.idToVendor = {};
            payload.forEach((vendor) => {
                store.idToVendor[vendor.id] = vendor;
            });
        },
        updateVendor(state, payload) {
            let mutable = _.clone(state.dropdownData);

            mutable = mutable.map((vendor) => {
                if (vendor.id == payload.vendor_id) {
                    vendor.fields = payload.fields;
                }

                return vendor;
            });

            state.dropdownData = mutable;
            state.idToVendor[payload.id] = payload;
        },
    },
    getters: {
        selectedVendor(state) {
            return (vendorId) => state.idToVendor[vendorId];
        },
        getVendorCutoffTimeId(state, getters) {
            return (vendorId) => {
                return _.get(getters.selectedVendor(vendorId), 'cutoffTimeId', null);
            };
        },
        getVendorNameById(state, getters) {
            return (vendorId) => {
                let name = '';
                let vendor = getters.selectedVendor(vendorId);

                if (vendor) {
                    name = vendor.name;
                }

                return name;
            };
        },
        activeVendors(state, getters) {
            return state.dropdownData.filter((vendor) => vendor.statusId === Statuses.ACTIVE);
        },
        orderFormVendors(state, getters) {
            return getters.activeVendors.filter((vendor) => vendor.showOnOrderForm);
        },
        pinnedToSPS(state) {
            return state.dropdownData.filter(
                (vendor) => vendor.rebatesPinnedToSps || vendor.flatRateShippingExceptionsPinnedToSps
            );
        },
    },
    actions: {
        async getData({ state, dispatch }) {
            let requestParams = {
                page: state.pagination.page,
                per_page: state.pagination.per_page,
                includes: ['categories'],
            };

            await dispatch('get', requestParams);
            dispatch('shared/quickbar/deselectAllItems', null, { root: true });
        },
        async get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, params, { sort: state.sort });
            let response;
            let vendors;
            if (params.hasOwnProperty('id')) {
                response = await apis.Vendors.getVendor({ vendor_id: params.id });
                vendors = [response.body.data];
            } else {
                response = await apis.Vendors.listVendors(params);
                vendors = response.body.data;
            }

            commit('setData', vendors);

            if (response.body.meta) {
                commit('setPaginationData', response.body.meta);
            }
        },
        async getVendorFields({ state, commit }, params = {}) {
            if (params.hasOwnProperty('id') == false) {
                throw Error('Vendor ID is required.');
            }
            let vendor_id = params.id;
            params = _.merge({}, state.filters, state.pagination, params, { sort: state.sort });

            let response = await apis.Vendors.listOrderFormFieldsForVendor({ vendor_id, params });

            commit('updateVendor', {
                vendor_id: vendor_id,
                fields: response.body.data,
            });
        },
        async [actions.GET_DROPDOWN_DATA]({ state, commit }, params = {}) {
            if (state.inRequest === false) {
                params = merge(params, {
                    sort: ['name'],
                    per_page: -1,
                    pagination_type: 'simple'
                });

                state.inRequest = true;
                const response = await apis.Vendors.listVendors(params);
                commit('setDropdownData', response.body.data);
                commit('mapVendors', response.body.data);
                state.inRequest = false;
            }

            return true;
        },
    },
});
