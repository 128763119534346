import useAddressDropdowns from 'use/partials/addressDropdown';
import useAdminUsersDropdowns from 'use/partials/adminUsersDropdown';
import useFacilityDropdowns from 'use/partials/facilitiesDropdown';
import useInventoryAddressesDropdowns from 'use/partials/inventoryAddressesDropdown';
import useInventoryManufacturersDropdowns from 'use/partials/inventoryManufacturersDropdown';
import useManufacturers from 'use/partials/manufacturers';
import useSavedOrderDropdowns from 'use/partials/savedOrdersDropdown';
import useShoppingListManufacturers from 'use/partials/shoppingListManufacturers';
import useUserDropdowns from 'use/partials/usersDropdown';
import useVendorDropdowns from 'use/partials/vendorsDropdown';
import useWarehouseDropdowns from 'use/partials/warehousesDropdown';

import useShippingMethods from 'use/partials/shippingMethods';
import useShippingServices from 'use/partials/shippingServices';

const Partials = {
    useAdminUsersDropdowns,
    useAddressDropdowns,
    useFacilityDropdowns,
    useInventoryAddressesDropdowns,
    useInventoryManufacturersDropdowns,
    useManufacturers,
    useSavedOrderDropdowns,
    useShoppingListManufacturers,
    useUserDropdowns,
    useVendorDropdowns,
    useWarehouseDropdowns,

    //todo: These should be moved out of partials into /use but will need to be
    // merged with existing use composables and the forms that consume them.
    useShippingMethods,
    useShippingServices,
};

export default Partials;
