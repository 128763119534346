import { computed } from '@vue/composition-api';
import { useStore } from 'use/store';

export default function useModal() {
    const store = useStore();

    function show({ data, component, bindings, listeners }) {
        store.dispatch('shared/modal/showModal', {
            data,
            component,
            bindings,
            listeners,
        });
    }

    function closeAll() {
        store.dispatch('shared/modal/closeAll');
    }

    function close(callback) {
        store.dispatch('shared/modal/closeModal', callback);
    }

    const data = computed(() => store.state.shared.modal.data);

    const modals = computed(() => store.state.shared.modal.modals);

    const parentModal = computed(() => {
        return modals.value[modals.value.length - 2];
    });

    function getSavedState(key) {
        return store.getters['shared/modal/getSavedState'](key);
    }

    function setSavedState(key, data) {
        store.commit('shared/modal/setSavedState', { key, data });
    }

    function deleteSavedState(key) {
        store.commit('shared/modal/deleteSavedState', key);
    }

    return {
        show,
        close,
        closeAll,
        data,
        parentModal,
        getSavedState,
        setSavedState,
        deleteSavedState,
    };
}
