import _ from 'lodash';

const DEFAULT_ID_PROPERTY = 'id';

export default {
    namespaced: true,
    state: {
        items: [],
        visible: false,
        activeTab: "",
        tabs: {},
        idProperty: DEFAULT_ID_PROPERTY,
    },
    mutations: {
        hide(state) {
            state.activeTab = null;
            state.visible = false;
        },
        show(state, tabName) {
            if (!tabName) {
                console.warn("Quickbar show without Tab Name");
                tabName = 'SidebarForm';
            }
            state.activeTab = tabName;
            state.visible = true;
        },
        addTab(state, tab) {
            state.tabs = {...state.tabs, ..._.keyBy([tab], m => m.name)};
        },
        removeTab(state, tabName) {
            state.tabs = _.omit(state.tabs, tabName);
        },
        mutateTab(state, {tabName, values}) {
            Object.assign(state.tabs[tabName], values);
        },


        addItem(state, item) {
            state.items.push(item);
        },
        removeItem(state, item) {
            const templateItem = {};
            templateItem[state.idProperty] = item[state.idProperty];
            state.items = _.reject(state.items, templateItem);
        },
        removeItemsById(state, itemIds) {
            state.items = _.reject(state.items, item => itemIds.includes(item[state.idProperty]));
        },
        addItems(state, items) {
            state.items = _.uniq(state.items.concat(items));
        },
        removeItems(state, items) {
            state.items = _.difference(state.items, items);
        },
        clearItems(state) {
            state.items = [];
        },
        setItems(state, items) {
            state.items = items;
        },
        replaceItem(state, { index, item }) {
            state.items.splice(index, 1, item);
        },
        setIdProperty(state, property) {
            state.idProperty = property;
        },
        resetIdProperty(state) {
            state.idProperty = DEFAULT_ID_PROPERTY;
        },
    },
    actions: {
        toggle({ commit, state }, tabName) {
            if (tabName) {
                if (state.activeTab === tabName) {
                    commit("hide");
                } else {
                    commit("show", tabName);
                }
            } else {
                console.warn("Quickbar toggle without Tab Name");
                commit("hide");
            }
        },
        registerTab({commit}, tab) {
            if (tab && tab.name) {
                commit("addTab", tab);
            }
        },
        unregisterTab({state, commit}, name) {
            if (state.activeTab === name) {
                commit("hide");
            }
            commit("removeTab", name);
        },

        updateTab({ commit, state }, {tabName, values}) {
            if (_.has(state.tabs, tabName)) {
                commit('mutateTab', { tabName, values })
            }
        },


        updateItem({ commit, state }, item) {
            const index = state.items.findIndex(storedItem => storedItem[state.idProperty] == item[state.idProperty]);
            const hasItem = index > -1;
            if (hasItem) {
                commit('replaceItem', { index, item })
            }
        },
        toggleAllItems({ getters, commit }, items) {
            if (getters.allItemsSelected(items)) {
                commit('clearItems');
                return false;
            } else {
                commit('addItems', items);
                return true;
            }
        },
        deselectAllItems({ commit }) {
            commit('setItems', []);
        },
    },
    getters: {
        activeTabValue: (state, getters) => (key) => {
            return getters.tabValue(state.activeTab, key);
        },
        tabValue: (state) => (tabName, key) => {
            if (_.has(state.tabs, tabName)) {
                return _.get(state.tabs[tabName], key);
            }
            return null;
        },
        tab: (state) => (tabName) => state.tabs[tabName],
        allItemsSelected: (state) => (tableItems) => {
            const id = state.idProperty;
            return _.every(tableItems, tableItem => {
                return _.find(state.items, storeItem => storeItem[id] === tableItem[id]);
            });
        },
        itemIsSelected: (state) => (tableItem) => {
            const id = state.idProperty;
            return _.findIndex(state.items, storeItem => storeItem[id] === tableItem[id]) !== -1;
        },
    }
}
