export async function createAndSettlePromisesFor(collection, callback) {
    return Promise.allSettled(collection.map(callback));
}

export function isFulfilled(promise) {
    if (promise instanceof Array) {
        return promise.every(isFulfilled);
    }

    return promise.status === 'fulfilled';
}

export function isRejected(promise) {
    if (promise instanceof Array) {
        return promise.every(isRejected);
    }

    return promise.status === 'rejected';
}
