import _ from "lodash";
import moment from "moment-timezone";

export default {
    setNotifications(state, notifications) {
        state.notifications = {
            ..._.keyBy(notifications, (notification) => notification.id)
        };
    },
    addNotification(state, notification) {
        state.notifications = {...state.notifications, ..._.keyBy([notification], n => n.id)};
    },
    removeNotification(state, id) {
        state.notifications = _.omit(state.notifications, id);
    },
    removeAllNotifications(state) {
        state.notifications = {};
    },
    updateNotification(state, {notificationId, values}) {
        Object.assign(state.notifications[notificationId], values);
    },
    markAllNotificationsAsRead(state, date) {
        _.each(state.notifications, (notification,id) => {
            state.notifications[id].read_at = date;
        });
    },
    clearNotifications(state) {
        state.notifications = {};
    },
}
