<template>
    <emp-component-modal @modal:close="onClose" :isActive="isActive" contentClass="width-30vw">
        <template v-if="isActive">
            <div slot="modal-header">Choose a Warehouse</div>
            <div slot="modal-body">
                <div class="choose-warehouse">
                    <div class="mobile-table-wrapper">
                        <table class="table is-fullwidth">
                            <thead>
                                <td class="has-text-centered">Name</td>
                                <td class="has-text-centered">State</td>
                                <td class="has-text-centered">Zipcode</td>
                                <td class="has-text-centered">Order By</td>
                                <td class="has-text-centered"></td>
                            </thead>
                            <tr v-for="(warehouse, index) in warehouses" :key="`warehouse_${index}`">
                                <td class="has-text-centered">{{ warehouse.name }}</td>
                                <td class="has-text-centered">{{ getStateNameById(warehouse.stateId) }}</td>
                                <td class="has-text-centered">{{ warehouse.zipcode }}</td>
                                <td class="has-text-centered">{{ formatTime(warehouse.cutoffTimeId) }}</td>
                                <td class="has-text-centered">
                                    <input type="radio" :value="warehouse" name="warehouse" v-model="chosenWarehouse" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="level-item">
                                <emp-button label="Choose Selected" @click="onSubmit" buttonClass="is-primary" />
                            </div>
                            <div class="level-item">
                                <emp-button buttonClass="is-danger" label="Cancel" @click="onClose" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </emp-component-modal>
</template>

<script>
import _ from 'lodash';
import { systemNotifications } from 'component/shared/elements/mixins';
import { getStateNameById } from 'enumHelpers';
import { formatTime } from 'helpers';
import { mapState, mapMutations, mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [systemNotifications],
    methods: {
        ...mapMutations({
            setChosenWarehouse: 'ordersManage/setChosenWarehouse',
        }),
        ...mapActions({
            closeModal: 'shared/modal/closeModal',
        }),
        async onSubmit() {
            if (_.isEmpty(this.chosenWarehouse)) {
                this.showDangerNotification('Please Choose Warehouse');
                return false;
            }

            this.onClose({
                method: this.onSubmitCallback,
                params: this.payload,
            });
        },
        onClose(callback = null) {
            this.setChosenWarehouse(null);
            this.closeModal(callback);
        },
        formatTime,
        getStateNameById,
    },
    computed: {
        ...mapState({
            storedWarehouse: (state) => state.ordersManage.chosenWarehouse,
            data: (state) => state.shared.modal.data,
        }),
        payload() {
            return this.type === 'estimate' ? this.estimateShippingPayload : this.estimateExpectedDatePayload;
        },
        estimateShippingPayload() {
            return {
                vendor: this.line.vendor,
                shippingAddress: this.line.shippingAddress,
                dimensions: this.dimensions,
                warehouse: this.chosenWarehouse,
            };
        },
        estimateExpectedDatePayload() {
            return {
                entityType: this.entityType,
                line: this.line,
                warehouse: this.chosenWarehouse,
            };
        },
        line() {
            return _.get(this.data, 'line', {});
        },
        type() {
            return _.get(this.data, 'type', null);
        },
        entityType() {
            return _.get(this.data, 'entityType', null);
        },
        dimensions() {
            return _.get(this.data, 'dimensions', null);
        },
        onSubmitCallback() {
            return _.get(this.data, 'onSubmit', () => {});
        },
        warehouses() {
            return _.get(this.data, 'warehouses', []);
        },
        defaultWarehouse() {
            const defaultWarehouse = this.warehouses.find((warehouse) => warehouse.isDefault);

            if (defaultWarehouse) {
                return defaultWarehouse;
            }

            return {};
        },
        chosenWarehouse: {
            get() {
                return this.storedWarehouse;
            },
            set(value) {
                this.setChosenWarehouse(value);
            },
        },
    },
    watch: {
        isActive: {
            handler(value) {
                if (value) {
                    let warehouse =
                        this.warehouses.find(({ id }) => id == this.line.warehouseId) || this.defaultWarehouse;
                    this.setChosenWarehouse(warehouse);
                }
            },
            immediate: true,
        },
    },
});
</script>

<style lang="scss">
.orders {
    .modal-content {
        .message-body {
            max-height: calc(100vh - 200px);
            overflow-y: auto;
        }
    }

    .card-header .button {
        text-decoration: none;
        margin-top: 6px;
        margin-right: 6px;
    }
    .margin-top {
        margin-bottom: 1.1rem;
    }
    .margin-bottom {
        margin-bottom: 1.5rem;
    }
    .checkbox {
        margin-top: 5px;
    }
}
</style>
