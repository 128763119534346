export default {
    mapper: {
        relations: {
            belongsTo: {
                user: {
                    foreignKey: 'user_id',
                    localField: 'user',
                },
                scope: {
                    foreignKey: 'scope_id',
                    localField: 'scope',
                },
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
            },
        },
    },
};
