/**
 * Shared
 */

const News = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/News');
const RedeemInventoryPart = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/RedeemInventoryPart');
const Login = () => import(/* webpackChunkName: "moose" */ 'component/shared/Login');
const Registration = () => import(/* webpackChunkName: "moose" */ 'component/shared/Registration');
const PasswordResetRequest = () => import(/* webpackChunkName: "moose" */ 'component/shared/PasswordResetRequest');
const PasswordResetForm = () => import(/* webpackChunkName: "moose" */ 'component/shared/PasswordResetForm');
const Dashboard = () => import('component/shared/Dashboard');
const OrderForm = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/OrderForm');
const OrderHistory = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/OrderHistory');
const InventoryForm = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/InventoryForm');
const AddItemToOrderForm = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/AddItemToOrderForm');
const Inventory = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/Inventory');
const InventoryRedemptions = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/InventoryRedemptions');
const InventoryChangeToDifferentProduct = () =>
    import(/* webpackChunkName: "slytherin" */ 'component/shared/InventoryChangeToDifferentProduct');
const Message = () => import(/* webpackChunkName: "quickbar" */ 'component/shared/Message');
import store from 'store/shared';

export const scrollBehavior = function (to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition;
    }

    return {
        x: 0,
        y: 0,
    };
};

function isAdmin() {
    const currentUser = store.modules.currentUser;
    const { state, getters } = currentUser;
    return getters.isAdmin(state);
}

function makeRedirector({ adminRouteName, facilityRouteName }) {
    return () => {
        if (isAdmin()) {
            return { name: adminRouteName };
        }
        return { name: facilityRouteName };
    };
}

export default [
    {
        path: '/users/',
        name: 'users',
        redirect: makeRedirector({
            adminRouteName: 'admin.users',
            facilityRouteName: 'facility.users',
        }),
    },
    {
        path: '/users/:id(\\d+)',
        name: 'users.edit',
        meta: { title: 'User Settings' },
        redirect: makeRedirector({
            adminRouteName: 'admin.users.edit',
            facilityRouteName: 'facility.users.edit',
        }),
    },
    {
        path: '/tools',
        name: 'tools',
        redirect: makeRedirector({
            adminRouteName: 'tools.batch-invoices',
            facilityRouteName: 'tools.products.search',
        }),
    },
    {
        path: '/inventory',
        component: Inventory,
        name: 'inventory',
        children: [
            {
                path: 'order/:id',
                component: AddItemToOrderForm,
                name: 'inventory.add-to-order-form',
            },
            {
                path: '/inventory/edit/:inventoryRecordId(\\d+)',
                component: InventoryForm,
                name: 'inventory.edit',
                props: (route) => {
                    return {
                        inventoryRecordId: Number(route.params.inventoryRecordId),
                        facilityId: false,
                        productId: false,
                    };
                },
                meta: { title: 'Edit Inventory Part' },
            },
            {
                path: '/inventory/change-to-different-product/:id(\\d+)/:facilityId(\\d+)',
                component: InventoryChangeToDifferentProduct,
                name: 'inventory.change-to-different-product',
                props: (route) => {
                    return {
                        inventoryRecordId: Number(route.params.id),
                        facilityId: Number(route.params.facilityId),
                    };
                },
                meta: { title: 'Change Inventory Part' },
            },
        ],
        meta: { title: 'Inventory' },
    },
    {
        path: '/inventory/redemptions',
        component: InventoryRedemptions,
        name: 'inventory.redemptions',
        meta: { title: 'Inventory Redemptions' },
    },
    {
        alias: '/',
        path: '/home',
        name: 'dashboard',
        component: Dashboard,
        meta: { title: 'Dashboard' },
    },
    {
        path: '/orders/form',
        component: OrderForm,
        name: 'orders.form',
        meta: { title: 'Order Form' },
    },
    {
        path: '/orders/history',
        component: OrderHistory,
        name: 'orders.history',
        children: [
            {
                path: '/orders/history/:facilityId(\\d+)/create-inventory/:productId(\\d+)',
                component: InventoryForm,
                name: 'orders.history.inventory.create',
                props: (route) => {
                    return {
                        facilityId: Number(route.params.facilityId),
                        productId: Number(route.params.productId),
                        inventoryRecordId: false,
                    };
                },
                meta: { title: 'Create Inventory Part' },
            },
            {
                path: 'order/:id',
                component: AddItemToOrderForm,
                name: 'orders.history.reorder',
            },
        ],
        meta: { title: 'Order History' },
    },
    {
        path: '/inventory/redeem/:digest',
        component: RedeemInventoryPart,
        name: 'inventory.redeem',
        meta: { title: 'Redeem Inventory Part' },
    },
    {
        path: '/inventory/manage',
        redirect: {
            name: 'inventory',
        },
    },
    {
        path: '/products/view-list',
        redirect: {
            name: 'products',
        },
    },
    {
        path: '/news',
        component: News,
        name: 'news',
        meta: { title: 'News' },
    },
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: { title: 'Login', guestOnly: true },
    },
    {
        path: '/register',
        component: Registration,
        name: 'register',
        meta: { title: 'Registration', guestOnly: true },
    },
    {
        path: '/password/reset',
        component: PasswordResetRequest,
        name: 'password.request',
        meta: { title: 'Password Reset Request', guestOnly: true },
    },
    {
        path: '/password/reset/:token',
        component: PasswordResetForm,
        name: 'password.reset',
        meta: { title: 'Password Reset Form', guestOnly: true },
    },
    {
        path: '/messages/:messageId(\\d+)',
        name: 'messages.view',
        meta: { title: 'Message Center' },
        component: Message,
    },
];
