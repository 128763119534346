import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async getNextId() {
        let url = route('purchase-orders.next-id');
        let response = await axios.post(url);
        return response.data.data;
    },
};
