import { Statuses as StatusesEnum } from 'enums';
import { getStatusNameById } from 'enumHelpers';

export const sortOrders = {
    isManage: [
        StatusesEnum.PENDING_REVIEW,
        StatusesEnum.OPEN,
        StatusesEnum.ON_HOLD,
        StatusesEnum.BACKORDERED,
        StatusesEnum.NEED_MORE_INFO,
        StatusesEnum.WAITING_FOR_VENDOR,
        StatusesEnum.ORDERED,
        StatusesEnum.ORDERED_NEED_MORE_INFO,
        StatusesEnum.RECEIVED,
        StatusesEnum.PARTIALLY_RECEIVED,
        StatusesEnum.DELETED,
    ],
    isManageAll: [
        StatusesEnum.PENDING_REVIEW,
        StatusesEnum.OPEN,
        StatusesEnum.ON_HOLD,
        StatusesEnum.BACKORDERED,
        StatusesEnum.NEED_MORE_INFO,
        StatusesEnum.WAITING_FOR_VENDOR,
        StatusesEnum.ORDERED,
        StatusesEnum.ORDERED_NEED_MORE_INFO,
        StatusesEnum.RECEIVED,
        StatusesEnum.PARTIALLY_RECEIVED,
        StatusesEnum.PENDING_RETURN,
        StatusesEnum.RETURN_REQUESTED,
        StatusesEnum.TO_BE_PRINTED,
        StatusesEnum.RETURNED,
        StatusesEnum.RETURN_REJECTED,
        StatusesEnum.INACTIVE,
        StatusesEnum.DELETED,
        StatusesEnum.ALL_EXCL_DELETED,
        StatusesEnum.ALL_INCL_DELETED,
    ],
    isAddress: [StatusesEnum.ACTIVE, StatusesEnum.INACTIVE],
    isFacility: [StatusesEnum.ACTIVE, StatusesEnum.INACTIVE],
    isVendor: [StatusesEnum.ACTIVE, StatusesEnum.INACTIVE],
    isProduct: [StatusesEnum.ACTIVE, StatusesEnum.INACTIVE],
    isEmailAccount: [StatusesEnum.ACTIVE, StatusesEnum.INACTIVE],
    isBilling: [
        StatusesEnum.OPEN,
        StatusesEnum.INVOICED,
        StatusesEnum.NEED_SHIPPING,
        StatusesEnum.POSTED,
        StatusesEnum.POSTED_CHANGED,
        StatusesEnum.POSTED_REVIEWED,
    ],
    isInvoice: [
        StatusesEnum.PENDING_REVIEW,
        StatusesEnum.NEED_SHIPPING,
        StatusesEnum.REVIEWED,
        StatusesEnum.REVIEWED_CHANGED,
        StatusesEnum.SENT,
        StatusesEnum.SENT_CHANGED,
        StatusesEnum.SENT_REVIEWED,
        StatusesEnum.POSTED,
        StatusesEnum.POSTED_CHANGED,
        StatusesEnum.POSTED_REVIEWED,
        StatusesEnum.DELETED,
    ],
    isCreditMemo: [
        StatusesEnum.PENDING_REVIEW,
        StatusesEnum.REVIEWED,
        StatusesEnum.REVIEWED_CHANGED,
        StatusesEnum.SENT,
        StatusesEnum.SENT_CHANGED,
        StatusesEnum.SENT_REVIEWED,
        StatusesEnum.POSTED,
        StatusesEnum.POSTED_CHANGED,
        StatusesEnum.POSTED_REVIEWED,
        StatusesEnum.DELETED,
    ],
    isPurchaseOrders: [StatusesEnum.ORDERED, StatusesEnum.WAITING, StatusesEnum.EDI_QUEUED],
    isPurchaseOrderBilling: [
        StatusesEnum.OPEN,
        StatusesEnum.PARTIALLY_BILLED,
        StatusesEnum.BILLED,
        StatusesEnum.CLOSED,
    ],
    isHistory: [
        StatusesEnum.ON_HOLD,
        StatusesEnum.OPEN,
        StatusesEnum.ORDERED,
        StatusesEnum.ORDERED_NEED_MORE_INFO,
        StatusesEnum.PARTIALLY_RECEIVED,
        StatusesEnum.RECEIVED,
        StatusesEnum.CREDITED,
        StatusesEnum.BACKORDERED,
        StatusesEnum.NEED_MORE_INFO,
        StatusesEnum.WAITING_FOR_VENDOR,
        StatusesEnum.PENDING_RETURN,
        StatusesEnum.RETURN_REQUESTED,
        StatusesEnum.TO_BE_PRINTED,
        StatusesEnum.RETURNED,
        StatusesEnum.RETURN_REJECTED,
        StatusesEnum.INACTIVE,
        StatusesEnum.DELETED,
    ],
    isLineBilling: [
        StatusesEnum.BILLED,
        StatusesEnum.PARTIALLY_BILLED,
        StatusesEnum.OPEN,
        StatusesEnum.CREDITED,
        StatusesEnum.MATCHED,
    ],
};

export function statusesSortedForType(type) {
    if (sortOrders.hasOwnProperty(type)) {
        return sortOrders[type].map(getStatusNameById);
    }
    return null;
}
