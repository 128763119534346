import { auditsRelation, notesRelation } from 'app/jsd/helpers';

export default {
    modelName: 'line',
    mapper: {
        relations: {
            belongsTo: {
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
                warehouse: {
                    foreignKey: 'warehouse_id',
                    localField: 'warehouse',
                },
                address: [
                    {
                        foreignKey: 'shipping_address_id',
                        localField: 'shippingAddress',
                    },
                    {
                        foreignKey: 'cost_center_id',
                        localField: 'costCenter',
                    },
                ],
                user: {
                    foreignKey: 'practitioner_id',
                    localField: 'practitioner',
                },
                category: {
                    foreignKey: 'category_id',
                    localField: 'category',
                },
                purchaseOrder: {
                    foreignKey: 'purchase_order_id',
                    localField: 'purchaseOrder',
                },
                product: {
                    foreignKey: 'product_id',
                    localField: 'product',
                },
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
                inventoryLocation: {
                    foreignKey: 'inventory_location_id',
                    localField: 'inventoryLocation',
                },
            },
            belongsToMany: {
                bill: {
                    foreignKey: 'id',
                    localField: 'bills',
                },
                credit: {
                    foreignKey: 'id',
                    localField: 'credits',
                },
                trackingNumber: {
                    foreignKey: 'id',
                    localField: 'trackingNumbers',
                },
                invoice: {
                    foreignKey: 'id',
                    localField: 'invoices',
                },
                patientName: {
                    foreignKey: 'id',
                    localField: 'patients',
                },
                generalShippingLabel: {
                    foreignKey: 'id',
                    localField: 'shippingLabels',
                },
            },
            hasMany: {
                serialNumber: {
                    foreignKey: 'line_id',
                    localField: 'serialNumbers',
                },
                lineView: {
                    foreignKey: 'line_id',
                    localField: 'lineViews',
                },
                returnRequest: {
                    foreignKey: 'line_id',
                    localField: 'returnRequests',
                },
            },
            morphMany: {
                ...auditsRelation('line'),
                ...notesRelation('line'),
            },
        },
    },
};
