import _ from 'lodash';
import { TABLE_STORE } from 'store/shared/mixins';
import { apis } from 'api/edi';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        essentialDataLoaded: false,
        availableTransferMethods: [],
        availableDocumentTypes: [],
        availableDocumentMappers: [],
        availableDocumentMappersByType: {},
        tradingPartnerMap: new Map(),
        tradingPartnerDocumentMappingsMap: new Map(),
        tradingPartnerEntityMappingsMap: new Map(),
    },
    actions: {
        async loadTradingPartnerDocumentMappings({ state, commit }, tradingPartnerId) {
            // TODO: Catch communication errors.
            const tradingPartnerDocumentMappingsApi = apis['Trading Partner - Document Mappings'];
            let result = await tradingPartnerDocumentMappingsApi.listTradingPartnerDocumentMappings({
                tradingPartnerId,
            });
            commit('updateTradingPartnerDocumentMappingsMap', { tradingPartnerId, documentMappings: result.body.data });
        },
        async loadTradingPartnerEntityMappings({ state, commit }, tradingPartnerId) {
            // TODO: Catch communication errors.
            const tradingPartnerEntityMappingsApi = apis['Trading Partner - Entity Mappings'];
            let result = await tradingPartnerEntityMappingsApi.listTradingPartnerEntityMappings({ tradingPartnerId });
            commit('updateTradingPartnerEntityMappingsMap', { tradingPartnerId, entityMappings: result.body.data });
        },
        async loadTradingPartner({ state, commit, dispatch }, tradingPartnerId) {
            const tradingPartner = apis['Trading Partner'];

            // TODO: Catch communication errors.
            let result = await tradingPartner.showTradingPartner({ tradingPartnerId });

            await Promise.all([
                dispatch('loadTradingPartnerDocumentMappings', tradingPartnerId),
                dispatch('loadTradingPartnerEntityMappings', tradingPartnerId),
            ]);

            commit('updateTradingPartnerMap', result.body.data);
        },
        async getEssentialData({ state, commit }) {
            if (state.essentialDataLoaded) {
                return;
            }

            const registeredEntitiesApi = apis['Registered Entities'];

            // TODO: Catch communication errors.
            let promises = [
                registeredEntitiesApi.listRegisteredTransferMethods(),
                registeredEntitiesApi.listRegisteredDocumentMappers(),
                registeredEntitiesApi.listRegisteredDocumentTypes(),
                registeredEntitiesApi.listRegisteredSerializers(),
            ];

            let [transferMethods, documentMappers, documentTypes, serializers] = await Promise.all(promises);

            commit('setAvailableTransferMethods', transferMethods.body.data);
            commit('setAvailableDocumentMappers', documentMappers.body.data);
            commit('setAvailableDocumentTypes', documentTypes.body.data);
            commit('setAvailableSerializers', serializers.body.data);
            commit('setEssentialDataLoaded', true);
        },
        async get({ commit }, params = {}) {
            const tradingPartnersApi = apis['Trading Partners'];
            // TODO: Catch communication errors.
            let results = await tradingPartnersApi.listTradingPartners();
            commit('setData', results.body.data);
            commit('setTradingPartnerMap', results.body.data);
        },
    },
    mutations: {
        updateTradingPartnerDocumentMappingsMap(state, { tradingPartnerId, documentMappings }) {
            let map = new Map(state.tradingPartnerDocumentMappingsMap);
            map.set(tradingPartnerId, documentMappings);
            state.tradingPartnerDocumentMappingsMap = map;
        },
        updateTradingPartnerEntityMappingsMap(state, { tradingPartnerId, entityMappings }) {
            let map = new Map(state.tradingPartnerEntityMappingsMap);
            map.set(tradingPartnerId, entityMappings);
            state.tradingPartnerEntityMappingsMap = map;
        },
        updateTradingPartnerMap(state, tradingPartner) {
            let map = new Map(state.tradingPartnerMap);
            map.set(tradingPartner.id, tradingPartner);
            state.tradingPartnerMap = map;
        },
        setTradingPartnerMap(state, payload) {
            let map = new Map();
            payload.forEach((tradingPartner) => {
                map.set(tradingPartner.id, tradingPartner);
            });
            state.tradingPartnerMap = map;
        },
        setEssentialDataLoaded(state, value) {
            state.essentialDataLoaded = value;
        },
        setData(state, payload) {
            state.data = payload;
        },
        setAvailableDocumentTypes(state, payload) {
            state.availableDocumentTypes = payload;
        },
        setAvailableTransferMethods(state, payload) {
            state.availableTransferMethods = payload;
        },
        setAvailableSerializers(state, payload) {
            state.availableSerializers = payload;
        },
        setAvailableDocumentMappers(state, payload) {
            state.availableDocumentMappers = payload;
            state.availableDocumentMappersByType = _.groupBy(state.availableDocumentMappers, 'document_type');
        },
    },
});
