// @ts-check
import Viewer from './viewer.js';

/**
 * Represent a view of a Line.
 */
export default class View {
    /** @type {Viewer}*/
    viewer;
    /** @type {number}*/
    id;

    /**
     * Create a view.
     * @param {Object} data
     * @param {Viewer} data.viewer
     * @param {number} data.id
     */
    constructor({ viewer, id }) {
        this.viewer = viewer;
        this.id = id;
    }
}
