// @ts-check
import { computed, ref, watchEffect } from '@vue/composition-api';

const STATES = {
    VALIDATING: 'VALIDATING',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    STALE_IF_ERROR: 'STALE_IF_ERROR',
};
Object.freeze(STATES);

/**
 * Return a Ref to the current state of the swrv request and a map of all states to strings.
 *
 * Important: This function will _not_ return the `PENDING` state unless data is `undefined` before fetching is complete.
 * E.g. if the function does something like `if (!data.value) { data.value = {}}`, then the initial state will be
 * `VALIDATING` instead of `PENDING`.
 *
 * @link https://docs-swrv.netlify.app/features.html#state-management
 * @param {import('@vue/composition-api').Ref} data Data returned from swrv.
 * @param {import('@vue/composition-api').Ref} error An error thrown by the fetcher and caught by swrv.
 * @param {import('@vue/composition-api').Ref<boolean>} isValidating Truthy if swrv is (re)validating the cached data.
 * @returns
 */
export default function useSwrvState(data, error, isValidating) {
    const state = ref('idle');
    watchEffect(() => {
        if (data.value && isValidating.value) {
            state.value = STATES.VALIDATING;
            return;
        }
        if (data.value && error.value) {
            state.value = STATES.STALE_IF_ERROR;
            return;
        }
        if (data.value === undefined && !error.value) {
            state.value = STATES.PENDING;
            return;
        }
        if (data.value && !error.value) {
            state.value = STATES.SUCCESS;
            return;
        }
        if (data.value === undefined && error) {
            state.value = STATES.ERROR;
            return;
        }
    });

    const isLoading = computed(() => state.value === STATES.PENDING);

    /**
     * True if a request is in process.
     */
    const isFetching = computed(() => isLoading.value || isValidating.value);

    return {
        state,
        STATES,
        isLoading,
        isFetching,
    };
}

export { STATES };
