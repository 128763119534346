// @ts-check
import * as actionTypes from './action_types.js';
import * as mutationTypes from './mutation_types.js';
import Viewer from './viewer.js';
import View from './view.js';
import messages from './messages.js';
import state from './state.js';
import _ from 'lodash';

/**
 * Return the name of the presence channel for the given Line id.
 * @param {number} id The id of the Line
 * @returns {string} The name of the presence channel.
 */
export function makeChannelName(id) {
    return `lines.${id}`;
}

let hasInitialized = false;

/**
 * @type {import('vuex').ActionTree<typeof state>}
 */
const actions = {
    /**
     * Join a presence channel for a Line.
     * @param {import('vuex').ActionContext<typeof state>} context
     * @param {Object} payload
     * @param {number} payload.id The id of a Line
     */
    [actionTypes.ENTER]({ commit, getters, dispatch, state, rootGetters }, { id }) {
        if (!state.isEnabled || rootGetters['shared/currentUser/isFacility']) {
            return;
        }
        id = Number(id);
        commit(mutationTypes.ENTER, { id });
        if (!getters.isConnected) {
            return;
        }
        if (!hasInitialized) {
            window.addEventListener('beforeunload', async () => {
                await dispatch(actionTypes.LEAVE_ALL);
            });
            hasInitialized = true;
        }
        /**
         * @type {import('laravel-echo').default}
         */
        const echo = getters.echo;

        /**
         * Action to execute when another user leaves the channel.
         * @param {Viewer} viewer
         */
        const whenOtherViewerLeaves = (viewer) => {
            viewer = new Viewer(viewer);
            const view = new View({
                id: id,
                viewer,
            });
            commit(mutationTypes.RECORD_SHUT, view);
        };

        /**
         * Action to execute when another viewer broadcasts that they have expanded a Line.
         * @param {Viewer} viewer
         */
        const whenOtherViewerOpens = (/** @type {Viewer} */ viewer) => {
            viewer = new Viewer(viewer);
            const view = new View({
                viewer,
                id: id,
            });
            commit(mutationTypes.RECORD_OPEN, view);
        };
        /**
         * Action to execute when another viewer broadcasts they have collapsed a Line.
         * @param {Viewer} viewer
         */
        const whenOtherViewerShuts = (/** @type {Viewer} */ viewer) => {
            viewer = new Viewer(viewer);
            const view = new View({
                viewer,
                id: id,
            });
            commit(mutationTypes.RECORD_SHUT, view);
        };

        /**
         * @typedef {Object} StatusRequest
         * @property {string} requestor
         */

        /**
         * Action to execute when another user requests the status of this Line.
         * @param {StatusRequest} statusRequest
         */
        const whenStatusIsRequested = (statusRequest) => {
            const view = new View({
                viewer: getters.selfAsViewer,
                id,
            });
            let status;
            if (getters.isOpen({ id })) {
                status = messages.OPEN;
            } else {
                messages.SHUT;
            }
            channel.whisper(messages.STATUS_RESPONSE, {
                respondingTo: statusRequest.requestor,
                status,
                view,
            });
        };

        /**
         * Action to execute when this client joins the channel.
         * @param {array} users
         */
        const onHere = (users) => {
            // Clear the current viewers to prevent the indicator from erroneously incrementing
            // if the client disconnects and then reconnects to the channel.
            commit(mutationTypes.CLEAR, { id });
            if (getters.isOpen({ id })) {
                // The Line list views do not always collapse expanded Lines on navigation.
                // Record this client as viewing the `Line` if is expanded in any list view.
                dispatch(actionTypes.BROADCAST_OPEN, { id });
            }
            // Request the status of a `Line` after joining the channel
            channel.whisper(messages.REQUEST_STATUS, {
                requestor: echo.socketId(),
            });
        };

        /**
         * @typedef {Object} StatusResponse
         * @property {string} respondingTo
         * @property {string} status
         * @property {View} view
         */

        /**
         * Action to execute when a status response is received.
         * @param {StatusResponse} statusResponse
         */
        const whenStatusIsBroadcast = (statusResponse) => {
            // Ignore status responses that are sent to requests made from other clients.
            if (statusResponse.respondingTo != echo.socketId()) {
                return;
            }
            if (statusResponse.status == messages.OPEN) {
                commit(mutationTypes.RECORD_OPEN, statusResponse.view);
            } else if (statusResponse.status == messages.SHUT) {
                commit(mutationTypes.RECORD_SHUT, statusResponse.view);
            }
        };

        const channelName = makeChannelName(id);
        const channel = echo
            .join(channelName)
            .here(onHere)
            // We do not make an announcement when another user joins. Instead, we wait for the user to ask for our status.
            // This is done because the server does not broadcast a join message to clients of the same user.
            .joining(_.noop)
            .leaving(whenOtherViewerLeaves)
            // @ts-ignore
            // listenForWhisper is not on the PresenceChannel interface
            // but it is on the concrete implementations.
            .listenForWhisper(messages.OPEN, whenOtherViewerOpens)
            .listenForWhisper(messages.SHUT, whenOtherViewerShuts)
            .listenForWhisper(messages.REQUEST_STATUS, whenStatusIsRequested)
            .listenForWhisper(messages.STATUS_RESPONSE, whenStatusIsBroadcast);
    },
    /**
     * Leave a presence channel for a Line.
     * @param {import('vuex').ActionContext<typeof state>} context
     * @param {Object} payload
     * @param {number} payload.id The id of a Line
     */
    async [actionTypes.LEAVE]({ commit, dispatch, getters, state, rootGetters }, { id }) {
        if (!state.isEnabled || rootGetters['shared/currentUser/isFacility']) {
            return;
        }
        id = Number(id);
        commit(mutationTypes.LEAVE, { id });
        if (!getters.isConnected) {
            return;
        }
        await dispatch(actionTypes.BROADCAST_SHUT, { id });
        /**
         * @type {import('laravel-echo').default}
         */
        const echo = getters.echo;
        echo.leave(makeChannelName(id));
    },
    /**
     * Broadcast the user has opened a Line to other users in the Line's channel.
     * @param {import('vuex').ActionContext<typeof state>} context
     * @param {Object} payload
     * @param {number} payload.id The id of a Line
     */
    [actionTypes.BROADCAST_OPEN]({ commit, getters, state, rootGetters }, { id }) {
        if (!state.isEnabled || rootGetters['shared/currentUser/isFacility']) {
            return;
        }
        id = Number(id);
        if (!getters.isConnected) {
            return;
        }
        const viewer = getters.selfAsViewer;
        commit(mutationTypes.RECORD_OPEN, { id, viewer });
        /**
         * @type {import('laravel-echo').default}
         */
        const echo = getters.echo;
        echo.join(makeChannelName(id))
            // @ts-ignore
            // whisper is not on the PresenceChannel interface
            // but it is on the concrete implementations.
            .whisper(messages.OPEN, viewer);
    },
    /**
     * Broadcast the user has closed a Line to other users in the Line's channel.
     * @param {import('vuex').ActionContext<typeof state>} context
     * @param {Object} payload
     * @param {number} payload.id The id of a Line
     */
    [actionTypes.BROADCAST_SHUT]({ commit, getters, state, rootGetters }, { id }) {
        if (!state.isEnabled || rootGetters['shared/currentUser/isFacility']) {
            return;
        }
        id = Number(id);
        if (!getters.isConnected) {
            return;
        }
        if (!getters.isOpen({ id })) {
            return;
        }
        const viewer = getters.selfAsViewer;
        commit(mutationTypes.RECORD_SHUT, { id, viewer });
        /**
         * @type {import('laravel-echo').default}
         */
        const echo = getters.echo;
        echo.join(makeChannelName(id))
            // @ts-ignore
            // whisper is not on the PresenceChannel interface
            // but it is on the concrete implementations.
            .whisper(messages.SHUT, viewer);
    },
    /**
     * Leave all Line presence channels that this client is a member of.
     * @param {import('vuex').ActionContext<typeof state>} context
     */
    async [actionTypes.LEAVE_ALL]({ state, dispatch, rootGetters }) {
        if (!state.isEnabled || rootGetters['shared/currentUser/isFacility']) {
            return;
        }
        await Promise.allSettled(
            Object.keys(state.views)
                .map((id) => Number(id))
                .map((id) => {
                    return dispatch(actionTypes.LEAVE, { id });
                })
        );
    },
};

export default actions;
