import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            hasMany: {
                line: {
                    foreignKey: 'category_id',
                    localField: 'lines',
                },
                category: {
                    foreignKey: 'parent_id',
                    localField: 'children',
                },
                product: {
                    foreignKey: 'category_id',
                    localField: 'products',
                },
            },
            hasOne: {
                category: {
                    foreignKey: 'id',
                    localField: 'parent',
                },
            },
            belongsToMany: {
                vendor: {
                    foreignKey: 'id',
                    localField: 'vendors',
                },
                returnAddress: {
                    foreignKey: 'id',
                    localField: 'returnAddresses',
                },
            },
            morphMany: {
                ...auditsRelation('category'),
            },
        },
    },
};
