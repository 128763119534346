import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = route('orders');
        return await axios.get(url, { params });
    },
    async getOriginalOrder(orderId) {
        let url = route('orders.original', { orderId });
        return await axios.get(url);
    },
    async post(params = {}) {
        let url = route('orders.create');
        return await axios.post(url, params);
    },
    async update(payload) {
        let url = route('orders.edit', { line: payload.id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async updateSerials(payload) {
        let url = route('orders.update-serials', { id: payload.id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async editOrderRequest(payload) {
        let url = route('orders.edit-order-request', { id: payload.line.id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async updateReturnRequest(payload) {
        let url = route('orders.update-request-return', { id: payload.lineId });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async changeStatus(payload = {}) {
        let url = route('orders.change-status');
        let result = await axios.post(url, payload, { hideLoader: true });
        return result.data.data;
    },
    async createShippingLabel(payload = {}) {
        let url = route('orders.create-shipping-label');
        let result = await axios.post(url, payload);
        return result;
    },
    async downloadSavings(params = {}) {
        params.responseType = 'blob';
        let url = route('orders.savings-csv');
        return await axios.get(url, { params });
    },
    async manageCSV(params = {}) {
        params.responseType = 'blob';
        let url = route('orders.manage-csv');
        return await axios.get(url, { params });
    },
    async history(params = {}) {
        let url = route('orders.history', { id: params.id });
        delete params.id;
        let response = await axios.get(url, { params });
        return response.data;
    },
};
