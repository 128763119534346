<template>
    <button @click="$emit('click')" :type="type" :disabled="disabled" :class="['button', buttonClass]">
        <span v-if="icon" class="icon"><i :class="['fa', icon]"></i></span>
        <span>{{ label }}</span>
        <emp-loading-indicator :style-override="loaderStyles" v-if="showLoader" />
    </button>
</template>

<script>
import { buttons } from '../../../components/revamp/elements/mixins';

export default {
    mixins: [buttons],
};
</script>
