import { statuses, validTransitions } from 'statusMaps';
import { sortOrders as sortOrdersByType } from '@/use/sort/statuses';


export default {
    namespaced: true,
    state: {
        statuses: statuses,
        validTransitions: validTransitions,
        inRequest: false,
    },
    mutations: {
        setStatuses(store, payload) {
            store.statuses = payload;
        },
    },
    actions: {
        async getDropdownData({ state, commit }) {
            console.trace('deprecated function getDropdownData called in statuses.js');
            return true;
        },
    },
    getters: {
        getStatusesWhereTypeSorted(state, getters) {
            return (type) => {
                const sorted = [];
                const statuses = getters.statusWhereType(type);

                if (statuses) {
                    sortOrdersByType[type].forEach((statusId) => {
                        const status = statuses.find((status) => status.id === statusId);

                        if (status) {
                            sorted.push(status);
                        }
                    });
                }

                return sorted;
            };
        },
        statusWhereType(state) {
            return (statusType) => {
                return state.statuses.filter((status) => status[statusType]);
            };
        },
        statusWhereId(state) {
            return (statusId) => {
                return state.statuses.filter((status) => status.id == statusId);
            };
        },
        statusName(state, getters) {
            return (statusId) => {
                let status = getters.statusWhereId(statusId);
                return status[0].name;
            };
        },
        getStatusIdsByType(state, getters) {
            return (type) => {
                return sortOrdersByType[type];
            };
        },
        validTransitionsForType(state) {
            return type => {
                return state.validTransitions[type];
            };
        },
    },
};
