export default {
    auth: {
        expiry: 0,
    },
    windowDimensions: { width: 0, height: 0 },
    inventoryManufacturers: [],
    inventoryAddresses: [],
    manufacturersWithProduct: [],
    shoppingListManufacturers: [],
    whitelistedUsers: [],
    whitelistedAddresses: [],
    manufacturersByProductType: {},
    expandedTableLines: {},
};
