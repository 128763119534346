// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import useCacheManager from 'use/cacheManager';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'SavedOrders Dropdowns',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for SavedOrders dropdowns.
 * @returns
 */
export default function useSavedOrderDropdowns(config = {}) {
    const fetcher = async () => {
        const resp = await apis.SavedOrders.listSavedOrders({
            pagination_type: 'simple',
            per_page: -1,
            partials: ['dropdown'],
        });
        return resp.body.data || [];
    };

    const { data: savedOrders, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    useCacheManager(mutate, metadata);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<SavedOrder[]>}
         */
        savedOrders,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
