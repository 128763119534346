<template>
    <div>
        <component
            v-for="(modal, index) in modals"
            :key="index"
            :is="modal.component"
            :class="{ 'is-active': index === modals.length - 1 }"
            :isActive="index === modals.length - 1"
            v-bind="modal.bindings"
            v-on="modal.listeners"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

/**
 * A container for open modals.
 *
 * Only the most recently opened modal is displayed. All others are hidden,
 * but kept in memory so that they can be returned to.
 * Modals should be opened and closed via the `showModal()` and `closeModal()`
 * actions on the Vuex store, `shared/modal`.
 */
export default {
    computed: {
        ...mapState('shared/modal', ['modals']),
    },
};
</script>

<style lang="scss">
.modal {
    &.fullscreen.is-active {
        box-sizing: border-box;
        position: fixed;
        top: 5rem;
        left: 100%;
        width: 100vw;
        height: calc(100vh - 5rem);
        transform: translateX(-100%);

        .modal-content {
            max-width: 75vw;
            width: auto;
            .message-body {
            }
        }
    }
}
</style>
