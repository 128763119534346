export default {
    mapper: {
        relations: {
            hasMany: {
                returnRequest: {
                    foreignKey: 'return_label_url_id',
                    localField: 'returnRequests',
                },
            },
        },
    },
};
