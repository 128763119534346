import { Mapper } from 'js-data';

const DOMAIN = 'Mapper';

class EmpireMapper extends Mapper {
    /**
     *
     * These relationship types correspond 1:1 to those used in Laravel.
     * They currently do not exist in js-data, and we likely do not need
     * to create them as they are mostly inverse relationships or are types
     * that are not worth the cost to create and maintain on the front-end.
     *
     * They are left here so that we have a more complete relationship map
     * for each model, and can implement them if needed.
     *
     * If you need one of these relationship types flesh it out and move it
     * above out of this section.
     *
     * -Toby
     *
     */
    morphToMany(relatedMapper, opts) {
        this.dbg("The 'morphToMany' relationship type has not been fleshed out:", relatedMapper, opts);
    }
    morphTo(relatedMapper, opts) {
        this.dbg("The 'morphTo' relationship type has not been fleshed out:", relatedMapper, opts);
    }
    belongsToMany(relatedMapper, opts) {
        this.dbg("The 'belongsToMany' relationship type has not been fleshed out:", relatedMapper, opts);
    }
    hasManyThrough(relatedMapper, opts) {
        this.dbg("The 'hasManyThrough' relationship type has not been fleshed out:", relatedMapper, opts);
    }
    hasOneThrough(relatedMapper, opts) {
        this.dbg("The 'hasOneThrough' relationship type has not been fleshed out:", relatedMapper, opts);
    }
}

export default EmpireMapper;
