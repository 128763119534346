import UserApi from 'api/endpoints/user';
import FacilityApi from 'api/endpoints/facility/facilities';
import * as types from './mutation_types';

export default {
    async fetchFacilityLCodeManufacturerWhitelist({ commit, rootGetters }) {
        const facilityId = rootGetters['shared/currentUser/facilityId'];
        const whitelist = await FacilityApi.manufacturers.getLCodeSearchWhitelist(facilityId);
        commit(types.SET_LCODE_MANUFACTURER_WHITELIST, whitelist);
    },
};
