import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        if (!params.type) {
            throw Error('Role type is required to perform this request.');
        }
        let url = route('roles.dropdowns', { type: params.type });
        return await axios.get(url, { params });
    },
};
