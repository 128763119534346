export default {
    handleUserEvents({ commit, dispatch }, event) {
        switch(event.type) {
            case 'message.new':
                commit('addMessages', [{inbox: event.data.inbox, ...event.data.message}]);
                break;
            case 'message.change':
                dispatch('updateMessageStatusFromEvent', event.data);
                break;
            case 'notification.change':
                dispatch('updateNotificationStatusFromEvent', event.data);
                break;
            case 'download.change':
                dispatch('updateDownloadStatusFromEvent', event.data);
                break;
            default:
                console.warn("Unknown event type: ", event);
        }
    },

    handleUserNotifications({ commit, dispatch }, notification) {
        switch(notification.type) {
            case 'App\\Notifications\\ReminderDue':
            case 'App\\Notifications\\DownloadNotification':
            case 'App\\Notifications\\ImportFinished':
            case 'App\\Notifications\\JobFailed':
                dispatch("addNotification", notification);
                break;
            default:
                console.warn("Unknown notification type: ", notification);
        }
    },
}
