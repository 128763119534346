import Swagger from 'swagger-client';
import SmartApi from 'SmartApi';
import moment from 'moment';

Swagger.http.withCredentials = true;

let tokenExpiresAt = moment();
let token = null;

let client = null;
let apis = null;
let innerClient = null;

export async function initializeClient() {
    if (!innerClient) {
        innerClient = await Swagger(EDI_API_SPEC_URL, {
            execute: function (options) {
                options.server = __EDI_SERVER_URL__;
                return Swagger.execute(options);
            },
            requestInterceptor: async (request) => {
                let tokenExpired = moment().isSameOrAfter(tokenExpiresAt);

                if (tokenExpired || !token) {
                    let response = await SmartApi.get({
                        routeName: 'edi.token',
                    });

                    tokenExpiresAt = moment(response.data.expires_at);
                    token = response.data.access_token;
                }

                request.headers['Authorization'] = `Bearer ${token}`;
                request.headers['X-Requested-With'] = 'XMLHttpRequest';
                request.headers['Content-Type'] = 'application/json';

                return request;
            },
        });
    }

    client = innerClient;
    apis = innerClient.apis;

    return innerClient;
}

export { client, apis };
