import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            hasMany: {
                extraction: {
                    foreignKey: 'extraction_instruction_id',
                    localField: 'extractions',
                },
            },
            belongsTo: {
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
            },
            morphMany: {
                ...auditsRelation('extractionInstruction'),
            },
        },
    },
};
