import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            morphMany: {
                documentProcessingRequest: {
                    foreignKey: 'processable_id',
                    foreignType: 'processable_type',
                    relatedType: 'uploadedFile',
                    localField: 'documentProcessingRequests',
                },
                ...auditsRelation('uploadedFile'),
            },
        },
    },
};
