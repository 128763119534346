// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import useCacheManager from 'use/cacheManager';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Warehouses Dropdowns',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Warehouses dropdowns.
 * @returns
 */
export default function useWarehouseDropdowns(config = {}) {
    const fetcher = async () => {
        const resp = await apis.Warehouses.listWarehouses({
            pagination_type: 'simple',
            per_page: -1,
            partials: ['dropdown'],
        });
        return resp.body.data || [];
    };

    const { data: warehouses, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    useCacheManager(mutate, metadata);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<Warehouse[]>}
         */
        warehouses,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
