// @ts-check
import Config from './baseConfig';
import prodConfig from './prodConfig';
import VueClip from 'vue-clip';
import VueFlatPickr from 'vue-flatpickr-component';
import VueRouter from 'vue-router';

const { mixins, components, validationRules } = prodConfig;
const axiosRequestConfig = {};
const vueFilters = { '': undefined };
const plugins = [
    { plugin: VueClip, options: [] },
    { plugin: VueFlatPickr, options: [] },
];
export default new Config(
    axiosRequestConfig,
    vueFilters,
    mixins,
    components,
    validationRules,
    plugins,
    new VueRouter({ routes: [], mode: 'abstract' }),
    []
);
