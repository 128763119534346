import { TABLE_STORE, SUBCONTENT_STORE } from 'store/shared/mixins';
import SmartApi from 'SmartApi';
import Vue from 'vue';
import moment from 'moment-timezone';
import { GET_DATA_INCLUDES } from 'ordersConstants';
import ConflictingUpdateResolutionModal from 'component/shared/ConflictingUpdateResolutionModal.vue';
import _ from 'lodash';
import { actionTypes as linePresenceActionTypes } from '../../admin/modules/linePresence/index.js';
import { mixVuexStoreOptions } from 'store/helpers.js';
import { isFulfilled, isRejected } from 'app/asyncHelpers.js';

/**
 * The outcome of a resolved or rejected Promise.
 * @typedef {object} Outcome
 * @property {string} status The status of the outcome. Either fulfilled or rejected.
 * @property {*} reason The reason a Promise was rejected.
 * @property {*} value The value a Promise resolved to.
 */

/**
 * An order Line in its current and original state.
 * @typedef {object} LineOriginal
 * @property {Line} line The Line in its current state.
 * @property {Line} original The Line in its original state.
 */

/**
 * A record of a set of atomic changes to a model.
 * @typedef {object} Audit
 * @property {string} created_at
 * @property {number} id
 * @property {string} event
 * @property {object} new_values
 * @property {object} old_values
 * @property {number} user_id
 */

/**
 * Context provided by the API server when a stale update is detected.
 * @typedef {object} StaleUpdateContext
 * @property {Audit[]} audits The Audits that were created since the stale model was fresh.
 * @property {object} current A map of property names to objects with name and value properties that contain the persisted value for that property.
 * @property {object} previous A map of property names to objects with name and value properties that contain the submitted value for that property.
 */

/**
 * A response from the API server that indicates a stale update
 * @typedef {object} StaleUpdateErrorResponse
 * @property {object} data
 * @property {StaleUpdateContext} data.context
 * @property {string} data.message
 * @property {string} data.type
 */

/**
 * @typedef {ValidationErrorResponse | StaleUpdateErrorResponse | FatalErrorResponse} LineErrorResponse
 */

/**
 * A response from the API server to a request to update a Line.
 * @typedef {LineSuccessResponse | LineErrorResponse} LineResponse
 */

/**
 * The outcome of a Promise that represents a success response from the API server.
 * @typedef {object} SuccessResponseOutcome
 * @property {string} status The status of the resolved Promise.
 * @property {object} value
 * @property {LineSuccessResponse} value.response
 */

/**
 * The outcome of a Promise that represents a failure response from the API server.
 * @typedef {object} FailureResponseOutcome
 * @property {string} status The status of the resolved Promise.
 * @property {object} reason
 * @property {LineErrorResponse} reason.response
 */

/**
 * @typedef {object} Conflict
 * @property {object} current
 * @property {object} previous
 * @property {Line} model
 * @property {number} toVersion
 */

/**
 * The outcome of a Promise that represents a failure response from the API server.
 * @typedef {object} StaleResponseOutcome
 * @property {string} status The status of the resolved Promise.
 * @property {object} reason
 * @property {StaleUpdateErrorResponse} reason.response
 * @property {Conflict} reason.conflicts
 */

/**
 * The outcome of a Promise that represents a response from the API server.
 * @typedef {SuccessResponseOutcome | FailureResponseOutcome | StaleResponseOutcome} ResponseOutcome
 */

/**
 * A response that indicates a successful update and the line the request was for.
 * @typedef {object} LineUpdateSuccess
 * @property {SuccessResponseOutcome} outcome
 * @property {Line} line The Line included in the request.
 */

/**
 * A response that indicates a failed update and the line the request was for.
 * @typedef {object} LineUpdateFailure
 * @property {FailureResponseOutcome} outcome
 * @property {Line} line The Line included in the request.
 */

/**
 * A response that indicates an update failed because the Line was stale and the Line the request was for.
 * @typedef {object} StaleLineUpdateFailure
 * @property {StaleResponseOutcome} outcome
 * @property {Line} line The Line included in the request.
 */

/**
 * A response and the line the request was for.
 * @typedef {LineUpdateSuccess | LineUpdateFailure} LineUpdateResult
 */

/**
 * A set of LineUpdateResult grouped by response type.
 * @typedef {object} LineUpdateResults
 * @property {LineUpdateSuccess[]} successes
 * @property {StaleLineUpdateFailure[]} stales
 * @property {LineUpdateFailure[]} errors
 */

/**
 * The result of calling updateLines().
 * @typedef {object} UpdateLineResult
 * @property {boolean} isSuccess
 * @property {LineUpdateSuccess[]} successes
 * @property {LineUpdateFailure[]} errors
 * @property {StaleLineUpdateFailure[]} stales
 */

const AUTO_SYNC_INTERVAL = 1000 * 60 * 5;

function getEarlierDateNeeded(a, b) {
    if (!a && b) {
        return b;
    } else if (!b && a) {
        return a;
    }

    let aMoment = moment(a.date).tz(a.timezone);
    let bMoment = moment(b.date).tz(b.timezone);

    if (bMoment.isBefore(aMoment)) {
        return b;
    }

    return a;
}

function getEarlierCutoffTime(a, b) {
    if (b > a) {
        return b;
    }

    return a;
}

const STALE_MESSAGE = 'Model has been modified since last read.';

/**
 * Return true if the response's message indicates the request included a stale Line.
 * @param {object} wrapper
 * @param {LineResponse} wrapper.response
 * @returns {boolean}
 */
function hasStaleMessage({ response }) {
    return _.get(response, 'data.message') === STALE_MESSAGE;
}

/**
 * Return true if the outcome's response's contains an error for a stale update.
 * @param {object} wrapper
 * @param {ResponseOutcome} wrapper.outcome An outcome object returned by Promise.allSettled().
 * @returns {boolean}
 */
function isRejectedForBeingStale({ outcome }) {
    const response = _.get(outcome, 'reason.response', {});
    return hasStaleMessage({ response });
}

/**
 * Submit requests to update a sequence of Lines.
 * @param {object} wrapper
 * @param {LineOriginal[]} wrapper.lineOriginals The lines to update with their original values.
 * @param {string[]} wrapper.includes The Lines' relations the server should include in the response.
 * @returns {Promise<LineUpdateResults>}
 */
async function submitLineUpdates({ lineOriginals, includes }) {
    const requests = lineOriginals.map(({ line, original }) => submitLineUpdate({ line, original, includes }));
    /**
     * @type {ResponseOutcome[]}
     */
    const outcomes = await Promise.allSettled(requests);
    const lines = lineOriginals.map(({ line }) => line);
    /**
     * @type {LineUpdateResult[]}
     */
    const resultLines = _.zip(outcomes, lines).map(([outcome, line]) => {
        return { outcome, line };
    });
    /**
     * @type {LineUpdateSuccess[]}
     */
    const successes = resultLines.filter((result) => isFulfilled(result.outcome));
    /**
     * @type {LineUpdateFailure[]}
     */
    const failures = resultLines.filter((result) => isRejected(result.outcome));
    /**
     * @type {StaleLineUpdateFailure[]}
     */
    const stales = failures.filter(isRejectedForBeingStale);
    const errors = failures.filter(_.negate(isRejectedForBeingStale));
    return {
        successes,
        stales,
        errors,
    };
}

/**
 * Submit a request to update a Line.
 * @param {object} wrapper
 * @param {Line} wrapper.line The line to update with the desired state.
 * @param {Line} wrapper.original The line to update in its original, unmodified state.
 * @param {string[]} wrapper.includes A list of relations the server should include in the response.
 * @returns {Promise<LineUpdateResult>}
 */
async function submitLineUpdate({ line, original, includes = GET_DATA_INCLUDES }) {
    /**
     * @type {LineResponse}
     */
    let response;
    line.includes = line.includes || includes;
    try {
        response = await SmartApi.put({
            routeName: 'orders.edit',
            routeParams: [line.id],
            data: line,
        });
    } catch (error) {
        /**
         * @type {LineResponse}
         */
        const response = _.get(error, 'response', {});
        if (hasStaleMessage({ response })) {
            handleStale({ line, original, error });
        }
        throw error;
    }
    return { response, line };
}

/**
 * Update the Line with non-conflicting changes from the most recent version, assemble conflicting properties and their values, re-throw the error.
 * @param {object} wrapper
 * @param {Line} wrapper.line
 * @param {Line} wrapper.original
 * @param {object} wrapper.error
 * @param {StaleUpdateErrorResponse} wrapper.error.response
 */
function handleStale({ line, original, error }) {
    const previous = _.get(error, 'response.data.context.previous', {});
    const current = _.get(error, 'response.data.context.current', {});
    const modified = Object.keys(current).filter((attribute) => {
        return attribute != 'lock_version' && attribute != 'lockVersion';
    });

    const changedByUs = new Set(
        modified.filter((attribute) => {
            return line[attribute] != original[attribute];
        })
    );

    const changedByThem = new Set(
        modified.filter((attribute) => {
            const currentVal = current[attribute].value;
            const localOriginal = original[attribute];
            return localOriginal != currentVal;
        })
    );
    const intersection = modified.filter((attribute) => {
        return changedByUs.has(attribute) && changedByThem.has(attribute);
    });
    // Accept all of their changes that do not conflict with our changes excluding
    // the lock version, which should be set only after the user confirms the conflict
    // changes. Otherwise, they could abort reconciliation, press save again, and
    // overwrite conflicting values without explicitly confirming their intent.
    changedByThem.forEach((attribute) => {
        if (!changedByUs.has(attribute)) {
            line[attribute] = current[attribute].value;
        }
    });

    const toVersion = current.lockVersion.value;
    error.conflicts = {
        previous: _.pick(previous, intersection),
        current: _.pick(current, intersection),
        model: line,
        toVersion,
    };
    throw error;
}

export const MANAGE_FORM = {
    state: {
        resettingManageForm: false,
    },
    mutations: {
        changeManageFormResetState(state, value = true) {
            state.resettingManageForm = value;
        },
    },
};

export const HAS_SIDEBAR = {
    state: {
        sidebarSyncing: false,
        sidebarData: [],
        sidebarLastSync: null,
        sidebarTimer: null,
    },
    actions: {
        startSidebarSyncTimer({ state, dispatch, commit }, syncArgs) {
            let timerId = setTimeout(function () {
                dispatch('syncSidebarData', syncArgs);
            }, AUTO_SYNC_INTERVAL);
            commit('setSidebarSyncTimerId', timerId);
        },
        async syncSidebarData({ state, commit, getters, dispatch }, { routeName, routeParams = {} }) {
            if (state.sidebarSyncing === false) {
                commit('setSidebarSyncing', true);

                let response = await SmartApi.get({
                    routeName,
                    routeParams,
                    config: {
                        hideLoader: true,
                    },
                });

                commit('setSidebarLastSync');
                commit('setSidebarData', response.data);
                commit('setSidebarSyncing', false);
                dispatch('startSidebarSyncTimer', { routeName, routeParams });
            }
        },
    },
};

export const ORDERS_TABLE = mixVuexStoreOptions(TABLE_STORE, SUBCONTENT_STORE, {
    state: {
        chosenWarehouse: {},
        lineErrors: {},
        changedLines: new Set(),
        unsavedLineIds: new Set(),
        mutableLines: [],
    },
    /**
     * @type {import("vuex").ActionTree<typeof state>}
     */
    actions: {
        updateLineReturnInformation({ commit, getters }, { id, trackingNumber, returnLabel, statusId, status }) {
            let lines = {
                original: _.clone(getters.getLineById(id)),
                mutable: _.clone(getters.getMutableLineById(id)),
            };

            const lineKeys = Object.keys(lines);

            for (let key of lineKeys) {
                lines[key].trackingNumbers.push(trackingNumber);
                lines[key].returnLabel = returnLabel;
                lines[key].statusId = statusId;
                lines[key].status = status;
            }

            commit('setOriginalLine', lines.original);
            commit('setMutableLine', lines.mutable);
        },
        updateLineShippingLabelsInformation({ commit, getters }, { id, shippingLabel, trackingNumber }) {
            shippingLabel.trackingNumber = trackingNumber;

            let lines = {
                original: _.clone(getters.getLineById(id)),
                mutable: _.clone(getters.getMutableLineById(id)),
            };

            const lineKeys = Object.keys(lines);

            for (let key of lineKeys) {
                lines[key].shippingLabels.push(shippingLabel);
            }

            commit('setOriginalLine', lines.original);
            commit('setMutableLine', lines.mutable);
        },
        addNoteToOrder({ commit, getters }, note) {
            let lines = {
                original: _.clone(getters.getLineById(note.noteableId)),
                mutable: _.clone(getters.getMutableLineById(note.noteableId)),
            };
            if (lines.original && lines.mutable) {
                const lineKeys = Object.keys(lines);

                for (let key of lineKeys) {
                    lines[key].notes.unshift(note);
                }

                commit('setOriginalLine', lines.original);
                commit('setMutableLine', lines.mutable);
            }
        },
        async get({ state, commit, dispatch }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);

            const response = await SmartApi.get({
                routeName: 'orders',
                config: {
                    params,
                },
            });

            let pagination = {};

            const lines = response.data.data;

            commit('setOrdersData', lines);

            if (response.data.meta) {
                pagination = response.data.meta.pagination;
            }

            commit('setPaginationData', pagination);
        },
        /**
         * Submit a request to update a Line.
         * @param {import("vuex").ActionContext} actionContext
         * @param {Line} line
         * @returns {Promise<LineSuccessResponse>}
         */
        async updateLine({ dispatch }, line) {
            /**
             * @type {UpdateLineResult}
             */
            let result = await dispatch('updateLines', { lines: [line] });
            if (result.errors.length) {
                throw result.errors[0].outcome.reason;
            }
            return result.successes[0].outcome.value.response;
        },
        /**
         * Submit requests to update multiple Lines.
         * @param {import("vuex").ActionContext} actionContext
         * @param {{lines: Line[]}} linesWrapper
         * @returns {Promise<UpdateLineResult>}
         */
        async updateLines({ getters, commit, dispatch }, { lines }) {
            const lineOriginals = lines.map((line) => {
                return {
                    line: { ...line },
                    /** @type {Line} */
                    original: getters.getLineById(line.id),
                };
            });
            const { successes, stales, errors } = await submitLineUpdates({
                lineOriginals,
            });
            successes
                .map(
                    /**
                     * @param {LineUpdateSuccess} lineUpdateSuccess
                     * @returns {Line}
                     */
                    ({ outcome }) => _.get(outcome, 'value.response.data.data', {})
                )
                .forEach((line) => {
                    commit('removeLineFromUnsavedList', line.id);
                    commit('setOriginalLine', { ...line });
                    commit('setMutableLine', _.cloneDeep(line));
                    dispatch('shared/quickbar/updateItem', { ...line }, { root: true });
                });
            const isSuccess = errors.length == 0 && stales.length == 0;
            const result = {
                isSuccess,
                successes,
                errors,
                stales,
            };
            if (isSuccess || errors.length > 0) {
                return result;
            }
            const conflicts = _.map(stales, (stale) => {
                return stale.outcome.reason.conflicts;
            });
            // Return a Promise that will be resolved when the user
            // closes the ConflictingUpdateResolutionModal. This allows callers of this
            // function to await its response and define success and error
            // handling that will occur after the user closes the ConflictingUpdateResolutionModal.
            return new Promise(async (resolve, reject) => {
                await dispatch(
                    'shared/modal/showModal',
                    {
                        component: ConflictingUpdateResolutionModal,
                        data: {
                            conflicts,
                            /**
                             *
                             * @param {Conflict[]} conflicts
                             */
                            onConfirm: async (conflicts) => {
                                // Update the line's lock version to the lockVersion reported as current
                                // by the API server.
                                conflicts.forEach((conflict) => {
                                    conflict.model.lockVersion = conflict.toVersion;
                                });
                                const nextAttempt = await dispatch('updateLines', {
                                    lines: conflicts.map(({ model }) => model),
                                });
                                if (nextAttempt && nextAttempt.isSuccess) {
                                    resolve(nextAttempt);
                                } else {
                                    reject(nextAttempt);
                                }
                            },
                            onCancel: () => {
                                reject(result);
                            },
                        },
                        bindings: {
                            'model-name': 'Order Line',
                        },
                    },
                    {
                        root: true,
                    }
                );
            });
        },
        setLineErrorsByIndex({ state, commit }, { lineIndex = null, value = [] }) {
            let lineId = null;

            if (lineIndex !== null) {
                lineId = state.data[lineIndex].id;
            }

            commit('setLineErrors', { lineId, value });
        },
    },
    getters: {
        groupedData(state, getters) {
            let data = {};

            switch (state.organizeBy) {
                case 'vendor_name':
                    data = getters.groupedByVendor;
                    break;
                case 'shipping_address_name':
                    data = getters.groupedByShippingAddressName;
                    break;
                case 'vendor_cutoff_time':
                    data = getters.groupedByVendorCutoffTime;
                    break;
            }

            return data;
        },
        groupedByVendor(state, getters) {
            let grouped = state.data.reduce((groupedLines, line) => {
                let vendorName = _.get(line, 'vendor.name', 'Various Supplier');

                if (groupedLines.hasOwnProperty(vendorName) === false) {
                    groupedLines[vendorName] = [];
                }

                groupedLines[vendorName].push(line);

                return groupedLines;
            }, {});

            let ordered = {};

            Object.keys(grouped)
                .sort((a, b) => a.localeCompare(b))
                .forEach(function (key) {
                    ordered[key] = grouped[key];
                });

            return ordered;
        },
        groupedByShippingAddressName(state, getters) {
            let grouped = state.data.reduce((groupedLines, line) => {
                let shippingAddressName = _.get(line, 'shippingAddress.name', 'Unknown');

                if (groupedLines.hasOwnProperty(shippingAddressName) === false) {
                    groupedLines[shippingAddressName] = [];
                }

                groupedLines[shippingAddressName].push(line);

                return groupedLines;
            }, {});

            let ordered = {};
            Object.keys(grouped)
                .sort((a, b) => a.localeCompare(b))
                .forEach(function (key) {
                    ordered[key] = grouped[key];
                });

            return ordered;
        },
        groupedByVendorCutoffTime(state, getters) {
            return state.data.reduce((groupedLines, line) => {
                let cutoffTimeValue = _.get(line, 'vendor.cutoffTimeId', 'Unknown');

                if (groupedLines.hasOwnProperty(cutoffTimeValue) === false) {
                    groupedLines[cutoffTimeValue] = [];
                }

                groupedLines[cutoffTimeValue].push(line);

                return groupedLines;
            }, {});
        },
        getLineById(state) {
            return (id) => {
                return state.data.find((line) => line.id == id);
            };
        },
        getMutableLineById(state) {
            return (id) => {
                return state.mutableLines.find((line) => line.id == id);
            };
        },
        lineHasUnsavedChanges(state) {
            return (lineId) => state.unsavedLineIds.has(lineId);
        },
        getErrorsForLine(state) {
            return (id) => {
                return state.lineErrors[id] || {};
            };
        },
    },
    mutations: {
        setSidebarSyncTimerId(state, timerId) {
            state.sidebarTimer = timerId;
        },
        clearSidebarSyncTimer(state) {
            clearTimeout(state.sidebarTimer);
            state.sidebarTimer = null;
        },
        setSidebarLastSync(state) {
            state.sidebarLastSync = moment();
        },
        setSidebarData(state, data) {
            state.sidebarData = data;
        },
        setSidebarSyncing(state, value) {
            state.sidebarSyncing = value;
        },
        addLineToUnsavedList(state, lineId) {
            state.unsavedLineIds.add(lineId);
            let updatedSet = new Set(state.unsavedLineIds);
            Vue.set(state, 'unsavedLineIds', updatedSet);
        },
        removeLineFromUnsavedList(state, lineId) {
            state.unsavedLineIds.delete(lineId);
            let updatedSet = new Set(state.unsavedLineIds);
            Vue.set(state, 'unsavedLineIds', updatedSet);
        },
        clearUnsavedLines(state) {
            state.unsavedLineIds = new Set();
        },
        setOrganizeBy(state, value) {
            state.organizeBy = value;
        },
        setChosenWarehouse(state, warehouse) {
            state.chosenWarehouse = warehouse;
        },
        setLineErrors(state, { lineId = null, value = [] }) {
            if (lineId in state.lineErrors) {
                value = _.merge({}, state.lineErrors[lineId], value);
            }

            if (lineId !== null) {
                Vue.set(state.lineErrors, lineId, value);
            }
        },
        clearLineErrors(state, lineId = null) {
            if (lineId === null) {
                state.lineErrors = {};
            } else if (lineId in state.lineErrors) {
                Vue.delete(state.lineErrors, lineId);
            }
        },
        removeLine(state, id) {
            state.data = _.filter(state.data, (line) => line.id != id);
            state.mutableLines = _.filter(state.mutableLines, (line) => line.id != id);
        },
        setOriginalLine(state, payload) {
            let lineIndex = _.findIndex(state.data, (line) => line.id == payload.id);
            let payloadKeys = Object.keys(payload);

            for (let key of payloadKeys) {
                Vue.set(state.data[lineIndex], key, payload[key]);
            }
        },
        setMutableLine(state, updatedLine) {
            const index = _.findIndex(state.mutableLines, (line) => line.id == updatedLine.id);
            state.mutableLines.splice(index, 1, updatedLine);
        },
        setOrdersData(state, payload) {
            state.data = _.cloneDeep(payload);
            state.mutableLines = payload;
        },
        setOrdersAdditionalData(state, payload) {
            var vendorArray = state.data[payload.vendor.data.name].data;
            var index = _.findIndex(vendorArray, { id: payload.id });
            vendorArray.splice(index, 1, payload);
            state.data[payload.vendor.data.name].data = vendorArray;
        },
    },
});
