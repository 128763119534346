<template>
    <empire-select
        :disabled="states.length == false || disabled"
        :fieldClass="fieldClass"
        :inputClass="{ 'is-loading': states.length == false }"
        label="State"
        :labelClass="labelClass"
        :options="states"
        :placeholder="placeholderToPass"
        valueFrom="id"
        :value="value"
        @input="$emit('input', $event)"
        :errors="errors"
        :name="name"
    />
</template>

<script>
import { input, hasOptions } from './mixins';
const Select = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/Select');
import { States } from 'enums';
import { getFriendlyName } from 'app/enumHelpers';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    mixins: [input],
    components: {
        'empire-select': Select,
    },
    props: {
        placeholder: {
            type: [String, Boolean],
            default: '',
        },
        useEmptyAsPlaceholder: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        placeholderToPass() {
            if (this.useEmptyAsPlaceholder) {
                return false;
            }
            return this.placeholder;
        },
        states() {
            let states = [];
            if (this.useEmptyAsPlaceholder) {
                states.push({ id: States.EMPTY, name: '' });
            }
            for (const property in States) {
                if (property == 'EMPTY') {
                    continue;
                }
                states.push({ id: States[property], name: getFriendlyName(property) });
            }
            return states;
        },
    },
});
</script>
