import { UPDATE_BY_LINE_ID } from 'revamp-mutations';

export default {
    namespaced: true,
    actions: {
        feeScheduleUpdated({ commit }, rowData) {
            commit(`revamp/table/${UPDATE_BY_LINE_ID}`, rowData, { root: true });
        },
    },
};
