import BugsnagPluginVue from '@bugsnag/plugin-vue';

export default {
    apiKey: 'c1437e07d8a643c44a7cda3a09525c70',
    enabledReleaseStages: ['production', 'stage'],
    releaseStage: APP_ENV,
    appVersion: VERSION,
    plugins: [new BugsnagPluginVue()],
    redactedKeys: ['notes', /^password$/i],
    onError: (event) => {
        return event.originalError.bugsnagShouldIgnore !== true;
    },
};
