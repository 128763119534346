import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}, config = {}) {
        let url = params.id ? route('vendors.show', { id: params.id }) : route('vendors');
        delete params.id;
        return await axios.get(url, { params, ...config });
    },
    async delete(id) {
        let url = route('vendors.destroy', { id: id });
        return await axios.delete(url);
    },
    async update(payload) {
        if (payload.audits) {
            delete payload.audits;
        }
        let url = route('vendors.edit', { id: payload.id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async updateStatus(payload) {
        let url = route('vendors.update-status', [payload.idString]);
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('vendors.create');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async dropdownData(params = {}, config = {}) {
        let url = route('vendor.dropdowns');
        return await axios.get(url, { params, ...config });
    },
    async getVendorFields(params = {}) {
        let url = route('vendors.fields', { vendor: params.id });
        delete params.id;
        return await axios.get(url, { params });
    },
};
