// @ts-check

import { ref } from '@vue/composition-api';

const cached = new Map();

/**
 * Setup cache/refresh for inventory Locations
 * @returns
 */
export default function useCacheManager(mutateFunction, metadata) {
    if (mutateFunction && metadata) {
        cached.set(metadata.name, {
            mutate: mutateFunction,
            metadata: metadata,
        });
    }

    const cachedItems = ref(cached);

    return {
        cachedItems,
    };
}
