// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import CreditCardApi from 'api/endpoints/admin/creditCards';
import useSwrvState from './swrvState';
import { computed } from '@vue/composition-api';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Credit Cards',
    uuid: getUID(),
};

/**
 * Setup data synchronization for CreditCards
 */
export default function useCreditCards(config = {}) {
    const fetcher = async () => {
        return await CreditCardApi.get({}, { hideLoader: true });
    };

    config = {
        // Cache credit cards for the lifetime of the client by
        // setting a TTL of 0 and an infinite dedupingInterval.
        // Credit cards are not currently modifiable at runtime,
        // so the client does not need to refresh them.
        ttl: 0,
        dedupingInterval: Infinity,
        revalidateOnFocus: false,
        ...config,
    };
    const { data: creditCards, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    const { state, STATES } = useSwrvState(creditCards, error, isValidating);
    const isLoading = computed(() => {
        return state.value == STATES.PENDING;
    });
    return {
        /**
         * @type {import('@vue/composition-api').Ref<CreditCard[]>}
         */
        creditCards,
        error,
        isValidating,
        mutate,
        isLoading,
    };
}
