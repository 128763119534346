import axios from 'axios';
import store from 'store';

async function reloadUser() {
    await store.dispatch('shared/currentUser/getData');
    const resolvedUser = store.state.shared.currentUser.resolvedUser;
    const storedUser = store.state.shared.currentUser.data;

    if (storedUser.id && resolvedUser.id !== storedUser.id) {
        window.location.href = '/app';
    } else {
        store.dispatch('shared/currentUser/setUserData', resolvedUser);
    }
}

export default class Interceptor {
    constructor(router) {
        this.router = router;
    }

    intercept() {
        this.interceptClientRequest();
        this.interceptServerResponse();
    }

    interceptClientRequest() {
        axios.interceptors.request.use(
            (config) => {
                if (config.method === 'get' && config.params && config.params.hasOwnProperty('sort')) {
                    let sort = config.params.sort;
                    if (!Array.isArray(sort)) {
                        sort = [sort];
                    }
                    config.params.sort = sort.filter((s) => s.length > 0).join(',');
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    interceptServerResponse() {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response) {
                    if (!error.response.config?.suppressErrorAlert) {
                        if (error.response.data?.type === 'alert_error' && error.response.data?.message) {
                            store.dispatch('shared/toast/show', {
                                content: error.response.data.message,
                                isDanger: true,
                                errors: error.response.data.errors,
                            });
                        }
                    }

                    if (error.response.status === 500) {
                        store.dispatch('shared/toast/show', {
                            content: 'A system error has occurred. Please try again or contact customer support.',
                            isDanger: true,
                        });
                    }

                    if (error.response.status === 401) {
                        store
                            .dispatch('shared/toast/show', {
                                content: 'You are not logged in. Redirecting to the login page.',
                                isDanger: true,
                            })
                            .then(() => store.dispatch('shared/auth/LOGOUT'))
                            .then(setTimeout(() => this.router.push({ name: 'login' }), 1000));
                    }

                    if (error.response.status === 403) {
                        store.dispatch('shared/toast/show', {
                            content:
                                "You currently don't have permission to access this feature. Contact either your facility administrator or Empire for help.",
                            isDanger: true,
                        });
                        store.dispatch('shared/modal/closeModal');
                        reloadUser();
                    }
                }

                return Promise.reject(error);
            }
        );
    }
}
