<template>
    <div :class="['quickbar_tab', 'pretty-scrollbars', 'scroll-y', { visible: visible, active: active }]">
        <div class="quickbar_tab_content" v-show="active">
            <div class="header-menu" v-if="hasHeaderMenuSlot">
                <slot name="header-menu"></slot>
            </div>
            <template v-if="heading">
                <h3 class="subtitle">{{ heading }}</h3>
            </template>
            <div class="quickbar-inner-content">
                <template v-if="number > 0 && hasStaticContentSlot">
                    <div class="quickbar_dynamic_content">
                        <slot name="staticContent"> </slot>
                    </div>
                </template>
                <template v-if="number > 0 && hasDynamicContentSlot">
                    <h5 class="title is-5 quickbar_dynamic_content_title">Selected Items:</h5>
                    <div class="quickbar_dynamic_content">
                        <slot name="dynamicContent" v-for="(item, index) in items" :item="item" :index="index"> </slot>
                        <hr />
                    </div>
                </template>
                <div class="quickbar_actions" :class="ifDisabled">
                    <slot name="action"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        heading: {
            type: String,
        },
        items: {
            default: () => [],
        },
        type: {
            type: String,
        },
        tabName: {
            type: String,
            default: () => 'SidebarForm',
        },
        tabIcon: {
            type: String,
            default: () => 'fas fa-edit',
        },
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions('shared/quickbar/', {
            registerQuickbarTab: 'registerTab',
            unregisterQuickbarTab: 'unregisterTab',
            updateQuickbarTab: 'updateTab',
        }),
    },
    computed: {
        ...mapState('shared/quickbar', { visible: 'visible', activeTab: 'activeTab' }),

        active() {
            return this.activeTab == this.tabName;
        },
        quickbarTabTitle() {
            return this.heading || this.type;
        },
        number() {
            return this.items.length;
        },
        ifDisabled() {
            if (this.number > 0) {
                return '';
            } else {
                return 'disabled';
            }
        },
        hasHeaderMenuSlot() {
            return !!this.$slots['header-menu'];
        },
        hasContent() {
            return Object.keys(this.$scopedSlots).length || Object.keys(this.$slots).length;
        },
        hasStaticContentSlot() {
            return !!this.$slots['staticContent'];
        },
        hasDynamicContentSlot() {
            return this.$scopedSlots.hasOwnProperty('dynamicContent');
        },
    },
    watch: {
        number: {
            handler(newValue) {
                this.updateQuickbarTab({ tabName: this.tabName, values: { badge: newValue } });
            },
            immediate: true,
        },
        quickbarTabTitle: {
            handler(newValue) {
                this.updateQuickbarTab({ tabName: this.tabName, values: { title: newValue } });
            },
            immediate: true,
        },
    },
    mounted() {
        this.registerQuickbarTab({
            name: this.tabName,
            title: this.quickbarTabTitle,
            badge: this.number,
            icon: this.tabIcon,
            drawerWidth: '375px',
            allowResize: false,
            shade: false,
            component: null,
        });
    },
    beforeDestroy() {
        this.unregisterQuickbarTab(this.tabName);
    },
};
</script>

<style lang="scss">
.header-menu {
    margin-bottom: 1em;
}

h3.subtitle {
    background-color: #7b7e85;
    color: #fff;
    font-size: 1.5em;
    padding: 0.2em 1em;
}

.quickbar-inner-content {
    color: #e8e8e8;
    padding: 1.2em;
    position: relative;
}

.quickbar_dynamic_content_title {
    color: #fff;
    margin: 0 0 1em;
}

.quickbar_dynamic_content {
    background-color: #565d5f;
    color: #fff;
    border-radius: 3px;
    max-height: 50vh;
    margin-bottom: 1em;
    overflow: auto;
    padding: 0.5em;

    .element {
        margin-top: 10px;
        line-height: 130%;

        &:first-child {
            margin-top: 0px;
        }
    }
}

.quickbar_tab {
    position: fixed;
    top: 0;
    right: 0;
    width: 375px;
    height: 100%;
    z-index: 19;
    -webkit-transition: all ease-out 200ms;
    -moz-transition: all ease-out 200ms;
    -o-transition: all ease-out 200ms;
    transition: all ease-out 200ms;
    transform: translateX(100%);
    padding-top: 6rem;
    pointer-events: none;
    background: transparent;

    &.visible {
        transform: translateX(0);
    }

    &.active {
        pointer-events: auto;
    }

    .quickbar_actions .field {
        text-align: center;
        button {
            display: block;
            width: 90%;
            margin: 10px auto;
        }
    }

    .quickbar_actions {
        .button {
            margin: 5px 0;
            width: 100%;
        }

        &.disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    }
}
.quickbar_tab_content {
    pointer-events: all;
}
</style>
