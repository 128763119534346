import * as mutationTypes from './mutation_types';
import * as actionTypes from './action_types';
import SmartApi from 'SmartApi';
import { getCookie } from 'helpers';

export default {
    [actionTypes.LOGIN]: async ({ commit, dispatch }, { email, password, remember }) => {
        // A CHECK_AUTH is required to obtain a session cookie, `laravel_session`,
        // and a CSRF token cookie, `XSRF-TOKEN`. Both are used to prevent login CSRF.
        await dispatch(actionTypes.CHECK_AUTH);
        commit(mutationTypes.AUTH_REQUEST);
        let response;
        try {
            response = await SmartApi.post({
                routeName: 'login',
                data: { email, password, remember, deviceId: getCookie('_logoutCookie')},
                config: { hideLoader: true },
            });
        } catch (error) {
            commit(mutationTypes.AUTH_ERROR);
            throw error;
        }
        const { expiry, user } = response.data;
        commit(mutationTypes.AUTH_SUCCESS, { expiry });
        commit('shared/currentUser/setResolvedUser', user, { root: true });
        await dispatch('shared/currentUser/setUserData', user, { root: true });
    },
    [actionTypes.CHECK_AUTH]: async ({ commit, dispatch }) => {
        let response;
        try {
            response = await SmartApi.get({
                routeName: 'is_authenticated',
                config: {
                    hideLoader: true,
                    params: {
                        deviceId: getCookie('_logoutCookie'),
                    }
                },
            });
        } catch (error) {
            throw error;
            // TODO handle error. The endpoint should return a 200 even if
            //      the user is not authenticated, so an error is likely
            //      a network problem.
        }
        let { authenticated, expiry, user } = response.data;
        if (authenticated) {
            commit(mutationTypes.AUTH_SUCCESS, { expiry });
        } else {
            commit(mutationTypes.AUTH_UNAUTHENTICATED);
        }
        // user will be `null` if unauthenticated.
        user = user || {};
        commit('shared/currentUser/setResolvedUser', user, { root: true });
        await dispatch('shared/currentUser/setUserData', user, { root: true });
    },
    [actionTypes.LOGOUT]: async ({ commit, dispatch, getters, rootGetters }, params = {}) => {
        if (getters.isLoggedOut || getters.isLoading) {
            return;
        }
        try {
            commit(mutationTypes.AUTH_BEGIN_LOGOUT);
            await SmartApi.post({
                routeName: 'logout',
                routeParams: params
            });
            commit('shared/currentUser/setFallbackUserUpdater', null, { root: true });
        } catch (error) {
            throw error;
            // TODO handle error. There are very few legitmate reasons
            //      a response from this endpoint should error.
        } finally {
            commit(mutationTypes.AUTH_LOGOUT);
            commit('shared/currentUser/setResolvedUser', {}, { root: true });
            await dispatch('shared/currentUser/setUserData', {}, { root: true });
            dispatch('shared/echo/disconnect', null, { root: true });
        }
    },
};
