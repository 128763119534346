import _ from 'lodash';
import FacilityApi from 'api/endpoints/admin/facilities';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';

// Why does this file exists? [DJF]
export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        default_sort: ['name'],
    },
    actions: {
        get({ state, commit }, id = undefined) {
            let params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, { id });

            FacilityApi.get(params).then((response) => {
                commit('setData', response.data.data);

                if (response.data.meta) {
                    commit('setPaginationData', response.data.meta.pagination);
                }
            });
        },
    },
    getters: {
        api() {
            return FacilityApi;
        },
    },
});
