// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import { computed } from '@vue/composition-api';
import useCacheManager from 'use/cacheManager';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Address Dropdowns',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Address "dropdowns."
 * @returns
 */
export default function useAddressDropdowns(config = {}) {
    const fetcher = async () => {
        const resp = await apis.Addresses.listAddresses({
            pagination_type: 'simple',
            per_page: -1,
            partials: ['dropdown'],
            sort: 'name',
        });
        return resp.body.data || [];
    };

    const { data: addresses, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    if (!addresses.value) {
        addresses.value = [];
    }

    /**
     * @type {import('@vue/composition-api').ComputedRef<AddressV2[]>}
     */
    const costCenters = computed(() => {
        return addresses.value.filter(({ is_cost_center }) => is_cost_center);
    });
    /**
     * @type {import('@vue/composition-api').ComputedRef<AddressV2[]>}
     */
    const shippingAddresses = computed(() => {
        return addresses.value.filter(({ is_ship_to }) => is_ship_to);
    });

    useCacheManager(mutate, metadata);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<AddressV2[]>}
         */
        addresses,
        costCenters,
        shippingAddresses,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
