<template>
    <div
        class="modal"
        :class="{
            'is-active': isActive,
        }"
    >
        <div class="modal-background" @click="close"></div>
        <div class="modal-content">
            <div class="message" :class="classToUse">
                <div class="message-header">
                    <p>{{ title }}</p>
                </div>
                <div class="message-body">
                    <div class="message-body-content">
                        {{ content }}
                    </div>
                    <div class="message-body-buttons">
                        <emp-button @click="close" :buttonClass="classToUse" label="Close" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        close() {
            this.$store.dispatch('shared/alert/close');
        },
    },
    computed: {
        classToUse() {
            if (this.$store.state.shared.alert.isSuccess) {
                return 'is-success';
            }
            if (this.$store.state.shared.alert.isDanger) {
                return 'is-danger';
            }
            return '';
        },
        isActive() {
            return this.$store.state.shared.alert.isActive;
        },
        title() {
            return this.$store.state.shared.alert.title;
        },
        content() {
            return this.$store.state.shared.alert.content;
        },
    },
};
</script>

<style lang="scss" scoped>
.message-body {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
}

.message-body-content {
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.message-body-buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
}
</style>
