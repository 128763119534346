import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}, config = {}) {
        let url = params.id ? route('bills.show', { id: params.id }) : route('bills');

        delete params.id;
        return await axios.get(url, { params, ...config });
    },
    async delete(id) {
        let url = route('bills.destroy', { id: id });
        return await axios.delete(url);
    },
    async update(id, payload) {
        let url = route('bills.edit', { id: id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('bills.create');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async createInvoices(payload) {
        let url = route('bills.invoices.create');
        return await axios.post(url, payload);
    },
    async downloadCSV(params = {}) {
        let url = route('bills.download.csv');
        return await axios.get(url, { params }, { responseType: 'blob' });
    },
    async changeStatus(payload) {
        let url = route('bills.status.edit');
        return await axios.post(url, payload);
    },
};
