export default {
    mapper: {
        relations: {
            belongsTo: {
                bill: {
                    foreignKey: 'bill_id',
                    localField: 'bill',
                },
                line: {
                    foreignKey: 'line_id',
                    localField: 'line',
                },
            },
        },
    },
};
