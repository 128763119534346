import _ from 'lodash';
import { TABLE_STORE } from 'store/shared/mixins';
import actions from './actions';
import getters from './getters';
import state from './state';
import mutations from './mutations';
import * as actionTypes from './action_types';
import * as mutationTypes from './mutation_types';

export { actionTypes, mutationTypes };
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
});
