export function makeGuard(router) {
    return async function (to, from) {
        const store = router.app.$store;
        store.commit('shared/system/setPageTitle', to);
        store.commit('shared/system/setPreviousRoute', from);
    };
}

function apply(router) {
    router.afterEach(makeGuard(router));
}

export default {
    apply,
};
