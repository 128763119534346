import _ from 'lodash';
import { HAS_SIDEBAR, ORDERS_TABLE } from 'store/admin/mixins/orders';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, HAS_SIDEBAR, ORDERS_TABLE, {
    namespaced: true,
    state: {
        moduleName: 'OrdersReview',
    },
});
