<template>
    <ul class="entry-detail">
        <li class="entry-detail-item" v-for="(item, index) in details" :key="index">
            <div class="entry-field">
                <strong>{{ item.field }}</strong>
            </div>
            <div class="entry-title">From</div>
            <pre class="entry-value" v-html="item.from"></pre>
            <div class="entry-title">To</div>
            <pre class="entry-value" v-html="item.to"></pre>
        </li>
    </ul>
</template>

<script>
import HistoryEntryDetail from './HistoryEntryDetail';
import moment from 'moment-timezone';
import { titleCase } from 'helpers';

export default {
    props: {
        detail: {
            type: Object,
            required: true,
        },
    },
    methods: {
        convertData(data) {
            if (
                data &&
                typeof data === 'object' &&
                data.hasOwnProperty('date') &&
                data.hasOwnProperty('timezone') &&
                data.hasOwnProperty('timezone_type')
            ) {
                return this.fromDateObject(data.date);
            }
            if (data === null) {
                return '-empty-';
            }
            return this.sanitizeHTML(data);
        },
        convertKey(key) {
            return titleCase(key.replace(/[_]+/g, ' '));
        },
    },
    computed: {
        details() {
            return Object.keys(this.detail).reduce((accumulator, key) => {
                const isId = key.endsWith('_id');
                const nameKey = key.replace(/_id$/, '_name');
                const hasNameKey = this.detail.hasOwnProperty(nameKey);
                if (!isId || !hasNameKey) {
                    accumulator.push({
                        field: this.convertKey(key),
                        from: this.convertData(this.detail[key].old),
                        to: this.convertData(this.detail[key].new),
                    });
                }
                return accumulator;
            }, []);
        },
    },
};
</script>

<style lang="scss" scoped>
.entry-detail-item {
    padding: 1rem 2rem;

    .entry-title {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .entry-value {
        white-space: pre-wrap;
        padding: 0.5rem;
    }
}
</style>
