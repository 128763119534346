// @ts-check
import { computed } from '@vue/composition-api';
import store from 'store';

export default function savedOrders() {
    let orderName = computed(() => store.state.shared.orderFormOrder.order.name);

    // The name is stored in OrderFormOrder, which makes this
    // feel a little off. But as we move into the composition API
    // the Saved Order Name should be handled by this composition function. [DJF]
    const savedOrderName = computed({
        get() {
            return orderName.value;
        },
        set(value) {
            store.dispatch('shared/orderFormOrder/updateOrder', { attribute: 'name', value });
        },
    });

    const savedOrderId = computed({
        set: (value) => {
            store.commit('shared/orderFormOrder/setSavedOrderId', value);
        },
        get: () => {
            return store.state.shared.orderFormOrder.savedOrderId;
        },
    });

    function saveOrder() {
        return store.dispatch('shared/orderFormOrder/saveOrder');
    }

    function loadOrder(payload) {
        return store.dispatch('shared/orderFormOrder/loadOrder', payload);
    }

    async function deleteAllSavedOrders() {
        return store.dispatch('shared/savedOrders/deleteAll');
    }

    async function deleteSavedOrder(id) {
        return store.dispatch('shared/savedOrders/delete', { id });
    }

    /**
     *
     * @param {Number} newId
     */
    function setSavedOrderId(newId) {
        savedOrderId.value = newId;
    }

    return {
        savedOrderId,
        savedOrderName,
        saveOrder,
        loadOrder,
        deleteAllSavedOrders,
        deleteSavedOrder,
        setSavedOrderId,
    };
}
