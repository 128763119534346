import * as types from "./mutation_types.js";

export default {
    [types.SET_DOCUMENT_PROCESSORS]: (state, { documentProcessors }) => {
        state.documentProcessors = documentProcessors;
    },
    [types.SET_IS_LOADING]: (state, { isLoading }) => {
        state.isLoading = isLoading;
    }
};
