export const STATUS_MESSAGES = {
    91: 'Waiting',
    69: 'Ordered',
    62: 'Reviewed',
    113: 'Closed',
    10: 'Deleted',
};

export const ORDERS_REVIEW_SIDEBAR_SYNC_ARGS = {
    routeName: 'lines.pending-review.sidebar-entries',
};

export const ORDERS_MANAGE_SIDEBAR_SYNC_ARGS = {
    routeName: 'lines.open.sidebar-entries',
};

export const QUICKBAR_TAB_MANAGE = 1;
export const QUICKBAR_TAB_REVIEW = 2;

export const EMPTY_MANAGE_FORM = {
    id: null,
    confirmationNumber: null,
    notes: null,
    shippingMethod: null,
    shippingAmount: null,
    orderMethod: null,
    accountType: 'empire',
    includePatientNames: false,
    lines: [],
    password: null,
};

export const EDI_ORDER_METHOD_ID = 7;

export const ORDER_METHOD_OPTIONS = [
    { value: 1, name: 'Email' },
    { value: 2, name: 'Phone' },
    { value: 3, name: 'Vendor Website' },
    { value: 4, name: 'Fax' },
    { value: 5, name: 'Customer Direct Order' },
    { value: 7, name: 'EDI' },
];

export const ACCOUNT_TYPE_OPTIONS = [
    { value: 'empire', name: 'Empire Account #' },
    { value: 'customer', name: 'Customer Account #' },
];

export const GET_DATA_INCLUDES = [
    'adminOrderedBy',
    'costCenter.notes',
    'costCenter.vendors',
    'costCenter',
    'costCenterVendor',
    'facility.vendors',
    'facility.notes',
    'isInventoryPart',
    'manufacturer.notes',
    'manufacturer.shippingServices',
    'manufacturer.warehouses',
    'manufacturer',
    'notes',
    'patients',
    'practitioner',
    'product.inventoryRecords',
    'product.manufacturer',
    'product.notes',
    'product.priceExceptions',
    'product.vendor',
    'purchaseOrder',
    'reviewedBy',
    'shippingAddress.notes',
    'shippingAddress.vendors',
    'shippingAddress',
    'shippingLabels',
    'shippingLabels.trackingNumber',
    'trackingNumbers',
    'userOrderedBy',
    'userReceivedBy',
    'vendor.notes',
    'vendor.representatives',
    'vendor.shippingServices',
    'vendor.warehouses',
    'vendor',
    'warehouse',
];

export const ENTITY_TYPE = {
    MANUFACTURER: 'manufacturer',
    VENDOR: 'vendor',
};
