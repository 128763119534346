export default {
    data: [],
    pagination: {
        page: 1,
        per_page: 25
    },
    sort: "name",
    default_sort: "name",
    isLoading: false
};
