// @ts-check
import { apis } from 'api/client';
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { Times } from 'enums';
import { getNameById } from 'enumHelpers';

/**
 * Setup data synchronization for Notes
 * @param {object} parameter
 * @param {import('@vue/composition-api').Ref<Number>} parameter.vendor_id
 * @returns
 */
export default function useVendorWarehouses({ vendor_id }, config = {}) {
    const fetcher = async () => {
        return (
            await apis.Vendors.listWarehousesForVendor({
                vendor_id: vendor_id.value,
            })
        ).body.data.map((warehouse) => {
            // TODO: Remove this once components can use snake case keys
            const candidate = getNameById(Times, warehouse.cutoff_time_id);
            let cutoffTimeValue;
            candidate ? (cutoffTimeValue = candidate) : (cutoffTimeValue = '');
            return {
                id: warehouse.id,
                vendorId: warehouse.vendor_id,
                name: warehouse.name,
                zipcode: warehouse.zipcode,
                stateId: warehouse.state_id,
                cutoffTimeId: warehouse.cutoff_time_id,
                cutoffTimeValue: cutoffTimeValue,
                isDefault: warehouse.is_default,
                statusId: warehouse.status_id,
            };
        });
    };

    const key = () => {
        if (!vendor_id.value) {
            return '';
        }
        return ['listWarehousesForVendor', vendor_id.value];
    };

    const { data: warehouses, error, isValidating, mutate } = useConfiguredSWRV(key, fetcher, config);

    if (!warehouses.value) {
        warehouses.value = [];
    }

    return {
        /**
         * @type {import('@vue/composition-api').Ref<Warehouse[]>}
         */
        warehouses,
        error,
        isValidating,
        mutate,
    };
}
