import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            hasMany: {
                shippingMethod: {
                    foreignKey: 'shipping_service_id',
                    localField: 'shippingMethods',
                },
            },
            belongsToMany: {
                address: {
                    foreignKey: 'id',
                    localField: 'addresses',
                },
                vendor: {
                    foreignKey: 'id',
                    localField: 'vendors',
                },
            },
            morphMany: {
                ...auditsRelation('shippingService'),
            },
        },
    },
};
