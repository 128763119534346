export default {
    mapper: {
        relations: {
            belongsTo: {
                user: {
                    foreignKey: 'user_id',
                    localField: 'user',
                },
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
            },
        },
    },
};
