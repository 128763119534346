// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Inventory Address Dropdowns',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Inventory Addresses "dropdowns."
 * @returns
 */
export default function useInventoryAddressesDropdowns(config = {}) {
    const fetcher = async () => {
        const resp = await apis.InventoryAddresses.listInventoryAddresses();
        return resp.body || [];
    };

    const { data: inventoryAddresses, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<InventoryAddress[]>}
         */
        inventoryAddresses,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
