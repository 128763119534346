import store from 'store';
import _ from 'lodash';
export default class DataLoader {
    static get actions() {
        return [];
    }
    static get mutations() {
        return [];
    }
    static get store() {
        return store;
    }
    async load(injectedParams = {}) {
        const promises = [];
        injectedParams = { ...injectedParams };

        this.constructor.actions.forEach((action) => {
            let finalParams = {};

            if (action.params) {
                finalParams = { ...action.params };
            }

            if (action.injectedParams) {
                action.injectedParams.forEach((paramName) => {
                    finalParams[paramName] = injectedParams[paramName];
                });
            }

            // We want to pass undefined to the action/mutation
            // parameters if there are no parameters to send
            // otherwise it passes an empty object which
            // isn't compatible with a lot of our current action/mutations [DJF]
            if (Object.keys(finalParams).length === 0) {
                finalParams = undefined;
            }

            const promise = this.constructor.store.dispatch(action.name, finalParams);
            promises.push(promise);
        });

        this.constructor.mutations.forEach((mutation) => {
            let finalParams = { ...params };

            if (mutation.params) {
                if (!injectedParams.hasOwnProperty(paramName)) {
                    console.warn(
                        `${action.name} requests injectedParam '${paramName}' but it was not set. This may be ignored if beforeRouteUpdate was called.`
                    );
                }

                finalParams = { ...finalParams, ...mutation.params };
            }

            // We want to pass undefined to the action/mutation
            // parameters if there are no parameters to send
            // otherwise it passes an empty object which
            // isn't compatible with a lot of our current action/mutations [DJF]
            if (Object.keys(finalParams).length === 0) {
                finalParams = undefined;
            }

            this.constructor.store.commit(mutation.name, finalParams);
        });

        return await Promise.all(promises);
    }
}
