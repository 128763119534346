import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = params.id ? route('users.show', { id: params.id }) : route('users');
        delete params.id;
        return await axios.get(url, { params });
    },
    async show(params = {}) {
        const { userId } = params;
        delete params.userId;

        const url = route('users.show', { id: userId });
        const response = await axios.get(url, { params });

        return response.data.data;
    },
    async delete(id) {
        let url = route('users.destroy', { id: id });
        let result = await axios.delete(url);
        return result.data.data;
    },
    async updateStatus(payload) {
        let url = route('users.update-status', [payload.idString]);
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async update(payload) {
        let url = route('users.edit', { id: payload.id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('users.create');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async downloadCSV(params = {}) {
        let url = route('users.download-csv');
        return await axios.get(url, { params });
    },
};
