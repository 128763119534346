import { apis } from 'api/client';
import * as types from './mutation_types';

export default {
    async fetchInventoryVendors({ commit, getters }, facilityId) {
        let vendors = [];
        if (facilityId) {
            const response = await apis.Vendors.listVendors({
                sort: ['name'],
                facility_id: facilityId,
                is_vendor: true,
                distinct: 'inventory_vendor',
                per_page: -1,
            });
            response.body.data.forEach((vendor) => vendors.push(vendor));
            commit(types.SET_INVENTORY_VENDORS, vendors);
        }
    },
};
