import { auditsRelation, notesRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                address: [
                    {
                        foreignKey: 'cost_center_id',
                        localField: 'costCenter',
                    },
                    {
                        foreignKey: 'shipping_address_id',
                        localField: 'shippingAddress',
                    },
                ],
                bill: {
                    foreignKey: 'bill_id',
                    localField: 'bill',
                },
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
                patientName: {
                    foreignKey: 'patient_name_id',
                    localField: 'patientName',
                },
                shippingMethod: {
                    foreignKey: 'shipping_method_id',
                    localField: 'shippingMethod',
                },
            },
            belongsToMany: {
                purchaseOrder: {
                    foreignKey: 'id',
                    localField: 'purchaseOrders',
                },
            },
            hasMany: {
                invoiceLine: {
                    foreignKey: 'invoice_id',
                    localField: 'invoiceLines',
                },
            },
            morphMany: {
                ...auditsRelation('invoice'),
                ...notesRelation('invoice'),
            },
        },
    },
};
