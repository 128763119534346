import { auditsRelation, notesRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
                address: {
                    foreignKey: 'shipping_address_id',
                    localField: 'shippingAddress',
                },
                user: {
                    foreignKey: 'ordered_by_user_id',
                    localField: 'orderedByUser',
                },
                shippingMethod: {
                    foreignKey: 'shipping_method_id',
                    localField: 'shippingMethod',
                },
            },
            belongsToMany: {
                invoice: {
                    foreignKey: 'id',
                    localField: 'invoices',
                },
            },
            hasMany: {
                line: {
                    foreignKey: 'purchase_order_id',
                    localField: 'lines',
                },
                bill: {
                    foreignKey: 'purchase_order_id',
                    localField: 'bills',
                },
                creditLine: {
                    foreignKey: 'purchase_order_id',
                    localField: 'creditLines',
                },
            },
            morphToMany: {
                document: {
                    foreignKey: 'documents_id',
                    localField: 'documents',
                },
            },
            morphMany: {
                ...auditsRelation('purchaseOrder'),
                ...notesRelation('purchaseOrder'),
            },
        },
    },
};
