export function notesRelation(type) {
    return {
        note: {
            foreignKey: 'noteable_id',
            foreignType: 'noteable_type',
            relatedType: type,
            localField: 'notes',
            params: {
                per_page: -1,
                pagination_type: 'simple',
            },
        },
    };
}

export function auditsRelation(type) {
    return {
        audit: {
            foreignKey: 'auditable_id',
            foreignType: 'auditable_type',
            relatedType: type,
            localField: 'audits',
        },
    };
}

export function logExceptionAndReportValidationErrors(err) {
    console.error(err);
    if (err.errors) console.error(err);
}
