import _ from 'lodash';
import Vue from 'vue';
import * as actions from 'revamp-actions';
import * as getterIDs from 'revamp-getters';
import * as mutations from 'revamp-mutations';
import { requestInterceptor as clientRequestInterceptor } from 'api/client';
import { UserPreferenceTypes } from 'enums';
import jsd from 'app/jsd';

const DEFAULT_SORT = ['-id'];
const DEFAULT_PAGINATION = {
    page: 1,
    per_page: 200,
};

export const aborter = {
    controller: new AbortController(),
};
const pipeP =
    (...fns) =>
    (args) =>
        fns.reduce((arg, fn) => arg.then(fn), Promise.resolve(args));

function hasValues(filterObject) {
    if (filterObject == null) {
        return false;
    }

    const filters = _.omit(filterObject, ['page', 'sort']);
    return Boolean(Object.keys(filters).length);
}

function filterEmptyProperties(objectToFilter, ignoreFacilityId) {
    let filteredObject = {};

    for (let prop in objectToFilter) {
        if (ignoreFacilityId && (prop == 'facilityId' || prop == 'facility_id')) {
            continue;
        }
        if (objectToFilter[prop] !== null && objectToFilter[prop] !== '') {
            filteredObject[prop] = objectToFilter[prop];
        }
    }

    return filteredObject;
}

export default {
    namespaced: true,
    state: {
        data: [],
        dataLoaded: false,
        defaultSort: _.clone(DEFAULT_SORT),
        sort: [],
        appliedFilters: null,
        defaultFilters: {},
        filters: {},
        filterOverride: null,
        preferencesKey: null,
        filterPreferences: null,
        defaultPaginationData: _.clone(DEFAULT_PAGINATION),
        paginationData: _.clone(DEFAULT_PAGINATION),
        storedSearchParams: new Map(),
        searchSubmitted: false,
    },
    getters: {
        [getterIDs.GET_STORED_FILTERS](state) {
            return (preferencesKey) => {
                if (state.storedSearchParams.has(preferencesKey)) {
                    return state.storedSearchParams.get(preferencesKey).filters;
                }
                return null;
            };
        },
        [getterIDs.ITEM_BY_ID](state) {
            return (itemId) => {
                return state.data.find((item) => item.id == itemId);
            };
        },
        [getterIDs.FILTER_PREFERENCES_ARE_APPLIED](state) {
            return (ignoreFacilityId) => {
                if (state.filterPreferences == null) {
                    return false;
                }

                const filterPreferences = filterEmptyProperties(state.filterPreferences, ignoreFacilityId);
                const appliedFilters = filterEmptyProperties(state.appliedFilters, ignoreFacilityId);

                if (!Object.keys(state.filterPreferences).length) {
                    return false;
                }

                return _.isEqual(appliedFilters, filterPreferences);
            };
        },
    },
    mutations: {
        [mutations.SET_FILTERS](state, value = {}) {
            Vue.set(state, 'filters', value);
        },
        [mutations.SET_FILTER_VALUE](state, { key = '', value = null }) {
            if (key) {
                Vue.set(state.filters, key, value);
            }
        },
        [mutations.SET_DEFAULT_FILTERS](state, value = {}) {
            state.defaultFilters = value;
        },
        [mutations.SET_FILTER_OVERRIDE](state, filterObject = null) {
            if (filterObject !== null) {
                filterObject = filterEmptyProperties(filterObject, false);
            }
            state.filterOverride = filterObject;
        },
        [mutations.SET_PREFERENCES_KEY](state, preferencesKey = null) {
            state.preferencesKey = preferencesKey;
        },
        [mutations.SET_APPLIED_FILTERS](state, filterObject = null) {
            state.appliedFilters = filterObject;
        },
        [mutations.SET_FILTER_PREFERENCES](state, filterPreferences = null) {
            state.filterPreferences = filterPreferences;
        },
        [mutations.LOAD_FILTER_PREFERENCES](state) {
            let filtersToUse = Object.keys(state.filterPreferences).length
                ? state.filterPreferences
                : state.defaultFilters;
            state.filterOverride = filtersToUse;
            Vue.set(state, 'filters', filtersToUse);
        },
        [mutations.STORE_SEARCH_PARAMS](state) {
            const key = state.preferencesKey;
            const data = {
                filters: state.filters,
                paginationData: state.paginationData,
                sort: state.sort,
            };

            state.storedSearchParams.set(key, data);
        },
        [mutations.CLEAR_STORED_SEARCH_PARAMS](state, key) {
            state.storedSearchParams.delete(key);
        },
        [mutations.SET_DATA](state, payload = []) {
            if (payload.length) {
                payload = payload.map((element) => {
                    element._listId = _.uniqueId();
                    return element;
                });
            }
            state.data = payload;
        },
        [mutations.SET_PAGINATION_DATA](state, value) {
            if (value !== null && !Object.keys(value).length) {
                value = _.clone(state.defaultPaginationData);
            }

            state.paginationData = value;
        },
        [mutations.SET_DEFAULT_PAGINATION_DATA](state, value = null) {
            value = value || _.clone(DEFAULT_PAGINATION);
            state.defaultPaginationData = value;
            state.paginationData = value;
        },
        [mutations.SET_PAGE](state, page = null) {
            page = page || DEFAULT_PAGINATION.page;
            state.paginationData.page = page;
        },
        [mutations.SET_PER_PAGE](state, perPage = null) {
            perPage = perPage || DEFAULT_PAGINATION.per_page;
            state.paginationData.per_page = perPage;
        },
        [mutations.SET_SORT](state, value) {
            if (value && typeof value == 'string') {
                state.sort = value.split(',');
            } else if (value && value.length > 0) {
                state.sort = value;
            } else {
                state.sort = state.defaultSort;
            }
        },
        [mutations.SET_DEFAULT_SORT](state, value = null) {
            value = value || _.clone(DEFAULT_SORT);
            state.defaultSort = value;
        },
        [mutations.UPDATE_BY_LINE_ID](state, payload) {
            let lineIndex = _.findIndex(state.data, ['id', payload.id]);
            _.mapKeys(payload, (value, key) => {
                _.set(state.data[lineIndex], key, payload[key]);
            });
        },
        [mutations.REMOVE_LINE_AT_INDEX](state, index) {
            Vue.delete(state.data, index);
        },
        [mutations.SET_TABLE_DATA_LOADED](state, value = true) {
            state.dataLoaded = value;
        },
        [mutations.SET_SEARCH_SUBMITTED](state, value = false) {
            state.searchSubmitted = value;
        },
    },
    actions: {
        [actions.INITIALIZE_FILTERS](
            { state, commit, getters, rootGetters },
            { defaultFilters = {}, preferencesKey, defaultPagination = null, defaultSort = null, toRoute = null }
        ) {
            if (defaultPagination) {
                commit(mutations.SET_DEFAULT_PAGINATION_DATA, defaultPagination);
            }
            commit(mutations.SET_DEFAULT_SORT, defaultSort);
            commit(mutations.SET_DEFAULT_FILTERS, defaultFilters);
            commit(mutations.SET_PREFERENCES_KEY, preferencesKey);

            const filterPreferences = rootGetters['shared/currentUser/preferencesValue']({
                key: preferencesKey,
                type: UserPreferenceTypes.FILTER,
            });
            commit(mutations.SET_FILTER_PREFERENCES, filterPreferences);

            //If filters are explicitly set, use 'em. Otherwise, auto-select filters based on priority.
            if (state.filterOverride !== null) {
                commit(mutations.SET_FILTERS, state.filterOverride);
                commit(mutations.SET_APPLIED_FILTERS, state.filterOverride);
                commit(mutations.SET_FILTER_OVERRIDE);
                return;
            }

            const queryParams = { ...toRoute?.query };
            const storedFilters = getters[getterIDs.GET_STORED_FILTERS](preferencesKey);

            const filterSources = [queryParams, storedFilters, filterPreferences, defaultFilters];

            for (let source of filterSources) {
                if (hasValues(source)) {
                    commit(mutations.SET_FILTERS, source);
                    commit(mutations.SET_APPLIED_FILTERS, source);
                    break;
                }
            }
        },
        [actions.REMOVE_LINES_BY_ID]({ state, commit }, lineIds) {
            lineIds.forEach((id) => {
                const index = _.findIndex(state.data, (line) => line.id == id);
                commit(mutations.REMOVE_LINE_AT_INDEX, index);
            });
        },
        [actions.RESET_TABLE_STATE]({ commit }) {
            commit(mutations.SET_DEFAULT_FILTERS);
            commit(mutations.SET_FILTERS);
            commit(mutations.SET_APPLIED_FILTERS);
            commit(mutations.SET_DATA);
            commit(mutations.SET_PAGE);
            commit(mutations.SET_PER_PAGE);
            commit(mutations.SET_SEARCH_SUBMITTED);
            commit(mutations.SET_PREFERENCES_KEY);
            commit(mutations.SET_FILTER_PREFERENCES);
        },
        [actions.HANDLE_SORT]({ state, commit }, { column = '', directionOverride = null, sortIndex = 0 }) {
            if (!column) {
                throw "Sort 'column' property is required.";
            }

            commit(mutations.SET_TABLE_DATA_LOADED, false);

            let fullSort = _.clone(state.sort);
            if (directionOverride == null && fullSort[sortIndex] === `-${column}`) {
                if (fullSort[sortIndex] === state.defaultSort[sortIndex]) {
                    fullSort[sortIndex] = column;
                } else {
                    fullSort[sortIndex] = state.defaultSort[sortIndex];
                }
            } else if (directionOverride == 'DESC' || (directionOverride == null && fullSort[sortIndex] === column)) {
                fullSort[sortIndex] = `-${column}`;
            } else {
                fullSort[sortIndex] = column;
            }

            commit(mutations.SET_SORT, fullSort);
        },
        [actions.SORT_OUT_QUERY_PARAM_VALUES]({ commit, state }, params) {
            for (let param in params) {
                const value = params[param];

                switch (param) {
                    case 'sort':
                        commit(mutations.SET_SORT, value);
                        break;
                    case 'page':
                        commit(mutations.SET_PAGE, value);
                        break;
                    case 'per_page':
                        commit(mutations.SET_PER_PAGE, value);
                        break;
                    default:
                        commit(mutations.SET_FILTER_VALUE, {
                            key: param,
                            value,
                        });
                }
            }

            if (!params.hasOwnProperty('sort')) {
                commit(mutations.SET_SORT, state.defaultSort);
            }
        },
        async [actions.FETCH]({ commit }, { operation, params, jsDataResourceName }) {
            commit(mutations.SET_TABLE_DATA_LOADED, false);
            // Abort any previous requests to prevent responses from
            // earlier requests overriding the response from the most recent request.
            aborter.controller.abort();
            // Replace the AbortController to ensure the next request
            // gets a new signal.
            aborter.controller = new AbortController();
            const interfaceLevelRequestInterceptor = (req) => {
                req.signal = aborter.controller.signal;
                return req;
            };
            const requestInterceptor = pipeP(clientRequestInterceptor, interfaceLevelRequestInterceptor);
            let response;
            if (jsDataResourceName) {
                try {
                    response = await jsd.findAll(jsDataResourceName, params, {
                        swaggerOptions: { requestInterceptor },
                        raw: true,
                        force: true,
                    });
                } catch (error) {
                    if (error.name === 'AbortError') {
                        return;
                    }
                    throw error;
                }
                commit(mutations.SET_DATA, response.data || []);
                commit(mutations.SET_PAGINATION_DATA, response.metadata || {});
                commit(mutations.SET_TABLE_DATA_LOADED, true);
            } else {
                try {
                    response = await operation(params, { requestInterceptor, hideLoader: true });
                } catch (error) {
                    if (error.name === 'AbortError') {
                        return;
                    }
                    throw error;
                }
                commit(mutations.SET_DATA, response.body.data || []);
                commit(mutations.SET_PAGINATION_DATA, response.body.meta || {});
                commit(mutations.SET_TABLE_DATA_LOADED, true);
            }
        },
    },
};
