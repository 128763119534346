import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = params.id ? route('facilities.show', { id: params.id }) : route('facilities');
        delete params.id;
        return await axios.get(url, { params });
    },
    async delete(id) {
        let url = route('facilities.destroy', { id: id });
        let result = await axios.delete(url);
        return result.data.data;
    },
    async update(payload) {
        let url = route('facilities.edit', { id: payload.id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async updateStatus(payload) {
        let url = route('facilities.update-status', [payload.idString]);
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('facilities.create');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async dropdownData(params = {}, config = {}) {
        let url = route('facilities.dropdowns');
        return await axios.get(url, { params, ...config });
    },
    async downloadCSV(params = {}) {
        let url = route('facilities.download-csv');
        return await axios.get(url, { params });
    },
};
