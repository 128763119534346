import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}, config = {}) {
        let url = route('credit-cards');
        let response = await axios.get(url, { params, ...config });
        return response.data.data;
    },
};
