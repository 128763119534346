export default {
    mapper: {
        relations: {
            belongsTo: {
                shoppingList: {
                    foreignKey: 'shopping_list_id',
                    localField: 'shoppingList',
                },
                product: {
                    foreignKey: 'product_id',
                    localField: 'product',
                },
                vendor: {
                    foreignKey: 'manufacturer_id',
                    localField: 'manufacturer',
                },
            },
        },
    },
};
