export default {
    mapper: {
        relations: {
            belongsTo: {
                bugReport: {
                    foreignKey: 'bug_report_id',
                    localField: 'bugReport',
                },
            },
        },
    },
};
