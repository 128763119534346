// @ts-check
import state from './state.js';
import Viewer from './viewer.js';

/**
 * @type {import('vuex').GetterTree<typeof state>}
 */
const getters = {
    isConnected: (state, getters, rootState, rootGetters) => {
        return rootGetters['shared/echo/connected'];
    },
    echo: (state, getters, rootState, rootGetters) => {
        return rootGetters['shared/echo/instance'];
    },
    isOpen:
        (state, getters, rootState, rootGetters) =>
        ({ id }) => {
            id = Number(id);
            // TODO: Re-evaluate the structure for storing open/shut state of `Line`s to remove the need to check 3 different stores.
            const stores = ['ordersReview', 'ordersManage', 'shared/orderHistory'];
            return stores
                .map((store) => `${store}/isLineOpen`)
                .map(
                    /**
                     * @type {(path: string)=> boolean}
                     */
                    (path) => rootGetters[path]({ id })
                )
                .some((isOpen) => isOpen);
        },
    /**
     *
     * @param {state} state
     * @param {any} getters
     * @param {any} rootState
     * @param {any} rootGetters
     * @returns {Viewer}
     */
    selfAsViewer: (state, getters, rootState, rootGetters) => {
        const user = rootGetters['shared/currentUser/user'];
        let socketId = 'not connected';
        if (getters.echo) {
            socketId = getters.echo.socketId();
        }
        return new Viewer({
            id: user.id,
            name: user.name,
            socketId,
        });
    },
    /**
     * Return a Line's Viewers.
     * @param {state} state
     * @param {*} getters
     * @param {*} rootState
     * @param {*} rootGetters
     * @returns {(id: number) => Viewer[]} A function to return the viewers of a given Line by id.
     */
    viewersOf: (state, getters, rootState, rootGetters) => (id) => {
        return state.views[id] || [];
    },
};
export default getters;
