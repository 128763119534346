import { apis } from 'api/client';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        facilityDropdownData: [],
        dropdownData: [],
        inRequest: false
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
            store.facilityDropdownData = payload.filter((permission) => {
                return permission.type == 'facility'
            });
        }
    },
    actions: {
        async getDropdownData({ state, commit }, params) {
            if (state.inRequest === false) {
                state.inRequest = true;

                let paramsToPass = _.merge({}, {
                    sort: ['name'],
                    per_page: -1,
                    pagination_type: 'simple'
                }, params);

                let response = await apis.Permissions.listPermissions(paramsToPass);
                commit('setDropdownData', response.body.data);

                state.inRequest = false;
            }
        },
    },
    getters: {
        permissionByType(state, getters) {
            return (type) => {
                return state.dropdownData.filter((permission) => {
                    return permission.type === 'shared' || permission.type === type;
                });
            };
        },
        adminPermissions(state, getters) {
            return getters.permissionByType('admin');
        },
        facilityPermissions(state, getters) {
            return getters.permissionByType('facility');
        }
    }
}
