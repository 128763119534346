import axios from 'axios';
import route from 'ziggyRoute';
import _ from 'lodash';

export default {
    async get(params = {}, config = {}) {
        let url = params.id ? route('shipping-services.show', { id: params.id }) : route('shipping-services');
        delete params.id;
        return await axios.get(url, { params, ...config });
    },
    async delete(id) {
        let url = route('shipping-services.destroy', { id: id });
        return await axios.delete(url);
    },
    async update(id, payload) {
        let url = route('shipping-services.edit', { id: id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('shipping-services.create');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async saveReorder(shippingServices = []) {
        let data = {
            shippingServices: shippingServices.map((method) =>
                _.pick(method, ['id', 'dropdownOrder', 'billDropdownOrder', 'invoiceDropdownOrder'])
            ),
        };
        let url = route('shipping-services.dropdowns.update');
        let result = await axios.put(url, data);
        return result;
    },
};
