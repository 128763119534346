<template>
    <div :class="['table-line-subcontent', { 'unsaved-changes': lineHasUnsavedChanges(lineId) }]">
        <div class="columns is-multiline">
            <div class="column is-full">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <emp-button label="Original Order" @click="showOriginalOrderModal" />
                        </div>
                        <div class="level-item">
                            <order-line-history-modal :itemId="lineId" />
                        </div>
                        <div class="level-item">
                            <router-link class="button is-info" :to="getReorderRoute(line.id)"> Reorder </router-link>
                        </div>
                        <div class="level-item" v-if="!isOrdersReview">
                            <emp-button label="Create Shipping Label" @click="showCreateShippingLabelModal" />
                        </div>
                        <div class="level-item" v-if="!isOrdersReview">
                            <emp-button label="Print Packing Slip" @click="onPrintPackingSlip" />
                        </div>
                        <emp-button
                            v-show="isPendingReview && !isCustomerDirectOrder"
                            label="Customer Direct"
                            @click="onCustomerDirectButton"
                        />
                    </div>

                    <div class="level-right">
                        <div v-if="isBilled" class="level-item has-text-danger already-billed">
                            This item has already been billed. Please check with Billing before making any changes.
                        </div>
                        <div class="level-item">
                            <status-buttons
                                :statusId="line.statusId"
                                type="line"
                                :entityId="line.id"
                                @statusChange="onStatusChange($event)"
                                :errors="lineErrors"
                            />
                        </div>
                        <div class="level-item">
                            <emp-button
                                label="Save"
                                @click="onSave"
                                :buttonClass="isBilled ? 'is-warning' : 'is-primary'"
                            />
                        </div>
                        <div class="level-item">
                            <emp-confirmation-button label="Cancel" @click="onCancel" buttonClass="is-danger" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <div class="tile is-child box">
                    <h4 class="title is-4">Facility Information</h4>
                    <div class="columns is-multiline">
                        <div class="column is-full">
                            <label class="label">Facility:</label
                            ><badge-display :is-flagship="isFlagshipFacility" :babysit="babysitFacility">{{
                                facilityName
                            }}</badge-display>
                        </div>
                        <select-shipping-address
                            label="Ship To"
                            fieldClass="column is-11"
                            inputClass=""
                            labelClass=""
                            :value="line.shippingAddressId"
                            :facility-id="facilityId"
                            :placeholder="false"
                            @input="onShippingAddressInput($event)"
                            filterLabel="Status"
                            emp-data="shipTo"
                            :disabled="!enabledFields.ship_to"
                        />
                        <div class="column is-1 has-tooltip">
                            <emp-tooltip>
                                <div slot="tooltip"
                                    ><!--
                                    --><div
                                        ><!--
                                        --><template v-if="line.shippingAddress"
                                            ><!--
                                            -->{{ line.shippingAddress.fullAddress
                                            }}<!--
                                            --><br /><!--
                                        --></template
                                        ><!--
                                        --><template v-if="line.shippingAddress.phoneNumber"
                                            ><!--
                                            -->{{ line.shippingAddress.phoneNumber
                                            }}<!--
                                            --><br /><!--
                                        --></template
                                        ><!--
                                        --><template v-if="line.shippingAddress && line.shippingAddress.notes"
                                            ><address-tooltip-information
                                                :facility-notes="line.facility.notes"
                                                :address-notes="line.shippingAddress.notes" /></template
                                        ><!--
                                    --></div
                                    ><!--
                                --></div
                                ><!--
                            --></emp-tooltip>
                        </div>
                        <select-cost-center
                            :label="`Cost Center - Code: ${line.costCenter.code}`"
                            fieldClass="column is-11"
                            inputClass=""
                            labelClass=""
                            :facility-id="facilityId"
                            :value="line.costCenterId"
                            :placeholder="false"
                            @input="onCostCenterInput($event)"
                            filterLabel="Status"
                            emp-data="costCenter"
                            v-if="isUnbilled"
                            :disabled="!enabledFields.cost_center"
                        />
                        <div class="column is-11" v-else>
                            <div class="field">
                                <label class="label">Cost Center - Code: {{ line.costCenter.code }}</label>
                                <span class="control">
                                    <badge-display :is-flagship="isFlagshipFacility" :babysit="babysitFacility">{{
                                        line.costCenter.name
                                    }}</badge-display>
                                </span>
                            </div>
                        </div>
                        <div class="column is-1 has-tooltip">
                            <emp-tooltip>
                                <div slot="tooltip"
                                    ><!--
                                    --><div v-if="line.costCenter && vendorRebates.length"
                                        ><!--
                                        !--><div class="vendor-rebates"
                                            >Vendor Rebates:
                                            <div v-for="(rebate, index) in vendorRebates" :key="index">{{
                                                rebate
                                            }}</div> </div
                                        ><!--
                                        --><div class="phone-number" v-if="line.costCenter.phoneNumber">{{
                                            line.costCenter.phoneNumber
                                        }}</div
                                        ><!--
                                        --><template v-if="line.costCenter.notes"
                                            ><address-tooltip-information
                                                :facility-notes="line.facility.notes"
                                                :address-notes="line.costCenter.notes" /></template
                                        ><!--
                                    --></div
                                    ><!--
                                --></div
                                ><!--
                            --></emp-tooltip>
                        </div>
                        <emp-select-facility-user
                            v-if="line.isAutomaticOrder == false"
                            fieldClass="column is-9"
                            inputClass=""
                            labelClass=""
                            :facility-id="facilityId"
                            :placeholder="false"
                            @input="setNumericProperty('practitionerId', $event)"
                            :value="line.practitionerId"
                            name="practitionerId"
                            :errors="lineErrors"
                            filterLabel="Status"
                            emp-data="practitioner"
                            :disabled="!enabledFields.practitioner"
                        />
                        <div class="column is-9" v-else>
                            <div class="field">
                                <label class="label">Practitioner</label>
                                Inventory Reorder
                            </div>
                        </div>
                        <div
                            class="column is-1 has-tooltip"
                            v-if="
                                selectedPractitioner && (selectedPractitioner.phoneNumber || selectedPractitioner.notes)
                            "
                        >
                            <emp-tooltip placement="right">
                                <div slot="tooltip">
                                    <template v-if="selectedPractitioner.phoneNumber"
                                        ><strong>Phone #:</strong> {{ selectedPractitioner.phoneNumber }}<br /><br
                                    /></template>
                                    <template v-if="selectedPractitioner.notes"
                                        ><strong>Notes:</strong><br />{{ selectedPractitioner.notes }}<br /><br
                                    /></template>
                                </div>
                            </emp-tooltip>
                        </div>
                        <div
                            class="column is-1 has-icon"
                            v-if="selectedPractitioner && selectedPractitioner.phoneNumber"
                        >
                            <router-link
                                :to="getPractitionerMessageModalRoute(selectedPractitioner.id)"
                                class="icon sms-icon is-medium"
                            >
                                <i class="fas fa-comment fa-lg"></i>
                            </router-link>
                        </div>
                        <div class="column is-1 has-icon" v-if="selectedPractitioner && selectedPractitioner.email">
                            <a
                                :href="`mailto:${selectedPractitioner.email}?subject=Empire%20Order%20%23${line.orderId}`"
                                class="icon email-icon is-medium"
                                target="_blank"
                            >
                                <i class="fas fa-envelope fa-lg"></i>
                            </a>
                        </div>
                        <patient-name-widget
                            :facility-id="facilityId"
                            fieldClass="column is-9"
                            :value="line.patients"
                            @input="onPatientsUpdated($event)"
                            :disabled="!enabledFields.patient_name"
                        />
                        <emp-checkbox
                            label="For Stock"
                            :value="line.isForStock"
                            fieldClass="column is-3 help"
                            @input="onIsForStockUpdated($event)"
                            :disabled="!enabledFields.for_stock"
                        />
                        <emp-textbox
                            label="Job #"
                            fieldClass="column is-full"
                            inputClass=""
                            labelClass=""
                            :value="line.jobNumber"
                            @input="setProperty('jobNumber', $event)"
                            :disabled="!enabledFields.job_number"
                        />
                    </div>
                </div>
            </div>
            <div class="tile is-parent">
                <div class="tile is-child box">
                    <h4 class="title is-4">Item Information</h4>
                    <div class="columns is-multiline">
                        <div class="column is-full">
                            <div class="columns is-multiline">
                                <select-vendor
                                    label="Vendor"
                                    fieldClass="column is-7"
                                    inputClass=""
                                    labelClass=""
                                    :placeholder="false"
                                    :value="line.vendorId"
                                    v-model.number="line.vendorId"
                                    @input="onSelectVendor"
                                    :errors="lineErrors"
                                    :disabled="isSelectVendorDisabled || !enabledFields.vendor"
                                    name="vendor_id"
                                />
                                <div class="column is-1 has-tooltip">
                                    <emp-tooltip v-if="line.vendor" placement="right">
                                        <div slot="tooltip">
                                            <line-vendor-notes :vendor="line.vendor" noteType="general" />
                                        </div>
                                    </emp-tooltip>
                                </div>
                                <div class="column is-1 has-tooltip">
                                    <emp-tooltip placement="right">
                                        <div slot="tooltip">
                                            <line-vendor-notes :vendor="line.vendor" noteType="return" />
                                        </div>
                                        <div slot="outlet">
                                            <slot name="outlet">R</slot>
                                        </div>
                                    </emp-tooltip>
                                </div>
                                <div class="column is-1 has-tooltip">
                                    <emp-tooltip placement="right">
                                        <div slot="tooltip">
                                            <line-vendor-notes :vendor="line.vendor" noteType="account" />
                                        </div>
                                        <div slot="outlet">
                                            <slot name="outlet">#</slot>
                                        </div>
                                    </emp-tooltip>
                                </div>
                            </div>
                            <div class="level">
                                <div class="account-numbers">
                                    <strong>Account #</strong><br />
                                    {{ line.vendor.accountNumber }}
                                </div>
                                <div class="account-numbers">
                                    <strong>Customer Account #</strong><br />
                                    {{ customerAccountNumber }}
                                </div>
                                <div class="account-numbers">
                                    <strong>Subaccount #</strong><br />
                                    {{ subaccountNumber }}
                                </div>
                            </div>
                        </div>
                        <part-number-autocomplete
                            :config="autocompleteConfig"
                            :classes="getAutocompleteClasses()"
                            :value="line.partNumber"
                            @input="setProperty('partNumber', $event)"
                            :onSelect="onSelectProduct"
                            :errors="lineErrors"
                            name="partNumber"
                            :facilityId="line.facilityId"
                            :showPrice="true"
                        />
                        <div class="column is-1 has-tooltip">
                            <emp-tooltip v-if="productNotes.length" placement="right">
                                <div slot="tooltip">
                                    <product-notes-tooltip :notes="productNotes" />
                                </div>
                            </emp-tooltip>
                        </div>
                        <div class="column is-full">
                            <div class="columns is-multiline">
                                <div class="line-option column is-6">
                                    <input
                                        type="checkbox"
                                        class="line-option__checkbox"
                                        :value="line.isDoNotSub"
                                        @input="setProperty('isDoNotSub', $event)"
                                    />
                                    <div
                                        :class="['line-option__overlay', { 'overlay-red': line.isDoNotSub }]"
                                        @click="onToggleOption('isDoNotSub')"
                                    >
                                        Do Not Sub
                                    </div>
                                </div>
                                <div class="line-option column is-6">
                                    <input
                                        type="checkbox"
                                        class="line-option__checkbox"
                                        :value="line.isWarrantyReplacement"
                                        @input="setProperty('isWarrantyReplacement', $event)"
                                    />
                                    <div
                                        :class="[
                                            'line-option__overlay',
                                            { 'overlay-purple': line.isWarrantyReplacement },
                                        ]"
                                        @click="onToggleOption('isWarrantyReplacement')"
                                    >
                                        Warranty Replacement
                                    </div>
                                </div>
                                <div class="line-option column is-6">
                                    <input
                                        type="checkbox"
                                        class="line-option__checkbox"
                                        :value="line.inventorySystemOrder"
                                        readonly
                                    />
                                    <div
                                        :class="[
                                            'line-option__overlay',
                                            { 'overlay-orange': line.inventorySystemOrder },
                                        ]"
                                    >
                                        Inventory Auto-Reorder
                                    </div>
                                </div>
                                <div class="line-option column is-6">
                                    <input
                                        type="checkbox"
                                        class="line-option__checkbox"
                                        :value="line.isShoppingList"
                                        readonly
                                    />
                                    <div :class="['line-option__overlay', { 'overlay-yellow': line.isShoppingList }]">
                                        Shopping List
                                    </div>
                                </div>
                            </div>
                        </div>
                        <emp-textarea
                            label="Part Description"
                            fieldClass="column is-full"
                            inputClass=""
                            labelClass=""
                            :value="line.description"
                            @input="setProperty('description', $event)"
                            name="description"
                            :errors="lineErrors"
                            :contentBasedHeight="true"
                            :disabled="!enabledFields.description"
                        />
                        <div class="manufacturer-information column is-full">
                            <div class="columns">
                                <select-manufacturer
                                    label="Manufacturer"
                                    fieldClass="column is-5"
                                    inputClass=""
                                    labelClass=""
                                    :placeholder="false"
                                    :value="line.manufacturerId"
                                    @input="onManufacturerChange"
                                    name="manufacturer_id"
                                    :errors="lineErrors"
                                    :disabled="!enabledFields.manufacturer"
                                />
                                <div class="column is-1 has-tooltip">
                                    <emp-tooltip v-if="line.manufacturer" placement="top">
                                        <div slot="tooltip">
                                            <line-vendor-notes :vendor="line.manufacturer" noteType="general" />
                                        </div>
                                    </emp-tooltip>
                                </div>
                                <div class="column is-1 has-tooltip" v-if="line.manufacturer">
                                    <emp-tooltip placement="top">
                                        <div slot="tooltip">
                                            <line-vendor-notes :vendor="line.manufacturer" noteType="account" />
                                        </div>
                                        <div slot="outlet">
                                            <slot name="outlet">#</slot>
                                        </div>
                                    </emp-tooltip>
                                </div>
                                <div class="column is-2 help" v-show="showCutOff || shipDateLeadTime">
                                    <div v-show="showCutOff">
                                        Order By: <span class="no-wrap">{{ cutoffTimeValue }}</span>
                                    </div>
                                </div>
                                <div class="column is-3">
                                    <label class="label">&nbsp;</label>
                                    <emp-button
                                        :buttonClass="[
                                            'is-info',
                                            { 'is-loading': gettingEstimateFor(ENTITY_TYPE.MANUFACTURER) },
                                        ]"
                                        @click="getWarehousesFor(ENTITY_TYPE.MANUFACTURER)"
                                        label="Get Estimate"
                                        :disabled="!enabledFields.date_expected"
                                    />
                                </div>
                            </div>
                        </div>
                        <select-category
                            label="Category"
                            name="categoryId"
                            :errors="lineErrors"
                            fieldClass="column is-full"
                            inputClass=""
                            labelClass=""
                            :placeholder="false"
                            :value="line.categoryId"
                            @input="setNumericProperty('categoryId', $event)"
                            categoryType="orders"
                            :disabled="!enabledFields.category"
                        />
                    </div>
                </div>
            </div>
            <div class="tile is-parent">
                <div class="tile is-child box">
                    <h4 class="title is-4">Additional Item Information</h4>
                    <div class="columns is-multiline">
                        <div :class="['column', 'is-full', { 'is-highlighted': shouldHighlightCustomerNote }]">
                            <label class="label">Customer Note</label>
                            <p class="customer-note">{{ line.customerNote }}</p>
                        </div>
                        <emp-textbox
                            label="Quantity"
                            fieldClass="column is-4"
                            inputClass="has-text-right"
                            labelClass=""
                            :value="line.quantity"
                            @keypress="filterAmountInput($event)"
                            @blur="onQuantityBlur($event)"
                            name="quantity"
                            :errors="lineErrors"
                            :disabled="!enabledFields.quantity"
                        />
                        <emp-textbox
                            label="Cost"
                            fieldClass="column is-4"
                            :inputClass="[
                                'has-text-right',
                                {
                                    'is-danger': shouldHighlightCost,
                                },
                            ]"
                            labelClass=""
                            :value="line.cost"
                            @keypress="filterAmountInput($event)"
                            @blur="onCostBlur($event)"
                            icon="fas fa-dollar-sign"
                            :disabled="!enabledFields.cost"
                        >
                            <template slot="label">
                                <div class="level cost-label">
                                    <div class="level-left">
                                        <label class="level-item label">Cost</label>
                                    </div>
                                    <div class="level-right" v-if="isPriceListItem">
                                        <div class="level-item">
                                            <emp-tooltip placement="right">
                                                <div slot="tooltip"
                                                    ><!--
                                                    --><div>List Price: ${{ listPrice }}</div
                                                    ><!--
                                                    --><div>Empire Discount: {{ empireDiscount }}%</div
                                                    ><!--
                                                    --><div>Customer Discount: {{ customerDiscount }}%</div
                                                    ><!--
                                                --></div>
                                                <div slot="outlet">
                                                    <slot name="outlet">?</slot>
                                                </div>
                                            </emp-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </emp-textbox>
                        <emp-textbox
                            label="Total Cost"
                            :readonly="true"
                            :disabled="true"
                            fieldClass="column is-4"
                            inputClass="has-text-right"
                            labelClass=""
                            :value="numberFormat(line.cost * line.quantity)"
                            icon="fas fa-dollar-sign"
                        />
                        <emp-textbox
                            label="Markup"
                            fieldClass="column is-4"
                            inputClass="has-text-right"
                            labelClass=""
                            icon="fas fa-percent"
                            :value="line.markup"
                            @keypress="filterAmountInput($event)"
                            @blur="onMarkupBlur($event)"
                            :disabled="!enabledFields.markup"
                        />
                        <emp-textbox
                            label="Price"
                            fieldClass="column is-4"
                            :inputClass="[
                                'has-text-right',
                                {
                                    'is-danger': shouldHighlightPrice,
                                },
                            ]"
                            labelClass=""
                            :value="line.price"
                            @keypress="filterAmountInput($event)"
                            @blur="onPriceBlur($event)"
                            icon="fas fa-dollar-sign"
                            :disabled="!enabledFields.price"
                        />
                        <emp-textbox
                            label="Total Price"
                            :readonly="true"
                            :disabled="true"
                            fieldClass="column is-4"
                            inputClass="has-text-right"
                            labelClass=""
                            :value="numberFormat(line.price * line.quantity)"
                            icon="fas fa-dollar-sign"
                        />
                        <emp-textbox
                            label="Price with rebate"
                            :readonly="true"
                            :disabled="true"
                            fieldClass="column is-4 is-offset-4 "
                            inputClass="has-text-right"
                            :value="priceAfterRebate"
                            icon="fas fa-dollar-sign"
                        />
                        <emp-textbox
                            label="Total Price w/ rebate"
                            :readonly="true"
                            :disabled="true"
                            fieldClass="column is-4"
                            inputClass="has-text-right"
                            :value="totalAfterRebate"
                            icon="fas fa-dollar-sign"
                        />
                        <div class="column is-full">
                            <hr />
                        </div>
                        <emp-textbox
                            label="Shipping"
                            fieldClass="column is-4"
                            inputClass="has-text-right"
                            labelClass=""
                            :value="line.shipping"
                            @input="setNumericProperty('shipping', $event)"
                            icon="fas fa-dollar-sign"
                            :disabled="!enabledFields.shipping"
                        />
                        <emp-textbox
                            label="Old Price"
                            fieldClass="column is-4"
                            inputClass="has-text-right"
                            labelClass=""
                            :value="line.oldPrice"
                            @input="setNumericProperty('oldPrice', $event)"
                            icon="fas fa-dollar-sign"
                            :disabled="!enabledFields.old_price"
                        />
                        <div class="column is-4">
                            <label class="label">Savings</label>
                            <p class="control has-text-right">
                                <emp-textbox
                                    :readonly="true"
                                    :disabled="true"
                                    fieldClass=""
                                    inputClass="has-text-right"
                                    :value="calculatedSavings"
                                    icon="fas fa-dollar-sign"
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tile is-parent">
                <div class="tile is-child box">
                    <h4 class="title is-4">Shipping / Return Information</h4>
                    <div class="columns is-multiline">
                        <emp-datepicker
                            label="Date Needed"
                            fieldClass="column is-4 no-margin-top no-margin-bottom"
                            :inputClass="['has-text-centered', { 'is-highlighted': !line.isGroundShipping }]"
                            :config="{ enableTime: false, dateFormat: 'm/d/Y' }"
                            :formatter="toDateObject"
                            labelClass=""
                            name="dateNeeded"
                            :value="line.dateNeeded"
                            @input="onDateNeededUpdated($event)"
                            :errors="lineErrors"
                            :convertTz="false"
                            :disabled="!enabledFields.date_needed"
                        />
                        <emp-checkbox
                            label="Is Ground"
                            fieldClass="column is-4 help"
                            inputClass=""
                            labelClass=""
                            :value="line.isGroundShipping"
                            @input="onIsGroundShippingUpdated($event)"
                            :disabled="!enabledFields.date_needed"
                        />
                        <div
                            class="column is-full columns is-multiline no-margin-top no-margin-bottom no-padding-bottom"
                        >
                            <emp-datepicker
                                label="Date Expected"
                                :value="line.dateExpected"
                                @input="setProperty('dateExpected', $event)"
                                inputClass="has-text-centered"
                                labelClass=""
                                fieldClass="column is-4"
                                :formatter="toDateObject"
                                :config="{ enableTime: false, dateFormat: 'm/d/Y' }"
                                :convertTz="false"
                                :disabled="!enabledFields.date_expected"
                                name="date_expected"
                            />
                            <div class="column">
                                <label class="label">&nbsp;</label>
                                <emp-button
                                    :buttonClass="['is-info', { 'is-loading': gettingEstimateFor(ENTITY_TYPE.VENDOR) }]"
                                    @click="getWarehousesFor(ENTITY_TYPE.VENDOR)"
                                    :disabled="!enabledFields.date_expected"
                                    label="Get Estimate"
                                />
                                <emp-tooltip
                                    placement="right"
                                    v-if="line.vendor.regionalDelivery"
                                    class="regional-delivery"
                                >
                                    <div slot="tooltip">Regional Delivery Service Available</div>
                                    <div slot="outlet">
                                        <slot name="outlet"><i class="fas fa-truck fa-flip-horizontal"></i></slot>
                                    </div>
                                </emp-tooltip>
                            </div>
                            <div
                                class="column help"
                                v-if="(line.vendor.cutoffTimeValue && showCutOff) || shipDateLeadTime"
                            >
                                <div v-show="line.vendor.cutoffTimeValue">
                                    Order By: <span class="no-wrap">{{ line.vendor.cutoffTimeValue }}</span>
                                </div>
                                <div v-show="shipDateLeadTime">
                                    Lead Time: <span class="no-wrap">{{ shipDateLeadTime }} Days</span>
                                </div>
                            </div>
                            <emp-select-warehouse
                                label="Warehouse"
                                :vendorId="line.vendor.id"
                                :value="line.warehouseId"
                                @input="setProperty('warehouseId', Number($event))"
                                :fieldClass="'is-full column'"
                                :disabled="!enabledFields.warehouse"
                                :errors="lineErrors"
                            />
                        </div>
                        <div class="column no-vertical-padding" v-show="hasBeenOrdered">
                            <div
                                class="tags has-addons no-margin-bottom"
                                v-show="!showDeliveredAtDatePicker"
                                @click="showDeliveredAtDatePicker = !showDeliveredAtDatePicker"
                            >
                                <div class="tag is-dark no-margin-bottom">Delivered</div>
                                <div class="tag is-warning no-margin-bottom">No</div>
                            </div>
                            <emp-datepicker
                                label="Delivered"
                                :value="line.deliveredAt"
                                @input="setProperty('deliveredAt', $event)"
                                :inputClass="[
                                    'tag',
                                    'delivery-date-input',
                                    { 'is-success': line.deliveredAt, 'is-danger': !line.deliveredAt },
                                ]"
                                labelClass="tag is-dark"
                                fieldClass="tags has-addons"
                                :formatter="toDateObject"
                                :config="{ enableTime: false, dateFormat: 'm/d/Y' }"
                                :convertTz="false"
                                v-show="showDeliveredAtDatePicker"
                                :disabled="!enabledFields.delivered_date"
                            />
                        </div>
                        <div class="field column is-full no-margin-top no-margin-bottom">
                            <tracking-numbers
                                ref="trackingNumberWidget"
                                :value="line.trackingNumbers"
                                @change="onTrackingNumbersChanged"
                                @input="setProperty('trackingNumbers', $event)"
                                :type="TrackingNumberTypes.NORMAL"
                                label="Tracking Numbers"
                                :editable="enabledFields.tracking_numbers"
                                :showTags="true"
                                :expectedShippingMethodId="purchaseOrderShippingMethodId"
                            />
                        </div>
                        <div class="column is-full">
                            <shipping-labels :labels="line.shippingLabels" />
                        </div>
                        <template v-if="showReturnInformation">
                            <div class="column is-full no-margin-top no-vertical-padding">
                                <hr class="no-margin-top no-margin-bottom" />
                            </div>
                            <div class="column is-full no-margin-top no-margin-bottom">
                                <emp-button
                                    label="Return Requests"
                                    @click="showReturnRequestsModal"
                                    :disabled="!enabledFields.return_requests"
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="tile is-parent">
                <div class="tile is-child box">
                    <h4 class="title is-4">General Order Information</h4>
                    <div class="columns is-multiline">
                        <div class="column is-6"><label class="label">Order #</label>{{ line.orderId }}</div>
                        <div class="column is-6"><label class="label">Line #</label>{{ line.id }}</div>
                        <emp-textbox
                            fieldClass="column is-6"
                            inputClass=""
                            labelClass=""
                            :value="purchaseOrderIdWithSuffix"
                            readonly
                            :disabled="!enabledFields.po_number"
                        >
                            <template slot="label">
                                <label class="label"
                                    >PO # <customer-direct-order-icon v-if="isCustomerDirectOrder"
                                /></label>
                            </template>
                        </emp-textbox>
                        <div class="column is-half">
                            <strong>Confirmation #</strong><br />
                            {{ confirmationNumber }}
                        </div>
                        <div class="column is-half">
                            <strong>Customer Ordered</strong><br />
                            {{ formatUserAndDate(line.userOrderedBy, line.userOrderedTimestamp, false) }}
                        </div>
                        <div class="column is-half" v-if="line.adminReviewedTimestamp">
                            <strong>Admin Reviewed</strong><br />
                            {{ formatUserAndDate(line.reviewedBy, line.adminReviewedTimestamp, false) }}
                        </div>
                        <div class="column is-full" v-if="line.adminOrderedTimestamp">
                            <strong>Admin Ordered</strong><br />
                            {{ formatUserAndDate(line.adminOrderedBy, line.adminOrderedTimestamp, false) }}
                            {{ formatShippingAndOrderMethod(line) }}
                        </div>
                        <div class="column is-half" v-if="line.dateReceived">
                            <strong>Customer Received</strong><br />
                            {{ formatUserAndDate(line.userReceivedBy, line.dateReceived, false) }}
                        </div>
                        <div :class="['column is-full', { 'is-highlighted': line.purchaseOrderNote }]">
                            <emp-textarea
                                label="Item Notes (for PO)"
                                v-model="line.purchaseOrderNote"
                                fieldClass=""
                                inputClass="height-6em"
                                @input="addLineToUnsavedList(lineId)"
                                :disabled="!enabledFields.item_notes_for_po"
                            />
                        </div>
                        <div :class="['column is-full internal-notes', { 'is-highlighted': adminNotes.length }]">
                            <emp-notes
                                label="Internal Notes"
                                :noteableId="line.id"
                                :noteableType="NOTEABLE_TYPES.LINE"
                                :notes.sync="line.notes"
                                type="admin"
                                :disabled="!enabledFields.internal_notes"
                                ref="internalNotes"
                                @input="setProperty('unsavedNote', true)"
                                @update:notes="setProperty('unsavedNote', false)"
                            >
                                <select-prewritten-note
                                    :noteTypeId="PrewrittenNoteTypes.ADMIN_INTERNAL"
                                    :singleLine="true"
                                    @selectPrewrittenNote:noteUpdated="updateInternalNote($event)"
                                />
                            </emp-notes>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { apis } from 'api/client';
import Bugsnag from '@bugsnag/js';
import AddressesApi from 'api/endpoints/addresses';
import VendorsApi from 'api/endpoints/vendors';
import ProductApi from 'api/endpoints/products';
import OrderLineHistoryModal from 'component/admin/OrderLineHistoryModal';
import PartNumberAutocomplete from 'component/shared/elements/revamp/PartNumberAutocomplete';
import LineVendorNotes from './LineVendorNotes';
import TrackingNumbers from './TrackingNumbers';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import {
    formatters,
    highlightErrorFields,
    systemNotifications,
    unsavedNoteNotification,
    requiresBlacklistPassword,
} from 'component/shared/elements/mixins';
import { numberFormat, evalMathExpression } from 'helpers';
import { TrackingNumberTypes, Statuses, OrderMethods, PrewrittenNoteTypes } from 'enums';
import BadgeDisplay from 'component/admin/BadgeDisplay';
import { ORDERS_REVIEW_SIDEBAR_SYNC_ARGS, ORDERS_MANAGE_SIDEBAR_SYNC_ARGS, ENTITY_TYPE } from 'ordersConstants';
import SmartApi from 'SmartApi';
import { calculateDiscount, calculateCustomerDiscount } from 'helpers';
import CustomerDirectOrderIcon from 'component/shared/elements/CustomerDirectOrderIcon';
import AddressTooltipInformation from 'component/admin/AddressTooltipInformation';
import ProductNotesTooltip from 'component/shared/ProductNotesTooltip';
import ProductRequiredNotesConfirmationModal from 'component/shared/ProductRequiredNotesConfirmationModal';
import SelectPrewrittenNote from 'component/shared/elements/SelectPrewrittenNote';
import LineNeedsMoreInfoModal from 'component/shared/LineNeedsMoreInfoModal';
import ShippingLabels from 'component/admin/elements/ShippingLabels.vue';
import OriginalOrder from 'component/shared/OriginalOrder.vue';
import ChooseWarehouse from 'component/admin/ChooseWarehouse.vue';
import ChooseDateExpectedModal from 'component/admin/ChooseDateExpectedModal.vue';
import CreateShippingLabel from 'component/admin/CreateShippingLabel.vue';
import PrintOrderModal from 'component/shared/PrintOrderModal.vue';
const ConvertCustomerDirectOrderModal = () => import('component/admin/ConvertCustomerDirectOrderModal.vue');
const ConfirmationDialogue = () => import('component/shared/ConfirmationDialogue.vue');
const InaccurateTrackingNumbersModal = () => import('component/admin/InaccurateTrackingNumbersModal.vue');
const ReturnRequestsModal = () => import('component/admin/ReturnRequestsModal.vue');
const StatusButtons = () => import(/* webpackChunkName: "orders" */ 'component/revamp/StatusButtons.vue');
const PatientNameWidget = () => import(/* webpackChunkName: "common" */ 'component/shared/elements/PatientNameWidget');
import SelectCostCenter from 'component/shared/elements/SelectCostCenter';
import { defineComponent } from '@vue/composition-api';
import SelectCategory from 'component/shared/elements/SelectCategory.vue';
import SelectVendor from 'component/shared/elements/SelectVendor.vue';
import SelectShippingAddress from 'component/shared/elements/SelectShippingAddress.vue';
import WarrantyReturnConfirmationModalVue from 'component/shared/WarrantyReturnConfirmationModal.vue';
import SelectManufacturer from 'component/shared/elements/SelectManufacturer';

export default defineComponent({
    components: {
        SelectManufacturer,
        AddressTooltipInformation,
        BadgeDisplay,
        CustomerDirectOrderIcon,
        LineVendorNotes,
        OrderLineHistoryModal,
        PartNumberAutocomplete,
        ProductNotesTooltip,
        SelectCategory,
        SelectPrewrittenNote,
        SelectVendor,
        ShippingLabels,
        PatientNameWidget,
        SelectCostCenter,
        SelectShippingAddress,
        StatusButtons,
        TrackingNumbers,
    },
    mixins: [systemNotifications, formatters, highlightErrorFields, unsavedNoteNotification, requiresBlacklistPassword],
    props: {
        lineId: {
            type: Number,
            required: true,
        },
        syncSidebar: {
            type: Function,
            required: true,
        },
        updateLine: {
            type: Function,
            required: true,
        },
        setLineErrors: {
            type: Function,
            required: true,
        },
        clearLineErrors: {
            type: Function,
            required: true,
        },
        setMutableLineInStore: {
            type: Function,
            required: true,
        },
        addLineToUnsavedList: {
            type: Function,
            required: true,
        },
        removeLineFromUnsavedList: {
            type: Function,
            required: true,
        },
        removeLine: {
            type: Function,
            required: true,
        },
        clearSidebarTimer: {
            type: Function,
            required: true,
        },
        getOriginalLineFromStore: {
            type: Function,
            required: true,
        },
        getMutableLineFromStore: {
            type: Function,
            required: true,
        },
        lineHasUnsavedChanges: {
            type: Function,
            required: true,
        },
        lineErrors: {
            type: Object,
            required: true,
        },
        showReturnInformation: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            originalOrder: {},
            TrackingNumberTypes,
            Statuses,
            shipDateLeadTime: 0,
            gettingEstimateForEntityType: '',
            ENTITY_TYPE,
            showDeliveredAtDatePicker: false,
            PrewrittenNoteTypes,
            trackingNumbersChanged: false,
            saveMethod: 'onSave',
        };
    },
    methods: {
        ...mapActions({
            showModal: 'shared/modal/showModal',
            closeModal: 'shared/modal/closeModal',
            getDateExpected: 'shippingEstimates/getDateExpected',
        }),
        ...mapMutations({
            setStoreQuickbarItems: 'shared/quickbar/setItems',
        }),
        ...mapGetters({
            getVendor: 'shared/vendors/selectedVendor',
        }),
        onCustomerDirectButton() {
            // Both Orders and Orders - Open use the ordersManage store.
            let storePathPrefix = 'ordersManage';
            if (this.isOrdersReview) {
                storePathPrefix = 'ordersReview';
            }
            this.showModal({
                component: ConvertCustomerDirectOrderModal,
                data: {
                    orderLines: [this.line],
                },
                bindings: {
                    'store-path-prefix': storePathPrefix,
                },
            });
        },
        getPractitionerMessageModalRoute(practitionerId) {
            let name = 'orders.search.message';

            if (!this.isOrdersSearch) {
                name = this.getRouteForCurrentContext('message');
            }

            return {
                name,
                params: {
                    practitionerId,
                },
            };
        },
        getReorderRoute(lineId) {
            let name = 'orders.search.reorder';

            if (!this.isOrdersSearch) {
                name = this.getRouteForCurrentContext('reorder');
            }

            return {
                name,
                params: {
                    id: lineId,
                },
            };
        },
        getRouteForCurrentContext(routeAppend = null) {
            let parentRoute = this.$route.name.split('.');
            parentRoute.pop();

            if (routeAppend) {
                parentRoute.push(routeAppend);
            }

            return parentRoute.join('.');
        },
        shouldRequireReadingNotes(statusId) {
            return [Statuses.ORDERED, Statuses.OPEN].includes(statusId) && this.productHasRequiredNotes;
        },
        showRequiredNotesDialogue(intendedStatusId) {
            this.showModal({
                component: ProductRequiredNotesConfirmationModal,
                bindings: {
                    notes: this.productNotes,
                },
                listeners: {
                    confirmed: () => {
                        this.doStatusChange(intendedStatusId);
                        this.closeModal();
                    },
                    close: this.closeModal,
                },
            });
        },
        showNeedMoreInfoDialogue(statusId) {
            this.showModal({
                component: LineNeedsMoreInfoModal,
                bindings: {
                    reason: this.line.needMoreInfoMessage,
                    statusId,
                },
                listeners: {
                    confirmWithMessage: (reason) => {
                        if(reason) {
                            this.setProperty('needMoreInfoMessage', reason);
                            this.doStatusChange(statusId);
                        }
                        this.closeModal();
                    },
                    close: this.closeModal,
                },
            });
        },
        async onStatusChange(statusId) {
            statusId = Number(statusId);
            const categoryId = this.line.categoryId || this.line.product?.categoryId;
            
            if (statusId === Statuses.BACKORDERED) {
                this.showModal({
                    component: ConfirmationDialogue,
                    data: {
                        title: "Don't forget!",
                        content: 'Please adjust the expected date and shipping method if necessary.',
                        onConfirmed: () => this.doStatusChange(statusId),
                    },
                });
            } else if (statusId == Statuses.OPEN && this.line.isWarrantyReplacement && categoryId) {
                const response = await apis.Categories.getCategory({
                    category_id: categoryId,
                });
                const category = response.body.data;
                if (category.show_warranty_warning) {
                    this.showModal({
                        component:WarrantyReturnConfirmationModalVue,
                        data: {
                            categoryName: category.full_name,
                            vendorName: this.line.vendor.name,
                            onConfirmed: () => this.doStatusChange(statusId),
                        }
                    })
                } else {
                    await this.doStatusChange(statusId);
                }
            } else if (statusId === Statuses.NEED_MORE_INFO || statusId === Statuses.ORDERED_NEED_MORE_INFO) {
                this.showNeedMoreInfoDialogue(statusId);
            } else if (this.shouldRequireReadingNotes(statusId)) {
                this.showRequiredNotesDialogue(statusId);
            } else {
                await this.doStatusChange(statusId);
            }
        },
        async doStatusChange(statusId) {
            this.saveMethod = `doStatusChange|${statusId}`;
            this.setNumericProperty('statusId', statusId);

            let saved = await this.onSave();

            if (!saved) {
                return;
            }

            let storedStatusId = this.$store.state.ordersManage.filters['status_id'];

            let filteredAllInclDeleted = storedStatusId == Statuses.ALL_INCL_DELETED;
            let filteredAllExclDeleted = storedStatusId == Statuses.ALL_EXCL_DELETED;
            let statusIsNotDeleted = statusId !== Statuses.DELETED;

            if (!filteredAllInclDeleted && !(filteredAllExclDeleted && statusIsNotDeleted)) {
                this.removeLine(this.line.id);
            }

            if (this.isOrdersReview || this.isOrdersManage) {
                let syncArgs = ORDERS_REVIEW_SIDEBAR_SYNC_ARGS;

                if (this.isOrdersManage) {
                    syncArgs = ORDERS_MANAGE_SIDEBAR_SYNC_ARGS;
                }

                this.clearSidebarTimer();
                this.syncSidebar(syncArgs);
                this.setStoreQuickbarItems([]);
            }
        },
        vendorBlacklistConfirmationCallback(password = '') {
            this.blacklistPassword = password;
            this.doStatusChange(Statuses.OPEN);
        },
        onIsGroundShippingUpdated(value) {
            this.setProperty('isGroundShipping', value);

            if (value) {
                this.setProperty('dateNeeded', null);
            }
        },
        onDateNeededUpdated(value) {
            this.setProperty('dateNeeded', value);

            if (value) {
                this.setProperty('isGroundShipping', 0);
            }
        },
        onIsForStockUpdated(value) {
            this.setProperty('isForStock', value);

            if (value) {
                this.setProperty('patients', []);
            }
        },
        onPatientsUpdated(value) {
            this.setProperty('patients', value);

            if (value) {
                this.setProperty('isForStock', 0);
            }
        },
        emitUpdated() {
            this.$emit('updated', this.line);
        },
        setProperty(property, value) {
            this.line[property] = value;
            this.markUnsavedIfValueChanged(property, value);
            this.emitUpdated();
        },
        setProperties(properties = {}) {
            for (let property in properties) {
                this.line[property] = properties[property];
                this.markUnsavedIfValueChanged(property, properties[property]);
            }
            this.emitUpdated();
        },
        markUnsavedIfValueChanged(property, value) {
            let storedValue = this.storedOriginalLine[property];
            let changedValue = this.line[property];
            const changedPropertyIsStored = storedValue !== undefined;
            const parsedChangedValue = parseFloat(changedValue);

            if (Number.isNaN(parsedChangedValue) === false && _.isNumber(parsedChangedValue)) {
                storedValue = storedValue || 0;
            }

            if (changedPropertyIsStored && storedValue != changedValue) {
                this.addLineToUnsavedList(this.lineId);
            }
        },
        setNumericProperty(property, value) {
            value = parseFloat(value);

            if (isNaN(value)) {
                value = 0;
            }

            this.setProperty(property, value);
        },
        showCreateShippingLabelModal() {
            this.showModal({
                data: this.line,
                component: CreateShippingLabel,
            });
        },
        showOriginalOrderModal() {
            this.showModal({
                data: {
                    orderId: this.line.orderId,
                    lineId: this.line.id,
                },
                component: OriginalOrder,
            });
        },
        formatShippingAndOrderMethod(line) {
            let details = '';

            if (line.purchaseOrder && (line.purchaseOrder.orderMethod || line.purchaseOrder.shippingMethodName)) {
                details += 'via ';
            }
            if (line.purchaseOrder && line.purchaseOrder.orderMethod) {
                details += line.purchaseOrder.orderMethod.name;
            }
            if (line.purchaseOrder && line.purchaseOrder.orderMethod && line.purchaseOrder.shippingMethodName) {
                details += ' and ';
            }
            if (line.purchaseOrder && line.purchaseOrder.shippingMethodName) {
                details += line.purchaseOrder.shippingMethodName;
            }
            return details;
        },
        onToggleOption(option) {
            this.line[option] = !this.line[option];
        },
        async onShippingAddressInput(value) {
            this.setNumericProperty('shippingAddressId', value);
            let addressInformation = await AddressesApi.get(
                {
                    id: this.line.shippingAddressId,
                    includes: ['notes'],
                },
                {
                    hideLoader: true,
                }
            );

            if (addressInformation.data.data) {
                this.line.shippingAddress = addressInformation.data.data;
            }
            this.fetchSubaccountNumber();
            this.addLineToUnsavedList(this.lineId);
        },
        getAutocompleteClasses() {
            let classes = { input: 'form-control ', wrapper: 'field column is-11', label: '' };

            if (this.isPriceListItem) {
                classes.input += ' is-pricelist-product';
            }

            return classes;
        },
        onPrintPackingSlip() {
            if (!this.line.purchaseOrder) {
                this.showDangerNotification("This Line doesn't have Purchase Order to be printed");
                return false;
            }

            this.showModal({
                data: this.line,
                component: PrintOrderModal,
                bindings: {
                    isPrintOrder: false,
                },
            });
        },
        async getWarehousesFor(entityType) {
            let entity = this.line[entityType];

            const warehouses = _.get(entity, 'warehouses', []).filter(({ statusId }) => statusId == Statuses.ACTIVE);

            if (warehouses.length > 1) {
                this.showWarehouseSelectionModal(entityType, warehouses);
                return;
            }

            if (warehouses.length === 1) {
                await this.selectShippingServiceFor({
                    entityType,
                    vendor: null, //if there's a single warehouse, the vendor data isn't necessary - JJB
                    warehouse: warehouses[0],
                });
                return;
            }

            await this.selectShippingServiceFor({
                entityType,
                vendor: entity,
                warehouse: null,
            });
        },
        showWarehouseSelectionModal(entityType, warehouses) {
            this.showModal({
                data: {
                    entityType,
                    type: 'ground',
                    onSubmit: this.onWarehouseSelected.bind(this),
                    warehouses,
                    line: this.line,
                },
                component: ChooseWarehouse,
            });
        },
        async onWarehouseSelected({ entityType, warehouse }) {
            this.setProperty('warehouseId', warehouse.id);
            await this.selectShippingServiceFor({ entityType, vendor: null, warehouse });
        },
        async selectShippingServiceFor({ entityType, vendor, warehouse }) {
            const shippingServices = _.get(this.line, `${entityType}.shippingServices`, []).filter(
                (service) => service.routeKey !== null
            );

            if (shippingServices.length === 0) {
                let entityName = _.get(this.line, `${entityType}.name`, _.startCase(entityType));
                this.showDangerNotification(`${entityName} does not have a shipping service enabled.`);
                return;
            }

            if (shippingServices.length > 1) {
                this.showDateExpectedSelectionModal({ shippingServices, entityType, vendor, warehouse });
                return;
            }

            await this.getDateExpectedEstimate({
                shippingServiceKey: shippingServices[0].routeKey,
                warehouse,
                vendor,
                entityType,
            });
        },
        showDateExpectedSelectionModal({ shippingServices, entityType, vendor, warehouse }) {
            const onSubmitCallback = function (expectedDate) {
                this.setShipDateLeadTime(vendor, warehouse);
                this.setExpectedDate(expectedDate);
            };

            this.showModal({
                data: {
                    entityType,
                    vendor,
                    warehouse,
                    shippingServices,
                    shippingAddressId: this.line.shippingAddress.id,
                    onSubmit: onSubmitCallback.bind(this),
                },
                component: ChooseDateExpectedModal,
            });
        },
        setExpectedDate(expectedDate) {
            this.setProperty('dateExpected', expectedDate);
        },
        async getDateExpectedEstimate({ warehouse, vendor, shippingServiceKey, entityType }) {
            this.gettingEstimateForEntityType = entityType;

            let vendorZipcode = '';
            let payload = {
                shippingAddressId: this.line.shippingAddressId,
                shippingServiceKey,
            };

            if (warehouse) {
                vendorZipcode = warehouse.zipcode;
                payload['warehouseId'] = warehouse.id;
            } else {
                vendorZipcode = vendor.zipcode;
                payload['vendorId'] = vendor.id;
            }

            try {
                this.validateZipcode(entityType, vendorZipcode);
                this.validateZipcode('Ship To', this.line.shippingAddress.zipcode);

                const response = await this.getDateExpected(payload);
                this.setShipDateLeadTime(vendor, warehouse);
                this.setExpectedDate(response.dateExpected);
            } catch (e) {
                if (e.hasOwnProperty('response') === false) {
                    this.showDangerNotification(e.message);
                }
                // TODO: Normalize validation responses and improve this process.
                const message = _.get(
                    e,
                    'response.data.generalErrors.vendor_id',
                    _.get(e, 'response.data.generalErrors.warehouse_id')
                );
                if (message) {
                    this.showDangerNotification(message);
                }
            }
            this.gettingEstimateForEntityType = '';
        },
        gettingEstimateFor(entityType) {
            return this.gettingEstimateForEntityType === entityType;
        },
        validateZipcode(type, zipcode) {
            if (!zipcode) {
                throw Error(`The selected ${type} does not have a Zipcode, which is required.`);
            }

            const zipcodeLength = zipcode.replace(/[^\d]+/g, '').length;

            if (zipcodeLength !== 5 && zipcodeLength !== 9) {
                throw Error(`The selected ${type} does not have a valid Zipcode.`);
            }
        },
        async onSave() {
            this.saveMethod = 'onSave';

            if (!this.notifyForUnsavedNotes()) {
                return false;
            }

            if (typeof this.$refs.trackingNumberWidget !== 'undefined') {
                this.$refs.trackingNumberWidget.onSetEditMode(false);
            }

            // [DJF]: I believe this next tick is only required because
            //        of the need to wait for the tracking number widget
            //        to close.
            await this.$nextTick();

            if (this.blacklistPassword) {
                this.line['password'] = this.blacklistPassword;
            }

            this.line.trackingNumbers = this.line.trackingNumbers.filter(
                (trackingNumber) => trackingNumber.tracking_number
            );

            if (this.trackingNumbersChanged) {
                this.line.trackingNumbers = this.line.trackingNumbers.map((trackingNumber) => {
                    trackingNumber.tracking_number = trackingNumber.tracking_number.trim().toUpperCase();
                    return trackingNumber;
                });
                const trackingNumbersAccurate = await this.checkTrackingNumberAccuracy();
                if (!trackingNumbersAccurate) {
                    return false;
                }
            }

            let originalStatusId = this.storedOriginalLine.statusId;

            try {
                this.clearLineErrors(this.line.id);
                await this.updateLine(this.line);
                this.formatAmounts();
                this.showSuccessNotification('Successfully Updated', { duration: 1000 });
            } catch (e) {
                this.line.statusId = originalStatusId;
                if (e.response) {
                    let responseData = e.response.data;
                    if (responseData.generalErrors) {
                        if (this.errorIsBlacklistRelated(responseData.generalErrors)) {
                            this.resetBlacklistPassword();
                            this.showVendorBlacklistConfirmationModal(
                                this.vendorBlacklistConfirmationCallback.bind(this),
                                { isMultiple: false }
                            );
                            this.showDangerNotification('Please provide the vendor blacklist password.');
                        } else {
                            if(Object.keys(responseData.generalErrors).length === 1) {
                                this.showDangerNotification(responseData.generalErrors[Object.keys(responseData.generalErrors)[0]]);
                            } else {
                                this.showDangerNotification(responseData.message);
                                this.setLineErrors({ lineId: this.line.id, value: e.response.data.generalErrors });
                            }
                        }
                    }
                    if (responseData.errors.length) {
                        this.highlightErrorFields(e.response.data);
                        this.setLineErrors({ lineId: this.line.id, value: e.response.data.errors[0] });
                        this.showDangerNotification(e.response.data.message);
                    }
                } else {
                    // If the error does not have response property, then it was likely a client side error or a network error
                    Bugsnag.notify(e);
                }
                return false;
            }

            return true;
        },
        async checkTrackingNumberAccuracy() {
            if (this.line.trackingNumbers.length == 0) {
                return true;
            }

            let trackingNumbers = this.line.trackingNumbers.map(({ tracking_number }) => tracking_number);

            let response;

            try {
                response = await apis.TrackingNumbers.verifyShipperAccuracy(
                    {},
                    {
                        requestBody: {
                            vendor_id: this.line.vendorId,
                            tracking_numbers: trackingNumbers,
                        },
                    }
                );
            } catch (e) {
                console.log(e);
                Bugsnag.notify(e);
                this.showDangerNotification(
                    'An error occurred while verifying vendor tracking number accuracy. Alert the developers if the issue persists.'
                );
            }

            const allCorrect = _.get(response, 'body.allTrackingNumbersCorrect', false);
            if (!allCorrect) {
                const inaccurateTrackingNumbers = _.get(response, 'body.inaccurateTrackingNumbers', [
                    'Unable to retrieve tracking numbers',
                ]);
                this.showInaccurateTrackingNumberModal(inaccurateTrackingNumbers);
            }

            return allCorrect;
        },
        showInaccurateTrackingNumberModal(inaccurateTrackingNumbers) {
            this.showModal({
                component: InaccurateTrackingNumbersModal,
                data: {
                    trackingNumbers: inaccurateTrackingNumbers,
                    vendorName: _.get(this, 'line.vendor.name', ''),
                    callback: this.onCloseInaccurateTrackingNumberModal.bind(this),
                },
            });
        },
        async onCloseInaccurateTrackingNumberModal({ confirmed }) {
            this.trackingNumbersChanged = !confirmed;

            if (confirmed) {
                await this.recallSaveMethod();
            }
        },
        async recallSaveMethod() {
            let [saveMethod, argument] = this.saveMethod.split('|');
            await this[saveMethod](argument);
        },
        onCancel() {
            this.line = _.cloneDeep(this.storedOriginalLine);
            this.formatAmounts();
            this.removeLineFromUnsavedList(this.lineId);
            this.clearLineErrors();
        },
        getProductExceptionForFacility(product) {
            let exception = product.priceExceptions.find(
                function (ex) {
                    let facility_key = 'facilityId';
                    if (product.priceExceptions.length > 0 && 'facility_id' in product.priceExceptions[0]) {
                        facility_key = 'facility_id';
                    }
                    return ex[facility_key] === this.line.facilityId;
                }.bind(this)
            );

            return exception;
        },
        getProductCostForFacility(product) {
            let cost = product.cost;

            if (product.priceExceptions) {
                let facilityException = this.getProductExceptionForFacility(product);

                if (facilityException) {
                    cost = facilityException.cost;
                }
            }

            return cost;
        },
        getProductPriceForFacility(product) {
            let price = product.price;

            if (product.priceExceptions) {
                let facilityException = this.getProductExceptionForFacility(product);

                if (facilityException) {
                    price = facilityException.price;
                }
            }

            return price;
        },
        async onSelectProduct(product) {
            await this.setProductProperties(product);

            const promises = [
                this.onVendorChange(_.get(product, 'vendor_id', product.vendorId), false),
                this.onManufacturerChange(_.get(product, 'manufacturer_id', product.manufacturerId)),
            ];

            await Promise.all(promises);

            this.calcBasedOnPrice();
        },
        async setProductProperties(product) {
            const [priceExceptionsRequest, notesRequest] = await Promise.all([
                apis.Products.listPriceExceptionsForProduct({
                    product_id: product.id,
                }),
                apis.Notes.listNotesForNoteable({
                    noteable_type: 'product',
                    noteable_id: product.id,
                }),
            ]);

            this.$set(product, 'priceExceptions', priceExceptionsRequest.body.data);
            this.$set(product, 'notes', notesRequest.body.data);

            this.setProperties({
                partNumber: _.get(product, 'part_number', product.partNumber),
                productId: product.id,
                categoryId: _.get(product, 'category_id', product.categoryId),
                description: product.description,
                product: product,
                cost: this.getProductCostForFacility(product),
                price: this.getProductPriceForFacility(product),
            });
        },
        async onCostCenterInput(value) {
            this.setNumericProperty('costCenterId', value);
            let addressResponse = await AddressesApi.get(
                {
                    id: parseInt(value),
                    includes: ['notes'],
                },
                { hideLoader: true }
            );
            let address = addressResponse.data.data;
            this.line.costCenter = address;
        },
        async onSelectVendor(vendorId) {
            if (!this.line.partNumber) {
                await this.onVendorChange(vendorId);
                return;
            }

            let products = await this.findProductsWithPartNumberForVendorId(this.line.partNumber, vendorId);

            if (products.length) {
                await this.onSelectProduct(products[0]);
                return;
            }

            this.setProperty('product', {});
            await this.onVendorChange(vendorId);
        },
        async findProductsWithPartNumberForVendorId(partNumber = '', vendorId) {
            let products = [];
            partNumber = partNumber.trim();

            if (partNumber) {
                let result = await ProductApi.get({
                    part_number_exact: partNumber,
                    vendor_id: vendorId,
                    includes: ['manufacturer', 'vendor', 'priceExceptions', 'category'],
                    status_id: Statuses.ACTIVE,
                });

                products = result.data.data;
            }

            return products;
        },
        async onVendorChange(vendorId, updateMarkup = true) {
            vendorId = parseInt(vendorId, 10);
            const vendorResponse = await VendorsApi.get(
                { id: vendorId, includes: ['notes', 'representatives', 'warehouses', 'shippingServices'] },
                { hideLoader: true }
            );
            const vendor = vendorResponse.data.data;
            this.line.vendorId = vendorId;
            this.line.vendor = vendor;

            if (updateMarkup) {
                this.line.markup = vendor.defaultMarkup;
                this.calcBasedOnMarkup();
            }
            this.fetchSubaccountNumber();
            this.emitUpdated();
            this.line.warehouseId = null;
            this.setWarehouseIfSolo();
        },
        async onManufacturerChange(manufacturerId) {
            manufacturerId = manufacturerId || VARIOUS_SUPPLIER_VENDOR_ID;
            manufacturerId = parseInt(manufacturerId, 10);

            const manufacturerResponse = await VendorsApi.get(
                { id: manufacturerId, includes: ['notes', 'warehouses', 'shippingServices'] },
                { hideLoader: true }
            );
            const manufacturer = manufacturerResponse.data.data;

            this.setNumericProperty('manufacturerId', manufacturerId);
            this.setProperty('manufacturer', manufacturer);
        },
        filterAmountInput(event) {
            if (event.key.match(/[^\d\\\-\+\*\/\.\(\)\%\ ]/i)) {
                event.preventDefault();
            }
        },
        onQuantityBlur(event) {
            const value = evalMathExpression(event.target.value);
            this.setNumericProperty('quantity', value);
        },
        onCostBlur($event) {
            const value = evalMathExpression($event.target.value);
            this.calcBasedOnCost(value);
            this.setProperty('cost', numberFormat(value, 2));
        },
        onMarkupBlur($event) {
            const value = evalMathExpression($event.target.value);
            this.calcBasedOnMarkup(value);
            this.setProperty('markup', numberFormat(value, 3));
        },
        onPriceBlur($event) {
            const value = evalMathExpression($event.target.value);
            this.calcBasedOnPrice(value);
            this.setProperty('price', numberFormat(value, 2));
        },
        calcBasedOnCost(cost = this.line.cost) {
            let price = this.line.price;
            let markup = this.line.markup;

            if (!cost || cost <= 0) {
                return;
            }

            if (markup) {
                price = cost * (markup / 100 + 1);
            } else {
                price = cost;
                markup = 0;
            }

            this.setProperties({
                cost,
                price: numberFormat(price, 2),
                markup: numberFormat(markup, 3),
                priceAfterRebate: this.calcPriceAfterRebate(price),
            });
        },
        calcBasedOnMarkup(markup = this.line.markup) {
            let price = this.line.price;
            let cost = this.line.cost;

            if (!markup) {
                markup = 0;
            }

            if (cost) {
                price = cost * (1 + markup / 100);
            } else if (!cost && !price) {
                cost = 0;
                price = 0;
            }

            this.setProperties({
                markup,
                price: numberFormat(price, 2),
                cost: numberFormat(cost, 2),
                priceAfterRebate: this.calcPriceAfterRebate(price),
            });
        },
        calcBasedOnPrice(price = this.line.price) {
            let markup = this.line.markup;
            let cost = this.line.cost;

            if (!price) {
                return;
            }

            if (cost) {
                markup = (price / cost - 1) * 100;
            } else if (!markup && !cost) {
                markup = 0;
                cost = 0;
            }

            this.setProperties({
                price,
                cost: numberFormat(cost, 2),
                markup: numberFormat(markup, 3),
                priceAfterRebate: this.calcPriceAfterRebate(price),
            });
        },
        calcPriceAfterRebate(price) {
            if (this.rebatePercent) {
                price = price * (1 - parseFloat(this.rebatePercent / 100));

                if (isNaN(price)) {
                    price = 0;
                }
            }

            return numberFormat(price);
        },
        async fetchSubaccountNumber() {
            const response = await SmartApi.get({
                routeName: 'addresses.vendor.show',
                routeParams: { address: this.line.shippingAddress.id, vendor: this.line.vendorId },
                config: { hideLoader: true },
            });
            let vendors = this.line.shippingAddress.vendors || [];
            vendors.push(response.data.data);
            this.$set(this.line.shippingAddress, 'vendors', vendors);
        },
        formatAmounts() {
            this.setProperties({
                markup: numberFormat(this.line.markup, 3),
                price: numberFormat(this.line.price, 2),
                cost: numberFormat(this.line.cost, 2),
                shipping: numberFormat(this.line.shipping, 2),
                oldPrice: numberFormat(this.line.oldPrice, 2),
            });
        },
        numberFormat,
        setShipDateLeadTime(vendor, warehouse) {
            if (!vendor) {
                let vendorId = _.get(warehouse, 'vendorId', 0);
                vendor = this.getVendor()(vendorId);
            }

            let leadtime = _.get(vendor, 'shipDateLeadTime', 0);

            this.shipDateLeadTime = leadtime;
        },
        setWarehouseIfSolo() {
            if (this.warehouses.length === 1) {
                this.line.warehouseId = this.warehouses[0].id;
                this.line.warehouse = this.warehouses[0];
            }
        },
        findWarehouse(warehouseId) {
            let vendor = this.vendor || {};
            let warehouses = vendor.warehouses || [];
            let warehouse = warehouses.find(({ id }) => warehouseId == id) || {};
            return warehouse;
        },
        calculateDiscount,
        calculateCustomerDiscount,
        updateInternalNote(updatedNote) {
            this.$refs.internalNotes.body = updatedNote;
        },
        onTrackingNumbersChanged() {
            this.trackingNumbersChanged = true;
        },
        showReturnRequestsModal() {
            this.showModal({
                component: ReturnRequestsModal,
                data: {
                    line: this.line,
                },
            });
        },
        /**
         * Return the value at the camelCase or snake_case path of object.
         *
         * The defaultValue is returned if both the camelCase and snake_case paths resolve to an undefined value.
         * @param {*} object
         * @param {String} path
         * @param {*} defaultValue
         */
        getCamelOrSnakeCase(object, path, defaultValue) {
            return _.get(object, _.camelCase(path), _.get(object, _.snakeCase(path), defaultValue));
        },
    },
    computed: {
        ...mapGetters({
            getFacilityPractitioners: 'shared/users/facilityPractitioners',
        }),
        productHasRequiredNotes() {
            return this.productNotes.filter((note) => note.type === 'required').length > 0;
        },
        vendorRebates() {
            let vendors = _.clone(this.facilityVendors);
            vendors = _.sortBy(vendors, ['name']);

            return vendors.map((vendor) => {
                let percent = parseFloat(vendor.rebatePercent);
                return `${vendor.name}: ${percent}%`;
            });
        },
        autocompleteConfig() {
            return {
                customParams: {
                    includes: ['manufacturer', 'vendor', 'priceExceptions', 'notes', 'category'],
                },
                disabled: !this.enabledFields.part_number,
            };
        },
        isOrdersReview() {
            return this.$route.name === 'orders.review.results';
        },
        isOrdersManage() {
            return this.$route.name === 'orders.manage.results';
        },
        isOrdersSearch() {
            return !this.isOrdersReview && !this.isOrdersManage;
        },
        priceAfterRebate() {
            if (this.shouldIncludeRebate == false) {
                return 'N/A';
            }

            return this.calcPriceAfterRebate(this.line.price);
        },
        totalAfterRebate() {
            if (this.shouldIncludeRebate == false) {
                return 'N/A';
            }

            return numberFormat(this.priceAfterRebate * this.line.quantity);
        },
        rebatePercent() {
            if (!this.shouldIncludeRebate) {
                return '0.000';
            }

            if (this.isUnbilled) {
                return this.facilityVendorRebatePercent;
            }

            return this.line.customerRebatePercent;
        },
        facilityPractitioners() {
            return this.getFacilityPractitioners(this.line.facilityId);
        },
        facilityVendors() {
            return _.get(this.line, 'facility.vendors', []);
        },
        facilityVendor() {
            return this.facilityVendors.find((vendor) => vendor.id == this.vendor.id) || {};
        },
        facilityVendorRebatePercent() {
            return _.get(this.facilityVendor, 'rebatePercent', 0);
        },
        storedOriginalLine() {
            return this.getOriginalLineFromStore(this.lineId);
        },
        line: {
            get() {
                return this.getMutableLineFromStore(this.lineId);
            },
            set(value) {
                this.setMutableLineInStore(value);
            },
        },
        selectedPractitioner() {
            const practitioner = this.facilityPractitioners.find(
                (practitioner) => this.line.practitionerId == practitioner.id
            );

            if (practitioner) {
                return practitioner;
            }

            return {};
        },
        confirmationNumber() {
            return _.get(this.line, 'purchaseOrder.confirmationNumber', null);
        },
        purchaseOrderShippingMethodId() {
            return _.get(this.line, 'purchaseOrder.shippingMethodId', null);
        },
        productNotes() {
            return _.get(this.line, 'product.notes', []);
        },
        adminNotes() {
            return this.line.notes.filter((note) => note.type === 'admin');
        },
        cutoffTimeValue() {
            return _.get(this.line, 'manufacturer.cutoffTimeValue', null);
        },
        calculatedSavings() {
            let savings = (this.line.oldPrice - this.priceAfterRebate) * this.line.quantity - this.line.shipping;
            return this.numberFormat(savings);
        },
        isPriceListItem() {
            const product = _.get(this.line, 'product');

            if (!product) {
                return false;
            }

            let type_id_key = 'type.id';
            if ('type_id' in product) {
                type_id_key = 'type_id';
            }

            return _.get(this.line, `product.${type_id_key}`) === 5;
        },
        shouldHighlightCost() {
            let shouldHighlightCost = false;

            if (this.isPriceListItem) {
                let facilityPriceExceptionCost = this.getProductCostForFacility(this.line.product);

                if (facilityPriceExceptionCost != this.line.cost) {
                    shouldHighlightCost = true;
                }
            }

            return shouldHighlightCost;
        },
        shouldHighlightPrice() {
            let shouldHighlightPrice = false;

            if (this.isPriceListItem) {
                let facilityPriceExceptionPrice = this.getProductPriceForFacility(this.line.product);

                if (facilityPriceExceptionPrice != this.line.price) {
                    shouldHighlightPrice = true;
                }
            }

            return shouldHighlightPrice;
        },
        showCutOff() {
            return this.cutoffTimeValue && [Statuses.OPEN, Statuses.PENDING_REVIEW].includes(this.line.status.id);
        },
        facilityName() {
            return _.get(this.line, 'facility.name', null);
        },
        isFlagshipFacility() {
            return _.get(this.line, 'facility.isFlagship', false);
        },
        babysitFacility() {
            return _.get(this.line, 'facility.babysit', false);
        },
        facilityId() {
            return _.get(this.line, 'facility.id', 0);
        },
        vendor() {
            return this.line.vendor || false;
        },
        purchaseOrderIdWithSuffix() {
            return _.get(this.line, 'purchaseOrder.idWithSuffix', '');
        },
        shouldHighlightCustomerNote() {
            return this.line.customerNote && this.line.customerNote.length;
        },
        subaccountNumber() {
            return this.addressVendor.subaccountNumber || '';
        },
        customerAccountNumber() {
            return this.addressVendor.accountNumber || '';
        },
        addressVendor() {
            if (this.line.shippingAddress) {
                let vendors = this.line.shippingAddress.vendors || [];
                return vendors.find((vendor) => vendor.id == this.line.vendorId) || {};
            }
            return {};
        },
        isBilled() {
            return !this.isUnbilled;
        },
        isUnbilled() {
            return this.line.billingStatus.id === Statuses.OPEN;
        },
        isCustomerDirectOrder() {
            return this.line.orderMethodId === OrderMethods.CUSTOMER_DIRECT_ORDER;
        },
        isSelectVendorDisabled() {
            return this.hasBeenOrdered;
        },
        hasBeenOrdered() {
            const hasBeenOrdered = [
                Statuses.ORDERED,
                Statuses.RECEIVED,
                Statuses.PARTIALLY_RECEIVED,
                Statuses.DELETED,
            ];
            return hasBeenOrdered.includes(this.line.statusId);
        },
        preOrder() {
            const preOrder = [
                Statuses.PENDING_REVIEW,
                Statuses.OPEN,
                Statuses.ON_HOLD,
                Statuses.NEED_MORE_INFO,
                Statuses.WAITING_FOR_VENDOR,
            ];
            return preOrder.includes(this.line.statusId);
        },
        postOrder() {
            const postOrder = [
                Statuses.ORDERED,
                Statuses.ORDERED_NEED_MORE_INFO,
                Statuses.PARTIALLY_RECEIVED,
                Statuses.RECEIVED,
                Statuses.BACKORDERED,
            ];
            return postOrder.includes(this.line.statusId);
        },
        shouldIncludeRebate() {
            if (this.isUnbilled) {
                return !_.get(this.line, 'product.skipRebates', 0);
            }
            return this.line.customerRebatePercent;
        },
        warehouses() {
            return _.get(this.vendor, 'warehouses', []);
        },
        enabledFields() {
            const preOrder = this.preOrder;
            const postOrder = this.postOrder;
            const isUnbilled = this.isUnbilled;
            const deleted = this.line.statusId == Statuses.DELETED;
            return {
                ship_to: preOrder,
                cost_center: preOrder || (isUnbilled && postOrder),
                practitioner: preOrder || postOrder,
                patient_name: preOrder || postOrder,
                for_stock: preOrder || postOrder,
                job_number: preOrder || postOrder,
                vendor: preOrder,
                part_number: preOrder || (isUnbilled && postOrder),
                description: preOrder || (isUnbilled && postOrder),
                manufacturer: preOrder || postOrder,
                category: preOrder || postOrder,
                quantity: preOrder || postOrder,
                cost: preOrder || (isUnbilled && postOrder),
                markup: preOrder || (isUnbilled && postOrder),
                price: preOrder || (isUnbilled && postOrder),
                shipping: preOrder || postOrder,
                old_price: preOrder || postOrder,
                date_needed: preOrder || postOrder,
                date_expected: preOrder || postOrder,
                delivered_date: postOrder,
                warehouse: preOrder || postOrder,
                tracking_numbers: postOrder,
                po_number: preOrder,
                item_notes_for_po: preOrder,
                internal_notes: preOrder || postOrder || deleted,
                return_requests: postOrder,
            };
        },
        isPendingReview() {
            return this.line.statusId === Statuses.PENDING_REVIEW;
        },
        listPrice() {
            return this.getCamelOrSnakeCase(this.line.product, 'manufacturerListPrice');
        },
        empireDiscount() {
            return this.calculateDiscount(this.line.cost, this.listPrice);
        },
        customerDiscount() {
            return this.calculateCustomerDiscount(this.line.price, this.listPrice);
        },
    },
    watch: {
        rebatePercent(value) {
            this.setProperty('customerRebatePercent', value);
        },
        'line.warehouseId': {
            handler(val, oldVal) {
                let warehouse = this.findWarehouse(val);
                this.line.warehouse = warehouse;
            },
        },
        'line.deliveredAt': {
            handler: function (val, oldVal) {
                if (oldVal && !val) {
                    this.showDeliveredAtDatePicker = false;
                } else if (val) {
                    this.showDeliveredAtDatePicker = true;
                }
            },
        },
    },
    created() {
        this.formatAmounts();
        if (!this.line.warehouseId) {
            this.setWarehouseIfSolo();
        }
        if (this.line.deliveredAt) {
            this.showDeliveredAtDatePicker = true;
        }
    },
});
</script>

<style lang="scss">
.table-line-subcontent {
    padding: 1vh 1vw;
    transition: background-color 0.3s ease-in-out;

    &.unsaved-changes {
        background-color: #cae1f1;
    }

    .no-wrap {
        white-space: nowrap;
    }

    .has-tooltip {
        padding: 0;
        margin-top: 3em;
        margin-bottom: 0;
        .tooltip {
            strong {
                color: #fff;
            }
        }
    }
    .has-icon {
        padding: 0;
        margin-top: 2.6em;
        margin-bottom: 0;
    }
    .help {
        margin-top: 3em;
        &.is-danger {
            margin-top: 0em;
        }
    }
}

.delivery-date-input {
    max-width: 6rem;
    cursor: pointer;
}
</style>

<style lang="scss" scoped>
.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
}

.no-padding-bottom {
    padding-bottom: 0;
}

div.is-highlighted {
    border: none;
    background-color: #f6bd60;
}

.line-option {
    position: relative;

    &__checkbox {
        appearance: none;
        position: absolute;
        z-index: 0;
    }

    &__overlay {
        position: relative;
        z-index: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        cursor: pointer;
    }

    .overlay-red {
        background-color: #d7263d;
        color: #fff;
    }

    .overlay-purple {
        background-color: #985f99;
        color: #fff;
    }

    .overlay-orange {
        background-color: #f7b267;
    }

    .overlay-yellow {
        background-color: #f6bd60;
    }
}

.email-icon,
.sms-icon {
    cursor: pointer;
    text-decoration: none;
}

.is-pricelist-product {
    height: 2.4em;
}

.customer-note,
.internal-notes {
    max-width: 16.5vw;
    word-wrap: break-word;
    white-space: pre-line;
}

@media screen and (max-width: 768px) {
    .customer-note,
    .internal-notes {
        max-width: 100%;
    }
}

.regional-delivery {
    margin-top: 0.5em;
}

.account-numbers {
    font-size: 0.75rem;
}
.cost-label {
    margin-bottom: 0.5em;
}

.already-billed {
    max-width: 24rem;
    padding-left: 1rem;
}
</style>
