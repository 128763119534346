<template>
    <line-subcontent-data
        v-on="$listeners"
        v-if="isOpen"
        :line-id="lineId"
        :syncSidebar="syncSidebar"
        :updateLine="updateLine"
        :setLineErrors="setLineErrors"
        :clearLineErrors="clearLineErrors"
        :setMutableLineInStore="setMutableLineInStore"
        :addLineToUnsavedList="addLineToUnsavedList"
        :removeLineFromUnsavedList="removeLineFromUnsavedList"
        :removeLine="removeLine"
        :clearSidebarTimer="clearSidebarTimer"
        :getOriginalLineFromStore="getOriginalLineFromStore"
        :getMutableLineFromStore="getMutableLineFromStore"
        :lineHasUnsavedChanges="lineHasUnsavedChanges"
        :lineErrors="lineErrors"
    />
</template>

<script>
import LineSubcontentData from './LineSubcontentData';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    components: {
        LineSubcontentData,
    },
    props: {
        lineId: {
            type: Number,
            required: true,
        },
    },
    methods: {
        ...mapActions({
            syncSidebar: 'ordersManage/syncSidebarData',
            updateLine: 'ordersManage/updateLine',
        }),
        ...mapMutations({
            setLineErrors: 'ordersManage/setLineErrors',
            clearLineErrors: 'ordersManage/clearLineErrors',
            setMutableLineInStore: 'ordersManage/setMutableLine',
            addLineToUnsavedList: 'ordersManage/addLineToUnsavedList',
            removeLineFromUnsavedList: 'ordersManage/removeLineFromUnsavedList',
            removeLine: 'ordersManage/removeLine',
            clearSidebarTimer: 'ordersManage/clearSidebarSyncTimer',
        }),
    },
    computed: {
        ...mapGetters({
            isLineOpen: 'ordersManage/isLineOpen',
            getOriginalLineFromStore: 'ordersManage/getLineById',
            getMutableLineFromStore: 'ordersManage/getMutableLineById',
            lineHasUnsavedChanges: 'ordersManage/lineHasUnsavedChanges',
            getErrorsForLine: 'ordersManage/getErrorsForLine',
        }),
        isOpen() {
            return this.isLineOpen({ id: this.lineId });
        },
        lineErrors() {
            return this.getErrorsForLine(this.lineId);
        },
    },
};
</script>
