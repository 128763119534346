import { computed } from '@vue/composition-api';
import store from 'store';

export default function useCurrentUser() {
    /** @type {import('@vue/composition-api').ComputedRef<Boolean>} currentUserIsAdmin */
    const currentUserIsAdmin = computed(() => store.getters['shared/currentUser/isAdmin']);
    /** @type {import('@vue/composition-api').ComputedRef<Boolean>} currentUserIsFacility */
    const currentUserIsFacility = computed(() => store.getters['shared/currentUser/isFacility']);
    /** @type {import('@vue/composition-api').ComputedRef<User>} currentUser */
    const currentUser = computed(() => store.getters['shared/currentUser/user']);

    /**
     * @param {String} scopeSlug
     * @returns Boolean
     */
    function currentUserCan(scopeSlug) {
        return store.getters['shared/currentUser/can'](scopeSlug);
    }

    return {
        currentUserIsAdmin,
        currentUserIsFacility,
        currentUserCan,
        currentUser,
    };
}
