import DataLoader from './DataLoader';

class OriginalOrderDataLoader extends DataLoader {
    static get actions() {
        let actions = [
            {
                name: 'shared/users/getDropdownData',
            },
            {
                name: 'shared/vendors/getDropdownData',
            },
        ];

        return actions;
    }
}

export default new OriginalOrderDataLoader();
