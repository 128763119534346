export default {
    mapper: {
        relations: {
            belongsTo: {
                message: {
                    foreignKey: 'parent_id',
                    localField: 'parent',
                },
            },
            hasMany: {
                message: {
                    foreignKey: 'data->thread',
                    localField: 'thread',
                },
            },
        },
    },
};
