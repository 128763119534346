/**
 * @param {String} message
 */
function showSuccessNotification(message) {
    if (this.showSuccessNotification) {
        this.showSuccessNotification(message);
    } else {
        window.alert(message);
    }
}

/**
 * @param {string} message
 */
function showDangerNotification(message) {
    if (this.showDangerNotification) {
        this.showDangerNotification(message);
    } else {
        window.alert(message);
    }
}

export default function useDialogues() {
    return {
        showSuccessNotification,
        showDangerNotification,
    };
}
