<template>
    <emp-component-modal @modal:close="onClose" :isActive="isActive" :contentClass="contentClass">
        <template v-if="isActive">
            <div slot="modal-header">
                <slot name="modal-header" v-bind:original="order"> Original Order </slot>
            </div>
            <div slot="modal-body">
                <div class="message-body-content original-order-modal" v-if="order">
                    <div class="mobile-table-wrapper">
                        <table class="table is-fullwidth">
                            <tr>
                                <td class="left-column"><label class="label">Order #</label></td>
                                <td>{{ order.id }}</td>
                            </tr>
                            <tr v-if="order.purchaseOrderId">
                                <td class="left-column"><label class="label">PO #</label></td>
                                <td>{{ order.purchaseOrderId }}</td>
                            </tr>
                            <tr>
                                <td class="left left-column" colspan="2"><label class="label">Lines:</label></td>
                            </tr>
                            <tr
                                v-for="(line, index) in lines"
                                :key="`original_order_line_${index}`"
                                :class="['notification', { 'is-warning': isSelectedLine(data.lineId, line.id) }]"
                            >
                                <td class="left">{{ index + 1 }}</td>
                                <td class="right line_detail">
                                    <div v-if="originalDataExists(line)">
                                        <div><strong>Vendor:</strong> {{ line.vendorName }}</div>
                                        <div><strong>Ship To:</strong> {{ line.shippingAddressName }}</div>
                                        <div><strong>Cost Center:</strong> {{ line.costCenterName }}</div>
                                        <div><strong>Practitioner:</strong> {{ line.practitionerName }}</div>
                                        <div><strong>Part #:</strong> {{ line.partNumber }}</div>
                                        <div><strong>Quantity:</strong> {{ line.quantity }}</div>
                                        <div><strong>Part Description:</strong> {{ line.description }}</div>
                                        <div><strong>Manufacturer:</strong> {{ line.manufacturerName }}</div>
                                        <div><strong>Patient Names/IDs:</strong> {{ line.patients }}</div>
                                        <div><strong>Need By:</strong> {{ formatDateNeeded(line) }}</div>
                                        <div><strong>Notes:</strong> {{ line.customerNote }}</div>
                                        <div
                                            ><strong>Do Not Sub:</strong>
                                            {{ convertBoolToString(line.isDoNotSub) }}</div
                                        >
                                        <div
                                            ><strong>Put On Hold:</strong>
                                            {{ convertBoolToString(line.isPutOnHold) }}</div
                                        >
                                        <div>
                                            <strong>Warranty Replacement:</strong>
                                            {{ convertBoolToString(line.isWarrantyReplacement) }}
                                        </div>
                                        <div>
                                            <strong>Email Me When Received :</strong>
                                            {{ convertBoolToString(line.sendNotificationWhenReceived) }}
                                        </div>
                                        <div><strong>Job #:</strong> {{ line.jobNumber }}</div>
                                    </div>
                                    <div
                                        v-else-if="
                                            !originalDataExists(line) && lineOrderedAfterOriginalOrderPlaced(line)
                                        "
                                        >This item was ordered after the original order was placed, and therefore does
                                        not include original order data.</div
                                    >
                                    <div v-else>Original order unavailable for this order line.</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="message-body-content original-order-modal" v-else>
                    <p>Original order data is not available for this line.</p>
                </div>
                <div class="message-body-buttons">
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="level-item">
                                <emp-button label="Close" @click="onClose" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </emp-component-modal>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import OriginalOrderDataLoader from 'data-loaders/OriginalOrderDataLoader';
import { OrderMethods } from 'enums';

export default {
    props: {
        contentClass: {
            type: String,
            default: '',
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            lines: [],
            order: null,
        };
    },
    methods: {
        ...mapActions({
            onClose: 'shared/modal/closeModal',
        }),
        async getData() {
            if (!this.data.orderId) {
                this.showDangerNotification('Original order id is not provided, please contact administrator');
                return false;
            }

            await this.$store.dispatch('shared/originalOrder/get', this.data.orderId);
            this.setLines();
            this.setOrder();
        },
        convertBoolToString(value) {
            return value === 1 ? 'Yes' : 'No';
        },
        isSelectedLine(selectedLineId, lineId) {
            return selectedLineId == lineId;
        },
        setLines() {
            this.lines = [];

            this.originalLineData.map((line) => {
                this.lines.push(line);
            });
        },
        originalDataExists(line) {
            const propertiesToCheck = ['vendorId', 'quantity', 'description', 'partNumber'];
            return _.some(propertiesToCheck, (property) => {
                return line[property];
            });
        },
        lineOrderedAfterOriginalOrderPlaced(line) {
            return line.orderMethodId == OrderMethods.BILL_ENTRY;
        },
        setOrder() {
            const purchaseOrderId = _.get(this.lines, '0.purchaseOrderId', null);
            this.order = {
                id: this.data.orderId,
                purchaseOrderId,
            };
        },
        formatDateNeeded(line) {
            return line.isGroundShipping ? 'Ground Shipping Selected' : line.dateNeeded;
        },
    },
    computed: {
        ...mapState({
            originalLineData: (state) => state.shared.originalOrder.data,
            data: (state) => state.shared.modal.data,
        }),
    },
    watch: {
        isActive: {
            handler(value) {
                if (value) {
                    this.getData();
                }
            },
            immediate: true,
        },
    },
    created() {
        OriginalOrderDataLoader.load();
    },
};
</script>

<style lang="scss" scoped>
.original-order-modal {
    overflow-wrap: anywhere;
    table {
        td.left-column label {
            white-space: nowrap;
        }
        .notification.is-warning,
        .notification.is-warning:hover {
            color: #0d1321;
            background-color: #f6bd60;
        }

        .line_detail div {
            margin-bottom: 0.3rem;
        }
    }
}
</style>
