<template>
    <div class="tracking-numbers">
        <label class="label">
            {{ label }}
            <span v-if="editable"
                >- <a @click="onToggleEdit">{{ toggleEditLabel }}</a></span
            >
        </label>
        <p class="control">
            <template v-if="!editMode">
                <tracking-number
                    v-for="(trackingNumber, index) in trackingNumbers"
                    :key="index"
                    :trackingNumber="trackingNumber"
                    :expectedShippingMethodId="expectedShippingMethodId"
                />
            </template>
            <template v-else>
                <emp-textbox
                    v-for="(trackingNumber, index) in trackingNumbers"
                    :key="index"
                    inputClass="is-uppercase"
                    @input="emitTrackingNumberChanged"
                    v-model="trackingNumbers[index].tracking_number"
                />
                <emp-button @click="onAddTrackingNumber" label="+" class="add-tracking-number" />
            </template>
        </p>
    </div>
</template>

<script>
import { TrackingNumberTypes } from 'enums';
const TrackingNumber = () => import(/* webpackChunkName: "tracking-numbers" */ 'component/admin/TrackingNumber');

export default {
    components: {
        TrackingNumber,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        type: {
            type: Number,
            default: TrackingNumberTypes.NORMAL,
        },
        label: String,
        editable: {
            type: [String, Boolean],
            default: true,
        },
        showTags: {
            type: Boolean,
            default: false,
        },
        expectedShippingMethodId: {
            type: Number,
        },
    },
    data() {
        return {
            editMode: false,
        };
    },
    methods: {
        onSetEditMode(value = true) {
            this.editMode = value;

            if (!value) {
                this.emitValue();
            }
        },
        emitValue() {
            this.$emit('input', this.value);
        },
        onToggleEdit() {
            this.editMode = !this.editMode;

            if (this.editMode && !this.value.length) {
                this.onAddTrackingNumber();
            }

            if (!this.editMode) {
                this.emitValue();
            }
        },
        onAddTrackingNumber() {
            this.value.push({
                tracking_number: '',
                type_id: this.type,
                id: null,
            });
        },
        emitTrackingNumberChanged() {
            this.$emit('change');
        },
    },
    computed: {
        toggleEditLabel() {
            return this.editMode ? 'Done' : 'Edit';
        },
        trackingNumbers() {
            return this.value.filter((item) => {
                return item.type_id == this.type;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.add-tracking-number {
    float: right;
    margin-right: 1rem;
}

.is-uppercase {
    text-transform: uppercase;
}
</style>
