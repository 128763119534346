import axios from 'axios';
import _ from 'lodash';
import route from 'ziggyRoute';

export default {
    async get(params = {}, config = {}) {
        let url = route('products');

        if (params.id) {
            url = route('products.show', { id: params.id });
            delete params.id;
        }

        const paramsWithConfig = _.merge({}, config, { params: params });
        return await axios.get(url, paramsWithConfig);
    },
    async delete(id) {
        let url = route('products.destroy', { id: id });
        return await axios.delete(url);
    },
    async update(id, payload) {
        let url = route('products.edit', { id: id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('products.create');
        let data = {};

        let result = await axios.post(url, payload);
        data = result.data.data;

        return data;
    },
    async setFavorite(params = {}) {
        let url = route('products.set-favorite-status', { id: params.id });
        delete params.id;
        let result = await axios.post(url, params, { hideLoader: true });
        return result.data.data;
    },
    async researchRequest(payload) {
        let url = route('products.research-request');
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async autocomplete(params = {}, config = {}) {
        let url = route(`products.autocomplete`);
        let paramsWithConfig = _.merge({}, config, { params: params });
        return await axios.get(url, paramsWithConfig);
    },
    async manufacturersForType(type) {
        const url = route('products.type.manufacturers', [type]);
        const response = await axios.get(url);
        return response.data.data;
    },
    async downloadCSV(params = {}) {
        let url = route('products.download.csv');
        return await axios.get(url, { params }, { responseType: 'blob' });
    },
};
