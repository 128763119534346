import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}, config = {}) {
        let url = params.id ? route('addresses.show', { id: params.id }) : route('addresses');
        delete params.id;
        return await axios.get(url, { params, ...config });
    },
    async delete(id) {
        let url = route('addresses.destroy', { id: id });
        return await axios.delete(url);
    },
    async update(id, payload) {
        let url = route('addresses.edit', { id: id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async updateStatus(payload) {
        let url = route('addresses.update-status', [payload.idString]);
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('addresses.create');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async getRebate(payload = {}, config = {}) {
        let url = route('addresses.vendor.show', { address: payload.addressId, vendor: payload.vendorId });
        let result = await axios.get(url, { params: payload, ...config });
        return result.data.data;
    },
    async dropdownData(params = {}, config = {}) {
        let url = route('addresses.dropdowns');
        return await axios.get(url, { params, ...config });
    },
    async downloadCSV(params = {}) {
        let url = route('addresses.download-csv');
        return await axios.get(url, { params });
    },
    vendors: {
        async get(params = {}) {
            let addressId = params.addressId;
            let vendorId = params.vendorId;

            if (!addressId) {
                throw Error('AddressID is required to perform this request.');
            }

            delete params.addressId;
            delete params.vendorId;

            let url = vendorId
                ? route('addresses.vendor.show', { address: addressId, vendor: vendorId })
                : route('addresses.vendor', { address: addressId });
            return await axios.get(url, { params });
        },
        async delete(addressId = undefined, vendorId = undefined, id) {
            if (!addressId) {
                throw Error('AddressID is required to perform this request.');
            }

            if (!vendorId) {
                throw Error('VendorID is required to perform this request.');
            }
            let url = route('addresses.vendor.destroy', { address: addressId, vendor: vendorId });
            return await axios.delete(url);
        },
        async update(addressId = undefined, vendorId = undefined, payload) {
            if (!addressId) {
                throw Error('AddressID is required to perform this request.');
            }

            if (!vendorId) {
                throw Error('VendorID is required to perform this request.');
            }

            let url = route('addresses.vendor.edit', { address: addressId, vendor: vendorId });
            let result = await axios.put(url, payload);
            return result.data.data;
        },
        async create(addressId = undefined, payload) {
            if (!addressId) {
                throw Error('AddressID is required to perform this request.');
            }
            let url = route('addresses.vendor.create', { address: addressId });
            let result = await axios.post(url, payload);
            return result.data.data;
        },
    },
};
