import axios from 'axios';
import _ from 'lodash';
import route from 'ziggyRoute';

export default {
    async get(params = {}, config = {}) {
        let url = params.id ? route('patient-names.show', { id: params.id }) : route('patient-names');
        delete params.id;
        let paramsWithConfig = _.merge({}, config, { params: params });
        return await axios.get(url, paramsWithConfig);
    },
    async delete(id) {
        let url = route('patient-names.destroy', { id: id });
        let result = await axios.delete(url);
        return result.data.data;
    },
    async update(payload) {
        let url = route('patient-names.edit', { id: payload.id });
        return await axios.put(url, payload);
    },
    async updateStatus(payload) {
        let url = route('patient-names.update-status', [payload.idString]);
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async create(payload) {
        let url = route('patient-names.create');
        return await axios.post(url, payload);
    },
    async combine(payload) {
        let url = route('patient-names.combine');
        return await axios.post(url, payload);
    },
};
