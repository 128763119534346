import _ from 'lodash';
import SmartApi from 'SmartApi';
import Bugsnag from '@bugsnag/js';
import { Roles } from 'enums';
import { set as vueAnalyticsSet } from 'vue-analytics';

function createFindPreferenceFunction({ key, type }) {
    return (preference) => {
        return preference.type === type && preference.key === key;
    }
}

export default {
    namespaced: true,
    state: {
        data: {},
        resolvedUser: {},
        hasLoadedOnce: false,
        fallBackUserUpdater: 0,
        loading: false,
        moduleName: 'Current User'
    },
    mutations: {
        setData(state, data) {
            state.data = data;
            vueAnalyticsSet('userId', data.id);
            state.hasLoadedOnce = true;
        },
        setResolvedUser(state, data) {
            state.resolvedUser = data;
        },
        storePreference(state, data) {
            const index = state.data.preferences.findIndex(createFindPreferenceFunction({ key: data.key, type: data.type }));

            if (index > -1) {
                state.data.preferences.splice(index, 1, data);
            } else {
                state.data.preferences.push(data);
            }
        },
        setUserPermissions(state, payload) {
            const scopes = payload.permissions;
            state.data = Object.assign({}, state.data, { scopes });
        },
        setFallbackUserUpdater(state, int = 0) {
            state.fallBackUserUpdater = int;
        },
        setLoading(state, loading) {
            state.loading = loading
        }
    },
    actions: {
        async getData({ commit }, params = {}) {
            const response = await SmartApi.get({
                routeName: 'me',
                routeParams: params,
                config: {
                    hideLoader: true,
                }
            });
            commit('setResolvedUser', response.data.data);
        },
        async loadData({ dispatch, commit, state }, params = {}) {
            if(!state.loading) {
                commit('setLoading', true);
                try {
                    await dispatch('getData', params);
                    dispatch('setUserData');
                } finally {
                    commit('setLoading', false);
                }
            }
        },
        setUserData({ state, dispatch, commit }, user) {
            commit('setData', state.resolvedUser);
            commit('setResolvedUser', {});
            dispatch('setBugsnagUser');
        },
        setBugsnagUser({ getters }) {
            // Only id, email and name can be set directly via setUser()
            // We have to set additional metadata in the user section
            // https://github.com/bugsnag/bugsnag-js/blob/master/UPGRADING.md#user
            Bugsnag.setUser(getters.user.id);
            Bugsnag.addMetadata("user", "type", getters.userType);
        },
        async savePreference({ dispatch, commit, getters }, preference) {
            await dispatch('loadData');

            const exists = getters.preferencesKeyExists({
                type: preference.type,
                key: preference.key
            });

            let action = 'createPreference';

            if (exists) {
                action = 'updatePreference';
            }

            preference.data = _.omit(preference.data, ['page', 'sort']);

            await dispatch(action, preference);
            commit('storePreference', preference);
        },
        async createPreference({ getters }, preference) {
            await SmartApi.post({
                routeName: 'user.preferences.store',
                routeParams: {
                    user: getters.user.id,
                    type: preference.type
                },
                data: {
                    key: preference.key,
                    data: preference.data
                }
            });
        },
        async updatePreference({ getters }, preference) {
            await SmartApi.put({
                routeName: 'user.preferences.update',
                routeParams: {
                    user: getters.user.id,
                    type: preference.type,
                    key: preference.key
                },
                data: {
                    data: preference.data
                }
            });
        },
        updateUser({dispatch}) {
            //TODO: When revamping users, change this to load/update only the data it needs
            dispatch('loadData');
        },
        updateUserPermissions({dispatch}) {
            //TODO: When revamping users, change this to load/update only the data it needs
            dispatch('loadData');
        },
        updateUserNotificationPreferences({dispatch}){
            //TODO: When revamping users, change this to load/update only the data it needs
            dispatch('loadData');
        },
        updateUserPreferences({dispatch}) {
            //TODO: When revamping users, change this to load/update only the data it needs
            dispatch('loadData');
        },
        pollForUserChanges({commit}) {
            commit('setFallbackUserUpdater', -1);
        },
        stopPollingForUserChanges({commit}) {
            commit('setFallbackUserUpdater', 1);
        },
        loadedUser({getters, commit}) {
            if (getters.fallBackUserUpdater !== 0) {
                commit('setFallbackUserUpdater', getters.fallBackUserUpdater - 1);
            }
        }
    },
    getters: {
        user(state, getters) {
            return state.data;
        },
        defaultPractitionerId(state, getters) {
            return state.data.defaultPractitionerId;
        },
        defaultShippingAddressId(state, getters) {
            return state.data.defaultShippingAddressId;
        },
        defaultCostCenterId(state, getters) {
            return state.data.defaultCostCenterId;
        },
        isFacility(state, getters) {
            return state.data.roleId === Roles.FACILITY_USER_ID;
        },
        isAdmin(state, getters) {
            return state.data.roleId === Roles.ADMIN_ID;
        },
        isAuthenticated(state, getters) {
            return Boolean(state.data.id);
        },
        isUnauthenticated(state, getters) {
            return !getters.isAuthenticated;
        },
        userType(state, getters) {
            if (getters.isAuthenticated) {
                return getters.isAdmin ? 'admin' : 'facility';
            }
            return 'unauthenticated';
        },
        facilityId(state, getters) {
            return state.data.facilityId;
        },
        facilityName(state, getters) {
            return _.get(state.data, 'facility.name', null);
        },
        facilityRestrictedToPatientView(state, getters) {
            return (state.data.facility && state.data.facility.isRestrictedToPatientView);
        },
        permissions(state, getters) {
            return _.get(state.data, 'scopes', []);
        },
        can(state, getters) {
            return (permission) => {
                return getters.permissions.some((scope) => scope.slug === permission);
            }
        },
        preferences(state) {
            return _.get(state, 'data.preferences', []);
        },
        preferencesKeyExists(state, getters) {
            return ({ type, key }) => {
                return getters.preferences.some(createFindPreferenceFunction({ type, key }));
            }
        },
        preferencesValue(state, getters) {
            return ({ type, key }) => {
                const preference = getters.preferences.find(createFindPreferenceFunction({ type, key }));

                if (preference === undefined) {
                    return {};
                }

                return preference.data;
            }
        },
        fallBackUserUpdater(state) {
            return state.fallBackUserUpdater;
        },
        shouldLoadUserViaFallBack(state) {
            return state.fallBackUserUpdater !== 0;
        }
    }
}
