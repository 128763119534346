export default {
    mapper: {
        relations: {
            belongsTo: {
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
            },
        },
    },
};
