import { auditsRelation, notesRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsToMany: {
                trackingNumber: {
                    foreignKey: 'id',
                    localField: 'trackingNumbers',
                },
            },
            belongsTo: {
                line: {
                    foreignKey: 'line_id',
                    localField: 'line',
                },
                user: {
                    foreignKey: 'return_requested_by_user_id',
                    localField: 'returnRequestedBy',
                },
                returnLabel: {
                    foreignKey: 'return_label_url_id',
                    localField: 'returnLabel',
                },
            },
            morphMany: {
                ...auditsRelation('returnRequest'),
                ...notesRelation('returnRequest'),
            },
        },
    },
};
