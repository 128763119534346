<template>
    <div class="history-modal">
        <emp-button label="History" @click="onToggleModal" />
        <emp-component-modal @modal:close="onToggleModal" :isActive="isActive">
            <div slot="modal-header">Item History</div>
            <div slot="modal-body">
                <div class="modal-body-content">
                    <history :history="history" :lines="history.length" />
                    <emp-button label="Load More" v-if="cursor.next" @click="onLoadMore" />
                </div>
            </div>
        </emp-component-modal>
    </div>
</template>

<script>
import History from 'component/shared/History';
import OrdersApi from 'api/endpoints/orders';
import { systemNotifications } from 'component/shared/elements/mixins';
import moment from 'moment-timezone';

export default {
    mixins: [systemNotifications],
    components: {
        History,
    },
    props: {
        itemId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            cursor: {
                previous: null,
                current: 0,
                next: 0,
                count: 0,
            },
            isActive: false,
            history: [],
        };
    },
    methods: {
        onToggleModal() {
            this.isActive = !this.isActive;
        },
        async onLoadMore() {
            await this.getHistory();
        },
        async getHistory() {
            try {
                let response = await OrdersApi.history({
                    id: this.itemId,
                    cursor: this.cursor.next,
                    previous: this.cursor.current,
                });

                this.history = this.history.concat(response.data);
                this.cursor = response.meta.cursor;
            } catch (e) {
                if (e.response) {
                    this.showDangerNotification(e.response.message);
                }

                console.error(e);
            }
        },
    },
    watch: {
        async isActive(value) {
            if (value) {
                await this.getHistory();
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
