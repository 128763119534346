import route from '@ziggy/js';
import { Ziggy } from 'ziggy';

const DEFAULTS = {
    routeName: undefined,
    routeParams: undefined,
    config: undefined,
    data: undefined,
};

function getFinalizedParameters(params = {}) {
    return Object.assign({}, DEFAULTS, params);
}

export default class SmartApi {
    constructor(transport) {
        if (Ziggy == undefined) {
            throw Error('Ziggy was not found, Smart API will not work without it.');
        }

        this.routes = Ziggy.routes;
        this.transport = transport;
    }
    async get(params = {}) {
        return await this.request('get', params);
    }
    async post(params = {}) {
        return await this.request('post', params);
    }
    async patch(params = {}) {
        return await this.request('patch', params);
    }
    async put(params = {}) {
        return await this.request('put', params);
    }
    async delete(params = {}) {
        return await this.request('delete', params);
    }
    async request(method, params = {}) {
        const finalizedParams = getFinalizedParameters(params);

        if (this.routeExists(finalizedParams.routeName) === false) {
            throw Error(`${finalizedParams.routeName} is not a valid Ziggy route.`);
        }

        if (this.methodSupported(finalizedParams.routeName, method) === false) {
            throw Error(`${method} is not supported for route ${finalizedParams.routeName}`);
        }

        //True here refers to absolute urls, it is the default, and I'm only including it here, because passing
        //undefined looks really nasty, and I don't like it.
        const url = route.apply(null, [finalizedParams.routeName, finalizedParams.routeParams, true, Ziggy]);

        return await this.transport[method](url, {
            config: finalizedParams.config,
            data: finalizedParams.data,
        });
    }
    routeExists(routeName) {
        return this.routes.hasOwnProperty(routeName);
    }
    methodSupported(routeName, method) {
        return this.routes[routeName].methods.indexOf(method.toUpperCase()) !== -1;
    }
}
