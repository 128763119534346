<template>
    <div>
        <router-view></router-view>
        <the-modals />
    </div>
</template>

<script>
import Interceptor from '../../Interceptor';
import TheModals from 'component/shared/TheModals.vue';

export default {
    components: {
        TheModals,
    },
    data() {
        return {
            interceptor: null,
        };
    },
    methods: {
        registerInterceptors() {
            this.interceptor = new Interceptor(this.$router);
            this.interceptor.intercept();
        },
    },
    created() {
        this.registerInterceptors();
    },
};
</script>
