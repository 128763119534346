<template>
    <div class="prewritten-note-list tile is-parent form-section">
        <div class="tile is-child box">
            <div class="box">
                <div class="level is-mobile">
                    <div class="level-item rearrange-column has-text-centered"><strong>Rearrange</strong></div>
                    <div class="level-item separator-column has-text-centered"><strong>Separator</strong></div>
                    <div class="level-item note-text-column has-text-centered"><strong>Note Text</strong></div>
                    <div class="level-item delete-column has-text-centered"><strong>Delete</strong></div>
                </div>
            </div>
            <draggable class="notes-draggable" v-model="notes" @change="onNoteOrderChanged">
                <div v-for="(note, index) in notes" :key="index" class="box">
                    <div class="level is-mobile">
                        <div
                            class="level-item rearrange-column has-text-centered pointer ui-icon"
                            title="Click and drag to rearrange note list."
                        >
                            <i class="fas fa-exchange-alt"></i>
                        </div>
                        <div class="level-item separator-column has-text-centered">
                            <emp-checkbox fieldClass="" v-model="note.is_separator" @input="onNoteChanged(index)" />
                        </div>
                        <div class="level-item note-text-column">
                            <emp-textarea
                                class="note-text"
                                v-model="note.note"
                                @input="onNoteChanged(index)"
                                :contentBasedHeight="true"
                                :showCharacterCount="true"
                                :maxCharacterCount="400"
                                :name="`note_text_${index}`"
                                :errors="note.errors"
                                ref="noteText"
                            />
                        </div>
                        <div class="level-item delete-column has-text-centered pointer ui-icon">
                            <i
                                class="fas fa-times-circle"
                                title="Click to delete note."
                                @click="onDeleteNote(index, note)"
                            ></i>
                        </div>
                    </div>
                </div>
            </draggable>
            <div class="level add-note-button">
                <div class="level-item"><i @click="addNote()" class="fas fa-plus-circle pointer"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import { PrewrittenNoteTypes } from 'enums';
import { routerNavigation } from 'component/shared/elements/mixins';
import Bugsnag from '@bugsnag/js';
import draggable from 'vuedraggable';
import SmartApi from 'SmartApi';
import Vue from 'vue';

export default {
    components: {
        draggable,
    },
    data() {
        return {
            notes: [],
            noteIdsForDelete: [],
            promises: [],
        };
    },
    methods: {
        async initializeComponent() {
            await this.refreshData();
        },
        async refreshData() {
            let data = await this.getData();
            this.setData(data);
            this.changeAllNotesUnsavedValues(false);
            this.triggerTextareaResizeForAllNotes();
        },
        async getData() {
            let data = [];
            let routeName = this.selectFor({
                facility: 'listFacilityPrewrittenNotes',
                admin: 'listAdminPrewrittenNotes',
            });
            let routeParams = this.selectFor({ facility: { facility_id: this.currentUserFacilityId }, admin: {} });

            try {
                let response = await SmartApi.get({
                    routeName,
                    routeParams,
                    config: {
                        params: {
                            per_page: -1,
                            pagination_type: 'simple',
                        },
                    },
                });
                data = response.data.data;
            } catch (e) {
                if (e.response) {
                    this.showDangerNotification(e.response.message);
                } else {
                    Bugsnag.notify(e);
                    this.showDangerNotification(
                        "An unexpected error occurred while attempting to retrieve your Facility's notes. Please contact Empire Medical if the problem persists."
                    );
                }
            }
            return data;
        },
        setData(data) {
            this.notes = this.sortDataByOrder(data);
        },
        sortDataByOrder(data) {
            return _.sortBy(data, ['order']);
        },
        onNoteChanged(index) {
            this.changeNoteIndexUnsavedValue(index, true);
            this.emitUnsavedChanges();
        },
        emitUnsavedChanges() {
            this.$emit('prewrittenNoteList:unsavedChanges', true);
        },
        emitSaved() {
            this.$emit('prewrittenNoteList:saved');
        },
        addNote() {
            const type_id = this.currentUserIsFacility
                ? PrewrittenNoteTypes.FACILITY_ORDER_FORM
                : PrewrittenNoteTypes.ADMIN_INTERNAL;
            const facility_id = this.currentUserFacilityId;
            const order = this.notes.length + 1;

            this.notes.push({
                type_id,
                facility_id,
                order,
                is_separator: false,
                note: '',
                unsavedChanges: true,
            });
            this.emitUnsavedChanges();
        },
        onNoteOrderChanged() {
            this.triggerTextareaResizeForAllNotes();
            this.changeAllNotesUnsavedValues(true);
            this.emitUnsavedChanges();
        },
        changeNoteIndexUnsavedValue(index, value) {
            this.notes[index]['unsavedChanges'] = value;
        },
        changeAllNotesUnsavedValues(value) {
            for (let i = 0, len = this.notes.length; i < len; i++) {
                this.changeNoteIndexUnsavedValue(i, value);
            }
        },
        triggerTextareaResizeForAllNotes() {
            if (this.$refs['noteText']) {
                for (let i = 0, len = this.$refs['noteText'].length; i < len; i++) {
                    this.$refs['noteText'][i].adjustHeightBasedOnContent();
                }
            }
        },
        onDeleteNote(index, note) {
            if (note.id) {
                this.noteIdsForDelete.push(note.id);
            }
            this.clearErrorsForNote(index);
            this.notes.splice(index, 1);
            this.triggerTextareaResizeForAllNotes();
            this.emitUnsavedChanges();
        },
        clearErrorsForNote(index) {
            Vue.set(this.notes[index], 'errors', {});
        },
        clearErrorsForAllNotes() {
            for (let i = 0, len = this.notes.length; i < len; i++) {
                this.clearErrorsForNote(i);
            }
        },
        duplicateNotesFound() {
            // This method exists because the back-end validation often
            // misses duplicates if the requests are submitted close together
            const duplicates = [];
            const seen = new Set();
            const notes = this.notes.map(({ note }) => note.trim());
            notes.forEach((note, index) => {
                if (seen.has(note)) {
                    duplicates.push(index);
                }
                seen.add(note);
            });

            for (let duplicate of duplicates) {
                let noteName = `note_text_${duplicate}`;
                Vue.set(this.notes[duplicate]['errors'], noteName, 'Duplicates found, please remove all but one.');
            }

            return Boolean(duplicates.length > 0);
        },
        async onSave() {
            // This method isn't called from within the component, but IS called
            // via component ref in a few places. - JJB

            this.clearErrorsForAllNotes();
            let allRequestsSuccessful = true;

            if (this.duplicateNotesFound()) {
                return false;
            }

            let notes = _.clone(this.notes);

            this.requestDeleteNotesMarkedForDelete();

            for (let i = 0, length = notes.length; i < length; i++) {
                if (notes[i]['unsavedChanges']) {
                    let data = notes[i];
                    data['order'] = i + 1;
                    data['note'] = data['note'].trim();

                    if (!data.id) {
                        this.requestCreateNote(data);
                    } else {
                        this.requestUpdateNote(data);
                    }
                }
            }

            let results = await Promise.allSettled(this.promises);

            results.forEach((result) => {
                if (result.status === 'rejected') {
                    allRequestsSuccessful = false;
                    this.addErrorMessageToFieldFromRequestResult(result);
                } else {
                    this.addIdToCreatedNote(result);
                }
            });

            if (allRequestsSuccessful) {
                this.showSuccessNotification('All notes saved.');
                this.emitSaved();
                this.refreshData();
            } else {
                this.showDangerNotification(
                    'There was a problem saving some of the notes. Please review and try again.'
                );
            }

            this.promises = [];
            return allRequestsSuccessful;
        },
        submitRequest(method, routeName, routeParams, data = {}) {
            const promise = SmartApi[method]({ routeName, routeParams, data, config: { suppressErrorAlert: true } });
            this.promises.push(promise);
        },
        selectFor({ admin, facility }) {
            if (this.currentUserIsAdmin) {
                return admin;
            }
            return facility;
        },
        requestCreateNote(data) {
            let routeName = this.selectFor({
                facility: 'createFacilityPrewrittenNote',
                admin: 'createAdminPrewrittenNote',
            });
            let routeParams = this.selectFor({ facility: { facility_id: this.currentUserFacilityId }, admin: {} });

            this.submitRequest('post', routeName, routeParams, data);
        },
        requestUpdateNote(data) {
            let routeName = this.selectFor({
                facility: 'updateFacilityPrewrittenNote',
                admin: 'updateAdminPrewrittenNote',
            });
            let routeParams = this.selectFor({
                facility: {
                    facility_id: this.currentUserFacilityId,
                    prewritten_note_id: data['id'],
                },
                admin: {
                    prewritten_note_id: data['id'],
                },
            });

            this.submitRequest('put', routeName, routeParams, data);
        },
        requestDeleteNote(noteId) {
            let routeName = this.selectFor({
                facility: 'deleteFacilityPrewrittenNote',
                admin: 'deleteAdminPrewrittenNote',
            });
            let routeParams = this.selectFor({
                facility: {
                    facility_id: this.currentUserFacilityId,
                    prewritten_note_id: noteId,
                },
                admin: {
                    prewritten_note_id: noteId,
                },
            });

            this.submitRequest('delete', routeName, routeParams);
        },
        requestDeleteNotesMarkedForDelete() {
            this.noteIdsForDelete.forEach((noteId) => {
                this.requestDeleteNote(noteId);
            });
        },
        addErrorMessageToFieldFromRequestResult(requestResult) {
            let requestData = JSON.parse(_.get(requestResult, 'reason.config.data', '{}'));
            let index = requestData.order - 1;
            let message = _.get(
                requestResult,
                'reason.response.data.generalErrors.note',
                'Unable to retrieve error message.'
            );
            let errors = {};
            errors[`note_text_${index}`] = message;
            if (this.notes[index]) {
                Vue.set(this.notes[index], 'errors', errors);
            } else {
                console.log(requestResult);
            }
        },
        addIdToCreatedNote(requestResult) {
            if (requestResult.value.statusText === 'Created') {
                let index = _.get(requestResult, 'value.data.data.order') - 1;
                let id = _.get(requestResult, 'value.data.data.id');
                this.notes[index]['id'] = id;
            }
        },
    },
    mounted() {
        this.initializeComponent();
    },
};
</script>

<style lang="scss" scoped>
.prewritten-note-list {
    &.form-section {
        margin: 0 auto;

        > .box {
            background-color: #efefef;
        }
        .box:not(:last-child) {
            margin-bottom: 0;
            padding: 1rem;
        }
        .separator-column,
        .rearrange-column,
        .delete-column {
            width: 8vw;
            border-right: 1px #ccc;
        }
        .note-text-column {
            text-align: left;
            width: 28vw;

            .note-text {
                width: 100%;
            }
        }
        .ui-icon {
            font-size: 1.4rem;
            :hover {
                color: #aaa;
            }

            .fa-exchange-alt {
                transform: rotate(-90deg);
            }
        }
    }
    .add-note-button {
        padding: 1rem 1rem 0.5rem;
        font-size: 2.5rem;

        i:hover {
            color: #fff;
        }
    }

    .pointer {
        cursor: pointer;
    }
}
</style>
