export default {
    addToast({ commit, dispatch }, params) {
        const id = new Date().getTime();
        const delay = params.hasOwnProperty('delay') ? params.delay : 4000;
        delete params.delay;

        let toast = {...params, id: id};
        toast.class = toast.class || "primary";
        commit("createToast", toast);

        if(delay !== 0) {
            toast.interval = setTimeout(() => {
                dispatch('dismissToast', id);
            }, delay);
        }
    },

    dismissToast({ dispatch, state }, id) {
        if(state.toasts[id]) {
            state.toasts[id].classes = state.toasts[id].classes + " hidden";
            state.toasts[id].interval = setTimeout(() => {
                dispatch("dismissToastImmediately", id);
            }, 500);
        } else {
            dispatch("dismissToastImmediately", id);
        }
    },

    dismissToastImmediately({ commit, state }, id) {
        if (state.toasts[id] && state.toasts[id].dismissCallback) {
            state.toasts[id].dismissCallback(id);
        }
        commit("deleteToast", id);
    },



    templateToast({ dispatch }, toast) {
        const defaults = { delay: 4000, showDismiss: true, clickAction: null, dismissCallback: null};
        if (typeof toast.input === "object") {
            dispatch("addToast", {...defaults, ...toast.defaults, ...toast.input});
        } else {
            dispatch("addToast", {...defaults, ...toast.defaults, text:toast.input});
        }
    },


    toast({ dispatch }, toast) {
        dispatch('templateToast', {
            input: toast,
            defaults: {
                classes: "",
                icon: "bell",
                title: false,
            }
        });
    },
    toastInfo({ dispatch }, toast) {
        dispatch('templateToast', {
            input: toast,
            defaults: {
                classes: "is-info",
                icon: "info",
                title: "Info",
            }
        });
    },
    toastSuccess({ dispatch }, toast) {
        dispatch('templateToast', {
            input: toast,
            defaults: {
                classes: "is-success",
                icon: "check",
                title: "Success",
            }
        });
    },
    toastWarning({ dispatch }, toast) {
        dispatch('templateToast', {
            input: toast,
            defaults: {
                classes: "is-warning",
                icon: "exclamation",
                title: "Warning",
            }
        });
    },
    toastDanger({ dispatch }, toast) {
        dispatch('templateToast', {
            input: toast,
            defaults: {
                classes: "is-danger",
                icon: "radiation",
                title: "Error",
                duration: 0,
            }
        });
    },
    toastDark({ dispatch }, toast) {
        dispatch('templateToast', {
            input: toast,
            defaults: {
                classes: "is-dark",
                icon: "circle",
                title: false,
            }
        });
    },


}
