<template>
    <div
        :class="['field', fieldClass]"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
        @touchstart="onTouchStart"
        data-testId="select-field"
    >
        <label :class="['label', labelClass]" v-if="label" :for="`${name}_${_uid}`">
            {{ label }}
        </label>
        <span class="control">
            <span :class="['select is-fullwidth', inputClass, { 'is-danger': hasError }]">
                <select
                    :class="inputClass"
                    :disabled="disabled"
                    :value="value"
                    @blur="onBlur"
                    @change="$emit('input', $event.target.value)"
                    @focus="onFocus"
                    :id="`${name}_${_uid}`"
                >
                    <slot name="options">
                        <option v-if="placeholder !== false" :value="null">{{ placeholder }}</option>
                        <select-option
                            v-for="option in selectables"
                            :key="option[valueFrom]"
                            :option="option"
                            :valueFrom="valueFrom"
                            :componentId="componentId"
                            :nameCallback="nameCallback"
                            :nameFrom="nameFrom"
                        />
                    </slot>
                </select>
            </span>
        </span>
        <template v-if="hasErrorArray">
            <p class="help is-danger" v-for="(error, index) in errors[name]" :key="index">{{ error }}</p>
        </template>
        <template v-else-if="hasError">
            <p class="help is-danger">{{ errors[name] }}</p>
        </template>
    </div>
</template>

<script>
import _ from 'lodash';
import { input, hasOptions } from './mixins';
import SelectOption from 'component/revamp/elements/SelectOption';

export default {
    components: {
        SelectOption,
    },
    props: {
        nameCallback: {
            type: [String, Function],
            default: null,
        },
        selectSolitaryOption: {
            type: Boolean,
            default: true,
        },
    },
    mixins: [input, hasOptions],
    data() {
        return {
            componentId: `select_${this._uid}`,
            isSelecting: true,
            isFocused: false,
        };
    },
    methods: {
        onMouseover() {
            this.isSelecting = true;
        },
        onMouseleave() {
            if (!this.isFocused) {
                this.isSelecting = false;
            }
        },
        onFocus($event) {
            this.$emit('focus', $event);
            this.isSelecting = true;
            this.isFocused = true;
        },
        onBlur($event) {
            this.$emit('blur', $event);
            this.isFocused = false;
        },
        onTouchStart($event) {
            this.isSelecting = true;
        },
    },
    computed: {
        selectables() {
            if (this.isSelecting) {
                return this.options;
            }
            // JEF: Always include the longest if it exists
            //      so that the width of the select does not
            //      change on mouseover.
            if (this.selected) {
                if (this.longest && this.selected !== this.longest) {
                    return [this.selected, this.longest];
                }
                return [this.selected];
            }
            if (this.longest) {
                return [this.longest];
            }
            return [];
        },
        selected() {
            return this.options.find((option) => this.value == option[this.valueFrom]);
        },
        longest() {
            let max = 0;
            let idxOfMax = 0;
            for (var i = this.options.length - 1; i >= 0; i--) {
                const element = this.options[i];
                const name = element[this.nameFrom];
                if (name !== null) {
                    const currentLength = name.trim().length;
                    if (currentLength > max) {
                        idxOfMax = i;
                        max = currentLength;
                    }
                }
            }
            return this.options[idxOfMax];
        },
    },
    watch: {
        options: {
            handler: function (val, old) {
                if (this.selectSolitaryOption) {
                    if (val.length == 1 && val[0][this.valueFrom] != this.value) {
                        this.$emit('input', val[0][this.valueFrom]);
                    }
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.isSelecting = false; //Fix for issue where select doesn't show the selected option on load -JJB
    },
};
</script>

<style lang="scss">
select.highlight-light-blue {
    background-color: #537d8d;
    color: #fff;
}
</style>
