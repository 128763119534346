import { hasErrorProps, hasErrors } from '@/has/errors';
import { reactive, ref } from '@vue/composition-api';

/** ----- [ Note ] -----**/
// If you add a new property to hasInputProps add it to the input section
// of hasInput() as well, so it can be auto-bound to the final component.
// same with removing a property - Toby

export function hasInputProps(defaults = {}) {
    return {
        ...hasErrorProps(defaults),
        autofocus: {
            type: Boolean,
            default: !!defaults.autofocus,
        },
        disabled: {
            type: [String, Boolean],
            default: !!defaults.disabled,
        },
        fieldClass: {
            type: [String, Array, Object],
            default: defaults.fieldClass ?? 'column',
        },
        inputClass: {
            type: [String, Array, Object],
            default: defaults.inputClass ?? '',
        },
        isLoading: {
            type: Boolean,
            default: !!defaults.isLoading,
        },
        label: {
            type: [String, Boolean],
            default: defaults.label ?? false,
        },
        labelClass: {
            type: String,
            default: defaults.labelClass ?? '',
        },
        placeholder: {
            type: [String, Boolean],
            default: defaults.placeholder ?? '',
        },
        tabindex: {
            type: Number,
            default: defaults.tabindex ?? null,
        },
        //todo: type probably doesn't need to be in input and should be moved to Textbox.vue - Toby
        type: String,
        value: {
            type: [String, Number, Array, Object, Boolean],
            default: null,
        },
        icon: {
            type: String,
            default: defaults.icon ?? null,
        },
        readonly: {
            type: Boolean,
            default: !!defaults.readonly,
        },
    };
}

export function hasInput(props) {
    return {
        ...hasErrors(props),
    };
}
