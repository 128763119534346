<template>
    <empire-select
        valueFrom="id"
        @input="$emit('input', $event)"
        :emp-data="empDataAttr"
        v-bind="{ ...$attrs, ...props }"
        :disabled="!vendors.length || disabled"
        :isLoading="!vendors.length"
        :options="vendors"
        :groupBy="groupByStatus"
    />
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
import { hasIdWhitelistProp, hasInput, hasInputProps, hasOnlyActiveOptionsProp } from '@/has';
import EmpireSelect from 'component/shared/elements/Select.vue';
import { useGroupByStatus } from '@/use/groups';
import { all, conditionalFilter, equal, filterList, inArray, truthy } from '@/use/filters';
import { Statuses } from '@/enums';
import partials from '@/use/partials';

export default defineComponent({
    name: 'SelectVendor',
    inheritAttrs: false,
    components: {
        EmpireSelect,
    },
    props: {
        //todo: it may be best to change this default to vendor_id as we're trying to standardize on the V2 format
        //but that would take a lot of testing and refactoring outside the scope of this ticket.   -Toby
        ...hasInputProps({ name: 'vendorId' }),
        ...hasOnlyActiveOptionsProp(),
        ...hasIdWhitelistProp(),
        onlyOrderFormVendors: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { attrs }) {
        const groupByStatus = useGroupByStatus('status_id');
        const { vendors: vendorData } = partials.useVendorDropdowns();

        const filters = computed(() => {
            return filterList([
                conditionalFilter(inArray(props.idWhitelist, 'id'), props.idWhitelist.length),
                conditionalFilter(equal('status_id', Statuses.ACTIVE), props.onlyActiveOptions),
                conditionalFilter(truthy('show_on_order_form'), props.onlyOrderFormVendors),
            ]);
        });
        const vendors = computed(() => {
            return vendorData.value.filter(all(filters.value));
        });

        const empDataAttr = attrs['emp-data'] ?? 'selectVendor';

        return {
            ...hasInput(props),
            empDataAttr,
            vendors,
            groupByStatus,
            props,
        };
    },
});
</script>
