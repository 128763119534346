import { formatTimestamp, formatCurrency, numberFormat, formatPercent } from 'helpers';
import { MODE_CREATE, MODE_EDIT, NOTEABLE_TYPES } from 'constants';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import PurchaseOrdersApi from 'api/endpoints/purchaseOrders';
import PurchaseOrdersAdminApi from 'api/endpoints/admin/purchaseOrders';
import sanitizeHTML from 'sanitize-html';
import { EMPTY_MANAGE_FORM } from 'ordersConstants';

import EventBus from 'event-bus';

import Moment from 'moment-timezone';
import _ from 'lodash';
import { Statuses, ProductTypes, TrackingNumberTypes } from 'enums';
import Bugsnag from '@bugsnag/js';
import Dialogue from 'component/shared/Dialogue.vue';
import VendorBlacklistConfirmationModal from 'component/admin/VendorBlacklistConfirmationModal.vue';
import { getFriendlyName } from 'app/enumHelpers';

sanitizeHTML.defaults = {
    allowedTags: [
        'a',
        'b',
        'blockquote',
        'br',
        'caption',
        'code',
        'dd',
        'del',
        'div',
        'dl',
        'em',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'hr',
        'i',
        'img',
        'kdb',
        'li',
        'ol',
        'p',
        'pre',
        's',
        'span',
        'strike',
        'strong',
        'sup',
        'table',
        'tbody',
        'td',
        'th',
        'thead',
        'tr',
        'ul',
    ],
    allowedAttributes: {
        a: ['href', 'target', 'title', 'name'],
        img: ['alt', 'height', 'src', 'title', 'width'],
        div: ['class'],
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: true,
};

export const hasSystemSettings = {
    data() {
        return {
            settings: [],
            name: 'general',
        };
    },
    created() {
        this.settings = _.cloneDeep(this.storedSettings);
    },
    computed: {
        ...mapState({
            storedSettings: (state) => state.systemSettings.data,
        }),
    },
    methods: {
        getSettingIndex(settingName) {
            let settingIndex = _.findIndex(this.settings, function (setting) {
                return setting.name == settingName;
            });

            if (settingIndex == -1) {
                this.settings.push({ name: settingName, value: '' });
                settingIndex = this.settings.length;
            }

            return settingIndex;
        },
        getSetting(settingName) {
            let settingIndex = this.getSettingIndex(settingName);
            let settingValue = _.get(this.settings, `${settingIndex}.value`, '');
            return settingValue;
        },
        setSetting(value, settingName) {
            let settingIndex = this.getSettingIndex(settingName);
            this.settings[settingIndex].value = value;
            this.$emit('settings:changed', this.name);
        },
        getSettings() {
            return this.settings;
        },
    },
};

export const sanitizeHtml = {
    methods: {
        sanitizeHTML,
    },
};

export const highlightAddressMixin = {
    computed: {
        ...mapGetters('shared/addresses', ['addressFromId']),
        highlightClass() {
            if (!this.currentUserIsAdmin) {
                return;
            }

            let selectedAddress = this.addressFromId(this.value);

            let flagship = _.get(selectedAddress, 'facilityIsFlagship', false);
            if (flagship) {
                return 'flagship-selected';
            }

            let babysit = _.get(selectedAddress, 'babysitFacility', false);
            if (babysit) {
                return 'babysit-selected';
            }

            return;
        },
    },
};

export const buttons = {
    props: {
        type: { default: 'button', type: String },
        label: { default: 'button', type: String },
        handler: { default: '' },
        disabled: { default: false, type: [String, Boolean] },
        buttonClass: {
            type: [String, Object, Array],
            default: 'is-info',
        },
    },
};

export const popoutModeAware = {
    computed: {
        isPopoutMode() {
            let popout = _.get(this.$route, 'query.popout', 0);
            return Boolean(Number(popout));
        },
    },
};

export const hasErrors = {
    props: {
        errors: {
            type: [Array, Object],
            default: () => {
                return [];
            },
        },
    },
    computed: {
        hasError() {
            return this.name in this.errors;
        },
        hasErrorArray() {
            return this.hasError && this.errors[this.name] instanceof Array;
        },
    },
};

export const input = {
    mixins: [hasErrors],
    props: {
        autofocus: Boolean,
        disabled: {
            type: [String, Boolean],
            default: false,
        },
        fieldClass: {
            type: [String, Array, Object],
            default: 'column',
        },
        inputClass: {
            type: [String, Array, Object],
            default: '',
        },
        label: {
            type: [String, Boolean],
            default: false,
        },
        labelClass: {
            type: String,
            default: '',
        },
        name: String,
        placeholder: {
            type: [String, Boolean],
            default: '',
        },
        tabindex: Number,
        type: String,
        value: {
            type: [String, Number, Array, Object, Boolean],
            default: null,
        },
        icon: String,
        readonly: {
            type: Boolean,
            default: false,
        },
    },
};

export const hasOptions = {
    props: {
        options: {
            type: Array,
            required: true,
            default: () => [],
        },
        nameFrom: {
            type: String,
            default: 'name',
        },
        valueFrom: {
            type: String,
            default: 'value',
        },
        placeholder: {
            type: [String, Boolean],
            default: '',
        },
    },
};

export const table = {
    data() {
        return {
            css: {
                tableClass: 'table is-narrow is-striped',
                ascendingIcon: 'fas fa-chevron-up',
                descendingIcon: 'fas fa-chevron-down',
                sortHandleIcon: 'fas fa-bars',
                loadingClass: 'is-loading',
                rowClass: '',
                wrapperClass: '',
            },
        };
    },
    methods: {
        closeAllSubcontent() {
            this.clearOpenedLines();
            EventBus.$emit('line-subcontent:leaveLine', true);
        },
        clearOpenedLines() {
            const message =
                '`clearOpenedLines()` is not implemented by the `table` mixin and must be implemented by the component!';
            if (APP_ENV !== 'production') {
                throw new Error(message);
            } else {
                console.error(message);
            }
        },
        async deleteSelectedLines({ dispatchPath, singular, plural, items, customSuccessMessage }) {
            plural = plural || `${singular}s`;
            dispatchPath = dispatchPath || `${plural}/deleteLines`;

            this.updateSelectedLinesStatus({
                statusId: Statuses.DELETED,
                dispatchPath,
                singular,
                plural,
                items,
                customSuccessMessage,
            });
        },
        async updateSelectedLinesStatus({
            statusId = null,
            dispatchPath = null,
            singular = null,
            plural = null,
            items = null,
            customSuccessMessage = null,
        }) {
            const requiredParams = { statusId, singular };

            for (let param in requiredParams) {
                if (requiredParams[param] === null) {
                    this.showDangerNotification('An error occurred.');
                    throw new Error(`Param: ${param} was not provided.`);
                }
            }

            plural = plural || `${singular}s`;
            dispatchPath = dispatchPath || `${plural}/updateLinesStatuses`;

            items = items || this.quickbarItems;
            const lineIds = _.map(items, 'id');
            const itemCount = lineIds.length;
            const itemName = itemCount > 1 ? plural : singular;
            const statusName = _.findKey(Statuses, (status) => status === statusId);

            try {
                await this.$store.dispatch(dispatchPath, { statusId, lineIds });
                await this.getData();
                this.showSuccessNotification(
                    customSuccessMessage ?? `${itemCount} ${itemName} successfully changed to ${statusName}.`
                );
            } catch (e) {
                if (e.response) {
                    this.showDangerNotification(e.response.data.message);
                } else {
                    console.error(e);
                }
            }
        },
    },
};

export const headers = {
    'X-Requested-With': 'XMLHttpRequest',
};

export const httpOptions = {
    data() {
        return {
            httpOptions: {
                headers,
            },
        };
    },
};

export const form = {
    props: {
        mode: {
            type: String,
            required: true,
            default: MODE_CREATE,
        },
    },
    methods: {
        isEditMode() {
            return this.mode === MODE_EDIT;
        },
        isCreateMode() {
            return this.mode === MODE_CREATE;
        },
    },
};

export const validation = {
    methods: {
        checkIfGreaterThanZero(value) {
            return Number(value) > 0;
        },
        checkIfNegative(value) {
            return Number(value) < 0;
        },
        checkIfNumeric(value) {
            return /^\d+(\.\d+)*$/.test(value);
        },
    },
};

export const dateValidation = {
    methods: {
        isFutureDate(date) {
            const format = 'M/D/YY';
            date = this.fromDateObject(date, format);
            return Moment(date, format).tz(Moment.tz.guess()).isAfter(Moment());
        },
    },
};

export const formatters = {
    methods: {
        formatAge(value) {
            let days = Math.floor(value / 1440);

            if (days > 4) {
                return `${days} days`;
            }

            let hours = Math.floor((value - days * 1440) / 60);
            let minutes = value - days * 1440 - hours * 60;

            //Force hours and minutes to have a leading zero, if less than 2 digits long
            hours = ('0' + hours).slice(-2);
            minutes = ('0' + minutes).slice(-2);

            return `${days}:${hours}:${minutes}`;
        },
        formatNotes(notes) {
            return notes.reduce((accumulator, note) => {
                return `${accumulator}<br>${note.body}`;
            }, '');
        },
        toDateObject(value, row, fromFormat = 'MM/DD/YYYY') {
            if (typeof row === 'string') {
                fromFormat = row;
            }

            if (value) {
                return {
                    date: Moment(value, fromFormat).tz(Moment.tz.guess()).toISOString(/* keepOffset */ true),
                    timezone: Moment.tz.guess(),
                };
            }

            return null;
        },
        formatDateString(dateString, format = 'M/D/YY h:mma', convertTz = true) {
            let parsed = Moment(dateString);

            if (convertTz) {
                let clientTimezone = Moment.tz.guess();
                parsed.tz(clientTimezone);
            } else {
                parsed.tz('UTC');
            }

            return parsed.format(format);
        },
        fromDateObject(dateObject, row, format = 'M/D/YY h:mma', convertTz = true) {
            if (typeof row === 'string') {
                format = row;
            }

            if (dateObject) {
                let { date, timezone } = dateObject;

                if (date && timezone) {
                    let parsedDate = Moment.parseZone(date);
                    let clientTimezone = Moment.tz.guess();

                    if (convertTz) {
                        return parsedDate.tz(clientTimezone).format(format);
                    }
                    return parsedDate.format(format);
                }
            }

            return null;
        },
        formatToTimestamp(value, row) {
            return Moment.utc(value, 'M/D/YY h:mma').unix();
        },
        formatTimestamp(value, row, format = null) {
            if (format === null && row) {
                format = row;
            }

            return formatTimestamp(value, format);
        },
        numberFormat: (value, row, decimals = 2) => {
            return numberFormat(value, decimals);
        },
        formatTrackingNumberLink(trackingNumber) {
            let formattedLink = '';
            if (trackingNumber.match(/^SP/)) {
                formattedLink = `http://packages.speedeedelivery.com/index.php?barcodes=${trackingNumber}`;
            } else if (trackingNumber.match(/^C/)) {
                formattedLink = `http://www.ontrac.com/trackingdetail.asp?tracking=${trackingNumber}`;
            } else if (trackingNumber.match(/^1Z/)) {
                formattedLink = `http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;
            } else {
                let numberLength = trackingNumber.length;
                if (numberLength >= 12 && numberLength <= 15) {
                    formattedLink = `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`;
                }
            }
            return formattedLink;
        },
        getTrackingNumberLinkHTML(trackingNumbers = [], typeId = TrackingNumberTypes.NORMAL) {
            if (trackingNumbers.length) {
                const filteredNumbers = trackingNumbers.filter((number) => number.type_id == typeId);
                const htmlArray = filteredNumbers.map((number) => {
                    const sanitizedNumber = this.sanitizeHTML(number.tracking_number);
                    const url = this.formatTrackingNumberLink(sanitizedNumber);
                    return url ? `<a href="${url}" target="_blank">${sanitizedNumber}</a>` : sanitizedNumber;
                });
                return htmlArray.join(', ');
            }

            return '';
        },
        formatOdooLink(type, odooId) {
            let formattedLink = '';
            const odooModels = {
                //Odoo production master branch.
                bill: { action: 654, model: 'account.move' },
                invoice: { action: 652, model: 'account.move' },
                credit: { action: 655, model: 'account.move' },
                'credit-memo': { action: 653, model: 'account.move' },
                address: { action: 660, model: 'res.partner' },
                vendor: { action: 661, model: 'res.partner' },

                // Odoo dev branch w/o test suite running during build.
                // 'bill': {'action': 218, 'model': 'account.move'},
                // 'invoice': {'action': 216, 'model': 'account.move'},
                // 'credit': {'action': 220, 'model': 'account.move'},
                // 'credit-memo': {'action': 217, 'model': 'account.move'},
                // 'address': {'action': 52, 'model': 'res.partner'},
                // 'vendor': {'action': 53, 'model': 'res.partner'},

                // Odoo dev branch w/ test suite running during build.
                // 'bill': {'action': 236, 'model': 'account.move'},
                // 'invoice': {'action': 234, 'model': 'account.move'},
                // 'credit': {'action': 238, 'model': 'account.move'},
                // 'credit-memo': {'action': 235, 'model': 'account.move'},
                // 'address': {'action': 52, 'model': 'res.partner'},
                // 'vendor': {'action': 53, 'model': 'res.partner'},
            };

            let odooModel = _.get(odooModels, type);
            if (odooModel) {
                formattedLink = this.getSetting('ERP_HOST');

                let action = _.get(odooModel, 'action');
                let model = _.get(odooModel, 'model');
                let path = 'web#id=' + odooId + '&action=' + action + '&model=' + model + '&view_type=form';

                formattedLink += '/' + path;
            }

            return formattedLink;
        },
        formatCurrency(value, row, decimals = 2) {
            return formatCurrency(value, decimals);
        },
        formatUpperCase(value, row) {
            if (value) {
                value = value.toUpperCase();
            }

            return value;
        },
        formatPercent(value) {
            return formatPercent(value);
        },
        formatPatientNames(patientArray) {
            let nameString = '';

            for (let i = 0; i < patientArray.length; i++) {
                nameString += patientArray[i].fullNameWithFacilityPatientId;
                nameString += i < patientArray.length - 1 ? '; ' : '';
            }

            return nameString;
        },
        formatFacilityNames(facilities) {
            let nameString = '';
            for (let i = 0, len = facilities.length; i < len; i++) {
                nameString += `<span class="facility-name">${facilities[i].name}`;
                nameString += i < len - 1 ? ' | ' : '';
                nameString += '</span> ';
            }

            return nameString;
        },
        formatBooleanColumn(value) {
            return value ? 'Yes' : 'No';
        },
        formatUserAndDate(user, datetime, userFirst = true, format = 'M/DD/YY h:mm A') {
            let formatted = '';

            if (user && userFirst) {
                formatted += user.name;
            }
            if (user && datetime && userFirst) {
                formatted += ', ';
            }
            if (datetime) {
                formatted += this.fromDateObject(datetime, format);
            }
            if (user && datetime && !userFirst) {
                formatted += ', ';
            }

            if (user && !userFirst) {
                formatted += user.name;
            }

            return formatted;
        },
    },
};

export const highlightErrorFields = {
    data() {
        return {
            form: {
                generalErrors: {},
                errors: {},
            },
        };
    },
    methods: {
        clearErrors() {
            this.form.generalErrors = {};
            this.form.errors = {};
        },
        combineLineErrorsIntoGeneralErrors(responseData) {
            // this is a temporary way to combine errors into generalErrors,
            // until we can create a good back-end solution
            let errors = { generalErrors: {} };
            responseData.errors.forEach((line) => {
                errors.generalErrors[line.field] = line.message;
            });

            return errors;
        },
        highlightErrorFields({ errors, generalErrors }) {
            if (generalErrors) {
                this.form.generalErrors = generalErrors;
            }

            if (errors) {
                this.form.errors = errors.reduce((accumulator, error) => {
                    if (accumulator.hasOwnProperty(error.lineNumber) === false) {
                        accumulator[error.lineNumber] = {};
                    }

                    accumulator[error.lineNumber][error.field] = error.message;

                    return accumulator;
                }, {});
            }
        },
    },
};

export const quickbar = {
    data() {
        return {
            quickbarItems: [],
            quickbarShow: false,
        };
    },
    methods: {
        ...mapMutations({
            hideQuickbar: 'shared/quickbar/hide',
            showQuickbar: 'shared/quickbar/show',
        }),
        toggleRow(event, object) {
            if (event) {
                this.quickbarItems.push(object);
            } else {
                this.quickbarItems = _.reject(this.quickbarItems, object);
            }
        },
        deselectAll() {
            EventBus.$emit('table:resetSelected', true);
        },
    },
};

export const hasDownloadable = {
    methods: {
        alertUserOfDownloadPreparation(response) {
            if (this.$store.state.shared.system.downloadConfirmationModalShown == false) {
                this.showDownloadConfirmationModal();
                return true;
            }

            this.showDownloadNotification(response);
        },
        showDownloadConfirmationModal() {
            this.$store.commit('shared/system/showDownloadConfirmationModal');
        },
        showDownloadNotification(response) {
            if (response && response.status === 202) {
                this.showInfoNotification(
                    'Your file is being prepared. An automatic download will start when it is ready.'
                );
                return true;
            }
        },
    },
};

export const authorizeAndPerformAction = {
    methods: {
        callIfAuthorized(permissionLevel, callback, callbackArgs = []) {
            if (this.currentUserCan(permissionLevel)) {
                callback.apply(this, callbackArgs);
            } else {
                this.showDialogue(
                    'Action Not Permitted',
                    "You currently don't have permission to access this feature.<br />Contact either your facility administrator or Empire for help."
                );
            }
        },
    },
};

export const dialogueBoxControls = {
    methods: {
        showDialogue(header = null, body = null) {
            if (header && body) {
                this.$store.dispatch('shared/modal/showModal', {
                    component: Dialogue,
                    data: {
                        header,
                        body,
                    },
                });
            }
        },
    },
};

export const systemNotifications = {
    methods: {
        showSuccessNotification(content = null, options = {}) {
            if (content) {
                const defaultOptions = {
                    content,
                    isSuccess: true,
                };
                options = _.merge(defaultOptions, options);
                this.$store.dispatch('shared/toast/show', options);
            }
        },
        showDangerNotification(content = null, options = {}) {
            if (content) {
                const defaultOptions = {
                    content,
                    isDanger: true,
                };
                options = _.merge(defaultOptions, options);
                this.$store.dispatch('shared/toast/show', options);
            }
        },
        showInfoNotification(content = null, options = {}) {
            if (content) {
                const defaultOptions = {
                    content,
                    isInfo: true,
                };
                options = _.merge(defaultOptions, options);
                this.$store.dispatch('shared/toast/show', options);
            }
        },
    },
};

export const onChangeStatus = {
    mixins: [systemNotifications],
    methods: {
        ...mapMutations({
            hideQuickbar: 'shared/quickbar/hide',
        }),
        async onChangeStatus(statusId, dataArray, callback = null, callbackParams = null) {
            try {
                let result = await this.api.changeStatus({
                    data: dataArray,
                    status: statusId,
                });
                let statusName = this.getStatusName(statusId);
                this.quickbarItems = [];
                this.hideQuickbar();

                if (callback != null) {
                    if (callbackParams == null) {
                        callbackParams = { statusName: statusName, data: dataArray, result: result.data };
                    }
                    callback(callbackParams);
                }

                let count = dataArray.length;
                let successMessage =
                    statusId == 10
                        ? `${count} item(s) successfully deleted.`
                        : `${count} item(s) successfully marked as ${statusName}.`;
                this.showSuccessNotification(successMessage);
            } catch (exception) {
                if (exception.reponse) {
                    this.showDangerNotification(exception.response.data.message);
                } else {
                    console.error(exception);
                }
            }
        },
        async onChangeBillingStatus(statusId, dataArray, callback = null, callbackParams = null) {
            try {
                let result = await this.api.changeBillingStatus({
                    data: dataArray,
                    status: statusId,
                });
                let statusName = this.getStatusName(statusId);
                this.quickbarItems = [];
                this.hideQuickbar();

                if (callback != null) {
                    if (callbackParams == null) {
                        callbackParams = { statusName: statusName, data: dataArray, result: result.data };
                    }
                    callback(callbackParams);
                }

                let count = dataArray.length;
                let successMessage =
                    statusId == 10
                        ? `${count} item(s) successfully deleted.`
                        : `${count} item(s) successfully marked as ${statusName}.`;
                this.showSuccessNotification(successMessage);
            } catch (exception) {
                if (exception.reponse) {
                    this.showDangerNotification(exception.response.data.message);
                } else {
                    console.error(exception);
                }
            }
        },
        getStatusName(statusId) {
            let status = [];
            status = this.$store.getters['shared/statuses/statusWhereId'](statusId);
            return status[0]['name'];
        },
    },
};

export const addToOrderForm = {
    data() {
        return {
            savedOrderName: '',
            savedOrderId: null,
            orderName: '',
        };
    },
    computed: {
        savedOrders() {
            return this.$store.state.shared.savedOrders.data;
        },
        selectedOrder() {
            let savedOrderId = this.savedOrderId;
            let selectedOrder = _.find(this.savedOrders, (order) => {
                return order.id == savedOrderId;
            });
            return selectedOrder;
        },
    },
    methods: {
        ...mapActions('shared/modal', ['showModal', 'closeModal']),
        redirectToOrderForm(savedOrder) {
            this.closeModal();

            const query = {
                saved_order_id: savedOrder.id,
            };

            this.$router.push({ name: 'orders.form', query });
        },
        getPacket(items) {
            let packet = {};

            if (!this.selectedOrder) {
                packet = {
                    name: this.orderName,
                    data: {
                        facilityId: this.facilityId,
                        items: items,
                        purchaseOrderId: this.purchaseOrderId,
                    },
                };
            } else {
                packet = _.clone(this.selectedOrder);
                if (this.orderName) {
                    packet.name = this.orderName;
                }
                packet.data.items = _.uniqBy(_.union(items, packet.data.items), 'partNumber');
            }

            return packet;
        },
        async saveOrder(items, callback = null) {
            let actionToCall = 'shared/savedOrders/create';
            let actionName = 'Saved';
            let updated = false;

            if (this.savedOrderId) {
                actionToCall = 'shared/savedOrders/update';
                actionName = 'Updated';
            }

            const packet = this.getPacket(items);

            try {
                updated = await this.$store.dispatch(actionToCall, packet);
                this.showSuccessNotification(`${actionName} Order Successfully`);
                if (callback != null) {
                    callback(updated);
                }
            } catch (e) {
                console.log(e);
                this.showDangerNotification(`${actionName} Order Failed`);
            }
        },
    },
};

export const storeFiltersInRouteQuery = {
    methods: {
        updateRouteQuery(query = {}) {
            query = _.pickBy(query, (value) => value !== null);

            if (!query.sort && this.sort) {
                query['sort'] = this.sort;
            }

            if (query.sort && Array.isArray(query.sort)) {
                query.sort = query.sort.join(',');
            }
            this.$router.push({ name: this.$route.name, query }, undefined, (err) => {
                if (!err || err.name == 'NavigationDuplicated') {
                    this.getData();
                }
            });
        },
        appendToRouteQuery(paramsToAppend) {
            const query = _.clone(this.$route.query);
            const mergedQuery = _.merge({}, query, paramsToAppend);
            this.updateRouteQuery(mergedQuery);
        },
    },
};

export const componentModal = {
    data() {
        return {
            modal: {
                isActive: false,
                heading: '',
                component: '',
                params: {},
            },
        };
    },
    methods: {
        onModalClose() {
            this.modal.isActive = false;
            this.modal.class = '';
            this.modal.heading = '';
            this.modal.component = '';
            this.modal.params = {};
        },
    },
};

export const BLANK_ITEM = {
    partNumber: null,
    quantity: null,
    description: null,
    manufacturerId: null,
    vendorId: null,
    shippingAddressId: null,
    costCenterId: null,
    practitionerId: null,
    dateNeeded: null,
    notes: [],
    customerNote: null,
    customerInternalMemo: null,
    internalNote: null,
    jobNumber: null,
    isForStock: false,
    isGroundShipping: false,
    isDoNotSub: false,
    isPutOnHold: false,
    isWarrantyReplacement: false,
    isShoppingList: 0,
    sendNotificationWhenReceived: false,
    vendorFieldInformation: [],
    facilityId: null,
    patients: [],
    product: {
        type: {
            id: ProductTypes.EMPTY,
            name: 'empty',
        },
    },
    onHand: 0,
};

export const unsavedNoteNotification = {
    mixins: [systemNotifications],
    data() {
        return {
            unsavedNote: false,
            alreadyNotified: false,
            NOTEABLE_TYPES,
        };
    },
    methods: {
        onBodyChanged() {
            this.unsavedNote = true;
            this.alreadyNotified = false;
        },
        onBodySaved() {
            this.unsavedNote = false;
            this.alreadyNotified = false;
        },
        notifyForUnsavedNotes() {
            if (this.unsavedNote && !this.alreadyNotified) {
                this.alreadyNotified = true;
                this.showDangerNotification('You have an unsaved note, are you sure you want to proceed?');
                return false;
            }
            return true;
        },
    },
    created() {
        EventBus.$on('bodyChanged', this.onBodyChanged);
        EventBus.$on('bodySaved', this.onBodySaved);
        this.$on('bodyChanged', this.onBodyChanged);
        this.$on('bodySaved', this.onBodySaved);
    },
    destroyed() {
        EventBus.$off('bodyChanged', this.onBodyChanged);
        EventBus.$off('bodySaved', this.onBodySaved);
    },
};

export const requiresBlacklistPassword = {
    data() {
        return {
            blacklistPassword: '',
        };
    },
    methods: {
        ...mapActions('shared/modal', ['showModal']),
        showVendorBlacklistConfirmationModal(callback, { isMultiple = false }) {
            this.showModal({
                component: VendorBlacklistConfirmationModal,
                data: {
                    callback,
                    isMultiple: isMultiple,
                },
            });
        },
        resetBlacklistPassword() {
            this.blacklistPassword = '';
        },
        errorIsBlacklistRelated(errors) {
            return (
                this.entityIsBlacklisted(errors, 'vendor') ||
                this.entityIsBlacklisted(errors, 'manufacturer') ||
                this.blacklistPasswordIsInvalid(errors)
            );
        },
        entityIsBlacklisted(errors, entityType) {
            let blacklistedMessage = `${entityType} is blacklisted`.toUpperCase();
            return _.some(errors, (error) => error.toUpperCase().includes(blacklistedMessage));
        },
        blacklistPasswordIsInvalid(errors) {
            let passwordMessage = 'INVALID PASSWORD';
            return _.some(errors, (error) => error.toUpperCase().includes(passwordMessage));
        },
    },
};

export const routerNavigation = {
    computed: {
        ...mapState('shared/system/', { previousRoute: 'previousRoute' }),
    },
    methods: {
        ...mapMutations('shared/system/', ['setPreviousRoute']),
        pushPreviousRoute(defaultRouteName = '', defaultAdminRouteName = '') {
            this.$router.push(this.getPreviousRoute(defaultRouteName, defaultAdminRouteName));
        },
        getPreviousRoute(defaultRouteName, defaultAdminRouteName) {
            if (this.previousRoute.name) {
                return this.previousRoute;
            }

            if (this.currentUserIsAdmin && defaultAdminRouteName) {
                return { name: defaultAdminRouteName };
            }

            return { name: defaultRouteName };
        },
    },
};

export const billAndCreditFormsShared = {
    methods: {
        onMoveItemUp(index) {
            let itemToMove = this.lines.splice(index, 1);
            this.lines.splice(index - 1, 0, itemToMove[0]);
        },
        onMoveItemDown(index) {
            let itemToMove = this.lines.splice(index, 1);
            this.lines.splice(index + 1, 0, itemToMove[0]);
        },
        onInsertItemAbove(index) {
            let itemToInsert = this.getBlankLineWithInformationFromIndex(index);
            this.lines.splice(index, 0, itemToInsert);
        },
        onRemoveItem(index) {
            this.lines.splice(index, 1);
        },
        getBlankLineWithInformationFromIndex(index) {
            if (index < 0) {
                return this.getBlankLine();
            }

            let line = this.getBlankLine();
            let lineToCopy = this.lines[index];
            line.facilityId = lineToCopy.facilityId;
            line.shippingAddressId = lineToCopy.shippingAddressId;
            line.manufacturerId = lineToCopy.manufacturerId;
            line.costCenterId = lineToCopy.costCenterId;

            return line;
        },
        onAddLine() {
            let lineToPush = this.getBlankLineWithInformationFromIndex(this.lines.length - 1);
            this.lines.push(lineToPush);
        },
        countLinesInCostCenter(needle) {
            return this.lines.filter(({ costCenterId }) => costCenterId == needle).length;
        },
        getAddressCodeById(id) {
            if (id) {
                let address = this.getAddressFromId(id);
                return address?.code ?? '';
            }
        },
    },
    computed: {
        pageTitle() {
            return this.$route.meta.title;
        },
        isOpen() {
            return this.status.id == Statuses.OPEN;
        },
        isInvoiced() {
            let invoicedStatuses = [
                Statuses.INVOICED,
                Statuses.POSTED,
                Statuses.POSTED_CHANGED,
                Statuses.POSTED_REVIEWED,
                Statuses.NEED_SHIPPING,
            ];
            return invoicedStatuses.includes(this.status.id);
        },
        vendorNotes() {
            return this.selectedVendor?.notes ?? [];
        },
        selectedVendor() {
            return this.$store.getters['shared/vendors/selectedVendor'](this.vendorId);
        },
        selectedCostCenterCustomerRebate() {
            return this.lines.reduce((accumulator, line) => {
                if (line.costCenterId && line.facilityId) {
                    let selectedCostCenter = this.getAddressFromId(line.costCenterId);
                    let selectedFacility = this.facilityById(line.facilityId);
                    let vendorId = this.vendorId;

                    if (selectedCostCenter && selectedFacility) {
                        let customerRebate = _.find(selectedFacility.vendors, { id: vendorId });
                        let id = selectedCostCenter.id;
                        if (
                            id &&
                            accumulator.indexOf(id) === -1 &&
                            customerRebate &&
                            customerRebate.rebatePercent > 0
                        ) {
                            accumulator[id] = customerRebate.rebatePercent;
                        }
                    }
                }

                return accumulator;
            }, []);
        },
        selectedCostCenters() {
            let costCenterIds = this.lines.map((line) => {
                return line.costCenterId;
            });

            return _.uniq(_.filter(costCenterIds));
        },
        numericalPurchaseOrderId() {
            if (this.purchaseOrderId) {
                return this.purchaseOrderId.replace(/[^\d]+/g, '');
            }
            return '';
        },
        purchaseOrderErrors() {
            if (this.purchaseOrderId && !this.numericalPurchaseOrderId) {
                return { purchaseOrder: 'Purchase Order number must contain digits.' };
            }
            return {};
        },
        hasPurchaseOrderErrors() {
            return this.purchaseOrderErrors.hasOwnProperty('purchaseOrder');
        },
    },
};

export const PlacesPurchaseOrders = {
    data() {
        return {
            placingOrder: false,
        };
    },
    methods: {
        ...mapMutations({
            setQuickbarItems: 'shared/quickbar/setItems',
        }),
        /**
         * Save all checked Lines.
         * @returns {UpdateLineResult}
         */
        async saveSelectedLines() {
            /**
             * @type {UpdateLineResult}
             */
            const result = await this.updateLines({ lines: this.quickbarItems });
            if (!result.isSuccess) {
                this.showDangerNotification('Some or All of the selected Order Lines could not be saved.');
            }
            result.errors.forEach((failure) => {
                Bugsnag.notify(failure.outcome.reason);
                this.formatAndSetLineErrors(failure.outcome.reason.response.data);
            });
            return result;
        },
        async getPurchaseOrderNumber() {
            try {
                let result = await PurchaseOrdersApi.getNextId({ status: 105 });
                this.manageForm.id = result.recordId;
            } catch (e) {
                if (e.response.message) {
                    this.showDangerNotification(e.response.message);
                }
                console.error(e);
            }
        },
        async onPlaceOrder() {
            if (this.placingOrder) {
                return;
            }

            this.placingOrder = true;

            this.clearLineErrors();
            this.clearErrors();

            try {
                const lineUpdateResult = await this.saveSelectedLines();

                if (lineUpdateResult.isSuccess) {
                    let purchaseOrder = _.clone(this.manageForm);
                    let noteBody = purchaseOrder.notes;

                    purchaseOrder.lines = this.quickbarItems;
                    purchaseOrder.notes = [];

                    if (noteBody) {
                        purchaseOrder.notes.push({
                            body: noteBody,
                            type: 'general',
                        });
                    }

                    if (this.selectedOrderMethodIsEdi && this.warehouseId) {
                        purchaseOrder.warehouseId = this.warehouseId;
                    }

                    let result = {};

                    try {
                        result = await PurchaseOrdersAdminApi.place(purchaseOrder);
                    } catch (error) {
                        this.placingOrder = false;
                        this.onPlaceOrderError(error);
                        return;
                    }

                    if (result.success) {
                        this.setQuickbarItems([]);

                        await this.$emit('actions:purchase-order-placed');

                        const shippingMethod = this.manageForm.shippingMethod;
                        this.manageForm = _.clone(EMPTY_MANAGE_FORM);
                        this.manageForm.shippingMethod = shippingMethod;
                        this.quickbarOrderMethodChanged = false;
                        this.showSuccessNotification(`Successfully placed order`);
                    }
                }
            } finally {
                this.placingOrder = false;
            }
        },
        onPlaceOrderError(error) {
            if (error && error.response) {
                let responseData = error.response.data;

                switch (responseData.type) {
                    case 'edi_validation_error':
                        this.handleEdiValidationError(responseData);
                        break;
                    case 'edi_gateway_error':
                        this.handleEdiGatewayError(responseData);
                        break;
                    default:
                        this.handleGeneralErrors(responseData);
                        break;
                }
            }
        },
        handleEdiGatewayError(responseData) {
            let body = _.get(
                responseData,
                'errorMessage',
                'The EDI Gateway had a fatal error, however the client did not receive an error message from Imperium.'
            );

            this.showModal({
                component: Dialogue,
                data: {
                    header: 'EDI Gateway Error',
                    body,
                },
            });
        },
        handleEdiValidationError(responseData) {
            let body = this.formatEdiErrors(responseData);

            this.showModal({
                component: Dialogue,
                data: {
                    header: 'EDI Validation Error',
                    body,
                },
            });
        },
        formatEdiErrors(responseData) {
            let errors = responseData.errors;
            let body = '<ul>';

            for (let errorKey in errors) {
                for (let error of errors[errorKey]) {
                    body += `<li>${error}</li>`;
                }
            }

            body += '</ul>';

            return body;
        },
        handleGeneralErrors(responseData) {
            this.showDangerNotification(responseData.message);
            this.generalErrors = responseData.generalErrors;
            this.formatAndSetLineErrors(responseData);

            if (this.generalErrors.invalid_purchase_order) {
                this.showDangerNotification(this.generalErrors.invalid_purchase_order);
            } else if (this.generalErrors.invalid_line_statuses) {
                this.showDangerNotification(this.generalErrors.invalid_line_statuses);
            }
        },
        clearErrors() {
            this.generalErrors = {};
        },
    },
};

export const HasDateExpectedColumn = {
    methods: {
        hasPurchaseOrderNote(line) {
            return Boolean(line.purchaseOrderNote);
        },
    },
};

export const handlesErrors = {
    methods: {
        handleError(error, errorMessage) {
            if (error.response) {
                errorMessage = error.response.message || error.response.body.message || errorMessage;
                this.showDangerNotification(errorMessage);
            } else {
                Bugsnag.notify(error);
                console.log(error);
                this.showDangerNotification(errorMessage);
            }
        },
    },
};
