// @ts-check
import _ from 'lodash';

/**
 * Return merged maps and sets for use with Lodash mergeWith.
 *
 * @link https://lodash.com/docs/4.17.15#mergeWith
 * @param {any} objValue
 * @param {any} srcValue
 * @param {string} key
 * @param {any} object
 * @param {any} source
 * @param {any} stack
 * @returns
 */
function mapAndSetMerger(objValue, srcValue, key, object, source, stack) {
    if (_.isMap(objValue) && _.isMap(srcValue)) {
        const rv = new Map();
        objValue.forEach((value, key) => rv.set(key, value));
        srcValue.forEach((value, key) => rv.set(key, value));
        return rv;
    } else if (_.isMap(srcValue)) {
        const rv = new Map();
        srcValue.forEach((value, key) => rv.set(key, value));
        return rv;
    } else if (_.isMap(objValue)) {
        const rv = new Map();
        objValue.forEach((value, key) => rv.set(key, value));
        return rv;
    }

    if (_.isSet(objValue) && _.isSet(srcValue)) {
        return new Set([...objValue, ...srcValue]);
    } else if (_.isSet(objValue)) {
        return new Set([...objValue]);
    } else if (_.isSet(srcValue)) {
        return new Set([...srcValue]);
    }
}

/**
 * Mix Vuex StoreOptions.
 * @param {...import("vuex").StoreOptions} options
 * @returns {import("vuex").StoreOptions}
 */
export function mixVuexStoreOptions(...options) {
    return _.mergeWith({}, ...options, mapAndSetMerger);
}
