// @ts-check
import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import useCacheManager from 'use/cacheManager';
import { computed } from '@vue/composition-api';
import { Statuses } from 'enums';
import { getUID } from 'helpers';

/**
 * @type {{
 *  name: string
 *  uuid: string
 * }}
 */
const metadata = {
    name: 'Users Dropdowns',
    uuid: getUID(),
};

/**
 * Setup cache/refresh for Users dropdowns.
 * @returns
 */
export default function useUserDropdowns(config = {}) {
    const fetcher = async () => {
        const resp = await apis.Users.listUsers({
            pagination_type: 'simple',
            per_page: -1,
            partials: ['dropdown'],
            sort: ['name'],
        });
        return resp.body.data || [];
    };

    const { data: users, error, isValidating, mutate } = useConfiguredSWRV(metadata.uuid, fetcher, config);

    if (!users.value) {
        users.value = [];
    }

    /**
     * @type {import('@vue/composition-api').ComputedRef<UserV2[]>}
     */
    const practitioners = computed(() => {
        return users.value.filter(({ is_practitioner }) => is_practitioner);
    });

    /**
     * @type {import('@vue/composition-api').ComputedRef<UserV2[]>}
     */
    const activePractitioners = computed(() => {
        return practitioners.value.filter(({ status_id }) => status_id == Statuses.ACTIVE);
    });

    /**
     * @type {import('@vue/composition-api').ComputedRef<UserV2[]>}
     */
    const inactivePractitioners = computed(() => {
        return practitioners.value.filter(({ status_id }) => status_id == Statuses.INACTIVE);
    });

    useCacheManager(mutate, metadata);

    return {
        /**
         * @type {import('@vue/composition-api').Ref<User[]>}
         */
        users,
        practitioners,
        activePractitioners,
        inactivePractitioners,
        error,
        isValidating,
        mutate,
        metadata,
    };
}
