import * as types from './mutation_types';

export default {
    [types.SET_USER_FACILITIES]: (state, { facilities }) => {
        state.user_facilities = facilities;
    },
    [types.SET_CHOOSE_FACILITY_STATUS]: (state, { facility }) => {
        state.shared.user.need_to_choose_facility = false;
        state.shared.user.can_choose_facility = true;
        state.shared.user.current_facility = facility;
    },
    [types.SET_PERMISSIONS](state, { permissions }) {
        state.shared.user.permissions = permissions;
    },
    [types.SET_LCODE_MANUFACTURER_WHITELIST](state, manufacturers) {
        state.lcodeManufacturerWhitelist = manufacturers;
    },
};
