export default {
    mapper: {
        relations: {
            belongsTo: {
                line: {
                    foreignKey: 'line_id',
                    localField: 'line',
                },
                trackingNumber: {
                    foreignKey: 'tracking_number_id',
                    localField: 'trackingNumber',
                },
            },
        },
    },
};
