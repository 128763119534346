import _ from 'lodash';
import ShippingServiceApi from 'api/endpoints/shippingServices';

export default {
    namespaced: true,
    state: {
        options: [],
        map: {},
        inRequest: false
    },
    mutations: {
        setOptions(store, payload) {
            store.options = payload;
        },
        mapServices(store, payload) {
            store.map = {};
            payload.forEach(shippingService => {
                store.map[shippingService.id] = shippingService;
            });
        }
    },
    actions: {
        async getOptions({ state, commit }, params = {}) {
            if (state.inRequest === false) {
                state.inRequest = true;

                const response = await ShippingServiceApi.get(params);
                const shippingServices = response.data.data;
                commit('setOptions', shippingServices);
                commit('mapServices', shippingServices);

                state.inRequest = false;
            }
        },
        async saveReorder({ state }) {
            let shippingServices = _.cloneDeep(state.options);
            return await ShippingServiceApi.saveReorder(shippingServices);
        },
        reorderDropdown({ getters, state, commit }, {shippingServiceId, up, dropdownModifier}) {      
            let orderVariable = _.camelCase(dropdownModifier + 'DropdownOrder');
            let shippingService = _.cloneDeep(state.map[shippingServiceId]);
            if (shippingService[orderVariable] === 0 && up) { return }
            if (_.maxBy(state.options, service => service[orderVariable])[orderVariable] === shippingService[orderVariable] && !up) { return }
            
            let stateShippingServices = _.cloneDeep(state.options);
            let serviceIndex = stateShippingServices.findIndex(service => service.id === shippingService.id);

            let newDropdownOrder = up ? shippingService[orderVariable] - 1 : shippingService[orderVariable] + 1;
            
            let displacedIndex = stateShippingServices.findIndex(service => service[orderVariable] === newDropdownOrder);

            if (serviceIndex !== -1) {
                stateShippingServices[serviceIndex][orderVariable] = newDropdownOrder;
            }
            if (displacedIndex !== -1) {
                stateShippingServices[displacedIndex][orderVariable] = shippingService[orderVariable];
            }

            commit('setOptions', stateShippingServices);
            commit('mapServices', stateShippingServices);
        },
    },
    getters: {
        shippingServices(state) {
            return state.options;
        },
        getVendorsOfService(state) {
            return (id) => {
                let found = state.options.find(option => option.id == id);
                return found ? found.vendors : []
            }
        },
        getAddressesOfService(state) {
            return (id) => {
                let found = state.options.find(option => option.id == id);
                return found ? found.addresses : []
            }
        },
        shippingServiceOptions(state, getters) {
            return _.sortBy(getters.shippingServices, service => service.dropdownOrder);
        },
        billShippingServiceOptions(state, getters) {
            return _.sortBy(getters.shippingServices, service => service.billDropdownOrder);
        },
        invoiceShippingServiceOptions(state, getters) {
            return _.sortBy(getters.shippingServices, service => service.invoiceDropdownOrder);
        },
    }
}
