export function makeGuard(router) {
    return async function (to, from, next) {
        const store = router.app.$store;
        //Don't reload user on unmatched or guest routes.
        if (to.matched.every((record) => record.meta.guestOnly)) {
            next();
        } else {
            if (store.getters['shared/currentUser/shouldLoadUserViaFallBack']) {
                await store.dispatch('shared/currentUser/getData', { fallback: true });
                const resolvedUser = store.state.shared.currentUser.resolvedUser;
                const storedUser = store.state.shared.currentUser.data;

                if (storedUser.id && resolvedUser.id !== storedUser.id) {
                    // If the user has changed, direct the Browser to the
                    // intended route instead of using the Vue Router so
                    // that the application is re-initialized and the
                    // new user's information is set.
                    window.location.href = to.fullPath;
                } else {
                    store.dispatch('shared/currentUser/setUserData', resolvedUser);
                    store.dispatch('shared/currentUser/loadedUser');
                    next();
                }
            } else {
                next();
            }
        }
    };
}

function apply(router) {
    router.beforeEach(makeGuard(router));
}

export default {
    apply,
};
