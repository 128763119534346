import merge from 'deepmerge';
import {Statuses} from 'enums';
import _ from 'lodash';
import { apis } from 'api/client';

export default {
    namespaced: true,
    state: {
        dropdownData: [],
        idToFacility: {},
        fetchingDropdownData: false,
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
        },
        mapFacilities(store, payload) {
            store.idToFacility = {};
            payload.forEach(facility => {
                store.idToFacility[facility.id] = facility;
            });
        },
        updateFacility(store, payload) {
            store.dropdownData = store.dropdownData.map((facility) => {
                if (payload.id == facility.id) {
                    facility = payload;
                }

                return facility;
            });
            store.idToFacility[payload.id] = payload;
        }
    },
    getters: {
        facilityById(state) {
            return (facilityId) => state.idToFacility[facilityId];
        },
        nameFromId(state, getters) {
            return (facilityId) => {
                let name = null;
                let facility = getters.facilityById(facilityId);

                if (facility && facility.name) {
                    name = facility.name;
                }

                return name;
            };
        },
        exceptionsForId(state, getters) {
            return (facilityId) => {
                let exceptions = null;
                let facility = getters.facilityById(facilityId);

                if (facility && facility.priceExceptions) {
                    exceptions = facility.priceExceptions;
                }

                return exceptions;
            }
        },
        activeFacilities(state, getters) {
            return state.dropdownData.filter((facility) => facility.statusId === Statuses.ACTIVE);
        }
    },
    actions: {
        getFacilityById({state}, id) {
            return state.idToFacility[id];
        },
        async getDropdownData({ state, commit}, params = {}) {
            if (state.fetchingDropdownData === false) {
                params = merge(
                    params,
                    {
                        sort: ['name'],
                        per_page: -1,
                        pagination_type: 'simple'
                    }
                );

                state.fetchingDropdownData = true;
                const response = await apis.Facilities.listFacilities(params);
                commit('setDropdownData', response.body.data);
                commit('mapFacilities', response.body.data);
                state.fetchingDropdownData = false;
            }

            return true;
        },
        async updateInventoryLocation({state, dispatch, commit}, payload) {
            let facility = await dispatch('getFacilityById', payload.facilityId);

            if (facility && facility.inventoryLocations) {
                let locationIndex = facility.inventoryLocations.findIndex((facilityInventoryLocation) => facilityInventoryLocation.id == payload.id);

                if (locationIndex) {
                    facility.inventoryLocations.splice(locationIndex, 1, payload);
                } else {
                    facility.inventoryLocations.push(payload);
                }

                commit('updateFacility', facility);
            }
        },
        async removeInventoryLocation({state, dispatch, commit}, payload) {
            let facility = await dispatch('getFacilityById', payload.facilityId)

            if (facility && facility.inventoryLocations) {
                facility.inventoryLocations = facility.inventoryLocations.filter((inventoryLocation) => inventoryLocation.id != payload.id);

                commit('updateFacility', facility);
            }
        },
    },
}
