import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                user: {
                    foreignKey: 'user_id',
                    localField: 'user',
                },
            },
            hasOne: {
                environmentData: {
                    foreignKey: 'bug_report_id',
                    localField: 'environmentData',
                },
            },
            morphMany: {
                ...auditsRelation('bugReport'),
            },
        },
    },
};
