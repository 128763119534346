import _ from 'lodash';
import { apis } from 'api/client';

export default {
    namespaced: true,
    state: {
        options: [],
        idToService: {},
        inRequest: false,
        addressShippingServices: {},
        shippingServiceVendors: {},
        cacheLifespan: 1000 * 60 * 5,
    },
    mutations: {
        setOptions(store, payload) {
            store.options = payload;
        },
        mapServices(store, payload) {
            store.idToService = {};
            payload.forEach((shippingService) => {
                store.idToService[shippingService.id] = shippingService;
            });
        },
        setaddressShippingServices(store, payload) {
            store.addressShippingServices[payload.name] = payload.request;
        },
        unsetaddressShippingService(store, name) {
            delete store.addressShippingServices[name];
        },
        setShippingServiceVendors(store, payload) {
            store.shippingServiceVendors[payload.name] = payload.request;
        },
        unsetShippingServiceVendor(store, name) {
            delete store.shippingServiceVendors[name];
        },
    },
    actions: {
        async getOptions({ state, commit }, params = {}) {
            if (state.inRequest === false) {
                state.inRequest = true;

                const response = await apis['Shipping Services'].listShippingServices({}, { ...params });
                const shippingServices = response.body.data;
                commit('setOptions', shippingServices);
                commit('mapServices', shippingServices);

                state.inRequest = false;
            }
        },
        async getOptionsIfNotGotten({ getters, dispatch }, params = {}) {
            if (getters.shippingServices.length == 0) {
                dispatch('getOptions', params);
            }
        },
        async getAddressShippingServices({ state, getters, commit }, params = {}) {
            const name = `${params.shipping_service_id}${params.address_id}`;

            if (state.addressShippingServices[name]) {
                return state.addressShippingServices[name];
            }
            let request = apis.Addresses.listAddressShippingServices(params);
            let payload = { name, request };

            commit('setaddressShippingServices', payload);
            setTimeout(() => {
                commit('unsetaddressShippingService', name);
            }, state.cacheLifespan);

            return request;
        },
        async getShippingServiceVendors({ state, getters, commit }, params = {}) {
            const name = `${params.shipping_service_id}${params.vendor_id}`;

            if (state.shippingServiceVendors[name]) {
                return state.shippingServiceVendors[name];
            }
            let request = apis.Vendors.listShippingServiceVendors(params);
            let payload = { name, request };

            commit('setShippingServiceVendors', payload);
            setTimeout(() => {
                commit('unsetShippingServiceVendor', name);
            }, state.cacheLifespan);

            return request;
        },
    },
    getters: {
        shippingServices(state) {
            return state.options;
        },
        servicesFromDropdown(state, getters, rootState, rootGetters) {
            return (dropdownName) => {
                const dropdown = rootGetters['revamp/dropdowns/dropdownWhereName'](dropdownName);
                let options = [];

                if (dropdown) {
                    options = dropdown.children
                        .map((id) => {
                            return getters.serviceWhereId(id);
                        })
                        // shippingService can be undefined if this action evaluates
                        // before shipping services are fetched. Populating options with
                        // undefined causes TypeErrors downstream
                        .filter((shippingService) => shippingService !== undefined);
                }

                return options;
            };
        },
        shippingServiceOptions(state, getters, rootState, rootGetters) {
            return getters.servicesFromDropdown('SHIPPING_SERVICES');
        },
        billShippingServiceOptions(state, getters) {
            return getters.servicesFromDropdown('BILL_SHIPPING_SERVICES');
        },
        invoiceShippingServiceOptions(state, getters) {
            return getters.servicesFromDropdown('INVOICE_SHIPPING_SERVICES');
        },
        serviceWhereId(state) {
            return (serviceId) => {
                return state.idToService[serviceId];
            };
        },
    },
};
