// @ts-check
import Viewer from './viewer.js';
const state = {
    /**
     * @type {Object.<number, Viewer[]>} A map of Line Id to an array Viewers representing other users viewing that Line.
     */
    views: {},
    isEnabled: true,
};
export default state;
