import _ from 'lodash';
import SmartApi from 'SmartApi';
import OrdersApi from 'api/endpoints/orders';
import OrderHistoryApi from 'api/endpoints/orderHistory';
import { TABLE_STORE, SUBCONTENT_STORE, SHOWS_ORDER_LINE_RETURN_STATUSES } from 'store/shared/mixins';
import ConflictingUpdateResolutionModal from 'component/shared/ConflictingUpdateResolutionModal.vue';
import { mixVuexStoreOptions } from 'store/helpers.js';
import { isFulfilled, isRejected } from 'app/asyncHelpers.js';

/**
 * The outcome of a resolved or rejected Promise.
 * @typedef {object} Outcome
 * @property {string} status The status of the outcome. Either fulfilled or rejected.
 * @property {*} reason The reason a Promise was rejected.
 * @property {*} value The value a Promise resolved to.
 */

/**
 * An order Line in its current and original state.
 * @typedef {object} LineOriginal
 * @property {Line} line The Line in its current state.
 * @property {Line} original The Line in its original state.
 */

/**
 * A record of a set of atomic changes to a model.
 * @typedef {object} Audit
 * @property {string} created_at
 * @property {number} id
 * @property {string} event
 * @property {object} new_values
 * @property {object} old_values
 * @property {number} user_id
 */

/**
 * Context provided by the API server when a stale update is detected.
 * @typedef {object} StaleUpdateContext
 * @property {Audit[]} audits The Audits that were created since the stale model was fresh.
 * @property {object} current A map of property names to objects with name and value properties that contain the persisted value for that property.
 * @property {object} previous A map of property names to objects with name and value properties that contain the submitted value for that property.
 */

/**
 * A response from the API server that indicates a stale update
 * @typedef {object} StaleUpdateErrorResponse
 * @property {object} data
 * @property {StaleUpdateContext} data.context
 * @property {string} data.message
 * @property {string} data.type
 */

/**
 * @typedef {ValidationErrorResponse | StaleUpdateErrorResponse | FatalErrorResponse} LineErrorResponse
 */

/**
 * A response from the API server to a request to update a Line.
 * @typedef {LineSuccessResponse | LineErrorResponse} LineResponse
 */

/**
 * The outcome of a Promise that represents a success response from the API server.
 * @typedef {object} SuccessResponseOutcome
 * @property {string} status The status of the resolved Promise.
 * @property {object} value
 * @property {LineSuccessResponse} value.response
 */

/**
 * The outcome of a Promise that represents a failure response from the API server.
 * @typedef {object} FailureResponseOutcome
 * @property {string} status The status of the resolved Promise.
 * @property {object} reason
 * @property {LineErrorResponse} reason.response
 */

/**
 * @typedef {object} Conflict
 * @property {object} current
 * @property {object} previous
 * @property {Line} model
 * @property {number} toVersion
 */

/**
 * The outcome of a Promise that represents a failure response from the API server.
 * @typedef {object} StaleResponseOutcome
 * @property {string} status The status of the resolved Promise.
 * @property {object} reason
 * @property {StaleUpdateErrorResponse} reason.response
 * @property {Conflict} reason.conflicts
 */

/**
 * The outcome of a Promise that represents a response from the API server.
 * @typedef {SuccessResponseOutcome | FailureResponseOutcome | StaleResponseOutcome} ResponseOutcome
 */

/**
 * A response that indicates a successful update and the line the request was for.
 * @typedef {object} LineUpdateSuccess
 * @property {SuccessResponseOutcome} outcome
 * @property {Line} line The Line included in the request.
 */

/**
 * A response that indicates a failed update and the line the request was for.
 * @typedef {object} LineUpdateFailure
 * @property {FailureResponseOutcome} outcome
 * @property {Line} line The Line included in the request.
 */

/**
 * A response that indicates an update failed because the Line was stale and the Line the request was for.
 * @typedef {object} StaleLineUpdateFailure
 * @property {StaleResponseOutcome} outcome
 * @property {Line} line The Line included in the request.
 */

/**
 * A response and the line the request was for.
 * @typedef {LineUpdateSuccess | LineUpdateFailure} LineUpdateResult
 */

/**
 * A set of LineUpdateResult grouped by response type.
 * @typedef {object} LineUpdateResults
 * @property {LineUpdateSuccess[]} successes
 * @property {StaleLineUpdateFailure[]} stales
 * @property {LineUpdateFailure[]} errors
 */

/**
 * The result of calling updateLines().
 * @typedef {object} UpdateLineResult
 * @property {boolean} isSuccess
 * @property {LineUpdateSuccess[]} successes
 * @property {LineUpdateFailure[]} errors
 * @property {StaleLineUpdateFailure[]} stales
 */

const STALE_MESSAGE = 'Model has been modified since last read.';

/**
 * Return true if the response's message indicates the request included a stale Line.
 * @param {object} wrapper
 * @param {LineResponse} wrapper.response
 * @returns {boolean}
 */
function hasStaleMessage({ response }) {
    return _.get(response, 'data.message') === STALE_MESSAGE;
}

/**
 * Return true if the outcome's response's contains an error for a stale update.
 * @param {object} wrapper
 * @param {ResponseOutcome} wrapper.outcome An outcome object returned by Promise.allSettled().
 * @returns {boolean}
 */
function isRejectedForBeingStale({ outcome }) {
    const response = _.get(outcome, 'reason.response', {});
    return hasStaleMessage({ response });
}

/**
 * Submit requests to update a sequence of Lines.
 * @param {object} wrapper
 * @param {LineOriginal[]} wrapper.lineOriginals The lines to update with their original values.
 * @param {string[]} wrapper.includes The Lines' relations the server should include in the response.
 * @param {string} wrapper.routeName The name of the API route
 * @returns {Promise<LineUpdateResults>}
 */
async function submitLineUpdates({ lineOriginals, includes, routeName = 'facilities.orders.edit' }) {
    const requests = lineOriginals.map(({ line, original }) =>
        submitLineUpdate({ line, original, includes, routeName })
    );
    /**
     * @type {ResponseOutcome[]}
     */
    const outcomes = await Promise.allSettled(requests);
    const lines = lineOriginals.map(({ line }) => line);
    /**
     * @type {LineUpdateResult[]}
     */
    const resultLines = _.zip(outcomes, lines).map(([outcome, line]) => {
        return { outcome, line };
    });
    /**
     * @type {LineUpdateSuccess[]}
     */
    const successes = resultLines.filter((result) => isFulfilled(result.outcome));
    /**
     * @type {LineUpdateFailure[]}
     */
    const failures = resultLines.filter((result) => isRejected(result.outcome));
    /**
     * @type {StaleLineUpdateFailure[]}
     */
    const stales = failures.filter(isRejectedForBeingStale);
    const errors = failures.filter(_.negate(isRejectedForBeingStale));
    return {
        successes,
        stales,
        errors,
    };
}

/**
 * Submit a request to update a Line.
 * @param {object} wrapper
 * @param {Line} wrapper.line The line to update with the desired state.
 * @param {Line} wrapper.original The line to update in its original, unmodified state.
 * @param {string[]} wrapper.includes A list of relations the server should include in the response.
 * @param {string} wrapper.routeName The name of the API route.
 * @returns {Promise<LineUpdateResult>}
 */
async function submitLineUpdate({
    line,
    original,
    includes = [
        'category',
        'costCenter',
        'facility',
        'inventoryLocation',
        'isInventoryPart',
        'manufacturer',
        'notes',
        'patients',
        'practitioner',
        'product',
        'purchaseOrder',
        'serialNumbers',
        'shippingAddress',
        'trackingNumbers',
        'userOrderedBy',
        'userReceivedBy',
        'vendor',
    ],
    routeName = 'facilities.orders.edit',
}) {
    /**
     * @type {LineResponse}
     */
    let response;
    line.includes = line.includes || includes;
    try {
        response = await SmartApi.put({
            routeName,
            // The "extra" parameters do not cause problems, but they do add unnecessary query parameters.
            routeParams: {
                lineId: line.id,
                facilityId: line.facilityId,
                line: line.id,
            },
            data: line,
        });
    } catch (error) {
        /**
         * @type {LineResponse}
         */
        const response = _.get(error, 'response', {});
        if (hasStaleMessage({ response })) {
            handleStale({ line, original, error });
        }
        throw error;
    }
    return { response, line };
}

/**
 * Update the Line with non-conflicting changes from the most recent version, assemble conflicting properties and their values, re-throw the error.
 * @param {object} wrapper
 * @param {Line} wrapper.line
 * @param {Line} wrapper.original
 * @param {object} wrapper.error
 * @param {StaleUpdateErrorResponse} wrapper.error.response
 */
function handleStale({ line, original, error }) {
    const previous = _.get(error, 'response.data.context.previous', {});
    const current = _.get(error, 'response.data.context.current', {});
    const modified = Object.keys(current).filter((attribute) => {
        return attribute != 'lock_version' && attribute != 'lockVersion';
    });

    const changedByUs = new Set(
        modified.filter((attribute) => {
            return line[attribute] != original[attribute];
        })
    );

    const changedByThem = new Set(
        modified.filter((attribute) => {
            const currentVal = current[attribute].value;
            const localOriginal = original[attribute];
            return localOriginal != currentVal;
        })
    );
    const intersection = modified.filter((attribute) => {
        return changedByUs.has(attribute) && changedByThem.has(attribute);
    });
    // Accept all of their changes that do not conflict with our changes excluding
    // the lock version, which should be set only after the user confirms the conflict
    // changes. Otherwise, they could abort reconciliation, press save again, and
    // overwrite conflicting values without explicitly confirming their intent.
    changedByThem.forEach((attribute) => {
        if (!changedByUs.has(attribute)) {
            line[attribute] = current[attribute].value;
        }
    });

    const toVersion = current.lockVersion.value;
    error.conflicts = {
        previous: _.pick(previous, intersection),
        current: _.pick(current, intersection),
        model: line,
        toVersion,
    };
    throw error;
}

export default mixVuexStoreOptions(
    {},
    TABLE_STORE,
    SUBCONTENT_STORE,
    SHOWS_ORDER_LINE_RETURN_STATUSES,
    {
        namespaced: true,
        state: {
            dataForPrint: [],
            pagination: {
                per_page: 50,
            },
            filters: {},
            openedLines: new Set(),
            moduleName: 'Order History',
        },
        actions: {
            get({ state, commit }, params = {}) {
                params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);
                OrderHistoryApi.get(params).then((response) => {
                    commit('setData', response.data.data);
                    let pagination = {};

                    if (response.data.meta) {
                        pagination = response.data.meta.pagination;
                    }

                    commit('setPaginationData', pagination);
                });
            },
            async getOrderForPrint({ state, commit }, params = {}) {
                let response = await OrdersApi.get(params);
                commit('setDataForPrint', response.data.data);
            },
            /**
             * Submit requests to mark multiple Lines as received.
             * @param {import("vuex").ActionContext} actionContext
             * @param {Line[]} lines
             * @returns {Promise<UpdateLineResult>}
             */
            async markAsReceived({ dispatch }, lines = []) {
                return await dispatch('updateLines', { lines, routeName: 'lines.date-received' });
            },
            /**
             * Submit a request to update a Line.
             * @param {import("vuex").ActionContext} actionContext
             * @param {Line} line
             * @returns {Promise<LineSuccessResponse>}
             */
            async updateLine({ dispatch }, line) {
                /**
                 * @type {UpdateLineResult}
                 */
                let result = await dispatch('updateLines', { lines: [line] });
                if (result.errors.length) {
                    throw result.errors[0].outcome.reason;
                }
                return result.successes[0].outcome.value.response;
            },
            /**
             * Submit requests to update multiple Lines.
             * @param {import("vuex").ActionContext} actionContext
             * @param {{lines: Line[]}} linesWrapper
             * @returns {Promise<UpdateLineResult>}
             */
            async updateLines({ getters, commit, dispatch }, { lines, routeName = 'facilities.orders.edit' }) {
                const lineOriginals = lines.map((line) => {
                    /** @type {Line} */
                    const original = getters.getLineById(line.id);
                    const lockVersion = line.lockVersion || original.lockVersion;
                    return {
                        line: {
                            ...line,
                            lockVersion,
                        },
                        original,
                    };
                });
                const { successes, stales, errors } = await submitLineUpdates({
                    lineOriginals,
                    routeName,
                });
                successes
                    .map(
                        /**
                         * @param {LineUpdateSuccess} lineUpdateSuccess
                         * @returns {Line}
                         */
                        ({ outcome }) => _.get(outcome, 'value.response.data.data', {})
                    )
                    .forEach((line) => commit('updateItemPartial', line));
                const isSuccess = errors.length == 0 && stales.length == 0;
                const result = {
                    isSuccess,
                    successes,
                    errors,
                    stales,
                };
                if (isSuccess || errors.length > 0) {
                    return result;
                }
                const conflicts = _.map(stales, (stale) => {
                    return stale.outcome.reason.conflicts;
                });
                // Return a Promise that will be resolved when the user
                // closes the ConflictingUpdateResolutionModal. This allows callers of this
                // function to await its response and define success and error
                // handling that will occur after the user closes the ConflictingUpdateResolutionModal.
                return new Promise(async (resolve, reject) => {
                    await dispatch(
                        'shared/modal/showModal',
                        {
                            component: ConflictingUpdateResolutionModal,
                            data: {
                                conflicts,
                                /**
                                 *
                                 * @param {Conflict[]} conflicts
                                 */
                                onConfirm: async (conflicts) => {
                                    // Update the line's lock version to the lockVersion reported as current
                                    // by the API server.
                                    conflicts.forEach((conflict) => {
                                        conflict.model.lockVersion = conflict.toVersion;
                                    });
                                    const nextAttempt = await dispatch('updateLines', {
                                        lines: conflicts.map(({ model }) => model),
                                        routeName,
                                    });
                                    if (nextAttempt && nextAttempt.isSuccess) {
                                        resolve(nextAttempt);
                                    } else {
                                        reject(nextAttempt);
                                    }
                                },
                                onCancel: () => {
                                    reject(result);
                                },
                            },
                            bindings: {
                                'model-name': 'Order Line',
                            },
                        },
                        {
                            root: true,
                        }
                    );
                });
            },
        },
        mutations: {
            setDataForPrint(state, payload) {
                state.dataForPrint = payload;
            },
            updateItemPartial(state, payload) {
                let currentItemIndex = state.data.findIndex((item) => item.id === payload.id);

                if (currentItemIndex > -1) {
                    let clone = Object.assign({}, state.data[currentItemIndex], payload);
                    state.data.splice(currentItemIndex, 1, clone);
                }
            },
        },
        getters: {
            areAllLinesOpen(state) {
                return (lineIds) => _.every(lineIds, (id) => state.openedLines.has(id));
            },
            getLineById(state) {
                return (id) => {
                    return state.data.find((line) => line.id == id);
                };
            },
        },
    });
