import { auditsRelation, notesRelation } from 'app/jsd/helpers';

export default {
    nullableProperties: ['facility_id'],
    mapper: {
        relations: {
            belongsTo: {
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
            },
            hasMany: {
                inventory: {
                    foreignKey: 'shipping_address_id',
                    localField: 'inventoryRecords',
                },
                invoice: {
                    foreignKey: 'cost_center_id',
                    localField: 'invoices',
                },
                line: [
                    {
                        foreignKey: 'cost_center_id',
                        localField: 'linesPurchased',
                    },
                    {
                        foreignKey: 'shipping_address_id',
                        localField: 'linesShipped',
                    },
                ],
                creditMemo: {
                    foreignKey: 'cost_center_id',
                    localField: 'creditMemos',
                },
                addressVendor: {
                    foreignKey: 'address_id',
                    localField: 'addressVendors',
                },
                // shippingMethod: { Needs a pivot relationship
                //     foreignKey: 'id',
                //     localField: 'shippingMethods',
                // },
                // shippingService: { Needs a pivot relationship
                //     foreignKey: 'id',
                //     localField: 'shippingServices',
                // },
                user: {
                    foreignKey: 'user_id',
                    localField: 'whitelistedByUsers',
                },
            },
            morphMany: {
                ...auditsRelation('address'),
                ...notesRelation('address'),
            },
        },
    },
    customIndexes: {},
};
