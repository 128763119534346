<template>
    <div :class="['modal', { 'is-active': isActive }, modalClass]">
        <div class="modal-background" @click="onClose"></div>
        <div class="modal-content" :class="contentClass">
            <div class="message">
                <div class="message-header" v-if="showHeader">
                    <slot name="modal-header"></slot>
                </div>
                <div class="message-body pretty-scrollbars">
                    <slot name="modal-body"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import useModal from 'use/modal';

export default defineComponent({
    setup(props, { emit }) {
        const Modal = useModal();

        function onClose() {
            emit('modal:close');
            Modal.close();
        }

        return { Modal, onClose };
    },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        modalClass: {
            type: String,
            default: '',
        },
        contentClass: {
            type: String,
            default: '',
        },
    },
    computed: {
        showHeader() {
            return !!this.$slots['modal-header'] || !!this.$scopedSlots['modal-header'];
        },
    },
});
</script>

<style lang="scss">
@media print {
    .modal.is-active {
        display: none;
    }
}
.modal {
    z-index: 1120;
    .modal-content {
        overflow: initial;
        margin: 0; // IE 11 apparently requires this so it will be centered. [DJF]

        .message-body {
            max-height: 85vh;
            min-height: 1em;
            display: flex;
            flex-direction: column;
            -webkit-overflow-scrolling: touch;
            overflow-y: auto;
            overflow-x: hidden;

            .message-body-content {
                flex-basis: 100%;
                flex-shrink: 0;
                flex-grow: 1;
            }

            .message-body-buttons {
                margin-top: 2rem;
                margin-bottom: 1rem;
                display: flex;
                justify-content: flex-start;
                align-self: flex-start;

                .level {
                    width: 100%;
                }
            }
        }
    }
}
.width-30vw {
    width: 30vw;
}
.width-40vw {
    width: 40vw;
}
.width-50vw {
    width: 50vw;
}
.width-70vw {
    width: 70vw;
}
@media screen and (max-width: 768px) {
    .width-30vw,
    .width-40vw,
    .width-50vw,
    .width-70vw {
        width: 90vw;
    }
}
</style>
