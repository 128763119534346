import * as types from './mutation_types';
import { statuses } from './state';

export default {
    [types.AUTH_REQUEST]: (state) => {
        state.status = statuses.LOADING;
    },
    [types.AUTH_SUCCESS]: (state, { expiry }) => {
        state.status = statuses.SUCCESS;
        state.hasLoadedOnce = true;
        state.expiry = expiry;

    },
    [types.AUTH_ERROR]: (state) => {
        state.status = statuses.ERROR;
        state.hasLoadedOnce = true;
    },
    [types.AUTH_LOGOUT]: (state) => {
        state.status = statuses.LOGGED_OUT;
        state.expiry = 0;
    },
    [types.AUTH_UNAUTHENTICATED]: (state) => {
        state.status = statuses.UNAUTHENTICATED;
        state.expiry = 0;
    },
    [types.AUTH_BEGIN_LOGOUT]: (state) => {
        state.status = statuses.LOADING;
    }
};
