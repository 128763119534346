import * as mutationTypes from "./mutation_types.js";
import * as actionTypes from "./action_types.js";
import { apis } from "api/client.js";
import Bugsnag from '@bugsnag/js';

export default {
    [actionTypes.GET_DOCUMENT_PROCESSORS]: async ({ commit }, params = {}) => {
        commit(mutationTypes.SET_IS_LOADING, {isLoading: true});
        let response;
        try {
            response = await apis["Document Processors"].listDocumentProcessors(
                {},
                { per_page: -1, pagination_type: 'simple' }
            );
        } catch (error) {
            Bugsnag.notify(error);
            return;
        } finally {
            commit(mutationTypes.SET_IS_LOADING, {isLoading: false});
        }
        commit(mutationTypes.SET_DOCUMENT_PROCESSORS, {
            documentProcessors: response.body.data
        });
    },
    [actionTypes.ENSURE_DOCUMENT_PROCESSORS]: async ({ state, dispatch }) => {
        // Document processors are not modified during normal operations.
        // In general, additions or modifications will accompany a deployment.
        // Assume that if any document processors have been fetched then the
        // client does not need to re-fetch them.
        if (state.documentProcessors.length > 0) {
            return;
        }
        await dispatch(actionTypes.GET_DOCUMENT_PROCESSORS);
    }
};
