export default {
    mapper: {
        relations: {
            belongsTo: {
                facility: {
                    foreignKey: 'facility_id',
                    localField: 'facility',
                },
            },
            hasMany: {
                shoppingListLine: {
                    foreignKey: 'shopping_list_id',
                    localField: 'shoppingListLine',
                },
            },
        },
    },
};
