<template>
    <div class="checkbox-cluster">
        <label v-if="label" :class="['label', labelClass]">{{ label }}</label>
        <div class="checkbox-cluster-buttons" v-if="!disabled">
            <emp-button label="All" buttonClass="is-primary" @click="onCheckAll" />
            <emp-button label="None" buttonClass="is-danger" @click="onUncheckAll" />
        </div>
        <div class="columns is-multiline is-vertical">
            <div :class="['field', fieldClass]" v-for="(item, index) in usedItems" :key="`${_uid}-${index}`">
                <p class="control">
                    <label :class="['label', labelClass]" :disabled="disabled" :for="item">
                        <input :id="item" :value="item" type="checkbox" v-model="internalValue" :disabled="disabled" />
                        <template>{{ item }}</template>
                    </label>
                </p>
            </div>
        </div>
        <div class="add-new-button" v-if="!disabled">
            <emp-textbox
                :label="addLabel"
                fieldClass=""
                inputClass="has-text-right"
                v-model.number="newSerialNumber"
                name="newCheckedItem"
                data-testid="newCheckedItem"
            />

            <emp-button label="Add" buttonClass="is-primary" @click="onAddCheckedItem" />
        </div>
        <p class="help is-danger" v-for="(message, index) in errors" :key="index">{{ message }}</p>
    </div>
</template>

<style lang="scss" scoped>
.checkbox-cluster-buttons {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;

    & > button {
        margin-right: 1rem;
    }
}
</style>
<script>
import { defineComponent } from '@vue/composition-api';
import _ from 'lodash';

export default defineComponent({
    data() {
        return {
            usedItems: [],
            internalValue: [],
            newSerialNumber: '',
        };
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        fieldClass: {
            type: String,
            default: 'column is-one-third',
        },
        value: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        labelClass: {
            type: String,
            default: '',
        },
        errors: {
            type: Object,
            default: () => {},
        },
        name: {
            type: String,
            default: '',
        },
        addLabel: {
            type: String,
            default: '',
        },
    },
    methods: {
        onCheckAll() {
            this.internalValue = [...this.usedItems];
        },
        onUncheckAll() {
            this.internalValue = [];
        },
        onAddCheckedItem() {
            this.usedItems.push(this.newSerialNumber);
            this.internalValue = [...this.internalValue, this.newSerialNumber];
            this.newSerialNumber = '';
        },
    },
    watch: {
        internalValue: function (newVal, oldVal) {
            this.$emit('input', newVal);
        },
    },
    created() {
        this.internalValue = [...this.value];
        this.usedItems = [...this.items];
    },
});
</script>
