import _ from 'lodash';
import QueueApi from 'api/endpoints/queue';
import { TABLE_STORE } from 'store/shared/mixins';

const STATUS_DELETED = 5;
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    mutations: {
        updateJob(state, payload) {
            let id = payload.id;
            delete payload.id;

            state.data.map((job) => {
                if (job.id == id) {
                    Object.keys(payload).forEach((key) => {
                        job[key] = payload[key];
                    });
                }

                return job;
            });
        },
        addJob(state, payload) {
            state.data.unshift(payload);
        },
        removeJob(state, payload) {
            let id = payload.id;
            delete payload.id;

            state.data = state.data.reduce((accumulator, job) => {
                if (job.id != id) {
                    accumulator.push(job);
                }

                return accumulator;
            }, []);
        },
    },
    actions: {
        get({ state, commit }) {
            QueueApi.get().then((response) => {
                commit('setData', response.data.data);
            });
        },
        updateQueueList({ state, commit }, payload) {
            let exists = state.data.find((job) => job.id == payload.id) !== undefined;

            if (exists && payload.status == STATUS_DELETED) {
                commit('removeJob', payload);
            } else if (exists && payload.status != STATUS_DELETED) {
                commit('updateJob', payload);
            } else if (payload.status != STATUS_DELETED) {
                commit('addJob', payload);
            }
        },
    },
});
