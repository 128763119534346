<template>
    <div class="field" :class="fieldClass">
        <slot name="label">
            <label v-if="label" class="label" :class="labelClass" :for="`${name}_${_uid}`">
                {{ label }}
            </label>
        </slot>
        <p
            class="control"
            :class="[
                {
                    'has-icons has-icons-left': icon,
                },
            ]"
        >
            <input
                :autofocus="autofocus"
                class="input"
                :class="[inputClass, { 'is-danger': hasError }]"
                :disabled="disabled"
                :max="max"
                :maxlength="maxlength"
                :min="min"
                :name="name"
                :step="step"
                :tabindex="tabindex"
                :type="type"
                :value="value"
                :readonly="readonly"
                :placeholder="placeholder"
                @input="$emit('input', $event.target.value)"
                @keypress="$emit('keypress', $event)"
                @keyup="$emit('keyup', $event)"
                @blur="$emit('blur', $event)"
                @click="$emit('click', $event)"
                :data-lpignore="ignoreLastPass"
                :id="`${name}_${_uid}`"
                v-bind="$attrs"
            />
            <span v-if="icon" class="icon is-left">
                <i class="fa" :class="icon"> </i>
            </span>
        </p>
        <template v-if="hasErrorArray">
            <p class="help is-danger" v-for="(error, index) in errors[name]" :key="index">{{ error }}</p>
        </template>
        <template v-else-if="hasError">
            <p class="help is-danger">{{ errors[name] }}</p>
        </template>
    </div>
</template>

<script>
import { input } from './mixins';

export default {
    inheritAttrs: false,
    props: {
        max: [String, Number],
        maxlength: [String, Number],
        min: [String, Number],
        step: [String, Number],
        placeholder: String,
        ignoreLastPass: {
            type: Boolean,
            default: true,
        },
    },
    mixins: [input],
};
</script>
