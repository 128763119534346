export let statuses = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    LOGGED_OUT: 'LOGGED_OUT',
    UNAUTHENTICATED: 'UNAUTHENTICATED',
}

export default {
    expiry: 0,
    status: 'UNAUTHENTICATED',
    hasLoadedOnce: false,
}