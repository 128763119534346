export default {
    mapper: {
        relations: {
            belongsTo: {
                address: {
                    foreignKey: 'address_id',
                    localField: 'address',
                },
                shippingService: {
                    foreignKey: 'shipping_service_id',
                    localField: 'shippingService',
                },
            },
        },
    },
};
