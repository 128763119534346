import { statuses } from './state';
export default {
    isAuthenticated(state) {
        // Expiry is seconds since the Unix Epoch.
        // Date.now() returns milliseconds.
        const now = Math.floor(Date.now() / 1000);
        return state.expiry > now;
    },
    isLoading(state) {
        return state.status === statuses.LOADING
    },
    isLoggedOut(state) {
        return state.status === statuses.LOGGED_OUT;
    }
}
