import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = route('users');
        return await axios.get(url, { params });
    },
    async show(params = {}) {
        const { userId, facilityId } = params;
        delete params.userId;
        delete params.facilityId;

        if (!userId) {
            throw Error('User ID is required.');
        }

        if (!facilityId) {
            throw Error('Facility ID is required.');
        }

        const url = route('facilities.users.show', [facilityId, userId]);
        const response = await axios.get(url, { params });
        return response.data.data;
    },
    async update(params = {}, facilityId) {
        let userId = params.id;

        const url = route('facilities.users.update', [facilityId, userId]);
        const response = await axios.put(url, params);
        return response.data.data;
    },
    async updateSelf(params = {}) {
        const url = route('user.updateSelf');
        const response = await axios.put(url, params);
        return response.data.data;
    },
};
