// @ts-check

import VueRouter from 'vue-router';

/**
 * @typedef {{[x: string]: Function | undefined}} VueFilters
 */

/**
 * @typedef {import("vue").VueConstructor | import("vue").ComponentOptions<Vue>} VueMixin
 */

/**
 * @typedef {{[x: string]: import("vue").ComponentOptions}} VueComponents
 */

/**
 * @typedef {{[x: string]: import("vee-validate/dist/types/types").ValidationRule}} VeeValidateRules
 */

/**
 * @typedef {Object} VuePluginAndOptions
 * @property {import("vue").PluginObject<any> | import("vue").PluginFunction<any>} plugin
 * @property {any[]} options
 */

/**
 * @typedef {VuePluginAndOptions[]} VuePlugins
 */

/**
 * @typedef {{apply: (router: any) => void}} NavigationGuard
 */

/**
 * The configuration for Axios, and Vue.
 */
export default class Config {
    /**
     * Create a configuration instance.
     *
     * @param {import("axios").AxiosRequestConfig} axios
     * @param {VueFilters} filters
     * @param {VueMixin} mixins
     * @param {VueComponents} components
     * @param {VeeValidateRules} validationRules
     * @param {VuePlugins} plugins
     * @param {VueRouter} router
     * @param {NavigationGuard[]} guards
     */
    constructor(axios, filters, mixins, components, validationRules, plugins, router, guards) {
        this.axios = axios;
        this.filters = filters;
        this.mixins = mixins;
        this.components = components;
        this.validationRules = validationRules;
        this.plugins = plugins;
        this.router = router;
        this.guards = guards;
    }
}
