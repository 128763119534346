import * as types from './mutation_types';

export default {
    setMenuItems: (state, { list }) => {
        state.menu = list;
    },
    [types.SET_INVENTORY_MANUFACTURERS](state, manufacturers) {
        state.inventoryManufacturers = manufacturers;
    },
    [types.SET_INVENTORY_ADDRESSES](state, addresses) {
        state.inventoryAddresses = addresses;
    },
    [types.SET_MANUFACTURERS_WITH_PRODUCTS](state, manufacturers) {
        state.manufacturersWithProduct = manufacturers;
    },
    [types.SET_WHITELISTED_ADDRESSES](state, addresses) {
        state.whitelistedAddresses = addresses;
    },
    [types.SET_WHITELISTED_USERS](state, users) {
        state.whitelistedUsers = users;
    },
    [types.SET_PRODUCT_TYPE_MANUFACTURERS](state, { typeId, manufacturers }) {
        state.manufacturersByProductType[typeId] = manufacturers;
    },
    [types.SET_SHOPPING_LIST_MANUFACTURERS](state, manufacturers) {
        state.shoppingListManufacturers = manufacturers;
    },
};
