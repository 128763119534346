import _ from 'lodash';
import InvitationsApi from 'api/endpoints/invitations';

const DEFAULT_FILTERS = {};
const DEFAULT_PAGINATION = {
    page: 1,
    per_page: 5
}

export default {
    namespaced: true,
    state: {
        data: [],
        paginationData: {},
        filters: _.clone(DEFAULT_FILTERS),
        pagination: _.clone(DEFAULT_PAGINATION),
        moduleName: 'Invitations'
    },
    mutations: {
        setData(state, payload) {
            state.data = payload;
        },
        clearData(state) {
            state.data = [];
        },
        setPaginationData(state, payload) {
            state.paginationData = payload;
        },
        setPage(state, page) {
            state.pagination.page = page;
        },
        clearPage(state) {
            state.pagination.page = DEFAULT_PAGINATION.page;
        },
        setPerPage(state, perPage) {
            state.pagination.per_page = perPage;
        },
        clearPerPage(state) {
            state.pagination.per_page = DEFAULT_PAGINATION.per_page;
        },
        setFilters(state, payload) {
            Object.keys(payload).forEach((key, keyIndex) => {
                state.filters[key] = payload[key];
            });
        },
        clearFilters(state) {
            state.filters = _.clone(DEFAULT_FILTERS);
        }
    },
    actions: {
        get({ state, commit }, id = undefined) {
            let params = _.merge({}, state.filters, state.pagination);

            InvitationsApi.get(id, params).then((response) => {
                commit('setData', response.data.data);
                commit('setPaginationData', response.data.meta.pagination);
            });
        },
        async getForFacility({state, commit}, facilityId) {
            let response = await InvitationsApi.forFacility(facilityId);
            commit('setData', response.data.data);
            commit('setPaginationData', response.data.meta.pagination);
        }
    }
}
