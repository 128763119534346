export default {
    mapper: {
        relations: {
            belongsTo: {
                invoice: {
                    foreignKey: 'invoice_id',
                    localField: 'invoice',
                },
                purchaseOrder: {
                    foreignKey: 'purchase_order_id',
                    localField: 'purchaseOrder',
                },
            },
        },
    },
};
