import _ from 'lodash';
import ShoppingListLinesApi from 'api/endpoints/shoppingListLines';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        default_sort: ['part_number'],
    },
    actions: {
        get({ state, commit }, params = {}) {
            let paramsMerged = _.merge({}, params, state.filters, state.pagination, { sort: state.sort });

            ShoppingListLinesApi.get(paramsMerged).then(({ data }) => {
                if (data.data) {
                    commit('setData', data.data);
                } else {
                    console.error('Shopping List does not exist.');
                }
            });
        },
    },
    mutations: {
        setData(state, value) {
            state.data = value;
        },
    },
});
