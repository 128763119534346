<template>
    <div
        @mouseenter="showIfHover"
        @mouseleave="hideIfHover"
        @focus="show"
        @blur="hide"
        @click="show"
        class="is-inline-block"
    >
        <div>
            <div class="tooltip-button" :class="{ 'small-button': smallButton }" ref="anchor"
                ><slot name="outlet"></slot
            ></div>
            <div ref="tooltip" class="tooltip" :style="tooltipStyle"
                ><div class="tooltip-content-boundary"> <slot name="tooltip"></slot> </div
                ><div class="arrow" data-popper-arrow></div
            ></div>
        </div>
        <div class="overlay" ref="overlay" v-if="needsOverlay" @click.stop="hide"></div>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import _ from 'lodash';

const MODE = {
    HOVER: 'hover',
    CLICK: 'click',
};
Object.freeze(MODE);
export default {
    name: 'Tooltip',
    data() {
        return {
            /**
             * @type {import("@popperjs/core").Instance | null}
             */
            popper: null,
            isShowing: false,
            arePopperEventListenersEnabled: false,
        };
    },
    props: {
        mode: {
            type: String,
            default: MODE.HOVER,
            validator: function (/** @type {string} */ mode) {
                return MODE.hasOwnProperty(mode.toUpperCase());
            },
        },
        placement: {
            type: String,
            default: 'auto',
            validator: function (placement) {
                const options = [
                    'auto',
                    'auto-start',
                    'auto-end',
                    'top',
                    'top-start',
                    'top-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'right',
                    'right-start',
                    'right-end',
                    'left',
                    'left-start',
                    'left-end',
                ];
                return options.includes(placement);
            },
        },
        minWidth: {
            type: Number,
            default: 0,
        },
        maxWidth: {
            type: Number,
            default: 0,
        },
        whiteSpace: {
            type: String,
            default: 'pre-wrap',
            validator: function (/** @type {string} */ whiteSpace) {
                const options = [
                    'normal',
                    'nowrap',
                    'pre',
                    'pre-wrap',
                    'pre-line',
                    'break-spaces',
                    'inherit',
                    'initial',
                    'revert',
                    'unset',
                ];
                return options.includes(whiteSpace);
            },
        },
        smallButton: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        initPopper() {
            let placement = this.placement;
            this.popper = createPopper(this.$refs.anchor, this.$refs.tooltip, {
                placement: placement,
                modifiers: this.modifiers,
            });
        },
        show() {
            if (this.isShowing) {
                return;
            }
            this.$refs.tooltip.setAttribute('data-show', '');
            this.arePopperEventListenersEnabled = true;
            this.popper.setOptions({
                modifiers: this.modifiers,
            });
            this.popper.update();
            this.isShowing = true;
        },
        hide() {
            if (this.isHidden) {
                return;
            }
            this.isShowing = false;
            this.$refs.tooltip.removeAttribute('data-show');
            this.arePopperEventListenersEnabled = false;
            this.popper.setOptions({
                modifiers: this.modifiers,
            });
        },
        showIfHover() {
            if (!this.isHoverable) {
                return;
            }
            this.show();
        },
        hideIfHover() {
            if (!this.isHoverable) {
                return;
            }
            this.hide();
        },
    },
    computed: {
        isHidden() {
            return !this.isShowing;
        },
        isHoverable() {
            return this.mode === MODE.HOVER;
        },
        isClickable() {
            return this.mode === MODE.CLICK;
        },
        offsetModifier() {
            return {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            };
        },
        eventListenersModifier() {
            return {
                name: 'eventListeners',
                enabled: this.arePopperEventListenersEnabled,
            };
        },
        modifiers() {
            return [this.offsetModifier, this.eventListenersModifier];
        },
        needsOverlay() {
            return this.isClickable && this.isShowing;
        },
        tooltipStyle() {
            let style = {
                whiteSpace: this.whiteSpace,
            };
            if (this.minWidth) {
                style.minWidth = this.minWidth + 'px';
            }
            if (this.maxWidth) {
                style.maxWidth = this.maxWidth + 'px';
            }
            return style;
        },
    },
    mounted() {
        this.initPopper();
    },
    beforeDestroy() {
        this.popper?.destroy();
    },
};
</script>

<style lang="scss" scoped>
.tooltip {
    display: none;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 5px;
    border: 0px;
    outline: none;
    padding: 5px 8px;
    // An explicit Z-index appears is required for the tooltips
    // to reliably display over inputs, which are positioned elements.
    z-index: 50;

    strong {
        color: #fff;
    }
}
.tooltip-content-boundary {
    overflow: auto;
    max-height: 400px;
}
.tooltip[data-show] {
    display: block;
}
.tooltip-button {
    background-color: #4f535d;
    border-radius: 50%;
    color: #eceded;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    width: 1.5rem;

    &.small-button {
        color: #fff;
        font-size: 0.8em;
        font-weight: 300;
        height: 1rem;
        line-height: 1rem;
        margin: 0 0.2rem;
        width: 1rem;
    }
}
.arrow,
.arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

.tooltip[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
}

.tooltip[data-popper-placement^='left'] > .arrow {
    right: -4px;
}

.tooltip[data-popper-placement^='right'] > .arrow {
    left: -4px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    // Must be less than the .tooltip's z-index
    z-index: 40;
    width: 100vw;
    height: 100vh;
}
</style>
