import { computed } from '@vue/composition-api';

export function hasErrorProps(defaults = {}) {
    return {
        errors: {
            type: [Array, Object],
            default: () => {
                return [];
            },
        },
        name: {
            type: String,
            default: defaults.name || '',
        },
    };
}

export function hasErrors(props) {
    const hasError = computed(() => props.name in props.errors);
    const hasErrorArray = computed(() => hasError.value && props.errors[props.name] instanceof Array);

    return {
        hasError,
        hasErrorArray,
    };
}
