<template>
    <div class="field" :class="fieldClass">
        <label class="label" :class="labelClass" v-if="label">{{ label }}</label>
        <div @click="focusNewTag()" class="input input-tag-wrapper" :class="{ 'read-only': readonly, active: focus }">
            <span v-for="(tag, index) in tags" :key="index" class="control input-tag">
                <span class="tag is-success"
                    >{{ tag }}
                    <button v-if="!readonly" @click.prevent.stop="remove(index)" class="delete is-small"></button
                ></span>
            </span>
            <input
                v-if="!readonly"
                :placeholder="placeholderComputed"
                type="text"
                v-model="newTag"
                @keyup="$emit('keyup', $event)"
                @keydown="$emit('keydown', $event)"
                @keydown.delete.stop="removeLastTag()"
                @keydown.enter.prevent.stop="addNew(newTag)"
                @blur="
                    addNew(newTag);
                    focus = false;
                "
                class="new-tag"
                autocomplete="off"
            />
        </div>
        <p class="help is-danger" v-if="hasError">{{ error }}</p>
    </div>
</template>

<script>
import { input } from './mixins';

const validators = {
    email: new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
    url: new RegExp(/^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i),
    text: new RegExp(/^[a-zA-Z]+$/),
    digits: new RegExp(/^[\d() \.\:\-\+#]+$/),
    isodate: new RegExp(/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/),
};

export default {
    mixins: [input],
    props: {
        tags: {
            type: Array,
            default: () => [],
        },
        overrideTagInput: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        validate: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            newTag: '',
            focus: false,
            error: '',
        };
    },
    methods: {
        removeActive() {
            this.focus = false;
        },
        focusNewTag() {
            this.error = '';
            if (this.readonly) {
                return;
            }
            this.focus = true;
            this.$el.querySelector('.new-tag').focus();
        },
        addNew(tag) {
            if (this.overrideTagInput) {
                this.newTag = '';
                return;
            }
            // JEF: Protect against null tags.
            let tags = this.tags || [];
            if (tag && !tags.includes(tag) && this.validateIfNeeded(tag)) {
                tags.push(tag.trim());
                this.tagChange();
            }
            this.$emit('input', tags);
            this.newTag = '';
        },
        validateIfNeeded(tagValue) {
            if (this.validate === '' || this.validate === undefined) {
                return true;
            } else if (Object.keys(validators).indexOf(this.validate) > -1) {
                let validated = validators[this.validate].test(tagValue);
                if (!validated) {
                    this.error = 'Please type valid ' + this.validate + ' value.';
                }
                return validated;
            }
            return true;
        },
        remove(index) {
            this.tags.splice(index, 1);
            this.tagChange();
        },
        removeLastTag() {
            if (this.newTag) {
                return;
            }
            this.tags.pop();
            this.tagChange();
        },
        tagChange() {
            this.$emit('input', JSON.parse(JSON.stringify(this.tags)));
        },
    },
    computed: {
        placeholderComputed() {
            return (this.tags && this.tags.length > 0) || this.focus ? '' : this.placeholder;
        },
        hasError() {
            return this.error != '';
        },
    },
};
</script>

<style lang="scss">
.input-tag-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    outline: none;
    overflow: hidden;

    .input-tag {
        display: inline-block;
        font-size: 0.75rem;
        margin-right: 4px;
        flex-shrink: 0;
        flex-grow: 0;
    }
    .new-tag {
        background: transparent;
        border: 0;
        color: #777;
        font-weight: 400;
        outline: none;
        font-size: 1rem;
        width: 200px;
        flex-grow: 1;
        flex-shrink: 0;
    }

    &.active {
        border-color: #88ab75;
    }
    &.read-only {
        cursor: default;
    }
}
</style>
