import { auditsRelation, notesRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                address: {
                    foreignKey: 'cost_center_id',
                    localField: 'costCenter',
                },
                credit: {
                    foreignKey: 'credit_id',
                    localField: 'credit',
                },
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
            },
            hasManyThrough: {
                purchaseOrder: {
                    foreignKey: 'purchase_orders_id',
                    localField: 'purchaseOrders',
                },
            },
            hasMany: {
                creditLine: {
                    foreignKey: 'credit_memo_id',
                    localField: 'lines',
                },
            },
            morphMany: {
                ...auditsRelation('creditMemo'),
                ...notesRelation('creditMemo'),
            },
        },
    },
};
