import * as actionTypes from "./action_types";
import SmartApi from "SmartApi";
import _ from "lodash";

export default {
    async getData({ dispatch }) {
        await dispatch(actionTypes.LIST_UPLOADED_FILES);
    },
    [actionTypes.LIST_UPLOADED_FILES]: async (
        { state, commit },
        params = {}
    ) => {
        params = _.merge(
            {},
            state.filters,
            state.pagination,
            { sort: state.sort },
            params
        );
        const request = {
            routeName: "listUploadedFiles",
            config: { params }
        };
        const response = await SmartApi.get(request);
        commit("setData", response.data.data);
        commit("setPaginationData", response.data.meta);
    }
};
