import _ from 'lodash';
import CreditMemoApi from 'api/endpoints/admin/creditMemos';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    actions: {
        get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);

            CreditMemoApi.get(params).then((response) => {
                commit('setData', response.data.data);
                commit('setPaginationData', response.data.meta.pagination);
            });
        },
    },
    mutations: {
        edit(state, payload) {
            var index = _.findIndex(state.data, { id: payload.id });
            state.data.splice(index, 1, payload);
        },
        changeStatuses(state, lineIdStatus) {
            for (var dataObject in state.data) {
                if (lineIdStatus.lineIds.indexOf(state.data[dataObject]['id']) > -1) {
                    state.data[dataObject]['status']['name'] = lineIdStatus.statusName;
                }
            }
        },
    },
    getters: {
        api() {
            return CreditMemoApi;
        },
    },
});
