<template>
    <div>
        <div class="message-body-content">
            <div class="columns is-multiline">
                <select-facility label="Facility" v-model="facilityId" />
            </div>
        </div>
        <div class="message-body-buttons">
            <div class="level">
                <div class="level-left"></div>
                <div class="level-right">
                    <div class="level-item">
                        <emp-button label="Save" @click="onSave" buttonClass="is-primary" />
                    </div>
                    <div class="level-item">
                        <emp-confirmation-button label="Cancel" @click="onClose" buttonClass="is-danger" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FacilitiesApi from 'api/endpoints/admin/facilities';
import EventBus from 'event-bus';
import moment from 'moment';
import _ from 'lodash';
import { MODE_EDIT } from 'constants';
import SelectFacility from 'component/shared/elements/SelectFacility';

export default {
    components: {
        SelectFacility,
    },
    data() {
        return {
            facilityId: {},
        };
    },
    methods: {
        clear() {
            this.facilityId = {};
        },
        onClose() {
            EventBus.$emit('user-invitations-form:onClose');
            this.clear();
        },
        async onSave() {
            let facility = _.find(this.facilities, { id: parseInt(this.facilityId) });

            let payload = {
                facilityId: facility.id,
                name: facility.name,
                dateOfInvite: moment().format('Y-MM-DD  HH:mm:ss'),
            };

            EventBus.$emit('user-invitations-form:onAdd', payload);

            this.onClose();
        },
        getFacilities() {
            this.$store.dispatch('shared/facilities/getDropdownData');
        },
    },
    computed: {
        facilities() {
            return this.$store.state.shared.facilities.dropdownData;
        },
    },
    mounted() {
        this.getFacilities();
    },
};
</script>
