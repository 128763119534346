import _ from "lodash";

export default {
    namespaced: true,
    /**
     * @type {ModalState}
     */
    state: {
        data: null,
        moduleName: "Modal",
        modals: [],
        modalSaveStates: new Map(),
    },
    actions: {
        /**
         * Display the requested modal.
         *
         * This action does not directly display the modal. Instead
         * it indirectly displays the modal by setting the name of
         * the requested modal as the currently active modal's name.
         * The showing and hiding of a given modal is handled by
         * observers of that state property.
         * @param {VuexActionContext} actionContext
         * @param {ModalContext} modalContext
         */
        showModal({ commit }, { data, component, bindings, listeners }) {
            commit("pushModal", { data, component, bindings, listeners });
            commit("setData", data);
        },
        /**
         * Close the open modal and display the next modal on the stack.
         * @param {VuexActionContext} actionContext
         * @param {object} callback
         * @param {?function} callback.method
         * @param {?object} callback.params
         */
        closeModal(
            { state, commit },
            callback = { method: null, params: null }
        ) {
            // Pop the current modal off the stack and
            // setup the next modal before executing the
            // callback so that if the callback pushes a modal
            // onto the stack it is not immediately popped off.
            commit("popModal");
            const next = _.last(state.modals);
            commit("setData", _.get(next, "data", null));
            if (callback && callback.method) {
                callback.method(callback.params);
            }
        },
        closeAll({state, dispatch}) {
            while (state.modals.length > 0) {
                dispatch('closeModal');
            }
        }
    },
    mutations: {
        /**
         * Set the active modal's initialization data to the given value.
         * @param {ModalState} state
         * @param {?object} data
         */
        setData(state, data = null) {
            state.data = data;
        },
        updateData(state, {key, data}){
            state.data[key] = data;
        },
        /**
         * Push a modal context onto the modals stack.
         *
         * @param {ModalState} state
         * @param {ModalContext} context
         */
        pushModal(state, { data, component, bindings, listeners }) {
            state.modals.push({ data, component, bindings, listeners });
        },
        /**
         * Pop a modal context off of the modals stack.
         *
         * @param {ModalState} state
         */
        popModal(state) {
            state.modals.pop();
        },
        /**
         * Stores the provided modal state data using the provided key.
         *
         * @param {ModalState} storeState
         * @param object modalState
         */
        setSavedState(storeState, { key, data }) {
            storeState.modalSaveStates.set(key, data);
        },
        /**
         * Deletes the saved modal state with the provided key.
         *
         * @param {ModalState} storeState
         * @param string key
         */
        deleteSavedState(storeState, key) {
            storeState.modalSaveStates.delete(key);
        },
        /**
         * Clears all saved modal states
         *
         * @param {ModalState} storeState
         */
        clearSavedStates(storeState) {
            storeState.modalSaveStates.clear();
        },
    },
    getters: {
        /**
         * Retrieves the stored modal state using the provided key.
         *
         * @param {ModalState} storeState
         */
        getSavedState(storeState) {
            return key => storeState.modalSaveStates.get(key);
        },
    }
};
