export default {
    mapper: {
        relations: {
            belongsTo: {
                document: {
                    foreignKey: 'document_id',
                    localField: 'document',
                },
            },
        },
    },
};
