// @ts-check
import { computed, ref, watch, nextTick, onBeforeUnmount } from '@vue/composition-api';
import store from 'store';

import useSavedOrders from './savedOrders';
import useDialogues from './dialogues';
import { secondsToMilliseconds } from 'helpers';

export default function useOrderFormSavedOrder() {
    const savedOrders = useSavedOrders();
    const dialogues = useDialogues();
    const currentOrder = computed(() => store.state.shared.orderFormOrder.order);

    const sessionExpiry = computed(() => {
        return store.state.shared.auth.expiry;
    });
    watch(sessionExpiry, (newValue) => {
        resetAutosaveTimeout(newValue);
    });

    let autosaveTimeoutId = ref(0);
    function startAutosaveTimer(logoutTimestamp) {
        if (!logoutTimestamp) {
            return;
        }

        const now = Math.floor(Date.now() / 1000);
        const saveBufferSeconds = 30;
        const autosaveTime = secondsToMilliseconds(logoutTimestamp - now - saveBufferSeconds);

        autosaveTimeoutId.value = window.setTimeout(async () => {
            await autosave();
        }, autosaveTime);
    }

    function stopAutosaveTimer() {
        if (autosaveTimeoutId.value) {
            window.clearTimeout(autosaveTimeoutId.value);
            autosaveTimeoutId.value = 0;
        }
    }

    function resetAutosaveTimeout(logoutTimestamp) {
        stopAutosaveTimer();
        startAutosaveTimer(logoutTimestamp);
    }

    let skipWatcher = ref(false);
    function setSkipWatcher(value) {
        skipWatcher.value = value;
    }

    function onDeleteSuccess(showNotification) {
        setSkipWatcher(true);
        savedOrders.setSavedOrderId(null);
        savedOrders.loadOrder({ id: null });
        if (showNotification) {
            dialogues.showSuccessNotification.call(this, 'Saved Orders deleted successfully.');
        }
    }

    async function onSaveOrder(showNotification = true) {
        let success = await savedOrders.saveOrder();
        if (success) {
            if (showNotification) {
                dialogues.showSuccessNotification.call(this, `Saved Order Successfully`);
            }
            // TODO: [JEF] Find a better spot for this.
            setSkipWatcher(true);
            savedOrders.setSavedOrderId(currentOrder.value.id);
            savedOrders.loadOrder({ id: currentOrder.value.id });
        } else {
            if (showNotification) {
                dialogues.showDangerNotification.call(this, `Saving Order Failed`);
            }
        }
    }

    async function onDeleteAllSavedOrders(showNotification = true) {
        let deleted = await savedOrders.deleteAllSavedOrders();

        if (deleted) {
            onDeleteSuccess.call(this, showNotification);
        }
    }

    async function onDeleteSelectedSavedOrder(showNotification = true) {
        let deleted = await savedOrders.deleteSavedOrder(savedOrders.savedOrderId.value);

        if (deleted) {
            onDeleteSuccess.call(this, showNotification);
        }
    }

    async function autosave() {
        if (currentOrder.value.isDirty) {
            await onSaveOrder(false);
        }
    }

    onBeforeUnmount(() => {
        stopAutosaveTimer();
    });

    watch(savedOrders.savedOrderId, async (newSavedOrderId, oldSavedOrderId) => {
        if (skipWatcher.value) {
            setSkipWatcher(false);
            return;
        }

        if (currentOrder.value.isDirty) {
            const answer = window.confirm(
                'Changes you have made may not be saved. Are you sure you want to leave this page?.'
            );
            if (!answer) {
                nextTick(() => {
                    setSkipWatcher(true);
                    savedOrders.setSavedOrderId(oldSavedOrderId);
                });
                return;
            }
        }

        savedOrders.setSavedOrderId(newSavedOrderId ?? null);
        savedOrders.loadOrder({ id: newSavedOrderId });
    });

    return {
        ...savedOrders,
        onSaveOrder,
        onDeleteAllSavedOrders,
        onDeleteSelectedSavedOrder,
        onDeleteSuccess,
        setSkipWatcher,
        startAutosaveTimer,
        stopAutosaveTimer,
    };
}
