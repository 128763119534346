<template>
    <div>
        <h4 class="title is-4">Large Order Notifications</h4>
        <div class="content">
            <div class="columns is-multiline">
                <emp-checkbox
                    fieldClass="column is-full"
                    label="Enable Notifications"
                    v-model="enabled"
                    :disabled="!isEditable"
                />
                <emp-textbox
                    label="Notification Amount"
                    fieldClass="column is-full"
                    v-model="amount"
                    icon="fas fa-dollar-sign"
                    :readonly="!isEditable"
                    @blur="formatAmount()"
                />
                <emp-email-list label="Notification Email Addresses" v-model="emails" :is-editable="isEditable" />
                <emp-tag
                    fieldClass="column is-full"
                    label="Notification Cell Numbers"
                    validate="digits"
                    :tags="cellNumbers"
                    v-model="cellNumbers"
                    :readonly="!isEditable"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { numberFormat } from 'helpers';

export default {
    props: {
        facility: {
            type: Object,
            required: true,
        },
        isEditable: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        amount: {
            get() {
                return this.facility.largeOrderNotificationAmount;
            },
            set(value) {
                this.facility.largeOrderNotificationAmount = value;
            },
        },
        enabled: {
            get() {
                return this.facility.largeOrderNotificationEnabled;
            },
            set(value) {
                this.facility.largeOrderNotificationEnabled = value;
            },
        },
        emails: {
            get() {
                return this.facility.largeOrderNotificationEmails;
            },
            set(value) {
                this.facility.largeOrderNotificationEmails = value;
            },
        },
        cellNumbers: {
            get() {
                return this.facility.largeOrderNotificationCellNumbers;
            },
            set(value) {
                this.facility.largeOrderNotificationCellNumbers = value;
            },
        },
    },
    methods: {
        formatAmount() {
            this.amount = numberFormat(this.amount);
        },
    },
};
</script>
