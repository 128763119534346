import { auditsRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            belongsTo: {
                document: {
                    foreignKey: 'document_id',
                    localField: 'document',
                },
                extractionInstruction: {
                    foreignKey: 'extraction_instruction_id',
                    localField: 'extractionInstruction',
                },
            },
            hasOneThrough: {
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
            },
            morphMany: {
                ...auditsRelation('extraction'),
            },
        },
    },
};
