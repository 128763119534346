<template>
    <div>
        <div class="message-body-content">
            <div class="columns is-multiline">
                <emp-select-role label="Role" v-model="roleId" :type="type" :ignored="ignored" />
            </div>
        </div>
        <div class="message-body-buttons">
            <div class="level">
                <div class="level-left"></div>
                <div class="level-right">
                    <div class="level-item">
                        <emp-button @click="onSave" buttonClass="is-primary" label="Save" />
                    </div>
                    <div class="level-item">
                        <emp-confirmation-button @click="onClose" buttonClass="is-danger" label="Cancel" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from 'event-bus';
import moment from 'moment';
import _ from 'lodash';

export default {
    data() {
        return {
            roleId: {},
            ignored: [],
            type: 'facility',
        };
    },
    methods: {
        clear() {
            this.roleId = {};
        },
        onClose() {
            EventBus.$emit('user-role-form:onClose');
            this.clear();
        },
        onSave() {
            let role = _.find(this.roles, { id: parseInt(this.roleId) });
            EventBus.$emit('user-role-form:onAdd', role);
            this.onClose();
        },
        getRoles() {
            this.$store.dispatch('shared/roles/getDropdownData', { type: this.type });
        },
        onSetData(data) {
            this.ignored = data.ignored;
            this.type = data.type;
            this.getRoles();
        },
    },
    computed: {
        roles() {
            return this.$store.state.shared.roles.dropdownData;
        },
    },
    created() {
        EventBus.$on('user-role-form:onSetData', this.onSetData);
    },
    destroyed() {
        EventBus.$off('user-role-form:onSetData');
    },
};
</script>
