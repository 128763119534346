import facilities from './facilities';
import invoices from './invoices';
import creditMemos from './creditMemos';
import queue from './queue';
import purchaseOrders from './purchaseOrders';
import shippingMethods from './shippingMethods';
import systemSettings from './systemSettings';
import bills from './bills';
import credits from './credits';
import shippingEstimates from './shippingEstimates';
import shippingServices from './shippingServices';
import ordersReview from './ordersReview';
import ordersManage from './ordersManage';
import ediDocuments from './ediDocuments';
import trackingNumberImportResults from './tracking-number-import-results';
import textTransformationLanguage from './textTransformationLanguage';
import extractables from './extractables';
import extractionInstructions from './extractionInstructions';
import extractions from './extractions';
import tradingPartners from './tradingPartners';
import uploadedFiles from './uploadedFiles';
import documentProcessors from './documentProcessors';
import linePresence from './linePresence';

export default {
    bills,
    creditMemos,
    credits,
    documentProcessors,
    facilities,
    invoices,
    purchaseOrders,
    queue,
    shippingMethods,
    systemSettings,
    shippingEstimates,
    shippingServices,
    ordersReview,
    ordersManage,
    ediDocuments,
    trackingNumberImportResults,
    tradingPartners,
    textTransformationLanguage,
    extractables,
    extractionInstructions,
    extractions,
    uploadedFiles,
    linePresence,
};
