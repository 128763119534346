import { auditsRelation, notesRelation } from 'app/jsd/helpers';
export default {
    mapper: {
        relations: {
            belongsTo: {
                line: {
                    foreignKey: 'line_id',
                    localField: 'line',
                },
            },
            morphMany: {
                ...notesRelation('serialNumber'),
            },
        },
    },
};
