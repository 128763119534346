<template>
    <emp-select
        :disabled="noFilteredCategories || disabled"
        :inputClass="{ 'is-loading': noFilteredCategories }"
        :label="label"
        :options="filteredCategories"
        :nameFrom="nameFrom"
        valueFrom="id"
        :value="value"
        @input="$emit('input', $event)"
        v-bind="$attrs"
    />
</template>

<script>
import useCategories from 'use/categories';
import _ from 'lodash';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    inheritAttrs: false,
    setup(props) {
        /**
         * @type {import('@vue/composition-api').Ref<Category[]>}
         */
        const { categories } = useCategories();

        const filteredCategories = computed(() => {
            if (!_.isArray(categories.value)) {
                return [];
            } else if (props.categoryType === 'all') {
                return categories.value;
            } else if (props.categoryType === 'parents') {
                let parentCategoryIds = _.uniq(categories.value.map((category) => category.parent_id));
                return categories.value.filter((category) => parentCategoryIds.includes(category.id));
            } else {
                return categories?.value?.filter((category) => _.get(category, `for_${props.categoryType}`, false));
            }
        });

        const noFilteredCategories = computed(() => {
            return !filteredCategories.value?.length;
        });

        return {
            filteredCategories,
            noFilteredCategories,
        };
    },

    props: {
        label: {
            type: String,
            default: 'Category',
        },
        categoryType: {
            //all, prostheticsAndOrthotics, vendors, orders, parents, billing
            type: String,
            default: 'all',
        },
        nameFrom: {
            type: String,
            default: 'full_name',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number, Array, Object, Boolean],
            default: null,
        },
    },
});
</script>
