<template>
    <option
        v-if="option[valueFrom] !== null"
        :data-debug-key="`option_${componentId}_${option[valueFrom]}`"
        :key="`option_${componentId}_${option[valueFrom]}`"
        :value="option[valueFrom]"
        :disabled="option.isDisabled"
    >
        {{ name }}
    </option>
    <option v-else :key="option[valueFrom]" :value="option[valueFrom]" disabled>{{ name || '' }}</option>
</template>

<script>
export default {
    props: {
        option: {
            type: Object,
            required: true,
        },
        valueFrom: {
            type: String,
            required: true,
        },
        componentId: {
            type: String,
            required: true,
        },
        nameCallback: {
            type: [String, Function],
            default: null,
        },
        nameFrom: {
            type: String,
            default: 'name',
        },
    },
    computed: {
        name() {
            if (typeof this.nameCallback === 'function') {
                return this.nameCallback(this.option);
            }

            if (typeof this.$parent.$parent[this.nameCallback] == 'function') {
                return this.$parent.$parent[this.nameCallback].call(this.$parent.$parent, this.option);
            }

            return this.option[this.nameFrom];
        },
    },
};
</script>

<style></style>
