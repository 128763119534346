import * as actionTypes from "./action_types";
import SmartApi from "SmartApi";
import _ from "lodash";

export default {
    async getData({ dispatch }) {
        await dispatch(actionTypes.GET_EXTRACTIONS);
    },
    [actionTypes.GET_EXTRACTIONS]: async ({ state, commit }, params = {}) => {
        params = _.merge(
            {},
            state.filters,
            state.pagination,
            { sort: state.sort },
            params
        );
        const request = {
            routeName: "listExtractions",
            config: { params }
        };
        const response = await SmartApi.get(request);
        commit("setData", response.data.data);
        commit("setPaginationData", response.data.meta);
    }
};
