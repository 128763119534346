import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = params.id ? route('facilities.show', { id: params.id }) : route('facilities');
        return await axios.get(url, { params });
    },
    async update(payload) {
        let url = route('facilities.edit', { id: payload.id });
        let result = await axios.put(url, payload);
        return result.data.data;
    },
    async dropdownData(params = {}) {
        let url = route('facilities.dropdowns');
        return await axios.get(url, { params });
    },
    //TODO: clean
    async inventoryManufacturers(params = {}) {
        let url = route('facilities.inventory.manufacturers', { id: params.id });
        return await axios.get(url, { params });
    },
    //TODO: clean
    async inventoryAddresses(params = {}) {
        let url = route('facilities.inventory.address', { id: params.id });
        return await axios.get(url, { params });
    },
    manufacturers: {
        async getLCodeSearchWhitelist(facilityId) {
            const url = route('facilities.manufacturers.lcode-search-whitelist', [facilityId]);
            const response = await axios.get(url);
            return response.data.data;
        },
    },
};
