import _ from 'lodash';
import * as actions from 'store/actions';
import { Statuses } from 'enums';
import { TABLE_STORE } from 'store/shared/mixins';
import Vue from 'vue';
import { mixVuexStoreOptions } from 'store/helpers.js';
import jsd from 'app/jsd';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        dropdownData: [],
        inRequest: false,
        default_sort: ['name'],
        facilityToAddresses: {},
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
        },
        clearFacilityToAddresses(store) {
            store.facilityToAddresses = {};
        },
        setFacilityAddresses(store, payload) {
            Vue.set(store.facilityToAddresses, payload.facilityId, payload);
        },
    },
    getters: {
        addressFromCode: (state) => (code) => {
            return state.dropdownData.find((address) => address.code == code);
        },
        addressesByFacility: (state) => (facilityId) => {
            return state.dropdownData.filter((address) => address.facility_id == facilityId);
        },
        costCenters: (state) => state.dropdownData.filter((address) => address.is_cost_center),
        shippingAddresses: (state) => state.dropdownData.filter((address) => address.is_ship_to),
        shippingAddressesByFacility: (state, getters) => (facilityId) => {
            return getters.addressesByFacility(facilityId).filter((address) => {
                return address.is_ship_to == true;
            });
        },
        costCentersByFacility: (state, getters) => (facilityId) => {
            return getters.addressesByFacility(facilityId).filter((address) => {
                return address.is_cost_center == true;
            });
        },
        activeCostCentersByFacility(state, getters) {
            return (facilityId) => {
                return getters.costCentersByFacility(facilityId).filter((address) => {
                    return address.status_id === Statuses.ACTIVE;
                });
            };
        },
        inactiveCostCentersByFacility(state, getters) {
            return (facilityId) => {
                return getters.costCentersByFacility(facilityId).filter((address) => {
                    return address.status_id === Statuses.INACTIVE;
                });
            };
        },
        activeShippingAddressesByFacility(state, getters) {
            return (facilityId) => {
                return getters.shippingAddressesByFacility(facilityId).filter((address) => {
                    return address.status_id === Statuses.ACTIVE;
                });
            };
        },
        inactiveShippingAddressesByFacility(state, getters) {
            return (facilityId) => {
                return getters.shippingAddressesByFacility(facilityId).filter((address) => {
                    return address.status_id === Statuses.INACTIVE;
                });
            };
        },
        empireInventoryAddress(state, getters) {
            return getters.addressFromName('Empire Inventory');
        },
        activeAccountingCostCentersFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.activeAccountingCostCenters`, []);
            };
        },
        activeCostCentersFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.activeCostCenters`, []);
            };
        },
        inactiveCostCentersFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.inactiveCostCenters`, []);
            };
        },
        activeShippingAddressesFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.activeShippingAddresses`, []);
            };
        },
        inactiveShippingAddressesFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.inactiveShippingAddresses`, []);
            };
        },
        getAddressName(state, getters) {
            return (addressId) => {
                return _.get(getters.addressFromId(addressId), 'name', null);
            };
        },
        addressFromId(state) {
            return (addressId) => {
                let addresses = [];
                // TODO: Pick one and make sure it's populated.
                if (state.dropdownData.length) {
                    addresses = state.dropdownData;
                } else if (state.data.length) {
                    addresses = state.data;
                }
                //TODO: Make this more efficient.
                return addresses.find((address) => address.id == addressId);
            };
        },
        addressFromName(state) {
            return (name) => {
                return state.dropdownData.find((address) => address.name === name);
            };
        },
        //TODO: This will need to be removed or refactored before the address revamp
        //is finished as addresses will not be stored with vendors
        addressFromIdVendorEntry(state, getters) {
            return (addressId, vendorId) => {
                console.error('Function Depricated');
            };
        },
    },
    actions: {
        getData({ dispatch }) {
            dispatch(actions.GET_ADDRESSES);
        },
        async [actions.GET_ADDRESSES]({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);
            try {
                const response = await jsd.findAll('address', params, { raw: true });
                commit('setData', response.data);
                commit('setPaginationData', response.metadata);
            } catch (e) {
                console.log(e);
            }
        },
        async getDropdownData({ state, commit }, params = {}) {
            if (params.hasOwnProperty('sort')) {
                params.sort.push('name');
            } else {
                params['sort'] = ['name'];
            }

            if (!params.hasOwnProperty('per_page')) {
                params.per_page = -1;
            }

            const addresses = await jsd.findAll('address', params, { force: true });

            commit('setDropdownData', addresses);
            commit('clearFacilityToAddresses');
        },
        mapFacilityAddresses({ state, commit, getters }, payload) {
            const { facilityId } = payload;
            if (!state.facilityToAddresses[facilityId]) {
                const activeCostCenters = getters.activeCostCentersByFacility(facilityId);
                const empireInventory = getters.empireInventoryAddress;
                let activeAccountingCostCenters = [...activeCostCenters];
                if (!activeCostCenters.includes(empireInventory)) {
                    activeAccountingCostCenters = [...activeAccountingCostCenters, empireInventory];
                }
                activeAccountingCostCenters = _.sortBy(activeAccountingCostCenters, ['name']);
                const inactiveCostCenters = getters.inactiveCostCentersByFacility(facilityId);
                const activeShippingAddresses = getters.activeShippingAddressesByFacility(facilityId);
                const inactiveShippingAddresses = getters.inactiveShippingAddressesByFacility(facilityId);
                commit('setFacilityAddresses', {
                    facilityId,
                    activeCostCenters,
                    activeAccountingCostCenters,
                    inactiveCostCenters,
                    activeShippingAddresses,
                    inactiveShippingAddresses,
                });
            }
        },
        async updateLinesStatuses({ state, getters }, { lineIds, statusId }) {
            if (lineIds instanceof Array === false) {
                lineIds = [lineIds];
            }
            const updates = lineIds.map((addressId) => ({ id: addressId, status_id: statusId }));
            const addresses = await jsd.updateMany('address', updates);
            return addresses;
        },
        async deleteLines({ getters }, { lineIds }) {
            if (lineIds instanceof Array === false) {
                lineIds = [lineIds];
            }
            const requests = lineIds.map((id) => {
                return jsd.destroy('address', id);
            });
            return await Promise.all(requests);
        },
    },
    modules: {
        vendors: mixVuexStoreOptions({}, TABLE_STORE, {
            namespaced: true,
            state: {
                pagination: {
                    per_page: null,
                },
            },
            actions: {
                get({ state, commit }, params = {}) {
                    params = _.merge({}, state.filters, state.pagination, params);

                    AddressApi.vendors.get(params).then((response) => {
                        commit('setData', response.data.data);
                    });
                },
            },
        }),
    },
});
