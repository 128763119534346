import _ from 'lodash';
import AddressApi from 'api/endpoints/addresses';
import SmartApi from 'SmartApi';
import * as actions from 'store/actions';
import { Statuses } from 'enums';
import { TABLE_STORE } from 'store/shared/mixins';
import Vue from 'vue';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        dropdownData: [],
        inRequest: false,
        default_sort: ['name'],
        facilityToAddresses: {},
        moduleName: 'Addresses',
        lastRequestedDropdownParams: null,
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
        },
        clearFacilityToAddresses(store) {
            store.facilityToAddresses = {};
        },
        setFacilityAddresses(store, payload) {
            Vue.set(store.facilityToAddresses, payload.facilityId, payload);
        },
        pushRequestedDropdownParams(store, payload) {
            store.lastRequestedDropdownParams = { ...payload };
        },
    },
    getters: {
        api() {
            return AddressApi;
        },
        addressFromCode: (state) => (code) => {
            return state.dropdownData.find((address) => address.code == code);
        },
        addressesByFacility: (state) => (facilityId) => {
            return state.dropdownData.filter((address) => address.facilityId == facilityId);
        },
        costCenters: (state) => state.dropdownData.filter((address) => address.isCostCenter),
        shippingAddresses: (state) => state.dropdownData.filter((address) => address.isShipTo),
        shippingAddressesByFacility: (state, getters) => (facilityId) => {
            return getters.addressesByFacility(facilityId).filter((address) => {
                return address.isShipTo == true;
            });
        },
        costCentersByFacility: (state, getters) => (facilityId) => {
            return getters.addressesByFacility(facilityId).filter((address) => {
                return address.isCostCenter == true;
            });
        },
        activeCostCentersByFacility(state, getters) {
            return (facilityId) => {
                return getters.costCentersByFacility(facilityId).filter((address) => {
                    return address.statusId === Statuses.ACTIVE;
                });
            };
        },
        inactiveCostCentersByFacility(state, getters) {
            return (facilityId) => {
                return getters.costCentersByFacility(facilityId).filter((address) => {
                    return address.statusId === Statuses.INACTIVE;
                });
            };
        },
        activeShippingAddressesByFacility(state, getters) {
            return (facilityId) => {
                return getters.shippingAddressesByFacility(facilityId).filter((address) => {
                    return address.statusId === Statuses.ACTIVE;
                });
            };
        },
        inactiveShippingAddressesByFacility(state, getters) {
            return (facilityId) => {
                return getters.shippingAddressesByFacility(facilityId).filter((address) => {
                    return address.statusId === Statuses.INACTIVE;
                });
            };
        },
        empireInventoryAddress(state, getters) {
            return getters.addressFromName('Empire Inventory');
        },
        activeAccountingCostCentersFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.activeAccountingCostCenters`, []);
            };
        },
        activeCostCentersFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.activeCostCenters`, []);
            };
        },
        inactiveCostCentersFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.inactiveCostCenters`, []);
            };
        },
        activeShippingAddressesFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.activeShippingAddresses`, []);
            };
        },
        inactiveShippingAddressesFromMap(state, getters) {
            return (facilityId) => {
                return _.get(state.facilityToAddresses, `${facilityId}.inactiveShippingAddresses`, []);
            };
        },
        getAddressName(state, getters) {
            return (addressId) => {
                return _.get(getters.addressFromId(addressId), 'name', null);
            };
        },
        addressFromId(state) {
            return (addressId) => {
                return state.dropdownData.find((address) => address.id == addressId);
            };
        },
        addressFromName(state) {
            return (name) => {
                return state.dropdownData.find((address) => address.name === name);
            };
        },
        addressFromIdVendorEntry(state, getters) {
            return (addressId, vendorId) => {
                let addressFromId = getters.addressFromId(addressId),
                    vendorEntry;

                if (addressFromId) {
                    vendorEntry = addressFromId.vendors.find((vendor) => vendor.id == vendorId);
                }

                return vendorEntry;
            };
        },
    },
    actions: {
        getData({ dispatch }) {
            dispatch(actions.GET_ADDRESSES);
        },
        async [actions.GET_ADDRESSES]({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);

            const request = {
                routeName: actions.GET_ADDRESSES,
                config: { params },
            };

            const response = await SmartApi.get(request);
            commit('setData', response.data.data);
            commit('setPaginationData', response.data.meta.pagination);
        },
        async getDropdownData({ state, commit, dispatch }, params = {}) {
            if (params.hasOwnProperty('sort')) {
                params.sort.push('name');
            } else {
                params['sort'] = ['name'];
            }

            if (_.isEqual({ ...state.lastRequestedDropdownParams }, params)) {
                return;
            }
            commit('pushRequestedDropdownParams', { ...params });

            let response = await AddressApi.dropdownData(params);
            commit('setDropdownData', response.data.data);
            // Determine the previous entries in the facilityToAddress map
            // so that they can be re-mapped after the map has been cleared.
            // This is done primarily to paper over some order of operations
            // issues that appear when testing components that fetch
            // addresses and have children that access addresses via the map.
            // E.g. BillsForm fetches addresses and its child, `AccountingSelectCostCenter`,
            // uses the addresses in the map to populate its `select`'s `option`s.
            //
            // The presumed benefit of lazy mapping should be re-evaluated. It may
            // be worth adding all Addresses and Facilitys to the map every time the
            // list of Addresses is fetched. It would certainly be simpler.
            const mappedFacilityIds = Object.keys(state.facilityToAddresses);
            commit('clearFacilityToAddresses');
            mappedFacilityIds.forEach((facilityId) => dispatch('mapFacilityAddresses', { facilityId }));
        },
        mapFacilityAddresses({ state, commit, getters }, payload) {
            const { facilityId } = payload;
            if (!state.facilityToAddresses[facilityId]) {
                const activeCostCenters = getters.activeCostCentersByFacility(facilityId);
                const empireInventory = getters.empireInventoryAddress;
                let activeAccountingCostCenters = [...activeCostCenters];
                // empireInventory should not be falsy, but can be in test environments
                // It's a little easier to check for its existence here than it is to ensure
                // it's available in all test environments.
                if (empireInventory && !activeCostCenters.includes(empireInventory)) {
                    activeAccountingCostCenters = [...activeAccountingCostCenters, empireInventory];
                }
                activeAccountingCostCenters = _.sortBy(activeAccountingCostCenters, ['name']);
                const inactiveCostCenters = getters.inactiveCostCentersByFacility(facilityId);
                const activeShippingAddresses = getters.activeShippingAddressesByFacility(facilityId);
                const inactiveShippingAddresses = getters.inactiveShippingAddressesByFacility(facilityId);
                commit('setFacilityAddresses', {
                    facilityId,
                    activeCostCenters,
                    activeAccountingCostCenters,
                    inactiveCostCenters,
                    activeShippingAddresses,
                    inactiveShippingAddresses,
                });
            }
        },
    },
    modules: {
        vendors: mixVuexStoreOptions({}, TABLE_STORE, {
            namespaced: true,
            state: {
                pagination: {
                    per_page: null,
                },
            },
            actions: {
                get({ state, commit }, params = {}) {
                    params = _.merge({}, state.filters, state.pagination, params);

                    AddressApi.vendors.get(params).then((response) => {
                        commit('setData', response.data.data);
                    });
                },
            },
        }),
    },
});
