import addressConfig from './configs/address';
import addressVendorConfig from './configs/addressVendor';
import adminProductConfig from './configs/adminProduct';
import adminVendorConfig from './configs/adminVendor';
import facilityProductConfig from './configs/facilityProduct';
import facilityVendorConfig from './configs/facilityVendor';
import invoiceConfig from './configs/invoice';
import noteConfig from './configs/note';
import addressShippingMethodConfig from './configs/addressShippingMethod';
import addressShippingServiceConfig from './configs/addressShippingService';
import auditConfig from './configs/audit';
import billConfig from './configs/bill';
import billLineConfig from './configs/billLine';
import bugReportConfig from './configs/bugReport';
import categoryConfig from './configs/category';
import creditConfig from './configs/credit';
import creditLineConfig from './configs/creditLine';
import creditMemoConfig from './configs/creditMemo';
import documentConfig from './configs/document';
import documentProcessingEventConfig from './configs/documentProcessingEvent';
import documentProcessingRequestConfig from './configs/documentProcessingRequest';
import documentProcessorConfig from './configs/documentProcessor';
import documentableConfig from './configs/documentable';
import dropdownConfig from './configs/dropdown';
import emailConfig from './configs/email';
import emailAccountConfig from './configs/emailAccount';
import emailAttachmentConfig from './configs/emailAttachment';
import emailProcessingRuleConfig from './configs/emailProcessingRule';
import environmentDataConfig from './configs/environmentData';
import extractionConfig from './configs/extraction';
import extractionInstructionConfig from './configs/extractionInstruction';
import facilityConfig from './configs/facility';
import facilityManufacturerConfig from './configs/facilityManufacturer';
import facilityOAuthPartnerConfig from './configs/facilityOAuthPartner';
import generalShippingLabelConfig from './configs/generalShippingLabel';
import importConfig from './configs/import';
import inventoryConfig from './configs/inventory';
import inventoryLocationConfig from './configs/inventoryLocation';
import inventoryRedemptionConfig from './configs/inventoryRedemption';
import invitationConfig from './configs/invitation';
import invoiceLineConfig from './configs/invoiceLine';
import invoicePurchaseOrderConfig from './configs/invoicePurchaseOrder';
import lCodeConfig from './configs/lCode';
import lCodeProductConfig from './configs/lCodeProduct';
import lCodeSearchConfig from './configs/lCodeSearch';
import versionedOrderLineConfig from './configs/versionedOrderLine';
import linePatientConfig from './configs/linePatient';
import lineShippingLabelConfig from './configs/lineShippingLabel';
import lineStatusTransitionConfig from './configs/lineStatusTransition';
import lineTrackingNumberConfig from './configs/lineTrackingNumber';
import lineViewConfig from './configs/lineView';
import messageConfig from './configs/message';
import notificationConfig from './configs/notification';
import oAuthClientConfig from './configs/oAuthClient';
import oAuthPartnerConfig from './configs/oAuthPartner';
import orderConfig from './configs/order';
import patientNameConfig from './configs/patientName';
import pdfTextractorResponseConfig from './configs/pdfTextractorResponse';
import persistentProcessableFileConfig from './configs/persistentProcessableFile';
import prewrittenNoteConfig from './configs/prewrittenNote';
import priceExceptionConfig from './configs/priceException';
import processableModelConfig from './configs/processableModel';
import productStateConfig from './configs/productState';
import productUserConfig from './configs/productUser';
import productViewConfig from './configs/productView';
import purchaseOrderConfig from './configs/purchaseOrder';
import relationshipConfig from './configs/relationship';
import reminderConfig from './configs/reminder';
import representativeConfig from './configs/representative';
import returnAddressConfig from './configs/returnAddress';
import returnLabelConfig from './configs/returnLabel';
import returnRequestConfig from './configs/returnRequest';
import rewardsTierConfig from './configs/rewardsTier';
import savedOrderConfig from './configs/savedOrder';
import serialNumberConfig from './configs/serialNumber';
import shippingLabelConfig from './configs/shippingLabel';
import shippingMethodConfig from './configs/shippingMethod';
import shippingServiceConfig from './configs/shippingService';
import shoppingListConfig from './configs/shoppingList';
import shoppingListLineConfig from './configs/shoppingListLine';
import trackingNumberConfig from './configs/trackingNumber';
import trackingNumberImportResultConfig from './configs/trackingNumberImportResult';
import trackingNumberImportResultCollectionConfig from './configs/trackingNumberImportResultCollection';
import uploadedFileConfig from './configs/uploadedFile';
import userConfig from './configs/user';
import userNotificationPreferenceConfig from './configs/userNotificationPreference';
import userPermissionConfig from './configs/userPermission';
import userPractitionerConfig from './configs/userPractitioner';
import userPreferenceConfig from './configs/userPreference';
import vendorFieldConfig from './configs/vendorField';
import vendorUpsDataConfig from './configs/vendorUpsData';
import warehouseConfig from './configs/warehouse';

export default {
    address: addressConfig,
    addressVendor: addressVendorConfig,
    adminProduct: adminProductConfig,
    adminVendor: adminVendorConfig,
    facilityProduct: facilityProductConfig,
    facilityVendor: facilityVendorConfig,
    invoice: invoiceConfig,
    note: noteConfig,
    addressShippingMethod: addressShippingMethodConfig,
    addressShippingService: addressShippingServiceConfig,
    audit: auditConfig,
    bill: billConfig,
    billLine: billLineConfig,
    bugReport: bugReportConfig,
    category: categoryConfig,
    credit: creditConfig,
    creditLine: creditLineConfig,
    creditMemo: creditMemoConfig,
    document: documentConfig,
    documentProcessingEvent: documentProcessingEventConfig,
    documentProcessingRequest: documentProcessingRequestConfig,
    documentProcessor: documentProcessorConfig,
    documentable: documentableConfig,
    dropdown: dropdownConfig,
    email: emailConfig,
    emailAccount: emailAccountConfig,
    emailAttachment: emailAttachmentConfig,
    emailProcessingRule: emailProcessingRuleConfig,
    environmentData: environmentDataConfig,
    extraction: extractionConfig,
    extractionInstruction: extractionInstructionConfig,
    facility: facilityConfig,
    facilityManufacturer: facilityManufacturerConfig,
    facilityOAuthPartner: facilityOAuthPartnerConfig,
    generalShippingLabel: generalShippingLabelConfig,
    import: importConfig,
    inventory: inventoryConfig,
    inventoryLocation: inventoryLocationConfig,
    inventoryRedemption: inventoryRedemptionConfig,
    invitation: invitationConfig,
    invoiceLine: invoiceLineConfig,
    invoicePurchaseOrder: invoicePurchaseOrderConfig,
    lCode: lCodeConfig,
    lCodeProduct: lCodeProductConfig,
    lCodeSearch: lCodeSearchConfig,
    versionedOrderLine: versionedOrderLineConfig,
    linePatient: linePatientConfig,
    lineShippingLabel: lineShippingLabelConfig,
    lineStatusTransition: lineStatusTransitionConfig,
    lineTrackingNumber: lineTrackingNumberConfig,
    lineView: lineViewConfig,
    message: messageConfig,
    notification: notificationConfig,
    oAuthClient: oAuthClientConfig,
    oAuthPartner: oAuthPartnerConfig,
    order: orderConfig,
    patientName: patientNameConfig,
    pdfTextractorResponse: pdfTextractorResponseConfig,
    persistentProcessableFile: persistentProcessableFileConfig,
    prewrittenNote: prewrittenNoteConfig,
    priceException: priceExceptionConfig,
    processableModel: processableModelConfig,
    productState: productStateConfig,
    productUser: productUserConfig,
    productView: productViewConfig,
    purchaseOrder: purchaseOrderConfig,
    relationship: relationshipConfig,
    reminder: reminderConfig,
    representative: representativeConfig,
    returnAddress: returnAddressConfig,
    returnLabel: returnLabelConfig,
    returnRequest: returnRequestConfig,
    rewardsTier: rewardsTierConfig,
    savedOrder: savedOrderConfig,
    serialNumber: serialNumberConfig,
    shippingLabel: shippingLabelConfig,
    shippingMethod: shippingMethodConfig,
    shippingService: shippingServiceConfig,
    shoppingList: shoppingListConfig,
    shoppingListLine: shoppingListLineConfig,
    trackingNumber: trackingNumberConfig,
    trackingNumberImportResult: trackingNumberImportResultConfig,
    trackingNumberImportResultCollection: trackingNumberImportResultCollectionConfig,
    uploadedFile: uploadedFileConfig,
    user: userConfig,
    userNotificationPreference: userNotificationPreferenceConfig,
    userPermission: userPermissionConfig,
    userPractitioner: userPractitionerConfig,
    userPreference: userPreferenceConfig,
    vendorField: vendorFieldConfig,
    vendorUpsData: vendorUpsDataConfig,
    warehouse: warehouseConfig,
};
