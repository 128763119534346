const TOAST_DEFAULTS = {
    content: null,
    isSuccess: false,
    isDanger: false,
    errors: [],
    actionButton: null,
    duration: 5000
};

export default {
    namespaced: true,
    state: {
        isActive: false,
        isSuccess: true,
        isDanger: false,
        isInfo: false,
        content: '',
        errors: [],
        actionButton: null,
        persistant: false,
        duration: 5000,
    },
    mutations: {
        setToastState(state, payload) {
            state.isActive  = true;
            state.content   = payload.content;
            state.isSuccess = payload.isSuccess;
            state.isDanger  = payload.isDanger;
            state.isInfo = payload.isInfo;
            state.errors = payload.errors
            state.actionButton = payload.actionButton
            state.persistant = payload.persistant;
            state.duration = payload.duration;
        },
        clearToastState(state) {
            state.isActive = false;

            Object.keys(TOAST_DEFAULTS).forEach((key) => {
                state[key] = TOAST_DEFAULTS[key];
            });
        }
    },
    actions: {
        show({state, commit}, payload) {
            let completePayload = Object.assign({}, TOAST_DEFAULTS, payload);
            commit('setToastState', completePayload);

            if (!state.persistant) {
                setTimeout(function () {
                    commit('clearToastState');
                }, state.duration);
            }
        },
        close({commit}) {
            commit('clearToastState');
        }
    }
};
