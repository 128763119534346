import * as types from "store/shared/mutation_types";

export default {
    [types.SET_REMINDERS](state, reminders) {
        state.reminders = reminders;
    },
    [types.ADD_REMINDER](state, reminder) {
        state.reminders.push(reminder);
    },
    [types.REMOVE_REMINDER](state, index) {
        state.reminders = [
            ...state.reminders.slice(0, index),
            ...state.reminders.slice(index + 1)
        ];
    },
}
