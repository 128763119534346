<template>
    <div class="emp-datepicker" :class="fieldClass" data-testid="datepicker">
        <label v-if="label" class="label" :class="labelClass" :for="`${name}_${_uid}`">
            {{ label }}
        </label>
        <flat-pickr
            :value="date"
            :class="['input', inputClass, { 'is-danger': hasError }]"
            :config="finalConfig"
            :disabled="this.disabled"
            data-lpignore="true"
            :id="`${name}_${_uid}`"
            :name="`flatpickr_${name}_${_uid}`"
            v-bind="$attrs"
            autocomplete="off"
        ></flat-pickr>
        <template v-if="hasErrorArray">
            <p class="help is-danger" v-for="(error, index) in errors[name]" :key="index">{{ error }}</p>
        </template>
        <template v-else-if="hasError">
            <p class="help is-danger">{{ errors[name] }}</p>
        </template>
        <template v-if="internalError">
            <p class="help is-danger">{{ internalError }}</p>
        </template>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import { formatters, hasErrors } from 'component/shared/elements/mixins';

export default {
    inheritAttrs: false,
    data() {
        return {
            finalConfigDate: _.merge({}, this.config, {
                dateFormat: 'm/d/Y',
                enableTime: false,
                onClose: this.onClose,
                onOpen: this.onOpen,
                allowInput: true,
                parseDate: this.parseDate,
            }),
            finalConfigTime: _.merge({}, this.config, {
                dateFormat: 'm/d/Y h:iK',
                onClose: this.onClose,
                onOpen: this.onOpen,
                onChange: this.onChange,
                onReady: this.onReady,
            }),
            selectedDate: null,
            tempInput: null,
            internalError: null,
        };
    },
    mixins: [hasErrors],
    methods: {
        parseDate(date, format) {
            var parts = date.split(/\W+/);
            var against = format.split(/\W+/),
                d,
                m,
                y,
                h,
                min,
                now = new Date();
            for (var i = 0; i < parts.length; i++) {
                switch (against[i]) {
                    case 'd':
                    case 'e':
                        d = parseInt(parts[i], 10);
                        break;
                    case 'm':
                        m = parseInt(parts[i], 10) - 1;
                        break;
                    case 'Y':
                    case 'y':
                        y = parseInt(parts[i], 10);
                        y += y > 100 ? 0 : y < 29 ? 2000 : 1900;
                        break;
                    case 'H':
                    case 'I':
                    case 'k':
                    case 'l':
                        h = parseInt(parts[i], 10);
                        break;
                    case 'P':
                    case 'p':
                        if (/pm/i.test(parts[i]) && h < 12) {
                            h += 12;
                        } else if (/am/i.test(parts[i]) && h >= 12) {
                            h -= 12;
                        }
                        break;
                    case 'M':
                        min = parseInt(parts[i], 10);
                        break;
                }
            }
            return new Date(
                y === undefined ? now.getFullYear() : y,
                m === undefined ? now.getMonth() : m,
                d === undefined ? now.getDate() : d,
                h === undefined ? now.getHours() : h,
                min === undefined ? now.getMinutes() : min,
                0
            );
        },
        onOpen() {
            document.addEventListener('paste', this.getClipboardContent);
            document.addEventListener('copy', this.copyToClipboard);
        },
        onClose(selectedDates, dateStr, instance) {
            document.removeEventListener('paste', this.getClipboardContent);
            document.removeEventListener('copy', this.copyToClipboard);

            this.setDate(dateStr);
        },
        getClipboardContent(e) {
            let types = e.clipboardData.types;
            if (!Array.isArray(types)) {
                types = Object.values(types);
            }
            if (types.indexOf('text/plain') > -1) {
                let data = e.clipboardData.getData('text/plain');
                this.setDate(data);
                e.preventDefault();
            }
        },
        copyToClipboard(e) {
            let isNotNull = !_.isNull(this.date);
            if (isNotNull) {
                e.clipboardData.setData('text/plain', this.date);
                e.preventDefault();
            }
        },
        onChange(selectedDates, dateStr, instance) {
            this.setDate(dateStr);
        },
        showInternalError(message) {
            this.internalError = message;
            setTimeout(() => {
                this.internalError = null;
            }, 3000);
        },
        setDate(datetime) {
            let output = null;

            if (datetime) {
                let format = this.momentFormat;
                output = this.toDateObject(datetime, format);

                if (this.finalConfig.minDate) {
                    let selectedDate = moment(datetime, format);
                    let minDate = null;
                    if (this.finalConfig.minDate == 'today') {
                        minDate = moment();
                    } else {
                        minDate = moment(this.finalConfig.minDate);
                    }

                    let granularity = this.finalConfig.enableTime ? 'minute' : 'day';
                    if (minDate.isAfter(selectedDate, granularity)) {
                        this.showInternalError('Selected date is smaller then allowed min date');
                    }
                }
            }

            if (this.outputFormat) {
                output = this.fromDateObject(output, this.outputFormat);
            }

            this.$emit('input', output);
        },
    },
    computed: {
        momentFormat() {
            // Flatpickr => Moment
            // moment does not have a direct translation for flatpickrs Z
            const map = {
                D: 'ddd',
                d: 'DD',
                l: 'dddd',
                M: 'MMM', // This can't ever move.
                j: 'M',
                J: 'Mo',
                w: 'd',
                F: 'MMMM',
                m: 'MM',
                n: 'M',
                U: 'X',
                y: 'YY',
                Y: 'YYYY',
                H: 'HH',
                // h is the same for both,
                i: 'mm',
                S: 'ss',
                K: 'A',
            };

            let dateFormat = this.finalConfig.dateFormat;
            let newDateFormat = '';

            for (let character of dateFormat) {
                if (map[character]) {
                    newDateFormat += map[character];
                } else {
                    newDateFormat += character;
                }
            }

            return newDateFormat;
        },
        date() {
            if (typeof this.value == 'string') {
                return this.formatDateString(this.value, this.momentFormat, this.convertTz);
            }

            return this.fromDateObject(this.value, this.momentFormat, null, this.convertTz);
        },
        finalConfig() {
            let finalConfig = this.finalConfigDate;
            if (this.config.enableTime) {
                finalConfig = this.finalConfigTime;
            }
            return finalConfig;
        },
    },
    created() {
        if (this.finalConfig.hasOwnProperty('defaultDate') && this.finalConfig.defaultDate) {
            this.setDate(this.finalConfig.defaultDate);
        }
    },
    props: {
        value: {
            type: [Object, String],
            default: () => {
                return { date: null, timezone: null };
            },
        },
        label: String,
        labelClass: String,
        inputClass: [String, Array, Object],
        fieldClass: {
            type: String,
            default: 'column',
        },
        config: {
            type: Object,
            default: () => {
                return {
                    enableTime: true,
                    minDate: 'today',
                };
            },
        },
        name: String,
        convertTz: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: [String, Boolean],
            default: false,
        },
        outputFormat: {
            type: String,
            default: '',
        },
    },
};
</script>
