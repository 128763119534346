<template>
    <div :class="['note', highlightClass]">
        <div class="note-title">
            <em class="italic">{{ timestamp }}</em> <strong class="bold">({{ note.notetakerName }}):</strong>
            <span v-if="!disabled">
                <span class="icon edit-reminder" @click="onEdit" v-show="mayModify">
                    <i class="fas fa-pencil-alt"></i>
                </span>
                <button class="delete" @click="$emit('delete', note.id)" v-show="mayModify"></button>
                <span class="icon error-saving" @click="retryFailedAction" v-if="showRetryButton">
                    <i class="fas fa-exclamation-circle"></i>
                    <div class="error-saving-message">
                        <p>An error occured while saving. Please click to try again.</p>
                    </div>
                </span>
            </span>
        </div>
        <emp-editable-content :content="content" @update:content="onUpdate" :isEditing="isEditing" />
        <div class="actions level">
            <div class="level-left"></div>
            <div class="level-right">
                <div class="level-item">
                    <emp-button label="Cancel" @click="onCancel" v-show="this.isEditing" buttonClass="is-danger" />
                </div>
                <div class="level-item">
                    <emp-button label="Done" @click="onDone" v-show="this.isEditing" buttonClass="is-primary" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment-timezone';
import SmartApi from 'SmartApi';

export default {
    props: {
        note: {
            type: Object,
        },
        readOnly: {
            type: Boolean,
        },
        hasAbsoluteTimestamp: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: [String, Boolean],
            default: false,
        },
    },
    data() {
        return {
            isEditing: false,
            content: this.note.body,
            highlightClass: '',
            showRetryButton: false,
            retryAction: '',
        };
    },
    methods: {
        retryFailedAction() {
            switch (this.retryAction) {
                case 'add':
                    this.$emit('save:note', this.note);
                    break;
                case 'delete':
                    this.$emit('delete', this.note.id);
                    break;
                case 'update':
                    this.onDone();
                    break;
            }
        },
        onEdit() {
            this.isEditing = true;
        },
        onCancel() {
            this.isEditing = false;
            this.content = this.note.body;
        },
        onDone() {
            let content = _.get(this, 'content', '');
            content = content.trim();

            if (!content.length) {
                this.highlightError();
                this.showDangerNotification('A note cannot be saved without content.');
                return;
            }

            this.isEditing = false;

            const editedNote = Object.assign({}, this.note, {
                userId: this.note.userId || this.currentUser.id,
                notetakerName: this.note.notetakerName || this.currentUser.name,
                body: content,
            });

            this.$emit('update:note', editedNote);
        },
        onUpdate(newValue) {
            this.content = newValue;
        },
        highlightNote(action, success) {
            if (success) {
                this.highlightSuccess();
                this.showRetryButton = false;
            } else {
                this.highlightError();
                this.showRetryButton = true;
                this.retryAction = action;
            }
        },
        highlightError(timeout = 1000) {
            this.highlightClass = 'note-saved-unsuccessfully';
            this.removeHighlightAfterTimeout(timeout);
        },
        highlightSuccess(timeout = 1000) {
            this.highlightClass = 'note-saved-successfully';
            this.removeHighlightAfterTimeout(timeout);
        },
        removeHighlightAfterTimeout(time) {
            setTimeout(() => {
                this.highlightClass = '';
            }, time);
        },
    },
    computed: {
        prettyDatetime() {
            return moment(this.note.created_at).fromNow();
        },
        ISO8601Datetime() {
            return moment(this.note.created_at).format('M/D/YY h:mma');
        },
        mayModify() {
            const userId = this.currentUser.id;
            let isAuthor = userId === this.note.userId;
            return (this.currentUserCan('notes.manage') || isAuthor) && !this.isEditing && !this.readOnly;
        },
        timestamp() {
            if (this.hasAbsoluteTimestamp) {
                return this.ISO8601Datetime;
            }
            return this.prettyDatetime;
        },
    },
};
</script>

<style lang="scss" scoped>
.note {
    padding: 0.5rem;
    transition: background-color 1.5s ease-in-out;

    &.note-saved-successfully {
        background: #d7e2d1;
        transition: background-color 0s;
    }

    &.note-saved-unsuccessfully {
        background: #ec9ca6;
        transition: background-color 0s;
    }
}

.note-title {
    padding-bottom: 0.2rem;
}
.italic {
    font-style: italic;
}
.bold {
    font-weight: bold;
}
.fa.fa-pencil {
    font-size: 1rem;
    vertical-align: middle;
}
.error-saving {
    position: relative;
    .fa.fa-exclamation-circle {
        color: #d7263d;
        cursor: pointer;
        font-size: 1.8rem;
        vertical-align: middle;
    }
    .error-saving-message {
        visibility: hidden;
        color: #fff;
        border-radius: 0.3rem;
        background-color: #333;
        left: 2rem;
        position: absolute;
        width: 31rem;
        text-align: center;
        z-index: 1;
    }
    &:hover .error-saving-message {
        visibility: visible;
    }
}
</style>
