import ScopesApi from 'api/endpoints/scopes';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        facilityDropdownData: [],
        dropdownData: [],
        inRequest: false
    },
    mutations: {
        setDropdownData(store, payload) {
            store.dropdownData = payload;
            store.facilityDropdownData =  payload.filter((scope) => {
                return scope.type == 'facility'
            });
        }
    },
    actions: {
        async getDropdownData({ state, commit }, params) {
            if (state.inRequest === false) {
                state.inRequest = true;

                let paramsToPass = _.merge({}, {
                    sort: ['name'],
                }, params);

                let roles = await ScopesApi.get(paramsToPass);
                commit('setDropdownData', roles.data.data);

                state.inRequest = false;
            }
        }
    },
    getters: {
        scopeByType(state, getters) {
            return (type) => {
                return state.dropdownData.filter((scope) => {
                    return scope.type === 'shared' || scope.type === type;
                });
            };
        },
        adminScopes(state, getters) {
            return getters.scopeByType('admin');
        },
        facilityScopes(state, getters) {
            return getters.scopeByType('facility');
        }
    }
}
