<template>
    <div class="select-prewritten-note">
        <div class="note-button" @click="toggleNoteList()">
            <i class="fas fa-plus-square icon" title="Quick-Add Note"></i>
        </div>
        <div class="note-list" v-if="showNoteList">
            <div class="note-list-header level is-mobile">
                <div class="level-left">
                    <div class="level-item header-text">Quick-Add Notes</div>
                </div>
                <div class="level-right">
                    <div class="level-item header-button" @click="editNotes()">
                        <i class="fas fa-pencil-alt" title="Add/Edit Notes"></i>
                    </div>
                    <div class="level-item header-button" @click="hideNoteList()">
                        <i class="fas fa-times-circle" title="Close note list"></i>
                    </div>
                </div>
            </div>
            <div v-if="notesAreAvailable">
                <div class="note-list-notes">
                    <div class="note-wrapper" v-for="note in unselectedNotes" :key="note.id">
                        <div class="note-separator" v-if="note.is_separator">{{ note.note }}</div>
                        <div class="note" v-else @click="addNoteToField(note.note)">
                            {{ note.note }} <i class="fas fa-plus-square" title="Add note."></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-notes-available" v-else>
                {{ noNotesMessage }} To add/edit notes, click the <i class="fas fa-pencil-alt"></i> icon above.
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { PrewrittenNoteTypes } from 'enums';
import EditPrewrittenNotesModal from 'component/shared/EditPrewrittenNotesModal.vue';

export default {
    props: {
        noteTypeId: {
            type: Number,
            default: PrewrittenNoteTypes.FACILITY_ORDER_FORM,
        },
        noteField: {
            type: String,
            default: '',
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showNoteList: false,
        };
    },
    methods: {
        ...mapActions('shared/modal', ['showModal']),
        toggleNoteList() {
            this.showNoteList = !this.showNoteList;
        },
        hideNoteList() {
            this.showNoteList = false;
        },
        addNoteToField(note) {
            let existingNote = _.get(this.$parent, this.noteField) || '';
            let compiledNote = existingNote;

            let whitespaceCharacters = [' ', '\r', '\n'];
            let lastCharacterIsNotWhitespace = !whitespaceCharacters.includes(compiledNote[compiledNote.length - 1]);

            if (lastCharacterIsNotWhitespace && compiledNote.length > 0) {
                compiledNote += this.singleLine ? ' ' : '\r\n';
            }

            compiledNote += note;
            this.emitUpdatedNote(compiledNote);
        },
        emitUpdatedNote(updatedNoteText) {
            this.$emit('selectPrewrittenNote:noteUpdated', updatedNoteText);
        },
        editNotes() {
            this.showModal({
                component: EditPrewrittenNotesModal,
                data: null,
            });
        },
    },
    computed: {
        notes() {
            return this.$store.getters['shared/prewrittenNotes/notesByTypeId'](this.noteTypeId);
        },
        unselectedNotes() {
            let existingNote = _.get(this.$parent, this.noteField) || '';
            let filteredNotes = _.filter(this.notes, (note) => !existingNote.includes(note.note));
            return filteredNotes;
        },
        notesAreAvailable() {
            return this.unselectedNotes.length > 0;
        },
        notesPageName() {
            return 'Order Form Notes';
        },
        notesPageLinkToName() {
            return 'tools.order-form-notes';
        },
        noNotesMessage() {
            return this.notes.length ? 'All existing notes have been added.' : 'No quick-add notes are available.';
        },
    },
};
</script>

<style lang="scss" scoped>
.select-prewritten-note {
    white-space: normal; //Added because heading displays too tall when inside the Notes component. - JJB

    .note-button {
        display: inline-block;
        cursor: pointer;
        padding: 0.25rem;
        i:hover {
            color: #aaa;
        }
    }
    .note-list {
        background-color: #fff;
        box-shadow: 3px 3px 8px #333;
        border-radius: 0.25rem;
        position: absolute;
        max-width: 32rem;
        z-index: 1000;

        .note-list-header {
            background-color: #ddd;
            box-shadow: 0 3px 8px #fff;
            font-weight: bold;
            margin: 0;
            z-index: 100;

            .level-item {
                padding: 0.5rem;
            }

            .header-button {
                cursor: pointer;
                font-size: 1.25rem;
                :hover {
                    color: #aaa;
                }
            }
        }

        .note-list-notes {
            max-height: 20rem;
            overflow-y: scroll;

            .note-separator {
                border-top: 1px solid #ccc;
                padding: 1rem 0.75rem 0.5rem;
                font-weight: bold;
                margin: 0.25rem;
                text-align: center;
            }
            .note {
                cursor: pointer;
                padding: 0.75rem;
                &:hover {
                    background-color: #4f535d;
                    color: #fff;
                }
            }
            .note-wrapper:last-child {
                margin-bottom: 1rem;
            }
        }

        .no-notes-available {
            padding: 0.5rem;
            .fa-pencil-alt {
                font-size: 0.8em;
                margin-left: 0.2em;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .note-list {
            max-width: 98%;
        }
    }
}
</style>
