/**-------------------------------[  WARNING  ]-------------------------------**/
/**  This file was generated by the generate:front-end-enums artisan command  **/
/**  Do not make changes to it directly, they will be overwritten.            **/
/**---------------------------------------------------------------------------**/

export const UserPreferenceTypes = {
    FILTER: 'filter',
    GENERAL: 'general',
    EMPTY: 0,
};

export const Terms = {
    NET_15: 3,
    NET_30: 1,
    NET_45: 15,
    NET_60: 7,
    NET_90: 21,
    DUE_ON_RECEIPT: 2,
    EMPTY: 0,
};

export const TrackingNumberTypes = {
    NORMAL: 1,
    RETURN: 2,
    EMPTY: 0,
};

export const Roles = {
    EMPTY: 0,
    ADMIN_ID: 1,
    FACILITY_USER_ID: 2,
};

export const EmailProcessingStatuses = {
    NA: 'N/A',
    SUCCESSFUL: 'Successful',
    UNSUCCESSFUL: 'Unsuccessful',
};

export const RelationshipTypes = {
    DEFAULT: 0,
    OWNER: 1,
    REVIEWER: 2,
    FALLBACK: 3,
    AUTHOR: 4,
    RECIPIENT: 5,
    HANDLES_MESSAGES: 6,
    EMPTY: 0,
};

export const RecommendedToCustomers = {
    NO: 0,
    YES: 1,
    INDIFFERENT: 2,
};

export const OrderMethods = {
    EMAIL: 1,
    PHONE: 2,
    VENDOR_WEBSITE: 3,
    FAX: 4,
    CUSTOMER_DIRECT_ORDER: 5,
    BILL_ENTRY: 6,
    EDI: 7,
    EMPTY: 0,
};

export const Statuses = {
    DELETED: 10,
    HIDDEN: 20,
    PENDING_REVIEW: 60,
    NEED_SHIPPING: 61,
    REVIEWED: 62,
    SENT: 63,
    POSTED: 64,
    POSTED_CHANGED: 65,
    POSTED_REVIEWED: 66,
    ON_HOLD: 67,
    OPEN: 68,
    ORDERED: 69,
    PARTIALLY_RECEIVED: 70,
    RECEIVED: 71,
    PENDING_RETURN: 72,
    RETURNED: 73,
    CREDITED: 74,
    BACKORDERED: 75,
    IN_EMAIL_QUEUE: 76,
    NEED_MORE_INFO: 77,
    WAITING_FOR_VENDOR: 78,
    DELIVERED: 79,
    ACTIVE: 90,
    WAITING: 91,
    INACTIVE: 92,
    INCOMPLETE: 93,
    COMPLETE: 94,
    BILLED: 102,
    PARTIALLY_BILLED: 103,
    INVOICED: 104,
    AUTO_GENERATED: 105,
    ADDED_TO_BILL: 106,
    ADDED_TO_CREDIT: 107,
    OLD_IMPORTED: 108,
    SENT_CHANGED: 109,
    SENT_REVIEWED: 110,
    REVIEWED_CHANGED: 111,
    CLOSED: 113,
    RETURN_REQUESTED: 114,
    TO_BE_PRINTED: 115,
    RETURN_REJECTED: 116,
    EDI_QUEUED: 117,
    CHANGED: 112,
    DRAFT: 118,
    UNREAD: 119,
    READ: 120,
    DOWNLOAD_QUEUED: 121,
    DOWNLOAD_PROCESSING: 122,
    DOWNLOAD_READY: 123,
    DOWNLOAD_EXPIRED: 124,
    DOWNLOAD_FAILED: 125,
    MATCHED: 126,
    ORDERED_NEED_MORE_INFO: 127,
    ALL_EXCL_DELETED: 900,
    ALL_INCL_DELETED: 901,
    EMPTY: 0,
};

export const Times = {
    AM0800: 1,
    AM0830: 2,
    AM0900: 3,
    AM0930: 4,
    AM1000: 5,
    AM1030: 6,
    AM1100: 7,
    AM1130: 8,
    PM1200: 9,
    PM1230: 10,
    PM0100: 11,
    PM0130: 12,
    PM0200: 13,
    PM0230: 14,
    PM0300: 15,
    PM0330: 16,
    PM0400: 17,
    PM0430: 18,
    PM0500: 19,
    PM0530: 20,
    PM0600: 21,
    AM0700: 22,
    AM0730: 23,
    EMPTY: 0,
};

export const ImportTypes = {
    BILL: 'bill',
    INVENTORY: 'inventory',
    PRICE_LIST: 'price_list',
    PRODUCT: 'product',
    SHIPPING_AMOUNTS: 'shipping_amounts',
    FEE_SCHEDULE: 'fee_schedule',
    EMPTY: 0,
};

export const PrewrittenNoteTypes = {
    FACILITY_ORDER_FORM: 1,
    ADMIN_INTERNAL: 2,
    EMPTY: 0,
};

export const ProductTypes = {
    MANUAL: 1,
    SEARCH: 2,
    EXTRA: 4,
    PRICE_LIST: 5,
    EMPTY: 0,
};

export const UpdateTypes = {
    ALL: 0,
    SINGLE: 1,
    MULTIPLE: 2,
    THREAD: 3,
    EMPTY: 0,
};

export const UserTypes = {
    EMPTY: 0,
    EMPIRE: 1,
    FACILITY_ADMIN: 2,
    FACILITY: 3,
};

export const BugReportTypes = {
    CUSTOMER_REQUEST: 1,
    WEBSITE_ISSUE: 2,
    LCODE_ISSUE: 3,
};

export const States = {
    ALABAMA: 1,
    ALASKA: 2,
    ARIZONA: 3,
    ARKANSAS: 4,
    CALIFORNIA: 5,
    COLORADO: 6,
    CONNECTICUT: 7,
    DELAWARE: 8,
    FLORIDA: 9,
    GEORGIA: 10,
    HAWAII: 11,
    IDAHO: 12,
    ILLINOIS: 13,
    INDIANA: 14,
    IOWA: 15,
    KANSAS: 16,
    KENTUCKY: 17,
    LOUISIANA: 18,
    MAINE: 19,
    MARYLAND: 20,
    MASSACHUSETTS: 21,
    MICHIGAN: 22,
    MINNESOTA: 23,
    MISSISSIPPI: 24,
    MISSOURI: 25,
    MONTANA: 26,
    NEBRASKA: 27,
    NEVADA: 28,
    NEWHAMPSHIRE: 29,
    NEWJERSEY: 30,
    NEWMEXICO: 31,
    NEWYORK: 32,
    NORTHCAROLINA: 33,
    NORTHDAKOTA: 34,
    OHIO: 35,
    OKLAHOMA: 36,
    OREGON: 37,
    PENNSYLVANIA: 38,
    RHODEISLAND: 39,
    SOUTHCAROLINA: 40,
    SOUTHDAKOTA: 41,
    TENNESSEE: 42,
    TEXAS: 43,
    UTAH: 44,
    VERMONT: 45,
    VIRGINIA: 46,
    WASHINGTON: 47,
    WESTVIRGINIA: 48,
    WISCONSIN: 49,
    WYOMING: 50,
    PUERTO_RICO: 51,
    DISTRICT_OF_COLUMBIA: 52,
    US_VIRGIN_ISLANDS: 53,
    EMPTY: 0,
};

export const ImportStorageServices = {
    GOOGLE_DRIVE: 'google_drive',
    LOCAL: 'local',
    EMPTY: 0,
};
