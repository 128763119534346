// @ts-check
import { apis } from 'api/client';
import useConfiguredSWRV from 'app/useConfiguredSwrv';

/**
 * Setup data synchronization for Notes
 * @param {object} parameter
 * @param {import('@vue/composition-api').Ref<string>} parameter.noteable_type
 * @param {import('@vue/composition-api').Ref<Number>} parameter.noteable_id
 * @returns
 */
export default function useNotes({ noteable_type, noteable_id }, config = {}) {
    const fetcher = async () => {
        return (
            await apis.Notes.listNotesForNoteable({
                noteable_type: noteable_type.value,
                noteable_id: noteable_id.value,
                sort: ['-created_at'],
            })
        ).body.data;
    };

    const key = () => {
        if (!(noteable_type.value && noteable_id.value)) {
            return '';
        }
        return ['listNotesForNoteable', noteable_type.value, noteable_id.value];
    };

    const { data: notes, error, isValidating, mutate } = useConfiguredSWRV(key, fetcher, config);

    if (!notes.value) {
        notes.value = [];
    }

    return {
        /**
         * @type {import('@vue/composition-api').Ref<Note[]>}
         */
        notes,
        error,
        isValidating,
        mutate,
    };
}
