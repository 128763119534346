import useConfiguredSWRV from 'app/useConfiguredSwrv';
import { apis } from 'api/client';
import { computed, ref, watch } from '@vue/composition-api';
import { useStore } from 'use/store';
import useAddressFacilityTypeMap from './addressFacilityTypeMap';

export default function useHighlightsAddress(props) {
    const highlightClass = computed(() => {
        const store = useStore();
        if (!store.getters['shared/currentUser/isAdmin'] || !props.value) {
            return;
        }

        let { addressFacilityMap } = useAddressFacilityTypeMap();
        if (!addressFacilityMap.value) {
            return;
        }
        let selectedAddress = addressFacilityMap.value.find((address) => address.id == props.value);

        let flagship = _.get(selectedAddress, 'facility_is_flagship', false);
        if (flagship) {
            return 'flagship-selected';
        }

        let babysit = _.get(selectedAddress, 'facility_is_babysit', false);
        if (babysit) {
            return 'babysit-selected';
        }

        return;
    });
    return {
        highlightClass,
    };
}
