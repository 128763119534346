import pluralize from 'pluralize';
import { States, Statuses } from 'enums';
import { idToNameMap, idToCodeMap } from './stateMaps';

const exceptions = {
    NA: 'N/A',
    EMPTY: '',
    POSTED_CHANGED: 'Posted-Changed',
    POSTED_REVIEWED: 'Posted-Reviewed',
    SENT_CHANGED: 'Sent-Changed',
    SENT_REVIEWED: 'Sent-Reviewed',
    REVIEWED_CHANGED: 'Reviewed-Changed',
    ORDERED_NEED_MORE_INFO: 'Ordered - Need More Info',
    ALL_EXCL_DELETED: 'All (excluding deleted)',
    ALL_INCL_DELETED: 'All (including deleted)',
    EDI_QUEUED: 'EDI Queued',
    AM0800: '8:00 AM',
    AM0830: '8:30 AM',
    AM0900: '9:00 AM',
    AM0930: '9:30 AM',
    AM1000: '10:00 AM',
    AM1030: '10:30 AM',
    AM1100: '11:00 AM',
    AM1130: '11:30 AM',
    PM1200: '12:00 PM',
    PM1230: '12:30 PM',
    PM0100: '1:00 PM',
    PM0130: '1:30 PM',
    PM0200: '2:00 PM',
    PM0230: '2:30 PM',
    PM0300: '3:00 PM',
    PM0330: '3:30 PM',
    PM0400: '4:00 PM',
    PM0430: '4:30 PM',
    PM0500: '5:00 PM',
    PM0530: '5:30 PM',
    PM0600: '6:00 PM',
    AM0700: '7:00 AM',
    AM0730: '7:30 AM',
};

const pluralizeExceptions = {
    NA: 'N/A',
    ORDERED_NEED_MORE_INFO: 'Ordered - Need More Info',
    MATCHED: 'Matched',
    DOWNLOAD_QUEUED: 'Downloads Queued',
    DOWNLOAD_PROCESSING: 'Downloads Processing',
    DOWNLOAD_READY: 'Downloads Ready',
    DOWNLOAD_EXPIRED: 'Downloads Expired',
    DOWNLOAD_FAILED: 'Downloads Failed',
    NEED_MORE_INFO: 'Need More Info',
    HIDDEN: 'Hidden',
    EDI: 'EDI',
    AM0800: '8:00 AM',
    AM0830: '8:30 AM',
    AM0900: '9:00 AM',
    AM0930: '9:30 AM',
    AM1000: '10:00 AM',
    AM1030: '10:30 AM',
    AM1100: '11:00 AM',
    AM1130: '11:30 AM',
    PM1200: '12:00 PM',
    PM1230: '12:30 PM',
    PM0100: '1:00 PM',
    PM0130: '1:30 PM',
    PM0200: '2:00 PM',
    PM0230: '2:30 PM',
    PM0300: '3:00 PM',
    PM0330: '3:30 PM',
    PM0400: '4:00 PM',
    PM0430: '4:30 PM',
    PM0500: '5:00 PM',
    PM0530: '5:30 PM',
    PM0600: '6:00 PM',
    AM0700: '7:00 AM',
    AM0730: '7:30 AM',
    ALL_EXCL_DELETED: 'All (excluding deleted)',
    ALL_INCL_DELETED: 'All (including deleted)',
};

const closingTimes = [
    'PM0100',
    'PM0130',
    'PM0200',
    'PM0230',
    'PM0300',
    'PM0330',
    'PM0400',
    'PM0430',
    'PM0500',
    'PM0530',
    'PM0600',
];

export function getFriendlyName(enumKey) {
    if (exceptions.hasOwnProperty(enumKey)) {
        return exceptions[enumKey];
    }
    if (States.hasOwnProperty(enumKey)) {
        return idToNameMap[States[enumKey]];
    }
    const words = enumKey.toLowerCase().split('_');
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function getNameById(enumObj, id, plural = false) {
    const enumKey = Object.entries(enumObj).find(([key, value]) => value == id)?.[0];
    if (!enumKey) {
        return null;
    }
    return plural ? getPluralFriendlyName(enumKey) : getFriendlyName(enumKey);
}

export function getPluralFriendlyName(enumKey) {
    if (pluralizeExceptions.hasOwnProperty(enumKey)) {
        return pluralizeExceptions[enumKey];
    }
    let name = getFriendlyName(enumKey);
    return pluralize(name);
}

export function isClosingTime(enumKey) {
    return closingTimes.includes(enumKey);
}

export function getStateCodeById(id) {
    return idToCodeMap[id] ? idToCodeMap[id] : '';
}

export function getStateNameById(id) {
    return idToNameMap[id] ? idToNameMap[id] : '';
}

export function getStatusNameById(id) {
    const key = getKeyByValue(Statuses, id);

    if (key === null) {
        return '';
    }

    return getFriendlyName(key);
}

export function getKeyByValue(object, value) {
    for (const key in object) {
        if (object[key] === value) {
            return key;
        }
    }
    return null; // If the value is not found in any property of the object
}
