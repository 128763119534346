export default {
    mapper: {
        relations: {
            hasMany: {
                emailAttachment: {
                    foreignKey: 'email_id',
                    localField: 'attachments',
                },
            },
            morphMany: {
                document: {
                    foreignKey: 'source_id',
                    foreignType: 'source_type',
                    relatedType: 'email',
                    localField: 'documents',
                },
            },
            belongsToMany: {
                emailProcessingRule: {
                    foreignKey: 'id',
                    localField: 'emailProcessingRules',
                },
            },
        },
    },
};
