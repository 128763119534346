import Vue from 'vue';
import Bugsnag from '@bugsnag/browser';
import bugsnagConfig from 'app/bugsnagConfig';
import store from './store';
import router from './router';
import { bootstrap as bootstrapJSD } from './jsd';
import ApplicationContainer from 'component/shared/ApplicationContainer';
import { initializeClient, apis } from 'api/client';
import { prodConfig, configureApp } from './config';
import IdleVue from 'idle-vue';
import moment from 'moment';

Bugsnag.start(bugsnagConfig);
Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

/**
 * Configure and create the Vue application.
 */
function createApplication() {
    configureApp.fromConfig(prodConfig, Vue);
    Vue.use(IdleVue, { idleTime: 10000, store });
    new Vue({
        el: '#app',
        store,
        router,
        components: {
            ApplicationContainer,
        },
    });
}

function checkForMaintenanceMode() {
    return new Promise((resolve, reject) => {
        apis['util'].checkMaintenanceMode().then((response) => {
            if (response.body.maintenance) {
                document.getElementById('app').innerHTML =
                    "<div style='font-weight: bold; font-size: 64px;'><center>Down for Maintenance.</center></div>" +
                    "<div style='font-size: 32px;'><p><center>Empire Medical is currently down for Maintenance.</center></p></div>";

                if (response.body.until) {
                    let maintenance_end = moment(response.body.until)
                        .tz(moment.tz.guess())
                        .format('MMMM Do YYYY, h:mm:ss a');
                    document.getElementById('app').innerHTML +=
                        "<div style='font-size: 32px;'><p><center>We plan to be operational by " +
                        maintenance_end +
                        '.</center></p></div>';
                }

                reject();
            } else {
                resolve();
            }
        });
    });
}

function displayApplicationLoadErrorMessage(e, tag) {
    Bugsnag.notify(e);
    document.getElementById('app').innerHTML =
        "<div style='font-weight: bold; font-size: 64px;'><center>Failed to load application!</center></div>" +
        "<div style='font-size: 32px;'><p><center>The application has failed to load, please contact Empire Medical.</center></p></div>" +
        "<div style='font-size: 32px;'><p><center><a href='tel:5412456657'>541.245.6657</a></p></center></div>" +
        "<div style='font-size: 18px;'><center>" +
        tag +
        '</center></div>';
}

initializeClient()
    .then(() => {
        bootstrapJSD()
            .then(() => {
                checkForMaintenanceMode().then(() => createApplication());
            })
            .catch((e) => displayApplicationLoadErrorMessage(e, 'jsd-load-fail'));
    })
    .catch((e) => displayApplicationLoadErrorMessage(e, 'swagger-load-fail'));
