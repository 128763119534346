import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(id = null, params = {}) {
        const url = id ? route('invitation', [id]) : route('invitation');
        return await axios.get(url, { params });
    },
    async forFacility(facilityId, params = {}) {
        const url = route('facilities.invitations', [facilityId]);
        return await axios.get(url, { params });
    },
    async store(payload) {
        let url = route('invitation.store');
        let result = await axios.post(url, payload);
        return result.data.data;
    },
    async delete(id) {
        let url = route('invitation.destroy', { id: id });
        return await axios.delete(url);
    },
};
