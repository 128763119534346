<template>
    <div>
        <div class="message-body-content">
            <div class="columns is-multiline">
                <emp-textbox label="Name" fieldClass="column is-full" inputClass="" labelClass="" v-model="form.name" />
                <emp-textbox
                    label="Title"
                    fieldClass="column is-full"
                    inputClass=""
                    labelClass=""
                    v-model="form.title"
                />
                <emp-textbox
                    label="Email Address"
                    fieldClass="column is-full"
                    inputClass=""
                    labelClass=""
                    v-model="form.emailAddress"
                />
                <emp-textbox
                    label="Phone Number"
                    fieldClass="column is-full"
                    inputClass=""
                    labelClass=""
                    v-model="form.phoneNumber"
                    maxlength="45"
                />
                <emp-textarea
                    label="Notes"
                    fieldClass="column is-full"
                    inputClass=""
                    labelClass=""
                    v-model="form.notes"
                />
            </div>
        </div>
        <div class="message-body-buttons">
            <div class="level">
                <div class="level-left"></div>
                <div class="level-right">
                    <div class="level-item">
                        <emp-button @click="onSave" buttonClass="is-primary" label="Save" />
                    </div>
                    <div class="level-item">
                        <emp-confirmation-button @click="onClose" buttonClass="is-danger" label="Cancel" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const BLANK_REPRESENTATIVE = {
    id: null,
    index: null,
    name: '',
    title: '',
    emailAddress: '',
    phoneNumber: '',
    notes: '',
};

export default {
    props: {
        representativeData: {
            type: Object,
            default: BLANK_REPRESENTATIVE,
        },
    },
    data() {
        return {
            form: BLANK_REPRESENTATIVE,
        };
    },
    methods: {
        onClose() {
            this.$emit('vendor-representative-form:onClose');
        },
        onSave() {
            let payload = this.form;

            if (this.form.index == null) {
                this.$emit('vendor-representative-form:onAdd', payload);
            } else {
                this.$emit('vendor-representative-form:onUpdate', payload);
            }

            this.onClose();
        },
    },
    watch: {
        representativeData(newValue) {
            this.form = _.clone(newValue);
        },
    },
};
</script>
