/**
 * Facility Specific
 */

const ShoppingLists = () => import(/* webpackChunkName: "components-facility" */ 'component/facility/ShoppingLists');
const ShoppingListView = () =>
    import(/* webpackChunkName: "components-facility" */ 'component/facility/ShoppingListView');
const TermsAndConditions = () =>
    import(/* webpackChunkName: "components-facility" */ 'component/facility/TermsAndConditions');
const Users = () => import(/* webpackChunkName: "components-facility" */ 'component/shared/Users');
const UsersForm = () => import(/* webpackChunkName: "components-facility" */ 'component/shared/UsersForm');
const MakePayment = () => import(/* webpackChunkName: "components-facility" */ 'component/facility/MakePayment');

const Vendors = () => import(/* webpackChunkName: "components-facility" */ 'component/shared/Vendors');
const ProductSearch = () => import(/* webpackChunkName: "components-facility" */ 'component/shared/ProductSearch');
const ProductSearchModal = () =>
    import(/* webpackChunkName: "components-facility" */ 'component/shared/ProductSearchModal');
const ProductDatabase = () => import(/* webpackChunkName: "components-facility" */ 'component/shared/ProductDatabase');
const InventoryForm = () => import(/* webpackChunkName: "components-facility" */ 'component/shared/InventoryForm');
const PatientNames = () => import(/* webpackChunkName: "components-facility" */ 'component/shared/PatientNames');
const PrewrittenNotes = () => import(/* webpackChunkName: "components-facility" */ 'component/shared/PrewrittenNotes');
const LargeOrderNotification = () =>
    import(/* webpackChunkName: "components-facility" */ 'component/shared/LargeOrderNotification');
const Integrations = () => import(/* webpackChunkName: "components-facility" */ 'component/shared/Integrations');
const NotificationPreferences = () =>
    import(/* webpackChunkName: "components-facility" */ 'component/shared/NotificationPreferences');

const AddItemToOrderForm = () => import(/* webpackChunkName: "slytherin" */ 'component/shared/AddItemToOrderForm');
const InventoryLocations = () => import(/* webpackChunkName: "slytherin" */ 'component/facility/InventoryLocations');
const InventoryLocationForm = () =>
    import(/* webpackChunkName: "slytherin" */ 'component/facility/InventoryLocationForm');
const Reports = () => import('component/facility/Reports');

export default [
    {
        path: '/inventory/locations',
        component: InventoryLocations,
        name: 'inventory.locations',
        meta: { title: 'Inventory Locations' },
    },
    {
        path: '/inventory/locations/:id(\\d+)',
        component: InventoryLocationForm,
        name: 'inventory.locations.edit',
        meta: { title: 'Edit Inventory Location' },
    },
    {
        path: '/inventory/locations/create',
        component: InventoryLocationForm,
        name: 'inventory.locations.create',
        meta: { title: 'Create Inventory Location' },
    },
    {
        path: '/shopping-lists',
        component: ShoppingLists,
        name: 'shopping-lists',
        meta: { title: 'Shopping Lists' },
    },
    {
        path: '/shopping-lists/:shoppingListId(\\d+)',
        component: ShoppingListView,
        name: 'shopping-lists.view',
        meta: { title: 'Shopping Lists' },
        children: [
            {
                path: 'order/:id(\\d+)',
                name: 'shopping-lists.reorder',
                component: AddItemToOrderForm,
            },
        ],
    },
    {
        path: '/tools/products/search',
        component: ProductSearch,
        name: 'tools.products.search',
        children: [
            {
                path: 'view/:id(\\d+)',
                component: ProductSearchModal,
                name: 'tools.products.single',
                meta: { title: 'View Product' },
            },
            {
                path: 'order/:id(\\d+)',
                component: AddItemToOrderForm,
                name: 'tools.products.order',
            },
            {
                path: 'order',
                component: AddItemToOrderForm,
                name: 'tools.products.order-by-query',
            },
        ],
        meta: { title: 'L-Code Search' },
    },
    {
        path: '/tools/products',
        component: ProductDatabase,
        name: 'tools.products',
        children: [
            {
                path: '/tools/products/:productId(\\d+)/create-inventory/:facilityId(\\d+)',
                component: InventoryForm,
                name: 'tools.products.inventory.create',
                props: (route) => {
                    return {
                        facilityId: Number(route.params.facilityId),
                        productId: Number(route.params.productId),
                        inventoryRecordId: false,
                    };
                },
                meta: { title: 'Create Inventory Part' },
            },
            {
                path: 'order/:id(\\d+)',
                component: AddItemToOrderForm,
                name: 'tools.product-database.order',
            },
        ],
        meta: { title: 'Product Database' },
    },
    {
        path: '/tools/terms-and-conditions',
        component: TermsAndConditions,
        name: 'tools.terms-and-conditions',
        meta: { title: 'Terms and Conditions' },
    },
    {
        path: '/tools/payment',
        component: MakePayment,
        name: 'tools.payment',
        meta: { title: 'Make Payment' },
    },
    {
        path: '/facility/users/',
        component: Users,
        name: 'facility.users',
        meta: { title: 'Manage Users' },
    },
    {
        path: '/tools/order-form-notes',
        component: PrewrittenNotes,
        name: 'tools.order-form-notes',
        meta: { title: 'Order Form Notes' },
    },
    {
        path: '/facility/integrations/',
        component: Integrations,
        name: 'integrations',
        meta: { title: 'Manage Integrations' },
    },
    {
        path: '/facility/user/:id/',
        component: UsersForm,
        name: 'facility.users.edit',
        meta: { title: 'User Settings' },
    },
    {
        path: '/tools/vendors',
        component: Vendors,
        name: 'tools.vendors',
        meta: { title: 'Vendors' },
    },
    {
        path: '/patient-names',
        component: PatientNames,
        name: 'tools.patient-names',
        meta: { title: 'Patient Names/IDs' },
    },
    {
        path: '/tools/large-order-notification',
        component: LargeOrderNotification,
        name: 'tools.large-order-notification',
        meta: { title: 'Large Order Notifications' },
    },
    {
        path: '/tools/reports',
        component: Reports,
        name: 'tools.facility-reports',
        meta: { title: 'Reports' },
    },
    {
        path: '/user/notification-preferences',
        component: NotificationPreferences,
        name: 'user.notification-preferences',
        meta: { title: 'Edit Notification Preferences' },
    },
];
