<template>
    <div>
        <label :class="['label', labelClass]" v-show="label && readOnly">
            {{ label }}
        </label>
        <div v-if="!readOnly" class="note-input">
            <emp-textbox
                v-model="body"
                :fieldClass="fieldClass"
                @keyup.enter="onAddNote(null)"
                :disabled="disabled"
                name="body"
                :errors="form.generalErrors"
                @input="$emit('input', $event)"
                :label="label"
            />
            <div class="level">
                <div class="level-left">
                    <slot></slot>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <emp-button label="Add Note" @click="onAddNote" :disabled="disabled" />
                    </div>
                </div>
            </div>
        </div>
        <div :class="notesWrapperClass">
            <emp-note
                v-for="(note, index) in filteredNotes"
                :ref="getUniqueRefString(index)"
                :note="note"
                @save:note="onAddNote($event, true)"
                @delete="onDelete"
                @update:note="onUpdate"
                :key="craftKey(note)"
                :readOnly="readOnly"
                v-bind="$attrs"
                :disabled="disabled"
            />
        </div>
    </div>
</template>

<script>
import Note from 'component/shared/Note';
import EventBus from 'event-bus';
import SmartApi from 'SmartApi';
import { systemNotifications, highlightErrorFields } from 'component/shared/elements/mixins';
import { mapMutations } from 'vuex';
import Bugsnag from '@bugsnag/js';

export default {
    mixins: [highlightErrorFields],
    props: {
        label: String,
        labelClass: {
            type: String,
            default: '',
        },
        fieldClass: {
            type: String,
            default: '',
        },
        notesWrapperClass: {
            type: String,
            default: 'notes-wrapper',
        },
        notes: {
            type: Array,
            default: () => [],
        },
        noteableId: {
            type: [Number, Object],
            default: null,
        },
        noteableType: {
            type: [String, Object],
            default: null,
        },
        type: {
            type: [String, Array],
            default: function () {
                return 'general';
            },
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        emitGlobalNotificationEvent: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: [String, Boolean],
            default: false,
        },
    },
    data() {
        return {
            body: '',
            unsavedNote: false,
        };
    },
    components: {
        'emp-note': Note,
    },
    methods: {
        getUniqueRefString(index) {
            return `note_${this._uid}_${index}`;
        },
        checkType(type) {
            let showNote = false;
            if (this.type instanceof Array) {
                showNote = this.type.includes(type);
            } else {
                showNote = this.type === type || this.type === 'all';
            }
            return showNote;
        },
        async onDelete(noteId) {
            let success = true;
            if (this.noteableId) {
                success = this.deleteNoteFromExistingEntity(noteId);
            }

            if (success) {
                let notes = this.removeNoteFromNoteData(noteId);
                this.emitUpdate(notes);
            }
        },
        async deleteNoteFromExistingEntity(noteId) {
            try {
                await SmartApi.delete({
                    routeName: 'notes.destroy',
                    routeParams: {
                        note: noteId,
                    },
                    config: {
                        hideLoader: true,
                    },
                });
                this.showSuccessNotification('Note successfully deleted.');
            } catch (e) {
                console.log(e);
                this.highlightNoteWithId(noteId, 'delete', false);
                return false;
            }
            return true;
        },
        removeNoteFromNoteData(noteId) {
            const index = this.getIndexById(noteId);
            let notes = _.clone(this.notes);
            notes.splice(index, 1);
            return notes;
        },
        async onAddNote(noteOverride = null, replaceNote = false) {
            this.clearErrors();
            let note = noteOverride || this.createNoteFromGivenInformation();

            let body = _.get(note, 'body', '');
            body = body.trim();

            if (!body.length) {
                this.showDangerNotification('A note cannot be saved without content.');
            } else {
                if (note.noteableId) {
                    try {
                        note['id'] = await this.addNoteForExistingEntity(note);
                    } catch (e) {
                        if (e.response) {
                            console.log(e);
                            this.highlightErrorFields(e.response.data);
                        } else {
                            Bugsnag.notify(e);
                        }
                        return;
                    }
                }

                let notes = replaceNote ? this.replaceNoteInNoteData(note) : this.prependNoteToNoteData(note);
                this.emitUpdate(notes);
                this.highlightNoteWithId(null, 'add', true);
                this.body = '';
                this.emitEvent('bodySaved');
            }
        },
        createNoteFromGivenInformation() {
            return {
                body: this.body,
                type: this.type || 'general',
                userId: this.currentUser.id,
                notetakerName: this.currentUser.name,
                noteableId: this.noteableId,
                noteableType: this.noteableType,
            };
        },
        async addNoteForExistingEntity(note) {
            let response = await SmartApi.post({
                routeName: 'notes.create',
                routeParams: {
                    noteableType: note.noteableType,
                    noteableId: note.noteableId,
                },
                data: note,
                config: {
                    hideLoader: true,
                },
            });

            return response.data.data.recordId;
        },
        prependNoteToNoteData(note) {
            let notes = _.clone(this.notes);
            notes.unshift(note);
            return notes;
        },
        replaceNoteInNoteData(note) {
            const index = this.getIndexById(note.id);
            let notes = _.clone(this.notes);
            notes.splice(index, 1, note);
            return notes;
        },
        async onUpdate(note) {
            let success = true;

            if (this.noteableId) {
                success = await this.updateNoteForExistingEntity(note);
            }

            if (success) {
                let notes = this.replaceNoteInNoteData(note);
                this.emitUpdate(notes);
            }
        },
        async updateNoteForExistingEntity(note) {
            try {
                await SmartApi.put({
                    routeName: 'notes.update',
                    data: note,
                    routeParams: {
                        noteableId: note.noteableId,
                        noteableType: note.noteableType,
                        note: note.id,
                    },
                    config: {
                        hideLoader: true,
                    },
                });
            } catch (e) {
                console.error(e);
                this.highlightNoteWithId(note.id, 'update', false);
                return false;
            }

            this.highlightNoteWithId(note.id, 'update', true);
            return true;
        },
        highlightNoteWithId(noteId = null, action = 'add', success) {
            let index = 0;

            if (noteId) {
                index = _.findIndex(this.filteredNotes, (note) => note.id === noteId);
            }

            this.$nextTick(() => {
                let ref = this.getUniqueRefString(index);
                this.$refs[ref][0].highlightNote(action, success);
            });
        },
        emitUpdate(notes) {
            this.$emit('update:notes', notes);
        },
        getIndexById(id) {
            function hasId(element) {
                return element.id == id;
            }
            return this.notes.findIndex(hasId);
        },
        scrollToTop() {
            this.$el.scrollTop = 0;
        },
        craftKey(note) {
            // A unique key is required for Vue to properly render the collection of notes via `v-for`.
            // All notes added before the parent is saved will have `id === undefined`, so `note.id`
            // alone is insufficient. The following combination of attributes will uniquely identify
            // all unsaved notes with unique content. Duplicate notes will confuse Vue, but if unsaved
            // notes have identical content then the user should not care which note is removed or
            // modified.
            const primaryKey = note.id;
            const fallbackKey = note.type + note.body + note.user_id + note.notetaker_name;
            return primaryKey || fallbackKey;
        },
        emitEvent(eventName) {
            if (this.emitGlobalNotificationEvent) {
                EventBus.$emit(eventName, true);
            } else {
                this.$parent.$emit(eventName, true);
            }
        },
    },
    computed: {
        filteredNotes() {
            return this.notes.filter((note) => this.checkType(note.type));
        },
    },
    updated() {
        this.scrollToTop();
    },
    watch: {
        body: function (newVal, oldVal) {
            if (newVal && newVal != oldVal) {
                this.emitEvent('bodyChanged');
            }
        },
    },
};
</script>

<style lang="scss" scope>
.notes-wrapper {
    max-height: 250px;
    overflow: auto;
}
</style>
