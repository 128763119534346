import { auditsRelation, notesRelation } from 'app/jsd/helpers';

export default {
    modelName: 'product',
    mapper: {
        relations: {
            belongsToMany: {
                lCode: {
                    foreignKey: 'id',
                    localField: 'lcodes',
                },
                facility: {
                    foreignKey: 'id',
                    localField: 'searchProductPriceExceptions',
                },
                user: {
                    foreignKey: 'id',
                    localField: 'favoritedBy',
                },
            },
            hasMany: {
                priceListException: {
                    foreignKey: 'product_id',
                    localField: 'priceExceptions',
                },
                inventory: {
                    foreignKey: 'product_id',
                    localField: 'inventoryRecords',
                },
                productView: {
                    foreignKey: 'product_id',
                    localField: 'productViews',
                },
            },
            belongsTo: {
                category: {
                    foreignKey: 'category_id',
                    localField: 'category',
                },
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
            },
            morphMany: {
                ...notesRelation('product'),
                ...auditsRelation('product'),
            },
        },
    },
};
