import _ from 'lodash';
import merge from 'deepmerge';
import InventoryLocationApi from 'api/endpoints/inventory-location';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    actions: {
        get({ state, commit }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, { sort: state.sort }, params);

            InventoryLocationApi.get(params).then((response) => {
                commit('setData', response.data.data);
                commit('setPaginationData', response.data.meta.pagination);
            });
        },
        async delete({ state, dispatch }, payload) {
            await InventoryLocationApi.delete(payload);
        },
    },
    getters: {
        api() {
            return InventoryLocationApi;
        },
        inventoryLocationById(state) {
            return (locationId) => state.data.find(location => location.id == locationId);
        },
        nameFromId(state, getters) {
            return (locationId) => {
                let name = null;
                let location = getters.inventoryLocationById(locationId);

                if (location && location.name) {
                    name = location.name;
                }

                return name;
            };
        },
    },
});
