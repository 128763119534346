<template>
    <div :class="['field', fieldClass]">
        <p class="control">
            <label :class="['label', labelClass]" :disabled="disabled" :title="title">
                <input
                    @change="$emit('input', Number($event.target.checked))"
                    :checked="value"
                    :class="['checkbox', inputClass]"
                    :disabled="disabled"
                    :name="name"
                    :tabindex="tabindex"
                    type="checkbox"
                    role="checkbox"
                />
                <template v-if="label">
                    {{ label }}
                </template>
            </label>
        </p>
        <template v-if="hasErrorArray">
            <p class="help is-danger" v-for="(error, index) in errors[name]" :key="index">{{ error }}</p>
        </template>
        <template v-else-if="hasError">
            <p class="help is-danger">{{ errors[name] }}</p>
        </template>
    </div>
</template>

<script>
import { input } from './mixins';
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    mixins: [input],
};
</script>

<style lang="scss" scoped>
.checkbox {
    margin-right: 0.3em;
}
</style>
