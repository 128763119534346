import axios from 'axios';
import route from 'ziggyRoute';

export default {
    async get(params = {}) {
        let url = params.id ? route('shopping-list-lines.index', { id: params.id }) : route('shopping-lists');
        delete params.id;
        return axios.get(url, { params });
    },
};
