<template>
    <span class="badge-display">
        <slot></slot>
        <flagship-badge v-if="isFlagship" />
        <babysit-badge v-if="babysit" />
    </span>
</template>

<script>
import FlagshipBadge from 'component/admin/elements/badges/Flagship';
import BabysitBadge from 'component/admin/elements/badges/Babysit';

export default {
    props: {
        isFlagship: {
            type: [Number, Boolean],
            default: 0,
        },
        babysit: {
            type: [Number, Boolean],
            default: 0,
        },
    },
    components: {
        FlagshipBadge,
        BabysitBadge,
    },
};
</script>
