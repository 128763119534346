import { auditsRelation, notesRelation } from 'app/jsd/helpers';

export default {
    mapper: {
        relations: {
            hasMany: {
                creditLine: {
                    foreignKey: 'credit_id',
                    localField: 'lines',
                },
                creditMemo: {
                    foreignKey: 'credit_id',
                    localField: 'creditMemos',
                },
            },
            belongsTo: {
                vendor: {
                    foreignKey: 'vendor_id',
                    localField: 'vendor',
                },
            },
            morphMany: {
                ...auditsRelation('credit'),
                ...notesRelation('credit'),
            },
        },
    },
};
