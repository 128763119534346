import { utils, Relation } from 'js-data';

export const MorphManyType = 'morphMany';

export const MorphManyRelation = Relation.extend(
    {
        validateOptions(related, opts) {
            Relation.prototype.validateOptions.call(this, related, opts);

            const { foreignKey, foreignType, relatedType } = opts;

            if (!foreignKey || !foreignType || !relatedType) {
                throw utils.err('new Relation', 'opts.<foreignKey|foreignType|relatedType>')(400, 'string', foreignKey);
            }
        },

        canFindLinkFor(record) {
            return !!this.foreignKey;
        },

        /**
         * This function gets called from the Record's loadRelations() function
         * See: node_modules/js-data/src/Record.js:504
         *
         * @param mapper
         * @param def
         * @param record
         * @param opts
         * @returns {*[]|*}
         */
        load(mapper, def, record, opts) {
            opts || (opts = {});
            const relatedMapper = def.getRelation();
            const store = relatedMapper.datastore;
            if (store && store['findAll']) {
                if (def.opts) {
                    utils.fillIn(opts, def.opts);
                }

                // console.log('query: ', {
                //     [def.foreignKey]: utils.get(record, mapper.idAttribute),
                //     [def.foreignType]: def.relatedType,
                // });

                return store
                    .findAll(
                        relatedMapper.name,
                        {
                            //When called from the Record, def === this. I'm using def
                            //here because it makes it easier to test, and aless confusing
                            // to be pulling all the keys/types from the same source. -Toby
                            [def.foreignKey]: utils.get(record, mapper.idAttribute),
                            [def.foreignType]: def.relatedType,
                        },
                        opts
                    )
                    .then(function (relatedData) {
                        return relatedData;
                    });
            }
            return [];
        },

        linkRecord(record, relatedRecords) {
            const relatedCollection = this.relatedCollection;
            const canAutoAddLinks = this.canAutoAddLinks;
            const foreignKey = this.foreignKey;
            const unsaved = relatedCollection.unsaved();

            return relatedRecords.map((relatedRecord) => {
                const relatedId = relatedCollection.recordId(relatedRecord);

                if (
                    (relatedId === undefined && unsaved.indexOf(relatedRecord) === -1) ||
                    relatedRecord !== relatedCollection.get(relatedId)
                ) {
                    if (foreignKey) {
                        // TODO: slow, could be optimized? But user loses hook
                        this.setForeignKey(record, relatedRecord);
                    }
                    if (canAutoAddLinks) {
                        relatedRecord = relatedCollection.add(relatedRecord);
                    }
                }

                return relatedRecord;
            });
        },

        findExistingLinksFor(record) {
            const id = utils.get(record, this.mapper.idAttribute);
            let records;

            if (id !== undefined && this.foreignKey) {
                records = this.findExistingLinksByForeignKey(id);
            }

            if (records && records.length) {
                return records;
            }
        },

        // In parent
        // e.g. user hasMany post via "foreignKey", so find all posts of user
        findExistingLinksByForeignKey(id) {
            if (id === undefined || id === null) {
                return;
            }
            return this.relatedCollection.filter({
                [this.foreignKey]: id,
                [this.foreignType]: this.relatedType,
            });
        },

        isRequiresParentId() {
            return !!this.localKeys && this.localKeys.length > 0;
        },

        isRequiresChildId() {
            return !!this.foreignKey;
        },

        createParentRecord(props, opts) {
            let relationData = this.getLocalField(props);
            const foreignIdField = this.getRelation().idAttribute;

            return this.createLinked(relationData, opts).then((records) => {
                utils.set(
                    props,
                    this.localKeys,
                    records.map((record) => utils.get(record, foreignIdField))
                );
            });
        },

        createChildRecord(props, relationData, opts) {
            this.setForeignKey(props, relationData);

            opts = {
                opts,
                ...this.opts,
            };

            relationData = relationData.map((data) => {
                return {
                    ...data,
                    [this.foreignType]: this.relatedType,
                };
            });

            return this.createLinked(relationData, opts).then((result) => {
                this.setLocalField(props, result);
            });
        },

        createLinked(props, opts) {
            return this.getRelation().createMany(props, opts);
        },
    },
    {
        TYPE_NAME: MorphManyType,
    }
);

export function createDescriptor(mapper, def, name, store) {
    const relation = def.relation;
    const localField = def.localField;
    const path = `links.${localField}`;
    const foreignKey = def.foreignKey;
    const updateOpts = { index: foreignKey };

    const getter = function () {
        return this._get(path);
    };

    if (store._collections[relation] && foreignKey && !store.getCollection(relation).indexes[foreignKey]) {
        store.getCollection(relation).createIndex(foreignKey);
    }

    return {
        get() {
            const current = getter.call(this);
            if (!current) {
                this._set(path, []);
            }
            return getter.call(this);
        },
        // e.g. post.comments = someComments
        // or user.groups = someGroups
        // or group.users = someUsers
        set(records) {
            if (records && !utils.isArray(records)) {
                records = [records];
            }
            const id = utils.get(this, mapper.idAttribute);
            const relatedIdAttribute = def.getRelation().idAttribute;
            const inverseDef = def.getInverse(mapper) || {};
            const inverseLocalField = inverseDef.localField;
            const current = this._get(path) || [];
            const toLink = [];
            const toLinkIds = {};

            if (records) {
                records.forEach((record) => {
                    // e.g. comment.id
                    const relatedId = utils.get(record, relatedIdAttribute);
                    const currentParent = utils.get(record, inverseLocalField);
                    if (currentParent && currentParent !== this) {
                        const currentChildrenOfParent = utils.get(currentParent, localField);
                        // e.g. somePost.comments.remove(comment)
                        if (relatedId === undefined) {
                            utils.remove(currentChildrenOfParent, (child) => child === record);
                        } else {
                            utils.remove(
                                currentChildrenOfParent,
                                (child) => child === record || relatedId === utils.get(child, relatedIdAttribute)
                            );
                        }
                    }
                    if (relatedId !== undefined) {
                        if (this._get('$')) {
                            // Prefer store record
                            record = store.get(relation, relatedId) || record;
                        }
                        // e.g. toLinkIds[comment.id] = comment
                        toLinkIds[relatedId] = record;
                    }
                    toLink.push(record);
                });
            }

            // e.g. post.comments = someComments
            if (foreignKey) {
                current.forEach((record) => {
                    // e.g. comment.id
                    const relatedId = utils.get(record, relatedIdAttribute);
                    if (
                        (relatedId === undefined && toLink.indexOf(record) === -1) ||
                        (relatedId !== undefined && !(relatedId in toLinkIds))
                    ) {
                        // Update (unset) inverse relation
                        if (records) {
                            // e.g. comment.post_id = undefined
                            utils.safeSetProp(record, foreignKey, undefined);
                            // e.g. CommentCollection.updateIndex(comment, { index: 'post_id' })
                            store.getCollection(relation).updateIndex(record, updateOpts);
                        }
                        // e.g. comment.post = undefined
                        utils.safeSetLink(record, inverseLocalField, undefined);
                    }
                });
                toLink.forEach((record) => {
                    // Update (set) inverse relation
                    // e.g. comment.post_id = post.id
                    utils.safeSetProp(record, foreignKey, id);
                    // e.g. CommentCollection.updateIndex(comment, { index: 'post_id' })
                    store.getCollection(relation).updateIndex(record, updateOpts);
                    // e.g. comment.post = post
                    utils.safeSetLink(record, inverseLocalField, this);
                });
            }

            this._set(path, toLink);
            return toLink;
        },
    };
}

export const LoadWith = function (adapter, mapper, def, records, __opts) {
    const relatedMapper = def.getRelation();
    const idAttribute = mapper.idAttribute;

    if (utils.isObject(records) && !utils.isArray(records) && records) {
        return adapter
            .findAll(
                relatedMapper,
                {
                    [def.foreignKey]: utils.get(records, mapper.idAttribute),
                    [def.foreignType]: def.relatedType,
                },
                __opts
            )
            .then((relatedItems) => {
                def.setLocalField(records, relatedItems);
            });
    } else {
        return adapter
            .findAll(
                relatedMapper,
                {
                    [def.foreignKey]: records.map((record) => utils.get(record, mapper.idAttribute)),
                    [def.foreignType]: def.relatedType,
                },
                __opts
            )
            .then((relatedItems) => {
                const foreignKeyField = def.foreignKey;
                records.forEach((record) => {
                    const _relatedItems = [];
                    const id = utils.get(record, idAttribute);
                    relatedItems.forEach((relatedItem) => {
                        if (utils.get(relatedItem, foreignKeyField) === id) {
                            _relatedItems.push(relatedItem);
                        }
                    });
                    def.setLocalField(record, _relatedItems);
                });
            });
    }
};
