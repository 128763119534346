<template>
    <emp-component-modal @modal:close="onClose" :isActive="isActive" contentClass="width-30vw">
        <template v-if="isActive">
            <div slot="modal-header">{{ modalHeader }}</div>
            <div slot="modal-body">
                <div class="choose-warehouse">
                    <div class="mobile-table-wrapper">
                        <table class="table is-fullwidth">
                            <thead>
                                <td class="has-text-centered">ID</td>
                                <td class="has-text-centered">Name</td>
                                <td class="has-text-centered">Reason</td>
                            </thead>
                            <tr v-for="(error, index) in errors" :key="`${_uid}_${index}`">
                                <td class="has-text-centered">{{ error.id }}</td>
                                <td class="has-text-centered">{{ error.name }}</td>
                                <td class="has-text-centered">{{ error.reason }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <div class="level-item">
                                <emp-button buttonClass="is-info" label="Close" @click="onClose" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </emp-component-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            data: (state) => state.shared.modal.data,
        }),
        errors() {
            return this.data.errors || [];
        },
        modalHeader() {
            return this.errors.length > 1 ? 'Some errors occurred' : 'An error occurred';
        },
    },
    methods: {
        ...mapActions({
            onClose: 'shared/modal/closeModal',
        }),
    },
};
</script>
