<template>
    <div
        class="modal"
        :class="{
            'is-active': isActive,
        }"
    >
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="message">
                <div class="message-header">
                    <p>{{ heading }}</p>
                </div>
                <div class="message-body">
                    <div class="message-body-content">
                        <div class="columns subsection">
                            <div class="column">
                                <div class="columns is-multiline">
                                    <template v-for="field in fields">
                                        <template v-if="field.type == 'textbox'">
                                            <emp-textbox
                                                :fieldClass="field.fieldClass"
                                                :label="field.label"
                                                :name="field.db_name"
                                                :value="editObject[field.db_name] ? editObject[field.db_name] : ''"
                                                v-model="editObject[field.db_name]"
                                            />
                                        </template>
                                        <template v-else-if="field.type == 'textarea'">
                                            <emp-textarea
                                                :fieldClass="field.fieldClass"
                                                :label="field.label"
                                                :name="field.db_name"
                                                :value="editObject[field.db_name] ? editObject[field.db_name] : ''"
                                                v-model="editObject[field.db_name]"
                                            />
                                        </template>
                                        <template v-else-if="field.type == 'select'">
                                            <emp-select
                                                :fieldClass="field.fieldClass"
                                                :label="field.label"
                                                :name="field.db_name"
                                                :selected="editObject[field.nameFrom] ? editObject[field.nameFrom] : ''"
                                                :options="field.options"
                                                :nameFrom="field.nameFrom"
                                                :valueFrom="field.valueFrom"
                                                v-model="editObject[field.valueFrom]"
                                            />
                                        </template>
                                        <template v-else-if="field.type == 'checkbox'">
                                            <emp-checkbox
                                                :fieldClass="field.fieldClass"
                                                :label="field.label"
                                                :name="field.db_name"
                                                :value="true"
                                                v-model="editObject[field.db_name]"
                                            />
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="message-body-buttons">
                        <div class="columns">
                            <div class="field column is-two-thirds is-grouped">
                                <div class="field is-grouped">
                                    <p class="control">
                                        <emp-button label="Save" buttonClass="is-primary" @click="save" />
                                    </p>
                                    <p class="control">
                                        <emp-button label="Close" @click="close" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
export default {
    methods: {
        close() {
            this.$store.dispatch('shared/simpleFormModal/close');
        },
        async save() {
            if (this.handler) {
                _.assign(this.origObject, this.editObject);
                await this.handler(this.origObject);
                this.close();
            }
        },
    },
    computed: {
        isActive() {
            return this.$store.state.shared.simpleFormModal.isActive;
        },
        heading() {
            return this.$store.state.shared.simpleFormModal.heading;
        },
        fields() {
            return this.$store.state.shared.simpleFormModal.fields;
        },
        editObject() {
            return _.clone(this.$store.state.shared.simpleFormModal.editObject);
        },
        origObject() {
            return this.$store.state.shared.simpleFormModal.editObject;
        },
        handler() {
            return this.$store.state.shared.simpleFormModal.handler;
        },
    },
};
</script>

<style lang="scss" scoped>
.message-body {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
}

.message-body-content {
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.message-body-buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
}
</style>
