import axios from 'axios';
import _ from 'lodash';
import route from 'ziggyRoute';

export default {
    async get(params = {}, config = {}) {
        let url = route('facilities.products.search', [params.facility_id]);

        if (params.id) {
            url = route('facilities.products.show', { facility: params.facility_id, id: params.id });
        }

        delete params.facility_id;

        const paramsWithConfig = _.merge({}, config, { params: params });
        return await axios.get(url, paramsWithConfig);
    },
};
