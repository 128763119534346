
import _ from 'lodash';
import {BLANK_ITEM} from 'component/shared/elements/mixins';
import Bugsnag from '@bugsnag/js';

export default {
    namespaced: true,
    state: {
        savedOrderId: null,
        order: {},
        moduleName: 'Order Form - Order'
    },
    mutations: {
        setSavedOrderId(state, payload) {
            state.savedOrderId = payload;
        },
        setOrder(state, payload) {
            state.order = payload.order;
        },
        updateOrder(state, payload) {
            state.order[payload.attribute] = payload.value;
        },
        updateItem(state, payload) {
            _.set(state.order.items[payload.index], payload.attribute, payload.value);
        },
        addItem(state, payload) {
            state.order.items.push(payload.item);
        },
        makeDirty(state) {
            state.order.isDirty = true;
        },
        makeClean(state) {
            state.order.isDirty = false;
        },
        insertItem(state, payload) {
            state.order.items.splice(payload.index + 1, 0, payload.item);
        },
        removeItem(state, payload) {
            state.order.items.splice(payload.index, 1);
        },
        replaceItem(state, payload) {
            state.order.items.splice(payload.index, 1, payload.item);
        },
        updateVendorField(state, payload) {
            let {index, id, value} = payload;
            let fields = state.order.items[index]['vendorFieldInformation'];
            let fieldIndex = fields.findIndex((field) => field.id == id);
            let field = Object.assign({}, fields[fieldIndex], {value});
            fields.splice(fieldIndex, 1, field);
        },
        setFacilityId(state, facilityId) {
            state.order.facilityId = facilityId;
        }
    },
    actions: {
        createBlankOrder({state, commit, getters, rootGetters}) {
            const order = getters.blankOrder();
            commit('setOrder', {order});
        },
        updateOrder({state, commit}, payload) {
            let original = state.order[payload.attribute];
            if (original !== payload.value){
                commit('makeDirty');
            }
            commit('updateOrder', payload);
        },
        updateItem({commit, state}, payload) {
            let original = state.order.items[payload.index][payload.attribute];
            if (original !== payload.value && !(payload.stayClean)) {
                commit('makeDirty');
            }
            commit('updateItem', payload);
            if (payload.attribute === 'patients') {
                commit('updateItem', {index: payload.index, attribute: 'isForStock', value: false});
            } else if (payload.attribute === 'isForStock') {
                commit('updateItem', {index: payload.index, attribute: 'patients', value: []});
            } else if (payload.attribute === 'dateNeeded') {
                let value = !Boolean(payload.value);  // isGroundShipping is checked if date is falsy.
                commit('updateItem', {index: payload.index, attribute: 'isGroundShipping', value});
            } else if (payload.attribute === 'isGroundShipping' && payload.value) {
                commit('updateItem', {index: payload.index, attribute: 'dateNeeded', value: null});
            }
        },
        addItem({commit}, payload) {
            commit('makeDirty');
            commit('addItem', payload);
        },
        applyToAll({dispatch, state}, payload) {
            state.order.items.forEach((item, index, array) => {
                dispatch('updateItem', {...payload, index});
            });
        },
        duplicateItem({commit, state, getters, rootGetters}, payload) {
            commit('makeDirty');
            let original = state.order.items[payload.index];
            let copy = getters.blankItem();
            let transferable = [
                'manufacturerId',
                'jobNumber',
                'shippingAddressId',
                'costCenterId',
                'practitionerId',
            ];

            if (original.onHand) {
                transferable.push('onHand');
            }

            if (original.isForStock) {
                copy.isForStock = true;
            } else {
                transferable.push('patients');
            }

            if (original.isGroundShipping) {
                copy.isGroundShipping = true;
            } else {
                transferable.push('dateNeeded');
            }

            let currentUser = rootGetters['shared/currentUser/user'];
            copy['isPutOnHold'] = currentUser.holdAllOrders;

            let originalShippingAddress = rootGetters['shared/addresses/addressFromId'](original['shippingAddressId']);

            if (originalShippingAddress) {
                copy['isDoNotSub'] = originalShippingAddress.isDoNotSub;
            }

            transferable.forEach((attribute, index, array) => {
                copy[attribute] = original[attribute];
            });

            commit('insertItem', {index: payload.index, item: copy});
        },
        removeItem({commit, state, getters}, payload) {
            commit('makeDirty');
            if (state.order.items.length > 1) {
                commit('removeItem', payload);
            } else {
                commit('updateOrder', {attribute: 'items', value: [getters.blankItem()]});
            }
        },
        clearItem({commit, state, getters}, payload) {
            let isNew = state.order.id === null;
            let hasOneItem = state.order.items.length === 1;
            let shouldClean = isNew && hasOneItem;
            let preserveKeys = payload.preserveKeys || [];

            if (shouldClean) {
                commit('makeClean');
            } else {
                commit('makeDirty');
            }
            let currentItem = state.order.items[payload.index];
            let replaceWith = getters.blankItem();

            preserveKeys.forEach((key) => {
                replaceWith[key] = currentItem[key];
            });

            commit('replaceItem', {index: payload.index, item: replaceWith});
        },
        insertBlankItem({state, commit, getters}, payload) {
            let preserveKeys = payload.preserveKeys || [];
            let firstItem = state.order.items[0];
            let append = getters.blankItem();

            preserveKeys.forEach((key) => {
                append[key] = firstItem[key];
            });

            commit('makeDirty');
            commit('insertItem', {index: payload.index, item: append});
        },
        loadOrder({state, commit, dispatch, getters, rootGetters}, payload) {
            if (!payload.id) {
                dispatch('createBlankOrder');
            } else {
                // TODO: [JEF] Clean this up.
                let savedOrder = rootGetters['shared/savedOrders/selectedSavedOrder'](payload.id);

                // set facilityId for getters.availableShippingAddresses
                if (!state.order.facilityId) {
                    commit('setFacilityId', savedOrder.data.facilityId);
                }

                let order = getters.blankOrder();
                let blankItem = getters.blankItem();

                if (!savedOrder) {
                    Bugsnag.leaveBreadcrumb("Saved order not loaded into store.", {
                        id: payload.id
                    });
                }

                order = Object.assign({}, order, savedOrder.data);
                order.id = savedOrder.id;
                order.name = savedOrder.name;
                order.purchaseOrderId = null;

                let activeVendors = rootGetters['shared/vendors/activeVendors'];
                let various_supplier_id = VARIOUS_SUPPLIER_VENDOR_ID;

                order.items = order.items.map((item) => {
                    item.isPutOnHold = blankItem.isPutOnHold;

                    if (!item.practitionerId) {
                        item.practitionerId = blankItem.practitionerId;
                    }

                    let vendorIsNotActive = !_.find(activeVendors, vendor => item.vendorId == vendor.id);
                    if (item.vendorId && vendorIsNotActive) {
                        item.vendorId = various_supplier_id;
                    }

                    let manufacturerIsNotActive = !_.find(activeVendors, manufacturer => item.manufacturerId == manufacturer.id);
                    if (item.manufacturerId && manufacturerIsNotActive) {
                        item.manufacturerId = various_supplier_id;
                    }

                    // Load the current shipping `Address` dependent values instead of the values when the order was saved.
                    // This ensures that a loaded order cannot bypass the current value of _Hold All Orders_ for the order's
                    // shipping `Address`.
                    let shippingAddress = getters.availableShippingAddresses.find((address) => address.id === item.shippingAddressId);

                    if (shippingAddress) {
                        item.isDoNotSub = shippingAddress.isDoNotSub;
                    } else {
                        item.shippingAddressId = blankItem.shippingAddressId;
                        item.isDoNotSub = blankItem.isDoNotSub;
                    }


                    if (!item.costCenterId) {
                        item.costCenterId = blankItem.costCenterId;
                    }

                    return item;
                });

                commit('setOrder', {order});
            }
            commit('makeClean');
        },
        async saveOrder({commit, dispatch, state}, payload) {
            // TODO: [JEF] Remove the need for this transformation.
            let savePayload = {
                name: state.order.name,
                data: {
                    facilityId: state.order.facilityId,
                    items: state.order.items,
                    purchaseOrderId: null,
                },
                id: state.order.id,
                config: {
                    hideLoader: true
                }
            };
            let success = false;
            let action = 'shared/savedOrders/update'
            if (state.order.id === null) {
                action = 'shared/savedOrders/create';
            }
            try {
                success = true;
                let savedOrder = await dispatch(action, savePayload, {root: true});
                commit('updateOrder', {attribute: 'id', value: savedOrder.id});
                commit('makeClean');
            } catch (e) {
                //todo: [Toby] Should this really be a console.log rather than an error?
                // It is being ignored by the testing framework when because it is a log.
                console.log(e);
                success = false;
            }
            return success;
        },
        updateVendorField({commit, state, getters}, payload) {
            commit('updateVendorField', payload);
            commit('makeDirty');
        }
    },
    getters: {
        blankOrder(state, getters, rootState, rootGetters) {
            return () => {
                return {
                    facilityId: rootGetters['shared/currentUser/facilityId'],
                    purchaseOrderId: null,
                    shippingAddressId: null,
                    costCenterId: null,
                    practitionerId: null,
                    quantity: null,
                    patients: [],
                    isForStock: null,
                    customerNote: null,
                    customerInternalMemo: null,
                    dateNeeded: null,
                    isGroundShipping: null,
                    jobNumber: null,
                    duplicateLineConfirmed: false,
                    items: [
                        getters.blankItem(),
                    ],
                    isDirty: false,
                    id: null,
                    name: '',
                };
            };
        },
        blankItem(state, getters, rootState, rootGetters) {
            return () => {
                let item = _.cloneDeep(BLANK_ITEM);

                let currentUser = rootGetters['shared/currentUser/user'];
                item.isPutOnHold = currentUser.holdAllOrders;

                if (getters.availablePractitioners.length === 1) {
                    item.practitionerId = getters.availablePractitioners[0].id;
                } else {
                    item.practitionerId = rootGetters['shared/currentUser/defaultPractitionerId'];
                }

                if (getters.availableShippingAddresses.length === 1) {
                    let shippingAddress = getters.availableShippingAddresses[0];
                    item.shippingAddressId = shippingAddress.id;
                    item.isDoNotSub = shippingAddress.isDoNotSub;
                } else {
                    item.shippingAddressId = rootGetters['shared/currentUser/defaultShippingAddressId'];
                }

                if (getters.availableCostCenters.length === 1) {
                    item.costCenterId = getters.availableCostCenters[0].id;
                } else {
                    item.costCenterId = rootGetters['shared/currentUser/defaultCostCenterId'];
                }

                return item;
            }
        },
        getItem: (state) => (index) => {
            return state.order.items[index];
        },
        getVendorField: (state) => ({index, id}) => {
            const item = state.order.items[index];
            const field = item.vendorFieldInformation.find((item) => item.id == id);
            return field;
        },
        availablePractitioners(state, getters, rootState, rootGetters) {
            if (rootGetters['shared/currentUser/isFacility']) {
                return rootGetters['shared/whitelistedUsers'];
            }

            return rootGetters['shared/users/facilityPractitioners'](state.order.facilityId);
        },
        availableShippingAddresses(state, getters, rootState, rootGetters) {
            if (rootGetters['shared/currentUser/isFacility']) {
                return rootGetters['shared/whitelistedShippingAddresses'];
            }

            return rootGetters['shared/addresses/shippingAddressesByFacility'](state.order.facilityId);
        },
        availableCostCenters(state, getters, rootState, rootGetters) {
            if (rootGetters['shared/currentUser/isFacility']) {
                return rootGetters['shared/whitelistedCostCenters'];
            }

            return rootGetters['shared/addresses/costCentersByFacility'](state.order.facilityId);
        }
    }
}
