import _ from 'lodash';
import ProductApi from 'api/endpoints/products';
import FacilityProductApi from 'api/endpoints/facility/products';
import { TABLE_STORE } from 'store/shared/mixins';
import { mixVuexStoreOptions } from 'store/helpers.js';

export default mixVuexStoreOptions({}, TABLE_STORE, {
    namespaced: true,
    state: {
        pagination: {
            page: 1,
            perPage: 96,
        },
        filters: {
            lcode_in: [],
            k_level: [],
        },
        default_sort: ['price'],
        viewMode: 'normal',
    },
    actions: {
        async get({ state, commit, rootGetters }, params = {}) {
            params = _.merge({}, state.filters, state.pagination, params, { sort: state.sort });

            if (params.k_level && params.k_level.length > 0) {
                const expression = /\d/;
                params.k_level = params.k_level.map((level) => Number(level.match(expression)));
            }

            let response = null;
            if (rootGetters['shared/currentUser/isAdmin']) {
                response = await ProductApi.get(params);
            } else {
                response = await FacilityProductApi.get(params);
            }

            commit('setData', response.data.data);
            commit('setPaginationData', response.data.meta.pagination);
        },
        delete({ dispatch }, payload) {
            ProductApi.delete(payload).then(() => dispatch('get'));
        },
    },
    mutations: {
        clearStoredFilters(state) {
            state.filters = {
                lcode_in: [],
            };
        },
        setPatientViewMode(state, value) {
            //Value can be 'normal' or 'patient'
            state.viewMode = value;
        },
    },
    getters: {
        defaultSort(state) {
            return state.default_sort;
        },
        facilityRestrictedToPatientView(state, getters, rootState, rootGetters) {
            return rootGetters['shared/currentUser/facilityRestrictedToPatientView'];
        },
    },
});
